import Joi from 'joi'
import language from './language'

export default Joi.object().keys({
    id: Joi.number().allow(null).options({ language }),
    idProposicao: Joi.number().integer().options({ language }),
    proposicao: Joi.string().options({ language }),
    idResponsavel: Joi.number().options({ language }),
    responsavel: Joi.string().options({ language }).default("0"),
    prazo: Joi.date().required().options({ language }),
    descricao: Joi.string().required().options({ language }),
    ambito: Joi.number().required().options({ language }),
    resultado: Joi.number().options({ language }),
    influenciaSobre: Joi.array().required().options({ language }),
    tipo: Joi.number().options({ language }),
    anexos: Joi.array().options({ language }),
    idDoCliente: Joi.number().options({ language }),
})