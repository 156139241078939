import React, { Component } from 'react'
import { withRouter, NavLink } from 'react-router-dom'

import { inject, observer } from "mobx-react"

@inject('menuStore', 'authStore')
@observer

class Sidebar extends Component {
    render() {
        const list = this.props.menuStore.select.list || []
        const open = this.props.menuStore.open ? 'open' : ''
        return (
            <nav className={`sidebar ${open} d-print-none`} >
                <h4>{this.props.menuStore.select.name}</h4>

                {list !== undefined && Array.isArray(list) && list.length > 0 ? list.map((item, index) =>
                    <NavLink to={item.path} key={index} className="btn btn-clean btn-block" activeClassName="active" exact={true}>
                        <i className={item.icon}></i>
                        {item.name}
                    </NavLink>
                ) : null}
            </nav>
        )
    }
}

export default withRouter(Sidebar)