import React, { Component } from 'react';
import Loading from '../../../componentes/loading';
import Grafico from '../graficos/graficoParlamentarPorCasas'
// import Pagination from "react-js-pagination";
import { get } from "../../../services//http"
import { inject, observer } from 'mobx-react'

@inject('authStore')
@observer

class ParlamentarPorCasas extends Component {
    state = {
        loading: true,
        dataInicio: '',
        tiposQuantidade: [],

        tabelas: [],
        totalCD: 0,
        totalSF: 0,

        resultadoRelatorioCamara: [],
        resultadoRelatorioSenado: [],
        resultadoRelatorioCongressoNacional: [],

        paginaAtualCamara: 1,
        limiteCamara: 3,
        quantidadeCamara: 0,

        paginaAtualSenado: 1,
        limiteSenado: 3,
        quantidadeSenado: 0,

        paginaAtualCongressoNacional: 1,
        limiteCongressoNacional: 3,
        quantidadeCongressoNacional: 0,

        totalQuantidadeCamara: 0,
        totalQuantidadeSenado: 0,
        totalQuantidadeCongressoNacional: 0,
        dataIni: '',
        dataFim: '',
        descricaoCD: []
    }


    async componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        await this.getDataRelatorio(1)
    }


    async getDataRelatorio(page) {
        const dtIni = this.props.match.params.dtIni
        const dtFim = this.props.match.params.dtFim
        await get(`/relatorio/parlamentares/casas/${dtIni}/${dtFim}`)
            .then(response => {
                // console.log(response)
                const dados = response || []
                this.setState({
                    tabelas: dados.tabela,
                    totalCD: dados.grafico.cd,
                    totalSF: dados.grafico.sf,
                    loading: false
                })
            })
    }

    imprimir() {
        window.print()
    }


    render() {

        const dados = [
            this.state.totalCD,
            this.state.totalSF,
            // this.state.totalQuantidadeCongressoNacional
        ]
        // const dataDaPagina = `Período de ${this.props.match.params.dtIni} a ${this.props.match.params.dtFim}`


        // const dominio = window.localStorage.getItem('dominio')
        // var logo = null
        // switch (dominio) {
        //     case 'Client':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'blueClient':
        //         logo = 'icon-purpleClient corblueClient'
        //         break

        //     case 'darkBlueClient':
        //         logo = 'icon-logo_darkBlueClient cordarkBlueClient'
        //         break

        //     case 'dev':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'localhost':
        //         logo = `icon-purpleClient cordarkBlueClient`
        //         break

        //     default:
        //         logo = 'icon-logo-Client corClient'
        // }
        return (
            this.state.loading ? <Loading /> :
                <div className="container paddingGeralXS">

                    <div id="non-printable" className="mt-5">
                        <button className="btn btn-danger" onClick={this.imprimir}>
                            <img
                                src={require('../../../design/assets/images/impressora.svg')}
                                alt="anexo"
                            />
                        </button>
                    </div>

                    <div className="pt-5">

                        <div className="row">
                        <div className="col-1" >
                        <a href="/">
                                <div className=" logoMenuRelatorios text-center"></div>
                                    {/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
                                </a>
                            </div>
                            <div className="col-11">
                                <div>
                                    <p className="tituloRelatorio">
                                        Relatório
                                        </p>
                                </div>
                                <div>
                                    <p className="descricaoTituloRelatorio">
                                        Quantidade de parlamentares divididos pelas Casas Legislativas
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 text-center periodoFiltro">
                                <div className="">
                                    {/* {dataDaPagina} */}
                                    Parlamentares em Exercício
                                </div>
                            </div>
                        </div>


                        {/* GRAFICO  */}

                        <div className="row">

                            <div className="col-md-6 divGrafico" id="align-grafico">
                                <Grafico
                                    dados={dados}
                                />
                            </div>
                            <div className="col-md-6 align-self-center divIndice" id="divAlignIndice">
                                <div className="row">

                                    <div className="corIndiceCamara-A"></div>
                                    <div>
                                        <p className="textoIndiceCamara-A">
                                            Câmara
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceCamara-A">
                                            {this.state.totalCD}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceSenado-A"></div>
                                    <div>
                                        <p className="textoIndiceSenado-A">
                                            Senado
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceSenado-A">
                                            {this.state.totalSF}
                                        </p>
                                    </div>

                                </div>

                                {/* <div className="row">

                                    <div className="corIndiceCongresso-A"></div>
                                    <div>
                                        <p className="textoIndiceCongresso-A">
                                            Congresso Nacional
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceCongresso-A">
                                            {this.state.totalQuantidadeCongressoNacional}
                                        </p>
                                    </div>

                                </div> */}
                            </div>

                        </div>

                        {/* GRAFICO CAMARA */}


                        <div className="mt-5">
                            <p className="tituloTabelaCamara">
                                Câmara
                            </p>
                        </div>


                        <div className="scroll-tabela">
                            <table className="table table-striped">
                                {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                                <thead className="theadStyleCamara">
                                    <tr>
                                        <th className="headStySiglaCamara">Sigla</th>
                                        <th className="headStyNomeCamara">Nome do Partido</th>
                                        <th className="headStyQuantCamara">Quantidade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tabelas !== undefined && Array.isArray(this.state.tabelas) && this.state.tabelas.length > 0 ?
                                        this.state.tabelas.map((item, index) => {
                                            return (
                                                item.casa === 'CD' && item.quantidade > 0 ?
                                                    <tr key={index}>
                                                        <td className="dadosColSiglaCamara">{item.sigla}</td>
                                                        <td className="dadosColNomeCamara">{item.nome}</td>
                                                        <td className="dadosColQuantCamara">{item.quantidade}</td>
                                                    </tr>
                                                    : null
                                            )
                                        })
                                        : null}
                                </tbody>
                            </table>
                        </div>

                        {/* <Pagination
                            activePage={this.state.paginaAtualCamara}
                            itemsCountPerPage={this.state.limiteCamara}
                            totalItemsCount={parseInt(this.state.quantidadeCamara)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaCamara}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}



                        {/* GRAFICO SENADO */}


                        <div className="mt-5">
                            <p className="tituloTabelaSenado">
                                Senado
                            </p>
                        </div>

                        <div className="scroll-tabela">
                            <table className="table table-striped">
                                {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                                <thead className="theadStyleSenado">
                                    <tr>
                                        <th className="headStySiglaSenado">Sigla</th>
                                        <th className="headStyNomeSenado">Nome do Partido</th>
                                        <th className="headStyQuantSenado">Quantidade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tabelas !== undefined && Array.isArray(this.state.tabelas) && this.state.tabelas.length > 0 ?
                                        this.state.tabelas.map((item, index) => {
                                            return (
                                                item.casa === 'SF' && item.quantidade > 0 ?
                                                    <tr key={index}>
                                                        <td className="dadosColSiglaCamara">{item.sigla}</td>
                                                        <td className="dadosColNomeCamara">{item.nome}</td>
                                                        <td className="dadosColQuantCamara">{item.quantidade}</td>
                                                    </tr>
                                                    : null

                                            )
                                        })
                                        : null}
                                </tbody>
                            </table>
                        </div>

                        {/* <Pagination
                            activePage={this.state.paginaAtualSenado}
                            itemsCountPerPage={this.state.limiteSenado}
                            totalItemsCount={parseInt(this.state.quantidadeSenado)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaSenado}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}

                        {/* GRAFICO CONGRESSO NACIONAL */}


                        {/* <div className="mt-5">
                            <p className="tituloTabelaCongresso">
                                Congresso Nacional
                            </p>
                        </div>
                        <div className="scroll-tabela">
                            <table className="table table-striped table-responsive-sm">
                                <thead className="theadStyleCongresso">
                                    <tr>
                                        <th className="headStySiglaCongresso">Sigla</th>
                                        <th className="headStyNomeCongresso">Nome do Partido</th>
                                        <th className="headStyQuantCongresso">Quantidade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                        this.state.tiposQuantidade.map((item, index) => {
                                            return (
                                                item.casa === 'CN' && item.quantidade !== 0 ?
                                                    <tr>
                                                        <td className="dadosColunaTipoCongresso">{item.tipo}</td>
                                                        <td className="dadosColNomeCongresso">{item.quantidade}</td>
                                                        <td className="dadosColQuantCongresso">{item.quantidade}</td>
                                                    </tr>
                                                    : null
                                            )
                                        })
                                        : null}
                                </tbody>
                            </table>
                        </div> */}

                        {/* <Pagination
                            activePage={this.state.paginaAtualSenado}
                            itemsCountPerPage={this.state.limiteSenado}
                            totalItemsCount={parseInt(this.state.quantidadeSenado)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaSenado}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}


                    </div>

                </div>

        )
    }
}

export default ParlamentarPorCasas