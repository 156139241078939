import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


export default function FormDialog(props) {

    const [open, setOpen] = React.useState(props.abrirModalCancelar === null ? true : props.abrirModalCancelar);


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpen(false)
    }

    const handleCloseModalSuccess = () => {
        setOpen(false)
    }



    return (
        <div>
            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title" style={{ height: '520px', }}>


                <DialogContent
                    style={{
                        // width: 580,
                        backgroundColor: "white",
                        color: '#3E4049'

                    }}
                >
                    <div className='pt-3 pl-3 pr-3'>
                        <div className='text-center p-3'>
                            <img
                                className="mr-3" style={{ width: '50px' }}
                                src={require('../../design/assets/images/alerta-cancelar-tema-IA.svg')}
                                alt="anexo"
                            />{' '}
                        </div>
                        <div className='p-3'>
                            <h4>Tem certeza que deseja excluir?</h4>
                            <p style={{ textAlign: 'center' }}>Esta ação não poderá ser desfeita.</p>
                        </div>
                        <div className='row'>
                            <div className='col-6' style={{ borderTop: '1px solid #E3E3E3', borderRight: '1px solid #E3E3E3', paddingTop: '10px', paddingBottom: '10px' }}>
                                <div onClick={handleCloseModalSuccess} className='text-center' style={{ marginTop: '10px', cursor: 'pointer' }}>
                                    VOLTAR
                                </div>
                            </div>
                            <div className='col-6' style={{ borderTop: '1px solid #E3E3E3', paddingTop: '10px', paddingBottom: '10px' }}>
                                <div onClick={() => window.location.reload()} className='text-center' style={{ marginTop: '10px', cursor: 'pointer' }}>
                                    EXCLUIR
                                </div>
                            </div>
                        </div>

                    </div>
                </DialogContent>

            </Dialog>
        </div >
    );
}