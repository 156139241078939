import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class GraficoCamara extends Component {

    constructor(props) {
    
        super(props);

        this.state = {
            options: {
                labels: ['Não Classificado', 'Convergente', 'Divergente', 'Neutro'],
                colors: ['#5856D7', '#4CD964', '#F63854', '#E1E8F2'],
                legend: {
                    show: false,
                }
            },
            series: [
                2, 1, 2, 3
            ],
        }
    }


    render() {
        // const larguraTela = window.innerWidth
        // const larguraGrafico = larguraTela > 375 ? '330' : '260'
        // console.log(this.props)
        const series1 = [
            this.props.dados.deputadoNaoClassificado !== undefined ? parseInt(this.props.dados.deputadoNaoClassificado) : 0,
            this.props.dados.deputadoConvergente !== undefined ? parseInt(this.props.dados.deputadoConvergente) : 0,
            this.props.dados.deputadoDivergente !== undefined ? parseInt(this.props.dados.deputadoDivergente) : 0,
            this.props.dados.deputadoNeutro !== undefined ? parseInt(this.props.dados.deputadoNeutro) : 0,
        ]
        const series2 = [0, 0, 0, 0]
        const series = this.props.dados !== undefined ? series1 : series2

        // console.log("kjhakjhgskjhgd", this.props.dados.deputadoConvergente)

        return (
            <div className="donut">
                <Chart
                    options={this.state.options}
                    series={series}
                    type="donut"
                    deputadoConvergente={this.state.deputadoConvergente}
                // width={larguraGrafico} 
                />
            </div>
        );
    }
}

export default GraficoCamara;
