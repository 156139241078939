import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'

import Relatorio from '../relatorioDefaut';
// import RelatorioTipo from '../relatorioTipo';

import RelatorioPropCasa from '../component/proposicaoPorCasaLegislativa'
import RelatorioPropTema from '../component/proposicaPorTemas'
import RelatorioPropPrior from '../component/proposicaoPorNivelPrioridade'
import RelatorioPropPosic from '../component/proposicaoPorPosicionamento'
import RelatorioPropAtivInat from '../component/proposicaoAtivoInativo'
// import RelatorioPropRes from '../component/'
import RelatorioPropPeri from '../component/proposicaoNovaPorPeriodo'
import RelatorioAcoesPeri from '../component/acoesPorPeriodo'
import RelatorioAcoesInflu from '../component/acoesInfluenciaPorResultado'
import RelatorioParlaCasa from '../component/parlamentarPorCasas'
import RelatorioParlaProf from '../component/parlamentarPorProfissao'
import RelatorioParlaPartido from '../component/parlamentarPorPartido'
import RelatorioParlaQuali from '../component/parlamentarPorQualificacao'
import RelatorioParlaOrgao from '../component/parlamentarPorOrgao'

export default () =>
    <Fragment>
        <Switch>
            <Route exact path="/relatorio" component={Relatorio} />
            {/* <Route exact path="/relatorio/:tipo/:dtIni/:dtFim" component={RelatorioTipo} /> */}

            <Route exact path="/relatorio/proposicao-por-casa-legislativa/:dtIni/:dtFim" component={RelatorioPropCasa} />
            <Route exact path="/relatorio/proposicao-temas/:dtIni/:dtFim" component={RelatorioPropTema} />
            <Route exact path="/relatorio/proposicao-por-nivel-prioridade/:dtIni/:dtFim" component={RelatorioPropPrior} />
            <Route exact path="/relatorio/proposicao-por-posicionamento/:dtIni/:dtFim" component={RelatorioPropPosic} />
            <Route exact path="/relatorio/proposicao-ativo-inativo/:dtIni/:dtFim" component={RelatorioPropAtivInat} />
            {/* <Route exact path="/relatorio/:tipo/:dtIni/:dtFim" component={RelatorioPropRes} /> */}
            <Route exact path="/relatorio/nova-proposicao-por-periodo/:dtIni/:dtFim" component={RelatorioPropPeri} />
            
            <Route exact path="/relatorio/acoes-por-periodo/:dtIni/:dtFim" component={RelatorioAcoesPeri} />
            <Route exact path="/relatorio/acoes-influencia-por-resultado/:dtIni/:dtFim" component={RelatorioAcoesInflu} />
            
            <Route exact path="/relatorio/parlamentar-por-casas/:dtIni/:dtFim" component={RelatorioParlaCasa} />
            <Route exact path="/relatorio/parlamentar-por-profissao/:dtIni/:dtFim" component={RelatorioParlaProf} />
            <Route exact path="/relatorio/parlamentar-por-partido/:dtIni/:dtFim" component={RelatorioParlaPartido} />
            <Route exact path="/relatorio/parlamentar-por-qualficacao/:dtIni/:dtFim" component={RelatorioParlaQuali} />
            <Route exact path="/relatorio/:tipo/:dtIni/:dtFim" component={RelatorioParlaOrgao} />

        </Switch>
    </Fragment>