import React, { Component } from 'react'
import { inject, observer } from "mobx-react"
//import { firstBy } from "thenby";
import { get, post, put } from "../../services/http";
//import Loading from "../../componentes/loading";
import ModalCadDepartamento from './modal-cadastro-departamento'
import ModalEditExcDepto from './modal-editar-departamento'
import alertaStore from '../../stores/alertaStore'
import Loading from "../../componentes/loading";
import Pagination from "react-js-pagination";

@inject('authStore')
@observer

class Departamentos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            status: 2,
            listarDepartamentos: [],
            listarDepartamentosFiltro: [],
            paginaAtual: 1,
            limite: 10,
            quantidade: 0,
            palavraFiltro: '',
            loadingTable: false,
        }
    }

    async componentDidMount() {
        if (this.props.authStore.ehAdministrador !== true) {
            window.location.href = '/usuario/perfil'
        }
        await this.carregarDepartamentos(1)
    }

    async carregarDepartamentos(pagina) {
        //console.log('carregarDepartamentos>>>>',pagina)

        this.setState({ loadingTable: true })
        const offset = pagina === 1 ? 0 : (pagina - 1) * this.state.limite
        await get(`/departamento/listar/${this.state.limite}/${offset}`)
            .then(resp => {
                //console.log('carregarDepartamentos>>>>>>>>',resp)
                this.setState({ listarDepartamentos: resp.array, quantidade: resp.total, loadingTable: false })
            })
            .catch(err => err)
    }

    carregarPaginaNova = async (pagina) => {
        //console.log('PAGINA>>>>>',pagina)
        this.setState({ paginaAtual: pagina })
        await this.carregarDepartamentos(pagina)
    }



    saveDepto = async (infoUser, actions) => {
        const data = infoUser
        //console.log('XXXXXXXXXX>>>>>>>',actions)


        if (actions === 'Cadastrar') {
            this.setState({ loadingTable: true })
            //console.log(data)

            await post(`/departamento/cadastrar`, data)
                .then(resp => {

                    //console.log(resp)
                    if (resp !== undefined) {
                        alertaStore.criar('Departamento criado com sucesso.')
                        this.setState({ loadingTable: false })
                        this.carregarDepartamentos(1)
                        //window.location.reload()

                    } else {
                        alertaStore.erro('Erro ao inserir departamento!')
                        //window.location.reload()
                    }

                })
                .catch(err => err)

        } else if (actions === 'Editar') {
            this.setState({ loadingTable: true })
            //console.log(data)

            await put(`/departamento/editar`, data)
                .then(resp => {
                    //console.log("EDITAR>>>", resp)
                    if (resp !== undefined) {
                        //window.location.reload()
                        alertaStore.criar('Departamento alterado com sucesso.')
                        this.setState({ loadingTable: false })
                        this.carregarDepartamentos(1)
                    } else {
                        alertaStore.erro('Erro ao editar departamento!')
                    }

                })
                .catch(err => err)

        } else if (actions === 'Excluir') {
            this.setState({ loadingTable: true })
            //console.log(data)
            await put(`/departamento/apagar-departamento`, data)
                .then(resp => {
                    //console.log('Excluir>>>',resp)
                    //window.location.reload()
                    alertaStore.criar('Departamento excluido com sucesso.')
                    this.setState({ loadingTable: false })
                    this.carregarDepartamentos(1)


                })
                .catch(err => err)


        }



    }

    async apagarDepartamento(data) {

    }

    async getDadosDepartamentoFiltro() {

        this.setState({ loadingTable: true })
        await get(`/departamento/usuarios?palavra=${this.state.palavraFiltro}`)
            .then(resp => {
                //console.log('RESP FIL>>>>>',resp)
                this.setState({ listarDepartamentos: resp.array, quantidade: 0, loadingTable: false })
            })
            .catch(err => err)

    }

    selecionarBusca(value) {
        if (this._buscarInput.value === '') {
            this.carregarDepartamentos(1)

        } else {
            const palavra = value
            this.setState({ palavraFiltro: palavra })
        }
    }



    render() {



        // const usuarios = this.state.status === 2 && this.state.usuarios !== undefined && this.state.usuarios.length > 0 ? this.state.usuarios : this.state && this.state.usuarios && this.state.usuarios.length > 0 &&
        //     this.state.usuarios.filter(u => u.id !== this.props.authStore.id && u.situacao === this.state.status && this.state.status !== 2)
        //         .sort(firstBy("idPermissao").thenBy("nome", { ignoreCase: true }));

        //console.log('>>>>>',this.state.listarDepartamentos)

        return (

            <div className="container-fluid">

                <div style={{ backgroundColor: 'white', padding: '15px' }}>

                    <div className="row mb-3">

                        <div className="input-group col-12 col-sm-6  col-lg-9 col-xl-9 p-3">
                            <input className="form-control py-2 border-right-0 border" type="search" autoComplete="off"
                                value={this.state.descricao}
                                onKeyUp={(event) => { if (event.which === 13) { this.getDadosDepartamentoFiltro() } }}
                                // onKeyPress={(event) => this.clickEnter(event)}
                                onChange={(event) => this.selecionarBusca(event.target.value)}
                                placeholder="Buscar departamento " aria-label="Pesquisar"
                                aria-describedby="search"

                                ref={(event) => this._buscarInput = (event)}
                            />

                            <span id="icone-pesquisar-proposicao" className="input-group-append">
                                <button className="btn btn-outline-secondary border-left-0 border" type="button"
                                    onClick={() => this.getDadosDepartamentoFiltro()}>
                                    <i className="icon-search-small"></i>
                                </button>
                            </span>
                        </div>

                        <div className="col-12 col-sm-6  col-lg-3 col-xl-3 mt-2">

                            <ModalCadDepartamento callbackParent={this.saveDepto} />

                        </div>



                    </div>

                    {/* <div className="col-12 col-md-12 my-3">
                        <nav className="nav mb-3 fs-12 text-uppercase">
                            <div className={this.state.status === 1 ? 'nav-link active' : 'nav-link cursor-pointer'} onClick={() => this.setState({ status: 1 })} >Membros Ativos</div>
                            <div className={this.state.status === 0 ? 'nav-link active' : 'nav-link cursor-pointer'} onClick={() => this.setState({ status: 0 })} >Membros Inativos</div>
                            <div className={this.state.status === 2 ? 'nav-link active' : 'nav-link cursor-pointer'} onClick={() => this.setState({ status: 2 })} >Todos os Membros</div>
                        </nav>
                    </div> */}


                    {/* </div><div id={`_${index}`} className="col-12 mb-2 bordasCitacoes" key={index}> */}
                    {
                        this.state.loadingTable ? <div style={{ marginTop: '200px' }}><Loading /></div> :
                            this.state.listarDepartamentos !== undefined && Array.isArray(this.state.listarDepartamentos) && this.state.listarDepartamentos.length > 0
                                ?
                                this.state.listarDepartamentos.map((item, index) => {
                                    return (
                                        <div className="col-12 mb-2 bordasCitacoes p-3" style={{ fontSize: '20px', fontWeight: 'bold' }} key={index}>
                                            <div className="d-flex justify-content-between">
                                                <div className=" ">{item.descricao}</div>
                                                <ModalEditExcDepto
                                                    callbackParent={this.saveDepto}
                                                    dadosDepartamento={item}

                                                />

                                            </div>


                                        </div>
                                    )

                                })

                                : null
                    }


                    {
                        this.state.listarDepartamentos.length <= 1
                            ?
                            null
                            :
                            <div className="d-flex justify-content-center paginacao-citacoes">
                                <Pagination
                                    activePage={this.state.paginaAtual}
                                    itemsCountPerPage={this.state.limite}
                                    totalItemsCount={this.state.quantidade}
                                    pageRangeDisplayed={10}
                                    hideFirstLastPages={true}
                                    onChange={this.carregarPaginaNova}
                                    prevPageText={<div className="prev-page"><img src={require('../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                                    nextPageText={<div className="next-page"><img src={require('../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                                />
                            </div>

                    }



                </div>
            </div>
        )
    }
}

export default Departamentos