import React from 'react'
import { Grid } from '../../../estruturas/bootstrap';
import If from '../../helpers/If';


export default function ({ cols, css, name, label, error, children })  {

  //console.log('PROPS>>>>>>>>>',cols, css, name, label, error, children)
    return(
          <Grid cols={cols || "12"} css={css}>
          <div className="form-group position-relative mt-1" style={{backgroundColor:"White", width:"100%", border:"1px solid var(--linkText)", borderRadius:"4px", padding:"5px", outlineStyle:"none"}}>
            <If test={label}>
              <label htmlFor={name} className="fs-12  ">{label}</label>
            </If>
            {children}
            <If test={error}>
              <small className="form-text text-danger">{error}</small>
            </If>
          </div>
        </Grid>
    )
}




