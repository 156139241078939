import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
// import Cadastrar from '../cadastrar/cadastrar-usuario'
// import NovaSenha from '../cadastrar/nova-senha'

import Login from '../index';

export const Token = () => {
    return (
        <div>
            Token
        </div>
    )
}

export default () =>
    <Fragment>
        <Switch>
            {/* <Route exact path="/cadastrar" component={Cadastrar} />
            <Route exact path="/senha-nova" component={NovaSenha} /> */}
            <Route path="/" component={Login} />
            <Route path="/token" component={Token} />
        </Switch>
    </Fragment>
