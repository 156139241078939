import * as React from "react";
import { useClearCache } from "react-clear-cache";

import IndexLogin from './indexLogin'


function clearApp(props) {
    const tamanhoTela = window.innerWidth
    const { isLatestVersion, emptyCacheStorage } = useClearCache();

    // if (process.env.REACT_APP_SSO ? process.env.REACT_APP_SSO : '0' === '1') return <></>
    // else
    return (
        <div>

            {
                tamanhoTela < 600
                    ?


                    < div className='d-block d-sm-none'>
                        {
                            isLatestVersion

                                ?

                                <IndexLogin />

                                :

                                null
                        }


                        {!isLatestVersion && (
                            <>

                                <div className='row d-block d-sm-none' style={{ margin: "0px", width: "100%" }}>

                                    <div className="text-center overFlowDiv">

                                        <div className="imgBrasilia">

                                        </div>
                                        <div className="bglogin">

                                        </div>
                                        <div className="logoAlign">
                                            <div className="logoLogin"></div>
                                            <div className="divFormLoginMobile">
                                                <p style={{ color: "white" }} className=" pt-5 d-flex justify-content-center">Atualize para nova versão, antes de fazer login !</p>
                                                <a href={"/"}
                                                    className="btn btn-danger col-sm-12 mb-5 w-100 p-3"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        emptyCacheStorage();
                                                    }}
                                                >Nova Versão</a>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </>
                        )}
                    </div>
                    :
                    <div className='d-none d-sm-block' >
                        {
                            isLatestVersion

                                ?

                                <IndexLogin />

                                :

                                null
                        }


                        {!isLatestVersion && (
                            <>
                                <div className="row ">

                                    <div className="col-md-6 col-sm-12 text-center overFlowDiv" >

                                        <div className="imgBrasilia">

                                        </div>
                                        <div className="bglogin">

                                        </div>
                                        <div className="logoAlign">
                                            <div className="logoLogin"></div>
                                        </div>

                                    </div>
                                    <div className="col-md-6 col-sm-12 overFlowDiv">
                                        <div className="overFlowDivRight">
                                            {/* <div className="text-right">
                                <div className="cadastre">Não possui uma conta? &nbsp;
                                    <a href="/cadastrar"><span style={{color:'var(--linkText)',textDecoration:'underline'}}> Cadastre-se </span> </a> 
                                </div>
                            </div> */}

                                            <div className=" divFormLogin col-12 col-sm-12  col-lg-12 col-xl-12">
                                                <p className="d-flex justify-content-center">Atualize para nova versão, antes de fazer login !</p>
                                                <a href={"/"}
                                                    className="btn btn-danger col-sm-12 mb-5 w-100 p-3"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        emptyCacheStorage();
                                                    }}
                                                >Nova Versão</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </>
                        )}
                    </div>

            }










        </div >

    );
};

export default clearApp;


