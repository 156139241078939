import React, {Fragment} from 'react'
import Breadcrumb from '../../estruturas/breadcrumb'
import Query from '../../componentes/hoc/query'
import {Row, Grid} from '../../estruturas/bootstrap'

import Casa from '../../helpers/casa'
import {formatarData} from '../../helpers/date'

const discurso = ({id, parlamentar}) => {
    return (
        <Fragment>
            <Breadcrumb/>
            <Query query={`/parlamentar/${parlamentar}/discursos/${id}`}>
                {({ data: discurso }) => {
                    //console.log(discurso)
                    return discurso ?
                        <div className="container-fluid">
                            <h6 className="text-uppercase my-4"><span
                                className="font-weight-normal ">Pronunciamento de</span> {discurso.nomeParlamentar} {discurso.data ? `em  ${formatarData(discurso.data)}` : ''}
                            </h6>
                            <div className="bg-white p-3 mb-3">
                                {discurso.data ? <Row css="mb-3">
                                    <Grid cols="12 12 3">Data</Grid>
                                    <Grid cols="12 12 9">{formatarData(discurso.data)} {discurso.horario}</Grid>
                                </Row> : null}
                                <Row css="mb-3">
                                    <Grid cols="12 12 3">Casa</Grid>
                                    <Grid cols="12 12 9">{Casa[discurso.casa]}</Grid>
                                </Row>
                                <Row css="mb-3">
                                    <Grid cols="12 12 3">Resumo do Assunto</Grid>
                                    <Grid cols="12 12 9">{discurso.sumario}</Grid>
                                </Row>
                                {/*
                                        <Row css="mb-3">
                                            <Grid cols="12 12 3">Tags</Grid>
                                            <Grid cols="12 12 9">{discurso.data}</Grid>
                                        </Row>
                                        */}
                                <hr/>
                                <Row css="mb-3">
                                    <Grid cols="12 12 3">Texto na íntegra</Grid>
                                    <Grid cols="12 12 9">{discurso.discurso || discurso.sumario}</Grid>
                                </Row>
                            </div>
                        </div>
                        : <p>Entre em contato com o administrador</p>
                }
                }
            </Query>
        </Fragment>
    )
}

export default discurso