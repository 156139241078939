import React from "react"

const bread = () => {
    return (
        <nav>
            <div className=" bg-white pl-2 marginTopXs" style={{boxShadow: "-3px 0px 10px #C0C0C0"}}>
                        <p style={{fontSize:"10px", color: "var(--linkText)", fontWeight:"bold", padding:"15px" }}>
                        MIDIA NEWS
                        </p>
                    </div>
            {/* <ol className="breadcrumb mb-3 p-2">
                <li className="breadcrumb-item">
                    <a className="active" href="/">
                        <i className="icon-arrow-straight-back-small align-baseline fs-14"></i>
                    </a>
                </li>
                <li className="breadcrumb-item">
                    <a className="text-capitalize active" href="/parlamentar">
                        Midia News
                    </a>
                </li>
            </ol> */}
        </nav>
    )
}

export default bread
