import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import { NavLink } from 'react-router-dom'
import authStore from '../stores/authStore'

export class Accordion extends Component {
  state = { aberto: this.props.aberto || false }

  // abrirAccordion = (titulo,aberto) =>{    
  //   this.props.abrirAccordion(titulo,aberto) 
  // }

  handleClick(){
   
    this.setState({ aberto: !this.state.aberto })
   
    if(this.props.abrirAccordion){
      // console.log(this.state.aberto)
      this.props.abrirAccordion(this.state.aberto)
    }
  }

  delete = (id) =>{
    this.props.delete(id)
  }

  render() {
      
    const tituloClasse = `m-2 d-flex justify-content-between align-items-center ${this.props.uppercase ? 'text-uppercase' : ''} ${this.props.size ? this.props.size : 'h5'}`
    const background = this.props.background ? this.props.background : 'bg-white'
    const padding = this.props.p ? this.props.p : 'p-1'
    const marginbottom = this.props.mb ? this.props.mb : 'mb-3'
    const margintop = this.props.mt ? this.props.mt : 'mt-3'
    const iconTrash = this.props.iconTrash ? this.props.iconTrash : require('../design/assets/images/trash-gray.svg');
    const iconEdit = this.props.iconEdit ? this.props.iconEdit : require('../design/assets/images/editar-gray.svg');
    const indice = this.props.index === this.props.indexAtual

    return <div className={`w-100 ${this.props.bottom ? this.props.bottom : 'mb-3'}`}>
      <div className={`${background} ${padding} ${marginbottom}`} >
        <ReactTooltip />
        <div data-tip={this.props.tooltip} className={tituloClasse} >
          <p className={`w-75 ${marginbottom}`}>
            {this.props.numero === undefined ? null :
              <span className="numero-acoes-influencia">{this.props.numero}{' '}</span>
            }
            {this.props.titulo} <span className='Client-detalhe'>{this.props.subtitle}</span>
            {this.props.proposicaoDescricao === undefined ? null :
              <NavLink id="proposicao-acao-influencia" to={`/proposicao/${this.props.proposicaoId}/acoes-influencia`}>
                <span className="proposicao-descricao-acoes-influencia">{' '}{this.props.proposicaoDescricao}</span>
              </NavLink>
            }
          </p>
          <div className={`d-flex align-items-center w-25 ${this.props.enableIcons ? 'justify-content-between' : 'justify-content-end'}`}>
            {
              this.props.enableIcons ? <>
                <img id="acao-preparatoria-excluir" className='cursor-pointer' onClick={() => this.props.onClick(this.props.itemToDelete)} src={iconTrash} alt="" />
                <NavLink id="acao-prepatoria-editar" to={this.props.url}>
                  <img src={iconEdit} alt="" />
                </NavLink>
              </> : null
            }
            {authStore.ehVisualizador === true ? null :
              <i className='icon-lixeira pr-2 cursor-pointer' style={{color:'#9B9B9B'}} onClick={()=> this.delete(this.props.id)}></i>
            }
            <i id={this.props.id} onClick={() => this.handleClick() }
              className={`${this.state.aberto && indice   ? 'icon-arrow-up-small' : 'icon-arrow-down-small'} ${this.props.iconFont ? this.props.iconFont : 'fs-16'} mt-1 cursor-pointer`} />
          </div>
        </div>
      </div>

      {indice ? 
      <div className={`${this.props.bodyBackground ? this.props.bodyBackground : ''} ${this.state.aberto ? margintop : 'mt-0'} ${this.props.mb ? this.props.mb : ''}`}>
        {this.state.aberto && this.props.children }
      </div>
      : null }

    </div>
  }
}

export default Accordion
