import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import UploadFile from '../../../componentes/forms/inputs/upload'
import { get } from '../../../services/http'

@inject('alertaStore', 'uploadStore', 'authStore')
@observer
class FormNotasTecnicas extends Component {
  state = { arquivo: [] } 
  componentDidMount = async () => this.getAnexos()

  async getAnexos() {
    await get(`anexo/proposicao-estrategia/${this.props.id}`)
      .then(arquivo => this.setState({ arquivo }))
      .catch(e => console.log(e))
  }

  render = () => <Fragment>
    <h6 className="text-capitalize mb-2">Anexos</h6>
    <div className="row mb-2">
      {
      this.state.arquivo !== undefined && Array.isArray(this.state.arquivo) && this.state.arquivo.length > 0 ?
      this.state.arquivo.map((item, index) =>
        <Fragment key={item.arquivo}>
          <div className="w-100 p-2">
              
              {/* <div className="float-left row w-70">
                <div  className="ml-2" style={{content: "var(--clipsAnexos)", width:"11px"}}></div>
                <span className=" ml-3 Client-link" style={{textOverflow:"ellipsis", whiteSpace:"nowrap", width:"200px", overflow:"hidden"}}>{item.nome}</span>
              </div>
             
              
                {this.props.authStore.ehVisualizador === true ? null :  
                    <span className=" ml-2 float-right w-30 cursor-pointer" onClick={() => {
                        this.props.uploadStore.removerAnexo(item);
                        this.setState({ arquivo: this.state.arquivo.filter(anexo => anexo.arquivo !== item.arquivo) });
                    }}>
                    <img src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo"/>
                    </span>
                } */}
              

              <div  className="float-left row w-75" style={{cursor:"pointer"}} onClick={() => this.props.uploadStore.downloadArquivo(item, this.refs).then(x => console.log(x))}>
                <div  className="ml-2" style={{content: "var(--clipsAnexos)", width:"11px"}}></div>
                <span className=" ml-2 Client-link text-truncate" style={{textOverflow:"ellipsis", whiteSpace:"nowrap", width:"200px", overflow:"hidden"}}>{item.nome}</span>
              </div>

              {this.props.authStore.ehVisualizador === true ? null :  
                <span className="float-right ml-2 cursor-pointer" onClick={() => {
                    this.props.uploadStore.removerAnexo(item);
                    this.setState({ arquivo: this.state.arquivo.filter(anexo => anexo.arquivo !== item.arquivo) });
                }}>
                <img style={{marginBottom:"8px"}} src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo"/>
              </span>
              }
          </div>
        </Fragment>):null}
    </div>

    <UploadFile modulo={'proposicao-estrategia'} id_referencia={this.props.id} onChange={anexo => { this.setState({ arquivo: [...this.state.arquivo, ...anexo] }); this.getAnexos() }} />

  </Fragment>
}

export default FormNotasTecnicas
