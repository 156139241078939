import React, { Component } from 'react';
import Loading from '../../componentes/loading';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { get, post } from "../../services/http";
import Pagination from "react-js-pagination";
import { inject, observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'
// import AlertaStore from '../../stores/alertaStore'


const inputStyleA = {
    border: "none",
    backgroundColor: 'transparent',
    color: '#000000',
    width: '100%',
    placeholder: 'red',
    outline: 'none',
    height: "100%"
};



@inject('authStore')
@observer

class Insights extends Component {
    state = {
        loading: false,
        user: JSON.parse(window.localStorage.getItem('user')),
        status: true,
        getGeralLista: {},

        listaGruposTemas: [],

        paginaAtual: 1,
        limite: 0,
        quantidade: 0,
        valorBusca: ""
    }


    componentWillMount() {
        this.getListaGrupoTemasRender()
    }


    async getListaGrupoTemasRender() {
        this.setState({
            loading: true
        })

        await get(`/tema-proposicoes/obterGrupos?pagina=${this.state.paginaAtual}`)
            .then(resp => {
                this.setState({
                    getGeralLista: resp,
                    listaGruposTemas: resp.resultados,
                    limite: resp.itensPagina,
                    quantidade: resp.totalItens,
                })
                this.setState({
                    loading: false
                })
            })
            .catch(err => err)
    }


    carregarPaginaNova = async (pagina) => {
        this.setState({ paginaAtual: pagina })
        await this.getListaGrupoTemas(pagina)
    }

    async getListaGrupoTemas(page) {
        this.setState({
            loading: true
        })
        await get(`/tema-proposicoes/obterGrupos?pagina=${page}`)
            .then(resp => {
                this.setState({
                    getGeralLista: resp,
                    listaGruposTemas: resp.resultados,
                    limite: resp.itensPagina,
                    quantidade: resp.totalItens,
                })
                this.setState({
                    loading: false
                })
            })
            .catch(err => err)
    }

    handleChangeStatus = async (position) => {
        if (this.state.status === position) {
            this.setState({
                status: false
            })
        } if (this.state.status !== position) {
            this.setState({
                status: true
            })
        }
    }

    selecionarBusca = async (e) => {
        if (await e === "") {
            this.getListaGrupoTemas(1)
        } else {
            this.setState({
                valorBusca: e
            })
        }

    }


    buscarGrupo = async () => {
        // if (this.state.valorBusca === "" || this.state.valorBusca === null || this.state.valorBusca === undefined) {
        //     AlertaStore.erro("Campo de busca está em branco")
        // } else {
            const data = {
                data: {
                    descricao: this.state.valorBusca
                }
            }
            post('/tema-proposicoes/buscaGrupo', data)
                .then(resp => {
                    this.setState({
                        listaGruposTemas: resp,
                    })
                })
        // }
    }


    limparBusca = () => {
        this.getListaGrupoTemas(1)
    }

    render() {

        const alturaTela = this.state.listaGruposTemas.length > 9 ? "1200px" : `${window.innerHeight}px`

        const StatusSwitch = withStyles({
            switchBase: {
                color: '#FFF',
                '&$checked': {
                    color: '#4CD964',
                },
                '&$checked + $track': {
                    backgroundColor: '#4CD964',
                },
            },
            checked: {},
            track: {},
        })(Switch);



        return (
            this.state.loading ? <div style={{ marginTop: '400px' }}><Loading /></div> :
                <div style={{ backgroundColor: "#f6f6f6", height: `${alturaTela}` }}>
                    <div className=" bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                        <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                            CLASSIFICAÇÃO DE TEMAS
                        </p>
                    </div>

                    <div className="container">

                        {/* ................head.................... */}
                        <div className='row' style={{ width: "100%" }}>
                            <div className='col-md-6 col-12'>
                                <p style={{ fontSize: "24px", fontWeight: "bold", paddingTop: "30px", color: "#1E0736" }}>
                                    Lista de Grupos
                        </p>
                            </div>
                            <div className='col-md-6 col-12' style={{ textAlign: "end", marginRight: "-20px" }}>
                                <NavLink to={`/classificacao-tema/cadastro-nova-classificacao`}>
                                    <button type="button" className="btn" style={{ marginTop: "5px", padding: "15px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter" }} variant="outlined" >
                                        Cadastrar
                                </button>
                                </NavLink>
                            </div>
                        </div>


                        {/* ................Background White.................... */}
                        <div className='bg-white container' style={{ paddingLeft: "40px", paddingRight: "40px", paddingTop: "25px", paddingBottom: "25px" }}>

                            <div style={{ width: "100%", marginBottom: "40px" }}>
                                <div className="row" style={{ border: "1px solid lightgray", height: "60px", backgroundColor: "white", paddingLeft: "5px", paddingRight: "5px", borderRadius: "7px" }}>
                                    <div className="col-11">
                                        <input type="search" autoComplete="off"
                                            style={inputStyleA}
                                            onChange={(event) => this.selecionarBusca(event.target.value)}
                                            onKeyPress={() => this.buscarGrupo()}
                                            placeholder="Buscar Grupos" aria-label="Pesquisar"
                                            aria-describedby="search"
                                        />
                                    </div>
                                    <div onClick={() => this.buscarGrupo()} className="col-1 text-right cursor-pointer" style={{ alignItems: "center", display: "flex", justifyContent: "center" }} >
                                        <img style={{ width: "30px" }} src={require('../../design/assets/images/icon-search.png')} alt="excluir anexo" />
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.listaGruposTemas.length === 0 ?
                                    <div className="row">
                                        <div className="col-12 text-right">
                                            <p onClick={() => this.limparBusca()} style={{ cursor: "pointer", fontSize: "10px", color: "var(--linkText)", marginTop: "-20px" }}>Limpar Busca</p>
                                        </div>
                                        <div className="col-12 text-center">
                                            <h4 style={{ marginTop: "20px" }}>Não encontramos nenhuma referência para a sua busca</h4>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            {this.state.listaGruposTemas.map((item, index) => {
                                return (
                                    <div key={index} className="row" style={{ border: "1px solid lightgray", padding: "10px", borderRadius: "7px", marginTop: "20px" }}>
                                        <div className="col-md-9 col-6" style={{ alignItems: "center", display: "flex" }}>
                                            <NavLink to={`/classificacao-tema/nome-do-grupo/${item.id}`}>
                                                <p style={{color:"black", padding:"0px", margin:"0px"}}>{item.descricao}</p>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-3 col-6" style={{ paddingLeft: "100px" }}>
                                            <div className="row">
                                                <div className="col-md-8 col-6">
                                                    <div className="row">
                                                        <StatusSwitch
                                                            checked={item.ativo}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                        <div style={{ alignItems: "center", display: "flex" }}>
                                                            {item.ativo === true ? "Ativo" : "Inativo"}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-6" style={{ alignItems: "center", display: "flex" }}>
                                                    <NavLink to={`/classificacao-tema/editar-grupo/${item.id}/lista`} >
                                                        <div className="cursor-pointer" style={{ border: "1px solid lightgray", borderRadius: "3px", paddingTop: "7px", paddingLeft: "7px", paddingRight: "7px" }}>
                                                            <i className='icon-editar-gray' style={{ color: '#9B9B9B' }} ></i>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                )
                            })

                            }



                            <div style={{ marginBottom: "-40px" }}>
                                <div className="d-flex justify-content-center paginacao-citacoes">
                                    <Pagination
                                        activePage={this.state.paginaAtual}
                                        itemsCountPerPage={this.state.limite}
                                        totalItemsCount={parseInt(this.state.quantidade)}
                                        pageRangeDisplayed={10}
                                        hideFirstLastPages={true}
                                        onChange={this.carregarPaginaNova}
                                        prevPageText={<div className="prev-page"><img src={require('../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                                        nextPageText={<div className="next-page"><img src={require('../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                                    />
                                </div>
                            </div>





                        </div>

                    </div>




                </div>
        )
    }
}

export default Insights