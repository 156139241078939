import React, { Component, Fragment } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import Menu from '../estruturas/menu'
import Sidebar from '../estruturas/sidebar'
import Routes from './routes'
import RouterLogin from '../paginas/login/router/index'
import Alertas from '../componentes/alertas/alertas'
@inject('authStore', 'modalStore')
@observer
class App extends Component {

    constructor() {
        super();
        document.title = process.env.REACT_APP_TITLE ? process.env.REACT_APP_TITLE : "Legisnote"
        this.state = {
            idDoCliente: 0
        }
    }

    async componentDidMount() {

        const dominio = window.location.hostname.split('.')[0]
        window.localStorage.setItem('dominio', dominio)
        let user = JSON.parse(window.localStorage.getItem('user'))

        // if(user&&window.location.search.indexOf('token=')>0 ){
        //     window.location.href="/";
        //     window.location.reload();
        // }


        if (process.env.REACT_APP_SSO ? process.env.REACT_APP_SSO : '0' === '1') {
            const invalid = window.location.search.split('invalid').length > 1;
            const data = window.location.search.split('token=');
            // console.log('invalid', invalid, data);

            if (!invalid && data.length < 2 && (!user || user === null) && (!window.location || (window.location && !window.location.search) || (window.location && window.location.search && window.location.search === ''))) {
                if (process.env.REACT_APP_SSO_REDIRECT && process.env.REACT_APP_SSO_REDIRECT !== '') {
                    // window.localStorage.removeItem('user');
                    // window.localStorage.clear();
                    //window.location = process.env.REACT_APP_SSO_REDIRECT
                }
            } else if (!invalid && (!user || user == null) && window.location && window.location.search) {

                // const data = window.location.search.split('token=');
                if (data && Array.isArray(data) && data.length === 2) {
                    const token = data[1];
                    await this.props.authStore.getUserByToken(token).then(async user => {
                        if (user && user.id) {
                            await this.props.authStore
                                .autenticar(user)
                            // .then(x => window.location = "/");
                            //http://localhost:3000/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MTQsImlkX3VzdWFyaW8iOjE0LCJpZF9jbGllbnRlIjoxLCJpZERvQ2xpZW50ZSI6MSwiY2xpZW50ZSI6IjEiLCJub21lIjoicm9iZXJ0by5saW1hIiwiZW1haWwiOiJmZXJuYW5kYS5wb3J0b0BzcHJlYWQuY29tLmJyIiwidXN1YXJpbyI6InJvYmVydG8ubGltYSIsImlkX3Blcm1pc3NhbyI6MSwiaWRQZXJtaXNzYW8iOjEsImNvZGlnb1Blcm1pc3NhbyI6MSwibm9tZVBlcm1pc3NhbyI6IkFETUlOSVNUUkFET1IiLCJkZXBhcnRhbWVudG8iOjE5LCJzaXR1YWNhbyI6MSwiaWF0IjoxNjE1NDg3MjUxOTE4LCJleHAiOjE2MTU2MDI0NTE5MTh9.sfqZ6TeWbgplYEJs3wCG5Bi3ZSL9WQVtXXe1JcqKBQc

                            //window.location="/";
                            // window.location = '/';
                            // } else {
                            //     window.location = "/?invalid=1";
                        }
                        return user;
                        // else if (!invalid && process.env.REACT_APP_SSO_REDIRECT && process.env.REACT_APP_SSO_REDIRECT !== '') {
                        //     window.localStorage.removeItem('user');
                        //     window.location = process.env.REACT_APP_SSO_REDIRECT
                        // }
                        // } else {
                        //     window.location = "/?invalid=1";
                    })
                }
            }
        }

        // let idDoCliente = null;
        // if (user !== null) {
        //     idDoCliente = user.idDoCliente;
        // }
        // this.setState({ idDoCliente: idDoCliente });
        // this.setState({ id_cliente: idDoCliente });

    }

    render() {

        const { open } = this.props.modalStore
        const { authenticated } = this.props.authStore

        return (
            <BrowserRouter>
                <Fragment>
                    <Alertas />
                    {!authenticated ?
                        <RouterLogin />
                        :
                        <Fragment>
                            <Menu />;
                            <Sidebar />
                            <Routes />
                        </Fragment>
                    }
                    {open && <div className="modal-backdrop fade show d-print-none"></div>}
                </Fragment>
            </BrowserRouter>
        )
    }
}

export default App;
