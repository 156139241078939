import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import Dashboard from '../dashboard'
// import Dashboard from '../dashboard2'

export default () =>
    <Fragment>
        <Switch>
            <Route exact path="/" component={Dashboard} />
        </Switch>
    </Fragment>
