import React, { Component } from 'react'
import { get } from '../../../services/http'
import moment from 'moment-timezone';
import 'moment/locale/pt-br'
import Slider from 'react-slick';
import Loading from '../../../componentes/loading'
import Accordion from '../../../componentes/accordion'

moment.tz.setDefault('UTC');

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

moment.locale('pt-BR')
class Tramitacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sliderSettings,
      tramitacoes: [],
      siglaAtual: '',
      momentosCriticos: {},
      designacaoDoRelator: {},
      prazoEmenda: {},
      parecerRelator: {},
      deliberacao: {},

      timelineArray: [],
      dadosTimeline: [],
      atual: '',
      ultimoStatus: '',
      comissaoAtiva: '',
    }
  }

  async componentDidMount() {
    this.setState({ proposicaoId: this.props.idProposicao })
    this.obterDadosTimeline()
  }

  async obterDadosTimeline() {
    await get(`/timeline/${this.props.idProposicao}`)
    .then(res => {
      // console.log(res)
      const timeline = res
      var momentoAtual = timeline.length > 0 ? timeline.slice(-1)[0] : []
      var ultimoStatus = momentoAtual.momentos_criticos !== undefined && momentoAtual.momentos_criticos.length > 0 ? momentoAtual.momentos_criticos.slice(-1)[0].data : null
      const sliderProps = {
        slidesToShow: res.length,
        slidesToScroll: res.length,
        initialSlide: timeline.length > 0 ? timeline.slice(-1)[0] : []
      }
      this.setState({ sliderSettings: { ...sliderProps } })
      this.setState({timelineArray: res, dadosTimeline: momentoAtual.momentos_criticos, atual: momentoAtual.comissao, ultimoStatus: ultimoStatus, comissaoAtiva: momentoAtual.comissao, loading: false})
    })

  }

  obterMomentosCriticos(item) {
    this.setState({dadosTimeline: item.momentos_criticos, comissaoAtiva: item.comissao})
  }
  abreMomentoCritico(abre){
    this.setState({
      abrir:abre
    })
  }
  render() {
    return (
      this.state.loading ? <Loading /> : 

      <div>
        {this.state.timelineArray.length === 0 ?

        <div className="container-fluid text-center bg-white p-4">
          <h4 className="mt-2 mb-0 Client-titulo">Ainda não existem momentos críticos</h4>
        </div>  
        :

        <div>
          <div className="card-timeline d-none d-sm-block">
              <Slider {...this.state.sliderSettings}>
                {this.state.timelineArray !== undefined && Array.isArray(this.state.timelineArray) && this.state.timelineArray.length > 0 ? this.state.timelineArray.map((item, index) => {
                  const ehFinal = item.momentos_criticos.slice(-1)[0].final
                  return (
                    <div className={`retirar-border d-flex ${item.comissao === this.state.atual ? 'comissao-ativo' : 'comissao-inativo'}`} onClick={() => this.obterMomentosCriticos(item)} key={`comissoes-${index}`}>
                    <a href className={`mb-3 d-flex align-items-center active justify-content-center ${this.state.comissaoAtiva === item.comissao ? 'comissoes-timeline-active' : 'comissoes-timeline'}`}>
                        <div className={`ml-2 mb-2 mr-2 circulo-timeline ${item.comissao === this.state.atual && ehFinal === false ? 'circulo-timeline-atual' : 'circulo-timeline-concluido'} `}>
                          <div className="ml-2">
                          {index + 1}
                          </div>
                        </div>
                        <span className="nome-comissao sigla inativo mr-5">{item.comissao}</span>
                      </a>
                    </div>
                  )
                }
                
                ) : null}
              </Slider>
            
            {this.state.dadosTimeline !== undefined && Array.isArray(this.state.dadosTimeline) && this.state.dadosTimeline.length > 0 ? this.state.dadosTimeline.map((item, index) => {

              return (
                <div className="div-pai" key={index}>
                  <div className="row"> 
                    <div className="col-12 d-flex">

                      {item.data !== this.state.ultimoStatus || item.final === true ?
                      <div className="timeline-image">
                        <img className="" src={require('../../../design/assets/images/timeline-ativo.svg')} alt="" />
                      </div>
                      :
                      <div className="timeline-image">
                        <img className="" src={require('../../../design/assets/images/timeline-pendente.svg')} alt="" />
                      </div>
                      }

                      <div className={`ml-3 ${item.data !== this.state.ultimoStatus ? 'status-concluido-timeline' : 'status-atual-timeline'}`}>{item.status}</div>
                    </div>
                    
                    <div className="col-1">
                      <div className={this.state.dadosTimeline.slice(-1)[0].status === item.status ? '' :  'linha-timeline'}></div>
                    </div>
                    <div className="col-11">
                      <div className="div-correcao p-5">

                        <div className="acontecimento-timeline">
                          <div className="data-timeline mb-2">{moment(item.data).format('DD/MM/YYYY')}</div>
                          <div className="descricao-timeline">{item.descricao}</div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              )
            }) : null}

          </div>

          <div className="d-block d-sm-none card-timeline">
            {
              this.state.timelineArray !== undefined && 
              Array.isArray(this.state.timelineArray) && 
              this.state.timelineArray.length > 0 ? 
              this.state.timelineArray.map((item, index) =>
              <div key={index} className="acordionsBordas">
                <Accordion 
                      mb={'mb-0'}
                      p={'p-0'}
                      mt={'mt-0'}
                      onClick={() => this.abreMomentoCritico(true)}
                      titulo={
                              <a href className={`d-sm-none d-flex align-items-center
                              active justify-content-center 
                              ${this.state.comissaoAtiva === item.comissao ?
                                'comissoes-timeline-active' : 'comissoes-timeline'}`}
                              >
                              <div className={`ml-2 mb-2 mr-2 circulo-timeline ${item.comissao === this.state.atual ? 'circulo-timeline-atual' : 'circulo-timeline-concluido'} `}>
                                <div className="ml-2">
                                {index + 1}
                                </div>
                              </div>
                              <span className="nome-comissao sigla inativo mr-5">{item.comissao}</span>
                            </a>
                      } 
                      aberto={this.state.showAcaoInfluencia}
                  >
                  {this.state.dadosTimeline !== undefined && 
                  Array.isArray(this.state.dadosTimeline) && 
                  this.state.dadosTimeline.length > 0 ? 
                  this.state.dadosTimeline.map((item, index) => {
                    return (
                      <div className="div-pai" key={index}>
                        <div className="row"> 
                          <div className="col-12 d-flex">

                            {item.data !== this.state.ultimoStatus ?
                            <div className="timeline-image">
                              <img className="" src={require('../../../design/assets/images/timeline-ativo.svg')} alt="" />
                            </div>
                            :
                            <div className="timeline-image">
                              <img className="" src={require('../../../design/assets/images/timeline-pendente.svg')} alt="" />
                            </div>
                            }

                            <div className={`ml-3 ${item.data !== this.state.ultimoStatus ? 'status-concluido-timeline' : 'status-atual-timeline'}`}>{item.status}</div>
                          </div>
                          
                          <div className="col-1">
                            <div className={this.state.dadosTimeline.slice(-1)[0].status === item.status ? '' :  'linha-timeline'}></div>
                          </div>
                          <div className="col-11">
                            <div className="div-correcao p-5">

                              <div className="acontecimento-timeline">
                                <div className="data-timeline mb-2">{moment(item.data).format('DD/MM/YYYY')}</div>
                                <div className="descricao-timeline">{item.descricao}</div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }) : null}
                </Accordion> 
              </div>                         
              ): null 
            }  
          </div>
        </div>
      }
      </div>
      
    )
  }
}
export default props => <Tramitacao {...props} semPaginacao={true} />