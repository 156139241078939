import React, { Component, Fragment } from 'react'
// import ParlamentarImagem from './items/parlamentarImagem'
// import isList from '../../componentes/hoc/isList';
import Loading from '../../componentes/loading'
import { post, get } from '../../services/http'
// import { firstBy } from "thenby";
// import _ from "lodash"
// import DropdownSelect from '../../componentes/react-dropdown-select'
// import { estados } from '../../enums/estados';
// import { partidos } from '../../enums/partidos';
// import Accordion from '../../componentes/accordion'
// import alertaStore from "../../stores/alertaStore";
import Pagination from "react-js-pagination";
import { NavLink } from 'react-router-dom';
import GraficoQualificacaoCamara from './graficoQualificacaoCamaraMobile'
import './graficoQualificacaoCamaraMobile'
import GraficoQualificacaoSenado from './graficoQualificacaoSenadoMobile'
import FiltroParlamentar from './filtroParlamentarMobile';
import { inject, observer } from 'mobx-react';
import alertaStore from "../../stores/alertaStore";
import FormatInput from '../../componentes/forms/inputs/formatInput'
import ClientCheckboxWithSelect from '../../componentes/forms/basic/clientSelectWithSearch'
// import { get } from '../../services/local';

@inject('authStore')
@observer

class Parlamentares extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            nome: '',
            seguindo: [],
            todos: [],
            temMais: true,
            paginaAtual: 1,
            limite: 48,
            quantidade: 100,
            parlamentares: [],
            busca: '',
            filtro: [],
            grafico: this.props.authStore.ehEsforcoColaborativo === true ? false : true,
            dadosGrafico: [],
            pagination: true,
            qualificacaoParametro: '',
            tratamentoParamentro: '',
            mostrarBotaoFiltro: false,
            ambito1: [],
            ambito2: [],
            ambito3: [],
            estado: [],
            partido: [],
            idade: [],
            profissao: [],
            escolaridade: [],
            visualizar: { value: 0, label: 'Selecione' },
            deputadoConvergente: "",
            divergenteCG: "",
            naoClassificado: "",
            neutroCG: "",
            convergenteSG: "",
            divergenteSG: "",
            naoClassificadoSG: "",
            neutroSG: ""

        }

    }


    async componentWillMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            const data = { value: 1, label: 'Todos' }
            this.selecionarVisualizarEsforcoColaborativo(data)
        }
        await this.getData()
    }

    async getData() {
        return await Promise.all([
            this.obterTodosParlamentares(1),
            this.obterDadosGraficosParlamentares(),
            this.buscarAmbito2(),
            this.buscarAmbito1(),
            this.buscarAmbito3(),
            this.buscarDadosFiltro()
        ])
            .then(r => {
                this.setState({ mostrarBotaoFiltro: true, loading: false })
            })
            .catch(e => e)
    }

    async obterTodosParlamentares(pagina, visualizar, path) {

        // console.log('path ===>',path)
        if (visualizar !== undefined) {
            var minhaVisualizacao = ''
            switch (visualizar.value) {
                case 0:
                    minhaVisualizacao = 'sem busca'
                    break;
                case 1:
                    minhaVisualizacao = ''
                    break;
                case 2:
                    minhaVisualizacao = 'deputad'
                    break;
                case 3:
                    minhaVisualizacao = 'senad'
                    break;
                default:
                    minhaVisualizacao = 'sem busca';
            }
            // this.setState({ busca: minhaVisualizacao })
        }
        const filtro = JSON.parse(window.localStorage.getItem('filtroParlamentar'))
        //console.log('FILTRO ===>',filtro)
        if (filtro !== null || visualizar !== undefined) {
            this.setState({ grafico: false, loading: true })
        }
        if ((this.state.qualificacaoParametro === '' && this.state.tratamentoParamentro === '') || path === 'visualizar') {
            if (filtro !== null || this.state.busca !== '' || visualizar !== undefined) {
                const data = {
                    pagina: pagina,
                    limite: this.state.limite,
                    ultimo: pagina === 1 ? 0 : (pagina - 1) * this.state.limite,
                    busca: path === 'busca' ? this.state.busca : '',
                    filtro: filtro,
                    visualizar: path === 'busca' ? 'sem busca' : minhaVisualizacao
                }

                await post("parlamentar/obter-todos", data)
                    .then(response => {
                        this.setState({ parlamentares: response.parlamentares, quantidade: response.total.count, loading: false })
                    })
            }
        } else {
            this.filtroQualificacao('x', this.state.qualificacaoParametro, this.state.tratamentoParamentro)
        }
    }

    async obterDadosGraficosParlamentares() {
        const user = JSON.parse(window.localStorage.getItem('user'))
        const id_cliente = user.idDoCliente
        console.log(user)
        await get(`parlamentar/dados-grafico/dashboard/${id_cliente}`)
            .then(response => {
                console.log(response)
                this.setState({ dadosGrafico: response })
            }).catch(err => err)
    }


    // async getInfoGraphics(){
    // await get(`parlamentar/dados-grafico/dashboard/${id_cliente}`)
    //         .then(response => {
    //             const dados = response || []
    //             console.log("dados::::::", dados)
    //             // this.setState({
    //             //     propsNumeroProposicoesAtiva: dados.numeroProposicoesAtiva,
    //             // })
    //         })
    //         .catch(err => err)
    //     }

    async buscarAmbito1() {
        await get('ambito/1/cliente')
            .then(response => {
                // console.log('AMBITO 1 ===>',response)
                var novoAmbito1 = []
                response !== undefined && Array.isArray(response) && response.length > 0 &&
                    response.map(item => {
                        var ambito1 = { value: item.sigla, label: item.sigla + ' - ' + item.descricao }
                        novoAmbito1.push(ambito1)
                        return this.setState({
                            ambito1: novoAmbito1
                        })
                    })
            })
            .catch(e => alertaStore.erro("Ops... algo deu errado dos âmbitos de senado: " + e && e.message ? e.message : e))
    }

    async buscarAmbito2() {
        await get('ambito/2/cliente')
            .then(response => {
                // console.log('AMBITO 2 ===>',response)
                if (response.message !== 'Internal server error') {
                    var novoAmbito2 = []
                    response !== undefined && Array.isArray(response) && response.length > 0 &&
                        response.map(item => {
                            var ambito2 = { value: item.sigla, label: item.sigla + ' - ' + item.descricao }
                            novoAmbito2.push(ambito2)
                            return this.setState({
                                ambito2: novoAmbito2
                            })
                        })
                } else {
                    console.log('Erro na busca do Ambito da Câmara dos Deputados')
                }
            })
            .catch(e => alertaStore.erro("Ops... algo deu errado na busca dos âmbitos da câmara: " + e && e.message ? e.message : e))
    }

    async buscarAmbito3() {
        await get('ambito/3/cliente')
            .then(response => {

                var novoAmbito3 = []
                response !== undefined && Array.isArray(response) && response.length > 0 &&
                    response.map(item => {
                        var ambito3 = { value: item.sigla, label: item.sigla + ' - ' + item.descricao }
                        novoAmbito3.push(ambito3)
                        return this.setState({
                            ambito3: novoAmbito3
                        })
                    })
            })
            .catch(e => alertaStore.erro("Ops... algo deu errado na busca dos âmbitos do congresso: " + e && e.message ? e.message : e))
    }

    buscarDadosFiltro = async () => {
        const user = JSON.parse(window.localStorage.getItem('user'))
        const id_cliente = user.idDoCliente
        await get(`parlamentar/dados-filtro/${id_cliente}`)
            .then(response => {
                this.setState({ estado: response.estado, partido: response.partido, idade: response.idade, profissao: response.profissao, escolaridade: response.escolaridade })
            }).catch(err => err)
    }

    carregarPaginaNova = async (pagina, path) => {
        var newPath = path === undefined && this.state.busca !== '' && this.state.busca !== 'deputad' && this.state.busca !== 'senad' ? 'busca' : path
        this.setState({ paginaAtual: pagina, loading: true, grafico: false })
        this.obterTodosParlamentares(pagina, this.state.visualizar, newPath)
    }

    seguirParlamnetar = async (event, item, index) => {
        const data = {
            item: item
        }
        await post("parlamentar/seguir", data)
            .then(response => {

                const path = this.state.busca !== '' && this.state.busca !== 'deputad' && this.state.busca !== 'senad' ? 'busca' : undefined
                if (this.state.qualificacaoParametro === '' && this.state.tratamentoParamentro === '') {
                    this.obterTodosParlamentares(this.state.paginaAtual, this.state.visualizar, path)
                } else {
                    this.filtroQualificacao('x', this.state.qualificacaoParametro, this.state.tratamentoParamentro)
                }
            })
            .catch(err => err)
    }

    naoSeguirParlamnetar = async (event, item, index) => {
        const data = {
            item: item
        }
        await post("parlamentar/nao-seguir", data)
            .then(response => {
                const path = this.state.busca !== '' && this.state.busca !== 'deputad' && this.state.busca !== 'senad' ? 'busca' : undefined
                if (this.state.qualificacaoParametro === '' && this.state.tratamentoParamentro === '') {
                    this.obterTodosParlamentares(this.state.paginaAtual, this.state.visualizar, path)
                } else {
                    this.filtroQualificacao('x', this.state.qualificacaoParametro, this.state.tratamentoParamentro)
                }
            })
            .catch(err => err)
    }


    aplicarFiltroInput(event) {
        this.setState({ busca: event.target.value, qualificacaoParametro: '', tratamentoParamentro: '' })
    }

    limparLocaStorage() {
        window.localStorage.removeItem('filtroParlamentar')
        window.location.href = "/parlamentar"
    }

    limparFiltroLocaStorage() {
        window.localStorage.removeItem('filtroParlamentar')
        window.location.href = "/parlamentar"
        //window.location.reload()
    }

    limparParlamentar() {
        window.localStorage.removeItem('filtroParlamentar')

    }

    limparFiltroVisualizar(e, grafico) {
        this.setState({ grafico: grafico, visualizar: { value: 0, label: 'Selecione' } })
    }

    async filtroQualificacao(event, qualificacao, tratamento) {
        this.setState({ qualificacaoParametro: qualificacao, tratamentoParamentro: tratamento })
        await get(`parlamentar/obter/${qualificacao}/${tratamento}`)
            .then(response => {
                window.location.href = "#inicio-pagina"
                this.setState({ parlamentares: response, grafico: false, pagination: false })
            }).catch(err => err)
    }

    async selecionarVisualizar(newValue) {
        window.localStorage.removeItem('filtroParlamentar')
        this.setState({ visualizar: newValue, qualificacaoParametro: '', tratamentoParamentro: '' })
        this.obterTodosParlamentares(1, newValue, 'visualizar')
    }

    async selecionarVisualizarEsforcoColaborativo(newValue) {
        this.setState({ visualizar: newValue })
        this.obterTodosParlamentares(1, newValue)
    }

    render() {

        const vizualizar = [
            { value: 1, label: 'Todos' },
            { value: 2, label: 'Deputados' },
            { value: 3, label: 'Senadores' },
        ]
        const filtroLocalStorage = JSON.parse(window.localStorage.getItem('filtroParlamentar'))
        const parlamentares = this.state.parlamentares
        const tamanhoTela = window.innerWidth

        return this.state.loading ? <Loading /> :
            <Fragment>

                {tamanhoTela < 600 ?

                    <div>


                        {/* >>>>>>>>>>>>>>>>>>>>>>>>  MOBILE CODE <<<<<<<<<<<<<<<<<<<<<<<<<< */}



                        <nav>
                            {/* <ol className="breadcrumb mb-3 p-2">
                            <li className="breadcrumb-item">
                                <a className="active" href="/"><i className="icon-arrow-straight-back-small align-baseline fs-14"></i></a>
                            </li>
                            <li className="breadcrumb-item"><a className="text-capitalize active" href="/parlamentar">parlamentares</a></li>
                        </ol> */}

                            <div id="inicio-pagina" className=" bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                                <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                                    PARLAMENTAR
                                </p>
                            </div>
                        </nav>

                        <div className="container-fluid m-0 mb-4">

                            <div className="row">
                                <div className="col-10 mt-3">
                                    <div className="form-control icon-input">
                                        <input type="text" autoComplete="off" id="buscarParlamentar"
                                            value={this.state.descricao}
                                            onKeyUp={(event) => { if (event.which === 13) { this.limparLocaStorage(); this.carregarPaginaNova(1, 'busca'); this.setState({ grafico: false }) } }}
                                            onChange={(event) => this.aplicarFiltroInput(event)} placeholder="Pesquisar" aria-label="Pesquisar" aria-describedby="search" />
                                        <span className="icon" onClick={() => { this.limparLocaStorage(); this.carregarPaginaNova(1, 'busca'); this.setState({ grafico: false }) }}><i className="icon-search-small"></i></span>
                                    </div>
                                </div>

                                <div className=" col-2 mt-3">

                                    <FiltroParlamentar
                                        ambito1={this.state.ambito1}
                                        ambito2={this.state.ambito2}
                                        ambito3={this.state.ambito3}
                                        estado={this.state.estado}
                                        partido={this.state.partido}
                                        idade={this.state.idade}
                                        profissao={this.state.profissao}
                                        escolaridade={this.state.escolaridade}
                                        busca={this.state.busca}
                                    />

                                </div>
                                <div className="col-lg-1 d-none d-lg-block"></div>
                                <div className="col-lg-2 col-md-3 margemMobile">
                                    <FormatInput
                                        className="Client-select"
                                        label="Visualizar"
                                    >
                                        <ClientCheckboxWithSelect
                                            value={'teste aqui'}
                                            onChange={newValue => this.selecionarVisualizar(newValue)}
                                            options={
                                                vizualizar !== undefined &&
                                                    Array.isArray(vizualizar) && vizualizar.length > 0 ?
                                                    vizualizar.map(item =>
                                                        ({ value: item.value, label: item.label })) : null}
                                            placeholder={this.state.visualizar.label}
                                        />

                                    </FormatInput>
                                </div>
                            </div>

                            <hr />

                            {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                filtroLocalStorage === null ? null :
                                    <span onClick={this.limparFiltroLocaStorage} style={{ color: 'var(--linkText)', borderBottom: '1px solid var(--linkText)', fontSize: '0.8rem', cursor: 'pointer' }}>Limpar Filtro</span>
                            }

                            {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                this.state.visualizar.label === 'Selecione' ? null :
                                    <span onClick={e => this.limparFiltroVisualizar(e, true)} style={{ color: 'var(--linkText)', borderBottom: '1px solid var(--linkText)', fontSize: '0.8rem', cursor: 'pointer' }}>Limpar Filtro</span>
                            }


                            {this.state.grafico === true ?
                                // PAGINA GRAFICOS PARLAMENTAR
                                <div className="row p-2">
                                    <div className="col-12 card-grafico-parlamentar">
                                        <div className="row">
                                            <div className="col-12 text-center mb-4">
                                                <span className="parlamentar-titulo-grafico-preto">Qualificação / </span>
                                                <span className="parlamentar-titulo-grafico-vermelho"> Câmara Geral</span>
                                            </div>

                                            <div className="col-12">
                                                <GraficoQualificacaoCamara
                                                    dados={this.state.dadosGrafico}
                                                />
                                            </div>

                                            <div className="col-12 text-left pl-4 mt-5">
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-exito mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Convergente', 'deputad')} className="link-indice-dashboard-parlamentar d-inline">Convergente</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.deputadoConvergente}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-sem-exito mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Divergente', 'deputad')} className="link-indice-dashboard-parlamentar d-inline">Divergente</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.deputadoDivergente}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-exito-parcial mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Não Classificado', 'deputad')} className="link-indice-dashboard-parlamentar d-inline">Não Classificado</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.deputadoNaoClassificado}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-sem-info mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Neutro', 'deputad')} className="link-indice-dashboard-parlamentar d-inline">Neutro</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.deputadoNeutro}</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-12 card-grafico-parlamentar mt-5">
                                        <div className="row">
                                            <div className="col-12 text-center mb-4">
                                                <span className="parlamentar-titulo-grafico-preto">Qualificação / </span>
                                                <span className="parlamentar-titulo-grafico-vermelho"> Senado Geral</span>
                                            </div>

                                            <div className="col-12">
                                                <GraficoQualificacaoSenado
                                                    dados={this.state.dadosGrafico}
                                                />
                                            </div>

                                            <div className="col-12 text-left pl-4 mt-5">
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-exito mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Convergente', 'senador')} className="link-indice-dashboard-parlamentar d-inline">Convergente</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.senadorConvergente}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-sem-exito mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Divergente', 'senador')} className="link-indice-dashboard-parlamentar d-inline">Divergente</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.senadorDivergente}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-exito-parcial mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Não Classificado', 'senador')} className="link-indice-dashboard-parlamentar d-inline">Não Classificado</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.senadorNaoClassificado}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-sem-info mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Neutro', 'senador')} className="link-indice-dashboard-parlamentar d-inline">Neutro</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.senadorNeutro}</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                :
                                // PAGINA LISTAR PARLAMENTAR
                                <div className="row">
                                    {parlamentares !== undefined && parlamentares.length > 0
                                        && Array.isArray(parlamentares) ? parlamentares.map((item, index) => {
                                            const user = JSON.parse(window.localStorage.getItem('user'))
                                            const id_cliente = user.idDoCliente
                                            var cssFotoQualificacao = ''
                                            switch (item.qualificacao) {
                                                case 'Convergente':
                                                    cssFotoQualificacao = 'parlamentar-foto-convergente';
                                                    break;
                                                case 'Divergente':
                                                    cssFotoQualificacao = 'parlamentar-foto-divergente';
                                                    break;
                                                case 'Não Classificado':
                                                    cssFotoQualificacao = 'parlamentar-foto-nao-classificado';
                                                    break;
                                                case 'Neutro':
                                                    cssFotoQualificacao = 'parlamentar-foto-neutro';
                                                    break;
                                                default:
                                                    cssFotoQualificacao = 'parlamentar-foto-default';
                                            }
                                            const linkUrl = this.props.authStore.ehEsforcoColaborativo === true ? `/parlamentar/${item.id}/proposicao` : `/parlamentar/${item.id}/acao-influencia`
                                            return (
                                                // ULTIMA ATUALIZACAO - PROBLEMA COM ID DE CLIENTE
                                                item.id_cliente === null || item.id_cliente === id_cliente ?
                                                    <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                                                        <div className="parlamentar-card text-center">
                                                            {/* <div className="parlamentar-foto-default mt-3"> */}
                                                            <NavLink to={linkUrl}>
                                                                {this.props.authStore.ehEsforcoColaborativo === true ?
                                                                    <div className={`mt-3`}>
                                                                        <img alt="" src={item.urlFoto ? item.urlFoto : require('../../design/assets/images/avatar-parlamentar.png')} className="parlamentar-foto-config" />
                                                                    </div>
                                                                    :

                                                                    <div className={`${cssFotoQualificacao} mt-3`}>
                                                                        <img alt="" src={item.urlFoto ? item.urlFoto : require('../../design/assets/images/avatar-parlamentar.png')} className="parlamentar-foto-config" />
                                                                    </div>
                                                                }
                                                            </NavLink>
                                                            <div className="parlamentar-nome mt-3 mb-2">
                                                                {item.nome.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); })}
                                                            </div>
                                                            <div className="parlamentar-tratamento mb-2">{item.tratamento}</div>
                                                            <div className="parlamentar-partido mb-4">{item.siglaPartido}</div>
                                                            <div>
                                                                {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                                                    item.seguindo === false || item.seguindo === null ?
                                                                        <button onClick={event => this.seguirParlamnetar(event, item, index)} className="parlamentar-botao-seguir mb-2" style={{ borderRadius: "7px" }} disabled={this.props.authStore.ehVisualizador === true ? true : false}>Seguir</button>
                                                                        :
                                                                        <button onClick={event => this.naoSeguirParlamnetar(event, item, index)} className="parlamentar-botao-seguindo mb-2" style={{ borderRadius: "7px" }} disabled={this.props.authStore.ehVisualizador === true ? true : false}>Seguindo</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            )
                                        }) : null}
                                </div>
                            }

                            {this.state.pagination === false ? null :
                                this.state.grafico === true ? null :
                                    this.state.quantidade <= this.state.limite ? null :
                                        <div>
                                            <div className="pagination mt-3"> Exibindo resultados {(this.state.paginaAtual - 1) * this.state.limite} - {this.state.paginaAtual * this.state.limite} de um total de {this.state.quantidade}</div>
                                            <Pagination
                                                activePage={this.state.paginaAtual}
                                                itemsCountPerPage={this.state.limite}
                                                totalItemsCount={this.state.quantidade}
                                                pageRangeDisplayed={10}
                                                hideFirstLastPages={true}
                                                onChange={this.carregarPaginaNova}
                                                prevPageText={<div className="prev-page"><img src={require('../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                                                nextPageText={<div className="next-page"><img src={require('../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                                            />
                                        </div>
                            }

                        </div>
                    </div>

                    :

                    <div>


                        {/* >>>>>>>>>>>>>>>>>>>>>>>>  DESK CODE <<<<<<<<<<<<<<<<<<<<<<<<<< */}


                        <nav>
                            {/* <ol className="breadcrumb mb-3 p-2">
                            <li className="breadcrumb-item">
                                <a className="active" href="/"><i className="icon-arrow-straight-back-small align-baseline fs-14"></i></a>
                            </li>
                            <li className="breadcrumb-item"><a className="text-capitalize active" href="/parlamentar">parlamentares</a></li>
                        </ol> */}

                            <div id="inicio-pagina" className=" bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                                <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                                    PARLAMENTAR
                                </p>
                            </div>
                        </nav>

                        <div className="container-fluid m-0 mb-4">

                            <div className="row">
                                <div className="col-6 mt-3">
                                    <div className="form-control icon-input">
                                        <input type="text" autoComplete="off" id="buscarParlamentar"
                                            value={this.state.descricao}
                                            onKeyUp={(event) => { if (event.which === 13) { this.limparParlamentar(); this.carregarPaginaNova(1, 'busca'); this.setState({ grafico: false }) } }}
                                            onChange={(event) => this.aplicarFiltroInput(event)} placeholder="Pesquisar" aria-label="Pesquisar" aria-describedby="search" />
                                        <span className="icon" onClick={() => { this.limparParlamentar(); this.carregarPaginaNova(1, 'busca'); this.setState({ grafico: false }) }}><i className="icon-search-small"></i></span>
                                    </div>
                                </div>

                                <div className="col-md-3 col-6 mt-3">

                                    <FiltroParlamentar
                                        ambito1={this.state.ambito1}
                                        ambito2={this.state.ambito2}
                                        ambito3={this.state.ambito3}
                                        estado={this.state.estado}
                                        partido={this.state.partido}
                                        idade={this.state.idade}
                                        profissao={this.state.profissao}
                                        escolaridade={this.state.escolaridade}
                                        busca={this.state.busca}
                                    />

                                </div>
                                <div className="col-lg-1 d-none d-lg-block"></div>
                                <div className="col-lg-2 col-md-3 margemMobile">
                                    <FormatInput
                                        className="Client-select"
                                        label="Visualizar"
                                    >
                                        <ClientCheckboxWithSelect
                                            value={'teste aqui'}
                                            onChange={newValue => this.selecionarVisualizar(newValue)}
                                            options={
                                                vizualizar !== undefined &&
                                                    Array.isArray(vizualizar) && vizualizar.length > 0 ?
                                                    vizualizar.map(item =>
                                                        ({ value: item.value, label: item.label })) : null}
                                            placeholder={this.state.visualizar.label}
                                        />

                                    </FormatInput>
                                </div>
                            </div>

                            <hr />




                            {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                filtroLocalStorage === null ?
                                    <span onClick={e => this.limparFiltroVisualizar(e, true)} className="limpar-filtro">Limpar Filtro</span>
                                    :
                                    <span onClick={this.limparFiltroLocaStorage} style={{ color: 'var(--linkText)', borderBottom: '1px solid var(--linkText)', fontSize: '0.8rem', cursor: 'pointer' }}>Limpar Filtro</span>
                            }




                            {this.state.grafico === true ?
                                // PAGINA GRAFICOS PARLAMENTAR
                                <div className="row p-md-5">
                                    <div className="col-12 card-grafico-parlamentar">
                                        <div className="row justify-content-md-center">
                                            <div className="col-12 text-center mb-4">
                                                <span className="parlamentar-titulo-grafico-preto">Qualificação / </span>
                                                <span className="parlamentar-titulo-grafico-vermelho"> Câmara Geral</span>
                                            </div>

                                            <div className="col-sm-8-md-6">
                                                <GraficoQualificacaoCamara
                                                    dados={this.state.dadosGrafico}
                                                />
                                            </div>

                                            <div className="col-sm-4-md-6 mt-5">
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-exito mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Convergente', 'deputad')} className="link-indice-dashboard-parlamentar d-inline">Convergente</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.deputadoConvergente}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-sem-exito mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Divergente', 'deputad')} className="link-indice-dashboard-parlamentar d-inline">Divergente</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.deputadoDivergente}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-exito-parcial mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Não Classificado', 'deputad')} className="link-indice-dashboard-parlamentar d-inline">Não Classificado</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.deputadoNaoClassificado}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-sem-info mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Neutro', 'deputad')} className="link-indice-dashboard-parlamentar d-inline">Neutro</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.deputadoNeutro}</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-12 card-grafico-parlamentar mt-5">
                                        <div className="row justify-content-md-center">
                                            <div className="col-12 text-center mb-4">
                                                <span className="parlamentar-titulo-grafico-preto">Qualificação / </span>
                                                <span className="parlamentar-titulo-grafico-vermelho"> Senado Geral</span>
                                            </div>

                                            <div className="col-sm-8-md-6">
                                                <GraficoQualificacaoSenado
                                                    dados={this.state.dadosGrafico}
                                                />
                                            </div>

                                            <div className="col-sm-4-md-6 mt-5">
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-exito mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Convergente', 'senador')} className="link-indice-dashboard-parlamentar d-inline">Convergente</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.senadorConvergente}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-sem-exito mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Divergente', 'senador')} className="link-indice-dashboard-parlamentar d-inline">Divergente</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.senadorDivergente}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-exito-parcial mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Não Classificado', 'senador')} className="link-indice-dashboard-parlamentar d-inline">Não Classificado</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.senadorNaoClassificado}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="ml-2">
                                                        <div className="indice-sem-info mt-1"></div>
                                                    </div>
                                                    <div className="ml-1 indice-descricao">
                                                        <div onClick={event => this.filtroQualificacao(event, 'Neutro', 'senador')} className="link-indice-dashboard-parlamentar d-inline">Neutro</div>
                                                    </div>
                                                    <div className="ml-2">
                                                        <span>{this.state.dadosGrafico.senadorNeutro}</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                :
                                // PAGINA LISTAR PARLAMENTAR
                                <div className="row">
                                    {parlamentares !== undefined && parlamentares.length > 0
                                        && Array.isArray(parlamentares) ? parlamentares.map((item, index) => {
                                            const user = JSON.parse(window.localStorage.getItem('user'))
                                            const id_cliente = user.idDoCliente
                                            var cssFotoQualificacao = ''
                                            switch (item.qualificacao) {
                                                case 'Convergente':
                                                    cssFotoQualificacao = 'parlamentar-foto-convergente';
                                                    break;
                                                case 'Divergente':
                                                    cssFotoQualificacao = 'parlamentar-foto-divergente';
                                                    break;
                                                case 'Não Classificado':
                                                    cssFotoQualificacao = 'parlamentar-foto-nao-classificado';
                                                    break;
                                                case 'Neutro':
                                                    cssFotoQualificacao = 'parlamentar-foto-neutro';
                                                    break;
                                                default:
                                                    cssFotoQualificacao = 'parlamentar-foto-default';
                                            }
                                            const linkUrl = this.props.authStore.ehEsforcoColaborativo === true ? `/parlamentar/${item.id}/proposicao` : `/parlamentar/${item.id}/acao-influencia`
                                            return (
                                                // ULTIMA ATUALIZACAO - PROBLEMA COM ID DE CLIENTE
                                                item.id_cliente === null || item.id_cliente === id_cliente ?
                                                    <div key={index} className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
                                                        <div className="parlamentar-card text-center">
                                                            {/* <div className="parlamentar-foto-default mt-3"> */}
                                                            <NavLink to={linkUrl}>
                                                                {this.props.authStore.ehEsforcoColaborativo === true ?
                                                                    <div className={`mt-3`}>
                                                                        <img alt="" src={item.urlFoto ? item.urlFoto : require('../../design/assets/images/avatar-parlamentar.png')} className="parlamentar-foto-config" />
                                                                    </div>
                                                                    :

                                                                    <div className={`${cssFotoQualificacao} mt-3`}>
                                                                        <img alt="" src={item.urlFoto ? item.urlFoto : require('../../design/assets/images/avatar-parlamentar.png')} className="parlamentar-foto-config" />
                                                                    </div>
                                                                }
                                                            </NavLink>
                                                            <div className="parlamentar-nome mt-3 mb-2">
                                                                {item.nome.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); })}
                                                            </div>
                                                            <div className="parlamentar-tratamento mb-2">{item.tratamento}</div>
                                                            <div className="parlamentar-partido mb-4">{item.siglaPartido}</div>
                                                            <div>
                                                                {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                                                    item.seguindo === false || item.seguindo === null ?
                                                                        <button onClick={event => this.seguirParlamnetar(event, item, index)} className="parlamentar-botao-seguir mb-2" style={{ borderRadius: "7px" }} disabled={this.props.authStore.ehVisualizador === true ? true : false}>Seguir</button>
                                                                        :
                                                                        <button onClick={event => this.naoSeguirParlamnetar(event, item, index)} className="parlamentar-botao-seguindo mb-2" style={{ borderRadius: "7px" }} disabled={this.props.authStore.ehVisualizador === true ? true : false}>Seguindo</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            )
                                        }) : null}
                                </div>
                            }

                            {this.state.pagination === false ? null :
                                this.state.grafico === true ? null :
                                    this.state.quantidade <= this.state.limite ? null :
                                        <div>
                                            <div className="pagination mt-3"> Exibindo resultados {(this.state.paginaAtual - 1) * this.state.limite} - {this.state.paginaAtual * this.state.limite} de um total de {this.state.quantidade}</div>
                                            <Pagination
                                                activePage={this.state.paginaAtual}
                                                itemsCountPerPage={this.state.limite}
                                                totalItemsCount={this.state.quantidade}
                                                pageRangeDisplayed={10}
                                                hideFirstLastPages={true}
                                                onChange={this.carregarPaginaNova}
                                                prevPageText={<div className="prev-page"><img src={require('../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                                                nextPageText={<div className="next-page"><img src={require('../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                                            />
                                        </div>
                            }

                        </div>
                    </div>
                }
            </Fragment >
    }
}

export default props => <Parlamentares {...props} />