import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ListarProposicoes from './listarProposicoes'
import FiltrarProposicoes from './filtrarProposicoes'
import DetalharProposicoes from './detalharProposicao'

export default () =>
    <Switch>
        <Route exact path="/proposicao-estadual-municipal" component={ListarProposicoes} />
        <Route exact path="/proposicao-estadual-municipal/filtro" component={FiltrarProposicoes} />
        <Route exact path="/proposicao-estadual-municipal/:id" component={DetalharProposicoes} />
    </Switch>