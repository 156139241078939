import React, { Component } from 'react';
import Loading from '../../../componentes/loading';
import Grafico from '../graficos/graficoProposicoesAtivoInativo'
import moment from 'moment-timezone';
// import Pagination from "react-js-pagination";
import { get } from "../../../services/http"
import { inject, observer } from 'mobx-react'

moment.tz.setDefault('UTC');

@inject('authStore')
@observer

class ProposicaoAtivoInativo extends Component {
    state = {
        loading: true,
        dataInicio: '',

        tiposQuantidade: [],

        resultadoRelatorioAtivo: [],
        resultadoRelatorioInativo: [],

        paginaAtuaAtivo: 1,
        limiteAtivo: 3,
        quantidadeAtivo: 0,

        paginaAtuaInativo: 1,
        limiteInativo: 3,
        quantidadeInativo: 0,

        totalQuantidadeAtivo: 0,
        totalQuantidadeInativo: 0,

        dataIni: '',
        dataFim: '',
    }


    async componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        await this.getDataRelatorio(1)

        this.setState({
            dataInicio: moment(this.props.match.params.dtIni).format('DD/MM/YYYY'),
            dataFim: moment(this.props.match.params.dtFim).format('DD/MM/YYYY'),
        })
    }

    async getDataRelatorio(page) {
        const dtIni = this.props.match.params.dtIni
        const dtFim = this.props.match.params.dtFim
        await get(`/relatorio/proposicoes/ativas-inativas/${dtIni}/${dtFim}`)
            .then(res => {
                // console.log(res)
                const dados = res || []
                this.setState({
                    tiposQuantidade: dados.tabela,
                    totalQuantidadeAtivo: dados.grafico.totalAtivos.quantidade,
                    totalQuantidadeInativo: dados.grafico.totalInativos.quantidade,
                    loading: false
                })
            })
    }

    imprimir() {
        window.print()
    }


    render() {

        // const dominio = window.localStorage.getItem('dominio')
        // var logo = null
        // switch (dominio) {
        //     case 'Client':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'blueClientlient':
        //         logo = 'icon-purpleClient corblueClientlient'
        //         break

        //     case 'darkBlueClient':
        //         logo = 'icon-logo_darkBlueClient cordarkBlueClient'
        //         break

        //     case 'dev':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'localhost':
        //         logo = `icon-purpleClient cordarkBlueClient`
        //         break

        //     default:
        //         logo = 'icon-logo-Client corClient'
        // }

        const dados = [
            this.state.totalQuantidadeAtivo,
            this.state.totalQuantidadeInativo,
        ]

        // const dados = [100, 200, 120, 180, 50]
        const dataDaPagina = `Período de Apresentação: ${this.state.dataInicio} a ${this.state.dataFim}`

        return (
            this.state.loading ? <Loading /> :
                <div className="container paddingGeralXS">

                    <div id="non-printable" className="mt-5">
                        <button className="btn btn-danger" onClick={this.imprimir}>
                            <img
                                src={require('../../../design/assets/images/impressora.svg')}
                                alt="anexo"
                            />
                        </button>
                    </div>

                    <div className="pt-5">

                        <div className="row">
                        <div className="col-1" >
                        <a href="/">
                                <div className=" logoMenuRelatorios text-center"></div>
                                    {/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
                                </a>
                            </div>
                            <div className="col-11">
                                <div>
                                    <p className="tituloRelatorio">
                                        Relatório
                                        </p>
                                </div>
                                <div>
                                    <p className="descricaoTituloRelatorio">
                                        Quantidade de proposições classificadas a partir do estado "Ativo" ou "Inativo"
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 text-center periodoFiltro">
                                <div className="">
                                    {dataDaPagina}
                                </div>
                            </div>
                        </div>


                        {/* GRAFICO  */}

                        <div className="row">

                            <div className="col-md-6 divGrafico" id="align-grafico">
                                <Grafico
                                    dados={dados}
                                />
                            </div>
                            <div className="col-md-6 align-self-center divIndice" id="divAlignIndice">

                                <div className="row">

                                    <div className="corIndiceAtivo-A"></div>
                                    <div>
                                        <p className="textoIndiceAtivo-A">
                                            Ativo
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceAtivo-A">
                                            {this.state.totalQuantidadeAtivo}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceInativo-A"></div>
                                    <div>
                                        <p className="textoIndiceInativo-A">
                                            Inativo
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceInativo-A">
                                            {this.state.totalQuantidadeInativo}
                                        </p>
                                    </div>

                                </div>
                            </div>

                        </div>


                        {/* GRAFICO ATIVO */}


                        <div className="mt-5">
                            <p className="tituloTabelaAtivo-A">
                                Ativo
                            </p>
                        </div>
                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleAtivo-A">
                                <tr>
                                    <th className="headTipoAtivo-A">Tipo de Proposição</th>
                                    <th className="headQuantAtivo-A">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                    this.state.tiposQuantidade.map((item, index) => {
                                        // console.log(item)
                                        return (
                                            item.posicao === 'ativos' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoAtivo-A">{item.tipo}</td>
                                                    <td className="dadosColunaQuantAtivo-A">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualAtivo}
                            itemsCountPerPage={this.state.limiteAtivo}
                            totalItemsCount={parseInt(this.state.quantidadeAtivo)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaAtivo}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}



                        {/* GRAFICO INATIVO */}


                        <div className="mt-5">
                            <p className="tituloTabelaInativo-A">
                                Inativo
                            </p>
                        </div>
                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleInativo-A">
                                <tr>
                                    <th className="headTipoInativo-A">Tipo de Proposição</th>
                                    <th className="headQuantInativo-A">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                    this.state.tiposQuantidade.map((item, index) => {
                                        // console.log(item)
                                        return (
                                            item.posicao === 'inativos' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoInativo-A">{item.tipo}</td>
                                                    <td className="dadosColunaQuantInativo-A">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualInativo}
                            itemsCountPerPage={this.state.limiteInativo}
                            totalItemsCount={parseInt(this.state.quantidadeInativo)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaInativo}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}

                    </div>

                </div>

        )
    }
}

export default ProposicaoAtivoInativo