import React from "react"
// import { useForm } from "react-hook-form"
// import ModalAviso from './modalAvisoCadastroProposicao'
// import React, { useState } from "react"
// import { get, post } from "../../services/http";
// import ClientSelect from '../../componentes/forms/basic/clientSelectModalCadUser'
// import { useEffect } from 'react';
// import uuid from 'uuid/v1'
// import alertaStore from '../../stores/alertaStore'
// import LoadingCircle  from '../../componentes/loadingCircle'




// const inputStyle = {
//     border: 'none', 
//     backgroundColor: 'transparent', 
//     borderBottom: '1px solid #C4C4C4', 
//     color: '#000000', 
//     marginTop: '10px', 
//     width: '100%', 
//     placeholder: 'red', 
//     outline: 'none'
//   };

// const inputStyleA = {
//     border: 'none', 
//     backgroundColor: 'transparent', 
//     borderBottom: '1px solid #C4C4C4', 
//     color: '#000000', 
//     marginTop: '13px', 
//     width: '100%', 
//     placeholder: 'red', 
//     outline: 'none',
//     height:"25px"
//   };
  

const feed = (props) => {

    // const { handleSubmit, errors } = useForm();
    // const [uf, setUf] = useState('')
   
    //    function erroSemValue(){
    //     alertaStore.erro("Falta preenchimento em alguns dos campos")
    //    }

      
    return (
        <div>
            Hello!!!
        </div>
    )
        
}
export default feed 