import React, { Fragment, Component } from "react"
import { observer } from "mobx-react"
import Query from "../hoc/query"
import Loading from "../loading"
import Comentar from "./comentar"
import Lista from "./lista"
import ValoresProvider from "../providers/valoresProvider";
import authStore from "../../stores/authStore"
import { del } from "../../services/http";
import alertaStore from "../../stores/alertaStore";

const excluirDefinitivamente = async ({ id, reload }) => {

    await del(`/comentario/${id}`)
    .then(resp =>{
      alertaStore.criar('Comentário removido.')
      reload && reload()
    }).catch(err => {
      console.error(err)
      alertaStore.erro('Não foi possível remover comentário.')
    })

  
}
class Comentarios extends Component {
  constructor(props) {
    super(props)
    this.state = {
      verMais: false
    }
  }
  render() {
    return (
      <ValoresProvider inicial={{ valor: '', editando: null, excluindo: {} }}>
        {({ valores, alterar }) =>
          <Query query={`comentario?contexto=${this.props.contexto}`}>
            {({ fetching, data, error, reload }) => {
              const id = `campo-${this.props.contexto}`
              const responder = ({ id, nome }) => alterar('valor', `<b data-id="${id}">${nome}</b>&nbsp;`)
              const editar = ({ id }) => alterar('editando', id)
              const cancelarEdicao = () => alterar('editando', null)
              const excluir = ({ id }) => {
                const timeout = setTimeout(async () => {
                  await excluirDefinitivamente({ id, reload })
                  alterar('excluindo', { ...valores.excluindo, [id]: false })
                }, 5000)
                alterar('excluindo', { ...valores.excluindo, [id]: timeout })
              }
              const cancelarExclusao = ({ id }) => {
                valores.excluindo[id] && clearTimeout(valores.excluindo[id])
                alterar('excluindo', { ...valores.excluindo, [id]: false })
              }
              const mencionados = (data && data.length && data.reduce((p, c) => [...p, ...c.mencionados], [])) || []
              const podeComentar = authStore.ehAdministrador || mencionados.find(id => id === authStore.id) || authStore.podeEditar || authStore.ehEsforcoColaborativo

              return <Fragment>
                {podeComentar ? <Comentar reload={reload} id={id} valor={valores['valor']}{...this.props} /> : null}
                {error ? <p>Não foi possível carregar comentários.</p>
                  : fetching ? <Loading />
                    : data && data.length
                      ? <Lista
                        usuarios={this.props.usuarios}
                        editando={valores.editando}
                        excluindo={valores.excluindo}
                        podeComentar={podeComentar}
                        reload={reload}
                        responder={responder}
                        editar={editar}
                        cancelarEdicao={cancelarEdicao}
                        excluir={excluir}
                        cancelarExclusao={cancelarExclusao}
                        comentarios={data}
                        verMais={this.state.verMais}
                        alterarVerMais={(verMais) => this.setState({verMais})}
                      />
                      : ''}
              </Fragment>
            }}
          </Query>}
      </ValoresProvider>
    )
  }
}
// const Comentarios = props =>
//   <ValoresProvider inicial={{ valor: '', editando: null, excluindo: {} }}>
//     {({ valores, alterar }) =>
//       <Query query={`comentario?contexto=${props.contexto}`}>
//         {({ fetching, data, error, reload }) => {
//           const id = `campo-${props.contexto}`
//           const responder = ({ id, nome }) => alterar('valor', `<b data-id="${id}">${nome}</b>&nbsp;`)
//           const editar = ({ id }) => alterar('editando', id)
//           const cancelarEdicao = () => alterar('editando', null)
//           const excluir = ({ id }) => {
//             const timeout = setTimeout(async () => {
//               await excluirDefinitivamente({ id, reload })
//               alterar('excluindo', { ...valores.excluindo, [id]: false })
//             }, 5000)
//             alterar('excluindo', { ...valores.excluindo, [id]: timeout })
//           }
//           const cancelarExclusao = ({ id }) => {
//             valores.excluindo[id] && clearTimeout(valores.excluindo[id])
//             alterar('excluindo', { ...valores.excluindo, [id]: false })
//           }
//           const mencionados = (data && data.length && data.reduce((p, c) => [...p, ...c.mencionados], [])) || []
//           const podeComentar = authStore.ehAdministrador || mencionados.find(id => id === authStore.id)

//           return <Fragment>
//             {podeComentar ? <Comentar reload={reload} id={id} valor={valores['valor']}{...props} /> : null}
//             {error ? <p>Não foi possível carregar comentários.</p>
//               : fetching ? <Loading />
//                 : data && data.length
//                   ? <Lista
//                     usuarios={props.usuarios}
//                     editando={valores.editando}
//                     excluindo={valores.excluindo}
//                     podeComentar={podeComentar}
//                     reload={reload}
//                     responder={responder}
//                     editar={editar}
//                     cancelarEdicao={cancelarEdicao}
//                     excluir={excluir}
//                     cancelarExclusao={cancelarExclusao}
//                     comentarios={data}
//                     verMais={verMais}

//                   />
//                   : ''}
//           </Fragment>
//         }}
//       </Query>}
//   </ValoresProvider>

export default observer(Comentarios)