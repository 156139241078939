import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'

import Insights from '../insightDefault';

export default () =>
    <Fragment>
        <Switch>
            <Route exact path="/insights/:assunto" component={Insights} />
        </Switch>
    </Fragment>
