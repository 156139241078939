import React, { Component } from 'react';
import Loading from '../../../componentes/loading';
import Grafico from '../graficos/graficoProposicoesPorPosicionaemnto'
import moment from 'moment-timezone';
// import Pagination from "react-js-pagination";
import { get } from "../../../services/http"
import { inject, observer } from 'mobx-react'

moment.tz.setDefault('UTC');

@inject('authStore')
@observer

class ProposicaoPorPosicionamento extends Component {
    state = {
        loading: true,
        dataInicio: '',

        tiposQuantidade: [],

        resultadoRelatorioFavoravel: [],
        resultadoRelatorioFavoravelComEmenda: [],
        resultadoRelatorioContrario: [],
        resultadoRelatorioContrarioEmendaSaneadora: [],
        resultadoRelatorioPendente: [],
        resultadoRelatorioNeutro: [],

        paginaAtualFavoravel: 1,
        limiteFavoravel: 3,
        quantidadeFavoravel: 0,

        paginaAtualFavoravelComEmenda: 1,
        limiteFavoravelComEmenda: 3,
        quantidadeFavoravelComEmenda: 0,

        paginaAtualContrario: 1,
        limiteContrario: 3,
        quantidadeContrario: 0,

        paginaAtualFavoravelEmendaSaneadora: 1,
        limiteFavoravelEmendaSaneadora: 3,
        quantidadeFavoravelEmendaSaneadora: 0,

        paginaAtualPendente: 1,
        limitePendente: 3,
        quantidadePendente: 0,

        paginaAtualNeutro: 1,
        limiteNeutro: 3,
        quantidadeNeutro: 0,

        totalQuantidadeFavoravel: 0,
        totalQuantidadeFavoravelComEmenda: 0,
        totalQuantidadeContrario: 0,
        totalQuantidadeContrarioEmendaSaneadora: 0,
        totalQuantidadePendente: 0,
        totalQuantidadeNeutro: 0,

        dataIni: '',
        dataFim: '',
    }

    async componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        await this.getDataRelatorio(1)

        this.setState({
            dataInicio: moment(this.props.match.params.dtIni).format('DD/MM/YYYY'),
            dataFim: moment(this.props.match.params.dtFim).format('DD/MM/YYYY'),
        })
    }

    async getDataRelatorio(page) {
        const dtIni = this.props.match.params.dtIni
        const dtFim = this.props.match.params.dtFim
        await get(`/relatorio/proposicoes/posicionamentos/${dtIni}/${dtFim}`)
            .then(res => {
                // console.log(res)
                const dados = res || []
                this.setState({
                    tiposQuantidade: dados.tabela,
                    totalQuantidadeFavoravel: dados.grafico.totalFavoravel.quantidade,
                    totalQuantidadeFavoravelComEmenda: dados.grafico.totalFavoravelComEmenda.quantidade,
                    totalQuantidadeContrario: dados.grafico.totalContrario.quantidade,
                    totalQuantidadeContrarioEmendaSaneadora: dados.grafico.totalContrarioComEmenda.quantidade,
                    totalQuantidadePendente: dados.grafico.totalPendente.quantidade,
                    totalQuantidadeNeutro: dados.grafico.totalNeutro.quantidade,
                    loading: false
                })
            })
    }

    imprimir() {
        window.print()
    }


    render() {

        // const dominio = window.localStorage.getItem('dominio')
        // var logo = null
        // switch (dominio) {
        //     case 'Client':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'blueClient':
        //         logo = 'icon-purpleClient corblueClient'
        //         break

        //     case 'darkBlueClient':
        //         logo = 'icon-logo_darkBlueClient cordarkBlueClient'
        //         break

        //     case 'dev':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'localhost':
        //         logo = `icon-purpleClient cordarkBlueClient`
        //         break

        //     default:
        //         logo = 'icon-logo-Client corClient'
        // }

        const dados = [
            this.state.totalQuantidadeFavoravel,
            this.state.totalQuantidadeFavoravelComEmenda,
            this.state.totalQuantidadeContrario,
            this.state.totalQuantidadeContrarioEmendaSaneadora,
            this.state.totalQuantidadePendente,
            this.state.totalQuantidadeNeutro,
        ]
        const dataDaPagina = `Período de Criação: ${this.state.dataInicio} a ${this.state.dataFim}`

        return (
            this.state.loading ? <Loading /> :
                <div className="container paddingGeralXS">

                    <div id="non-printable" className="mt-5">
                        <button className="btn btn-danger" onClick={this.imprimir}>
                            <img
                                src={require('../../../design/assets/images/impressora.svg')}
                                alt="anexo"
                            />
                        </button>
                    </div>

                    <div className="pt-5">

                        <div className="row">
                        <div className="col-1" >
                        <a href="/">
                                <div className=" logoMenuRelatorios text-center"></div>
                                    {/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
                                </a>
                            </div>
                            <div className="col-11">
                                <div>
                                    <p className="tituloRelatorio">
                                        Relatório
                                        </p>
                                </div>
                                <div>
                                    <p className="descricaoTituloRelatorio">
                                        Quantidade de proposições classificadas a partir do seu posicionamento
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 text-center periodoFiltro">
                                <div className="">
                                    {dataDaPagina}
                                </div>
                            </div>
                        </div>


                        {/* GRAFICO  */}

                        <div className="row">

                            <div className="col-md-6 divGrafico" id="align-grafico">
                                <Grafico
                                    dados={dados}
                                />
                            </div>
                            <div className="col-md-6 align-self-center divIndice" id="divAlignIndice">
                                <div className="row">

                                    <div className="corIndiceFavoravel"></div>
                                    <div>
                                        <p className="textoIndiceFavoravel">
                                            Favorável
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceFavoravel">
                                            {this.state.totalQuantidadeFavoravel}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceFavoravelComEmenda"></div>
                                    <div>
                                        <p className="textoIndiceFavoravelComEmenda">
                                            Favorável com Emenda Saneadora
                                        </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceFavoravelComEmenda">
                                            {this.state.totalQuantidadeFavoravelComEmenda}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceContrario"></div>
                                    <div>
                                        <p className="textoIndiceContrario">
                                            Contrário
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceContrario">
                                            {this.state.totalQuantidadeContrario}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceContrarioComEmendaSaneadora"></div>
                                    <div>
                                        <p className="textoIndiceContrarioComEmendaSaneadora">
                                            Contrário com Emenda Saneadora
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceContrarioComEmendaSaneadora">
                                            {this.state.totalQuantidadeContrarioEmendaSaneadora}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndicePendente"></div>
                                    <div>
                                        <p className="textoIndicePendente">
                                            Pendente
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndicePendente">
                                            {this.state.totalQuantidadePendente}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceNeutro"></div>
                                    <div>
                                        <p className="textoIndiceNeutro">
                                            Neutro
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceNeutro">
                                            {this.state.totalQuantidadeNeutro}
                                        </p>
                                    </div>

                                </div>
                            </div>

                        </div>



                        {/* GRAFICO FAVORÁVEL*/}


                        <div className="mt-5">
                            <p className="tituloTabelaFavoravel">
                                Favorável
                            </p>
                        </div>


                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleFavoravel">
                                <tr>
                                    <th className="headTipoFavoravel">Tipo de Proposição</th>
                                    <th className="headQuantFavoravel">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                    this.state.tiposQuantidade.map((item, index) => {
                                        // console.log(item)
                                        return (
                                            item.posicao === 'favoravel' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoFavoravel">{item.tipo}</td>
                                                    <td className="dadosColunaQuantFavoravel">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualFavoravel}
                            itemsCountPerPage={this.state.limiteFavoravel}
                            totalItemsCount={parseInt(this.state.quantidadeFavoravel)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaFavoravel}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}



                        {/* GRAFICO FAVORÁVEL COM EMENDA*/}


                        <div className="mt-5">
                            <p className="tituloTabelaFavoravelComEmenda">
                                Favorável com Emenda Saneadora
                            </p>
                        </div>


                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleFavoravelComEmenda">
                                <tr>
                                    <th className="headTipoFavoravelComEmenda">Tipo de Proposição</th>
                                    <th className="headQuantFavoravelComEmenda">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                    this.state.tiposQuantidade.map((item, index) => {
                                        return (
                                            item.posicao === 'favoravelComEmenda' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoFavoravelComEmenda">{item.tipo}</td>
                                                    <td className="dadosColunaQuantFavoravelComEmenda">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualFavoravelComEmenda}
                            itemsCountPerPage={this.state.limiteFavoravelComEmenda}
                            totalItemsCount={parseInt(this.state.quantidadeFavoravelComEmenda)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaFavoravelComEmenda}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}

                        {/* GRAFICO CONTRARIO*/}


                        <div className="mt-5">
                            <p className="tituloTabelaContrario">
                                Contrário
                            </p>
                        </div>


                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleContrario">
                                <tr>
                                    <th className="headTipoContrario">Tipo de Proposição</th>
                                    <th className="headQuantContrario">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                    this.state.tiposQuantidade.map((item, index) => {
                                        return (
                                            item.posicao === 'contrario' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoContrario">{item.tipo}</td>
                                                    <td className="dadosColunaQuantContrario">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualContrario}
                            itemsCountPerPage={this.state.limiteContrario}
                            totalItemsCount={parseInt(this.state.quantidadeContrario)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaContrario}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}

                        {/* GRAFICO CONTRARIO COM EMENDA SANEADORA */}


                        <div className="mt-5">
                            <p className="tituloTabelaContrarioEmendaSaneadora">
                                Contrário com Emenda Saneadora
                            </p>
                        </div>
                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleContrarioEmendaSaneadora">
                                <tr>
                                    <th className="headTipoContrarioEmendaSaneadora">Tipo de Proposição</th>
                                    <th className="headQuantContrarioEmendaSaneadora">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                    this.state.tiposQuantidade.map((item, index) => {
                                        return (
                                            item.posicao === 'contrarioComEmenda' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoContrarioEmendaSaneadora">{item.tipo}</td>
                                                    <td className="dadosColunaQuantContrarioEmendaSaneadora">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualContrarioEmendaSaneadora}
                            itemsCountPerPage={this.state.limiteContrarioEmendaSaneadora}
                            totalItemsCount={parseInt(this.state.quantidadeContrarioEmendaSaneadora)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaContrarioEmendaSaneadora}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}


                        {/* GRAFICO PENDENTE */}


                        <div className="mt-5">
                            <p className="tituloTabelaPendente">
                                Pendente
                            </p>
                        </div>
                        <table className="table table-striped">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStylePendente">
                                <tr>
                                    <th className="headTipoPendente">Tipo de Proposição</th>
                                    <th className="headQuantPendente">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                    this.state.tiposQuantidade.map((item, index) => {
                                        return (
                                            item.posicao === 'pendente' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoPendente">{item.tipo}</td>
                                                    <td className="dadosColunaQuantPendente">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualPendente}
                            itemsCountPerPage={this.state.limitePendente}
                            totalItemsCount={parseInt(this.state.quantidadePendente)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaPendente}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}



                        {/* GRAFICO NEUTRO */}


                        <div className="mt-5">
                            <p className="tituloTabelaNeutro">
                                Neutro
                            </p>
                        </div>
                        <table className="table table-striped">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleNeutro">
                                <tr>
                                    <th className="headTipoNeutro">Tipo de Proposição</th>
                                    <th className="headQuantNeutro">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                    this.state.tiposQuantidade.map((item, index) => {
                                        return (
                                            item.posicao === 'neutro' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoNeutro">{item.tipo}</td>
                                                    <td className="dadosColunaQuantNeutro">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualNeutro}
                            itemsCountPerPage={this.state.limiteNeutro}
                            totalItemsCount={parseInt(this.state.quantidadeNeutro)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaNeutro}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}

                    </div>

                </div>

        )
    }
}

export default ProposicaoPorPosicionamento