import React, { Component, Fragment } from 'react';
import Loading from '../../../componentes/loading'
import isList from '../../../componentes/hoc/isList';
import Accordion from '../../../componentes/accordion';
import { get, put, post } from '../../../services/http';
import { formatarData } from '../../../helpers/date';
import { resultadoClient } from '../../../enums/resultadoDaAcao';
import { inject, observer } from 'mobx-react'
import casas from '../../../helpers/casa';
import moment from 'moment-timezone';
import ClientSelect from '../../../componentes/forms/basic/clientSelect';
import ConfirmModal from '../../../componentes/confirmModal';
import ModalAcaoInfluencia from '../modal/modalAcaoInfluencia'
import Anexos from '../components/anexos'

moment.tz.setDefault('UTC');

@inject('alertaStore', 'uploadStore', 'authStore')
@observer

class AcoesInfluencia extends Component {

  async excluir(showModal, save, item) {
    this.setState({ showModal })
    if (save) {
      this.setState({ loading: !this.state.loading })
      try {
        await put(`/acao/acao-influencia/${item.id}`, { ...item })
        this.props.alertaStore.criar('Ações de influência excluída com sucesso.')
        this.setState({ loading: !this.state.loading })
        this.getDados()
      } catch (error) { console.error(error); this.setState({ loading: !this.state.loading }) }
    }
  }

  changeShowModalState(showModal, itemToDelete) {
    this.setState({ showModal, itemToDelete })
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      dados: [],
      parlamentares: [],
      ambitos: [],
      influenciarSobre: [],
      idProposicaoCasaExterna: null,
      arquivos: []
    }
  }

  async componentWillMount() {
    await this.setState({ idProposicaoCasaExterna: this.props.idProposicaoCasaExterna })
    this.getDados();
    this.getAmbitos();
  }

  getAmbitos = () => {
    [1, 2, 3].forEach(casaLegislativa => get(`ambito/${casaLegislativa}`).then(r => {
      this.setState({ ambitos: this.state.ambitos.concat(r) })
    }));
  }

  async getDados() {
    await get(`acao/${this.props.idProposicaoCasaExterna}/acao-influencia`)
      .then(async r => {
        const arrayIds = []
        r.itens !== undefined && Array.isArray(r.itens) && r.itens.length > 0 && r.itens.map(item =>
          arrayIds.push(item.id)
        )
        const data = {
          arrayIds: arrayIds
        }

        await post("parlamentar/acao-influencia/influenciar-sobre", data)
          .then(response => {

            this.setState({ influenciarSobre: response, loading: false })
          })
          .catch(err => err)

        this.setState({ dados: r.itens, arquivos: r.anexos });
      })
      .catch(e => console.log(e))
  }
  async getAnexos(id) {
    return get(`anexo/acao-influencia/${id}`);
  }
  get ativas() { return this.state.dados.filter(x => !x.resultado || parseInt(x.resultado) === 99) }
  get concluidas() { return this.state.dados.filter(x => parseInt(x.resultado) < 99) }

  mudarResultado = async (resultado, acaoInfluencia) => {
    await put(`/acao/${this.props.idProposicaoCasaExterna}/acao-influencia/${acaoInfluencia}`, { resultado: resultado, putResultado: true });
    this.props.alertaStore.criar('Resultado alterada com sucesso');
    this.getDados()
  }


  getAmbito = ambito_id => {
    let ambito = this.state.ambitos.find(item => item.id === ambito_id);

    //console.log('amib',ambito)
    return (ambito === undefined) ? "" : `${ambito.sigla} - ${ambito.descricao}`;
  }

  render() {

    const idProposicaoCasaExterna = this.props.idProposicaoCasaExterna
    const parlamentares = this.state.influenciarSobre
    const idProposicaoNovo = this.props.idNovo


    const Item = (dados) => {
      // console.log('dados aqui ===>', dados)
      return this.state.loading ? <Loading /> : <div className="mt-4 justify-content-between  border">
        <header className='w-100 d-flex justify-content-between mb-4'>
          <h5 className="Client-titulo-paragrafo w-40 ml-3 mt-2">
            Ação de influência
            <span className="Client-cinza-claro ml-3 fs-16">{moment(dados.criada_em).format('[criada em] DD/MM/YYYY')}</span>
          </h5>
          <div className="d-flex align-items-center justify-content-between mr-3">
            <ConfirmModal
              text={'Tem certeza que deseja excluir uma ação de influência?'}
              description={'Esta ação não poderá ser desfeita no futuro'}
              onClose={(show, save, item) => this.excluir(show, save, item)}
              show={this.state.showModal}
              item={dados}
            />

            {this.props.authStore.ehVisualizador === true ? null :
              <div className="col-1 text-center">
                <span id="proposicao-remover-acao-influencia" className={'cursor-pointer'} onClick={() => this.changeShowModalState(true, { ...dados })}>
                  <img src={require('../../../design/assets/images/trash-gray.svg')} alt="" />
                </span>
              </div>
            }
            {this.props.authStore.ehVisualizador === true ? null :
              <ModalAcaoInfluencia
                button={false}
                create={false}
                dados={dados}
                parlamentares={parlamentares}
                idProposicaoCasaExterna={idProposicaoCasaExterna}
                usuarios={this.props.usuarios}
                descricaoProposicao={this.props.descricaoProposicao}
                onClick={() => this.getDados()}
                mensagens={() => this.props.alertaStore.criar('Ação de influências alterada com sucesso')}
              />
            }
          </div>
        </header>

        <div className="row mr-lg-3 ml-lg-3">
          <div className="col-12 mb-3 col-sm-12 col-lg 4">
            <div className="text-navy font-weight-bold">Responsável</div>
            <div>{dados.responsavel}</div>
          </div>
          <div className="col-12 mb-3 col-sm-12 col-lg 4">
            <div className="text-navy font-weight-bold">Prazo</div>
            <div>{dados.prazo !== null && dados.prazo !== undefined ? formatarData(dados.prazo) : 'Não identificado'}</div>
          </div>
          {this.props.authStore.ehVisualizador === true ? <div className="col-12 mb-3 col-sm-12 col-lg 4"></div> :
            <div className="col-12 mb-3 col-sm-12 col-lg 4">
              <ClientSelect
                items={resultadoClient}
                label="Resultado"
                defaultValue={{ id: 99, value: 'Pendente', icon: 'pendente' }}
                value={dados.resultado}
                onChange={(event) => this.mudarResultado(event.id, dados.id)}
              />
            </div>
          }
        </div>

        <p className="seprarar-acao-influencia"></p>

        <div className="row mr-3 ml-3">
          {dados.influenciaSobre &&
            <div className="col-md-4">
              <div className="text-navy font-weight-bold"><b>Influência sobre</b></div>
              {parlamentares !== undefined && Array.isArray(parlamentares) && parlamentares.length > 0 ? parlamentares.map((item, index) => {
                var nomeParlamentar = item.nome
                nomeParlamentar = nomeParlamentar.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
                return (
                  item.id_acao_influencia === dados.id ?
                    <div key={index} style={{ fontSize: '0.9rem' }}>{nomeParlamentar}</div>
                    : null
                )
              }) : null}
            </div>
          }


          <div className="col-md-4">
            <div className="text-navy font-weight-bold"><b>Casa Legislativa</b></div>
            <div>{casas[dados.casa_legislativa]}</div>
          </div>

          <div className="col-md-4">
            <div className="text-navy font-weight-bold"><b>Âmbito</b></div>
            <div>{dados.ambito_id !== 1 ? this.getAmbito(dados.ambito_id) : dados.ambito_id === 1 ? 'Plenário' : ''}</div>
          </div>
        </div>

        <div className="row m-3 text-wrap text-truncate">
          <div className="col-12 text-wrap text-truncate">{dados.descricao}</div>
        </div>
        <div className="p-4">
          <Anexos
            idParametro={dados.id}
            idProposicaoCasaExterna={this.props.idProposicaoCasaExterna}
            create={false}
            modulo={'acao-influencia'}
          />
        </div>
      </div>
    }

    return this.state && this.state.dados ?
      <Fragment>
        <div className="bg-white p-3 mb-3">
          {this.state.dados.length > 0 ?
            <Fragment>
              <header className='mb-4 p-4 d-flex justify-content-between align-items-center'>
                <div style={{ fontSize: '1.2rem' }}>
                  <span className='Client-span-criar-acoes-preparatoria mr-2'>{this.state.dados.length}</span>
                  Ações de influência
                </div>
                {this.props.authStore.ehVisualizador === true ? null :
                  <ModalAcaoInfluencia
                    button={false}
                    create={true}
                    idProposicaoCasaExterna={idProposicaoCasaExterna}
                    idNovo={idProposicaoNovo}
                    usuarios={this.props.usuarios}
                    descricaoProposicao={this.props.descricaoProposicao}
                    onClick={() => this.getDados()}
                    mensagens={() => this.props.alertaStore.criar('Ação de influências criada com sucesso')}
                  />
                }
              </header>
              <hr className="m-1" />
              <div className="row">
                <Accordion titulo={<span className="quantidade-acao-influencia">{this.ativas.length} <span className="Client-cinza-adjacente">ATIVAS</span> </span>} aberto={true}>
                  <div className="accordion-body">
                    {isList({ dados: this.ativas })(Item)}
                  </div>
                </Accordion>
              </div>
              <div className="row">
                <Accordion titulo={<span className="quantidade-acao-influencia">{this.concluidas.length} <span className="Client-cinza-adjacente">CONCLUÍDAS</span> </span>} >
                  <div className="accordion-body">
                    {isList({ dados: this.concluidas })(Item)}
                  </div>
                </Accordion>
              </div>
              <hr />
            </Fragment> :

            <div>
              <h5 className="mt-4 mb-0 text-center Client-titulo">Ainda não há uma ação de influência criadas para esta proposição</h5>
              {this.props.authStore.ehVisualizador === true ? null :
                <ModalAcaoInfluencia
                  button={true}
                  create={true}
                  idProposicaoCasaExterna={idProposicaoCasaExterna}
                  idNovo={idProposicaoNovo}
                  usuarios={this.props.usuarios}
                  descricaoProposicao={this.props.descricaoProposicao}
                  onClick={() => this.getDados()}
                  mensagens={() => this.props.alertaStore.criar('Ação de influências criada com sucesso')}
                />
              }
            </div>
          }
        </div>
      </Fragment> : null
  }
}

export default props => <AcoesInfluencia {...props} semPaginacao={true} />
