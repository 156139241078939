import React, { Component, Fragment } from 'react'
import { NavLink, Route } from 'react-router-dom'
import { get, post, put } from '../../services/http'
import MediaItem from '../../componentes/mediaItem'
import ProximosEventos from './listas/proximosEventos'
import { formatarData, calcularIdade } from '../../helpers/date'
import Modal from '../../componentes/modal'
import FormAcaoParlamentar from './form/formAcaoParlamentar'
import Loading from '../../componentes/loading'
import { inject } from 'mobx-react'
import Slider from 'react-slick'
import ParlamentarAcontecimentos from './parlamentarAcontecimento'
import ParlamentarProposicao from './parlamentarProposicao'
import ParlamentarComissoes from './parlamentarComissoes'
import ParlamentarDiscursos from './parlamentarDiscursos'
import ParlamentarOutros from './parlamentarOutros'
import ParlamentarDefault from './parlamentarDefault'
import ClientSelect from '../../componentes/forms/basic/clientSelect'
import { parlamentarQualificacao } from '../../enums/parlamentarQualificacao'
import moment from 'moment-timezone';
// import { Icon } from '@material-ui/core'
// import { inject } from 'mobx-react';
moment.tz.setDefault('UTC');

@inject('alertaStore', 'authStore')

class AcaoInfluencia extends Component {
    state = {
        parlamentar: {},
        telefones: [],
        proposicoes: [],
        estrategia: [],
        acaoDeInfluencia: [],
        influenciaSobre: [],
        anexos: [],
        idsAcoes: [],
        loading: true,

        qualificacao: '',
        observacao: '',
        descricao: '',
        seguindo: false,

        atualizar: false,
        mensagemQualificacao: false,
        mensagemObservacao: false,
        mensagemTelefone: false,
        qualificacaoParlamentar: '',
        limite: 50,
        paginaAtual: 1,
        ultimo: 0,
        imgFoto: '',
        telefone: ''
    }

    async componentWillMount() {
        await Promise.all([
            this.getParlamentarPorId()
        ]).then(x => this.setState({ loading: false }))
    }

    async getParlamentarPorId() {
        const id = this.props.id

        await get(`parlamentar/${id}/dados-completos`)
            .then(response => {
                this.setState({
                    parlamentar: response.parlamentar,
                    classificacao: response.classificacao,
                    telefones: response.telefones,
                    acaoDeInfluencia: response.acaoInfluencia,
                    influenciaSobre: response.influenciaSobre,
                    anexos: response.anexos,
                    idsAcoes: response.idsAcoes,
                    observacao: response.classificacao.length > 0 ? response.classificacao[0].observacao : '',
                    qualificacaoParlamentar: response.classificacao.length > 0 ? response.classificacao[0].qualificacao : '',
                    descricao: response.classificacao.length > 0 ? response.classificacao[0].descricao : '',
                    seguindo: response.classificacao.length > 0 ? response.classificacao[0].seguindo : false
                })
            })
    }

    async atualizarParlamentarCliente(valor, itemAlterado) {
        const data = {
            observacao: itemAlterado === 'observacao' ? valor.observacao : this.state.observacao,
            qualificacao: itemAlterado === 'qualificacao' ? valor.qualificacao : this.state.qualificacaoParlamentar,
            descricao: itemAlterado === 'telefone' ? valor.descricao : this.state.descricao,
            seguindo: itemAlterado === 'seguindo' ? valor.seguindo : this.state.seguindo,
            id_parlamentar: this.props.id,
            itemAlterado: itemAlterado
        }
        await put('/parlamentar/classificacao/atualizar', data)
            .then(response => {
                this.getParlamentarPorId()
                // if(response === 1) {
                //     this.props.alertaStore.criar(this.state.mensagemObservacao === true ? 'Observação Atualizada com sucesso!' : 'Qualificação Atualizada com sucesso!')
                // }
            })
            .catch(err => {
                this.props.alertaStore.erro(this.state.mensagemObservacao === true ? 'Não foi possível Atualizar sua Observação' + err : 'Não foi possível Atualizar sua Qualificação' + err)
            })
    }

    async inserirParlamentarCliente(valor, itemAlterado) {
        const data = {
            observacao: itemAlterado === 'observacao' ? valor.observacao : this.state.observacao,
            qualificacao: itemAlterado === 'qualificacao' ? valor.qualificacao : this.state.qualificacaoParlamentar,
            descricao: itemAlterado === 'telefone' ? valor.descricao : this.state.descricao,
            seguindo: itemAlterado === 'seguindo' ? valor.seguindo : this.state.seguindo,
            id_parlamentar: this.props.id,
            itemAlterado: itemAlterado
        }
        await post('/parlamentar/classificacao/inserir', data)
            .then(response => {
                if (response.rowCount === 1) {
                    this.getParlamentarPorId()
                }
            })
            .catch(err => {
                this.props.alertaStore.erro(this.state.mensagemObservacao === true ? 'Não foi possível inserir sua Observação' + err : 'Não foi possível inserir sua Qualificação' + err)
            })
    }

    alterarQualificacao(newValue) {
        this.setState({ qualificacao: newValue, imgFoto: newValue.img })
        this.salvarQualificacao(newValue)
    }

    alterarObservacao(event) {
        console.log(event.target.value)
        this.setState({ observacao: event.target.value })
        this.salvarObservacao(event.target.value)
    }


    alterarTelefone(event) {
        this.setState({ telefone: event.target.value })
        this.salvarTelefone(event.target.value)
    }

    salvarTelefone(value) {
        this.setState({ mensagemTelefone: true })
        if (value !== '') {
            const data = {
                id_parlamentar: this.state.parlamentar.id,
                descricao: value,
                qualificacao: this.state.qualificacaoParlamentar,
                observacao: this.state.observacao,
                seguindo: this.state.seguindo
            }
            const itemAlterado = "telefone"
            if (this.state.classificacao.length > 0) {
                this.atualizarParlamentarCliente(data, itemAlterado)
                this.props.alertaStore.criar("Telefones salvo com sucesso!")
            } else {
                this.inserirParlamentarCliente(data, itemAlterado)
            }
        }

    }

    salvarQualificacao(newValue) {
        this.setState({ mensagemQualificacao: true, mensagemObservacao: false })
        const data = {
            id_parlamentar: this.state.parlamentar.id,
            qualificacao: newValue.value,
            observacao: this.state.observacao,
            descricao: this.state.descricao,
            seguindo: this.state.seguindo
        }

        const itemAlterado = "qualificacao"
        if (this.state.classificacao.length > 0) {
            this.atualizarParlamentarCliente(data, itemAlterado)
            this.props.alertaStore.criar('Qualificação Inserida com sucesso!')
        } else {
            // this.inserirQualificacao(data, itemAlterado)
            this.inserirParlamentarCliente(data, itemAlterado)
        }
    }



    salvarObservacao(value) {
        this.setState({ mensagemObservacao: true, mensagemQualificacao: false })

        if (value !== '') {
            const data = {
                id_parlamentar: this.state.parlamentar.id,
                observacao: value,
                qualificacao: this.state.qualificacaoParlamentar,
                descricao: this.state.descricao,
                seguindo: this.state.seguindo
            }

            const itemAlterado = "observacao"
            if (this.state.classificacao.length > 0) {
                this.atualizarParlamentarCliente(data, itemAlterado)
                this.props.alertaStore.criar('Observação inserida com sucesso!')
            } else {
                // this.inserirObservacao(data, itemAlterado)
                this.inserirParlamentarCliente(data, itemAlterado)
            }
        }

    }

    async seguirParlamentar(event, id) {
        const user = JSON.parse(window.localStorage.getItem('user'))
        const data = {
            id_parlamentar: id,
            criado_em: new Date(),
            id_cliente: user.idDoCliente,
        }
        await post('parlamentar/seguir/paralamentar-especifico', data)
            .then(response => {
                this.getParlamentarPorId()
            }).catch(err => err)
    }

    async naoSeguirParlamentar(event, id) {
        const user = JSON.parse(window.localStorage.getItem('user'))
        const data = {
            id_parlamentar: id,
            atualizado_em: new Date(),
            id_cliente: user.idDoCliente,
        }
        await put('parlamentar/nao-seguir/paralamentar-especifico', data)
            .then(response => {
                this.getParlamentarPorId()
            }).catch(err => err)
    }

    render() {




        const tamanhoTela = window.innerWidth

        const urlPathname = window.location.pathname.split('/')

        let SlideActive

        if (tamanhoTela > 600) {
            if (urlPathname[3] === 'acontecimentos' || urlPathname[3] === 'acao-influencia' || urlPathname[3] === 'proposicao' || urlPathname[3] === 'comissoes') {

                SlideActive = 0

            } else if (urlPathname[3] === 'discursos' || urlPathname[3] === 'outros') {

                SlideActive = 4

            }
        } else {

            switch (urlPathname[3]) {

                case 'acontecimentos':
                    SlideActive = 0
                    break;

                case 'acao-influencia':
                    SlideActive = 1
                    break;

                case 'proposicao':
                    SlideActive = 2
                    break;

                case 'comissoes':
                    SlideActive = 3
                    break;

                case 'discursos':
                    SlideActive = 4
                    break;

                case 'outros':
                    SlideActive = 5
                    break;


                default:
                    break;
            }

        }


        const sliderSettingsMenu = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: SlideActive,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: false,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        //console.log("PARLAMENTAR ===>",this.props.id)
        //console.log("PARLAMENTAR ===>",this.state.parlamentar)


        const acaoDeInfluencia = this.state.acaoDeInfluencia
        const icon = parlamentarQualificacao.filter(item => item.value === this.state.qualificacaoParlamentar)
        const icone = icon.length > 0 ? icon[0].icon : icon
        const imgFoto = icon.length > 0 ? icon[0].img : icon
        const parlamentar = this.state.parlamentar[0] || this.state.parlamentar
        const idParlamentar = this.props.id;
        const telefonesApi = this.state.telefones || []
        // console.log(parlamentar.dataFalecimento)
        // <div style={{width: '10px', height: '10px', backgroundColor: 'green', borderRadius: '5px'}}></div>
        return this.state.loading ? <Loading /> : !parlamentar ? null :


            <Fragment>




                {tamanhoTela <= 500


                    ?


                    <div className="">

                        {/* >>>>>>>>>>>>> MOBILE CODE <<<<<<<<<<<<<<*/}


                        <div className=" bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                            <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                                {/* PARLAMENTAR - <span>{parlamentar.nome}</span> */}
                                PARLAMENTAR
                            </p>
                        </div>
                        {/* <Breadcrumb /> */}
                        <div className="container-fluid pt-3">
                            {/* BOTAO SEGUI COMENTADO ---- NAO EXCLUIR */}
                            <MediaItem
                                css="h4 text-capitalize"
                                cssSubtitle="text-capitalize"
                                size="large"
                                icon="icon-senator-small"
                                border="mb-3 border-bottom"
                                title={`${parlamentar.nome.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); })}`}
                                statusParlamentar={parlamentar.situacao ? `EM ${parlamentar.situacao}` : 'Inativo'}
                                info={[{ value: parlamentar.siglaPartido + ' | ' + parlamentar.tratamento + ' - ' + parlamentar.ufExercicio }]}>
                                {/* <Seguir status={!!(estrategia && estrategia.SEGUINDO)} url={`/parlamentar/${idParlamentar}/seguir`} /> */}
                                {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                    this.state.seguindo === false ?
                                        <button onClick={event => this.seguirParlamentar(event, idParlamentar)} className="parlamentar-botao-seguir" style={{ borderRadius: "7px" }} disabled={this.props.authStore.ehVisualizador === true ? true : false}>Seguir</button>
                                        :
                                        <button onClick={event => this.naoSeguirParlamentar(event, idParlamentar)} className="parlamentar-botao-seguindo" style={{ borderRadius: "7px" }} disabled={this.props.authStore.ehVisualizador === true ? true : false}>Seguindo</button>
                                }
                            </MediaItem>
                            <div className="row">
                                <div className={`
                                ${this.props.authStore.ehAdministrador === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.podeEditar === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.ehVisualizador === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.ehEsforcoColaborativo === true ? "col-md-3 col-lg-3" : ""} 
                            ` } css={'mb-2'}>

                                    <div className="bg-white">
                                        <div className="carousel-item1  active pt-3">
                                            {this.props.authStore.ehEsforcoColaborativo === true ?
                                                <img alt="" src={parlamentar.urlFoto ? parlamentar.urlFoto : require('../../design/assets/images/avatar-parlamentar.png')} className={`d-block m-auto foto-parlamentar foto-parlamentar-div`} />
                                                :
                                                <div>
                                                    <img alt="" src={parlamentar.urlFoto ? parlamentar.urlFoto : require('../../design/assets/images/avatar-parlamentar.png')} className={`d-block m-auto ${this.state.imgFoto === '' ? imgFoto : this.state.imgFoto} foto-parlamentar foto-parlamentar-div`} />
                                                    <div className="carousel-caption">
                                                        <h5 className="text-capitalize mt-3 qualificacao-titulo-parlamentar">PARLAMENTAR <span className="font-weight-bold">{this.state.qualificacao.value === undefined ? this.state.qualificacaoParlamentar : this.state.qualificacao.value}</span></h5>
                                                    </div>
                                                </div>
                                            }

                                            {parlamentar.cargo === 'Senador' || parlamentar.cargo === 'Senadora' || parlamentar.cargo === 'Deputado' || parlamentar.cargo === 'Deputada' ?
                                                <NavLink to={`/parlamentar/${parlamentar.cargo}`}>{parlamentar.cargo}</NavLink> : <div>{parlamentar.cargo}</div>
                                            }
                                            <p />
                                            {/* {parlamentar.siglaPartido ?
                                            // <NavLink to={`/parlamentar/partido/${parlamentar.siglaPartido}`}>
                                            //     <span>{parlamentar.siglaPartido} - </span>
                                            // </NavLink> : <span>{parlamentar.siglaPartido}</span>
                                            <div className="d-block m-auto text-center" >{parlamentar.siglaPartido}</div>
                                            : null
                                        }
                                        {parlamentar.siglaUf ?
                                            // <NavLink to={`/parlamentar/estado/${parlamentar.siglaUf}`}>
                                            //     <span className="m-0 mb-2 text-uppercase">{parlamentar.siglaUf}</span>
                                            // </NavLink> : <span>{parlamentar.siglaUf}</span>
                                            <div className="m-auto mb-2 text-uppercase">{parlamentar.siglaUf}</div>
                                            : null
                                        }  */}
                                        </div>
                                    </div>

                                    <div className="bg-white p-3 mt-3">
                                        <div>
                                            {isNaN(this.props.id) === false ?
                                                <div>
                                                    {parlamentar.dataFalecimento === null ?
                                                        <div>
                                                            <h6 className="mb-1"><b>Idade</b></h6>
                                                            <p className="text-wrap ">{`${calcularIdade(parlamentar.dataNascimento)} anos - ${formatarData(parlamentar.dataNascimento)}`}</p>
                                                        </div>
                                                        :
                                                        <div>
                                                            <h6 className="mb-1"><b>Nascimento - Falecimento</b></h6>
                                                            <p className="text-wrap ">{`${moment(parlamentar.dataNascimento).format('DD/MM/YYYY')} - ${moment(parlamentar.dataFalecimento).format('DD/MM/YYYY')}`}</p>
                                                        </div>
                                                    }
                                                </div>
                                                : null
                                            }
                                        </div>
                                        {parlamentar.nomeProfissao &&
                                            <div>
                                                <h6 className="mb-1"><b>Profissão</b></h6>
                                                <p className="text-wrap">{parlamentar.nomeProfissao || '-'}</p>
                                            </div>
                                        }
                                        {parlamentar.escolaridade &&
                                            <div>
                                                <h6 className="mb-1"><b>Escolaridade</b></h6>
                                                <p className="text-wrap">{parlamentar.escolaridade || '-'}</p>
                                            </div>
                                        }
                                    </div>

                                    <div className="bg-white p-3 mt-3">
                                        {/* <div className="bg-primary p-3 mt-3"> */}
                                        <div>
                                            <h6 className="mb-1"><b>E-mail</b></h6>
                                            <p className="text-wrap ">{parlamentar.email || '-'}</p>
                                        </div>
                                        <div>
                                            <h6 className="mb-1"><b>Telefone do Gabinete API</b></h6>
                                            {
                                                telefonesApi !== undefined &&
                                                    Array.isArray(telefonesApi) && telefonesApi.length > 0 ?
                                                    telefonesApi.map((telefone, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <p className="text-wrap ">{telefone}</p>
                                                            </div>
                                                        )
                                                    }) : null
                                            }
                                            <p className="text-wrap"></p>
                                        </div>
                                        <div className="mb-3 mt-3">
                                            {/* <input
                                            type="tel"
                                            className="Client-input Client-texto-form  d-inline-block text-truncate text-wrap overflow-auto"
                                            placeholder="Telefones do Parlamentar"
                                            value={this.state.telefone || ''}
                                            autoComplete="off"
                                            maxLength={40}
                                            onChange={(event) => this.salvarTelefone(event)}
                                            onBlur={event => this.inserirParlamentarCliente(event.target.value, true)}
                                        /> */}

                                            {this.props.authStore.ehVisualizador === false ?
                                                this.props.authStore.ehEsforcoColaborativo === false ?
                                                    <div>
                                                        <h6 className="mb-1"><b>Telefones do parlamentar</b></h6>
                                                        <textarea
                                                            className="mt-2 form-control form-control-lg  d-inline-block  text-area-analise-impacto text-truncate text-wrap overflow-auto"
                                                            id="telefone"
                                                            name="telefone"
                                                            rows="2"
                                                            cols="4"
                                                            placeholder="Digite aqui os telefones que desejar!"
                                                            onBlur={event => this.alterarTelefone(event)}
                                                            defaultValue={this.state.descricao !== '' ? this.state.descricao : null}
                                                        />
                                                    </div>

                                                    : null
                                                :

                                                <div className="border p-3 h-100">
                                                    {this.state.descricao !== '' && this.state.descricao !== null && this.state.descricao !== undefined ? this.state.descricao : ""}
                                                </div>

                                            }

                                        </div>
                                        <div>
                                            <h6 className="mb-1"><b>Endereço</b></h6>
                                            <p className="text-wrap">{parlamentar.endereco}</p>
                                        </div>
                                        <div className="biografia-parlamentar-link">

                                            {parlamentar.casa === 1 && parlamentar.uri === '' ?
                                                <a href={`https://www25.senado.leg.br/web/senadores/senador/-/perfil/${parlamentar.id_parlamentar}`} className="biografia-parlamentar" target='_blank' rel='noopener noreferrer'><img alt="parlamanetar-url" className="mr-2" src={require('../../design/assets/images/trending.svg')} />Biografia Completa</a>
                                                :
                                                <a href={parlamentar.uri} className="biografia-parlamentar" target='_blank' rel='noopener noreferrer'><img alt="parlamanetar-url" className="mr-2" style={{ content: "var(--setaBiografia)" }} />Biografia Completa</a>
                                            }
                                        </div>

                                        {/* <div>
                                        <h6 className="mb-1">Telefone do Gabinete</h6>
                                        <p className="text-wrap ">{gabinete.telefone || '-'}</p>
                                    </div> */}
                                        {/* {gabinete.nome &&
                                        <div>
                                            <h6 className="mb-1">Prédio {gabinete.predio}, {gabinete.andar}º Andar - Gabinete {gabinete.nome}</h6>
                                            <p className="text-wrap "></p>
                                        </div>
                                    } */}

                                    </div>

                                    {this.props.authStore.ehEsforcoColaborativo === true || this.props.authStore.ehVisualizador === true ? null :
                                        <div className="d-block d-sm-none mt-4">
                                            <p className="menu-parlamentar">Menu Parlamentar</p>
                                            <label>Qualificação</label>
                                            <div className="d-flex">
                                                {this.state.qualificacao.id === undefined ? <p className={`Client-circulo ${icone} mt-3 m-0`}></p> : null}
                                                <ClientSelect
                                                    items={parlamentarQualificacao}
                                                    value={this.state.qualificacao.id || this.state.qualificacaoParlamentar}
                                                    onChange={(newValue) => this.alterarQualificacao(newValue)}
                                                    placeholder={this.state.qualificacaoParlamentar === '' ? this.state.qualificacao.value : this.state.qualificacaoParlamentar}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label><b>Observações</b></label>
                                                <textarea
                                                    className="mt-2 form-control form-control-lg  d-inline-block  text-area-analise-impacto text-truncate text-wrap overflow-auto"
                                                    id="notas"
                                                    name="notas"
                                                    rows="6"
                                                    cols="33"
                                                    placeholder="Digite aqui"
                                                    maxLength="4000"
                                                    onBlur={event => this.alterarObservacao(event)}
                                                    defaultValue={this.state.observacao && this.state.observacao !== null ? this.state.observacao : null}
                                                />
                                                <span className="d-flex justify-content-end">{this.state.observacao && this.state.observacao !== null ? this.state.observacao.length : ''}/4000</span>
                                                {/* {this.state.observacao !== '' ? this.state.observacao : null}
                                        </textarea> */}
                                            </div>
                                            {/* <NavLink className="btn btn-outline-primary btn-block mb-4" to={`/parlamentar/${idParlamentar}/criar-acao`}>Criar ação de influência</NavLink> */}
                                            {/* <FormEstrategia onMouseDown={event => this.salvarObservacao(event)} titulo="Observações" esconderPosicao={true} notas={estrategia && estrategia.NOTAS} url={`/parlamentar/${idParlamentar}/dados`} /> */}
                                            <ProximosEventos idParlamentar={idParlamentar} />
                                        </div>
                                    }
                                    {this.props.authStore.ehVisualizador === true ?
                                        <div className="d-block d-sm-none">
                                            <label>Qualificação</label>
                                            <div>{this.state.qualificacaoParlamentar !== null && this.state.qualificacaoParlamentar !== '' ? this.state.qualificacaoParlamentar : 'Não foi qualificado!'}</div>

                                            <div className="mt-5">
                                                <label><b>Observações</b></label>
                                                <div className="text-truncate text-wrap p-2">{this.state.observacao && this.state.observacao !== null ? this.state.observacao : 'Não tem nenhuma observação!'}</div>
                                            </div>
                                            {/* <NavLink className="btn btn-outline-primary btn-block mb-4" to={`/parlamentar/${idParlamentar}/criar-acao`}>Criar ação de influência</NavLink> */}
                                            {/* <FormEstrategia onMouseDown={event => this.salvarObservacao(event)} titulo="Observações" esconderPosicao={true} notas={estrategia && estrategia.NOTAS} url={`/parlamentar/${idParlamentar}/dados`} /> */}
                                            <ProximosEventos idParlamentar={idParlamentar} />
                                        </div>
                                        : null}
                                </div>
                                <div className={`
                                ${this.props.authStore.ehAdministrador === true ? "col-md-6 col-lg-6" : ""}
                                ${this.props.authStore.podeEditar === true ? "col-md-6 col-lg-6" : ""}
                                ${this.props.authStore.ehVisualizador === true ? "col-md-6 col-lg-6" : ""}
                                ${this.props.authStore.ehEsforcoColaborativo === true ? "col-md-9 col-lg-9" : ""}
                            `} css={'mb-2'}>
                                    <Fragment>
                                        {/* <div className="bg-blue px-3 mb-3 rounded">
                                        <nav className="nav mb-3 fs-12 text-uppercase d-flex justify-content-between">
                                            <NavLink className="nav-link text-white proposicao-menu" activeClassName="active text-white"
                                                isActive={() => url.endsWith(`/parlamentar/${idParlamentar}`) || url.endsWith(`/parlamentar/${idParlamentar}/detalhes`)} to={`/parlamentar/${idParlamentar}/detalhes`}>
                                                <span className={!(url.endsWith(`/parlamentar/${idParlamentar}/detalhes`) || url.endsWith(`/parlamentar/${idParlamentar}`)) ? 'text-no-active' : ''}>Detalhes</span>
                                            </NavLink>
                                            {!authStore.podeEditar ? null :
                                                <NavLink className="nav-link text-white proposicao-menu" activeClassName="active text-white" to={`/parlamentar/${idParlamentar}/acoes-influencia`}>
                                                    <span className={!url.endsWith(`/parlamentar/${idParlamentar}/acoes-influencia`) ? 'text-no-active' : ''}>Ações de Influência</span>
                                                </NavLink>
                                            }
                                            <NavLink className="nav-link text-white proposicao-menu" activeClassName="active text-white" to={`/parlamentar/${idParlamentar}/comissoes`}>
                                                <span className={!url.endsWith(`/parlamentar/${idParlamentar}/comissoes`) ? 'text-no-active' : ''}>Comissões</span>
                                            </NavLink>
                                            <NavLink className="nav-link text-white proposicao-menu" activeClassName="active text-white" to={`/parlamentar/${idParlamentar}/proposicoes`}>
                                                <span className={!url.endsWith(`/parlamentar/${idParlamentar}/proposicoes`) ? 'text-no-active' : ''}>Proposições</span>
                                            </NavLink>
                                            <NavLink className="nav-link text-white proposicao-menu" activeClassName="active text-white" to={`/parlamentar/${idParlamentar}/discursos`}>
                                                <span className={!url.endsWith(`/parlamentar/${idParlamentar}/discursos`) ? 'text-no-active' : ''}>Discursos</span>
                                            </NavLink>
                                        </nav>
                                    </div> */}


                                        <div className="menu-proposicao text-center px-3 mb-3 rounded">
                                            <Slider {...sliderSettingsMenu}>

                                                {this.props.authStore.ehEsforcoColaborativo === false ?
                                                    <NavLink
                                                        to={`/parlamentar/${this.props.id}/acontecimentos`}
                                                        className="nav-link outline"
                                                        activeClassName="active">
                                                        <span>Acontecimentos</span>
                                                    </NavLink>
                                                    : null
                                                }
                                                {this.props.authStore.ehEsforcoColaborativo === false ?
                                                    <NavLink
                                                        to={`/parlamentar/${this.props.id}/acao-influencia`}
                                                        className="nav-link outline"
                                                        activeClassName="active">
                                                        <span>Ação de Influência</span>
                                                    </NavLink>
                                                    : null
                                                }

                                                <NavLink
                                                    to={`/parlamentar/${this.props.id}/proposicao`}
                                                    className="nav-link outline"
                                                    activeClassName="active">
                                                    <span>Proposição</span>
                                                </NavLink>

                                                <NavLink
                                                    to={`/parlamentar/${this.props.id}/comissoes`}
                                                    className="nav-link outline"
                                                    activeClassName="active">
                                                    <span>Comissões</span>
                                                </NavLink>

                                                <NavLink
                                                    to={`/parlamentar/${this.props.id}/discursos`}
                                                    className="nav-link outline"
                                                    activeClassName="active">
                                                    <span>Discursos</span>
                                                </NavLink>

                                                <NavLink
                                                    to={`/parlamentar/${this.props.id}/outros`}
                                                    className="nav-link outline"
                                                    activeClassName="active">
                                                    <span>Outros</span>
                                                </NavLink>

                                            </Slider>
                                        </div>

                                        <Route exact path={`/parlamentar/:id/acontecimentos`} component={() => <ParlamentarAcontecimentos idParlamentar={idParlamentar} />} />
                                        <Route exact path={`/parlamentar/:id/acao-influencia`} component={() => <ParlamentarDefault dados={parlamentar} proposicoes={acaoDeInfluencia} anexos={this.state.anexos} influencia={this.state.influenciaSobre} idsAcoes={this.state.idsAcoes} />} />
                                        <Route exact path={`/parlamentar/:id/proposicao`} component={() => <ParlamentarProposicao dados={parlamentar} />} />
                                        <Route exact path={`/parlamentar/:id/comissoes`} component={() => <ParlamentarComissoes dados={parlamentar} />} />
                                        <Route exact path={`/parlamentar/:id/discursos`} component={() => <ParlamentarDiscursos dados={parlamentar} />} />
                                        <Route exact path={`/parlamentar/:id/outros`} component={() => <ParlamentarOutros dados={parlamentar} idParlamentar={idParlamentar} />} />

                                        <Route exact path={`/parlamentar/:id/criar-acao`} render={() =>
                                            <Modal titulo="Criar Ação de Influência" goBack={`/parlamentar/${idParlamentar}/acoes-influencia`}>
                                                <FormAcaoParlamentar idParlamentar={idParlamentar} nomeParlamentar={parlamentar.nomeEleitoral} />
                                            </Modal>} />
                                        <Route exact path={`/parlamentar/:id/editar-acao/:acao`} render={() =>
                                            <Modal titulo="Editar Ação de Influência" goBack={`/parlamentar/${idParlamentar}/acoes-influencia`}>
                                                <FormAcaoParlamentar idParlamentar={idParlamentar} nomeParlamentar={parlamentar.nomeEleitoral} />
                                            </Modal>} />
                                    </Fragment>
                                </div>
                                {this.props.authStore.ehEsforcoColaborativo === true || this.props.authStore.ehVisualizador === true ? null :
                                    <div className={`
                                ${this.props.authStore.ehAdministrador === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.podeEditar === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.ehVisualizador === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.ehEsforcoColaborativo === true ? "col-md-3 col-lg-3" : ""} 
                            `}
                                        css={'mb-2'}>
                                        <div className="d-none d-sm-block">
                                            <p className="menu-parlamentar">Menu Parlamentar</p>
                                            <label>Qualificação</label>
                                            <div className="d-flex">
                                                {this.state.qualificacao.id === undefined ? <p className={`Client-circulo ${icone} mt-3 m-0`}></p> : null}
                                                <ClientSelect
                                                    items={parlamentarQualificacao}
                                                    value={this.state.qualificacao.id || this.state.qualificacaoParlamentar}
                                                    onChange={(newValue) => this.alterarQualificacao(newValue)}
                                                    placeholder={this.state.qualificacaoParlamentar === '' ? this.state.qualificacao.value : this.state.qualificacaoParlamentar}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label><b>Observações </b></label>
                                                <textarea
                                                    className="mt-2 form-control form-control-lg  d-inline-block  text-area-analise-impacto text-truncate text-wrap overflow-auto"
                                                    id="notas"
                                                    name="notas"
                                                    rows="6"
                                                    cols="33"
                                                    maxLength="4000"
                                                    placeholder="Digite aqui"
                                                    onBlur={event => this.alterarObservacao(event)}
                                                    defaultValue={this.state.observacao && this.state.observacao !== null ? this.state.observacao : null}
                                                />
                                                <span className="d-flex justify-content-end">{this.state.observacao && this.state.observacao !== null ? this.state.observacao.length : ''}/4000</span>
                                                {/* {this.state.observacao !== '' ? this.state.observacao : null}
                                        </textarea> */}
                                            </div>
                                            {/* <NavLink className="btn btn-outline-primary btn-block mb-4" to={`/parlamentar/${idParlamentar}/criar-acao`}>Criar ação de influência</NavLink> */}
                                            {/* <FormEstrategia onMouseDown={event => this.salvarObservacao(event)} titulo="Observações" esconderPosicao={true} notas={estrategia && estrategia.NOTAS} url={`/parlamentar/${idParlamentar}/dados`} /> */}
                                            <ProximosEventos idParlamentar={idParlamentar} />
                                        </div>
                                    </div>
                                }
                                {this.props.authStore.ehVisualizador === true ?
                                    <div className={`
                                ${this.props.authStore.ehAdministrador === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.podeEditar === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.ehVisualizador === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.ehEsforcoColaborativo === true ? "col-md-3 col-lg-3" : ""}
                                `} css={'mb-2'}>
                                        <div className="d-none d-sm-block">
                                            <p className="menu-parlamentar">Menu Parlamentar</p>
                                            <label>Qualificação</label>
                                            <div className="border p-2 d-flex">
                                                <div className={`
                                                ${this.state.qualificacaoParlamentar === "Divergente" ? "Client-circulo negativo-foto" : ""} 
                                                ${this.state.qualificacaoParlamentar === "Convergente" ? "Client-circulo sucesso-foto" : ""}
                                                ${this.state.qualificacaoParlamentar === "Não Classificado" ? "Client-circulo pendente-foto" : ""}
                                                ${this.state.qualificacaoParlamentar === "Neutro" ? "Client-circulo neutro-foto" : ""}
                                            `}></div>
                                                <div >{this.state.qualificacaoParlamentar !== null && this.state.qualificacaoParlamentar !== '' ? this.state.qualificacaoParlamentar : 'Não foi qualificado!'}</div>
                                            </div>

                                            <div className="mt-5">
                                                <label><b>Observações</b></label>
                                                <div className="border h-100 text-truncate text-wrap p-2">{this.state.observacao && this.state.observacao !== null ? this.state.observacao : 'Não tem nenhuma observação!'}</div>
                                            </div>
                                            {/* <NavLink className="btn btn-outline-primary btn-block mb-4" to={`/parlamentar/${idParlamentar}/criar-acao`}>Criar ação de influência</NavLink> */}
                                            {/* <FormEstrategia onMouseDown={event => this.salvarObservacao(event)} titulo="Observações" esconderPosicao={true} notas={estrategia && estrategia.NOTAS} url={`/parlamentar/${idParlamentar}/dados`} /> */}
                                            <ProximosEventos idParlamentar={idParlamentar} />
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>


                    :

                    <div className="">

                        {/* >>>>>>>>>>>>> DESK CODE <<<<<<<<<<<<<<*/}

                        <div className=" bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                            <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                                {/* PARLAMENTAR - <span>{parlamentar.nome}</span> */}
                                PARLAMENTAR
                            </p>
                        </div>
                        {/* <Breadcrumb /> */}
                        <div className="container-fluid pt-5">
                            {/* BOTAO SEGUI COMENTADO ---- NAO EXCLUIR */}
                            <MediaItem
                                css="h4 text-capitalize"
                                cssSubtitle="text-capitalize"
                                size="large"
                                icon="icon-senator-small"
                                border="mb-3 border-bottom"
                                title={`${parlamentar.nome.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); })}`}
                                statusParlamentar={parlamentar.situacao ? `EM ${parlamentar.situacao}` : 'Inativo'}
                                info={[{ value: parlamentar.siglaPartido + ' | ' + parlamentar.tratamento + ' - ' + parlamentar.ufExercicio }]}>
                                {/* <Seguir status={!!(estrategia && estrategia.SEGUINDO)} url={`/parlamentar/${idParlamentar}/seguir`} /> */}
                                {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                    this.state.seguindo === false ?
                                        <button onClick={event => this.seguirParlamentar(event, idParlamentar)} className="parlamentar-botao-seguir" style={{ borderRadius: "7px" }} disabled={this.props.authStore.ehVisualizador === true ? true : false}>Seguir</button>
                                        :
                                        <button onClick={event => this.naoSeguirParlamentar(event, idParlamentar)} className="parlamentar-botao-seguindo" style={{ borderRadius: "7px" }} disabled={this.props.authStore.ehVisualizador === true ? true : false}>Seguindo</button>
                                }
                            </MediaItem>
                            <div className="row">
                                <div className={`
                                ${this.props.authStore.ehAdministrador === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.podeEditar === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.ehVisualizador === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.ehEsforcoColaborativo === true ? "col-md-3 col-lg-3" : ""} 
                            ` } css={'mb-2'}>

                                    <div className="bg-white">
                                        <div className="carousel-item1  active pt-3">
                                            {this.props.authStore.ehEsforcoColaborativo === true ?
                                                <img alt="" src={parlamentar.urlFoto ? parlamentar.urlFoto : require('../../design/assets/images/avatar-parlamentar.png')} className={`d-block m-auto foto-parlamentar foto-parlamentar-div`} />
                                                :
                                                <div>
                                                    <img alt="" src={parlamentar.urlFoto ? parlamentar.urlFoto : require('../../design/assets/images/avatar-parlamentar.png')} className={`d-block m-auto ${this.state.imgFoto === '' ? imgFoto : this.state.imgFoto} foto-parlamentar foto-parlamentar-div`} />
                                                    <div className="carousel-caption">
                                                        <h5 className="text-capitalize mt-3 qualificacao-titulo-parlamentar">PARLAMENTAR <span className="font-weight-bold">{this.state.qualificacao.value === undefined ? this.state.qualificacaoParlamentar ? this.state.qualificacaoParlamentar : '' : this.state.qualificacao.value}</span></h5>
                                                    </div>
                                                </div>
                                            }

                                            {parlamentar.cargo === 'Senador' || parlamentar.cargo === 'Senadora' || parlamentar.cargo === 'Deputado' || parlamentar.cargo === 'Deputada' ?
                                                <NavLink to={`/parlamentar/${parlamentar.cargo}`}>{parlamentar.cargo}</NavLink> : <div>{parlamentar.cargo}</div>
                                            }
                                            <p />
                                            {/* {parlamentar.siglaPartido ?
                                            // <NavLink to={`/parlamentar/partido/${parlamentar.siglaPartido}`}>
                                            //     <span>{parlamentar.siglaPartido} - </span>
                                            // </NavLink> : <span>{parlamentar.siglaPartido}</span>
                                            <div className="d-block m-auto text-center" >{parlamentar.siglaPartido}</div>
                                            : null
                                        }
                                        {parlamentar.siglaUf ?
                                            // <NavLink to={`/parlamentar/estado/${parlamentar.siglaUf}`}>
                                            //     <span className="m-0 mb-2 text-uppercase">{parlamentar.siglaUf}</span>
                                            // </NavLink> : <span>{parlamentar.siglaUf}</span>
                                            <div className="m-auto mb-2 text-uppercase">{parlamentar.siglaUf}</div>
                                            : null
                                        }  */}
                                        </div>
                                    </div>

                                    <div className="bg-white p-3 mt-3">
                                        <div>
                                            {isNaN(this.props.id) === false ?
                                                <div>
                                                    {parlamentar.dataFalecimento === null ?
                                                        <div>
                                                            <h6 className="mb-1"><b>Idade</b></h6>
                                                            <p className="text-wrap ">{`${calcularIdade(parlamentar.dataNascimento)} anos - ${formatarData(parlamentar.dataNascimento)}`}</p>
                                                        </div>
                                                        :
                                                        <div>
                                                            <h6 className="mb-1"><b>Nascimento - Falecimento</b></h6>
                                                            <p className="text-wrap ">{`${moment(parlamentar.dataNascimento).format('DD/MM/YYYY')} - ${moment(parlamentar.dataFalecimento).format('DD/MM/YYYY')}`}</p>
                                                        </div>
                                                    }
                                                </div>
                                                : null
                                            }
                                        </div>
                                        {parlamentar.nomeProfissao &&
                                            <div>
                                                <h6 className="mb-1"><b>Profissão</b></h6>
                                                <p className="text-wrap">{parlamentar.nomeProfissao || '-'}</p>
                                            </div>
                                        }
                                        {parlamentar.escolaridade &&
                                            <div>
                                                <h6 className="mb-1"><b>Escolaridade</b></h6>
                                                <p className="text-wrap">{parlamentar.escolaridade || '-'}</p>
                                            </div>
                                        }
                                    </div>

                                    <div className="bg-white p-3 mt-3">
                                        {/* <div className="bg-primary p-3 mt-3"> */}
                                        <div>
                                            <h6 className="mb-1"><b>E-mail</b></h6>
                                            <p className="text-wrap ">{parlamentar.email || '-'}</p>
                                        </div>
                                        <div>
                                            <h6 className="mb-1"><b>Telefone do Gabinete API</b></h6>
                                            {
                                                telefonesApi !== undefined &&
                                                    Array.isArray(telefonesApi) && telefonesApi.length > 0 ?
                                                    telefonesApi.map((telefone, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <p className="text-wrap ">{telefone}</p>
                                                            </div>
                                                        )
                                                    }) : null
                                            }
                                            <p className="text-wrap"></p>
                                        </div>
                                        <div className="mb-3 mt-3">
                                            {/* <input
                                            type="tel"
                                            className="Client-input Client-texto-form  d-inline-block text-truncate text-wrap overflow-auto"
                                            placeholder="Telefones do Parlamentar"
                                            value={this.state.telefone || ''}
                                            autoComplete="off"
                                            maxLength={40}
                                            onChange={(event) => this.salvarTelefone(event)}
                                            onBlur={event => this.inserirParlamentarCliente(event.target.value, true)}
                                        /> */}

                                            {this.props.authStore.ehVisualizador === false ?
                                                this.props.authStore.ehEsforcoColaborativo === false ?
                                                    <div>
                                                        <h6 className="mb-1"><b>Telefones do parlamentar</b></h6>
                                                        <textarea
                                                            className="mt-2 form-control form-control-lg  d-inline-block  text-area-analise-impacto text-truncate text-wrap overflow-auto"
                                                            id="telefone"
                                                            name="telefone"
                                                            rows="2"
                                                            cols="4"
                                                            placeholder="Digite aqui os telefones que desejar!"
                                                            onBlur={event => this.alterarTelefone(event)}
                                                            defaultValue={this.state.descricao !== '' ? this.state.descricao : null}
                                                        />
                                                    </div>

                                                    : null
                                                :

                                                <div className="border p-3 h-100">
                                                    {this.state.descricao !== '' && this.state.descricao !== null && this.state.descricao !== undefined ? this.state.descricao : ""}
                                                </div>

                                            }

                                        </div>
                                        <div>
                                            <h6 className="mb-1"><b>Endereço</b></h6>
                                            <p className="text-wrap">{parlamentar.endereco}</p>
                                        </div>
                                        <div className="biografia-parlamentar-link">

                                            {parlamentar.casa === 1 && parlamentar.uri === '' ?
                                                <a href={`https://www25.senado.leg.br/web/senadores/senador/-/perfil/${parlamentar.id_parlamentar}`} className="biografia-parlamentar" target='_blank' rel='noopener noreferrer'><img alt="parlamanetar-url" className="mr-2" src={require('../../design/assets/images/trending.svg')} />Biografia Completa</a>
                                                :
                                                <a href={parlamentar.uri} className="biografia-parlamentar" target='_blank' rel='noopener noreferrer'><img alt="parlamanetar-url" className="mr-2" style={{ content: "var(--setaBiografia)" }} />Biografia Completa</a>
                                            }
                                        </div>

                                        {/* <div>
                                        <h6 className="mb-1">Telefone do Gabinete</h6>
                                        <p className="text-wrap ">{gabinete.telefone || '-'}</p>
                                    </div> */}
                                        {/* {gabinete.nome &&
                                        <div>
                                            <h6 className="mb-1">Prédio {gabinete.predio}, {gabinete.andar}º Andar - Gabinete {gabinete.nome}</h6>
                                            <p className="text-wrap "></p>
                                        </div>
                                    } */}

                                    </div>

                                    {this.props.authStore.ehEsforcoColaborativo === true || this.props.authStore.ehVisualizador === true ? null :
                                        <div className="d-block d-sm-none">
                                            <p className="menu-parlamentar">Menu Parlamentar</p>
                                            <label>Qualificação</label>
                                            <div className="d-flex">
                                                {this.state.qualificacao.id === undefined ? <p className={`Client-circulo ${icone} mt-3 m-0`}></p> : null}
                                                <ClientSelect
                                                    items={parlamentarQualificacao}
                                                    value={this.state.qualificacao.id || this.state.qualificacaoParlamentar}
                                                    onChange={(newValue) => this.alterarQualificacao(newValue)}
                                                    placeholder={this.state.qualificacaoParlamentar === '' ? this.state.qualificacao.value : this.state.qualificacaoParlamentar ? this.state.qualificacaoParlamentar : ''}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label><b>Observações</b></label>
                                                <textarea
                                                    className="mt-2 form-control form-control-lg  d-inline-block  text-area-analise-impacto text-truncate text-wrap overflow-auto"
                                                    id="notas"
                                                    name="notas"
                                                    rows="6"
                                                    cols="33"
                                                    placeholder="Digite aqui"
                                                    maxLength="4000"
                                                    onBlur={event => this.alterarObservacao(event)}
                                                    defaultValue={this.state.observacao && this.state.observacao !== null ? this.state.observacao : null}
                                                />
                                                <span className="d-flex justify-content-end">{this.state.observacao && this.state.observacao !== null && this.state.observacao.length ? this.state.observacao.length : ''}/4000</span>
                                                {/* {this.state.observacao !== '' ? this.state.observacao : null}
                                        </textarea> */}
                                            </div>
                                            {/* <NavLink className="btn btn-outline-primary btn-block mb-4" to={`/parlamentar/${idParlamentar}/criar-acao`}>Criar ação de influência</NavLink> */}
                                            {/* <FormEstrategia onMouseDown={event => this.salvarObservacao(event)} titulo="Observações" esconderPosicao={true} notas={estrategia && estrategia.NOTAS} url={`/parlamentar/${idParlamentar}/dados`} /> */}
                                            <ProximosEventos idParlamentar={idParlamentar} />
                                        </div>
                                    }
                                    {this.props.authStore.ehVisualizador === true ?
                                        <div className="d-block d-sm-none">
                                            <label>Qualificação</label>
                                            <div>{this.state.qualificacaoParlamentar && this.state.qualificacaoParlamentar !== null && this.state.qualificacaoParlamentar !== '' ? this.state.qualificacaoParlamentar : 'Não foi qualificado!'}</div>

                                            <div className="mt-5">
                                                <label><b>Observações</b></label>
                                                <div className="text-truncate text-wrap p-2">{this.state.observacao && this.state.observacao !== null ? this.state.observacao : 'Não tem nenhuma observação!'}</div>
                                            </div>
                                            {/* <NavLink className="btn btn-outline-primary btn-block mb-4" to={`/parlamentar/${idParlamentar}/criar-acao`}>Criar ação de influência</NavLink> */}
                                            {/* <FormEstrategia onMouseDown={event => this.salvarObservacao(event)} titulo="Observações" esconderPosicao={true} notas={estrategia && estrategia.NOTAS} url={`/parlamentar/${idParlamentar}/dados`} /> */}
                                            <ProximosEventos idParlamentar={idParlamentar} />
                                        </div>
                                        : null}
                                </div>
                                <div className={`
                                ${this.props.authStore.ehAdministrador === true ? "col-md-6 col-lg-6" : ""}
                                ${this.props.authStore.podeEditar === true ? "col-md-6 col-lg-6" : ""}
                                ${this.props.authStore.ehVisualizador === true ? "col-md-6 col-lg-6" : ""}
                                ${this.props.authStore.ehEsforcoColaborativo === true ? "col-md-9 col-lg-9" : ""}
                            `} css={'mb-2'}>
                                    <Fragment>
                                        {/* <div className="bg-blue px-3 mb-3 rounded">
                                        <nav className="nav mb-3 fs-12 text-uppercase d-flex justify-content-between">
                                            <NavLink className="nav-link text-white proposicao-menu" activeClassName="active text-white"
                                                isActive={() => url.endsWith(`/parlamentar/${idParlamentar}`) || url.endsWith(`/parlamentar/${idParlamentar}/detalhes`)} to={`/parlamentar/${idParlamentar}/detalhes`}>
                                                <span className={!(url.endsWith(`/parlamentar/${idParlamentar}/detalhes`) || url.endsWith(`/parlamentar/${idParlamentar}`)) ? 'text-no-active' : ''}>Detalhes</span>
                                            </NavLink>
                                            {!authStore.podeEditar ? null :
                                                <NavLink className="nav-link text-white proposicao-menu" activeClassName="active text-white" to={`/parlamentar/${idParlamentar}/acoes-influencia`}>
                                                    <span className={!url.endsWith(`/parlamentar/${idParlamentar}/acoes-influencia`) ? 'text-no-active' : ''}>Ações de Influência</span>
                                                </NavLink>
                                            }
                                            <NavLink className="nav-link text-white proposicao-menu" activeClassName="active text-white" to={`/parlamentar/${idParlamentar}/comissoes`}>
                                                <span className={!url.endsWith(`/parlamentar/${idParlamentar}/comissoes`) ? 'text-no-active' : ''}>Comissões</span>
                                            </NavLink>
                                            <NavLink className="nav-link text-white proposicao-menu" activeClassName="active text-white" to={`/parlamentar/${idParlamentar}/proposicoes`}>
                                                <span className={!url.endsWith(`/parlamentar/${idParlamentar}/proposicoes`) ? 'text-no-active' : ''}>Proposições</span>
                                            </NavLink>
                                            <NavLink className="nav-link text-white proposicao-menu" activeClassName="active text-white" to={`/parlamentar/${idParlamentar}/discursos`}>
                                                <span className={!url.endsWith(`/parlamentar/${idParlamentar}/discursos`) ? 'text-no-active' : ''}>Discursos</span>
                                            </NavLink>
                                        </nav>
                                    </div> */}


                                        <div className="menu-proposicao text-center px-3 mb-3 rounded">
                                            <Slider {...sliderSettingsMenu}>

                                                {this.props.authStore.ehEsforcoColaborativo === false ?
                                                    <NavLink
                                                        to={`/parlamentar/${this.props.id}/acontecimentos`}
                                                        className="nav-link outline"
                                                        activeClassName="active">
                                                        <span>Acontecimentos</span>
                                                    </NavLink>
                                                    : null
                                                }
                                                {this.props.authStore.ehEsforcoColaborativo === false ?
                                                    <NavLink
                                                        to={`/parlamentar/${this.props.id}/acao-influencia`}
                                                        className="nav-link outline"
                                                        activeClassName="active">
                                                        <span>Ação de Influência</span>
                                                    </NavLink>
                                                    : null
                                                }

                                                <NavLink
                                                    to={`/parlamentar/${this.props.id}/proposicao`}
                                                    className="nav-link outline"
                                                    activeClassName="active">
                                                    <span>Proposição</span>
                                                </NavLink>

                                                <NavLink
                                                    to={`/parlamentar/${this.props.id}/comissoes`}
                                                    className="nav-link outline"
                                                    activeClassName="active">
                                                    <span>Comissões</span>
                                                </NavLink>

                                                <NavLink
                                                    to={`/parlamentar/${this.props.id}/discursos`}
                                                    className="nav-link outline"
                                                    activeClassName="active">
                                                    <span>Discursos</span>
                                                </NavLink>

                                                <NavLink
                                                    to={`/parlamentar/${this.props.id}/outros`}
                                                    className="nav-link outline"
                                                    activeClassName="active">
                                                    <span>Outros</span>
                                                </NavLink>

                                            </Slider>
                                        </div>

                                        <Route exact path={`/parlamentar/:id/acontecimentos`} component={() => <ParlamentarAcontecimentos idParlamentar={idParlamentar} />} />
                                        <Route exact path={`/parlamentar/:id/acao-influencia`} component={() => <ParlamentarDefault dados={parlamentar} proposicoes={acaoDeInfluencia} anexos={this.state.anexos} influencia={this.state.influenciaSobre} idsAcoes={this.state.idsAcoes} />} />
                                        <Route exact path={`/parlamentar/:id/proposicao`} component={() => <ParlamentarProposicao dados={parlamentar} />} />
                                        <Route exact path={`/parlamentar/:id/comissoes`} component={() => <ParlamentarComissoes dados={parlamentar} />} />
                                        <Route exact path={`/parlamentar/:id/discursos`} component={() => <ParlamentarDiscursos dados={parlamentar} />} />
                                        <Route exact path={`/parlamentar/:id/outros`} component={() => <ParlamentarOutros dados={parlamentar} idParlamentar={idParlamentar} />} />

                                        <Route exact path={`/parlamentar/:id/criar-acao`} render={() =>
                                            <Modal titulo="Criar Ação de Influência" goBack={`/parlamentar/${idParlamentar}/acoes-influencia`}>
                                                <FormAcaoParlamentar idParlamentar={idParlamentar} nomeParlamentar={parlamentar.nomeEleitoral} />
                                            </Modal>} />
                                        <Route exact path={`/parlamentar/:id/editar-acao/:acao`} render={() =>
                                            <Modal titulo="Editar Ação de Influência" goBack={`/parlamentar/${idParlamentar}/acoes-influencia`}>
                                                <FormAcaoParlamentar idParlamentar={idParlamentar} nomeParlamentar={parlamentar.nomeEleitoral} />
                                            </Modal>} />
                                    </Fragment>
                                </div>
                                {this.props.authStore.ehEsforcoColaborativo === true || this.props.authStore.ehVisualizador === true ? null :
                                    <div className={`
                                ${this.props.authStore.ehAdministrador === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.podeEditar === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.ehVisualizador === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.ehEsforcoColaborativo === true ? "col-md-3 col-lg-3" : ""} 
                            `}
                                        css={'mb-2'}>
                                        <div className="d-none d-sm-block">
                                            <p className="menu-parlamentar">Menu Parlamentar</p>
                                            <label>Qualificação</label>
                                            <div className="d-flex">
                                                {this.state.qualificacao.id === undefined ? <p className={`Client-circulo ${icone} mt-3 m-0`}></p> : null}
                                                <ClientSelect
                                                    items={parlamentarQualificacao}
                                                    value={this.state.qualificacao.id || this.state.qualificacaoParlamentar}
                                                    onChange={(newValue) => this.alterarQualificacao(newValue)}
                                                    placeholder={this.state.qualificacaoParlamentar === '' ? this.state.qualificacao.value : this.state.qualificacaoParlamentar}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label><b>Observações </b></label>
                                                <textarea
                                                    className="mt-2 form-control form-control-lg  d-inline-block  text-area-analise-impacto text-truncate text-wrap overflow-auto"
                                                    id="notas"
                                                    name="notas"
                                                    rows="6"
                                                    cols="33"
                                                    maxLength="4000"
                                                    placeholder="Digite aqui"
                                                    onBlur={event => this.alterarObservacao(event)}
                                                    defaultValue={this.state.observacao && this.state.observacao !== null ? this.state.observacao : null}
                                                />
                                                <span className="d-flex justify-content-end">{this.state.observacao && this.state.observacao !== null ? this.state.observacao.length : ''}/4000</span>
                                                {/* {this.state.observacao !== '' ? this.state.observacao : null}
                                        </textarea> */}
                                            </div>
                                            {/* <NavLink className="btn btn-outline-primary btn-block mb-4" to={`/parlamentar/${idParlamentar}/criar-acao`}>Criar ação de influência</NavLink> */}
                                            {/* <FormEstrategia onMouseDown={event => this.salvarObservacao(event)} titulo="Observações" esconderPosicao={true} notas={estrategia && estrategia.NOTAS} url={`/parlamentar/${idParlamentar}/dados`} /> */}
                                            <ProximosEventos idParlamentar={idParlamentar} />
                                        </div>
                                    </div>
                                }
                                {this.props.authStore.ehVisualizador === true ?
                                    <div className={`
                                ${this.props.authStore.ehAdministrador === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.podeEditar === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.ehVisualizador === true ? "col-md-3 col-lg-3" : ""}
                                ${this.props.authStore.ehEsforcoColaborativo === true ? "col-md-3 col-lg-3" : ""}
                                `} css={'mb-2'}>
                                        <div className="d-none d-sm-block">
                                            <p className="menu-parlamentar">Menu Parlamentar</p>
                                            <label>Qualificação</label>
                                            <div className="border p-2 d-flex">
                                                <div className={`
                                                ${this.state.qualificacaoParlamentar === "Divergente" ? "Client-circulo negativo-foto" : ""} 
                                                ${this.state.qualificacaoParlamentar === "Convergente" ? "Client-circulo sucesso-foto" : ""}
                                                ${this.state.qualificacaoParlamentar === "Não Classificado" ? "Client-circulo pendente-foto" : ""}
                                                ${this.state.qualificacaoParlamentar === "Neutro" ? "Client-circulo neutro-foto" : ""}
                                            `}></div>
                                                <div >{this.state.qualificacaoParlamentar && this.state.qualificacaoParlamentar !== null && this.state.qualificacaoParlamentar !== '' ? this.state.qualificacaoParlamentar : 'Não foi qualificado!'}</div>
                                            </div>

                                            <div className="mt-5">
                                                <label><b>Observações</b></label>
                                                <div className="border h-100 text-truncate text-wrap p-2">{this.state.observacao && this.state.observacao !== null ? this.state.observacao : 'Não tem nenhuma observação!'}</div>
                                            </div>
                                            {/* <NavLink className="btn btn-outline-primary btn-block mb-4" to={`/parlamentar/${idParlamentar}/criar-acao`}>Criar ação de influência</NavLink> */}
                                            {/* <FormEstrategia onMouseDown={event => this.salvarObservacao(event)} titulo="Observações" esconderPosicao={true} notas={estrategia && estrategia.NOTAS} url={`/parlamentar/${idParlamentar}/dados`} /> */}
                                            <ProximosEventos idParlamentar={idParlamentar} />
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>


                }


            </Fragment >
    }
}
export default props => <AcaoInfluencia {...props} />