import React, { Fragment, Component } from 'react'
import { inject, observer } from 'mobx-react'
import FormLogin from './login'
import FormCriarNovaSenha from './criarNovaSenha'

@inject('authStore')
@observer
class Login extends Component {
    login = this.props.authStore.login
    componentDidMount() {
        this.props.authStore.zerar()

    }

    render() {
        const tamanhoTela = window.innerWidth
        return <Fragment>

            {
                tamanhoTela < 600
                    ?
                    <div className='row d-block d-sm-none' style={{ margin: "0px", width: "100%" }}>

                        <div className="text-center overFlowDiv">

                            <div className="imgBrasilia">

                            </div>
                            <div className="bglogin">

                            </div>
                            <div className="logoAlign">
                                <div className="logoLogin"></div>
                                <div className="divFormLoginMobile">
                                    {this.props.authStore.deveCriarNovaSenha ? <FormCriarNovaSenha /> : <FormLogin />}
                                </div>
                            </div>

                        </div>


                    </div>


                    :



                    <div className="d-none d-sm-block">
                        <div className='row'>

                            <div className="col-md-6 col-sm-12 text-center overFlowDiv" >

                                <div className="imgBrasilia">

                                </div>
                                <div className="bglogin">

                                </div>
                                <div className="logoAlign">
                                    <div className="logoLogin"></div>
                                </div>

                            </div>
                            <div className="col-md-6 col-sm-12 overFlowDiv">
                                <div className="overFlowDivRight">
                                    {/* <div className="text-right">
                                <div className="cadastre">Não possui uma conta? &nbsp;
                                    <a href="/cadastrar"><span style={{color:'var(--linkText)',textDecoration:'underline'}}> Cadastre-se </span> </a> 
                                </div>
                            </div> */}

                                    <div className=" divFormLogin col-12 col-sm-8  col-lg-8 col-xl-9">

                                        {this.props.authStore.deveCriarNovaSenha ? <FormCriarNovaSenha /> : <FormLogin />}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

            }

        </Fragment>
    }
}

export default Login