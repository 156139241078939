import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Link from '../../componentes/link'

@inject('authStore', 'menuStore')
@observer
class MenuDropdown extends Component {

    state = { aberto: false }

    constructor(props) {
        super(props)
        this.iptCpnt = React.createRef()
        this.iptDropdown = React.createRef()
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }

    componentDidMount() { this.updateWindowDimensions(); window.addEventListener('resize', this.updateWindowDimensions) }

    componentDidUpdate() {
        let cpnt = this.iptCpnt.current
        let drop = this.iptDropdown.current
        let soma = cpnt.offsetTop + drop.clientHeight
        if (soma > this.state.height && this.state.width > 360) {
            drop.style.top = `-${drop.clientHeight - cpnt.clientHeight + 12}px`
        }
    }

    componentWillUnmount() { window.removeEventListener('resize', this.updateWindowDimensions) }
    updateWindowDimensions() { this.setState({ width: window.innerWidth, height: window.innerHeight }) }

    toogle = () => this.setState({ aberto: !this.state.aberto })
    close = () => this.setState({ aberto: false })

    renderRows() {
        const redirectUri = window.location.href;
        return this.props.list !== undefined && Array.isArray(this.props.list) && this.props.list.length > 0 ?
            this.props.list.map((item, index) => item.path !== '/logout' ?
                <div key={`menu-div-${index}`} onClick={() => this.close()}>
                    <Link nome={typeof item.name === 'function' ? item.name() : item.name}
                        url={item.path} css={`dropdown-item ${item.css || ''}`} key={`menu-${index}`}
                    ></Link>
                </div>

                : 
                <div key={`menu-div-${index}`} onClick={e => { e.preventDefault(); this.props.authStore.logout(redirectUri) }}>
                    <Link nome={typeof item.name === 'function' ? item.name() : item.name}
                        url='/logout' css={`dropdown-item ${item.css || ''}`} key={`menu-${index}`}
                    ></Link>
                </div>
                
                // <a href="#section" nome={item.name} onClick={e => { e.preventDefault(); this.props.authStore.logout() }} className="dropdown-item" key={`menu-${index}`}>Sair</a>
            ) : null
    }

    render() {
        return (
            <div ref={this.iptCpnt} className="btn-menu-dropdown">
                <button type="button" className={`btn-menu ${this.props.css || ''}`} onClick={() => this.toogle()}>
                    {this.props.icon ? <i className={this.props.icon}></i> : '?'}
                </button>
                <div ref={this.iptDropdown} className={`dropdown-menu px-2 ${this.state.aberto ? 'show' : ''}`}>
                    {this.renderRows()}
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default MenuDropdown