import {observable, action} from 'mobx'
//import {get, getSemToken, put} from '../services/http'
import {get, put} from '../services/http'
import authStore from './authStore'
import alertaStore from "../stores/alertaStore"
//import {get, getSemToken, put} from '../services/http'
// import {get, put} from '../services/http'

class NotificacaoStore {
    tempo = 120
    interval = null

    @observable notificacoes = {itens: [], total: 0, naoLidas: 0}

    constructor() {
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.buscar = this.buscar.bind(this)
        this.alterarLida = this.alterarLida.bind(this)

        this.start()
        authStore.aoAutenticar(this.start)
        authStore.aoSair(this.stop)
    }

    @action
    start() {
        if (authStore.id) {
            // console.log(authStore.id)
            this.buscar()
            // if (this.interval === null){
            //     setInterval(this.buscar, this.tempo)
            // }
            if (Number.isInteger(this.tempo)) this.interval = setInterval(this.buscar, this.tempo * 1000)
        }
    }

    @action
    stop() {
        this.interval && clearInterval(this.interval)
    }

    @action
    async buscar() {
        try {
            //this.notificacoes = await getSemToken(`/notificacao/${btoa(authStore.id)}`)
            this.notificacoes = await get(`/notificacao/${btoa(authStore.id)}`)
        } catch (error) {
            console.error(error)
        }
    }

    @action
    async alterarLida(notificacaoId, lida) {
        await put(`/notificacao/${notificacaoId}`, {lida})
        const naoLidas = this.notificacoes.naoLidas + (lida ? -1 : 1)
        this.notificacoes = {...this.notificacoes, naoLidas}
    }

    @action
    async arquivar(notificacaoId) {
        await put(`/notificacao/arquivar/${notificacaoId}`, {})
        alertaStore.criar("A notificação foi arquivada")
    }

    @action
    async arquivarVarias(tipo) {
        if (!['lidas', 'todas'].find(x => x === tipo)) throw new Error('Tipo inválido')
        await put(`/notificacao/arquivar/${tipo}`, {})
        await this.buscar()
        const mensagem = tipo === 'todas' ? 'Todas notificações' : 'Notificações lidas'
        alertaStore.criar(`${mensagem} foram arquivadas`)
    }

    @action
    async like(notificacaoId) {
        await put(`/notificacao/like/${notificacaoId}`, {})
        alertaStore.criar("Obrigado por informar! Você está ajudando o purpleClient a ser cada vez melhor")
    }

    @action
    async dislike(notificacaoId) {
        await put(`/notificacao/dislike/${notificacaoId}`, {})
        alertaStore.aviso("Entendi e aprendi! Você não verá mais itens como esse")
    }

    @action
    async marcarTodasComoLida() {
        await put(`/notificacao/todas`)
        this.notificacoes = {...this.notificacoes, naoLidas: 0}
    }
}

export default new NotificacaoStore()
