import React, { Component } from 'react';

class TemaCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: false
    };
    this.onHover = this.onHover.bind(this);
  }
  onHover() {
    this.setState({
      icon: !this.state.icon
    })
  }
  render() {
    return (
      <>
        <div className="tema-card">
          <p className="favorite">
            <i 
              className={this.props.following || this.state.icon ? "icon-star" : "icon-star-without-fill"}
              onMouseLeave={this.onHover}
              onMouseOver={this.onHover}
            ></i>
          </p>
          <div className="figure">
            <img src={this.props.image} alt={this.props.name} />
          </div>
          <p className="text">{this.props.name}</p>
        </div>
      </>
    )
  }
}

export default TemaCard