import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

@inject('uploadStore', 'authStore')
@observer

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    this.state = {
      modulo: this.props.modulo || '',
      id_parlamentar: this.props.idParlamentar || '',
      id_referencia: this.props.id_referencia || 0
    }
  }

  async onChange(event) {
    this.props.onChange(await this.props.uploadStore.carregarArquivo(event, this.state.modulo, this.state.id_referencia, this.props.create, this.state.id_parlamentar))
  }

  render() {
    console.log("UPUPPUPUPUPUPUP", this.props);

    const { modulo, id_referencia } = this.state;
    const name = `arquivos-${modulo}-${id_referencia}`;

    return (
      <div className="mr-auto">

        {this.props.authStore.ehVisualizador === true ? null :
          <label htmlFor={name} className="m-0 w-100">
            {
              this.props.linkButton ?
                <span id={this.props.id} className="cursor-pointer" style={{ color: "#9B9B9B", fontSize: "13px", textDecorationLine: "underline" }}>Anexar</span>
                :
                <div className="modal--files">
                  <span className="Client-btn btn-secundario" style={{ borderRadius: "7px" }}>
                    Anexar</span>
                </div>
            }
          </label>
        }


        {this.props.authStore.ehVisualizador === true ? null :
          <input type="file" id={name} className="entradaArquivo" onChange={this.onChange} multiple />
        }

        {this.props.uploadStore.loading === true ? <img src={require('../../../design/assets/images/loading.svg')} alt="carregando" width="10px" /> : null}
      </div>
    )
  }
}

export default UploadFile