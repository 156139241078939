import React, { Component } from 'react'
import { post } from '../../services/http'
import Pagination from "react-js-pagination";
import Loading from '../../componentes/loading'
import { NavLink } from 'react-router-dom'
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

class ParlamentarProposicao extends Component {
    state = { 
        loading: true,
        parlamentar: {}, 
        proposicoes: [], 
        limite: 50,
        paginaAtual: 1,
        ultimo: 0,
        quantidade: 100
    }

    async componentWillMount() {
        await Promise.all([
            this.getParlamentarDados(),
            this.getParlamentarProposicao()
        ]).then(x => this.setState({ loading: false }))
    }

    async getParlamentarDados() {
        // console.log('entrei aqui getParlamentarDados')
    }

    async getParlamentarProposicao(pagina) {
        const minhaPagina = pagina === undefined ? this.state.paginaAtual : pagina
        const data = { 
            id: this.props.dados.id,
            // nome_parlamentar: this.props.dados.nome, 
            limite: this.state.limite, 
            pagina: minhaPagina, 
            ultimo: minhaPagina === 1 ? 0 : (minhaPagina - 1) * this.state.limite,
        }
        await post('parlamentar/proposicao', data)
            .then(response => {
                this.setState({ quantidade: response.quantidade, proposicoes: response.resultados, loading: false })
            })
            .catch(err => console.log(err))
    }

    carregarPaginaNova = async pagina => {
        this.setState({ paginaAtual: pagina, loading: true })
        await this.getParlamentarProposicao(pagina)
      }


    render() {
        return (
            this.state.loading ? <Loading /> : 
            <div>

                <div className="row p-2 background-parlamentar-card">
                    {this.state.proposicoes !== undefined  
                    && Array.isArray(this.state.proposicoes) && this.state.proposicoes.length > 0 ?
                    this.state.proposicoes.map((item, index) => {
                        return (
                            <div className="col-12 p-3" key={index}>
                                <div className="parlamentar-card-proposicoes">

                                    <NavLink to={"/proposicao/" + item.id + "/acao-preparatoria"}>
                                        <div className="parlamentar-card-proposicoes-descricao">
                                            <img alt="proposicao" src={require('../../design/assets/images/proposicao-parlamentar.png')} className="mr-3" />
                                            {item.descricao}
                                        </div>
                                        <div className="parlamentar-card-proposicoes-data">
                                            <span>{moment(item.data_apresentacao).format('DD/MM/YYYY')}</span>
                                        </div>
                                    </NavLink>
                                    <div className="parlamentar-card-proposicoes-ementa">
                                        {item.ementa}
                                    </div>
                                </div>
                            </div>
                        )
                    }) : null }
                </div>

                <div className="pagination"> Exibindo resultados {(this.state.paginaAtual - 1) * this.state.limite} - {this.state.paginaAtual * this.state.limite} de um total de {this.state.quantidade}</div>
                  <Pagination
                    activePage={this.state.paginaAtual}
                    itemsCountPerPage={this.state.limite}
                    totalItemsCount={parseInt(this.state.quantidade)}
                    pageRangeDisplayed={10}
                    hideFirstLastPages={true}
                    onChange={this.carregarPaginaNova}
                    prevPageText={<div className="prev-page"><img src={require('../../design/assets/images/prev-page-pagination.svg')} alt="prev-page"/></div>}
                    nextPageText={<div className="next-page"><img src={require('../../design/assets/images/next-page-pagination.svg')} alt="next-page"/></div>}
                  /> 
                
            </div>
        )
    }
}

export default ParlamentarProposicao;