import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                labels: ['Mínima', 'Baixa', 'Média', 'Alta', 'Máxima'],
                colors: ['#F63854', '#4CD964', '#5856D7', '#FFD200', '#007AFF',],
                legend: {
                    show: false,

                }

            },
            // series: [
            //     0, 1, 2, 3, 4
            // ],
        }
    }


    render() {
        console.log(this.props.dados)
        const seriesProps = [
            this.props.dados !== undefined ? this.props.dados[0] : 0,
            this.props.dados !== undefined ? this.props.dados[1] : 0,
            this.props.dados !== undefined ? this.props.dados[2] : 0,
            this.props.dados !== undefined ? this.props.dados[3] : 0,
            this.props.dados !== undefined ? this.props.dados[4] : 0,
        ]

        return (
            <div className="donut divGraficoBuilding">
                <Chart
                    options={this.state.options}
                    series={seriesProps}
                    type="donut"
                />
            </div>
        );
    }
}

export default Donut;
