import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import Feed from '../feed'

export default () =>
    <Fragment>
        <Switch>
            <Route exact path="/feed" component={Feed} />
        </Switch>
    </Fragment>
