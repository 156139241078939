import React, { Component } from 'react'
import Loading from '../../componentes/loading';
// import Breadcrumb from '../../estruturas/breadcrumb'
import { inject, observer } from "mobx-react"
import { post, get } from "../../services/http";
import { FormDialog } from './form-relatorio-usuario'
import Pagination from "react-js-pagination";
import moment from 'moment-timezone';
import 'moment/locale/pt-br.js'
// import { CommunicationStayCurrentLandscape } from 'material-ui/svg-icons';

moment.tz.setDefault('UTC');

@inject('authStore')
@observer

class RelatorioUsuarios extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            status: 2,
            limite: 20,
            paginaAtual: 1,
            quantidade: 100,
            usuarios: [],
            logs: [],
            Numerologs: 0,
            dataInicial: moment(new Date(new Date().getTime() - 432000000)).format('YYYY-MM-DD'),
            dataFinal: moment(new Date()).format('YYYY-MM-DD'),
            departamento: [],
            tipo: [],
            usandoFiltroInput: false,
            descricaoBusca: '',
            user: JSON.parse(window.localStorage.getItem('user')),
            departamentoUser: '',
            inputFiltro: false
        }

    }

    async componentDidMount() {

        if (this.props.authStore.ehAdministrador !== true) {
            window.location.href = '/usuario/perfil'
        }
        await this.carregarLogs()
        await this.contarLogs()
        await this.getDepartamento()

    }

    async getDepartamento() {
        await get('/departamento')
            .then(response => {
                // window.localStorage.setItem('departamentos', JSON.stringify(response))
                // this.setState({ departamentoUser: response })
            })
            .catch(err => err)
    }

    async carregarLogs() {

        this.setState({ loading: true })

        const filtro = JSON.parse(localStorage.getItem('filtroLogs'))

        const data = {
            limite: this.state.limite,
            ultimo: (this.state.paginaAtual - 1) * this.state.limite,
            dataInicial: filtro !== null && filtro.dataInicial !== "" ? filtro.dataInicial : this.state.dataInicial,
            dataFinal: filtro !== null && filtro.dataFinal !== "" ? filtro.dataFinal : this.state.dataFinal,
            departamento: filtro !== null ? filtro.departamento : this.state.departamento,
            tipo: filtro !== null ? filtro.tipo : this.state.tipo
        }
        await post('/logs', data).then(response => {
            this.setState({ logs: response, loading: false })
        });
    }

    async contarLogs() {

        const filtro = JSON.parse(localStorage.getItem('filtroLogs'))

        const data = {
            dataInicial: filtro !== null && filtro.dataInicial !== "" ? filtro.dataInicial : this.state.dataInicial,
            dataFinal: filtro !== null && filtro.dataFinal !== "" ? filtro.dataFinal : this.state.dataFinal,
            departamento: filtro !== null ? filtro.departamento : this.state.departamento,
            tipo: filtro !== null ? filtro.tipo : this.state.tipo
        }
        await post('/logs/count', data).then(response => {
            this.setState({ quantidade: response })
        });
    }

    async carregarLogsPaginacao(pagina) {

        this.setState({ loading: true })

        if (this.state.usandoFiltroInput === false) {

            const filtro = JSON.parse(localStorage.getItem('filtroLogs'))

            const data = {
                limite: this.state.limite,
                ultimo: (pagina - 1) * this.state.limite,
                dataInicial: filtro !== null && filtro.dataInicial !== "" ? filtro.dataInicial : this.state.dataInicial,
                dataFinal: filtro !== null && filtro.dataFinal !== "" ? filtro.dataFinal : this.state.dataFinal,
                departamento: filtro !== null ? filtro.departamento : this.state.departamento,
                tipo: filtro !== null ? filtro.tipo : this.state.tipo
            }
            await post('/logs', data)
                .then(response => {
                    this.setState({ logs: response, paginaAtual: pagina, loading: false })
                });

        } else {

            const data = {
                limite: this.state.limite,
                ultimo: (pagina - 1) * this.state.limite,
                filtro: this.state.descricaoBusca
            }
            await post('/logs/filtro', data).then(response => {
                this.setState({ logs: response, paginaAtual: pagina, loading: false })
            });
            await post('/logs/count-filtro', data).then(response => {
                this.setState({ quantidade: response })
            });

        }
    }

    limparFiltro = () => {
        this.setState({ inputFiltro: false })
        window.localStorage.removeItem('filtroLogs')
        window.location.reload()
    }

    // imprimirPagina = () => {
    //     window.print()
    // }



    imprimirPagina = async () => {

        this.setState({ loading: true })

        const filtro = JSON.parse(localStorage.getItem('filtroLogs'))

        const pagina = 1

        // var conteudo = document.getElementById('sua_div').innerHTML,
        //     tela_impressao = window.open('about:blank');

        const data = {
            limite: 1000,
            ultimo: (pagina - 1) * 1000,
            dataInicial: filtro !== null && filtro.dataInicial !== "" ? filtro.dataInicial : this.state.dataInicial,
            dataFinal: filtro !== null && filtro.dataFinal !== "" ? filtro.dataFinal : this.state.dataFinal,
            departamento: filtro !== null ? filtro.departamento : this.state.departamento,
            tipo: filtro !== null ? filtro.tipo : this.state.tipo
        }
        await post('/logs', data).then(response => {
            this.setState({ logs: response, paginaAtual: pagina, loading: false })
            window.print()

            // tela_impressao.document.write(conteudo);
            // tela_impressao.window.print();
            // tela_impressao.window.close();
        });
    }

    carregarLogs = async pagina => {
        await this.carregarLogsPaginacao(pagina)
    }

    render() {


        const user = JSON.parse(window.localStorage.getItem('user'))
        const filtroLocal = JSON.parse(window.localStorage.getItem('filtroLogs'))
        const countDataInicial = filtroLocal !== null && filtroLocal.dataInicial !== '' ? 1 : 0
        const countDataFinal = filtroLocal !== null && filtroLocal.dataFinal !== '' ? 1 : 0
        const countDepartamento = filtroLocal !== null && filtroLocal.departamento.length > 0 ? 1 : 0
        const countTipo = filtroLocal !== null && filtroLocal.tipo.length
        const numeroDeFiltros = countDataInicial + countDataFinal + countDepartamento + countTipo


        const logs = this.state.logs || []
        //const departamentos = this.state.departamentoUser || []
        // const numeroDeFiltros = 3
        return (

            this.state.loading ? <Loading /> :

                //  <div style= backgroundColor: 'white', padding: '15px', borderTop: '2px solid #DC2D41' > 
                //      <div className="text-center">
                //             <p style=position: 'relative', top: '-50px', fontSize: '18px', fontWeight: 'bold'>Relatório</p>
                //         </div> 
                //  </div> 

                <div className="container-fluid p-5" style={{ background: 'white' }} id="sua_div" >

                    <div className="container-fluid no-print"  >
                        <div className="row">

                            <div className="col-sm-12 col-md-12 col-lg-10 col-xl-10 mb-3 icon-input form-control">
                                <input type="text"
                                    value={this.state.descricaoBusca}
                                    onKeyUp={(event) => { if (event.which === 13) { this.setState({ descricaoBusca: event.target.value, usandoFiltroInput: true, inputFiltro: true }); this.carregarLogsPaginacao(1); window.localStorage.removeItem('filtroLogs') } }}
                                    onChange={(event) => this.setState({ descricaoBusca: event.target.value, usandoFiltroInput: true })} placeholder="Pesquisar" aria-label="Pesquisar" aria-describedby="search" />
                                <span className="icon"
                                    onClick={() => {
                                        this.carregarLogsPaginacao(1)
                                        window.localStorage.removeItem('filtroLogs')
                                        this.setState({ inputFiltro: true })
                                    }}><i className="icon-search-small"></i></span>
                            </div>

                            <div className="zerar-div col-sm-12 col-md-12 col-lg-2 col-xl-2 mb-3">
                                <FormDialog
                                    dados={'aqui'}
                                />
                            </div>

                        </div>

                    </div>

                    {filtroLocal !== null && filtroLocal !== undefined ?
                        <div style={{ fontSize: '1.5rem', marginBottom: '20px' }}><span style={{ color: 'red' }}>{numeroDeFiltros}</span> filtros aplicados <span onClick={this.limparFiltro} style={{ color: 'var(--linkText)', borderBottom: '1px solid var(--linkText)', marginLeft: '15px', cursor: 'pointer', fontSize: '1rem' }}> Limpar filtros</span></div>
                        : false}

                    {this.state.inputFiltro === true ?
                        <div style={{ fontSize: '1.5rem', marginBottom: '20px' }}>Resultado para <span style={{ color: 'red' }}>"{this.state.descricaoBusca}"</span> | <span style={{ color: 'red' }}>1</span> filtro aplicados<span onClick={this.limparFiltro} style={{ color: 'red', borderBottom: '1px solid red', marginLeft: '15px', cursor: 'pointer', fontSize: '1rem' }}> Limpar filtros</span></div>
                        : false}
                    <div className="usuario">
                        <table className="table table-striped table-responsive-sm">
                            <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                <tr>
                                    <th>Data</th>
                                    <th>Hora</th>
                                    <th>Usuário</th>
                                    <th>Departamento</th>
                                    <th>Atividades</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    logs !== undefined && Array.isArray(logs) && logs.length > 0 ?
                                        logs.map((log, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{moment(log.criado_em).format('DD/MM/YYYY')}</td>
                                                    <td>{moment(new Date(new Date(log.criado_em).getTime() - 10800000)).format('HH')}:{moment(log.criado_em).format('mm')}</td>
                                                    {/* <td>{moment(new Date(new Date(log.criado_em).getTime())).format('HH')}:{moment(log.criado_em).format('mm')}</td> */}
                                                    <td>{log.nome_usuario !== null ? log.nome_usuario : ''}</td>
                                                    <td>{log.descricao_departamento !== null ? log.descricao_departamento : ''}</td>
                                                    <td>{log.descricao !== null ? log.descricao : ''}</td>
                                                </tr>
                                            )
                                        }) : null}
                            </tbody>
                        </table>
                    </div>
                    {user.idDoCliente !== 3 ? // EXCLUIR APOS APRESENTACAO PARA darkBlueClient
                        this.state.quantidade < this.state.limite ? null :
                            <div className="no-print">
                                <Pagination
                                    activePage={this.state.paginaAtual}
                                    itemsCountPerPage={this.state.limite}
                                    totalItemsCount={parseInt(this.state.quantidade)}
                                    pageRangeDisplayed={10}
                                    // itemClass={'page-item'}
                                    // linkClass={'page-link'}
                                    hideFirstLastPages={true}
                                    onChange={this.carregarLogs}
                                    prevPageText={<div className="prev-page"><img src={require('../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                                    nextPageText={<div className="next-page"><img src={require('../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                                />
                            </div>
                        : null}

                </div>

        )
    }
}

export default RelatorioUsuarios