import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import { childrenWithProps } from './helpers/utils';

@inject('modalStore')
@withRouter
@observer
class Modal extends Component {

    constructor(props) {
        super(props)
        this.close = this.close.bind(this)
    }

    componentDidMount() {
        document.body.classList.toggle('modal-open')
        this.props.modalStore.open = true
    }

    componentWillUnmount() {
        document.body.classList.remove('modal-open')
        this.props.modalStore.open = false
    }

    close() { this.props.history.push(this.props.goBack) }

    render() {
        return (
            <div className="modal fade show" style={{ display: 'block' }} >
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content fs-14">
                        <div className="container-fluid">
                            <div className="modal-header">
                                <h4 className="modal-title">{this.props.titulo}</h4>
                                <button type="button" className="close" onClick={() => this.close()}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            {childrenWithProps(this.props.children, this.props)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal