import React, { Component } from 'react';
import { inject, observer } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { put, get } from '../../services/http'
import AlertaStore from '../../stores/alertaStore'
import { NavLink } from 'react-router-dom'
import Loading from '../../componentes/loading';







const inputStyle = {
    border: 'none',
    marginLeft: "0px",
    backgroundColor: 'transparent',
    borderBottom: '1px solid #C4C4C4',
    color: '#000000',
    marginTop: '5px',
    width: '100%',
    outline: 'none'
};





@inject('authStore')
@observer

class Insights extends Component {
    state = {
        loading: false,
        user: JSON.parse(window.localStorage.getItem('user')),
        status: true,

        // listaGruposTemas: {},
        selectedOption: "",
        paginaAtual: 2,

        // Cadastrro de Grupo
        origemDemanda: this.props.match.params.Origin,
        idDoGrupo: "",
        insertDescricaoGrupo: "",
        statusDescricaoCadastroGrupo: false,
        erroDescricaoGrupo: "",

    }


    componentWillMount() {
        this.buscarGrupoPorId()
    }



    // ...................CHAMADA DO GRUPO ID ................

    buscarGrupoPorId = async () => {
        await get(`/tema-proposicoes/obterGrupo/${this.props.match.params.id}`)
            .then(resp => {
                this.setState({
                    loading: true
                })
                this.setState({
                    idDoGrupo: resp[0].id,
                    insertDescricaoGrupo: resp[0].descricao,
                    statusDescricaoCadastroGrupo: resp[0].ativo
                })
                // console.log("respGrupoId", resp[0].id)
                // console.log("resp", resp)
                this.setState({
                    loading: false
                })
            })

    }



    handleChangeStatus = async (position) => {
        if (this.state.status === position) {
            this.setState({
                status: false
            })
        } if (this.state.status !== position) {
            this.setState({
                status: true
            })
        }
    }




    // .................EDITAR DE GRUPO................

    cancel = async () => {
        console.log("origin:", this.state.origemDemanda)
        if (this.state.origemDemanda === "lista") {
            window.location.href = `/classificacao-tema`
        } else {
            window.location.href = `/classificacao-tema/nome-do-grupo/${this.state.idDoGrupo}`
        }
    }

    alterarGrupo = async () => {

        if (this.state.insertDescricaoGrupo === "" || this.state.insertDescricaoGrupo === null || this.state.insertDescricaoGrupo === undefined) {
            this.setState({
                erroDescricaoGrupo: "Campo vazio. Favor preencher!"
            })
        } else {

        const data = {
            data: {
                id: this.state.idDoGrupo,
                descricao: this.state.insertDescricaoGrupo,
                ativo: this.state.statusDescricaoCadastroGrupo
            }
        }

        put('/tema-proposicoes/alterar/grupo', data)
            .then(response => {
                console.log(response)
                if (response === undefined || response === null || response === "") {
                    this.setState({
                        erroDescricaoGrupo: "Grupo já cadastrado, escolha outro nome!"
                    })
                } else {
                    AlertaStore.criar("Grupo alterado com sucesso!")
                    if (this.state.origemDemanda === "lista") {
                            window.location.href = `/classificacao-tema`
                        } else {
                            window.location.href = `/classificacao-tema/nome-do-grupo/${this.state.idDoGrupo}`
                        }
                }
            })

        }


    }


    handleTextoInsertDescricaoGrupo = async (descricaoTexto) => {
        this.setState({
            insertDescricaoGrupo: descricaoTexto
        })
    }

    handleStatusInsertDescricaoGrupo = async (descricaoStatus) => {
        this.setState({
            statusDescricaoCadastroGrupo: descricaoStatus
        })
    }




    render() {

        // console.log(this.state.insertDescricaoGrupo)


        const StatusSwitch = withStyles({
            switchBase: {
                color: '#FFF',
                '&$checked': {
                    color: '#4CD964',
                },
                '&$checked + $track': {
                    backgroundColor: '#4CD964',
                },
            },
            checked: {},
            track: {},
        })(Switch);



        return (
            this.state.loading === true ? <div style={{ marginTop: '400px' }}><Loading /></div> :
                <div style={{ backgroundColor: "#f6f6f6", height: "100%" }}>
                    <div className=" bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                        <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                            EDITAR GRUPO
                        </p>
                    </div>


                    <div className="container">



                        {/* ................head.................... */}
                        <div className='row' style={{ width: "100%" }}>
                            <div className='col-12 col-12'>
                                <p style={{ fontSize: "24px", fontWeight: "bold", paddingTop: "30px", color: "#1E0736" }}>
                                    Editar Grupo
                        </p>
                            </div>
                        </div>


                        {/* ................Background White.................... */}
                        <div className='bg-white container' style={{ paddingLeft: "40px", paddingRight: "40px", paddingTop: "25px", paddingBottom: "25px" }}>
                            <div style={{fontSize:"16px", fontWeight:"400"}}>
                                Informações Gerais
                            </div>

                            {/* ................Formulários.................... */}

                            <div>
                                <div className="row">
                                    <div className="col-md-6 mt-3" >
                                        <label style={{ color: "var(--linkText)", fontSize:"12px" }}>Descrição</label>
                                        <input
                                            value={this.state.insertDescricaoGrupo}
                                            onChange={(e) => this.handleTextoInsertDescricaoGrupo(e.target.value)}
                                            name={'DescriçãoGrupo'}
                                            style={inputStyle}
                                            type="text"
                                            placeholder="Digite o nome desejado"
                                            autoComplete="off"
                                            maxLength="40"

                                        />

                                        <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{this.state.erroDescricaoGrupo}</span>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <div>
                                            <label style={{  color: "var(--linkText)", fontSize:"12px" }}>Status</label>
                                        </div>
                                        <div className="row">
                                            <div>
                                                <StatusSwitch
                                                    checked={this.state.statusDescricaoCadastroGrupo}
                                                    onChange={(e) => this.handleStatusInsertDescricaoGrupo(e.target.checked)}
                                                    name="checkedA"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                            </div>
                                            <div style={{ alignItems: "center", display: "flex" }}>
                                                {
                                                    this.state.statusDescricaoCadastroGrupo === true ?
                                                        "Ativo"
                                                        :
                                                        "Inativo"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* .................... BOTOES ....................... */}
                        <div className="container">
                            <div className="row" style={{ float: "right" }}>
                                <NavLink to={`/classificacao-tema`}>
                                    <div className="cursor-pointer" onClick={() => this.cancel()} style={{ padding: "10px", color: "var(--botaoPesquisar)", marginTop: "25px" }}>
                                        Cancelar
                                </div>
                                </NavLink>
                                <div style={{ padding: "10px" }}>
                                    <button type="button" onClick={() => this.alterarGrupo()} className="btn" style={{ width: "100%", marginTop: "10px", padding: "15px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter" }} variant="outlined" >
                                        <img
                                            className="mr-2"
                                            style={{ width: "20px", marginTop: "-3px" }}
                                            src={require('../../design/assets/images/diskSave.svg')}
                                            alt="btn-pesquisar"
                                        />{' '}
                                    Salvar
                                </button>
                                </div>
                            </div>
                        </div>


                    </div>




                </div>
        )
    }
}

export default Insights