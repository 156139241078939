import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                labels: ['Êxito Parcial', 'Êxito', 'Pendente', 'Sem Êxito', 'Sem Informação'],
                colors: ['#5856D7', '#4CD964', '#FFD200', '#F63854', '#E1E8F2' ],
                legend: {
                    show: false,
                }
            },
            series: [
                0, 1, 2, 3, 4
            ],
        }
    }


    render() {
        const larguraTela = window.innerWidth
        const larguraGrafico = larguraTela > 375 ? '330' : '260'
        const series1 = [
            this.props.dados.exito_parcial !== undefined ? this.props.dados.exito_parcial : 0 ,
            this.props.dados.exito !== undefined ? this.props.dados.exito : 0 ,
            this.props.dados.pendente !== undefined ? this.props.dados.pendente : 0 ,
            this.props.dados.sem_exito !== undefined ? this.props.dados.sem_exito : 0 ,
            this.props.dados.null !== undefined ? this.props.dados.null : 0 
        ]
        const series2 = [0,0,0,0,0]
        const series = this.props.dados !== undefined ? series1 : series2
        // console.log('TESTE AQUI DONUT ===>',this.props.dados)

        return (
            <div className="donut">
                <Chart 
                    options={this.state.options} 
                    series={series} 
                    type="donut" 
                    width={larguraGrafico}
                />
            </div>
        );
    }
}

export default Donut;
