import React, {} from "react"

const modalAviso = ({mensagem1, mensagem2, imagem,url}) => {
    function redirecionar(url){
        window.location.href = url
    }
    return(
            <div className={'background-confirm-modal'}>
            <div className="confirm-modal w-50">
                <div className="modal-header text-center">
                    <div className="image w-100">
                        <img
                            className="mr-3" style={{ width: '50px' }}
                            src={require('../../design/assets/images/'+imagem)}
                            alt="anexo"
                        />
                    </div>
                </div>
                <div className="modal-body">
                <p className="Client-titulo-card text-center">{mensagem1}</p>
                    <p className="w-75 mt-0 mb-0 mr-auto ml-auto Client-cinza-claro text-center">
                        {mensagem2}
                    </p>
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-item w-100" >
                        <button type='button'onClick={()=>redirecionar(url)}>FECHAR</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default modalAviso