import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Parlamentares from './parlamentares'
import ParlamentarEspecifico from './parlamentarEspecifico'
import Discurso from './discurso'

export default () =>
    <Switch>
        <Route exact path="/parlamentar/senador" component={Parlamentares} />
        <Route exact path="/parlamentar/deputado" component={Parlamentares} />
        <Route exact path="/parlamentar" component={Parlamentares} />
        <Route exact path="/parlamentar/estado/:estado" component={Parlamentares} />
        <Route exact path="/parlamentar/partido/:partido" component={Parlamentares} />
        <Route exact path="/parlamentar/partido/:partido/:estado" component={Parlamentares} />
        <Route path="/parlamentar/:id" render={({ match }) => <ParlamentarEspecifico id={match.params.id} />} />
        <Route exact path={`/parlamentar/:id/discursos/:discurso`} render={({ match }) => <Discurso id={match.params.discurso} parlamentar={match.params.id} />} />
    </Switch>