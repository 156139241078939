import React, { Component, Fragment } from 'react'
// import { useClearBrowserCache } from 'react-clear-browser-cache';
// import { useClearCache } from "react-clear-cache";
import { inject, observer } from 'mobx-react'
import { Row } from '../../estruturas/bootstrap'
import FormEnhance from '../../componentes/forms/FormEnhance'
import Schema from '../../componentes/forms/validadores/login'
// import Loading from '../../componentes/loading'
import SimpleInput from '../../componentes/forms/inputs/simpleInput'
import SimpleInputMobile from '../../componentes/forms/inputs/simpleInputMobile'
import Modal from './modalEnviarEmail'
import ModalAviso from './modalAviso'
// import { useEffect } from 'react';


const ButtonLoginSSO = () => {
    // const { emptyCacheStorage } = useClearCache();

    // useEffect(() => {
    //     // const contextValue = useClearBrowserCache();
    //     emptyCacheStorage();
    //     // contextValue.clearCacheAndReload();
    // }, [])

    return (
        <button
            // onClick={() => window.location = process.env.REACT_APP_SSO_REDIRECT}
            onClick={() => window.location = process.env.REACT_APP_SSO_REDIRECT}
            // onClick={() => {
            //     emptyCacheStorage()
            //         .then(x => window.location.href = "https://dev.legisnote.com.br/api/mslogin");
            // }} 
            //process.env.REACT_APP_SSO_REDIRECT}
            //onClick={() => this.authStore.Mslogin()}//window.location = "https://dev.legisnote.com.br/api/mslogin"} //process.env.REACT_APP_SSO_REDIRECT}
            className="btn btn-danger col-sm-12 mb-3 w-100 p-3"
            style={{ width: "100%" }}
            type="button">Acessar via SSO
        </button>
    )
}


@inject('authStore')
@observer
class Login extends Component {



    state = { sso: false, AD: false, close: true, closeSucesso: true }

    abrirFecharModalEmail = () => {
        this.setState({
            close: !this.state.close
        })
    }
    abrirFecharModalSucesso = () => {
        // console.log('qqqq')
        this.setState({
            closeSucesso: !this.state.closeSucesso
        })
    }

    async componentWillMount() {
        console.log("process.env.REACT_APP_SSO_REDIRECT - - - : ", process.env)
        //if (window.location.hostname.startsWith('portal.purpleClient')) await this.props.authStore.loginSSOporCliente('blueClient')
        // if(process.env.REACT_APP_SSO?+process.env.REACT_APP_SSO:0===1) await this.props.authStore.loginSSOporCliente('blueClient')
        // if (window.location.hash) await this.props.authStore.loginSSOcomHash()
        // this.setState({ AD: +process.env.REACT_APP_AD === 1 })
    }

    async fazerLogin(e, sso) {
        // await window.localStorage.clear()
        e.preventDefault()
        this.state.AD ? await this.props.authStore.loginAD(this.props) :
            sso
                ? await this.props.authStore.loginSSOporCliente(this.props.data.email)
                : await this.props.authStore.loginCognito(this.props)
    }

    render() {
        const tamanhoTela = window.innerWidth

        return this.props.authStore.loading ?
            <Fragment >
                <div className="div-titulo-login" >
                    <h1 className="h4 text-inherit titulo-login">Fazer login</h1>
                </div>
                {!this.state.sso
                    ? <form onSubmit={(e) => { this.fazerLogin(e, false) }}>
                        <Row>
                            {(!process.env.REACT_APP_SSO || process.env.REACT_APP_SSO === '0') &&
                                <SimpleInput
                                    className="input-responsivo"
                                    cols="12"
                                    label="Usuário"
                                    name="username"
                                    icon="icon-user-small"
                                    position="left"
                                    placeholder="joao@purpleClient.com.br"
                                    onChange={username => this.props.onChange('username', username)}
                                    error={this.props.handleError("username", "Nome de Usuário")} />
                            }
                        </Row>
                        <Row>
                            {(!process.env.REACT_APP_SSO || process.env.REACT_APP_SSO === '0') &&
                                <SimpleInput
                                    className="input-responsivo"
                                    cols="12"
                                    label="Senha"
                                    name="password"
                                    type="password"
                                    icon="icon-download-small"
                                    position="left"
                                    onChange={password => this.props.onChange('password', password)}
                                    error={this.props.handleError("password", "Senha")} />
                            }
                        </Row>
                        {(!process.env.REACT_APP_SSO || process.env.REACT_APP_SSO === '0') &&
                            <button
                                className="btn btn-danger col-sm-12 mb-3 w-100 p-3"
                                type="submit"
                                disabled={!this.props.valid}>Entrar
                            </button>
                        }
                        {/* {
                !this.state.AD ?
                    <Fragment>
                    <div className="border-bottom mt-4 mb-4 text-center">
                        <span className="span-login">ou</span>
                    </div>
                    <button type="button" className="btn btn-outline-primary btn-block" onClick={() => this.setState({ sso: true })}>Acessar via SSO</button>
                    </Fragment> : null
                } */}
                    </form>
                    : <form onSubmit={(e) => { this.fazerLogin(e, true) }}>
                        {(!process.env.REACT_APP_SSO || process.env.REACT_APP_SSO === '0') &&
                            <Row>

                                <SimpleInput
                                    cols="12"
                                    label="E-mail corporativo"
                                    type="email"
                                    name="email"
                                    icon="icon-user-small"
                                    position="left"
                                    placeholder="joao@purpleClient.com.br"
                                    onChange={email => this.props.onChange('email', email)} />
                                <button className="btn btn-danger col-sm-12 mb-3 w-100 p-3" type="submit" disabled={!this.props.data.email}>Entrar</button>
                                <button className="btn btn-light btn-block mb-3" type="button" onClick={() => this.setState({ sso: false })}>Cancelar</button>
                            </Row>
                        }
                    </form>}
                {/* <p style={{color:'var(--linkText)', textDecoration:'underline', cursor: "pointer"}} onClick={this.abrirFecharModalEmail} className="text-center">Esqueci minha senha</p>
            {!this.state.close ?  <Modal abrirFechar={this.abrirFecharModalEmail} 
            abrirFecharSucesso={this.abrirFecharModalSucesso}/> : null} */}

                {!this.state.closeSucesso ?
                    <ModalAviso mensagem1={'E-mail enviado com sucesso'}
                        mensagem2={'Caso você não receba o e-mail em alguns minutos, verifique a sua caixa de spam ou repita o processo.'}
                        imagem={"icone_sucesso.svg"}
                        url={'/senha-nova'}
                    />
                    : null}
            </Fragment>

            : <Fragment>

                {
                    tamanhoTela < 600
                        ?
                        <div className=' text-left'>

                            <div className="div-titulo-login text-left pt-3 pb-4">
                                <h1 className="titulo-login" style={{ color: "white", fontSize: "18px" }}>Fazer login</h1>
                            </div>
                            {!this.state.sso
                                ? <form onSubmit={(e) => { this.fazerLogin(e, false) }}>
                                    {(!process.env.REACT_APP_SSO || process.env.REACT_APP_SSO === '0') &&
                                        <>
                                            <Row>
                                                <SimpleInputMobile
                                                    className="input-responsivo"
                                                    cols="12"
                                                    label="Usuário"
                                                    name="username"
                                                    icon="icon-user-small"
                                                    position="left"
                                                    placeholder="joao@purpleClient.com.br"
                                                    onChange={username => this.props.onChange('username', username)}
                                                    error={this.props.handleError("username", "Nome de Usuário")} />
                                            </Row>
                                            <Row>
                                                <SimpleInputMobile
                                                    className="input-responsivo"
                                                    cols="12"
                                                    label="Senha"
                                                    name="password"
                                                    type="password"
                                                    icon="icon-download-small"
                                                    position="left"
                                                    onChange={password => this.props.onChange('password', password)}
                                                    error={this.props.handleError("password", "Senha")} />
                                            </Row>
                                            <button
                                                className="btn btn-danger col-sm-12 p-3 mb-3 "
                                                style={{ width: "100%" }}
                                                type="submit"
                                                disabled={!this.props.valid}>Entrar
                                            </button>
                                        </>}
                                    {/* {
                                    !this.state.AD ?
                                        <Fragment>
                                        <div className="border-bottom mt-4 mb-4 text-center">
                                            <span className="span-login">ou</span>
                                        </div>
                                        <button type="button" className="btn btn-outline-primary btn-block" onClick={() => this.setState({ sso: true })}>Acessar via SSO</button>
                                        </Fragment> : null
                                    } */}
                                </form>
                                : <form onSubmit={(e) => { this.fazerLogin(e, true) }}>
                                    {(!process.env.REACT_APP_SSO || process.env.REACT_APP_SSO === '0') &&
                                        <Row>
                                            <SimpleInputMobile
                                                cols="12"
                                                label="E-mail corporativo"
                                                type="email"
                                                name="email"
                                                icon="icon-user-small"
                                                position="left"
                                                placeholder="joao@purpleClient.com.br"
                                                onChange={email => this.props.onChange('email', email)} />
                                            <button className="btn btn-danger col-sm-12 mb-3 w-100 p-3" type="submit" disabled={!this.props.data.email}>Entrar</button>
                                            <button className="btn btn-light btn-block mb-3" type="button" onClick={() => this.setState({ sso: false })}>Cancelar</button>
                                        </Row>
                                    }
                                </form>}
                            {/* <p style={{color:'var(--linkText)', textDecoration:'underline', cursor: "pointer"}} onClick={this.abrirFecharModalEmail} className="text-center">Esqueci minha senha</p> */}
                            {!this.state.close ? <Modal abrirFechar={this.abrirFecharModalEmail}
                                abrirFecharSucesso={this.abrirFecharModalSucesso} /> : null}

                            {!this.state.closeSucesso ?
                                <ModalAviso mensagem1={'E-mail enviado com sucesso'}
                                    mensagem2={'Caso você não receba o e-mail em alguns minutos, verifique a sua caixa de spam ou repita o processo.'}
                                    imagem={"icone_sucesso.svg"}
                                    url={'/senha-nova'}
                                />
                                : null}

                        </div>


                        :


                        <div>

                            <div className="div-titulo-login">
                                <h1 className="h4 text-inherit titulo-login">Fazer login</h1>
                            </div>
                            {/* AQUI DESC */}
                            {!process.env.REACT_APP_SSO || process.env.REACT_APP_SSO === '0'
                                ?
                                <form onSubmit={(e) => { this.fazerLogin(e, false) }}>
                                    <Row>
                                        <SimpleInput
                                            className="input-responsivo"
                                            cols="12"
                                            label="Usuário"
                                            name="username"
                                            icon="icon-user-small"
                                            position="left"
                                            placeholder="joao@purpleClient.com.br"
                                            onChange={username => this.props.onChange('username', username)}
                                            error={this.props.handleError("username", "Nome de Usuário")} />
                                    </Row>
                                    <Row>
                                        <SimpleInput
                                            className="input-responsivo"
                                            cols="12"
                                            label="Senha"
                                            name="password"
                                            type="password"
                                            icon="icon-download-small"
                                            position="left"
                                            onChange={password => this.props.onChange('password', password)}
                                            error={this.props.handleError("password", "Senha")} />
                                    </Row>
                                    <button
                                        className="btn btn-danger col-sm-12 mb-3 w-100 p-3"
                                        style={{ width: "100%" }}
                                        type="submit"
                                        disabled={!this.props.valid}>Entrar
                                    </button>
                                    {
                                        !this.state.AD && process.env.SSO === 'true' ?
                                            <Fragment>
                                                <div className=" mb-4 text-center">
                                                    <hr />
                                                    {/* <span className="span-login">ou</span> */}
                                                </div>
                                                <button
                                                    onClick={() => this.setState({ sso: true })}
                                                    className="btn btn-danger col-sm-12 mb-3 w-100 p-3"
                                                    style={{ width: "100%" }}
                                                    type="button">Acessar via SSO
                                                </button>
                                                {/* <button type="button" className="btn btn-outline-primary btn-block" onClick={() => this.setState({ sso: true })}>Acessar via SSO</button> */}
                                            </Fragment> : null
                                    }

                                </form>

                                :
                                <ButtonLoginSSO />
                                // <button
                                //     // onClick={() => window.location = process.env.REACT_APP_SSO_REDIRECT}
                                //     onClick={() => {
                                //         emptyCacheStorage().then(x => window.location.href = "https://dev.legisnote.com.br/api/mslogin");

                                //     }} //process.env.REACT_APP_SSO_REDIRECT}
                                //     //onClick={() => this.authStore.Mslogin()}//window.location = "https://dev.legisnote.com.br/api/mslogin"} //process.env.REACT_APP_SSO_REDIRECT}
                                //     className="btn btn-danger col-sm-12 mb-3 w-100 p-3"
                                //     style={{ width: "100%" }}
                                //     type="button">Acessar via SSO
                                // </button>

                            }


                            {/*<p style={{color:'var(--linkText)', textDecoration:'underline', cursor: "pointer"}} onClick={this.abrirFecharModalEmail} className="text-center">Esqueci minha senha</p> */}
                            {!this.state.close ? <Modal abrirFechar={this.abrirFecharModalEmail}
                                abrirFecharSucesso={this.abrirFecharModalSucesso} /> : null}

                            {!this.state.closeSucesso ?
                                <ModalAviso mensagem1={'E-mail enviado com sucesso'}
                                    mensagem2={'Caso você não receba o e-mail em alguns minutos, verifique a sua caixa de spam ou repita o processo.'}
                                    imagem={"icone_sucesso.svg"}
                                    url={'/senha-nova'}
                                />
                                : null}

                        </div>
                }

                {/* >>>>>>>>>>>>>>>>> MOBILE <<<<<<<<<<<<<<<<<< */}






                {/* >>>>>>>>>>>>>>>>> DESK <<<<<<<<<<<<<<<<<< */}







            </Fragment >
    }
}

export default FormEnhance(Login, Schema)