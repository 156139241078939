import React, { Component } from 'react';
import Loading from '../../../componentes/loading';
import Grafico from '../graficos/graficoAcoesPorPeriodo'
import moment from 'moment-timezone';
// import Pagination from "react-js-pagination";
import { get } from "../../../services/http"
import { inject, observer } from 'mobx-react'

moment.tz.setDefault('UTC');

@inject('authStore')
@observer

class AcoesPorPeriodo extends Component {
    state = {
        loading: true,
        resultadoRelatorioAcaoPreparatoria: [],
        resultadoRelatorioAcaoDeInfluencia: [],

        paginaAtualAcaoDeInfluencia: 1,
        limiteAcaoDeInfluencia: 3,
        quantidadeAcaoDeInfluencia: 0,

        totalQuantidadeAcaoPreparatoria: 0,
        totalQuantidadeAcaoDeInfluencia: 0,
    }



    componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        this.getDadosAcoesPorPeriodo()
    }

    async getDadosAcoesPorPeriodo() {
        await get(`/relatorio/proposicoes/influencia-preparatoria/${this.props.match.params.dtIni}/${this.props.match.params.dtFim}`)
            .then(response => {
                const dados = response || []
                // console.log(dados)
                this.setState({
                    totalQuantidadeAcaoDeInfluencia: dados.graficoInfluencia,
                    totalQuantidadeAcaoPreparatoria: dados.graficoPreparatoria,
                    resultadoRelatorioAcaoDeInfluencia: dados.tabelaInfluencia,
                    resultadoRelatorioAcaoPreparatoria: dados.tabelaPreparatoria,
                    loading: false
                })

            })
            .catch(err => console.error(err))
    }

    imprimir() {
        window.print()
    }


    render() {

        const dados = [this.state.totalQuantidadeAcaoPreparatoria, this.state.totalQuantidadeAcaoDeInfluencia]
        const dataInicio = moment(this.props.match.params.dtIni).format('DD/MM/YYYY')
        const dataFim = moment(this.props.match.params.dtFim).format('DD/MM/YYYY')
        const dataDaPagina = `Período de Criação: ${dataInicio} a ${dataFim}`

        // const dominio = window.localStorage.getItem('dominio')
        // var logo = null
        // switch (dominio) {
        //     case 'Client':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'blueClient':
        //         logo = 'icon-purpleClient corblueClient'
        //         break

        //     case 'darkBlueClient':
        //         logo = 'icon-logo_darkBlueClient cordarkBlueClient'
        //         break

        //     case 'dev':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'localhost':
        //         logo = `icon-purpleClient cordarkBlueClient`
        //         break

        //     default:
        //         logo = 'icon-logo-Client corClient'
        // }

        return (
            this.state.loading ? <Loading /> :
                <div className="container paddingGeralXS" >

                    <div id="non-printable" className="mt-5">
                        <button className="btn btn-danger" onClick={this.imprimir}>
                            <img
                                src={require('../../../design/assets/images/impressora.svg')}
                                alt="anexo"
                            />
                        </button>
                    </div>

                    <div className="pt-5">

                        <div className="row">
                        <div className="col-1" >
                        <a href="/">
                                <div className=" logoMenuRelatorios text-center"></div>
                                    {/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
                                </a>
                            </div>
                            <div className="col-11">
                                <div>
                                    <p className="tituloRelatorio">
                                        Relatório
                                        </p>
                                </div>
                                <div>
                                    <p className="descricaoTituloRelatorio">
                                        Quantidade de ações realizadas por período
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 text-center periodoFiltro">
                                <div className="">
                                    {dataDaPagina}
                                </div>
                            </div>
                        </div>


                        {/* GRAFICO  */}

                        <div className="row">

                            <div className="col-md-6 divGrafico" id="align-grafico">
                                <Grafico
                                    dados={dados}
                                />
                            </div>
                            <div className="col-md-6 align-self-center divIndice" id="divAlignIndice">

                                <div className="row">

                                    <div className="corIndiceAcaoPreparatoria"></div>
                                    <div>
                                        <p className="textoIndiceAcaoPreparatoria">
                                            Ação Preparatória
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceAcaoPreparatoria">
                                            {this.state.totalQuantidadeAcaoPreparatoria}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceAcaoDeInfluencia"></div>
                                    <div>
                                        <p className="textoIndiceAcaoDeInfluencia">
                                            Ação de Influência
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceAcaoDeInfluencia">
                                            {this.state.totalQuantidadeAcaoDeInfluencia}
                                        </p>
                                    </div>

                                </div>
                            </div>

                        </div>


                        {/* GRAFICO ACAO PREPARATORIA */}


                        <div className="mt-5">
                            <p className="tituloTabelaAcaoPreparatoria">
                                Ação Preparatória
                            </p>
                        </div>
                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleAcaoPreparatoria">
                                <tr>
                                    <th className="headTipoAcaoPreparatoria">Tipo de Proposição</th>
                                    <th className="headQuantAcaoPreparatoria">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.resultadoRelatorioAcaoPreparatoria !== undefined && Array.isArray(this.state.resultadoRelatorioAcaoPreparatoria) && this.state.resultadoRelatorioAcaoPreparatoria.length > 0 ?
                                    this.state.resultadoRelatorioAcaoPreparatoria.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="dadosColunaTipoAcaoPreparatoria">{item[0]}</td>
                                                <td className="dadosColunaQuantAcaoPreparatoria">{item[1]}</td>
                                            </tr>
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualAtivo}
                            itemsCountPerPage={this.state.limiteAtivo}
                            totalItemsCount={parseInt(this.state.quantidadeAtivo)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaAtivo}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}



                        {/* GRAFICO ACAO DE INFLUENCIA */}


                        <div className="mt-5">
                            <p className="tituloTabelaAcaoDeInfluencia">
                                Ação de Influência
                            </p>
                        </div>
                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleAcaoDeInfluencia">
                                <tr>
                                    <th className="headTipoAcaoDeInfluencia">Tipo de Proposição</th>
                                    <th className="headQuantAcaoDeInfluencia">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.resultadoRelatorioAcaoDeInfluencia !== undefined && Array.isArray(this.state.resultadoRelatorioAcaoDeInfluencia) && this.state.resultadoRelatorioAcaoDeInfluencia.length > 0 ?
                                    this.state.resultadoRelatorioAcaoDeInfluencia.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="dadosColunaTipoAcaoDeInfluencia">{item[0]}</td>
                                                <td className="dadosColunaQuantAcaoDeInfluencia">{item[1]}</td>
                                            </tr>
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualAcaoDeInfluencia}
                            itemsCountPerPage={this.state.limiteAcaoDeInfluencia}
                            totalItemsCount={parseInt(this.state.quantidadeAcaoDeInfluencia)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaAcaoDeInfluencia}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}

                    </div>

                </div>

        )
    }
}

export default AcoesPorPeriodo