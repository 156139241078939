import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import Temas from '../temas'

export default () =>
  <Fragment>
    <Switch>
      <Route exact path="/temas" component={Temas} />
    </Switch>
  </Fragment>
