import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { get } from '../../../services/http'
import UploadFile from '../../../componentes/forms/inputs/upload'

@withRouter
@inject('uploadStore', 'authStore')
@observer

class Anexo extends Component {
    state = {
        arquivo: [],
        anexoObtido: false
    }

    getAnexos = async (id) => {
        // console.log("CHAMANDO GET")
        // console.log('ID', id)
        await get(`anexo/${this.props.modulo}/${id}`)
            .then(res => {
                // console.log('resposta do anexo aqui', res)
                this.setState({ arquivo: res, anexoObtido: true })
            })
    }

    handleAnexo = async (anexo) => {
        // console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA HANDLE", anexo[0].id_referencia);
        // var arquivoArray = this.state.arquivo 
        // const novoAnexo = anexo[0]
        // arquivoArray.push(novoAnexo)
        this.setState({ arquivo: [...this.state.arquivo, ...anexo], anexoObtido: true })
        await this.getAnexos(anexo[0].id_referencia)
    }

    componentWillMount() {
        if (this.props.idParametro && this.state.anexoObtido === false) {
            this.getAnexos(this.props.idParametro)
        }
    }



    render() {

        if (this.props.idParametro && this.state.anexoObtido === false) {
            this.getAnexos(this.props.idParametro)
        }

        return (
            <Fragment>
                <h6 className='d-flex justify-content-between align-items-center'>
                    <span className="mr-4" style={{ fontSize: '0.8rem' }}><b>Anexos</b></span>
                    <UploadFile
                        linkButton={true}
                        modulo={this.props.modulo}
                        id_referencia={this.props.create ? this.props.idProposicaoCasaExterna : this.props.idParametro}
                        onChange={anexo => this.handleAnexo(anexo)}
                        create={this.props.create}
                    />
                </h6>

                <div className="row mb-2">
                    {this.state.arquivo !== undefined && Array.isArray(this.state.arquivo) && this.state.arquivo.length > 0 ? this.state.arquivo.map((item, index) =>
                        <Fragment key={item.arquivo}>
                            <div className="col-6 border p-2 d-flex justify-content-between w-50" >
                                <div className="text-truncate row mr-4" onClick={() => this.props.uploadStore.downloadArquivo(item, this.refs).then(x => console.log(x))}>
                                    <div className="ml-2" style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                    <span className=" ml-2 mt-1 Client-link text-truncate" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px", overflow: "hidden" }}>{item.nome}</span>
                                </div>
                                <span className="cursor-pointer" onClick={() => {
                                    if (item.id === undefined) {
                                        item = { nome: item.nome, id_referencia: this.props.idProposicaoCasaExterna }
                                        this.props.uploadStore.removerAnexoSemId(item);
                                        this.state.arquivo.splice(index, 1)
                                        this.setState({ arquivo: this.state.arquivo });
                                    } else {
                                        this.props.uploadStore.removerAnexo(item);
                                        this.setState({ arquivo: this.state.arquivo.filter(anexo => anexo.arquivo !== item.arquivo) });
                                    }
                                }}>
                                    {this.props.authStore.ehVisualizador === false && this.props.authStore.ehEsforcoColaborativo === false ?
                                        <img src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo" />
                                        : null}
                                </span>
                            </div>
                        </Fragment>) : null}
                </div>
            </Fragment>
        )
    }
}

export default Anexo