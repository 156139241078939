import React, { Component } from 'react'
import moment from 'moment-timezone';
// import 'moment/locale/pt-br'
import { post, get, put } from "../../../services/http";
import { inject, observer } from 'mobx-react'
import Loading from '../../../componentes/loading'
import UploadFile from '../../../componentes/forms/inputs/upload'
import UploadFileMobile from '../../../componentes/forms/inputs/uploadMobile'
import ConfirmModal from '../../../componentes/confirmModal';
import EditModal from '../../../componentes/editModal';
import EditModalMobile from '../../../componentes/editModalMobile';
// import AcontecimentosTimeLine from "../../../componentes/acontecimentos/acontecimentosTimeline";
import AcontecimentosNovoComentario from "../../../componentes/acontecimentos/acontecimentosNovoComentario";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

moment.tz.setDefault('UTC');

// DE ACORDO COM O REGIME DE TRAMITAÇÃO MOSTRAR MOMENTOS CRITICOS
// CONCLUSIVO MOSTRAR OS 4 MOMENTOS CRÍTICOS

@inject('uploadStore', 'authStore', 'alertaStore')
@observer

class Acontecimentos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            timeline: [],
            usuarios: [],
            acontecimento: [],
            anexos: [],
            relator: [],
            proposicaoId: 0,
            arquivo: [],
            showModal: false,
            showEdit: false,
            itemToDelete: {},
            itemToEdit: {},
        }
    }



    changeShowModalState(showModal, item) {
        // console.log("showModal, item", showModal, item);
        this.setState({ showModal: showModal, showEdit: false, itemToDelete: item })
    }

    editModal(showEdit, item) {

        // console.log(item);
        this.setState({
            showModal: false,
            showEdit: showEdit,
            itemToEdit: item
        })
    }

    editModalMobile(showEdit, item) {
        this.setState({ showModal: false, showEdit: showEdit, itemToEdit: item })
    }

    async componentWillMount() {
        await this.setState({ proposicaoId: this.props.idProposicao })

        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = `/proposicao/${this.props.idProposicao}/acao-preparatoria`
        }

        this.getDadosAcontecimento()
    }

    async excluir(show, save, item) {
        if (save) {
            await put(`/acontecimentos/${this.state.itemToDelete.id}`).then(() => {
                this.setState({ showModal: false })
                this.getDadosAcontecimento(this.state.proposicaoId)
                this.props.alertaStore.criar('Registro de atividade foi excluído com sucesso.');
            });
            // window.location.reload()
        }
    }

    edit(show, save, item) {
        if (save) {
            this.setState({ showEdit: false })
            this.getDadosAcontecimento(this.state.proposicaoId)
        }
    }

    async getDadosAcontecimento() {
        await get(`/tramitacao/acontecimentos/${this.props.idProposicao}`)
            .then(response => {
                // console.log("response ++++++++++++++++++++", response);
                this.setState({
                    acontecimento: response.acontecimento,
                    anexos: response.anexos,
                    relator: response.relator,
                    loading: false
                })
            })
    }


    render() {
        const tamanhoTela = window.innerWidth
        // const user = JSON.parse(window.localStorage.getItem('user'))
        // const userLogado = user.id
        const novoAcontecimento = async ({ contexto, acontecimento, link, id_preposicao, dataHora, anexosNome }) =>
            post(`/acontecimentos/` + this.state.proposicaoId, { contexto, acontecimento, link, id_preposicao, dataHora, anexosNome })
                .then(x => {
                    this.setState({ loading: true })
                    window.location.reload()
                })

        // console.log("test2", userLogado)

        return this.state.loading ? <Loading /> :



            <div>

                {
                    tamanhoTela < 600

                        ?


                        <div className="d-block d-sm-none ">

                            {/* >>>>>>>>>>>>>>>>>>>>MOBILE<<<<<<<<<<<<<<<<<< */}

                            <ConfirmModal
                                text={'Tem certeza que deseja excluir o registro de atividade?'}
                                description={'Esta ação não poderá ser desfeita no futuro'}
                                onClose={(show, save, item) => this.excluir(show, save, item)}
                                show={this.state.showModal}
                                reloadKey={true}
                            />
                            <EditModalMobile
                                text={'Tem certeza que deseja excluir o registro de atividade?'}
                                description={'Esta ação não poderá ser desfeita no futuro'}
                                onClose={(show, save, item) => this.edit(show, save, item)}
                                show={this.state.showEdit}
                                edit={this.state.itemToEdit}
                                anexos={this.state.anexos}
                            />
                            <div className="novo-acontecimento" >
                                <div className="container-fluid bg-white mb-2 p-0" style={{ border: "1px solid lightgray" }}>
                                    <div className="bg-gray-600 p-3">
                                        <h5 className="text-white m-0">Registro de Atividade</h5>
                                    </div>
                                    <AcontecimentosNovoComentario idProposicao={this.props.idProposicao} acao={novoAcontecimento} idPreposicao={this.state.proposicaoId} />
                                </div>
                            </div>

                            {/* ----- Cards ----- */}
                            <div className="acontecimentos">
                                <div>
                                    <div>
                                        {this.state.acontecimento !== undefined && Array.isArray(this.state.acontecimento) && this.state.acontecimento.length > 0 ? this.state.acontecimento.map((item, index) => {
                                            // console.log('ITEM ===> ',item.id_usuario)
                                            var casa = ''
                                            switch (item.sigla_casa) {
                                                case 'CN':
                                                    casa = 'Congresso Nacional'
                                                    break;
                                                case 'SF':
                                                    casa = 'Senado Federal'
                                                    break;
                                                case 'CD':
                                                    casa = 'Câmara dos Deputados'
                                                    break;
                                                default:
                                                    casa = ''
                                            }

                                            var comissao = ''
                                            switch (item.sigla_orgao) {
                                                case 'CCP':
                                                    comissao = 'Comissão de Conciliação Prévia'
                                                    break;
                                                case 'CCULT':
                                                    comissao = 'Comissão de cultura'
                                                    break;
                                                case 'CCJC':
                                                    comissao = 'Comissão de Constituição e Justiça e de Cidadania'
                                                    break;
                                                case 'MESA':
                                                    comissao = 'Mesa do Senado Federal'
                                                    break;
                                                case 'NAJUR(SGM)':
                                                    comissao = 'Núcleo de Assessoramento Jurídico'
                                                    break;
                                                case 'PLEN':
                                                    comissao = 'Plenário'
                                                    break;
                                                case 'CE':
                                                    comissao = 'Comissão de Educação'
                                                    break;
                                                case 'SGMGAB':
                                                    comissao = 'Secretaria do Gabinete do SGM'
                                                    break;
                                                case 'CVT':
                                                    comissao = 'Comissão de Viação e Transportes'
                                                    break;
                                                case 'SARQ':
                                                    comissao = 'Secretaria de Arquivo'
                                                    break;
                                                case 'SEXP':
                                                    comissao = 'Secretaria de expediente'
                                                    break;
                                                case 'PLEG':
                                                    comissao = 'Subsecretaria de ata - Plenário'
                                                    break;
                                                case 'COARQ':
                                                    comissao = 'Coordenação de Arquivo'
                                                    break;
                                                case 'ATA-PLEN':
                                                    comissao = 'Protocolo Legislativo'
                                                    break;
                                                case 'SSCLSF':
                                                    comissao = 'Subsecretaria coordenação legislativa do senado'
                                                    break;
                                                case 'SSEXP':
                                                    comissao = 'Secretaria Geral da Mesa'
                                                    break;
                                                case 'CDIR':
                                                    comissao = 'Comissão Diretora'
                                                    break;
                                                case 'SGM':
                                                    comissao = 'Secretaria Geral da Mesa'
                                                    break;
                                                case 'SSARQ':
                                                    comissao = 'Subsecretaria de Arquivo'
                                                    break;
                                                case 'CREDN':
                                                    comissao = 'Comissão de Relações Exteriores e de Defesa Nacional'
                                                    break;
                                                case 'CEUNIFI':
                                                    comissao = 'Unificação das polícias'
                                                    break;
                                                case 'CPIANIM':
                                                    comissao = 'Comissão Interna de Prevenção de Acidentes, na mineração'
                                                    break;
                                                default:
                                                    comissao = ''
                                            }

                                            return (
                                                item.acontecimento === undefined ?

                                                    <div key={index} className="" style={{ background: '#EAEAEA' }}>

                                                        <div className="row p-4">
                                                            <div className="col-12 col-sm-12 col-xl-6 casa-acontecimento">-
                                                                {casa}
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-xl-6 text-left text-sm-left text-xl-right">
                                                                <div className="tramitacao-data">
                                                                    {moment(item.data_hora).format('DD/MM/YYYY')}
                                                                </div>
                                                            </div>

                                                            <div className="col-12 mt-5 text-truncate text-wrap " style={{ fontWeight: 'bold' }}>
                                                                {item.sigla_orgao} {comissao !== '' ? ' - ' : ''} {comissao}
                                                            </div>

                                                            <div className="col-12 mt-2 despacho-texto text-truncate text-wrap mb-3">
                                                                {item.despacho}
                                                            </div>

                                                            <div className="col-12">
                                                                <UploadFile linkButton={true} modulo={'tramitacao'} id_referencia={item.id} onChange={anexo => this.setState({ anexos: [...this.state.anexos, ...anexo], showEdit: false })} />
                                                            </div>

                                                            {this.state.anexos !== undefined && this.state.anexos.length > 0 ?
                                                                this.state.anexos.map((anexo, index) => {
                                                                    return (
                                                                        anexo.modulo === 'tramitacao' && anexo.id_referencia === item.id ?
                                                                            <div key={index} className="col-md-6 mt-3 d-flex">
                                                                                <div className="anexo-linha">
                                                                                    <div className="row">
                                                                                        <div className="col-10" onClick={() => this.props.uploadStore.downloadArquivo(anexo, this.refs).then(x => console.log(x))}>
                                                                                            {/* <img src={require('../../../design/assets/images/clips-red.svg')} alt="anexo" /> */}
                                                                                            <div className=" ml-2" style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                                                            {' '}
                                                                                            {anexo.nome.substr(0, 18)}...
                                                                                        </div>
                                                                                        {this.props.authStore.ehVisualizador === true ? null :
                                                                                            <div className="col-2 text-center delete-anexo">
                                                                                                <img onClick={() => {
                                                                                                    this.props.uploadStore.removerAnexo(anexo);
                                                                                                    this.props.uploadStore.loading = false;
                                                                                                    const array = this.state.anexos
                                                                                                    array.splice(index, 1)
                                                                                                    this.setState({ anexos: array })
                                                                                                }} src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : null
                                                                    )
                                                                })
                                                                : null}

                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="mt-3 mb-3 ml-2 mr-2" >
                                                        <div className="row p-3" style={{ backgroundColor: "white", border: "1px solid lightgray", borderRadius: "7px" }}>


                                                            <div className="col-12 nome-relator">
                                                                <div className="row">
                                                                    <div className="col-10" style={{ marginLeft: "-14px" }}>
                                                                        {item.nome.replace('.', ' ').toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase() })}
                                                                        <div style={{ fontSize: "12px" }}>{moment(item.data_acontecimento).format('DD/MM/YYYY')}</div>
                                                                        <div>
                                                                            {item.meetingTime !== null ?
                                                                                <div style={{ backgroundColor: "black", height: "25px", width: "150px", borderRadius: "7px", alignContent: "center" }}>
                                                                                    <div className="text-center">
                                                                                        <span style={{ fontSize: "9px", color: "white", fontWeight: "lighter" }}>
                                                                                            Reunião -
                                                                                        </span>
                                                                                        <span style={{ fontSize: "9px", color: "white", fontWeight: "lighter" }} >{moment(item.meetingTime).format(' DD/MM/yyyy [às] HH:mm')}</span>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div class="dropleft col-2" >
                                                                        <button class="btn btn-white" style={{ border: "1px solid lightgray" }} type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-three-dots-vertical" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                            </svg>
                                                                        </button>
                                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                            <button style={{ marginTop: "5px" }} onClick={() => this.editModal(true, item)} class="dropdown-item" type="button">
                                                                                <span style={{ fontSize: "14px" }}><img className='' src={require('../../../design/assets/images/Vector.svg')} alt="" /><span style={{ marginTop: "10px", marginLeft: "10px", fontSize: "13px" }}>Editar</span></span>
                                                                            </button>
                                                                            <button class="dropdown-item" type="button">
                                                                                <div className="row" style={{ marginTop: "5px" }}>
                                                                                    <div>
                                                                                        <img style={{ width: "9px", marginTop: "-3px", marginLeft: "10px", marginRight: "12px" }} src={require('../../../design/assets/images/Mask.svg')} alt="excluir anexo delete-anexo" />
                                                                                    </div>
                                                                                    <div>
                                                                                        <UploadFileMobile linkButton={true} modulo={'acontecimentos'} id_referencia={item.id} onChange={anexo => this.setState({ anexos: [...this.state.anexos, ...anexo], showEdit: false })} />
                                                                                    </div>
                                                                                </div>
                                                                            </button>
                                                                            <button onClick={() => this.changeShowModalState(true, item)} class="dropdown-item" type="button">
                                                                                <span style={{ fontSize: "14px" }}><img style={{ width: "12px", marginTop: "-3px" }} src={require('../../../design/assets/images/Trash.svg')} alt="excluir anexo delete-anexo" /><span style={{ marginTop: "10px", marginLeft: "12px", fontSize: "13px" }}>Deletar</span></span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <span>
                                                                        {this.props.authStore.ehVisualizador === true ? null :
                                                                            <span>
                                                                                <span className="ml-3 parlamentarEspecificoDesk d-none d-sm-block" onClick={() => this.changeShowModalState(true, item)}>
                                                                                    <img src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                                </span>
                                                                                <span className="ml-3 parlamentarEspecificoMobile" onClick={() => this.changeShowModalState(true, item)}>
                                                                                    <img src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                                </span>
                                                                                <span className="ml-3 parlamentarEspecificoDesk d-none d-sm-block" onClick={() => this.editModal(true, item)}>
                                                                                    <img className='' src={require('../../../design/assets/images/editar-gray.svg')} alt="" />
                                                                                </span>
                                                                                <span className="ml-3 parlamentarEspecificoMobile" onClick={() => this.editModalMobile(true, item)}>
                                                                                    <img className='' src={require('../../../design/assets/images/editar-gray.svg')} alt="" />
                                                                                </span>
                                                                            </span>
                                                                        }
                                                                    </span> */}
                                                            </div>

                                                            <textarea name="acontecimento-card" id="acontecimento-card" className="mt-3 acontecimento-texto" rows="3" value={`${item.acontecimento.replace(/<(?!!br\s*\/?)[^>]+>/g, '').replace(/&nbsp;/g, '')}`} readonly></textarea>

                                                            {this.state.anexos !== undefined && this.state.anexos.length > 0 ?
                                                                this.state.anexos.map((anexo, index) => {
                                                                    return (
                                                                        anexo.modulo === 'acontecimentos' && anexo.id_referencia === item.id ?
                                                                            <div key={index} className="col-md-6 mt-3 d-flex" >

                                                                                <div style={{ border: "1px solid lightgray", borderRadius: "2px", padding: "10px", width: "100%" }}>
                                                                                    <div className="row">
                                                                                        <div className="col-10" onClick={() => this.props.uploadStore.downloadArquivo(anexo, this.refs).then(x => console.log(x))}>
                                                                                            {/* <img src={require('../../../design/assets/images/clips-red.svg')} alt="anexo" /> */}
                                                                                            <div className="row">
                                                                                                <div className=" ml-4" style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                                                                <div className="pl-2">
                                                                                                    {' '}{anexo.nome.substr(0, 18)}...
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {this.props.authStore.ehVisualizador === true ? null :
                                                                                            <div className="col-2 text-center delete-anexo">
                                                                                                <img onClick={() => {
                                                                                                    this.props.uploadStore.removerAnexo(anexo);
                                                                                                    this.props.uploadStore.loading = false;
                                                                                                    const array = this.state.anexos
                                                                                                    array.splice(index, 1)
                                                                                                    this.setState({ anexos: array })
                                                                                                }} src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : null
                                                                    )
                                                                })
                                                                : null}

                                                        </div>
                                                    </div>
                                            )
                                        }) : null}
                                    </div>
                                </div>
                            </div>
                        </div>




                        :


                        // ===============DESK===========
                        <div>
                            <ConfirmModal
                                text={'Tem certeza que deseja excluir o registro de atividade?'}
                                description={'Esta ação não poderá ser desfeita no futuro'}
                                onClose={(show, save, item) => this.excluir(show, save, item)}
                                show={this.state.showModal}
                                reloadKey={true}
                            />
                            <EditModal
                                text={'Tem certeza que deseja excluir o registro de atividade?'}
                                description={'Esta ação não poderá ser desfeita no futuro'}
                                onClose={(show, save, item) => this.edit(show, save, item)}
                                show={this.state.showEdit}
                                edit={this.state.itemToEdit}
                                anexos={this.state.anexos}
                            />
                            <div className="novo-acontecimento">
                                <div className="container-fluid bg-white mb-2 p-0" style={{ border: "1px solid lightgray" }}>
                                    <div className="bg-gray-600 p-3">
                                        <h5 className="text-white m-0">Registro de Atividade</h5>
                                    </div>
                                    <AcontecimentosNovoComentario idProposicao={this.props.idProposicao} acao={novoAcontecimento} idPreposicao={this.state.proposicaoId} />
                                </div>
                            </div>






                            {/* ----- Cards ----- */}





                            <div className="acontecimentos">
                                <div>
                                    <div>
                                        {this.state.acontecimento !== undefined && Array.isArray(this.state.acontecimento) && this.state.acontecimento.length > 0 ? this.state.acontecimento.map((item, index) => {
                                            // console.log('ITEM ===> ', item)
                                            var casa = ''
                                            switch (item.sigla_casa) {
                                                case 'CN':
                                                    casa = 'Congresso Nacional'
                                                    break;
                                                case 'SF':
                                                    casa = 'Senado Federal'
                                                    break;
                                                case 'CD':
                                                    casa = 'Câmara dos Deputados'
                                                    break;
                                                default:
                                                    casa = ''
                                            }

                                            var comissao = ''
                                            switch (item.sigla_orgao) {
                                                case 'CCP':
                                                    comissao = 'Comissão de Conciliação Prévia'
                                                    break;
                                                case 'CCULT':
                                                    comissao = 'Comissão de cultura'
                                                    break;
                                                case 'CCJC':
                                                    comissao = 'Comissão de Constituição e Justiça e de Cidadania'
                                                    break;
                                                case 'MESA':
                                                    comissao = 'Mesa do Senado Federal'
                                                    break;
                                                case 'NAJUR(SGM)':
                                                    comissao = 'Núcleo de Assessoramento Jurídico'
                                                    break;
                                                case 'PLEN':
                                                    comissao = 'Plenário'
                                                    break;
                                                case 'CE':
                                                    comissao = 'Comissão de Educação'
                                                    break;
                                                case 'SGMGAB':
                                                    comissao = 'Secretaria do Gabinete do SGM'
                                                    break;
                                                case 'CVT':
                                                    comissao = 'Comissão de Viação e Transportes'
                                                    break;
                                                case 'SARQ':
                                                    comissao = 'Secretaria de Arquivo'
                                                    break;
                                                case 'SEXP':
                                                    comissao = 'Secretaria de expediente'
                                                    break;
                                                case 'PLEG':
                                                    comissao = 'Subsecretaria de ata - Plenário'
                                                    break;
                                                case 'COARQ':
                                                    comissao = 'Coordenação de Arquivo'
                                                    break;
                                                case 'ATA-PLEN':
                                                    comissao = 'Protocolo Legislativo'
                                                    break;
                                                case 'SSCLSF':
                                                    comissao = 'Subsecretaria coordenação legislativa do senado'
                                                    break;
                                                case 'SSEXP':
                                                    comissao = 'Secretaria Geral da Mesa'
                                                    break;
                                                case 'CDIR':
                                                    comissao = 'Comissão Diretora'
                                                    break;
                                                case 'SGM':
                                                    comissao = 'Secretaria Geral da Mesa'
                                                    break;
                                                case 'SSARQ':
                                                    comissao = 'Subsecretaria de Arquivo'
                                                    break;
                                                case 'CREDN':
                                                    comissao = 'Comissão de Relações Exteriores e de Defesa Nacional'
                                                    break;
                                                case 'CEUNIFI':
                                                    comissao = 'Unificação das polícias'
                                                    break;
                                                case 'CPIANIM':
                                                    comissao = 'Comissão Interna de Prevenção de Acidentes, na mineração'
                                                    break;
                                                default:
                                                    comissao = ''
                                            }

                                            return (
                                                item.acontecimento === undefined ?

                                                    <div key={index} className="m-3" style={{ background: '#EAEAEA' }}  >

                                                        <div className="row p-4">
                                                            <div className="col-12 col-sm-12 col-xl-6 casa-acontecimento">- {casa}</div>
                                                            <div className="col-12 col-sm-12 col-xl-6 text-left text-sm-left text-xl-right">
                                                                <div className="tramitacao-data">{moment(item.data_hora).format('DD/MM/YYYY')}</div>
                                                            </div>

                                                            <div className="col-12 mt-5 text-truncate text-wrap " style={{ fontWeight: 'bold' }}>{item.sigla_orgao} {comissao !== '' ? ' - ' : ''} {comissao}</div>

                                                            <div className="col-12 mt-2 despacho-texto text-truncate text-wrap mb-3">{item.despacho}</div>

                                                            <div className="col-12">
                                                                <UploadFile
                                                                    linkButton={true}
                                                                    modulo={'tramitacao'}
                                                                    id_referencia={item.id}
                                                                    onChange={anexo => this.setState({ anexos: [...this.state.anexos, ...anexo], showEdit: false })} />
                                                            </div>

                                                            {this.state.anexos !== undefined && this.state.anexos.length > 0 ?
                                                                this.state.anexos.map((anexo, index) => {
                                                                    return (
                                                                        anexo.modulo === 'tramitacao' && anexo.id_referencia === item.id ?
                                                                            <div key={index} className="col-md-6 mt-3 d-flex">
                                                                                <div className="anexo-linha">
                                                                                    <div className="row" >
                                                                                        <div className="col-2" onClick={() => this.props.uploadStore.downloadArquivo(anexo, this.refs).then(x => console.log(x))}>
                                                                                            {/* <img src={require('../../../design/assets/images/clips-red.svg')} alt="anexo" /> */}
                                                                                            <div style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                                                        </div>
                                                                                        <div className="col-8 text-start" style={{ marginLeft: "-30px" }}>
                                                                                            {anexo.nome.substr(0, 18)}...
                                                                                        </div>
                                                                                        {this.props.authStore.ehVisualizador === true ? null :
                                                                                            <div className="col-2 text-center delete-anexo">
                                                                                                <img onClick={() => {
                                                                                                    const itemRef = { nome: anexo.nome, id_referencia: anexo.id_referencia }
                                                                                                    this.props.uploadStore.removerAnexoSemId(itemRef)
                                                                                                    this.props.uploadStore.loading = false;
                                                                                                    const array = this.state.anexos
                                                                                                    array.splice(index, 1)
                                                                                                    this.setState({ anexos: array })
                                                                                                }} src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : null
                                                                    )
                                                                })
                                                                : null}

                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="m-3" >
                                                        <div className="row p-3" style={{ backgroundColor: "white", border: "1px solid lightgray", borderRadius: "7px" }}>


                                                            <div className="col-12 nome-relator">
                                                                <div className="row">
                                                                    <div className="col-md-7" style={{ marginLeft: "-14px" }}>
                                                                        {item.nome.replace('.', ' ').toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase() })}
                                                                        <div style={{ fontSize: "12px" }}>{moment(item.data_acontecimento).format('DD/MM/yyyy [às] hh:mm').substr(0, 10)}</div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        {item.meetingTime !== null ?
                                                                            <div style={{ backgroundColor: "black", height: "30px", borderRadius: "7px", alignContent: "center", paddingTop: "3px" }}>
                                                                                <div className="text-center">
                                                                                    <span style={{ fontSize: "11px", color: "white", fontWeight: "lighter" }}>
                                                                                        Reunião -
                                                                                    </span>
                                                                                    <span style={{ fontSize: "11px", color: "white", fontWeight: "lighter" }} >{moment(item.meetingTime).format(' DD/MM/yyyy [às] HH:mm')}</span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                    <div class="dropleft col-md-1 ml-2" >
                                                                        <button class="btn btn-white" style={{ border: "1px solid lightgray" }} type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-three-dots-vertical" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                            </svg>
                                                                        </button>
                                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">

                                                                            <button style={{ marginTop: "5px" }} onClick={() => this.editModal(true, item)} class="dropdown-item" type="button">
                                                                                <span style={{ fontSize: "14px" }}><img className='' src={require('../../../design/assets/images/Vector.svg')} alt="" /><span style={{ marginTop: "10px", marginLeft: "10px", fontSize: "13px" }}>Editar</span></span>
                                                                            </button>
                                                                            <button class="dropdown-item" type="button">
                                                                                <div className="row" style={{ marginTop: "5px" }}>
                                                                                    <div>
                                                                                        <img style={{ width: "9px", marginTop: "-3px", marginLeft: "16px", marginRight: "12px" }} src={require('../../../design/assets/images/Mask.svg')} alt="excluir anexo delete-anexo" />
                                                                                    </div>
                                                                                    <div>
                                                                                        <UploadFile linkButton={true} modulo={'acontecimentos'} id_referencia={item.id} onChange={anexo => this.setState({ anexos: [...this.state.anexos, ...anexo], showEdit: false })} />
                                                                                    </div>
                                                                                </div>
                                                                            </button>
                                                                            <button onClick={() => this.changeShowModalState(true, item)} class="dropdown-item" type="button">
                                                                                <span style={{ fontSize: "14px" }}><img style={{ width: "12px", marginTop: "-3px" }} src={require('../../../design/assets/images/Trash.svg')} alt="excluir anexo delete-anexo" /><span style={{ marginTop: "10px", marginLeft: "12px", fontSize: "13px" }}>Deletar</span></span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <span>
                                                                        {this.props.authStore.ehVisualizador === true ? null :
                                                                            <span>
                                                                                <span className="ml-3 parlamentarEspecificoDesk d-none d-sm-block" onClick={() => this.changeShowModalState(true, item)}>
                                                                                    <img src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                                </span>
                                                                                <span className="ml-3 parlamentarEspecificoMobile" onClick={() => this.changeShowModalState(true, item)}>
                                                                                    <img src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                                </span>
                                                                                <span className="ml-3 parlamentarEspecificoDesk d-none d-sm-block" onClick={() => this.editModal(true, item)}>
                                                                                    <img className='' src={require('../../../design/assets/images/editar-gray.svg')} alt="" />
                                                                                </span>
                                                                                <span className="ml-3 parlamentarEspecificoMobile" onClick={() => this.editModalMobile(true, item)}>
                                                                                    <img className='' src={require('../../../design/assets/images/editar-gray.svg')} alt="" />
                                                                                </span>
                                                                            </span>
                                                                        }
                                                                    </span> */}
                                                            </div>

                                                            <textarea name="acontecimento-card" id="acontecimento-card" className="mt-3 acontecimento-texto" rows="3" value={`${item.acontecimento.replace(/<(?!!br\s*\/?)[^>]+>/g, '').replace(/&nbsp;/g, '')}`} readonly></textarea>

                                                            {this.state.anexos !== undefined && this.state.anexos.length > 0 ?
                                                                this.state.anexos.map((anexo, index) => {
                                                                    return (
                                                                        anexo.modulo === 'acontecimentos' && anexo.id_referencia === item.id ?
                                                                            <div key={index} className="col-md-6 mt-3 d-flex" >

                                                                                <div style={{ border: "1px solid lightgray", borderRadius: "2px", padding: "10px", width: "100%" }}>
                                                                                    <div className="row">
                                                                                        <div className="col-10" onClick={() => this.props.uploadStore.downloadArquivo(anexo, this.refs).then(x => console.log(x))}>
                                                                                            {/* <img src={require('../../../design/assets/images/clips-red.svg')} alt="anexo" /> */}
                                                                                            <div className="row">
                                                                                                <div className=" ml-4" style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                                                                <div className="pl-2">
                                                                                                    {' '}{anexo.nome.substr(0, 18)}...
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {this.props.authStore.ehVisualizador === true ? null :
                                                                                            <div className="col-2 text-center delete-anexo">
                                                                                                <img onClick={() => {
                                                                                                    const itemRef = { nome: anexo.nome, id_referencia: anexo.id_referencia }
                                                                                                    this.props.uploadStore.removerAnexoSemId(itemRef)
                                                                                                    this.props.uploadStore.loading = false;
                                                                                                    const array = this.state.anexos
                                                                                                    array.splice(index, 1)
                                                                                                    this.setState({ anexos: array })
                                                                                                }} src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : null
                                                                    )
                                                                })
                                                                :
                                                                null
                                                            }

                                                        </div>
                                                    </div>
                                            )
                                        }) : null}
                                    </div>
                                </div>
                            </div>
                        </div>


                }
            </div>
    }
}
export default props => <Acontecimentos {...props} />