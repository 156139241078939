import React, { Component } from 'react'
import Breadcrumb from '../../estruturas/breadcrumb';
// import './temas.css';
import TemaCard from './components/TemaCard';
import previdencia from './images/previdencia.svg';
import meioambiente from './images/meioambiente.svg';
import beneficios from './images/beneficios.svg';
import saude from './images/saude.svg';

const themes = [
  {
    id: 1,
    name: 'Previdência',
    image: previdencia,
    following: true
  },
  {
    id: 2,
    name: 'Meio Ambiente',
    image: meioambiente,
    following: false
  },
  {
    id: 3,
    name: 'Benefícios Sociais',
    image: beneficios,
    following: true
  },
  {
    id: 4,
    name: 'Saúde',
    image: saude,
    following: false
  }
]

class Temas extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <article className='temas'>
        <Breadcrumb />
        <section className="container mt-3 mb-3">
          <h1>Temas que eu sigo</h1>
          <div className="themes-container">
            {
              themes.filter(theme => theme.following).map(theme =>
                <TemaCard key={theme.id} following={theme.following} name={theme.name} image={theme.image}></TemaCard>
              )
            }
          </div>
          <h1>Todos os temas</h1>
          <div className="themes-container">
            {
              themes !== undefined && Array.isArray(themes) && themes.length > 0 ?
              themes.map(theme =>
                <TemaCard key={theme.id} following={theme.following} name={theme.name} image={theme.image}></TemaCard>
              ):null
            }
          </div>
        </section>
      </article>
    )
  }
}

export default Temas