
export const parlamentarQualificacao = [
    { "id": 1, value: "Convergente", icon: 'sucesso', img: 'sucesso-foto' },
    { "id": 2, value: "Divergente", icon: 'negativo', img: 'negativo-foto' },
    { "id": 3, value: "Não Classificado", icon: 'pendente', img: 'pendente-foto' },
    { "id": 4, value: "Neutro", icon: 'neutro', img: 'neutro-foto' },
]

 


