import React, { useState, useEffect, Fragment } from "react"
import { get, post, del } from "../../services/http"
import { Route } from "react-router-dom"
import Bread from "./bread"
import Badge from "./badge"
import { Accordion, SearchList } from "./accordion"
import Form from "./form"
import Add from "./add"
// import If from "../../componentes/helpers/If"

let MidiaNewsContext 
const { Provider, Consumer } = (MidiaNewsContext = React.createContext())

const MidiaNews = () => {
  const [seguidas, setSeguida] = useState([])
  const [importantes, setImportantes] = useState([])
  const [search, setSearch] = useState([])
  const [palavrasChaves, palavrasChave] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const result = (await get("midia/keyword")) || []
      // const importantes = (await get("midia/importantes")) || []
      setSeguida([...result])
      setImportantes([...importantes])
    }
    fetchData()
  }, [])

  const add = async ({ keyword }) => {
    if(keyword) {
      const insert = await post("midia/keyword", { keyword })
      await setSeguida([...seguidas, insert])
    
    }
  }

  const remove = async ({ id }) => {
    await del(`midia/keyword/${id}`)
    setSeguida(seguidas.filter(item => item.id !== id))
  }

  // const marcar = async ({ url }) => {
  //   const insert = await post("midia/importantes", { url })
  //   await setImportantes([...importantes, insert])
  // }

  // const desmarcar = async ({ id }) => {
  //   await del(`midia/importantes/${id}`)
  //   setImportantes(importantes.filter(item => item.id !== id))
  // }

  const loadSearch = async ({ keyword }) => {
    if (keyword) {
    const { articles } = await get(`midia/search/"${keyword}"`)
      setSearch(articles)
      palavrasChave(keyword)
    }
  }

  return (
    <Fragment>
      <Bread />
      <div className="container-fluid">
        <Provider
          value={{
            seguidas,
            importantes,
            // marcar,
            // desmarcar,
            remove,
            add,
            search,
            setSearch,
            loadSearch
          }}
        >
        <div className="row">
          <Form />
        </div>
        <hr />
        {palavrasChaves.length > 0 ? 
          <span className="badge py-0 mr-2 mb-3">
            <div className="text-secondary"> 
              Resultado para <span className="text-danger "> "{ palavrasChaves }" </span> 
            </div>
          </span>
        : null }
        <span >
          <Badge midiaFlex="d-flex" />
        </span>
        <SearchList />
        <Accordion />
        <Route exact path="/midia/add" component={Add} />
      </Provider>
      </div>
    </Fragment>
  )
}

export { MidiaNews, Consumer as MidiaNewsConsumer, MidiaNewsContext }

// export default index
