import React, { Component } from 'react'

const Container = (props) => (
    <div className={props.fluid ? `container-fluid ${props.css || ''}` : `container ${props.css || ''}`}>
        {props.children}
    </div>
)

const Row = (props) => (
    <div className={`row ${props.css || ''}`}>
        {props.children}
    </div>
)

class Grid extends Component {

    toCssClass(numbers) {
        const cols = numbers ? numbers.split(" ") : []

        let css = ''
        if (cols[0]) css += `col-${cols[0]} `
        if (cols[1]) css += `col-sm-${cols[1]} `
        if (cols[2]) css += `col-md-${cols[2]} `
        if (cols[3]) css += `col-lg-${cols[3]} `
        if (cols[4]) css += `col-xl-${cols[4]}`

        return css
    }

    render() {
        const gridClass = this.toCssClass(this.props.cols || 12)
        return (
            <div className={`${gridClass} ${this.props.css || ''}`}>
                {this.props.children}
            </div>
        )
    }
}

export { Container, Row, Grid }