import React, { Fragment } from "react";
import authStore from "../stores/authStore";
let id = 1;
// let permissao = user !== null && user !== undefined ? user.idPermissao : null
// let id_user = user !== null && user !== undefined ? user.id : null
// if(user !== null) {
//     var idDoCliente = user.idDoCliente
// } else {
//     idDoCliente = 1
// }
export default [
	//{ 'id': id++, 'posicao': 1, 'name': 'temas', 'path': '/', icon: 'icon-home-small' },
	// {
	//     'id': id++, 'posicao': 1, 'name': 'Proposições', 'path': '/proposicao', icon: 'icon-editorial-small fs-24', 'list': [
	//         { 'id': id++, 'name': 'Seguindo', 'path': '/proposicao', icon: 'icon-propositions-follow-small fs-24' },
	//         //{ 'id':id++, 'name': 'Temas', 'path': '/proposicao/temas', icon: 'icon-propositions-themes-small' },
	//         { 'id': id++, 'name': 'Novas', 'path': '/proposicao/hoje', icon: 'icon-propositions-themes-small fs-24' },
	//         { 'id': id++, 'name': 'Recomendações', 'path': '/proposicao/recomendacoes', icon: 'icon-propositions-themes-small fs-24' },
	//     ]
	// },
	{
		id: id++,
		posicao: 1,
		path: "/proposicao",
		icon: "icon-editorial-small fs-24",
	},
	{
		id: id++,
		posicao: 1,
		path: "/proposicao-estadual-municipal",
		icon: "icon-instituicoes-financeiras fs-24",
	},
	{
		id: id++,
		posicao: 1,
		path: "/parlamentar",
		icon: "icon-senator-small fs-24",
	},

	/*
    {
        'id': id++, 'posicao': 2, 'name': 'help', 'title': 'Help', 'css': 'white', 'list': [
            { 'id': id++, 'name': 'Perguntas frequentes', 'path': '/perguntas-frequentes' },
            { 'id': id++, 'name': 'O que há de novo?', 'path': '/o-que-ha-de-novo' },
            { 'id': id++, 'name': 'Sobre o purpleClient', 'path': '/sobre-o-purpleClient' },
            { 'id': id++, 'name': 'Termos de uso', 'path': '/termos-de-uso' },
            { 'id': id++, 'name': 'Deixar feedback', 'path': '/deixar-feedback' }
        ]
    },
    */
	// authStore.ehAdministrador === true
	//     ?
	{
		id: id++,
		posicao: 2,
		title: "Usuários",
		css: "blue",
		icon: "icon-configEngineIcon mt-4 mb-4 pl-2 fs-22",
		list: [
			{
				id: id++,
				css: "usuario",
				name: () => (
					<Fragment>
						<i
							style={{
								content: "var(--iconTema)",
								marginTop: "2px",
							}}
							className="fs-16"
						></i>
						Classificação de Temas
					</Fragment>
				),
				path: "/classificacao-tema",

				// 'path': '/usuario/perfil'
			},

			{
				id: id++,
				css: "usuario",
				name: () => (
					<Fragment>
						<i
							style={{
								content: "var(--iconIA)",
								marginTop: "2px",
							}}
							className="fs-16"
						></i>
						Inteligência Artificial
					</Fragment>
				),
				path: "/inteligencia-artificial",
				// 'path': '/usuario/perfil'
			},

			{
				id: id++,
				css: "usuario",
				name: () => (
					<Fragment>
						<i
							style={{
								content: "var(--iconUsuario)",
								marginTop: "2px",
							}}
							className="fs-16"
						></i>
						Gerenciamento de Usuário
					</Fragment>
				),
				path:
					authStore.ehAdministrador === true
						? "/usuario/gerenciamento-usuarios"
						: "/usuario/perfil",
				// 'path': '/usuario/perfil'
			},

			{
				id: id++,
				css: "usuario",
				name: () => (
					<Fragment>
						<i
							style={{
								content: "var(--iconSair)",
								marginTop: "2px",
							}}
							className="fs-16"
						></i>
						Sair
					</Fragment>
				),
				path: "/logout",
			},
			// { 'id': id++, 'name': 'Gerenciamento do time', 'path': '/usuario/gerenciamento/ativos', icon: 'icon-user-small mt-4 mb-4 fs-24' },
			// { 'id': id++, 'name': 'Sair', 'path': '/logout' }
		],
	},
	// : {
	// 		id: id++,
	// 		posicao: 2,
	// 		title: "Usuários",
	// 		css: "blue",
	// 		icon: "icon-configEngineIcon mt-4 mb-4 pl-2 fs-22",
	// 		list: [
	// 			{
	// 				id: id++,
	// 				css: "usuario",
	// 				name: () => (
	// 					<Fragment>
	// 						<i
	// 							style={{
	// 								content: "var(--iconUsuario)",
	// 								marginTop: "2px",
	// 							}}
	// 							className="fs-16"
	// 						></i>
	// 						Gerenciamento de Usuário
	// 					</Fragment>
	// 				),
	// 				path:
	// 					authStore.ehAdministrador === true
	// 						? "/usuario/gerenciamento-usuarios"
	// 						: "/usuario/perfil",
	// 				// 'path': '/usuario/perfil'
	// 			},

	// 			{
	// 				id: id++,
	// 				css: "usuario",
	// 				name: () => (
	// 					<Fragment>
	// 						<i
	// 							style={{
	// 								content: "var(--iconSair)",
	// 								marginTop: "2px",
	// 							}}
	// 							className="fs-16"
	// 						></i>
	// 						Sair
	// 					</Fragment>
	// 				),
	// 				path: "/logout",
	// 			},
	// 			// { 'id': id++, 'name': 'Gerenciamento do time', 'path': '/usuario/gerenciamento/ativos', icon: 'icon-user-small mt-4 mb-4 fs-24' },
	// 			// { 'id': id++, 'name': 'Sair', 'path': '/logout' }
	// 		],
	//   },
	{
		id: id++,
		posicao: 1,
		path: "/agenda",
		icon: "icon-calendar-events-small fs-24",
	},
	{
		id: id++,
		posicao: 1,
		path: "/midia",
		icon: "icon-acoes-relevantes fs-24",
	},
	// { 'id': id++, 'posicao': 1, 'name': 'Relatórios', 'path': '/relatorio', icon: 'icon-home-small fs-24' },
	// { 'id': id++, 'posicao': 1, 'name': 'Insights', 'path': '/insights/tema', icon: 'icon-pencil' },
];
