import React, { Component } from 'react'
import { put } from '../services/http'
import authStore from "../stores/authStore"
import {inject, observer } from 'mobx-react'

@inject('authStore')
@observer

class Seguir extends Component {
	constructor(props) {
		super(props);
		this.state = { seguindo: this.props.status, atualizando: false }
		console.log(this.state.atualizando)
		console.log(this.state.seguindo)
	}

	async alternar() {
		// console.log('this.props.url ===>',this.props.url)
		this.setState({ atualizando: true })
		try { await put(`${this.props.url}`).then(result => this.setState({ atualizando: false, seguindo: !!result })) }
		catch (error) { console.error(error) }
	}

	render() {
		
		const desabilitar = authStore.ehVisualizador || this.state.atualizando
		return (
			!this.props.linkButton ?
			<button style={{borderRadius:"5px"}} id="botao-seguir" disabled={desabilitar} onClick={() => this.alternar()} className={` ${this.state.atualizando ? 'btn btn-ligth' : !! (this.state.seguindo === 1 || this.state.seguindo === true) ? 'Client-btn-seguir btn-seguir'  : 'Client-btn-seguir btn-seguindo'}`}>
				{this.state.atualizando ? '' : (this.state.seguindo === 1 || this.state.seguindo === true) ? 'Seguindo' : 'Seguir'} 
			</button> : <>  <span className="Client-link" onClick={() => this.alternar()}>{this.state.atualizando ? '' : (this.state.seguindo === 1 || this.state.seguindo === true) ? 'Seguindo' : 'Seguir'} </span> </>
		)	
	}
}

export default Seguir
