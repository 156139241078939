import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'

import notificacaoStore from "../../stores/notificacaoStore";
import moment from 'moment-timezone';
// import '../notificacoes/components/tooltip-notification/tooltip-notification.css'
import Notificacao from '../notificacoes/notificacoes'
import GraficoInfoDashboard from './graficoInformacoesDashboard'
import GraficoInfoProposicoesCasa from './graficoInfoProposicoesCasa'
import GraficoInfoAcoesInfluencia from './graficoInfoAcoesInfluencia'
import GraficoInfoMomentosCriticos from './graficoInfoMomentosCriticos'
import GraficoInfoPosicionamento from './graficoInfoPosicionamento'
import GraficoInfoQualificacao from './graficoInfoQualificaoParlamentar'
import { get } from "../../services/http"
import Loading from '../../componentes/loading'

//import { amanha } from '../../helpers/date'
import { formatarDinheiro } from '../../helpers/formatarMoney'
import AccordionMobile from "../../componentes/accordionMobileDashPropo"

moment.tz.setDefault('UTC');

@inject('alertaStore', 'uploadStore', 'authStore')
@observer

class Dashboard extends Component {

    state = {
        diaDoEvento:
            new Date(),
        loading: true,
        mes: {},
        dados: {},
        propsNumeroProposicoesAtiva: '',
        emTramitacao: '',
        inativas: '',
        propSeguidas: '',
        propNumeroProposicoesCamara: '',
        propNumeroProposicoesSenado: '',
        propNumeroProposicoesCongresso: '',
        propMomentosCriticosAguardandoDesignacaoRelator: '',
        propMomentosCriticosAguardanoParecerDoRelator: '',
        propMomentosCriticosPrazoParaEmenda: '',
        propMomentosCriticosAguardaDeliberacao: '',
        propResultadoClientPendente: '',
        propResultadoClientExito: '',
        propResultadoClientSemExito: '',
        propResultadoClientExitoParcial: '',
        propQualificacaoParlamentarConvergente: '',
        propQualificacaoParlamentarDivergente: '',
        propQualificacaoParlamentarNaoClassificado: '',
        propQualificacaoParlamentarNeutro: '',
        propPosicionamentoFavoravel: '',
        propPosicionamentoFavoravelComEmenda: '',
        propPosicionamentoContrario: '',
        propPosicionamentoContrarioComEmenda: '',
        propPosicionamentoPendente: '',
        propPosicionamentoNeutro: '',
        dataInicial: '',
        dataFinal: '',
        idDoCliente: 0,
        propImpactoFinanceiroPositivo: 0,
        propImpactoFinanceiroIndiferente: 0,
        propImpactoFinanceiroNegativo: 0,
        propImpactoFinanceiroNaoMensuravel: 0,
        propParLamentaresSeguidas: '',
        propPrioridadeMinima: '',
        propPrioridadeMedia: '',
        propPrioridadeBaixa: '',
        propPrioridadeAlta: '',
        propPrioridadeMaximo: '',
        qtdPendeciaPorUsuario: '',
        qtdPendeciaPorDepartamento: '',
    };

    mudarDiaDoEvento(day) { this.setState({ diaDoEvento: day }) }
    fromNow = data => {
        const Y = moment(data).year()
        const M = moment(data).month()
        const D = moment(data).date()
        const HH = moment(data).hour()
        const MM = moment(data).minute()
        const SS = moment(data).second()
        return moment([Y, M, D, HH, MM, SS]).fromNow()
    }

    async componentDidMount() {
        const user = JSON.parse(window.localStorage.getItem('user'))
        this.setState({ idDoCliente: user.idDoCliente })
        await this.getData()
        await this.getPendencia()
    }

    getMonth(mes) {
        this.setState({ mes })
    }


    dataInicial(event) {
        this.setState({ dataInicial: event.target.value })
    }

    dataFinal(event) {
        this.setState({ dataFinal: event.target.value })
    }

    async limparDatas() {
        await this.setState({
            dataInicial: '',
            dataFinal: ''
        })
        document.getElementsByClassName('data-inicial')[0].value = ""
        document.getElementsByClassName('data-final')[0].value = ""

        // await this.setState({
        //     loading: true
        // })

        await this.getData()
    }

    filtroSeguidos = () => {

        const data = {
            convergente: false,
            divergente: false,
            naoClassificado: false,
            neutro: false,
            casa: [],
            ambito: [],
            estado: [],
            partido: [],
            idade: [],
            profissao: [],
            escolaridade: [],
            busca: '',
            seguidos: true
        }
        //console.log('data do filtro ==> ', data)
        window.localStorage.setItem('filtroParlamentar', JSON.stringify(data))
        window.location.href = "parlamentar#inicio-pagina"
        //window.location.reload()
    }

    async getData() {
        // if(this.state.loading === false) {
        //     await this.setState({ loading: true })
        //   }

        //const dataAtual = new Date()
        //const data = amanha(1, dataAtual)
        const dataAtualPesquisa = moment().format('YYYY-MM-DD 23:59:59')
        const dtIni = this.state.dataInicial ? this.state.dataInicial : moment().subtract(5, 'years').format('YYYY-MM-DD 00:00:01');
        let dataFim
        if (this.state.dataFinal) {
            //dataFim = new Date(this.state.dataFinal);
            //dataFim = new Date(dataFim.setDate(dataFim.getDate() + 2));
            dataFim = moment(this.state.dataFinal).format('YYYY-MM-DD 23:59:59')
            // console.log("cheguei aqui")
        }
        else {
            dataFim = dataAtualPesquisa
        }
        // console.log('dtIni',dtIni)
        // console.log('dataFim',dataFim)
        await get(`/dashboard/${dtIni}/${dataFim}`)
            .then(response => {
                const dados = response || []
                this.setState({
                    propsNumeroProposicoesAtiva: dados.numeroProposicoesAtiva,
                    emTramitacao: dados.numeroProposicoesSeguidasAtivas,
                    inativas: dados.numeroProposicoesSeguidasInativas,
                    propSeguidas: dados.numeroProposicoesSeguidasCamara,
                    propNumeroProposicoesCamara: dados.numeroProposicoesCamara,
                    propNumeroProposicoesSenado: dados.numeroProposicoesSenado,
                    propNumeroProposicoesCongresso: dados.numeroProposicoesCongresso,
                    propMomentosCriticosAguardandoDesignacaoRelator: dados.momentosCriticosAguardandoDesignacaoRelator,
                    propMomentosCriticosAguardanoParecerDoRelator: dados.momentosCriticosAguardanoParecerDoRelator,
                    propMomentosCriticosPrazoParaEmenda: dados.momentosCriticosPrazoParaEmenda,
                    propMomentosCriticosAguardaDeliberacao: dados.momentosCriticosAguardaDeliberacao,
                    propResultadoClientPendente: dados.resultadoClientPendente,
                    propResultadoClientExito: dados.resultadoClientExito,
                    propResultadoClientSemExito: dados.resultadoClientSemExito,
                    propResultadoClientExitoParcial: dados.resultadoClientExitoParcial,
                    propQualificacaoParlamentarConvergente: dados.qualificacaoParlamentarConvergente,
                    propQualificacaoParlamentarDivergente: dados.qualificacaoParlamentarDivergente,
                    propQualificacaoParlamentarNaoClassificado: dados.qualificacaoParlamentarNaoClassificado,
                    propQualificacaoParlamentarNeutro: dados.qualificacaoParlamentarNeutro,
                    propPosicionamentoFavoravel: dados.posicionamentoFavoravel,
                    propPosicionamentoFavoravelComEmenda: dados.posicionamentoFavoravelComEmenda,
                    propPosicionamentoContrario: dados.posicionamentoContrario,
                    propPosicionamentoContrarioComEmenda: dados.posicionamentoContrarioComEmenda,
                    propPosicionamentoPendente: dados.posicionamentoPendente,
                    propPosicionamentoNeutro: dados.posicionamentoNeutro,
                    propImpactoFinanceiroPositivo: parseFloat(dados.impactoFinanceiroPositivo),
                    propImpactoFinanceiroIndiferente: parseFloat(dados.impactoFinanceiroIndiferente),
                    propImpactoFinanceiroNegativo: parseFloat(dados.impactoFinanceiroNegativo),
                    propImpactoFinanceiroNaoMensuravel: parseFloat(dados.impactoFinanceiroNaoMensuravel),
                    propParLamentaresSeguidas: dados.parlamentaresSeguidas,
                    propPrioridadeMinima: dados.prioridadeMinima,
                    propPrioridadeMedia: dados.prioridadeMedia,
                    propPrioridadeBaixa: dados.prioridadeBaixa,
                    propPrioridadeAlta: dados.prioridadeAlta,
                    propPrioridadeMaximo: dados.prioridadeMaximo,
                    loading: false
                })
            })
            .catch(err => err)
    }
    async getPendencia() {
        await get(`/dashboard-pendencia/esforco-colaborativo`)
            .then(response => {
                const dados = response || []
                this.setState({
                    qtdPendeciaPorUsuario: dados.totalPorUsuario[0].totalPendenciaPorUsuario,
                    qtdPendeciaPorDepartamento: dados.totalPorDepartamento[0].totalPendenciaPorDepartamento,
                })
            })
            .catch(err => err)
    }

    selectFiltroProposicao = async (text) => {
        await window.localStorage.removeItem('filtroProposicao')
        await window.localStorage.removeItem('filtroInput')
        await window.localStorage.removeItem('filtroGrafico')
        const data = {
            text: text
        }
        await window.localStorage.setItem('filtroPendencia', JSON.stringify(data))
        window.location.href = '/proposicao/lista'
    }
    render() {
        // console.log('POSITIVO ===>',this.state.propImpactoFinanceiroPositivo)
        const idDoCliente = this.state.idDoCliente
        //const alturaNotificacao = this.props.authStore.ehEsforcoColaborativo === false ? "2458px" : "1090px"


        let classMarginTop;

        if (idDoCliente === 1) {

            classMarginTop = 'mt-0';

        } else if (idDoCliente === 3) {

            classMarginTop = 'mt-0';
        }
        return (

            this.state.loading ? <Loading /> :



                <article className={`dashboard ${classMarginTop}`}>
                    <div className="d-none d-sm-block bg-white pl-2" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                        <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                            DASHBOARD INICIAL
                        </p>
                    </div>

                    {/* >>>>>>>>>>>>>>>>>Mobile<<<<<<<<<<<<<<<< */}

                    <div className="d-block d-sm-none bg-white pl-2" style={{ boxShadow: "-3px 0px 10px #C0C0C0", marginTop: "52px" }}>
                        <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                            DASHBOARD INICIAL
                        </p>
                    </div>


                    <section className="container-fluid mb-3" style={{ marginTop: "20px" }}>

                        <div className="row">

                            <div className="col-xl-9 col-lg-8 col-12" style={{ height: '100%' }}>


                                <div className="row mr-1 ml-1 ">
                                    <div className="col-12 col-sm-12 col-lg-12 col-xl-12 mt-3">
                                        <p className="dashboard-titulo"><b>Dashboard Inicial</b></p>

                                        <div className="d-none d-sm-block">
                                            <div className="row">

                                                <div className="col-12 col-sm-6  col-lg-3 col-xl-3 mb-2 ">
                                                    <div className="data-dashboard-label">Data Inicial</div>
                                                    <input id="data-inicial" className="data-inicial" type="date" onChange={event => this.dataInicial(event)} />
                                                </div>
                                                <div className="col-12 col-sm-6  col-lg-3 col-xl-3">
                                                    <div className="data-dashboard-label">Data Final</div>
                                                    <input id="data-final" className="data-final" type="date" onChange={event => this.dataFinal(event)} />
                                                </div>
                                                <div className="col-12 col-sm-12  col-lg-2 col-xl-4 d-flex justify-content-xl-center mobile-top-dash">
                                                    <button id="pesquisa-dash" className="btn" style={{ backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)" }} variant="outlined" onClick={() => this.getData()}>
                                                        <img
                                                            className="mr-2"
                                                            src={require('../../design/assets/images/lupa.svg')}
                                                            alt="btn-pesquisar"
                                                        />{' '}
                                                        Pesquisar
                                                    </button>
                                                    <button id="limpar-dash" className="btn ml-2" style={{ border: "1px solid var(--corTextoBotaoLimpar)", backgroundColor: "var(--botaoLimpar)", color: "var(--corTextoBotaoLimpar)" }} variant="outlined" onClick={() => this.limparDatas()}>

                                                        Limpar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* >>>>>>>>>>>>>>>>>Mobile<<<<<<<<<<<<<<<< */}


                                    <div style={{ border: "1px solid var(--BorderSelectExpandButtom)", borderRadius: "5px", backgroundColor: '#fff', marginTop: "5px" }} className="p-2 col-12 d-block d-sm-none">

                                        <AccordionMobile
                                            mb={'mb-0'}
                                            p={'p-0'}
                                            titulo="Filtrar por período"
                                        >
                                            <div style={{ marginTop: "20px" }}>
                                                <hr />
                                            </div>


                                            <div className="col-12">
                                                <div className="row bg-white ">
                                                    <div className="col-12">
                                                        <div className="row espacamentoData">
                                                            <div className="col-6 espacamentoDataInt">
                                                                <div className="data-dashboard-label-proposicao">
                                                                    Data Inicial
                                                                </div>
                                                                <input id="data-inicial" className="data-inicial" type="date" onChange={event => this.dataInicial(event)} />
                                                            </div>

                                                            <div className="col-6">
                                                                <div className="data-dashboard-label-proposicao">
                                                                    Data Final
                                                                </div>
                                                                <input id="data-final" className="data-final" type="date" onChange={event => this.dataFinal(event)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-12 centerPesquisar">
                                                        <button id="pesquisa-dash" className="btn" style={{ padding: "15px", width: "100%", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)" }} variant="outlined" onClick={() => this.getData()}>
                                                            <img
                                                                className="mr-2"
                                                                src={require('../../design/assets/images/lupa.svg')}
                                                                alt="btn-pesquisar"
                                                            />{' '}
                                                            Pesquisar
                                                        </button>
                                                    </div>
                                                    <div className="col-12">
                                                        <button id="limpar-dash" className="btn" style={{ padding: "15px", width: "100%", backgroundColor: "var(--botaoLimpar)", color: "var(--corTextoBotaoLimpar)", border: "1px solid var(--corTextoBotaoLimpar)" }} variant="outlined" onClick={() => this.limparDatas()}>
                                                            Limpar
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </AccordionMobile>

                                    </div>
                                </div>

                                {/* >>>>>>>>>>>>>>>>>Mobile<<<<<<<<<<<<<<<< */}

                                <div className="d-block d-sm-none col-12" style={{ paddingLeft: "5px", paddingRight: "5px", marginBottom: "-22px" }}>
                                    <hr />
                                </div>




                                {/* >>>>>>>>>>>>>>>>>Continua Desk com configuracao CSS pra mobile<<<<<<<<<<<<<<<< */}

                                <div className="row mr-1 ml-1 ">
                                    <div className="col-12 card-grafico mt-4" >

                                        <div className="d-none d-sm-block">
                                            {this.props.authStore.ehEsforcoColaborativo === false ?
                                                <div>
                                                    <div className="row mt-2">
                                                        <div className="col-sm-12 col-xl-4 dashboad-filtro-listagem">
                                                            <b>Impacto Financeiro</b>
                                                        </div>
                                                        <div className="col-6 mb-3 pl-4 col-sm-6 col-xl-2">
                                                            <label htmlFor="">Positivo</label>
                                                            <div className="d-flex">
                                                                <span className="Client-circulo sucesso mt-1"></span>

                                                                <div className="dashboard-font-filtro-listagem">{formatarDinheiro(this.state.propImpactoFinanceiroPositivo)}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 mb-3 col-sm-6 col-xl-2">
                                                            <label htmlFor="">Indiferente</label>
                                                            <div className="d-flex">
                                                                <span className="Client-circulo neutro mt-1"></span>
                                                                <div className="dashboard-font-filtro-listagem">{formatarDinheiro(this.state.propImpactoFinanceiroIndiferente)}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 pl-4 col-sm-6 col-xl-2">
                                                            <label htmlFor="">Negativo</label>
                                                            <div className="d-flex">
                                                                <span className="Client-circulo negativo mt-1"></span>
                                                                <div className="dashboard-font-filtro-listagem">{formatarDinheiro(this.state.propImpactoFinanceiroNegativo)}</div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-6  col-sm-6 col-xl-2">
                                                        <label htmlFor="">Não Mensurável</label>
                                                        <div className="d-flex">
                                                        <span className="Client-circulo mensuravel mt-1"></span>
                                                        <div className="dashboard-font-filtro-listagem">{formatarDinheiro(this.state.propImpactoFinanceiroNaoMensuravel)}</div>
                                                        </div>
                                                    </div> */}
                                                    </div>

                                                    <hr />

                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 col-xl-2 dashboad-filtro-listagem">
                                                            <b>Prioridade</b>
                                                        </div>
                                                        <div className="col-4 mb-3 col-sm-6 col-lg-2 col-xl-2">
                                                            <label htmlFor="">Mínimo</label>
                                                            <div className="d-flex">
                                                                <span className="Client-circulo prioridade-1 mt-1"></span>
                                                                <div className="dashboard-font-filtro-listagem">{this.state.propPrioridadeMinima} %</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 mb-3 col-sm-6 col-lg-2 col-xl-2">
                                                            <label htmlFor="">Baixa</label>
                                                            <div className="d-flex">
                                                                <span className="Client-circulo prioridade-2 mt-1"></span>
                                                                <div className="dashboard-font-filtro-listagem">{this.state.propPrioridadeBaixa} %</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 mb-3 col-sm-6 col-lg-2 col-xl-2">
                                                            <label htmlFor="">Média</label>
                                                            <div className="d-flex">
                                                                <span className="Client-circulo prioridade-3 mt-1"></span>
                                                                <div className="dashboard-font-filtro-listagem">{this.state.propPrioridadeMedia} %</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 col-sm-6 col-lg-2 col-xl-2">
                                                            <label htmlFor="">Alta</label>
                                                            <div className="d-flex">
                                                                <span className="Client-circulo prioridade-4 mt-1"></span>
                                                                <div className="dashboard-font-filtro-listagem">{this.state.propPrioridadeAlta} %</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4  col-sm-6 col-lg-2 col-xl-2">
                                                            <label htmlFor="">Máxima</label>
                                                            <div className="d-flex">
                                                                <span className="Client-circulo prioridade-5 mt-1"></span>
                                                                <div className="dashboard-font-filtro-listagem">{this.state.propPrioridadeMaximo} %</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }

                                            <div>
                                                {this.props.authStore.ehEsforcoColaborativo === false ?
                                                    <hr />
                                                    : null}

                                                <div className="row" style={{ paddingTop: `${this.props.authStore.ehEsforcoColaborativo === true ? "15px" : "0px"}` }}>
                                                    <div className="col-12 col-sm-12 col-xl-3 dashboad-filtro-listagem">
                                                        <b>Pendências</b>
                                                    </div>
                                                    <div className="col-9 col-sm-4 mb-3 col-sm-6 col-lg-5 col-xl-4 ml-xl-5 fontPendencia">
                                                        <label htmlFor="">{this.state.qtdPendeciaPorUsuario}</label><label htmlFor="" className="pl-2">Atribuídos a mim</label>
                                                    </div>
                                                    <div className="d-block d-xl-none col-3 col-sm-4 fontPendencia">
                                                        <label htmlFor="" style={{ color: "red", textDecoration: "underline", cursor: "pointer" }}
                                                            onClick={() => { this.selectFiltroProposicao('pendenciaUsuario') }}>
                                                            Ver lista
                                                        </label>
                                                    </div>
                                                    <div className="col-9 col-sm-6 col-lg-5 col-xl-4 fontPendencia">
                                                        <label htmlFor="">{this.state.qtdPendeciaPorDepartamento}</label><label htmlFor="" className="pl-2">Atribuídos ao departamento</label>
                                                    </div>
                                                    <div className="d-block d-xl-none col-3 col-sm-4 fontPendencia">
                                                        <label htmlFor="" style={{ color: "red", textDecoration: "underline", cursor: "pointer" }}
                                                            onClick={() => { this.selectFiltroProposicao('pendenciaDepartamento') }}>Ver lista</label>
                                                    </div>
                                                </div>
                                                <div className="row d-none d-xl-flex" style={{ paddingBottom: `${this.props.authStore.ehEsforcoColaborativo === true ? "5px" : "0px"}` }}>
                                                    <div className="col-xl-8 ml-xl-5 mt-xl-n3 text-center justify-content-center">
                                                        <label htmlFor=""
                                                            style={{ color: "var(--linkText)", textDecoration: "underline", cursor: "pointer" }}

                                                            onClick={() => { this.selectFiltroProposicao('pendenciaUsuario') }}>
                                                            Ver lista
                                                        </label>
                                                    </div>
                                                    <div className="col-xl-2 mt-xl-n3 text-left justify-content-left">
                                                        <label htmlFor="" style={{ color: "var(--linkText)", textDecoration: "underline", cursor: "pointer" }}
                                                            onClick={() => { this.selectFiltroProposicao('pendenciaDepartamento') }}>
                                                            Ver lista
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>


                                            {this.props.authStore.ehEsforcoColaborativo === false ?
                                                <div>
                                                    <hr />

                                                    <div className="row pb-3">
                                                        <div className="col-6 p-0 col-sm-6 col-xl-6 dashboad-filtro-listagem">
                                                            <div className="ml-2"><b>Parlamentares</b> </div>
                                                        </div>
                                                        <div className="col-6 pl-4 mt-2 col-sm-6 col-xl-6 d-flex">
                                                            <div className="mr-1 "><b>{this.state.propParLamentaresSeguidas}</b></div>
                                                            {/*<label style={{cursor:"pointer", color:"var(--linkText)", textDecoration:"underline"}} htmlFor="">Seguidos</label>*/}
                                                            <div onClick={event => this.filtroSeguidos(event)} className="link-indice-dashboard-parlamentar d-inline">Seguidos</div>
                                                        </div>
                                                    </div>

                                                    {/* <hr/> */}
                                                </div>
                                                : null
                                            }
                                        </div>



                                        {/* >>>>>>>>>>>>>>>>Mobile<<<<<<<<<<<<<<<<< */}

                                        <div className="d-block d-sm-none" >
                                            {this.props.authStore.ehEsforcoColaborativo === false ?
                                                <div>
                                                    <div className="row mt-2 pl-2 pt-2">
                                                        <div className="col-12 dashboad-filtro-listagem">
                                                            <b>Impacto Financeiro</b>
                                                        </div>
                                                        <div className="col-12 pt-2">
                                                            <label htmlFor="">Positivo</label>
                                                            <div className="d-flex pl-1">
                                                                <span className="Client-circulo sucesso mt-1"></span>

                                                                <div className="dashboard-font-filtro-listagem">{formatarDinheiro(this.state.propImpactoFinanceiroPositivo)}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 pt-2">
                                                            <label htmlFor="">Indiferente</label>
                                                            <div className="d-flex pl-1">
                                                                <span className="Client-circulo neutro mt-1"></span>
                                                                <div className="dashboard-font-filtro-listagem">{formatarDinheiro(this.state.propImpactoFinanceiroIndiferente)}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 pt-2">
                                                            <label htmlFor="">Negativo</label>
                                                            <div className="d-flex pl-1">
                                                                <span className="Client-circulo negativo mt-1"></span>
                                                                <div className="dashboard-font-filtro-listagem">{formatarDinheiro(this.state.propImpactoFinanceiroNegativo)}</div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-6  col-sm-6 col-xl-2">
                                                        <label htmlFor="">Não Mensurável</label>
                                                        <div className="d-flex">
                                                        <span className="Client-circulo mensuravel mt-1"></span>
                                                        <div className="dashboard-font-filtro-listagem">{formatarDinheiro(this.state.propImpactoFinanceiroNaoMensuravel)}</div>
                                                        </div>
                                                    </div> */}
                                                    </div>

                                                    <hr />

                                                    <div className="row mt-2 pl-2">

                                                        <div className="col-12 dashboad-filtro-listagem">
                                                            <b>Prioridade</b>
                                                        </div>

                                                        <div className=" row pt-2 pl-2">
                                                            <div className="pl-2 ">
                                                                <label style={{ fontSize: "13px" }} htmlFor="">Mínima</label>
                                                                <div className="d-flex pl-1">
                                                                    <span className="Client-circulo prioridade-1 mt-1"></span>
                                                                    <div style={{ fontSize: "11px", marginLeft: "-4px" }}>{this.state.propPrioridadeMinima} %</div>
                                                                </div>
                                                            </div>
                                                            <div className="pl-2">
                                                                <label style={{ fontSize: "13px" }} htmlFor="" className="pl-1">Baixa</label>
                                                                <div className="d-flex pl-2">
                                                                    <span className="Client-circulo prioridade-2 mt-1"></span>
                                                                    <div style={{ fontSize: "11px", marginLeft: "-4px" }}>{this.state.propPrioridadeBaixa} %</div>
                                                                </div>
                                                            </div>
                                                            <div className="pl-2">
                                                                <label style={{ fontSize: "13px" }} htmlFor="" className="pl-1">Média</label>
                                                                <div className="d-flex pl-2">
                                                                    <span className="Client-circulo prioridade-3 mt-1"></span>
                                                                    <div style={{ fontSize: "11px", marginLeft: "-4px" }}>{this.state.propPrioridadeMedia} %</div>
                                                                </div>
                                                            </div>
                                                            <div className="pl-2">
                                                                <label style={{ fontSize: "13px" }} htmlFor="" className="pl-1">Alta</label>
                                                                <div className="d-flex pl-2">
                                                                    <span className="Client-circulo prioridade-4 mt-1"></span>
                                                                    <div style={{ fontSize: "11px", marginLeft: "-4px" }}>{this.state.propPrioridadeAlta} %</div>
                                                                </div>
                                                            </div>
                                                            <div className="pl-2">
                                                                <label style={{ fontSize: "13px" }} htmlFor="" className="pl-1">Máxima</label>
                                                                <div className="d-flex pl-2">
                                                                    <span className="Client-circulo prioridade-5 mt-1"></span>
                                                                    <div style={{ fontSize: "11px", marginLeft: "-4px" }}>{this.state.propPrioridadeBaixa} %</div>
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>

                                                    <hr />

                                                    <div className="row pl-2">
                                                        <div className="col-12 col-sm-12 col-xl-3 dashboad-filtro-listagem">
                                                            <b>Pendências</b>
                                                        </div>

                                                        <div className="pl-2 pt-2 row">

                                                            <div className="col-12">
                                                                <div className="row pl-2">
                                                                    <p>
                                                                        {this.state.qtdPendeciaPorUsuario}
                                                                    </p>
                                                                    <label htmlFor="" style={{ paddingLeft: "5px", color: "var(--linkText)", textDecoration: "underline", cursor: "pointer" }}
                                                                        onClick={() => { this.selectFiltroProposicao('pendenciaUsuario') }}>
                                                                        Atribuídos à mim
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12" style={{ marginTop: "-15px", marginBottom: "-13px" }} >
                                                                <div className="row pl-2">
                                                                    <p>
                                                                        {this.state.qtdPendeciaPorDepartamento}
                                                                    </p>
                                                                    <label htmlFor="" style={{ paddingLeft: "5px", color: "var(--linkText)", textDecoration: "underline", cursor: "pointer" }}
                                                                        onClick={() => { this.selectFiltroProposicao('pendenciaDepartamento') }}>
                                                                        Atribuídos ao Departamento
                                                                    </label>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>



                                                    <hr />

                                                    <div className="row pb-3 pl-2" style={{ marginBottom: "-17px" }}>
                                                        <div className="col-12 dashboad-filtro-listagem">
                                                            <div><b>Parlamentares</b> </div>
                                                        </div>
                                                        <div className="row ml-2 pt-2">
                                                            <p>
                                                                {this.state.propParLamentaresSeguidas}
                                                            </p>
                                                            <label className="pl-1" style={{ cursor: "pointer", color: "var(--linkText)", textDecoration: "underline" }} htmlFor="">Seguidos</label>
                                                        </div>
                                                    </div>

                                                    {/* <hr/> */}
                                                </div>
                                                : null
                                            }
                                        </div>

                                        {/* { <div className="row mb-2">

                                                <div className="col-6 p-0 col-sm-6 col-xl-6 dashboad-filtro-listagem">
                                                <b>Em tramitação</b>
                                                </div>

                                                <div className="col-6 pl-4 mt-2 col-sm-3 col-xl-3 d-flex flex-wrap">
                                                    <div className="mr-1"><b>{this.state.emTramitacao}</b></div>
                                                    <label>Seguidas</label>
                                                </div>    

                                            <div className="col-4 col-sm-3 col-xl-3 d-flex flex-wrap">     
                                                    
                                                    <label htmlFor="">Não seguidas</label>
                                                    <div className="ml-1"><b>{this.state.inativas}</b></div> 
                                                </div> 


                                            </div> } */}


                                    </div>

                                    {/* <div className="col-md-12 card-grafico mt-2 ">
                                        {this.props.authStore.ehEsforcoColaborativo === false ?
                                            <div style={{ backgroundColor: "white" }}>
                                                <div className="row p-2">

                                                    <div className="col-md-3 dashboad-filtro-listagem">
                                                        <b>Pendências</b>
                                                    </div>

                                                    <div className="col-md-4 bigDisplayNone text-center">
                                                        <label htmlFor="">{this.state.qtdPendeciaPorUsuario}</label><label htmlFor="" className="pl-2">Atribuídos a mim</label>

                                                        <div className="text-center" >
                                                            <label htmlFor="" style={{ color: "red", textDecoration: "underline", cursor: "pointer" }}
                                                                onClick={() => { this.selectFiltroProposicao('pendenciaUsuario') }}>
                                                                Ver lista
                                                        </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 smallDisplayNone">
                                                        <div className="row pl-2 pt-2" >
                                                            <label htmlFor="">{this.state.qtdPendeciaPorUsuario}</label><label htmlFor="" className="pl-2">Atribuídos a mim</label>
                                                            <div className="pl-3">
                                                                <label htmlFor="" style={{ color: "red", textDecoration: "underline", cursor: "pointer" }}
                                                                    onClick={() => { this.selectFiltroProposicao('pendenciaUsuario') }}>
                                                                    Ver lista
                                                            </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-5 bigDisplayNone text-center" >
                                                        <label htmlFor="">{this.state.qtdPendeciaPorDepartamento}</label><label htmlFor="" className="pl-2">Atribuídos ao departamento</label>
                                                        <div>
                                                            <label htmlFor="" style={{ color: "red", textDecoration: "underline", cursor: "pointer" }}
                                                                onClick={() => { this.selectFiltroProposicao('pendenciaDepartamento') }}>Ver lista</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-5 smallDisplayNone" >
                                                        <div className="row pl-2 pt-2" >
                                                            <label htmlFor="">{this.state.qtdPendeciaPorDepartamento}</label><label htmlFor="" className="pl-2">Atribuídos ao departamento</label>
                                                            <div className="pl-3">
                                                                <label htmlFor="" style={{ color: "red", textDecoration: "underline", cursor: "pointer" }}
                                                                    onClick={() => { this.selectFiltroProposicao('pendenciaDepartamento') }}>Ver lista</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            :
                                            null
                                        }
                                    </div> */}

                                    <div className="col-12 card-grafico mt-2">
                                        <span className="d-flex justify-content-center mt-2"><b className="dashboard-graficos-titulo">Universo</b></span>
                                        <div className={`d-flex justify-content-center flex-wrap`}>
                                            <GraficoInfoDashboard
                                                emTramitacao={this.state.emTramitacao}
                                                inativas={this.state.inativas}
                                            />
                                            <div className="div-indice">
                                                <div className="d-flex">
                                                    <div className="indice-exito mt-1 mr-2"></div>
                                                    <div className="mr-2 dasboard-graficos-legenda">Ativos</div>
                                                    <span className="dashboard-graficos-dados">{this.state.emTramitacao}</span>
                                                </div>
                                                <div className="d-flex mt-2 mb-2">
                                                    <div className="indice-sem-exito mt-1 mr-2"></div>
                                                    <div className="mr-2 dasboard-graficos-legenda">Inativas</div>
                                                    <span className="dashboard-graficos-dados">{this.state.inativas}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 card-grafico mt-2">
                                        <span className="d-flex justify-content-center mt-2"><b className="dashboard-graficos-titulo">Casas legislativas</b></span>
                                        <div className={`d-flex justify-content-center flex-wrap`}>
                                            <GraficoInfoProposicoesCasa
                                                propCamara={this.state.propNumeroProposicoesCamara}
                                                propSenado={this.state.propNumeroProposicoesSenado}
                                                propCongresso={this.state.propNumeroProposicoesCongresso}
                                            />
                                            <div className="div-indice">
                                                <div className="d-flex">
                                                    <div className="indice-exito mt-1 mr-2"></div>
                                                    <div className="mr-2 dasboard-graficos-legenda">Câmara dos Deputados</div>
                                                    <span className="dashboard-graficos-dados">{this.state.propNumeroProposicoesCamara}</span>
                                                </div>

                                                <div className="d-flex mt-2">
                                                    <div className="indice-sem-exito mt-1 mr-2"></div>
                                                    <div className="mr-2 dasboard-graficos-legenda">Senado Federal</div>
                                                    <span className="dashboard-graficos-dados">{this.state.propNumeroProposicoesSenado}</span>
                                                </div>

                                                <div className="d-flex mt-2 mb-2">
                                                    <div className="indice-exito-parcial mt-1 mr-2"></div>
                                                    <div className="mr-2 dasboard-graficos-legenda">Congresso Nacional</div>
                                                    <span className="dashboard-graficos-dados">{this.state.propNumeroProposicoesCongresso}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.props.authStore.ehEsforcoColaborativo === false ?
                                        <div className="col-12 card-grafico mt-2">
                                            <span className="d-flex justify-content-center mt-2"><b className="dashboard-graficos-titulo">Ação de influência</b></span>
                                            <div className={`d-flex justify-content-center flex-wrap`}>
                                                <GraficoInfoAcoesInfluencia
                                                    propResultadoClientPendente={this.state.propResultadoClientPendente}
                                                    propResultadoClientExito={this.state.propResultadoClientExito}
                                                    propResultadoClientSemExito={this.state.propResultadoClientSemExito}
                                                    propResultadoClientExitoParcial={this.state.propResultadoClientExitoParcial}
                                                />
                                                <div className="div-indice">
                                                    <div className="d-flex mt-2">
                                                        <div className="indice-exito mt-1 mr-2"></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Êxito</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propResultadoClientExito}</span>
                                                    </div>
                                                    <div className="d-flex mt-2">
                                                        <div className="indice-sem-exito mt-1 mr-2"></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Sem Êxito</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propResultadoClientSemExito}</span>
                                                    </div>
                                                    <div className="d-flex mt-2">
                                                        <div className="indice-exito-parcial mt-1 mr-2 "></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Êxito Parcial</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propResultadoClientExitoParcial}</span>
                                                    </div>
                                                    <div className="d-flex mb-2">
                                                        <div className="indice-pendente mt-1 mr-2"></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Pendente</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propResultadoClientPendente}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    <div className="col-12 card-grafico mt-2">
                                        <span className="d-flex justify-content-center mt-2"><b className="dashboard-graficos-titulo">Momentos críticos</b></span>
                                        <div className={`d-flex justify-content-center flex-wrap`}>
                                            <div className="alinhamentoGraficoMomentoCritico">

                                                <GraficoInfoMomentosCriticos
                                                    propMomentosCriticosAguardandoDesignacaoRelator={this.state.propMomentosCriticosAguardandoDesignacaoRelator}
                                                    propMomentosCriticosAguardanoParecerDoRelator={this.state.propMomentosCriticosAguardanoParecerDoRelator}
                                                    propMomentosCriticosPrazoParaEmenda={this.state.propMomentosCriticosPrazoParaEmenda}
                                                    propMomentosCriticosAguardaDeliberacao={this.state.propMomentosCriticosAguardaDeliberacao}
                                                />
                                            </div>
                                            <div className="div-indice">
                                                <div className="d-flex">
                                                    <div className="indice-exito mt-1 mr-1"></div>
                                                    <div className="mr-2 dasboard-graficos-legenda">Aguardando Designação do Relator</div>
                                                    <span className="dashboard-graficos-dados">{this.state.propMomentosCriticosAguardandoDesignacaoRelator}</span>
                                                </div>

                                                <div className="d-flex mt-2">
                                                    <div className="indice-sem-exito mt-1 mr-1"></div>
                                                    <div className="mr-2 dasboard-graficos-legenda">Aguardando Parecer do Relator</div>
                                                    <span className="dashboard-graficos-dados">{this.state.propMomentosCriticosAguardanoParecerDoRelator}</span>
                                                </div>

                                                <div className="d-flex mt-2">
                                                    <div className="indice-exito-parcial mt-1 mr-1"></div>
                                                    <div className="mr-2 dasboard-graficos-legenda">Prazo para Emendas</div>
                                                    <span className="dashboard-graficos-dados">{this.state.propMomentosCriticosPrazoParaEmenda}</span>
                                                </div>

                                                <div className="d-flex mt-2 mb-2">
                                                    <div className="indice-pendente mt-1 mr-1 "></div>
                                                    <div className="mr-2 dasboard-graficos-legenda">Aguardando Deliberação</div>
                                                    <span className="dashboard-graficos-dados">{this.state.propMomentosCriticosAguardaDeliberacao}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.props.authStore.ehEsforcoColaborativo === false ?
                                        <div className="col-12 card-grafico mt-2">
                                            <span className="d-flex justify-content-center mt-2"><b className="dashboard-graficos-titulo">Qualificação parlamentar</b></span>
                                            <div className={`d-flex justify-content-center flex-wrap`}>
                                                <div className="alinhamentoGraficoQualificacaoParlamentar">

                                                    <GraficoInfoQualificacao
                                                        propQualificacaoParlamentarConvergente={this.state.propQualificacaoParlamentarConvergente}
                                                        propQualificacaoParlamentarDivergente={this.state.propQualificacaoParlamentarDivergente}
                                                        propQualificacaoParlamentarNaoClassificado={this.state.propQualificacaoParlamentarNaoClassificado}
                                                        propQualificacaoParlamentarNeutro={this.state.propQualificacaoParlamentarNeutro}
                                                    />
                                                </div>
                                                <div className="div-indice">
                                                    <div className="d-flex">
                                                        <div className="indice-exito mt-1 mr-1"></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Convergente</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propQualificacaoParlamentarConvergente}</span>
                                                    </div>

                                                    <div className="d-flex mt-2">
                                                        <div className="indice-sem-exito mt-1 mr-1"></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Divergente</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propQualificacaoParlamentarDivergente}</span>
                                                    </div>

                                                    <div className="d-flex mt-2">
                                                        <div className="indice-exito-parcial mt-1 mr-1"></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Não Classificado</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propQualificacaoParlamentarNaoClassificado}</span>
                                                    </div>

                                                    <div className="d-flex mt-2 mb-2">
                                                        <div className="indice-sem-info mt-1 mr-1 "></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Neutro</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propQualificacaoParlamentarNeutro}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {this.props.authStore.ehEsforcoColaborativo === false ?
                                        <div className="col-12 card-grafico mt-2">
                                            <span className="d-flex justify-content-center mt-2"><b className="dashboard-graficos-titulo">Posicionamento</b></span>
                                            <div className={`d-flex justify-content-center flex-wrap`}>
                                                <GraficoInfoPosicionamento
                                                    propPosicionamentoFavoravel={this.state.propPosicionamentoFavoravel}
                                                    propPosicionamentoFavoravelComEmenda={this.state.propPosicionamentoFavoravelComEmenda}
                                                    propPosicionamentoContrario={this.state.propPosicionamentoContrario}
                                                    propPosicionamentoContrarioComEmenda={this.state.propPosicionamentoContrarioComEmenda}
                                                    propPosicionamentoPendente={this.state.propPosicionamentoPendente}
                                                    propPosicionamentoNeutro={this.state.propPosicionamentoNeutro}
                                                />

                                                <div className="div-indice">
                                                    <div className="d-flex">
                                                        <div className="indice-exito mt-1 mr-2"></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Favorável</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propPosicionamentoFavoravel}</span>
                                                    </div>
                                                    <div className="d-flex mt-2">
                                                        <div className="indice-exito-parcial mt-1 mr-2"></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Favorável com Emenda Saneadora</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propPosicionamentoFavoravelComEmenda}</span>
                                                    </div>
                                                    <div className="d-flex mt-2">
                                                        <div className="indice-sem-exito mt-1 mr-2"></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Contrário</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propPosicionamentoContrario}</span>
                                                    </div>
                                                    <div className="d-flex mt-2 mb-2">
                                                        <div className="indice-contrario-emenda mt-1 mr-2 "></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Contrário com Emenda Saneadora</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propPosicionamentoContrarioComEmenda}</span>
                                                    </div>
                                                    <div className="d-flex mt-2 mb-2">
                                                        <div className="indice-pendente mt-1 mr-2 "></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Pendente</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propPosicionamentoPendente}</span>
                                                    </div>
                                                    <div className="d-flex mt-2 mb-2">
                                                        <div className="indice-sem-info mt-1 mr-2 "></div>
                                                        <div className="mr-2 dasboard-graficos-legenda">Neutro</div>
                                                        <span className="dashboard-graficos-dados">{this.state.propPosicionamentoNeutro}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                </div>
                            </div>
                            {/* <div className="d-none d-sm-block"> */}
                            <div className="col-xl-3 col-lg-4 col-12 d-none d-sm-block" style={{ overflow: 'auto', background: 'white' }}>
                                {/* <div className="col-xl-3 col-lg-4 col-12 d-none d-sm-block" style={{ overflow: 'auto', height: '100vh', background: 'white' }}> */}
                                {/* <div className="col-xl-3 col-lg-4 col-12" style={{ overflow: 'auto', height: heightScreen, background: 'red'}}> */}
                                {
                                    notificacaoStore.notificacoes &&
                                        notificacaoStore.notificacoes.itens &&
                                        notificacaoStore.notificacoes.itens.length > 0 ?
                                        <div className="" style={{ height: '100vh' }}>
                                            <div className='infos mt-3'>
                                                <h6 className="ml-4 notificacoes-titulo">Notificações </h6>
                                                <Notificacao />
                                            </div>
                                        </div> : <span>Nenhuma notificação</span>
                                }
                            </div>
                        </div>
                        {/* </div> */}


                    </section>
                </article>
        )
    }
}

export default Dashboard