import React, { Component } from "react"

export class AtomicImage extends Component {
    constructor(props) {
        super(props)
        this.state = { dimensions: {} }
        this.onImgLoad = this.onImgLoad.bind(this)
    }
    onImgLoad({ target: img }) {
        this.setState({
            dimensions: { height: img.offsetHeight, width: img.offsetWidth }
        })
    }
    render() {
        const { src } = this.props
        const { height } = this.state.dimensions
        return (
            <div className="thumbnail">
                <img className={height > 200 ? "portrait" : "" } onLoad={this.onImgLoad} src={src} alt=""/>
            </div>
        )
    }
}

export default AtomicImage
