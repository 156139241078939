import React, { Component } from 'react';
import Loading from '../../../componentes/loading';
import Grafico from '../graficos/graficoAcoesInfluenciaPorResultado'
import { get } from "../../../services/http"
import moment from 'moment-timezone';
import { inject, observer } from 'mobx-react'
// import "../../../../src/design/framework/_relatorio.scss"

moment.tz.setDefault('UTC');

@inject('authStore')
@observer


class AcoesInfluenciaPorResultado extends Component {
    state = {
        loading: true,
        dataInicio: '',
        dataFim: '',
        dados: [],
        paginaAtuaAtivo: 1,
        limiteAtivo: 3,
        quantidadeAtivo: 0,
        paginaAtuaInativo: 1,
        limiteInativo: 3,
        quantidadeInativo: 0,
        totalQuantidadeExito: 0,
        totalQuantidadeSemExito: 0,
    }


    async componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        await this.getDadosAcoesPorResultado()
    }

    async getDadosAcoesPorResultado() {
        await get(`/relatorio/proposicoes/acoes-influencia/${this.props.match.params.dtIni}/${this.props.match.params.dtFim}`)
            .then(response => {
                if (response !== undefined) {
                    this.setState({ dados: response, loading: false })
                }
            })
            .catch(err => console.error(err))
    }

    imprimir() {
        window.print()
    }

    render() {
        
        const dataDaPagina = `Período de Criação: ${moment(this.props.match.params.dtIni).format('DD/MM/YYYY')} até ${moment(this.props.match.params.dtFim).format('DD/MM/YYYY')}`

        // const dominio = window.localStorage.getItem('dominio')
        // var logo = null
        // switch (dominio){
        //     case 'Client':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'blueClient':
        //         logo = 'icon-purpleClient corblueClient'
        //         break

        //     case 'darkBlueClient':
        //         logo = 'icon-logo_darkBlueClient cordarkBlueClient'
        //         break

        //     case 'dev':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'localhost':
        //         logo = `icon-purpleClient cordarkBlueClient`
        //         break

        //     default:
        //         logo = 'icon-logo-Client corClient'
        // }
        
        return (
            this.state.loading ? <Loading /> :
                <div className="container paddingGeralXS">

                    <div id="non-printable" className="mt-5">
                        <button className="btn btn-danger" onClick={this.imprimir}>
                            <img
                                src={require('../../../design/assets/images/impressora.svg')}
                                alt="anexo"
                            />
                        </button>
                    </div>

                    <div className="pt-5">

                        <div className="row">
                            <div className="col-1" >
                                <a href="/">
                                <div className=" logoMenuRelatorios text-center"></div>
                                    {/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
                                </a>
                            </div>
                            <div className="col-11">
                                <div>
                                    <p className="tituloRelatorio">
                                        Relatório
                                        </p>
                                </div>
                                <div>
                                    <p className="descricaoTituloRelatorio">
                                        Quantidade de ações de influência realizadas por periodo a partir do resultado
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 text-center periodoFiltro">
                                <div className="">
                                    {dataDaPagina}
                                </div>
                            </div>
                        </div>


                        {/* GRAFICO  */}

                        <div className="row">

                            <div className="col-md-6 divGrafico" id="align-grafico">
                                <Grafico
                                    dados={this.state.dados}
                                />
                            </div>
                            <div className="col-md-6 align-self-center divIndice" id="divAlignIndice">

                                <div className="row">
                                    <div className="corIndiceAtivo-A"></div>
                                    <div>
                                        <p className="textoIndiceAtivo-A">
                                            Êxito
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceAtivo-A">
                                            {
                                                this.state.dados.exitoGrafico !== undefined ?
                                                    this.state.dados.exitoGrafico :
                                                    0
                                            }
                                        </p>
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="corIndiceInativo-A"></div>
                                    <div>
                                        <p className="textoIndiceInativo-A">
                                            Sem exito
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceInativo-A">
                                            {
                                                this.state.dados.semExitoGrafico !== undefined ?
                                                    this.state.dados.semExitoGrafico :
                                                    0
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="corIndiceNaoClassificado"></div>
                                    <div>
                                        <p className="textoIndiceAtivo-A">
                                            Êxito Parcial
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceAtivo-A">
                                            {
                                                this.state.dados.parcialGrafico !== undefined ?
                                                    this.state.dados.parcialGrafico :
                                                    0
                                            }
                                        </p>
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="corIndiceNeutro"></div>
                                    <div>
                                        <p className="textoIndiceInativo-A">
                                            Pendente
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceInativo-A">
                                            {
                                                this.state.dados.pendenteGrafico !== undefined ?
                                                    this.state.dados.pendenteGrafico :
                                                    0
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* GRAFICO ATIVO */}


                        <div className="mt-5">
                            <p className="tituloTabelaAtivo-A">
                                Êxito
                            </p>
                        </div>
                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleAtivo-A">
                                <tr>
                                    <th className="headTipoAtivo-A">Tipo de Proposição</th>
                                    <th className="headQuantAtivo-A">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dados.arrayExito !== undefined && Array.isArray(this.state.dados.arrayExito) && this.state.dados.arrayExito.length > 0 ?
                                    this.state.dados.arrayExito.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="dadosColunaTipoAtivo-A">{item[0]}</td>
                                                <td className="dadosColunaQuantAtivo-A">{item[1]}</td>
                                            </tr>
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        <div className="mt-5">
                            <p className="tituloTabelaInativo-A">
                                Sem êxito
                            </p>
                        </div>
                        <table className="table table-striped ">
                            <thead className="theadStyleInativo-A">
                                <tr>
                                    <th className="headTipoInativo-A">Tipo de Proposição</th>
                                    <th className="headQuantInativo-A">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dados.arraySemExito !== undefined && Array.isArray(this.state.dados.arraySemExito) && this.state.dados.arraySemExito.length > 0 ?
                                    this.state.dados.arraySemExito.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="dadosColunaTipoInativo-A">{item[0]}</td>
                                                <td className="dadosColunaQuantInativo-A">{item[1]}</td>
                                            </tr>
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        <div className="mt-5">
                            <p className="tituloTabelaInativo-A">
                                Êxito Parcial
                            </p>
                        </div>
                        <table className="table table-striped ">
                            <thead className="theadStyleInativo-A">
                                <tr>
                                    <th className="headTipoInativo-A">Tipo de Proposição</th>
                                    <th className="headQuantInativo-A">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dados.arrayParcial !== undefined && Array.isArray(this.state.dados.arrayParcial) && this.state.dados.arrayParcial.length > 0 ?
                                    this.state.dados.arrayParcial.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="dadosColunaTipoInativo-A">{item[0]}</td>
                                                <td className="dadosColunaQuantInativo-A">{item[1]}</td>
                                            </tr>
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        <div className="mt-5">
                            <p className="tituloTabelaInativo-A">
                                Pendente
                            </p>
                        </div>
                        <table className="table table-striped ">
                            <thead className="theadStyleInativo-A">
                                <tr>
                                    <th className="headTipoInativo-A">Tipo de Proposição</th>
                                    <th className="headQuantInativo-A">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dados.arrayPendente !== undefined && Array.isArray(this.state.dados.arrayPendente) && this.state.dados.arrayPendente.length > 0 ?
                                    this.state.dados.arrayPendente.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="dadosColunaTipoInativo-A">{item[0]}</td>
                                                <td className="dadosColunaQuantInativo-A">{item[1]}</td>
                                            </tr>
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>
                    </div>
                </div>
        )
    }
}

export default AcoesInfluenciaPorResultado