import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class GraficoParlaMaisApreProj extends Component {

    render() {
        const larguraTela = window.innerWidth
        const larguraGrafico = larguraTela > 375 ? '330' : '260'
        const series = []
        const labels = []
        let options= {
            colors: ['#4CD964','#F63854','#5856D7','#FFD200','#007AFF'],
            legend: {
                show: false,
            }
        }
        if(
            this.props.dados !== undefined &&
            Array.isArray(this.props.dados) &&
            this.props.dados.length > 0
          )
        {
            this.props.dados.map( (dado,index) => {
                if (index >4){
                    return series  
                }
                labels.push(dado.nome)
            return series.push(parseInt(dado.qtd))
            })
        }
        options.labels=labels
        return (
            <div className="donut">
                <Chart 
                    options={options} 
                    series={series} 
                    type="donut" 
                    width={larguraGrafico}
                />
            </div>
        );
    }
}

export default GraficoParlaMaisApreProj;
