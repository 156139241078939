import React, { useContext } from "react"
import { MidiaNewsContext } from "./index"
import Query from "../../componentes/hoc/query"
import Loading from "../../componentes/loading"
import isList from "../../componentes/hoc/isList"
import AtomicImage from "./atomicImage"
// import { dataHora } from "../../helpers/date"
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

const subString = text => text && `${text.substring(0, 70)} ...`

const findInportante = ({ importantes, url }) =>
    importantes.find(item => item.url === url)

const media = ({
    source: { name },
    author,
    title,
    description,
    url,
    urlToImage,
    publishedAt,
    content
}) => {
    //< ************* Descomentar quando implementar os metodos marcar e desmarcar *************   >
    //const { importantes, marcar, desmarcar } = useContext(MidiaNewsContext)

    const { importantes } = useContext(MidiaNewsContext)
    
    return (
        <div className="col-md-6" >
            <div className="row no-gutters border rounded overflow-hidden flex-md-row mb-3 shadow-sm h-md-250 position-relative" style={{ backgroundColor: "white"}}> 
                <div className="col p-4 d-flex flex-column position-static">
                    <strong className="d-inline-block mb-2 text-muted float-left">
                        {/* {name} {dataHora(publishedAt)} */}
                        {name} {moment(publishedAt).format('DD/MM/YYYY [às] HH:mm')}
                        {findInportante({ importantes, url }) ? (
                            // <a
                            //     href="/"
                            //     onClick={async event => {
                            //         event.preventDefault()
                            //         let { id } = findInportante({
                            //             importantes,
                            //             url
                            //         })
                            //         await desmarcar({ id })
                            //     }}
                            // >
                            <span className="text-danger ml-3 ">Desmarcar</span> 
                            // </a>
                        ) : (
                            // <a
                            //     href="/"
                            //     onClick={async event => {
                            //         debugger
                            //         event.preventDefault()
                            //         await marcar({ url })
                            //     }}
                            // >
                            <span className="text-danger ml-3">Marcar</span>    
                            // </a>
                        )}
                        <p></p>
                    </strong>
                    <h3 className="h5 mb-2">{title}</h3>
                    <p className="fs-12 mb-2">{subString(description)}</p>

                    <a className="text-danger" rel="noopener noreferrer" href={url} target="_blank">
                        Continue lendo...
                    </a>
                </div>
                <div className="col-auto d-none d-lg-block p-4">
                    <AtomicImage src={urlToImage} alt={title} />
                </div>
            </div>
        </div>
    )
}

const newspaper = ({ url }) => (
    <Query query={`midia/search/${url}`}>
        
        {({ fetching, data, error }) => {
            if (fetching) return <Loading />
            if (error) return <div>Não foi possível carregar dados.</div>
            if (!data) return <div>Não há informações para essa data.</div>
            return (
                <div className="row">
                    {isList({ dados: data.articles })(media)}
                </div>
            )
        }}
    </Query>
)

export { newspaper as Newspaper, media as Media }
