import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Row } from '../../estruturas/bootstrap'
import FormEnhance from '../../componentes/forms/FormEnhance'
import Schema from '../../componentes/forms/validadores/criarNovaSenha'
import SimpleInput from '../../componentes/forms/inputs/simpleInput'
import Loading from '../../componentes/loading'

@inject('authStore')
@observer
class CriarNovaSenha extends Component {
    render() {
        return this.props.authStore.usuarioCognito ? <Fragment>
            <h1 className="h4 text-inherit">Crie uma nova senha para continuar</h1>
            <form onSubmit={this.props.onSubmit(this.props.authStore.criarNovaSenha)}>
                <Row>
                    <SimpleInput
                        cols="12"
                        label="Nova senha"
                        name="password"
                        type="password"
                        icon="icon-download-small"
                        position="left"
                        onChange={password => this.props.onChange('password', password)}
                        onBlur={this.props.validate}
                        error={this.props.handleError("password")} />
                </Row>
                <button
                    className="btn btn-primary btn-block mb-3"
                    type="submit"
                    disabled={!this.props.valid}>Continuar</button>
            </form>
        </Fragment> : <Loading />
    }
}

export default FormEnhance(CriarNovaSenha, Schema)