import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                labels: ['Convergente', 'Divergente', 'Não Classificado', 'Neutro'],
                colors: ['#4CD964','#F63854', '#5856D7', '#D8D8D8' ],
                legend: {
                    show: false,

                }

            },
            // series: [
            //     0, 1, 2, 3, 4
            // ],
        }
    }


    render() {
        

        const series1 = [
            this.props.ComissoesConvergente     !== undefined ? parseInt(this.props.ComissoesConvergente) : 0,
            this.props.ComissoesDivergente      !== undefined ? parseInt(this.props.ComissoesDivergente) : 0,
            this.props.ComissoesNaoClassificado !== undefined ? parseInt(this.props.ComissoesNaoClassificado) : 0,
            this.props.ComissoesNeutro          !== undefined ? parseInt(this.props.ComissoesNeutro) : 0,
        ]
       
        const series2 = [0,0,0,0]
        const seriesProps = this.props.ComissoesConvergente !== 0 || this.props.ComissoesDivergente !== 0 || this.props.ComissoesNaoClassificado !== 0 || this.props.ComissoesNeutro !== 0  ? series1 : series2
        //console.log ('====>>>',seriesProps)
      
        return (
            <div className="donut divGraficoBuilding">
                <Chart
                    options={this.state.options}
                    series={seriesProps}
                    type="donut"
                // width={larguraGrafico}
                />
            </div>
        );
    }
}

export default Donut;
