import React, { Component } from 'react';
import Loading from '../../../componentes/loading';
import Grafico from '../graficos/graficoParlamentarPorQualificacao'
import moment from 'moment-timezone';
// import Pagination from "react-js-pagination";
import { get } from "../../../services/http"
import { inject, observer } from 'mobx-react'

moment.tz.setDefault('UTC');

@inject('authStore')
@observer

class ParlamentarPorQualificacao extends Component {
    state = {
        loading: true,
        dataInicio: '',

        tiposQuantidade: [],

        resultadoRelatorioConvergente: [],
        resultadoRelatorioDivergente: [],
        resultadoRelatorioNaoClassificado: [],
        resultadoRelatorioNeutro: [],

        paginaAtualConvergente: 1,
        limiteConvergente: 3,
        quantidadeConvergente: 0,

        paginaAtualDivergente: 1,
        limiteDivergente: 3,
        quantidadeDivergente: 0,

        paginaAtualNaoClassificado: 1,
        limiteNaoClassificado: 3,
        quantidadeNaoClassificado: 0,

        paginaAtualNeutro: 1,
        limiteNeutro: 3,
        quantidadeNeutro: 0,

        totalQuantidadeConvergente: 0,
        totalQuantidadeDivergente: 0,
        totalQuantidadeNaoClassificado: 0,
        totalQuantidadeNeutro: 0,

        dataIni: '',
        dataFim: '',

        convergenteData: [],
        convergenteTotal: 0,

        divergenteData: [],
        divergenteTotal: 0,

        naoClassificadoData: [],
        naoClassificadoTotal: 0,

        neutroData: [],
        neutroTotal: 0,



    }


    async componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        await this.getDataRelatorio(1)

        this.setState({
            dataInicio: moment(this.props.match.params.dtIni).format('DD/MM/YYYY'),
            dataFim: moment(this.props.match.params.dtFim).format('DD/MM/YYYY'),
        })

    }


    async getDataRelatorio(page) {
        const dtIni = this.props.match.params.dtIni
        const dtFim = this.props.match.params.dtFim
        await get(`/relatorio/qualificacao/${dtIni}/${dtFim}`)
            .then(response => {
                // console.log(response)
                const dados = response || []
                this.setState({
                    loading: false,
                    convergenteData: dados.Convergentes,
                    convergenteTotal: dados.qualificacaoConvergente,
                    divergenteData: dados.Divergentes,
                    divergenteTotal: dados.qualificacaoeDivergente,
                    naoClassificadoData: dados.naoClassificados,
                    naoClassificadoTotal: dados.qualificacaoNaoClassificado,
                    neutroData: dados.Neutros,
                    neutroTotal: dados.qualificacaoNeutro,
                })
            })
    }

    imprimir() {
        window.print()
    }


    render() {

        // const dominio = window.localStorage.getItem('dominio')
        // var logo = null
        // switch (dominio) {
        //     case 'Client':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'blueClient':
        //         logo = 'icon-purpleClient corblueClient'
        //         break

        //     case 'darkBlueClient':
        //         logo = 'icon-logo_darkBlueClient cordarkBlueClient'
        //         break

        //     case 'dev':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'localhost':
        //         logo = `icon-purpleClient cordarkBlueClient`
        //         break

        //     default:
        //         logo = 'icon-logo-Client corClient'
        // }

        // console.log(this.state.convergenteData)
        // console.log(  this.state.convergenteTotal)

        const dados = [
            this.state.convergenteTotal,
            this.state.divergenteTotal,
            this.state.naoClassificadoTotal,
            this.state.neutroTotal,
        ]

        const dataDaPagina = `Período de Criação: ${this.state.dataInicio} a ${this.state.dataFim}`

        return (
            this.state.loading ? <Loading /> :
                <div className="container paddingGeralXS">

                    <div id="non-printable" className="mt-5">
                        <button className="btn btn-danger" onClick={this.imprimir}>
                            <img
                                src={require('../../../design/assets/images/impressora.svg')}
                                alt="anexo"
                            />
                        </button>
                    </div>

                    <div className="pt-5">

                        <div className="row">
                        <div className="col-1" >
                        <a href="/">
                                <div className=" logoMenuRelatorios text-center"></div>
                                    {/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
                                </a>
                            </div>
                            <div className="col-11">
                                <div>
                                    <p className="tituloRelatorio">
                                        Relatório
                                        </p>
                                </div>
                                <div>
                                    <p className="descricaoTituloRelatorio">
                                        Quantidade de Parlamentares distribuídos pelo critério de Qualificação
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 text-center periodoFiltro">
                                <div className="">
                                    {dataDaPagina}
                                    {/* Parlamentares em Exercício */}
                                </div>
                            </div>
                        </div>


                        {/* GRAFICO  */}

                        <div className="row">

                            <div className="col-md-6 divGrafico" id="align-grafico">
                                <Grafico
                                    dados={dados}
                                />
                            </div>
                            <div className="col-md-6 align-self-center divIndice" id="divAlignIndice">

                                <div className="row">

                                    <div className="corIndiceConvergente"></div>
                                    <div>
                                        <p className="textoIndiceConvergente">
                                            Convergente
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceConvergente">
                                            {this.state.convergenteTotal}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceDivergente"></div>
                                    <div>
                                        <p className="textoIndiceDivergente">
                                            Divergente
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceDivergente">
                                            {this.state.divergenteTotal}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceNaoClassificado"></div>
                                    <div>
                                        <p className="textoIndiceNaoClassificado">
                                            Não Classificado
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceNaoClassificado">
                                            {this.state.naoClassificadoTotal}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceNeutro"></div>
                                    <div>
                                        <p className="textoIndiceNeutro">
                                            Neutro
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceNeutro">
                                            {this.state.neutroTotal}
                                        </p>
                                    </div>

                                </div>
                            </div>

                        </div>


                        {/* GRAFICO CONVERGENTE */}


                        <div className="mt-5">
                            <p className="tituloTabelaConvergente">
                                Convergente
                            </p>
                        </div>
                        <div className="scroll-tabela ">
                            <table className="table table-striped">
                                {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                                <thead className="theadStyleConvergente">
                                    <tr>
                                        <th className="headParlamentarConvergente">Parlamentar</th>
                                        <th className="headPartidoConvergente">Partido</th>
                                        <th className="headEstadoConvergente">Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.convergenteData !== undefined && Array.isArray(this.state.convergenteData) && this.state.convergenteData.length > 0 ?
                                        this.state.convergenteData.map((item, index) => {

                                            return (
                                                < tr key={index}>
                                                    <td className="dadosColunaTipoConvergente">{item.nome}</td>
                                                    <td className="dadosColunaQuantConvergente">{item.siglaPartido}</td>
                                                    <td className="dadosColunaQuantConvergente">{item.ufExercicio}</td>
                                                </tr>
                                            )
                                        })
                                        : null}
                                </tbody>
                            </table>
                        </div>


                        {/* <Pagination
                            activePage={this.state.paginaAtualConvergente}
                            itemsCountPerPage={this.state.limiteConvergente}
                            totalItemsCount={parseInt(this.state.quantidadeConvergente)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaConvergente}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}


                        {/* GRAFICO DIVERGENTE */}


                        <div className="mt-5">
                            <p className="tituloTabelaDivergente">
                                Divergente
                            </p>
                        </div>
                        <div className="scroll-tabela">
                            <table className="table table-striped">
                                {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                                <thead className="theadStyleDivergente">
                                    <tr>
                                        <th className="headParlamentarDivergente">Parlamentar</th>
                                        <th className="headPartidoDivergente">Partido</th>
                                        <th className="headEstadoDivergente">Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.divergenteData !== undefined && Array.isArray(this.state.divergenteData) && this.state.divergenteData.length > 0 ?
                                        this.state.divergenteData.map((item, index) => {
                                            // console.log(item)
                                            return (
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoDivergente">{item.nome}</td>
                                                    <td className="dadosColunaQuantDivergente">{item.siglaPartido}</td>
                                                    <td className="dadosColunaQuantDivergente">{item.ufExercicio}</td>
                                                </tr>
                                            )
                                        })
                                        : null}
                                </tbody>
                            </table>
                        </div>


                        {/* <Pagination
                            activePage={this.state.paginaAtualDivergente}
                            itemsCountPerPage={this.state.limiteDivergente}
                            totalItemsCount={parseInt(this.state.quantidadeDivergente)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaDivergente}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}


                        {/* GRAFICO NAO CLASSIFICADO */}


                        <div className="mt-5">
                            <p className="tituloTabelaNaoClassificado">
                                Não Classificado
                            </p>
                        </div>
                        <div className="scroll-tabela">
                            <table className="table table-striped ">
                                {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                                <thead className="theadStyleDivergente">
                                    <tr>
                                        <th className="headParlamentarNaoClassificado">Parlamentar</th>
                                        <th className="headPartidoNaoClassificado">Partido</th>
                                        <th className="headEstadoNaoClassificado">Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.naoClassificadoData !== undefined && Array.isArray(this.state.naoClassificadoData) && this.state.naoClassificadoData.length > 0 ?
                                        this.state.naoClassificadoData.map((item, index) => {

                                            // console.log(item)
                                            return (
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoDivergente">{item.nome}</td>
                                                    <td className="dadosColunaQuantDivergente">{item.siglaPartido}</td>
                                                    <td className="dadosColunaQuantDivergente">{item.ufExercicio}</td>
                                                </tr>
                                            )
                                        })
                                        : null}
                                </tbody>
                            </table>
                        </div>

                        {/* <Pagination
                            activePage={this.state.paginaAtualNaoClassificado}
                            itemsCountPerPage={this.state.limiteNaoClassificado}
                            totalItemsCount={parseInt(this.state.quantidadeNaoClassificado)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaNaoClassificado}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}



                        {/* GRAFICO NEUTRO */}


                        <div className="mt-5">
                            <p className="tituloTabelaNeutro-A">
                                Neutro
                            </p>
                        </div>
                        <div className="scroll-tabela">
                            <table className="table table-striped ">
                                {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                                <thead className="theadStyleNeutro-A">
                                    <tr>
                                        <th className="headParlamentarNeutro">Parlamentar</th>
                                        <th className="headPartidoNeutro">Partido</th>
                                        <th className="headEstadoNeutro">Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.neutroData !== undefined && Array.isArray(this.state.neutroData) && this.state.neutroData.length > 0 ?
                                        this.state.neutroData.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoNeutro-A">{item.nome}</td>
                                                    <td className="dadosColunaQuantNeutro-A">{item.siglaPartido}</td>
                                                    <td className="dadosColunaQuantNeutro-A">{item.ufExercicio}</td>
                                                </tr>
                                            )
                                        })
                                        : null}
                                </tbody>
                            </table>
                        </div>

                        {/* <Pagination
                            activePage={this.state.paginaAtualNeutro}
                            itemsCountPerPage={this.state.limiteNeutro}
                            totalItemsCount={parseInt(this.state.quantidadeNeutro)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaNeutro}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}

                    </div>

                </div >

        )
    }
}

export default ParlamentarPorQualificacao