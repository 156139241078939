import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import DatePicker from 'react-datepicker'
import FormatInput from '../../../componentes/forms/inputs/formatInput'
import SearchUserInputOffline from '../../../componentes/forms/basic/searchUserInputOffline'

// import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
// import { date } from 'joi';
import { post, put } from '../../../services/http'
// import { propStyle } from 'aws-amplify-react';
import moment from 'moment-timezone';
import Loading from '../../../componentes/loading'
import Anexos from '../components/anexos'

moment.tz.setDefault('UTC');

export default function FormDialog(props) {

    const [open, setOpen] = React.useState(false);
    const [responsavel, setResponsavel] = React.useState(props.create ? props.usuarioLogado : props.idResponsavel)
    const [prazo, setPrazo] = React.useState(props.create ? moment(new Date()).format('YYYY-MM-DD') : moment(props.prazo).format('YYYY-MM-DD'))
    const [descricao, setDescricao] = React.useState(props.create ? '' : props.descricaoAtualizar)
    const [esforco, setEsforco] = React.useState(props.create ? '' : props.responsaveisEsforco)
    const [loading, setLoading] = React.useState(false);


    useEffect(() => {

    }, []);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.onClick()
    }

    const handleResponsavel = (id_usuario) => {
        setResponsavel(id_usuario)
    }

    const handleDescricao = (descricao) => {
        setDescricao(descricao)
    }

    const handlePrazo = (data) => {
        setPrazo(data)
    }

    const handleEsforco = (esforco) => {
        setEsforco(esforco)
    }


    const save = async () => {
        setLoading(true)
        if (props.create === true) {
            const data = {
                descricao: descricao,
                id_proposicao: props.idProposicaoCasaExterna,
                id_proposicao_novo: props.idNovo,
                id_responsavel: responsavel,
                prazo: prazo,
                prioridade: 0,
                proposicao: props.descricao,
                responsaveis_esforco: esforco || [],
                uriInteiroTeor: props.interiorTeor || '',
            }
            await post(`/acao-preparatoria`, data)
                .then(resp => {
                    props.mensagens()
                    props.onClick()
                    setLoading(false)
                    handleClose(false)
                })
                .catch(err => console.error(err))


        } else {
            const data = {
                descricao: descricao,
                id_proposicao: parseInt(props.idProposicaoCasaExterna),
                id_responsavel: responsavel,
                prazo: prazo,
                prioridade: 0,
                proposicao: props.descricao,
                responsaveis_esforco: esforco || [],
                uriInteiroTeor: props.interiorTeor || ''
            }

            await put(`/acao-preparatoria/atualizar/${props.idAcaoPrepatoria}`, data)
                .then(res => {
                    props.mensagens()
                    props.onClick()
                    setLoading(false)
                    handleClose(false)
                })
                .catch(err => console.error(err))
        }
    }

    const tamanhoTela = window.innerWidth

    //console.log('props.modal',props)
    return (
        <div>
            {props.modal <= 0 ?
                <>
                    <h5 className="mt-4 mb-0 text-center Client-titulo">Ainda não há uma ação de preparatória criadas para esta proposição</h5>
                    <div onClick={handleClickOpen} className="Client-btn btn-primario centralizer mt-4 btn-acao-criar" style={{ borderRadius: "7px" }}  >
                        CRIAR AÇÃO PREPARATÓRIA
                    </div>
                </>
                :
                null
            }

            {props.create === true && props.modal > 0 ?
                <div className="d-flex align-items-center justify-content-between p-4" >
                    <div style={{ fontSize: '1.2rem' }} >
                        <span className='Client-span-criar-acoes-preparatoria mr-1'>{props.modal}</span>
                        Ações preparatórias
                    </div>
                    <div onClick={handleClickOpen} className="d-flex  align-items-center">
                        <div className='Client-texto mr-2 fs-18'>+</div>
                        <div className="Client-link">Ações preparatórias</div>
                    </div>
                </div>
                : null
            }

            {props.create === true ? <div></div> :
                <div onClick={handleClickOpen}>
                    <img src={require('../../../design/assets/images/editar-gray.svg')} alt="" />
                </div>
            }

            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title" >


                <DialogContent
                    style={{
                        width: tamanhoTela > 600 ? 600 : tamanhoTela,
                        backgroundColor: "white",
                        color: '#3E4049'
                    }}
                >

                    <h5>Ação preparatória</h5>

                    {loading ? <div style={{ marginTop: '500px' }}><Loading /></div> :
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <label className="Client-label">Prazo</label>
                                <div>
                                    <input type="date" className="inputAcaoInfluencia"
                                        onChange={(e) => handlePrazo(e.target.value)}
                                        defaultValue={prazo}
                                    />
                                    <p className="border-bottom-input mt-2"></p>
                                </div>
                            </div>

                            <div className="col-12 col-sm-6">
                                <label className="Client-label">Responsável por posicionamento</label>
                                <FormatInput
                                // readOnly={this.state.readOnly || false}
                                // label="Responsável por posicionamento"
                                // error={this.props.handleError("id_responsavel", "Responsável por Posicionamento")}
                                >
                                    <SearchUserInputOffline
                                        id="acao-preparatoria-form-responsavel"
                                        name="id_responsavel"
                                        lista={props.usuarios}
                                        value={responsavel}
                                        placeholder="user@purpleClient.com.br"
                                        onChange={id_usuario => handleResponsavel(id_usuario)} />
                                    <p className="border-bottom-input"></p>
                                </FormatInput>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                <label className="Client-label">Solicitar Esforço Colaborativo de</label>
                                <FormatInput
                                // readOnly={this.state.readOnly || false}
                                // label="Solicitar Esforço Colaborativo de"
                                // error={this.props.handleError("responsaveis_esforco", "Solicitar Esforço Colaborativo de")}
                                >
                                    <SearchUserInputOffline
                                        id="acao-preparatoria-form-solicitar-esforco"
                                        idAcaoPreparatoriaSearch={props.idAcaoPrepatoria}
                                        ResponsavelEsforco={props.responsaveisEsforco}
                                        name="esforco"
                                        lista={props.usuarioDepartamento}
                                        filtro="nome"
                                        value={props.responsaveisEsforco}
                                        multi={true}
                                        onChange={responsaveis_esforco => handleEsforco(responsaveis_esforco)} />
                                    <p className="border-bottom-input"></p>
                                </FormatInput>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-12 col-xl-12">
                                <label className="Client-label">Objetivo</label>
                                <textarea
                                    id="acao-preparatoria-objetivo"
                                    required="required"
                                    // readOnly={this.state.readOnly || false}
                                    name="descricao"
                                    rows="3"
                                    maxLength="4000"
                                    defaultValue={descricao}
                                    className={`form-control form-control-lg`}
                                    placeholder="Digite aqui"
                                    autoComplete="off"
                                    // onChange={event => {
                                    //   this.props.onChange("descricao", event.target.value)
                                    //   this.contadorCaracter(event)
                                    // }}
                                    onChange={event => handleDescricao(event.target.value)}
                                // onMouseLeave={event => this.contadorCaracter(event)}
                                // onBlur={event => this.contadorCaracter(event)}
                                >
                                </textarea>
                            </div>


                            <div className="col-12">
                                {props.create === true ?
                                    <Anexos
                                        idParametro={props.idAcaoPrepatoria}
                                        idProposicaoCasaExterna={props.idProposicaoCasaExterna}
                                        create={props.create}
                                        modulo={'acao-preparatoria'}
                                    />
                                    :
                                    null
                                }
                            </div>

                            <div className="col-12">
                                {props.interiorTeor !== '' ?
                                    <a href={props.interiorTeor}
                                        className="fs-12 Client-link" target="_blank" rel="noopener noreferrer">Inteiro Teor</a>
                                    : null}
                            </div>


                            <div className="col-md-6 text-center borderBotaoModalAcaoInfluencia borderBotaoModalAcaoInfluenciaLeft" onClick={handleClose}>
                                CANCELAR
                            </div>
                            <div className="col-md-6 text-center borderBotaoModalAcaoInfluencia" onClick={save}>
                                SALVAR
                            </div>

                        </div>
                    }
                </DialogContent>

            </Dialog>
        </div>
    );
}

