import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'

// import AgendaPrincipal from '../principal/AgendaPrincipal'
// import AgendaAcoes from '../acoes/AgendaAcoes'
// import AgendaLegislativa from '../legislativa/AgendaLegislativa'
import Agenda from '../agenda.jsx';
import AgendaJornal from '../agenda-jornal.jsx';
import AgendaOperacional from '../agenda-operacional.jsx';
import CadastroEventos from '../cadastroEvento.jsx';
import EditarEventos from '../editarEvento.jsx';

export default () =>
    <Fragment>
        <Switch>
        
            <Route exact path="/agenda/:data" component={Agenda} />
            <Route exact path="/agenda" component={Agenda} />
            <Route exact path="/agenda-jornal/:dataInicial/:dataFinal/:casa" component={AgendaJornal} />
            <Route exact path="/agenda-operacional/:dataInicial/:dataFinal/:casa" component={AgendaOperacional} />
            <Route exact path="/agenda/agenda-cadastro/cadastro" component={CadastroEventos} />
            <Route exact path="/agenda/agenda-cadastro/editar/:id" component={EditarEventos} />
            
            {/* <Route exact path="/agenda" component={AgendaPrincipal} />
            <Route exact path="/agenda/acoes" component={AgendaAcoes} />
            <Route exact path="/agenda/legislativa" component={AgendaLegislativa} /> */}
        </Switch>
    </Fragment>
