const permissao = {
    ADMINISTRADOR: 1,
    PODE_EDITAR: 2,
    PODE_VER: 3,
    ESFORCO_COLABORATIVO: 4,

    1: 'MASTER',
    2: 'NORMAL',
    3: 'READ ONLY',
    4: 'ESFORÇO COLABORATIVO'
}

export default permissao
export const permissoes = [1, 2, 3, 4].map(id => ({ id, nome: permissao[id] }))