import React, { Component } from 'react';
import Loading from '../../../componentes/loading';
import moment from 'moment-timezone';
// import Pagination from "react-js-pagination";
import { get } from "../../../services/http"
import { inject, observer } from 'mobx-react'

moment.tz.setDefault('UTC');

@inject('authStore')
@observer

class ProposicaoNovaPorPeriodo extends Component {
    state = {
        loading: true,
        dataInicio: '',

        tiposQuantidade: [],

        resultadoRelatorioNovos: [],

        paginaAtuaNovos: 1,
        limiteNovos: 3,
        quantidadeNovos: 0,

        dataIni: '',
        dataFim: '',
    }

    async componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        await this.getDataRelatorio(1)

        this.setState({
            dataInicio: moment(this.props.match.params.dtIni).format('DD/MM/YYYY'),
            dataFim: moment(this.props.match.params.dtFim).format('DD/MM/YYYY'),
        })

    }


    async getDataRelatorio(page) {
        const dtIni = this.props.match.params.dtIni
        const dtFim = this.props.match.params.dtFim
        await get(`/relatorio/proposicoes/novas-qtd/${dtIni}/${dtFim}`)
            .then(res => {
                // console.log(res)
                const dados = res || []
                this.setState({
                    tiposQuantidade: dados,
                    loading: false
                })
            })
    }



    imprimir() {
        window.print()
    }


    render() {

        // const dominio = window.localStorage.getItem('dominio')
        // var logo = null
        // switch (dominio) {
        //     case 'Client':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'blueClient':
        //         logo = 'icon-purpleClient corblueClient'
        //         break

        //     case 'darkBlueClient':
        //         logo = 'icon-logo_darkBlueClient cordarkBlueClient'
        //         break

        //     case 'dev':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'localhost':
        //         logo = `icon-purpleClient cordarkBlueClient`
        //         break

        //     default:
        //         logo = 'icon-logo-Client corClient'
        // }

        // const dados = [100, 200, 120, 180, 50]
        const dataDaPagina = `Período de Apresentação: ${this.state.dataInicio} a ${this.state.dataFim}`

        return (
            this.state.loading ? <Loading /> :
                <div className="container paddingGeralXS">

                    <div id="non-printable" className="mt-5">
                        <button className="btn btn-danger" onClick={this.imprimir}>
                            <img
                                src={require('../../../design/assets/images/impressora.svg')}
                                alt="anexo"
                            />
                        </button>
                    </div>

                    <div className="pt-5">

                        <div className="row">
                        <div className="col-1" >
                        <a href="/">
                                <div className=" logoMenuRelatorios text-center"></div>
                                    {/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
                                </a>
                            </div>
                            <div className="col-11">
                                <div>
                                    <p className="tituloRelatorio">
                                        Relatório
                                        </p>
                                </div>
                                <div>
                                    <p className="descricaoTituloRelatorio">
                                        Quantidade de novas proposições inseridas no purpleClient por período
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 text-center periodoFiltro">
                                <div className="">
                                    {dataDaPagina}
                                </div>
                            </div>
                        </div>


                        {/* GRAFICO CONVERGENTE */}

                        <table className="table table-striped">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleNovos">
                                <tr>
                                    <th className="headTipoNovos">Tipo de Proposição</th>
                                    <th className="headQuantNovos">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                    this.state.tiposQuantidade.map((item, index) => {
                                        // console.log(item)

                                        return (
                                            item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoNovos">{item.siglaTipo}</td>
                                                    <td className="dadosColunaQuantNovos">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualNovos}
                            itemsCountPerPage={this.state.limiteNovos}
                            totalItemsCount={parseInt(this.state.quantidadeNovos)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaNovos}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}

                    </div>

                </div>

        )
    }
}

export default ProposicaoNovaPorPeriodo