import React, { Component } from 'react'
import Loading from '../../loading';
import {  get, post, put } from '../../../services/http'

class SelectInfluenciarSobre extends Component {
    state = {
        loading: true,
        influenciarSobre: [],
        selectParlamentar: [],
        abrirSelect: false,
        dadosProposicao: [],
        arrayParlamentares: [],
        pesquisa:''
    }

    async componentWillMount() {
        await this.getInfluenciarSobre()
    }

    async getInfluenciarSobre() {
        //console.log('ENTREI AQUI 2')
        if(this.props.id_acao_influencia !== undefined) {
            await get(`parlamentar/acao-influencia/${this.props.id_acao_influencia}`)
            .then(response => {
              this.setState({ influenciarSobre: response, loading: false, abrirSelect: false })
            })
        } else {
              this.setState({ loading: false })
        }
    }

    removerInfluenciaAntesDeAdd(index) {

        const array = this.state.arrayParlamentares
        array.splice(index, 1)
        this.setState({ arrayParlamentares: array })
        window.localStorage.setItem('influenciaSobre', JSON.stringify(this.state.arrayParlamentares))
    }

    async removerInfluencia(id_parlamentar, id_acao_influencia, casa) {

        this.setState({ loading: true })
        const user = JSON.parse(window.localStorage.getItem('user'))
        const data = {
            id_parlamentar: id_parlamentar,
            id_acao_influencia: id_acao_influencia,
            atualizado_em: new Date(),
            id_cliente: user.idDoCliente,
            casa: casa,
        }
        await put("parlamentar/acao-influencia/delete", data)
        .then(response => {
            if(response === 1) {
                this.getInfluenciarSobre()
            }
        })
        .catch(err => err)
    }

    async pesquisarParlamentar(event) {
        this.setState({ pesquisa: event.target.value })
        if(event.target.value.length > 2) {
            await get(`parlamentar/acao-influencia/select/${event.target.value}`)
            .then(response => {
                this.setState({ selectParlamentar: response, abrirSelect: true, loading: false })
            })
        }
    }

    async parlamentarSelecionado(item) {
      

        const user = JSON.parse(window.localStorage.getItem('user'))
        if(this.props.id_acao_influencia !== undefined) {
            this.setState({ loading: true, pesquisa: '' })
            const data = {
                id_acao_influencia: this.props.id_acao_influencia,
                id_parlamentar: item.id,
                criado_em: new Date(),
                id_cliente: user.idDoCliente
            }
            //console.log('data influencia sobre post',data)
            await post('parlamentar/acao-influencia/add-influencia-sobre', data)
            .then(response => {
                if(response.length === 1) {
                    this.getInfluenciarSobre()
                }
            })
            .catch(err => err)

        } else {
            
            this.setState({ pesquisa: '' })
            const array = this.state.arrayParlamentares
            const data = {
                id_parlamentar: item.id,
                criado_em: new Date(),
                id_cliente: user.idDoCliente,
                nome: item.nome
            }
            array.push(data)
            this.setState({ arrayParlamentares: array, abrirSelect: false })
            window.localStorage.setItem('influenciaSobre', JSON.stringify(this.state.arrayParlamentares))
        }
    }

    render() {
        const dados = this.state.influenciarSobre || []
        const arrayAdd = this.state.arrayParlamentares
        return ( 
            this.state.loading ? <p className="p-2"><Loading /></p> :
            <div>

                {dados !== undefined && Array.isArray(dados) && dados.length > 0 ?
                 dados.map((item, index) => {
                    return (
                        <span key={index} className="lista-campo-influencia-sobre d-block">{item.nome} <span onClick={() => this.removerInfluencia( item.id_parlamentar, item.id_acao_influencia, item.casa )} className="click-excluir-influencia-sobre">X {' '}</span></span>
                        )
                }) : null}

                {arrayAdd && arrayAdd.length > 0 ? arrayAdd.map((item, index) => {
                    return (
                        <span key={index} className="lista-campo-influencia-sobre">{item.nome} <span onClick={() => this.removerInfluenciaAntesDeAdd( index )} className="click-excluir-influencia-sobre">X {' '}</span></span>
                        )
                }) : null}

                <input id="inputSelect" autoComplete="off" className="input-influencia-sobre" type="text" value={this.state.pesquisa} onChange={event => this.pesquisarParlamentar(event)} />

                {this.state.abrirSelect === true &&
                 this.state.selectParlamentar !== undefined && Array.isArray(this.state.selectParlamentar) && this.state.selectParlamentar.length > 0 ?
                 this.state.selectParlamentar.map((item, index) =>
                    <div className="mascara" key={index}>
                        <ul className="list-group">
                            <li className="list-group-item" key={`${index}-pesquisa`} onClick={() => this.parlamentarSelecionado(item, index)}>
                                {
                                    item.label || 
                                    item.nome || 
                                    item.nomeCivil
                                }
                            </li>
                        </ul>
                    </div>
                ) : null }
            </div>
        )

    }
}

export default SelectInfluenciarSobre