import {Component} from 'react'

class ValoresProvider extends Component {
    constructor(props) {
        super(props)
        this.state = props.inicial || {}
    }

    alterar = (prop, valor) => this.setState({[prop]: valor})

    render = () => this.props.children({
        valores: this.state,
        alterar: this.alterar.bind(this)
    })
}

export default ValoresProvider