import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Row, Grid } from '../../../estruturas/bootstrap'
import { get, post, put } from '../../../services/http'
import FormEnhance from '../../../componentes/forms/FormEnhance'
import Schema from '../../../componentes/forms/validadores/acaoParlamentar'
import FormatInput from '../../../componentes/forms/inputs/formatInput'
import UploadFile from '../../../componentes/forms/inputs/upload'
import SearchUserInput from '../../../componentes/forms/basic/searchUserInput'
import { ambitos } from '../../../helpers/casa';
import { resultado } from '../../../enums/resultadoDaAcao';
import DropdownSelect from '../../../componentes/react-dropdown-select'

@withRouter
@inject('uploadStore', 'authStore', 'modalStore', 'alertaStore')
@observer
class FormAcaoParlamentar extends Component {
    state = { arquivo: [] }

    componentWillMount = async () => {
        this.props.onAppendState({
            proposicao: this.props.nomeParlamentar,
            idResponsavel: +this.props.authStore.id || 0,
            responsavel: this.props.authStore.nome || '0',
            influenciaSobre: [+this.props.idParlamentar],
            idProposicao: 0,
            descricao: '',
            prazo: new Date(),
            tipo: 0
        })

        if (!this.props.match.params.acao) return;

        const acaoParlamentar = await get(`parlamentar/${this.props.idParlamentar}/acao-influencia/${this.props.match.params.acao}`)
        await this.props.onAppendState({
            id: acaoParlamentar.id,
            idResponsavel: acaoParlamentar.idResponsavel,
            responsavel: acaoParlamentar.responsavel,
            influenciaSobre: JSON.parse(acaoParlamentar.influenciaSobre),
            idProposicao: acaoParlamentar.idProposicao,
            descricao: acaoParlamentar.descricao,
            proposicao: this.props.nomeParlamentar,
            resultado: acaoParlamentar.resultado,
            ambito: acaoParlamentar.ambito,
            prazo: new Date(acaoParlamentar.prazo),
            tipo: 0
        })
    }

    save = async (data) => {
        try {
            this.setState({ loading: true })
            if (this.props.data.id)
                await put(`/parlamentar/${this.props.idParlamentar}/acao-influencia/${this.props.data.id}`, data)
            else
                await post(`/parlamentar/${this.props.idParlamentar}/acao-influencia`, data)

            this.props.alertaStore.criar(`Ação ${this.props.data.id ? 'alterada' : 'criada'} com sucesso`)
            this.setState({ loading: false })
            this.props.history.push(this.props.goBack);
            this.props.modalStore.fecharModal()
        }
        catch (error) {
            console.error(error)
            this.setState({ loading: false })
        }
    }

    render() {
        return <Fragment>
            <form onSubmit={this.props.onSubmit(this.save)}>
                <div className="modal-body">
                    <Row css="mb-4">
                        <Grid cols="12 12 7">
                            <h6>Prazo</h6>
                            <DatePicker
                                autoComplete="false"
                                selected={this.props.data.prazo}
                                onChange={prazo => this.props.onChange('prazo', prazo)}
                                className="form-control"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="dd/MM/yyyy HH:mm"
                                timeCaption="hora" />
                            <FormatInput error={this.props.handleError("prazo")} />
                        </Grid>

                        <FormatInput
                            cols="12 12 5"
                            label="Usuário">
                            <SearchUserInput
                                name="idResponsavel"
                                url="/usuario?situacao=1"
                                filtro="nome"
                                value={this.props.data.idResponsavel}
                                placeholder="user@purpleClient.com.br"
                                onChange={idResponsavel => this.props.onChange('idResponsavel', idResponsavel)} />
                        </FormatInput>
                    </Row>
                    <Row>
                        <FormatInput
                            label="Descrição"
                            error={this.props.handleError("descricao")}>
                            <textarea
                                label="Descrição"
                                name="descricao"
                                rows="3"
                                value={this.props.data.descricao}
                                className={`form-control form-control-lg`}
                                placeholder="Digite a descrição"
                                autoComplete="off"
                                onChange={event => this.props.onChange('descricao', event.target.value)}>
                            </textarea>
                        </FormatInput>
                    </Row>
                    <Row>
                        <FormatInput
                            cols="6"
                            label="Âmbito"
                            error={this.props.handleError("ambito")}>
                            <DropdownSelect lista={ambitos} value={this.props.data.ambito} onChange={ambito => this.props.onChange('ambito', ambito)} />
                        </FormatInput>
                    </Row>

                    {this.props.data.id ?
                        <Row>
                            <FormatInput
                                label="Resultado"
                                error={this.props.handleError("resultado")}>
                                <DropdownSelect
                                    lista={resultado}
                                    value={parseInt(this.props.data.resultado)}
                                    onChange={resultado => this.props.onChange('resultado', resultado)} />
                            </FormatInput>
                        </Row> : null}


                    <h6>Anexos</h6>
                    <div className="row mb-2">
                        {
                        this.state.arquivo !== undefined && Array.isArray(this.state.arquivo) &&
                         this.state.arquivo.length > 0 ?
                        this.state.arquivo.map((item, index) =>
                            <Fragment key={item.arquivo}>
                                <div className="col-9">
                                    <button type="button" className="btn btn-light btn-block border text-left mr-1 mb-2">
                                        <div className="float-left text-truncate w-75" onClick={() => this.props.uploadStore.downloadArquivo(item)}>
                                            <i className="icon-download-small mr-1" />
                                            <span className="align-middle fs-12">{item.nome}</span>
                                        </div>
                                        <span className="float-right ml-2" onClick={() => {
                                            this.props.uploadStore.removerAnexo(item);
                                            this.setState({ arquivo: this.state.arquivo.filter(anexo => anexo.arquivo !== item.arquivo) });
                                        }}>X</span>
                                    </button>
                                </div>
                                <div className="col-3 fs-12 ">{item.criado_em || 'Enviado agora'}</div>
                            </Fragment>):null}
                    </div>
                    <UploadFile modulo={'parlamentar-influencia'} id_referencia={this.props.idParlamentar} onChange={anexo => this.setState({ arquivo: [...this.state.arquivo, ...anexo] })} />

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => { this.props.history.push(this.props.goBack); this.props.modalStore.fecharModal() }}>Cancelar</button>
                    <button className="btn btn-primary">Salvar</button>
                </div>
            </form>
        </Fragment >
    }
}

export default FormEnhance(FormAcaoParlamentar, Schema)
