import { observable, action } from "mobx"

class ModalStore {
    @observable open = false

    @action
    fecharModal() {
        this.open = false
    }
}

export default  new ModalStore()