import React, { Component, Fragment } from 'react';
import Loading from '../../componentes/loading';
import { NavLink } from 'react-router-dom';
import Pagination from "react-js-pagination";
import CardProposicao from './cardProposicao';
import { post } from "../../services/http";

class ListarProposicoes extends Component {
  state = {
    loading: true,
    proposicoes: [],
    limite: 50,
    quantidade: 0,
    paginaAtual: 1,
  }

  async componentDidMount() {
    // const user = JSON.parse(window.localStorage.getItem('user'))
    // if(user.id !== 449) {
    //   window.location.href = "/"
    // }
    await this.listardarkBlueClient(1)
  }

  async listardarkBlueClient(pagina) {
    const filtrodarkBlueClient =  window.localStorage.getItem('filtrodarkBlueClient') !== undefined && window.localStorage.getItem('filtrodarkBlueClient') !== null ? JSON.parse(window.localStorage.getItem('filtrodarkBlueClient')) : null
    const data = {
      filtro: filtrodarkBlueClient,
      pagina: pagina,
      limite: this.state.limite,
      ultimo: pagina === 1 ? 0 : (pagina - 1) * this.state.limite
    }
    await post('/darkBlueClient/listar', data)
    .then(res => {
      this.setState({ proposicoes: res.proposicoes, quantidade: parseInt(res.quantidade), loading: false })
    })
  }

  carregarPaginaNova = async pagina => {
    this.setState({ paginaAtual: pagina })
    await this.listardarkBlueClient(pagina)
  }

  openFile() {
    document.getElementById('upload').click()
  }

  getFile(event) {
    console.log(event.target.files[0])
    const file = event.target.files[0]
    // const data = {
    //   name: 'darkBlueClient/' + file.name,
    //   lastModified: file.lastModified,
    //   lastModifiedDate: file.lastModifiedDate,
    //   webkitRelativePath: file.webkitRelativePath,
    //   size: file.size,
    //   type: file.type
    // }
    this.uploaddarkBlueClient(file)
  }

  async uploaddarkBlueClient(arquivo) {
    // console.log(arquivo)
    await post(`/anexo`, arquivo)
    .then(r => console.log(r))
  }

  render() {
    // console.log('QUANTIDADE AQUI ===> ',this.state.quantidade)
    return this.state.loading ? <Loading /> :
    
      <Fragment>
          <div className=" bg-white pl-2 marginTopXs" style={{boxShadow: "-3px 0px 10px #C0C0C0"}}>
                        <p style={{fontSize:"10px", color: "var(--linkText)", fontWeight:"bold", padding:"15px" }}>
                        Proposições Estaduais e Municipais
                        </p>
          </div>
        <div className="area-listar-proposicao">
          <div className="container-fluid p-5">
            <div className="row">
              <div className="col-6 margin-top-botoes">
                <div>
                  <NavLink to="/proposicao-estadual-municipal/filtro" className="texto-botao-filtro-proposicao">
                    <button className="btn-block botao-filtro-proposicao">
                      <img
                        className="mr-2"
                        src={require('../../design/assets/images/filtar.png')}
                        alt="anexo"
                      />{' '}
                        Filtrar
                    </button>
                  </NavLink>
                </div>
              </div>
              <div className="col-6 margin-top-botoes">
                <div>
                  <button onClick={this.openFile} className="btn-block botao-importar-proposicao">
                    <input multiple onChange={event => this.getFile(event)} type="file" id="upload" style={{ display: 'none' }}/>
                    <img
                      className="mr-1"
                      src={require('../../design/assets/images/importar.png')}
                      alt="anexo"
                    />{' '}
                    Importar
                  </button>
                </div>
              </div>

              <div className="col-12 mt-3">
                <hr />
              </div>

              <CardProposicao 
                dados={this.state.proposicoes}
              />

              <div className="col-12">
              <Pagination
                activePage={this.state.paginaAtual}
                itemsCountPerPage={this.state.limite}
                totalItemsCount={this.state.quantidade}
                pageRangeDisplayed={10}
                hideFirstLastPages={true}
                onChange={this.carregarPaginaNova}
                prevPageText={<div className="prev-page"><img src={require('../../design/assets/images/prev-page-pagination.svg')} alt="prev-page"/></div>}
                nextPageText={<div className="next-page"><img src={require('../../design/assets/images/next-page-pagination.svg')} alt="next-page"/></div>}
              /> 
              </div>

            </div>
          </div>
        </div>
      </Fragment>
  }

}
export default ListarProposicoes