import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import word from '../helpers/word';

@withRouter
@inject('breadcrumbStore')
@observer
class Breadcrumb extends Component {
    state = { rotas: [] }
    componentWillMount() {
        const { pathname } = this.props.location
        this.setState({ rotas: [...pathname.split("/").slice(1)] })
    }

    render() {
        const { rotas } = this.state || []
        return (
            <nav className={this.props.css}>
                <ol className="breadcrumb mb-1 p-2">
                    <li className="breadcrumb-item">
                        <NavLink to="/"><i className="icon-arrow-straight-back-small align-baseline fs-14"></i></NavLink>
                    </li>
                    {rotas !== undefined && Array.isArray(rotas) && rotas.length > 0 ? 
                        rotas.map((rota, index) =>
                        <li key={`nav-rota-${index}`} className="breadcrumb-item">
                            <NavLink to={`${index ? `/${rotas.slice(0, index).join('/')}` : ''}/${rotas[index]}`} className="text-capitalize">
                                {word[rota] || this.props.breadcrumbStore.obter(rotas[index - 1], rota) || rota}
                            </NavLink>
                        </li>) : null }
                </ol>
            </nav>
        )
    }
}

export default Breadcrumb