import React, { Component } from 'react'
import { Grid } from '../../../estruturas/bootstrap';
import If from '../../helpers/If';

class SimpleInput extends Component {

    intervalo = null
    state = {
        value: this.props.value || ""
    }

    constructor(props) {
        super(props)
        this.aoAlterar = this.aoAlterar.bind(this)
        this.limparIntervalo = this.limparIntervalo.bind(this)
    }

    aoAlterar(event) {
        this.limparIntervalo()

        const { value } = event.target
        this.setState({ value })
        this.props.onChange(value)

        if (this.props.debounce && typeof this.props.onDebounced === 'function')
            this.intervalo = setTimeout(() => this.props.onDebounced(value), 1200)
    }

    componentWillUnmount = () => this.limparIntervalo()

    limparIntervalo = () => this.intervalo ? clearInterval(this.intervalo) : null

    render() {
        let { cols, css, name, label, position, icon, type, error, placeholder, onBlur, onPressEnter = () => { } } = this.props
        return (
            <Grid cols={cols || "12"} css={css}>
                <div className="form-group ">
                    <If test={label} className="text-left">
                        <label htmlFor={name} className="fs-14 font-weight-bold " style={{color: "white"}}>{label}</label>
                    </If>
                    <div className={`input-group ${icon ? `input-inside`: ''} ${position}`} style={{width:"100%"}}>
                        <If test={icon}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className={icon}></i>
                                </span>
                            </div>
                        </If>
                        <input
                            id={name}
                            type={type || 'text'}
                            name={name}
                            value={this.state.value}
                            className={`input-responsivo form-control form-control-lg ${error ? 'is-invalid' : ''}`}
                            placeholder={placeholder}
                            autoComplete="off"
                            onChange={event => this.aoAlterar(event)}
                            onBlur={onBlur}
                            onKeyDown={({ keyCode, target: { value } }) => keyCode === 13 ? onPressEnter(value) : null} />
                    </div>
                    <If test={error}>
                        <small className="form-text text-danger">{error}</small>
                    </If>
                </div>
            </Grid>
        )
    }
}

export default SimpleInput

