import React, { useContext } from "react"
import { MidiaNewsContext } from "./index"
import isList from "../../componentes/hoc/isList"

const item = ({ id, keyword }) => {
    const { remove } = useContext(MidiaNewsContext)
    return (
        <span className="badge badge-dark py-0 mr-2 mb-1">
            <span className="text-uppercase ">{keyword}</span>
            <button
                className="btn btn-link px-0 pl-1 "
                type="button"
                onClick={async () => {
                    await remove({ id, keyword })
                }}
            >
           <span className="text-white">x</span>
            </button>
        </span>
    )
}

const badge = (props) => {
    const { seguidas } = useContext(MidiaNewsContext)
    const flex = props.midiaFlex ? "d-flex" : "d-block"
    return (
        seguidas.length > 0  ? 
           <div className={`mb-3 ${flex}`}>
                <h5 className="d-flex align-items-center txt-dark mr-2">
                    <b>Suas palavras-chave</b> 
                </h5>
                <div className="mt-1">
                    {isList({ dados: seguidas })(item)}
                </div>
            </div>
        
        : 
        <div className="mb-3">
            <h5 className="text-wrap">
                As palavras-chave buscadas aparecerão aqui.
                Você pode adicionar ou remover a qualquer momento!
            </h5>
        </div> 

    )

}

export default badge
