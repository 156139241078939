import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import moment from 'moment-timezone';
import { get, post, put } from "../../../services/http";
import Loading from "../../../componentes/loading";
import ModalCorrelata from "../modal/modalCorrelatas";
import { inject, observer } from "mobx-react";
import ClientSelect from "../../../componentes/forms/basic/clientSelect";
import { posicionamentosClient } from "../../../enums/posicionamento";
import alertaStore from "../../../../src/stores/alertaStore";

moment.tz.setDefault('UTC');

@inject("authStore")
@observer
@withRouter
class Correlatas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
			proposicaoId: null,
			loading: true,
			palavrasConfig: [],
			mensagem: "",
			responseCorrelatas: false,
			analiseImpacto: {},
			dadosProposicao: {},
		};
	}

	async componentDidMount() {
		await this.setState({ proposicaoId: this.props.idProposicao });

		if (this.props.authStore.ehEsforcoColaborativo === true) {
			window.location.href = "/";
		}
		// await this.getNovoIdProposicao()
		await this.obterCorrelatas();
		await this.obterPalavrasConfig();
	}

	async obterCorrelatas() {
		const data = { id: this.props.idProposicao, pagina: 1 };
		await post("proposicao/correlatas", data).then((response) => {
			// console.log("Response:", response)
			this.setState({ items: response, responseCorrelatas: true });
		});
	}

	async obterPalavrasConfig() {
		await get(`/ia/palavras/${this.props.idProposicao}`).then((res) => {
			this.setState({ palavrasConfig: res, loading: false });
		});
	}

	async deletarDespacho(id) {
		this.setState({ loading: true });
		await put(`/proposicao/deletar-correlatas/${id}`)
			.then(async (res) => {
				if (res.length !== undefined) {
					await this.obterCorrelatas();
					this.setState({ loading: false });
					alertaStore.criar("Correleta descartada com sucesso.");
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	async gerarCorrelata() {
		this.setState({ loading: true });
		const data = { id: this.state.proposicaoId };
		await post("/ia/gerar-correlatas", data).then((res) => {
			if (res !== "") {
				this.setState({ mensagem: res, responseCorrelatas: true });
			}
			this.obterCorrelatas(this.state.proposicaoId);
			this.obterPalavrasConfig(this.state.proposicaoId);
		});
	}

	proposicaoLink(id) {
		window.location.href = `/proposicao/${id}/acao-preparatoria`;
	}

	async alterarPosicionamento(novoPosicionamento, id) {
		// console.log("id:", id)
		const data = { posicionamento: novoPosicionamento };
		await put(`/proposicao/correlata/posicionamento/${id}`, data)
			.then((res) => {
				alertaStore.criar("Posicionamento alterado com sucesso.");
			})
			.catch((err) => {
				// console.log(err)
				alertaStore.erro("Erro ao alterar posicionamento");
			});
	}

	render() {
		return this.state.loading ? (
			<Loading />
		) : (
			<Fragment>
				{this.props.authStore.ehVisualizador === true ? null : (
					<div className="row">
						<div className="col-md-6 mt-2 mb-3">
							<ModalCorrelata dados={this.state.palavrasConfig} />
						</div>
						<div className="col-md-6 mt-2 mb-3">
							<button
								className="btn btn-danger btn-block"
								onClick={() => this.gerarCorrelata()}
							>
								Gerar Correlatas
							</button>
						</div>
					</div>
				)}

				{this.state.items !== undefined &&
					Array.isArray(this.state.items) &&
					this.state.items.length > 0 ? (
					this.state.items.map((item, index) => {
						console.log(item.posicionamento);
						// const idCorrelata = item.id_correlata;
						let casa = "";

						switch (item.siglaCasa) {
							case "CN":
								casa = "Congresso Nacional";
								break;
							case "SF":
								casa = "Senado Federal";
								break;
							case "CD":
								casa = "Câmara dos Deputados";
								break;
							default:
								casa = "";
						}

						return (
							<div
								key={index}
								className={`Client-mini-card mb-4`}
								style={{ backgroundColor: "white" }}
							>
								<div
									style={{ cursor: "pointer" }}
									className="header d-flex align-items-start"
									onClick={() =>
										this.proposicaoLink(item.id_correlata)
									}
								>
									<img
										src={require("../../../design/assets/images/joined-small.svg")}
										alt="icone-apensadas"
									/>
									<div className="title ml-4">
										<span className="Client-titulo-card">
											{item.descricao}
										</span>
										<span className="Client-cinza-claro fs-16 font-weight-bold">
											{moment(item.data).format(
												"DD/MM/YYYY"
											)}
										</span>
									</div>
									<div className="font-weight-bold ml-2 mt-1">
										{casa}
									</div>
								</div>

								<p className="text-wrap fs-16 mt-2">
									{item.ementa}
								</p>

								<div style={{ paddingRight: "150px" }}>
									{/* <span className="label-analise-impacto-proposicao">Posicionamento</span> */}

									<ClientSelect
										padding="p-0"
										marginLeft="ml-1"
										id="analise-posicionamento-proposicao"
										items={posicionamentosClient}
										value={
											item.posicionamento > 0
												? item.posicionamento
												: posicionamentosClient
										}
										// label = 'Posicionamento'
										placeholder="Posicionamento"
										onChange={
											this.props.authStore
												.ehVisualizador === true
												? null
												: (novoPosicionamento) =>
													this.alterarPosicionamento(
														novoPosicionamento.id,
														item.id_correlata
													)
										}
										disabled={
											this.props.authStore
												.ehVisualizador === true
												? true
												: false
										}
									/>
								</div>
								<div
									className="botao-descartar-correlatas mt-3"
									onClick={() =>
										this.deletarDespacho(item.id)
									}
								>
									Descartar
								</div>
							</div>
						);
					})
				) : (
					<div className="container-fluid text-center bg-white p-4 mb-4">
						<h4 className="mb-0 Client-titulo">
							{this.state.responseCorrelatas === true &&
								this.state.items.length === 0
								? "Ainda não há correlatas identificadas"
								: "Ainda não existem correlatas"}
						</h4>
					</div>
				)}
			</Fragment>
		);
	}
}

export default (props) => <Correlatas {...props} semPaginacao={true} />;
