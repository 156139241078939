import React, {Component} from 'react';
import { get } from '../../services/http'
import Loading from '../../componentes/loading';
import LoadingCircle  from '../../componentes/loadingCircle'
import AccordionParlamentaresProposicoes from '../../componentes/accordion'
import AccordionParlamentaresCPI from '../../componentes/accordion'
import AccordionParlamentaresTemas from '../../componentes/accordion'
//import AccordionParlamentaresTramaticao from '../../componentes/accordion'
import AccordionComissoes from '../../componentes/accordion'
import AccordionParlamentaresMaisApresentamProjetos from '../../componentes/accordion'
import AccordionParlamentaresMaisApresentamParidos from '../../componentes/accordion'
//import ClientSelect from '../../componentes/forms/basic/clientSelect'
import GraficoParlamentarCPI from '../insights/graficos/parlamentaresCPI'
import GraficoParlamentarCORR from '../insights/graficos/parlamentaresCorrelatas'
import GraficoParlaMaisApreProj from '../insights/graficos/graficoParlaMaisApreProj'
import GraficoParlaMaisAprePartido from '../insights/graficos/graficoParlaMaisAprePartido'

// import Radio from '@material-ui/core/Radio';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Grid from '@material-ui/core/Grid';

import ClientCheckboxWithSelect from '../../componentes/forms/basic/clientSelectWithSearch'
import { inject, observer } from 'mobx-react'
import FormatInput from '../../componentes/forms/inputs/formatInput'
import posicionamento from "../../enums/posicionamento"

@inject('authStore')
@observer

class InsightParlamentar extends Component {
    state = {
        loadingCircle:false,
        loading: false,

        casa_legislativa:[
           {id: 'CD', value:'Câmara'},
           {id: 'SF', value:'Senado'}, 
        //    {id: 'CN', value:'Congresso'}
        ],
        casa_legislativaSemCongresso:[
           {id: 'CD', value:'Câmara'},
           {id: 'SF', value:'Senado'}
        ],
        ambito:[{}],
        ambitoComissoes:[],
        // filtrar_proposicao:[{id:'1', value:'Teste'}],
        filtrar_proposicao_seguidas:[
            {id: 1, value:'Seguidas'},
            {id: 0, value:'Não Seguidas'}
        ],
       
        inicializandoPRP:true,
        inicializandoCPI:true,
        inicializandoTTP:true,
        inicializandoCFC:true,

        mensagemSemRegistrosPRP: '',
        mensagemSemRegistrosCPI: '',
        mensagemSemRegistrosTTP: '',
        mensagemSemRegistrosCFC: '',

        displayMsgPRP:'',
        displayMsgCPI:'',
        displayMsgTTP:'',
        displayMsgCFC:'',
        
        //Resposta da pesquisa parlamentares que mais relatam proposições
        resultadoPRP: [],

        //Resposta da pesquisa parlamentares que mais participam de CPI
        resultadoCPI:[],

        //Resposta da pesquisa temas mais tratados por parlamentar
        resultadoTTP:[],

        //Resposta da pesquisa tempo medio de tramitação de proposicao por parlamentar
        resultadoTMTPP:[],

         //Resposta da pesquisa correlação de forçãs nas comissões
         resultadoCFC:[],

         dataInicialParlamentaresProposicao: '',
         dataFinalParlamentaresProposicao: '',
         ambitoCasaSelecionado: '',      
         proposicaoSeguidas: '',

         dataInicialParlamentaresCPI: '',
         dataFinalParlamentaresCPI: '',

         dataInicialParlamentaresTemas: '',
         dataFinalParlamentaresTemas: '',

         dataInicialParlamentaresTramitacao: '',
         dataFinalParlamentaresTramitacao: '',

         dataInicialComissoes: '',
         dataFinalComissoes: '',
         casaSelecionada: '',         
         
         casaSelecionadaComissoes: '',
         ambitoCasaSelecionadoComissoes: '',
         respConvergente:'',
         respDivergente:'',
         respNaoClassificado:'',
         respNeutro:'',
      
         parlamentarNomes: [],
         radioSelecionadoTemas: [
            {id: 1, value:'Relatoria'},
            {id: 0, value:'Autoria'}
        ],
        opcaoSelecionadoTemas: '',
        idParlamentar: 0,
        temasRelatoria: [],
        temaAutoria: [],
        mensagensTema: false,
        parlamentarOption:'',
        parlamentarTemaOption:'',
        somaResultadosComissoes : 0,
        dataIniParlaMaisApresProj:null,
        dataFimParlaMaisApresProj:null,
        dataIniParlaMaisApresPartido:null,
        dataFimParlaMaisApresPartido:null,
        casa_legislativaParlaMaisApresProjValue:null,              
        casa_legislativaParlaMaisApresPartidoValue:null,              
        // ambitoParlaMaisApresProjValue:null,
        // ambitoParlaMaisApresPartidoValue:null,
        posicionamentoParlaMaisApresProjValue:null,
        partidoParlaMaisApresPartidoValue:null,
        // dadosAmbitoParlaMaisApresProj:[],
        dadosResultadoParlaMaisApresProj:[],
        // dadosAmbitoParlaMaisApresPartido:[],
        dadosResultadoParlaMaisApresPartido:[],
        pesquisouParlaMaisApresProj:false,
        pesquisouParlaMaisApresPartido:false,
        dadosPartidoParlamentares:[]
    }

    componentDidMount() {
        if(this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        this.getparlamentarPorTemas()
        this.getPartidoParlamentares()        

    }
    
    clearMsg(){	 

        setTimeout(() =>{
          this.setState({
              mensagemSemRegistrosPRP:'', 
              mensagemSemRegistrosCPI:'', 
              mensagemSemRegistrosTTP:'', 
              mensagemSemRegistrosCFC:'', 
              displayMsgPRP :'d-none',
              displayMsgCPI :'d-none',
              displayMsgTTP :'d-none',
              displayMsgCFC :'d-none',
              pesquisouParlaMaisApresProj:false,
              pesquisouParlaMaisApresPartido:false              
            })
        }, 5000);      
  
      }

    async onChangeCasaLegislativa(casa){
       this.setState({
            casaSelecionadaComissoes: casa,
            ambitoCasaSelecionado:'',
            proposicaoSeguidas:'',
            loadingCircle:true,
        })
        await get(`insights/parlamentares/ambitos/${casa.value}`)
        .then(response => {
            const dados = response || []
            //console.log(dados)
            this.setState({ ambito: dados, loadingCircle:false })
        }).catch(err => err)
        
    }
    onChangeAmbito(ambito){
        //console.log(ambito)
        this.setState({ ambitoCasaSelecionado: ambito })
    }

    onChangeProposicoesSeguidas(seguidas) {
        //console.log(seguidas)
        this.setState({ proposicaoSeguidas: seguidas })
    }

    onChangeFiltrarProposicoes(){
        // console.log(this.state.ambitoCasaSelecionado.value)
        // console.log(this.state.proposicaoSeguidas.value)
    }


    dataInicialParlamentaresProposicao(event){
        this.setState({ dataInicialParlamentaresProposicao: event.target.value })
    }

    dataFinalParlamentaresProposicao(event){
        this.setState({ dataFinalParlamentaresProposicao: event.target.value  })
    }

    async getDataParlamentaresProposicoes() {

        await get(`insights/parlamentares/${this.state.casaSelecionadaComissoes.value}/${this.state.ambitoCasaSelecionado.label}/${this.state.proposicaoSeguidas.value}/${this.state.dataInicialParlamentaresProposicao}/${this.state.dataFinalParlamentaresProposicao}`)
        .then(response => {
            const dados = response || []
            this.setState({ resultadoPRP: dados, inicializandoPRP: false})         
            
        }).catch(err => err)  
        
        if(this.state.resultadoPRP.length === 0){
            this.setState({mensagemSemRegistrosPRP:'A pesquisa não retornou resultados.', displayMsgPRP :''})
            this.clearMsg();
        }
    }    
   
    /************ INSIGHTS CPI ************/ 
    dataInicialParlamentaresCPI(event) {
        this.setState({ dataInicialParlamentaresCPI: event.target.value  })
    }

    dataFinalParlamentaresCPI(event) {
        this.setState({ dataFinalParlamentaresCPI: event.target.value })
    }

    async getDataParlamentaresCPI() {
     
        if(this.state.dataInicialParlamentaresCPI !== "" && this.state.dataFinalParlamentaresCPI !==  "" ){
            await get(`insights/parlamentares/cpis/${this.state.dataInicialParlamentaresCPI}/${this.state.dataFinalParlamentaresCPI}`)
           // await get(`insights/parlamentares/cpis/2018-01-09/2020-04-09`)
                .then(response =>{
                    const dados = response || []
                    //console.log(dados)
                    this.setState({ resultadoCPI: dados, inicializandoCPI:false })
                }).catch(err => err)
        }

        if(this.state.resultadoCPI.length === 0){
            this.setState({mensagemSemRegistrosCPI:'A pesquisa não retornou resultados.', displayMsgCPI :''})
            this.clearMsg();
        }
        
    }

    async getparlamentarPorTemas() {
        this.setState({loadingCircle:true})
        await get(`/insights/parlamentares/lista`)
            .then(response => {
                const dados = response || []
                // console.log(dados)
                this.setState({ parlamentarNomes: dados, loadingCircle:false })
               
            }).catch(err => err)
    }

    /*****************FIM*******************/

    dataInicialParlamentaresTemas(event) {
        this.setState({ dataInicialParlamentaresTemas: event.target.value })
    }
    
    dataFinalParlamentaresTemas(event) { 
        this.setState({ dataFinalParlamentaresTemas: event.target.value }) 
    }

    async getDataParlamentaresTemas() {
        // console.log(this.state.opcaoSelecionadoTemas)
       
        if(this.state.opcaoSelecionadoTemas === "Relatoria") {
            await get(`/insights/parlamentares/relatoria/${this.state.idParlamentar}/${this.state.dataInicialParlamentaresTemas}/${this.state.dataFinalParlamentaresTemas}`)
            .then(response => {
                const dados = response || []
                //console.log("relatoria", dados)
                if(dados.length === 0) {
                    this.setState({ mensagensTema: true, temasRelatoria:[]})
                } else {
                    this.setState({ temasRelatoria: dados, mensagensTema: false })

                }
               
            }).catch(err => err)

            if(this.state.temasRelatoria.length === 0){
                this.setState({mensagemSemRegistrosTTP:'A pesquisa não retornou resultados.', displayMsgTTP :''})
                this.clearMsg();
            }
            
        } else {
            await get(`/insights/parlamentares/autoria/${this.state.idParlamentar}/${this.state.dataInicialParlamentaresTemas}/${this.state.dataFinalParlamentaresTemas}`)
            .then(response => {
                const dados = response || []
                if(dados.length === 0) {
                    this.setState({ mensagensTema: true, temaAutoria:[] })
                } else {
                    this.setState({ temaAutoria: dados, mensagensTema: false })

                }
               
            }).catch(err => err)

            if(this.state.temaAutoria.length === 0){
                this.setState({mensagemSemRegistrosTTP:'A pesquisa não retornou resultados.', displayMsgTTP :''})
                this.clearMsg();
            }
        }        
        
    }

    onChangeEventoTemasRelatoria(temas){
        this.setState({ 
            opcaoSelecionadoTemas: temas.label,
            parlamentarTemaOption: temas 
         })
    }

    parlamentarSelecionadoTemas(selecionado){
        this.setState({ 
            idParlamentar: selecionado.value,
            parlamentarOption: selecionado,
         })
    }

    dataInicialParlamentaresTramitacao(event) {
        this.setState({ dataInicialParlamentaresTramitacao: event.target.value  })
    }

    dataFinalParlamentaresTramitacao(event){
        this.setState({ dataInicialParlamentaresTramitacao: event.target.value })
    }
    
    getDataParlamentaresTramitacao(){
        // console.log("parlamentares tramitação!")
    }


    /************ COMISSOES ***********/
    dataInicialComissoes(event) {
        this.setState({ dataInicialComissoes: event.target.value })
    }

    dataFinalComissoes(event) {
        this.setState({ dataFinalComissoes: event.target.value }) 
    }

    async onChangeCasaLegislativaComissoes(casa){
       this.setState({ loadingCircle:true ,casaSelecionada:casa, ambitoCasaSelecionadoComissoes:'' })
        await get(`insights/parlamentares/ambito/forca/${casa.value}`)
        .then(response => {
            const dados = response || []
            this.setState({ ambitoComissoes: dados, loadingCircle:false })
        }).catch(err => err)
        
    }
    onChangeAmbitoComissoes(ambitoComissoes){
        this.setState({ ambitoCasaSelecionadoComissoes: ambitoComissoes })
    }

    async getDataComissoes(){
        const idCliente = 1

        // let casaLegisNum = 0

        // switch (this.state.casaSelecionadaComissoes.value) {
        //     case 'CD':
        //         casaLegisNum = 1
        //         break;

        //     case 'SF':
        //         casaLegisNum = 2
        //         break;

        //     case 'CN':
        //         casaLegisNum = 3
        //         break;
                
        //     default:
        //         break;
        // }


        //console.log(casaLegisNum)

        
           await get(`insights/parlamentares/correlacao/${this.state.casaSelecionada.value}/${idCliente}/${this.state.ambitoCasaSelecionadoComissoes.label}`)
                .then(response => {
                const dados = response 
                this.setState({ 
                    resultadoCFC: dados,
                    respConvergente: dados.length > 0 ? dados[0].convergente : 0,
                    respDivergente: dados.length > 0 ? dados[0].divergente : 0,
                    respNaoClassificado: dados.length > 0 ? dados[0].naoClassificado : 0,
                    respNeutro: dados.length > 0 ? dados[0].neutro : 0,
                    inicializandoCFC: false 
                })
               
            }).catch(err => err)

    
        //console.log('XXXXXXXX>>>>>>',this.state.respConvergente)
        const somaComissoes = parseInt(this.state.respConvergente) + parseInt(this.state.respDivergente) + parseInt(this.state.respNaoClassificado) + parseInt(this.state.respNeutro)
        this.setState({somaResultadosComissoes:somaComissoes})

        //console.log('soma',this.state.somaResultadosComissoes )

        if(this.state.respConvergente === 0 && this.state.respDivergente === 0 && this.state.respNaoClassificado === 0 && this.state.respNeutro === 0 ){
            this.setState({mensagemSemRegistrosCFC:'A pesquisa não retornou resultados.', displayMsgCFC :''})
            this.clearMsg();
        }
    }
    /********** FIM *****************/

    limparDatasParlPro() {
        document.getElementsByClassName('data-inicial-ParlPro')[0].value=""
        document.getElementsByClassName('data-fim-ParlPro')[0].value="";
        this.setState({
            dataInicialParlamentaresProposicao:'',
            dataFinalParlamentaresProposicao:'',
            casaSelecionadaComissoes:'',
            ambitoCasaSelecionado:'',
            proposicaoSeguidas:'',
            resultadoPRP:[]
        })
    }

    limparDatasParlTemas() {
        document.getElementsByClassName('data-inicial-ParlTemas')[0].value=""
        document.getElementsByClassName('data-final-ParlTemas')[0].value="";
        this.setState({
            parlamentarOption:'',
            parlamentarTemaOption:'',
            temaAutoria:[],
            temasRelatoria:[],
            dataInicialParlamentaresTemas:'',
            dataFinalParlamentaresTemas:'',
            mensagemSemRegistrosTTP:'',
            displayMsgTTP:'d-none'
        })
    }
    limparDatasParlCPI() {
        document.getElementsByClassName('data-inicial-ParlCPI')[0].value=""
        document.getElementsByClassName('data-final-ParlCPI')[0].value="";
        this.setState({
            dataInicialParlamentaresCPI:'',
            dataFinalParlamentaresCPI:'',
            resultadoCPI:[],
            displayMsgCPI :'d-none'
        })
    }
    limparDatasParlTram() {
        document.getElementsByClassName('data-inicial-ParlTram')[0].value=""
        document.getElementsByClassName('data-final-ParlTram')[0].value="";
    }
    limparDatasComis() {
        // document.getElementsByClassName('data-inicial-Comis')[0].value=""
        // document.getElementsByClassName('data-final-Comis')[0].value="";
        this.setState({
            casaSelecionada:'',
            ambitoCasaSelecionadoComissoes:'',
            // dataInicialComissoes:'',
            // dataFinalComissoes:'',
            inicializandoCFC:true
        })
    }
 // Parlamentares que mais apresentam projetos por posicionamento
    dataInicialParlaMaisApresProj = (dtini) =>{
     this.setState({dataIniParlaMaisApresProj:dtini.target.value})
    }
    
    dataFinalParlaMaisApresProj = (dtFim) =>{
        this.setState({dataFimParlaMaisApresProj:dtFim.target.value})
    }
    async enviaDataParlaMaisApresProj() {
        await get(`insights/parlamentares/qtd-projetos/posicionamento/${this.state.posicionamentoParlaMaisApresProjValue.value}/${this.state.casa_legislativaParlaMaisApresProjValue.value}/${this.state.dataIniParlaMaisApresProj}/${this.state.dataFimParlaMaisApresProj}`)
        .then(response => {
            // console.log(response)
            const dados = response 
            this.setState({ 
                dadosResultadoParlaMaisApresProj:dados,
                pesquisouParlaMaisApresProj:true
            })
            if(dados.length===0){
                this.clearMsg()
            }
           
        }).catch(err => err)
         
    }    
    limparDatasParlaMaisApresProj() {
        document.getElementsByClassName('data-inicial-ParlaMaisApresProj')[0].value=""
        document.getElementsByClassName('data-final-ParlaMaisApresProj')[0].value="";
        this.setState({
            dataIniParlaMaisApresProj:null,
            dataFimParlaMaisApresProj:null,
            casa_legislativaParlaMaisApresProjValue:null,
            // ambitoParlaMaisApresProjValue:null,
            posicionamentoParlaMaisApresProjValue:null,
            pesquisouParlaMaisApresProj:false,
            dadosResultadoParlaMaisApresProj:[]
        })
    }
    onChangeCasaLegislativaParlaMaisApresProj = (casa) => {
        this.setState({
            casa_legislativaParlaMaisApresProjValue:casa,
            // ambitoParlaMaisApresProjValue:null
        })
        // this.getAmbitos(casa.value)        
    }
    // onChangeAmbitoParlaMaisApresProj = (ambito) => {
    //     this.setState({
    //         ambitoParlaMaisApresProjValue:ambito
    //     })               
    // }
    onChangePosicionamentoParlaMaisApresProj = (posicao) => {
        this.setState({
            posicionamentoParlaMaisApresProjValue:posicao
        })               
    }
    // async getAmbitos(siglaCasa) {
    //     await get(`insights/tema/ambito/lista/${siglaCasa}`)
    //     .then(response => {
    //         this.setState({ dadosAmbitoParlaMaisApresProj: response })
    //     }).catch(err => err)
    // }    
   // Parlamentares que mais apresentam projetos por partido
   dataInicialParlaMaisApresPartido = (dtini) =>{
    this.setState({dataIniParlaMaisApresPartido:dtini.target.value})
   }
   
   dataFinalParlaMaisApresPartido = (dtFim) =>{
       this.setState({dataFimParlaMaisApresPartido:dtFim.target.value})
   }
   async enviaDataParlaMaisApresPartido() {
       await get(`insights/parlamentares/qtd-projetos/partido/${this.state.partidoParlaMaisApresPartidoValue.value}/${this.state.casa_legislativaParlaMaisApresPartidoValue.value}/${this.state.dataIniParlaMaisApresPartido}/${this.state.dataFimParlaMaisApresPartido}`)
       .then(response => {
           const dados = response 
           this.setState({ 
               dadosResultadoParlaMaisApresPartido:dados,
               pesquisouParlaMaisApresPartido:true
           })
           if(dados.length===0){
               this.clearMsg()
           }
          
       }).catch(err => err)
        
   }    
   limparDatasParlaMaisApresPartido() {
       document.getElementsByClassName('data-inicial-ParlaMaisApresPartido')[0].value=""
       document.getElementsByClassName('data-final-ParlaMaisApresPartido')[0].value="";
       this.setState({
           dataIniParlaMaisApresPartido:null,
           dataFimParlaMaisApresPartido:null,
           casa_legislativaParlaMaisApresPartidoValue:null,
        //    ambitoParlaMaisApresPartidoValue:null,
           partidoParlaMaisApresPartidoValue:null,
           pesquisouParlaMaisApresPartido:false,
           dadosResultadoParlaMaisApresPartido:[]
       })
   }
   onChangeCasaLegislativaParlaMaisApresPartido = (casa) => {
       this.setState({
           casa_legislativaParlaMaisApresPartidoValue:casa,
        //    ambitoParlaMaisApresPartidoValue:null
       })
    //    this.getAmbitosParlaPartido(casa.value)        
   }
//    onChangeAmbitoParlaMaisApresPartido = (ambito) => {
//        this.setState({
//            ambitoParlaMaisApresPartidoValue:ambito
//        })               
//    }
   onChangePosicionamentoParlaMaisApresPartido = (posicao) => {
       this.setState({
           partidoParlaMaisApresPartidoValue:posicao
       })               
   }
//    async getAmbitosParlaPartido(siglaCasa) {
//       await get(`insights/tema/ambito/lista/${siglaCasa}`)
//      .then(response => {
//         this.setState({ dadosAmbitoParlaMaisApresPartido: response })
//      }).catch(err => err)
//    }       
   async getPartidoParlamentares() {
      await get(`insights/parlamentares/partidos`)
     .then(response => {
        this.setState({ dadosPartidoParlamentares: response })
     }).catch(err => err)
   }       

    render() { 
        const {casaSelecionadaComissoes} = this.state
        // const {ambitoCasaSelecionado} = this.state
        const {proposicaoSeguidas} = this.state
        const {parlamentarOption} = this.state
        const {parlamentarTemaOption} = this.state
        const {casaSelecionada} = this.state
        const {ambitoCasaSelecionadoComissoes} = this.state
        const {casa_legislativaParlaMaisApresProjValue} = this.state
        const {casa_legislativaParlaMaisApresPartidoValue} = this.state
        // const {ambitoParlaMaisApresProjValue} = this.state
        // const {ambitoParlaMaisApresPartidoValue} = this.state
        const {posicionamentoParlaMaisApresProjValue} = this.state
        const {partidoParlaMaisApresPartidoValue} = this.state

        // let legendasParlamentares = []

        // this.state.dadosResultadoParlaMaisApresProj.length > 0 ?                                    
        // this.state.dadosResultadoParlaMaisApresProj.map((dado,index)=>
        //     legendasParlamentares[index] = dado.siglaPartido
        // ): legendasParlamentares = null
        
        // Array.isArray(legendasParlamentares) ? legendasParlamentares = legendasParlamentares.values() : legendasParlamentares = null

        // let legendasParlamentaresPartidos = []

        // this.state.dadosResultadoParlaMaisApresPartido.length > 0 ?                                    
        // this.state.dadosResultadoParlaMaisApresPartido.map((dado,index)=>
        //     legendasParlamentaresPartidos[index] = dado.siglaPartido
        // ): legendasParlamentaresPartidos = null
        // Array.isArray(legendasParlamentaresPartidos) ? legendasParlamentaresPartidos = legendasParlamentaresPartidos.values() : legendasParlamentaresPartidos = null

        //GRAFICO CPI
        const quantidade = this.state.resultadoCPI.map(item=>(parseInt(item.qtd)))
        const nome_parlamentar =  this.state.resultadoCPI.map(item=>(item.nome))
      
        /******* FIM *********/



        const larguraTela = window.innerWidth
        const classButtonPesquisar = larguraTela <= 600 ? 'col-12  col-sm-6  col-lg-6 col-xl-6 d-flex justify-content-center mt-1' : 'col-12  col-sm-6  col-lg-6 col-xl-6 d-flex justify-content-end'
        return (
            this.state.loading ? <Loading/> :
                <div className="" >
                    <div className="acordionsBordas mt-2">
                        
                        <AccordionParlamentaresProposicoes
                            mb={'mb-0'}
                            p={'p-0'}
                            titulo="Parlamentares que mais relatam proposições"
                          
                        >
                        <hr/>
                        <div className="row p-2">  
                            <div className="col-12 col-sm-3  col-lg-3 col-xl-3 mb-2 ">
                                <div className="label-insight-acordion">Data Inicial</div> 
                                <input id="data-inicial" className="data-inicial-ParlPro"  type="date" onChange={event => this.dataInicialParlamentaresProposicao(event)} />    
                            </div>
                            <div className="col-12 col-sm-3  col-lg-3 col-xl-3"> 
                                <div className="label-insight-acordion">Data Final</div>  
                                <input id="data-final" className="data-fim-ParlPro"  type="date" onChange={event => this.dataFinalParlamentaresProposicao(event)}   />
                            </div>

                            {this.state.dataInicialParlamentaresProposicao !== "" && this.state.dataFinalParlamentaresProposicao !== "" && this.state.proposicaoSeguidas !== "" && this.state.casaSelecionadaComissoes !== "" ?
                                <div className={`${classButtonPesquisar}`} >
                                    <img id="pesquisa-dash" className=""  src={require('../../design/assets/images/btn_pesquisar.svg')} alt="btn-pesquisar" onClick={() => this.getDataParlamentaresProposicoes()}  style={{cursor: 'pointer'}}/>
                                    <img  id="limpar-dash" className="ml-3"  src={require('../../design/assets/images/btn_limpar.svg')} alt="btn-limpar" onClick={() => this.limparDatasParlPro()} style={{cursor: 'pointer'}}/>
                                </div>
                            : null }               

                        </div>
                        <div className="row p-2">
                            <div className="col-12 col-sm-6  col-lg-6 col-xl-6 mt-3 ">
                                <label className="label-insight-acordion">Casa Legislativa</label>
                                <ClientCheckboxWithSelect 
                                    css="no-border"
                                    value={casaSelecionadaComissoes}
                                    options={this.state.casa_legislativa.filter(item => item.id !== 'CD').map(item => ({ value: item.id, label: item.value }))}
                                    onChange={casa_legislativa => this.onChangeCasaLegislativa(casa_legislativa)} 
                                    // placeholder={this.state.casaSelecionadaComissoes}
                                    label={'Casa Legislativa'}
                                />                    

                            </div>
                            {/* <div className="col-12 col-sm-6  col-lg-3 col-xl-3 mt-3 ">
                                <label className="label-insight-acordion">Âmbito</label>

                                {this.state.loadingCircle ? 
                            
                                    <div className="d-flex justify-content-center mt-2" >
                                        <LoadingCircle color={'#DC2D41'} size={'30px'} />
                                    </div>
                                :
                                    <ClientCheckboxWithSelect 
                                        css="no-border"
                                        value={ambitoCasaSelecionado}
                                        options={this.state.ambito.map(item => ({ value: item.id, label: item.value }))}
                                        onChange={ambito => this.onChangeAmbito(ambito)} 
                                        // placeholder={this.state.ambitoCasaSelecionado}
                                        label={'Casa Legislativa'}
                                    />  

                                 }

                            </div> */}
                                <div className="col-12 col-sm-6  col-lg-6 col-xl-6 mt-3 ">
                                    <label className="label-insight-acordion">Filtrar Proposições</label>
                                    <ClientCheckboxWithSelect 
                                        css="no-border"
                                        value={proposicaoSeguidas}
                                        options={this.state.filtrar_proposicao_seguidas.map(item => ({ value: item.id, label: item.value } ))}
                                        onChange={seguidas => this.onChangeProposicoesSeguidas(seguidas)} 
                                        // placeholder={this.state.proposicaoSeguidas.value}
                                        label={'Casa Legislativa'}
                                    /> 
                                </div>
                        </div>
                        {
                             this.state.inicializandoPRP === true ? <div className="mb-4"></div>
                             :
                                this.state.resultadoPRP.length > 0 && this.state.casaSelecionadaComissoes !== '' ?    
                                    <div className="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl ">
                                        <table className="table table-striped ">
                                            <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                                <tr>
                                                    <th>Parlamentar</th>
                                                    <th>Número de proposições relatadas </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {

                                                    this.state.resultadoPRP !== undefined && Array.isArray(this.state.resultadoPRP) && this.state.resultadoPRP.length > 0 ?
                                                        this.state.resultadoPRP.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.nome}</td>
                                                                    <td>{item.qtd}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </tbody>
                                        </table>
                                    </div> 

                                    :     
                                    <div className={`${this.state.displayMsgPRP}`} style={{backgroundColor: "rgba(86,61,124,.15)",
                                    border: "1px solid rgba(86,61,124,.15)", fontSize: "18px"}}>
                                        <div className={this.state.casaSelecionadaComissoes !== '' ?
                                         `p-3 d-flex justify-content-center mb-2 `:'d-none'}                                       
                                         >
                                            {this.state.mensagemSemRegistrosPRP} 
                                        </div> 
                                    </div>              
                        }              
                        </AccordionParlamentaresProposicoes>

                    </div>
                    
                    <div className="acordionsBordas mt-2">
                        <AccordionParlamentaresCPI
                            mb={'mb-0'}
                            p={'p-0'}
                            titulo="Parlamentares que mais participam de CPI"
                        >
                         <hr/>
                            <div className="row p-2">  
                                <div className="col-12 col-sm-3  col-lg-3 col-xl-3">
                                    <div className="label-insight-acordion">Data Inicial</div> 
                                    <input id="data-inicial" className="data-inicial-ParlCPI"  type="date" onChange={event => this.dataInicialParlamentaresCPI(event)} />    
                                </div>
                                <div className="col-12 col-sm-3  col-lg-3 col-xl-3"> 
                                    <div className="label-insight-acordion">Data Final</div>  
                                    <input id="data-final" className="data-final-ParlCPI"  type="date" onChange={event => this.dataFinalParlamentaresCPI(event)}   />
                                </div>

                                {this.state.dataInicialParlamentaresCPI !== "" && this.state.dataFinalParlamentaresCPI !== "" ?
                                    <div className={`${classButtonPesquisar}`} >
                                        <img id="pesquisa-dash" className=""  src={require('../../design/assets/images/btn_pesquisar.svg')} alt="btn-pesquisar" onClick={() => this.getDataParlamentaresCPI()}  style={{cursor: 'pointer'}}/>
                                        <img  id="limpar-dash" className="ml-3"  src={require('../../design/assets/images/btn_limpar.svg')} alt="btn-limpar" onClick={() => this.limparDatasParlCPI()} style={{cursor: 'pointer'}}/>
                                    </div>            
                                : null }   

                            </div>    
                            {
                            this.state.inicializandoCPI === true ?
                             <div className="mb-4"></div>
                             :
                                this.state.resultadoCPI.length > 0 ? 
                                    <div className="insightGraficoCPI graficoBar" >
                                        <GraficoParlamentarCPI
                                            quantidade = {quantidade}  
                                            nome = {nome_parlamentar}                                      
                                        />
                                    </div>
                                :  
                                <div className={`${this.state.displayMsgCPI}`}
                                style={{backgroundColor: "rgba(86,61,124,.15)",
                                    border: "1px solid rgba(86,61,124,.15)", fontSize: "18px"}}>
                                    <div className={`p-3 d-flex justify-content-center mb-2`}>
                                        <b>{this.state.mensagemSemRegistrosCPI}</b> 
                                    </div> 
                                </div> 
                                                              
                            }
                        </AccordionParlamentaresCPI>
                    </div>
                    <div className="acordionsBordas mt-2">
                        <AccordionParlamentaresTemas
                             mb={'mb-0'}
                             p={'p-0'}
                             titulo="Temas mais tratados por parlamentares"
                        >
                         <hr/>
                        <div className="row p-2">  
                            <div className="col-12 col-sm-3  col-lg-3 col-xl-3">
                                <div className="label-insight-acordion">Data Inicial</div> 
                                <input id="data-inicial" className="data-inicial-ParlTemas"  type="date" onChange={event => this.dataInicialParlamentaresTemas(event)} />    
                            </div>
                            <div className="col-12 col-sm-3  col-lg-3 col-xl-3"> 
                                <div className="label-insight-acordion">Data Final</div>  
                                <input id="data-final" className="data-final-ParlTemas"  type="date" onChange={event => this.dataFinalParlamentaresTemas(event)}   />
                            </div>
                            {this.state.dataInicialParlamentaresTemas !== "" && this.state.dataFinalParlamentaresTemas !== "" && this.state.opcaoSelecionadoTemas !== "" ?
                                <div className={`${classButtonPesquisar}`} >
                                    <img id="pesquisa-dash" className=""  src={require('../../design/assets/images/btn_pesquisar.svg')} alt="btn-pesquisar" onClick={() => this.getDataParlamentaresTemas()}  style={{cursor: 'pointer'}}/>
                                    <img  id="limpar-dash" className="ml-3"  src={require('../../design/assets/images/btn_limpar.svg')} alt="btn-limpar" onClick={() => this.limparDatasParlTemas()} style={{cursor: 'pointer'}}/>
                                </div>
                            : null}                                
                        </div>    
                        
                        <div className="row p-2">
                            <div className="col-12 col-sm-6  col-lg-6 col-xl-6 mt-3 ">
                                    <span className="label-insight-acordion">Parlamentar | Tratamento | Legislatura</span>

                                    {this.state.loadingCircle ? 
                            
                                        <div className="d-flex justify-content-center mt-2" >
                                            <LoadingCircle color={'#DC2D41'} size={'30px'} />
                                        </div>
                                    :

                                        <ClientCheckboxWithSelect 
                                            css="no-border"
                                            value={parlamentarOption}
                                            options={this.state.parlamentarNomes.map(item => ({ value: item.id, label: item.nome + ' | ' + item.tratamento + ((item.legislatura === null) ? ' | sem legislatura' : ' | ' + item.legislatura)}) )}
                                            onChange={parlamentarSelecionado => this.parlamentarSelecionadoTemas(parlamentarSelecionado)} 
                                            // placeholder={this.state.parlamentarNomes.label}
                                            //label={'Casa Legislativa'}
                                        />      
                                    
                                    }   

                            </div>
                            <div className="col-12 col-sm-6  col-lg-6 col-xl-6 mt-3 ">
                                <span className="label-insight-acordion">Temas</span>
                                <ClientCheckboxWithSelect 
                                    css="no-border"
                                    value={parlamentarTemaOption}
                                    options={this.state.radioSelecionadoTemas.map(item => ({ value: item.id, label: item.value }) )}
                                    onChange={temas => this.onChangeEventoTemasRelatoria(temas)} 
                                    // placeholder={this.state.opcaoSelecionadoTemas}
                                    label={'Casa Legislativa'}
                                />   
                            </div>
                        </div>  
                        {
                            (this.state.temasRelatoria.length > 0 || this.state.temaAutoria.length > 0)  &&  this.state.mensagensTema === false ?
                                this.state.opcaoSelecionadoTemas === "Relatoria" ?     
                                    <div className="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl ">
                                        <table className="table table-striped">
                                            <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                                <tr >
                                                    <th>Temas</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {                                              
                                                    this.state.temasRelatoria !== undefined && Array.isArray(this.state.temasRelatoria) && this.state.temasRelatoria.length > 0 ?
                                                        this.state.temasRelatoria.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.tema}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl ">
                                        <table className="table table-striped">
                                            <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                                <tr >
                                                    <th>Temas</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.temaAutoria !== undefined && Array.isArray(this.state.temaAutoria) && this.state.temaAutoria.length > 0 ?
                                                    this.state.temaAutoria.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.tema}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : null}
                                            </tbody>
                                        </table>
                                    </div>
                            :
                                null
                        }

               { this.state.mensagensTema  ? 
                    <div className={`${this.state.displayMsgTTP}`}
                    style={{backgroundColor: "rgba(86,61,124,.15)",
                                border: "1px solid rgba(86,61,124,.15)", fontSize: "18px"}}>
                        <div className={`p-3 d-flex justify-content-center mb-2`}>
                            <b>{this.state.mensagemSemRegistrosTTP}</b> 
                        </div> 
                    </div>  
                    : null
                }

                        
                </AccordionParlamentaresTemas>
            </div>

            {/* <div className="acordionsBordas mt-2">  
                <AccordionParlamentaresTramaticao
                    mb={'mb-0'}
                    p={'p-0'}
                    titulo="Tempo médio de tramitação de proposição por parlamentar"
                >
                 <hr/>
                    <div className="row p-2">  
                        <div className="col-12 col-sm-3  col-lg-3 col-xl-3">
                            <div className="label-insight-acordion">Data Inicial</div> 
                            <input id="data-inicial" className="data-inicial-ParlTram"  type="date" onChange={event => this.dataInicialParlamentaresTramitacao(event)} />    
                        </div>
                        <div className="col-12 col-sm-3  col-lg-3 col-xl-3"> 
                            <div className="label-insight-acordion">Data Final</div>  
                            <input id="data-final" className="data-final-ParlTram"  type="date" onChange={event => this.dataFinalParlamentaresTramitacao(event)} />
                        </div>
                        <div className={`${classButtonPesquisar}`} >
                            <img id="pesquisa-dash" className=""  src={require('../../design/assets/images/btn_pesquisar.svg')} alt="btn-pesquisar" onClick={() => this.getDataParlamentaresTramitacao()}  style={{cursor: 'pointer'}}/>
                            <img  id="limpar-dash" className="ml-3"  src={require('../../design/assets/images/btn_limpar.svg')} alt="btn-limpar" onClick={() => this.limparDatasParlTram()} style={{cursor: 'pointer'}}/>
                        </div>                                
                    </div>
                    <div className="row p-2">
                            <div className="col-12 col-sm-6  col-lg-6 col-xl-6 mt-3 ">
                                    <label className="label-insight-acordion">Parlamentar</label>

                                    <ClientSelect
                                            id="casa-legislativa"
                                            items={this.state.casa_legislativa}
                                            //value={this.state.casa_legislativa}
                                            onChange={casa_legislativa => this.onChangeCasaLegislativa(casa_legislativa)}
                                          
                                    />                       

                            </div>
                            <div className="col-12 col-sm-6  col-lg-6 col-xl-6 mt-3 ">
                                {/* <label className="label-form-filtro" style={{fontSize:'0.8rem'}}>Arquivamento</label> */}
                                {/* <FormGroup row  aria-label="position">                 
                                    <Grid container item xs={6} sm={6} md={6} lg={3} xl={3}   > 
                                            <FormControlLabel
                                                control={
                                                    <Radio                                                         
                                                        //style={{color: '#D8D8D8'}} 
                                                        value="1" 
                                                        id="relatoria"
                                                        //onChange={event => this.onChangeArqAtivos(event)} 
                                                        checked={this.arquivamentoAtivoCheck} 
                                                    />}
                                                value={this.state.arquivamentoAtivoCheck}
                                                label="Relatoria"
                                                
                                            />
                                            
                                        </Grid>
                                        <Grid container item xs={6} sm={6} md={6} lg={3} xl={3}   > 
                                            <FormControlLabel
                                                control={
                                                    <Radio  
                                                        //style={{color: '#D8D8D8'}} 
                                                        value="2" 
                                                        id="autoria" 
                                                        //onChange={event => this.onChangeArqArquivados(event)} 
                                                        checked={this.arquivamentoAtivoCheck}
                                                        />}
                                                value={this.state.arquivamentoArquivadosCheck}   
                                                label="Autoria"                                   
                                            />
                                            
                                        </Grid>              
                                </FormGroup>   
                            </div>
                        </div>
                        <div className="tempo-tramitação d-flex justify-content-center">
                            <span className="texto-tramitação">Tempo Medio de tramitação das proposições </span>
                            <span className="valor-tramitação">54 dias</span>
                        </div>

                </AccordionParlamentaresTramaticao> 
            </div> */}

             <div className="acordionsBordas mt-2">                        
                    <AccordionComissoes
                        mb={'mb-0'}
                        p={'p-0'}
                        titulo="Correlação de Forças nas Comissões"
                    >
                     <hr/>
                        <div className="row p-2">
                        <div className="col-12 text-right p-2">  
                           
                            {
                             this.state.casaSelecionada !== "" && this.state.ambitoCasaSelecionadoComissoes !== ""?
                                <div  >    
                                    <img id="pesquisa-dash" className=""  src={require('../../design/assets/images/btn_pesquisar.svg')} alt="btn-pesquisar" onClick={() => this.getDataComissoes()}  style={{cursor: 'pointer'}}/>
                                    <img  id="limpar-dash" className="ml-3"  src={require('../../design/assets/images/btn_limpar.svg')} alt="btn-limpar" onClick={() => this.limparDatasComis()} style={{cursor: 'pointer'}}/>
                                </div>  
                            :null
                            }                              
                        </div>
                            <div className="col-12 col-sm-6  col-lg-6 col-xl-6 mt-3 ">
                                <label className="label-insight-acordion">Casa Legislativa</label>
                                    
                                    <ClientCheckboxWithSelect 
                                        css="no-border"
                                        value={casaSelecionada}
                                        options={this.state.casa_legislativa.map(item => ({ value: item.id, label: item.value }) )}
                                        onChange={casa_legislativa => this.onChangeCasaLegislativaComissoes(casa_legislativa)} 
                                        // placeholder={this.state.casaSelecionada}
                                        label={'Casa Legislativa'}
                                     />                      

                            </div>
                            
                            <div className="col-12 col-sm-6  col-lg-6 col-xl-6 mt-3 ">
                                <label className="label-insight-acordion">Âmbito</label>
                                    {this.state.loadingCircle ? 
                                        
                                        <div className="d-flex justify-content-center mt-2" >
                                            <LoadingCircle color={'#DC2D41'} size={'30px'} />
                                        </div>
                                    :
                                        <ClientCheckboxWithSelect 
                                            css="no-border"
                                            value={ambitoCasaSelecionadoComissoes}
                                            options={
                                                this.state.ambitoComissoes !== undefined && Array.isArray(this.state.ambitoComissoes) && this.state.ambitoComissoes.length > 0 ?
                                                this.state.ambitoComissoes.map((item,index) => ({ value: index, label: item.value }) ): []}
                                            onChange={ambitoComissoes => this.onChangeAmbitoComissoes(ambitoComissoes)} 
                                            // placeholder={this.state.ambitoCasaSelecionadoComissoes}
                                            label={'Casa Legislativa'}
                                        />  
                                    }               

                            </div>
                        </div>
                        

                        {
                            this.state.inicializandoCFC === true ? <div className="mb-4"></div>

                            :
                            this.state.somaResultadosComissoes === 0  ?       
                                <div className={`${this.state.displayMsgCFC}`} style={{backgroundColor: "rgba(86,61,124,.15)",
                                border: "1px solid rgba(86,61,124,.15)", fontSize: "18px"}}>
                                    <div className={`p-3 d-flex justify-content-center mb-2`}>
                                        <b>{this.state.mensagemSemRegistrosCFC}</b> 
                                    </div> 
                                </div>            
                                   
                                :
                                <div className="col-12  mt-2" id="divgrafico">
                                        <div className={`d-flex justify-content-center flex-wrap`}>
                                            <div>
                                                
                                                    <GraficoParlamentarCORR
                                                        ComissoesConvergente        = {this.state.respConvergente}
                                                        ComissoesDivergente         = {this.state.respDivergente}
                                                        ComissoesNaoClassificado    = {this.state.respNaoClassificado}
                                                        ComissoesNeutro             = {this.state.respNeutro } 
                                                    />
                                            
                                            </div>
                                            
                                            <div className="div-indice">
                                                <div className="d-flex">
                                                    <div className="indice-exito mt-1 mr-2"></div>
                                                    <div className="mr-2 dasboard-graficos-legenda">Convergente</div>
                                                    <span className="dashboard-graficos-dados">{this.state.propResultadoClientPendente}</span>
                                                </div>
                                                <div className="d-flex mt-2">
                                                    <div className="indice-sem-exito mt-1 mr-2"></div>
                                                    <div className="mr-2 dasboard-graficos-legenda">Divergente</div>
                                                    <span className="dashboard-graficos-dados">{this.state.propResultadoClientExito}</span>
                                                </div>
                                                <div className="d-flex mt-2">
                                                    <div className="indice-exito-parcial mt-1 mr-2"></div>
                                                    <div className="mr-2 dasboard-graficos-legenda">Não Classificado</div>
                                                    <span className="dashboard-graficos-dados">{this.state.propResultadoClientSemExito}</span>
                                                </div>
                                                <div className="d-flex mt-2 mb-2">
                                                    <div className="indice-sem-info mt-1 mr-2 "></div>
                                                    <div className="mr-2 dasboard-graficos-legenda">Neutro</div>
                                                    <span className="dashboard-graficos-dados">{this.state.propResultadoClientExitoParcial}</span>
                                                </div>
                                        </div>
                                        
                                        
                                        </div>
                                    </div>
                         }   

                    </AccordionComissoes>
                </div>
             <div className="acordionsBordas mt-2">
                <AccordionParlamentaresMaisApresentamProjetos
                    mb={'mb-0'}
                    p={'p-0'}
                    titulo="Parlamentares que mais apresentam projetos por posicionamento"                    
                >
                <hr/>
                    <div className='row p-4'>  
                        <div className="col-12 col-sm-3  col-lg-3 col-xl-3 mb-2 ">
                            <div className="label-insight">Data Inicial de Apresentação</div> 
                            <input id="data-inicial" className="data-inicial-ParlaMaisApresProj"  type="date" onChange={dtIni => this.dataInicialParlaMaisApresProj(dtIni)} />    
                        </div>
                        <div className="col-12 col-sm-3  col-lg-3 col-xl-3"> 
                            <div className="label-insight">Data Final de Apresentação</div>  
                            <input id="data-final" className="data-final-ParlaMaisApresProj"  type="date" onChange={dtFim => this.dataFinalParlaMaisApresProj(dtFim)}   />
                        </div>

                        <div className={this.state.casa_legislativaParlaMaisApresProjValue !== null && 
                            // this.state.ambitoParlaMaisApresProjValue !== null &&
                             this.state.posicionamentoParlaMaisApresProjValue !== null && 
                            this.state.dataIniParlaMaisApresProj !== null && this.state.dataFimParlaMaisApresProj !== null ?
                            `${classButtonPesquisar}` : 'd-none'} >
                    
                            <img id="pesquisa-dash" className=""  src={require('../../design/assets/images/btn_pesquisar.svg')} alt="btn-pesquisar" onClick={() => this.enviaDataParlaMaisApresProj()}  style={{cursor: 'pointer'}}/>
                            <img  id="limpar-dash" className="ml-3"  src={require('../../design/assets/images/btn_limpar.svg')} alt="btn-limpar" onClick={() => this.limparDatasParlaMaisApresProj()} style={{cursor: 'pointer'}}/>
                        </div>
                    </div>

                    <div className='row justify-content-center'>
                        <div className="col-sm-6 mt-3">
                            <FormatInput
                                className="Client-select"
                                label="Casa Legislativa"
                                >
                                <ClientCheckboxWithSelect
                                value={casa_legislativaParlaMaisApresProjValue}
                                onChange={casa_legislativa => this.onChangeCasaLegislativaParlaMaisApresProj(casa_legislativa)}
                                options={this.state.casa_legislativaSemCongresso.map(item => ({ value: item.id, label: item.value }) )}
                                    placeholder={
                                        "Escolha uma casa"}
                                />
                            </FormatInput>                         
                        </div>        
                        {/* <div className="col-sm-4 mt-3">
                            <FormatInput
                                css={this.state.casa_legislativaParlaMaisApresProjValue !== null ? "" : "label-disabled"}
                                className="Client-select"
                                label="Âmbito"
                                >
                                <ClientCheckboxWithSelect
                                value={ambitoParlaMaisApresProjValue}
                                onChange={ambito => this.onChangeAmbitoParlaMaisApresProj(ambito)}
                                options={
                                this.state.dadosAmbitoParlaMaisApresProj !== null && this.state.dadosAmbitoParlaMaisApresProj !== undefined ? 
                                this.state.dadosAmbitoParlaMaisApresProj.map((item,index) => ({ value: index, label: item.ambito })):null}
                                placeholder={
                                "Escolha um âmbito"}
                                disabled={this.state.casa_legislativaParlaMaisApresProjValue !== null ? false : true}
                                />
                            </FormatInput>                         
                        </div> */}

                        <div className="col-sm-6 mt-3">
                            <FormatInput
                            className="Client-select "
                            label="Posicionamento"
                            >
                            <ClientCheckboxWithSelect
                            value={posicionamentoParlaMaisApresProjValue}
                            onChange={posicao => this.onChangePosicionamentoParlaMaisApresProj(posicao)}
                            options={posicionamento.posicionamentosClient.map(item => ({ value: item.id, label: item.value }))}
                            placeholder={"Escolha um posicionamento"}
                            />
                            </FormatInput>                      
                        </div>
                        <div className={this.state.dadosResultadoParlaMaisApresProj.length > 0 ? 
                            `card-grafico-parlamentar`: `d-none`}>
                             <div className="row justify-content-center">                         

                                <div className="col-sm-12-md-12 item-descricao2">
                                   Quantidade de Projetos por Parlamentar
                                </div>
                            </div>
                             <div className="row justify-content-center">                         

                                <div className="col-sm-7-md-5">
                                    <GraficoParlaMaisApreProj 
                                    dados={this.state.dadosResultadoParlaMaisApresProj}
                                    />
                                </div>
                                <div className={this.state.dadosResultadoParlaMaisApresProj.length > 0 ? `col-sm-4-md-6 mt-5` : `d-none`}>
                                    {this.state.dadosResultadoParlaMaisApresProj !== null ?
                                        this.state.dadosResultadoParlaMaisApresProj.map((dado,index)=>
                                            index === 0 ?
                                            <div className="row" key={index}>
                                                <div className="col-1">
                                                    <div className="indice-exito mt-1"></div>
                                                </div>
                                                <div className="col-10 indice-descricao">
                                                    <div className=" d-inline">
                                                    {dado.nome}
                                                    </div>
                                                </div>
                                            </div>
                                            :null
                                        )
                                    :null
                                    }
                                    {this.state.dadosResultadoParlaMaisApresProj !== null ?
                                    this.state.dadosResultadoParlaMaisApresProj.map((dado,index)=>
                                        index === 1 ?                                   
                                        <div className="row" key={index}>
                                            <div className="col-1">
                                                <div className="indice-sem-exito mt-1"></div>
                                            </div>
                                            <div className="col-10 indice-descricao">
                                                <div  className=" d-inline">
                                                {dado.nome}
                                                </div>
                                            </div>
                                        </div>
                                        :null
                                        )
                                    :null
                                    }      
                                    {this.state.dadosResultadoParlaMaisApresProj !== null ?
                                    this.state.dadosResultadoParlaMaisApresProj.map((dado,index)=>
                                    index === 2 ?                                                                             
                                    <div className="row" key={index}>
                                        <div className="col-1">
                                            <div className="indice-exito-parcial mt-1"></div>
                                        </div>
                                        <div className="col-10 indice-descricao">
                                            <div className=" d-inline">
                                            {dado.nome}                                                
                                            </div>
                                        </div>
                                    </div>
                                            :null
                                        )
                                    :null
                                    }    
                                    {this.state.dadosResultadoParlaMaisApresProj !== null ?
                                    this.state.dadosResultadoParlaMaisApresProj.map((dado,index)=>
                                    index === 3 ?                                                                          
                                    <div className="row" key={index}>
                                        <div className="col-1">
                                            <div className="indice-pendente mt-1"></div>
                                        </div>
                                        <div className="col-10 indice-descricao">
                                            <div className=" d-inline">
                                            {dado.nome}                                                
                                            </div>
                                        </div>
                                    </div>
                                            :null
                                        )
                                    :null
                                    }  
                                    {this.state.dadosResultadoParlaMaisApresProj !== null ?
                                    this.state.dadosResultadoParlaMaisApresProj.map((dado,index)=>
                                    index === 4 ?                                                                          
                                    <div className="row" key={index}>
                                        <div className="col-1">
                                            <div className="indice-contrario-emenda mt-1"></div>
                                        </div>
                                        <div className="col-10 indice-descricao">
                                            <div className=" d-inline">
                                            {dado.nome}                                                
                                            </div>
                                        </div>
                                    </div>
                                            :null
                                        )
                                    :null
                                    }                                      
                                </div>
                            </div>
                        </div>
                        <table className={
                            this.state.dadosResultadoParlaMaisApresProj !== undefined && 
                            this.state.dadosResultadoParlaMaisApresProj !== null ?
                            this.state.dadosResultadoParlaMaisApresProj.length > 0 ?
                             `table table-striped table-responsive-sm`:`d-none`: `d-none`}>
                            <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                <tr>
                                    <th>Parlamentar</th>
                                    <th>Projetos</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                this.state.dadosResultadoParlaMaisApresProj !== undefined && Array.isArray(this.state.dadosResultadoParlaMaisApresProj) && this.state.dadosResultadoParlaMaisApresProj.length > 0 ?
                                this.state.dadosResultadoParlaMaisApresProj.map((dados, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{dados.nome !== null ? dados.nome : ''}</td>
                                            <td>{dados.qtd !== null ? dados.qtd : ''}</td>
                                        </tr>
                                    )
                                }) : null}
                            </tbody>
                        </table>  
                        <div className={this.state.dadosResultadoParlaMaisApresProj.length === 0 &&
                            this.state.pesquisouParlaMaisApresProj
                         ?
                             `p-3 d-flex justify-content-center mb-2 d-flex col-12 rounded`: `d-none`}
                             style={{backgroundColor: "rgba(86,61,124,.15)",
                                border: "1px solid rgba(86,61,124,.15)", fontSize: "18px"}}>
                                        A pesquisa não retornou resultados. 
                        </div>                                              
                    </div>                 
                </AccordionParlamentaresMaisApresentamProjetos>
            </div>    
             <div className="acordionsBordas mt-2">
                <AccordionParlamentaresMaisApresentamParidos
                    mb={'mb-0'}
                    p={'p-0'}
                    titulo="Partidos que mais apresentam projetos por posicionamento"                    
                >
                <hr/>
                    <div className='row p-4'>  
                        <div className="col-12 col-sm-3  col-lg-3 col-xl-3 mb-2 ">
                            <div className="label-insight">Data Inicial de Apresentação</div> 
                            <input id="data-inicial" className="data-inicial-ParlaMaisApresPartido"  type="date" onChange={dtIni => this.dataInicialParlaMaisApresPartido(dtIni)} />    
                        </div>
                        <div className="col-12 col-sm-3  col-lg-3 col-xl-3"> 
                            <div className="label-insight">Data Final de Apresentação</div>  
                            <input id="data-final" className="data-final-ParlaMaisApresPartido"  type="date" onChange={dtFim => this.dataFinalParlaMaisApresPartido(dtFim)}   />
                        </div>

                        <div className={this.state.casa_legislativaParlaMaisApresPartidoValue !== null && 
                            // this.state.ambitoParlaMaisApresPartidoValue !== null &&
                             this.state.partidoParlaMaisApresPartidoValue !== null && 
                            this.state.dataIniParlaMaisApresPartido !== null && this.state.dataFimParlaMaisApresPartido !== null ?
                            `${classButtonPesquisar}` : 'd-none'} >
                    
                            <img id="pesquisa-dash" className=""  src={require('../../design/assets/images/btn_pesquisar.svg')} alt="btn-pesquisar" onClick={() => this.enviaDataParlaMaisApresPartido()}  style={{cursor: 'pointer'}}/>
                            <img  id="limpar-dash" className="ml-3"  src={require('../../design/assets/images/btn_limpar.svg')} alt="btn-limpar" onClick={() => this.limparDatasParlaMaisApresPartido()} style={{cursor: 'pointer'}}/>
                        </div>
                    </div>

                    <div className='row justify-content-center'>
                        <div className="col-sm-6 mt-3">
                            <FormatInput
                                className="Client-select"
                                label="Casa Legislativa"
                                >
                                <ClientCheckboxWithSelect
                                value={casa_legislativaParlaMaisApresPartidoValue}
                                onChange={casa_legislativa => this.onChangeCasaLegislativaParlaMaisApresPartido(casa_legislativa)}
                                options={this.state.casa_legislativaSemCongresso.map(item => ({ value: item.id, label: item.value }) )}
                                    placeholder={
                                        "Escolha uma casa"}
                                />
                            </FormatInput>                         
                        </div>        
                        {/* <div className="col-sm-4 mt-3">
                            <FormatInput
                                css={this.state.casa_legislativaParlaMaisApresPartidoValue !== null ? "" : "label-disabled"}
                                className="Client-select"
                                label="Âmbito"
                                >
                                <ClientCheckboxWithSelect
                                value={ambitoParlaMaisApresPartidoValue}
                                onChange={ambito => this.onChangeAmbitoParlaMaisApresPartido(ambito)}
                                options={
                                this.state.dadosAmbitoParlaMaisApresPartido !== null && this.state.dadosAmbitoParlaMaisApresPartido !== undefined ? 
                                this.state.dadosAmbitoParlaMaisApresPartido.map((item,index) => ({ value: index, label: item.ambito })):null}
                                placeholder={
                                "Escolha um âmbito"}
                                disabled={this.state.casa_legislativaParlaMaisApresPartidoValue !== null ? false : true}
                                />
                            </FormatInput>                         
                        </div> */}
                        <div className="col-sm-6 mt-3">
                            <FormatInput
                            className="Client-select "
                            label="Posicionamento"
                            >
                            <ClientCheckboxWithSelect
                            value={partidoParlaMaisApresPartidoValue}
                            onChange={posicao => this.onChangePosicionamentoParlaMaisApresPartido(posicao)}
                            options={posicionamento.posicionamentosClient.map(item => ({ value: item.id, label: item.value }))}
                            placeholder={"Escolha um posicionamento"}
                            />
                            </FormatInput>                      
                        </div>
                        {/* <div className="col-sm-6 mt-3">
                            <FormatInput
                            className="Client-select "
                            label="Posicionamento"
                            >
                            <ClientCheckboxWithSelect
                            value={partidoParlaMaisApresPartidoValue}
                            onChange={posicao => this.onChangePosicionamentoParlaMaisApresPartido(posicao)}
                            options={this.state.dadosPartidoParlamentares.map((item,index) => ({ value: index, label: item.siglaPartido }))}
                            placeholder={"Escolha um partido"}
                            />
                            </FormatInput>                      
                        </div> */}
                        <div className={this.state.dadosResultadoParlaMaisApresPartido.length > 0 ? 
                            `card-grafico-parlamentar`: `d-none`}>
                             <div className="row justify-content-center">                         

                                <div className="col-sm-12-md-12 item-descricao2">
                                   Quantidade de Projetos por Partido
                                </div>
                            </div>
                             <div className="row justify-content-center">                         

                                <div className="col-sm-7-md-5">
                                    <GraficoParlaMaisAprePartido 
                                    dados={this.state.dadosResultadoParlaMaisApresPartido}
                                    />
                                </div>
                                <div className={this.state.dadosResultadoParlaMaisApresPartido.length > 0 ? `col-sm-4-md-6 mt-5` : `d-none`}>
                                {this.state.dadosResultadoParlaMaisApresPartido !== null ?
                                        this.state.dadosResultadoParlaMaisApresPartido.map((dado,index)=>
                                            index === 0 ?                                   
                                    <div className="row" key={index}>
                                        <div className="col-2">
                                            <div className="indice-exito mt-1"></div>
                                        </div>
                                        <div className="col-9">
                                            <div className=" d-inline">
                                            {' '+ dado.siglaPartido}                                                
                                            </div>
                                        </div>
                                    </div>
                                        :null
                                        )
                                    :null
                                    }  
                                {this.state.dadosResultadoParlaMaisApresPartido !== null ?
                                        this.state.dadosResultadoParlaMaisApresPartido.map((dado,index)=>
                                            index === 1 ?                                      
                                    <div className="row" key={index} >
                                        <div className="col-2">
                                            <div className="indice-sem-exito mt-1"></div>
                                        </div>
                                        <div className="col-9">
                                            <div  className=" d-inline">
                                            {dado.siglaPartido}                                                
                                            </div>
                                        </div>
                                    </div>
                                        :null
                                        )
                                    :null
                                    } 
                                    {this.state.dadosResultadoParlaMaisApresPartido !== null ?
                                    this.state.dadosResultadoParlaMaisApresPartido.map((dado,index)=>
                                        index === 2 ?                                                                         
                                    <div className="row" key={index} >
                                        <div className="col-2">
                                            <div className="indice-exito-parcial mt-1"></div>
                                        </div>
                                        <div className="col-9">
                                            <div className=" d-inline">
                                            {dado.siglaPartido}                                                
                                            </div>
                                        </div>
                                    </div>
                                        :null
                                        )
                                    :null
                                    }         
                                    {this.state.dadosResultadoParlaMaisApresPartido !== null ?
                                    this.state.dadosResultadoParlaMaisApresPartido.map((dado,index)=>
                                        index === 3 ?                                                                 
                                    <div className="row" key={index} >
                                        <div className="col-2">
                                            <div className="indice-pendente mt-1"></div>
                                        </div>
                                        <div className="col-9">
                                            <div className=" d-inline">
                                            {dado.siglaPartido}                                                
                                            </div>
                                        </div>
                                    </div>
                                        :null
                                        )
                                    :null
                                    } 
                                {this.state.dadosResultadoParlaMaisApresPartido !== null ?
                                this.state.dadosResultadoParlaMaisApresPartido.map((dado,index)=>
                                    index === 4 ?                                                                       
                                    <div className="row" key={index} >
                                        <div className="col-2">
                                            <div className="indice-contrario-emenda mt-1"></div>
                                        </div>
                                        <div className="col-9">
                                            <div className=" d-inline">
                                            {dado.siglaPartido}                                                                                                
                                            </div>
                                        </div>
                                    </div>
                                           :null
                                           )
                                       :null
                                       } 
                                </div>
                            </div>
                        </div>
                        <table className={
                            this.state.dadosResultadoParlaMaisApresPartido !== undefined && 
                            this.state.dadosResultadoParlaMaisApresPartido !== null ?
                            this.state.dadosResultadoParlaMaisApresPartido.length > 0 ?
                             `table table-striped table-responsive-sm`:`d-none`: `d-none`}>
                            <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                <tr>
                                    <th>Parlamentar</th>
                                    <th>Projetos</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                this.state.dadosResultadoParlaMaisApresPartido !== undefined && Array.isArray(this.state.dadosResultadoParlaMaisApresPartido) && this.state.dadosResultadoParlaMaisApresPartido.length > 0 ?
                                this.state.dadosResultadoParlaMaisApresPartido.map((dados, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{dados.siglaPartido !== null ? dados.siglaPartido : ''}</td>
                                            <td>{dados.qtd !== null ? dados.qtd : ''}</td>
                                        </tr>
                                    )
                                }) : null}
                            </tbody>
                        </table>  
                        <div className={this.state.dadosResultadoParlaMaisApresPartido.length === 0 &&
                            this.state.pesquisouParlaMaisApresPartido
                         ?
                             `p-3 d-flex justify-content-center mb-2 d-flex col-12 rounded`: `d-none`}
                             style={{backgroundColor: "rgba(86,61,124,.15)",
                                border: "1px solid rgba(86,61,124,.15)", fontSize: "18px"}}>
                                        A pesquisa não retornou resultados. 
                        </div>                                              
                    </div>                 
                </AccordionParlamentaresMaisApresentamParidos>
            </div>    
        </div>
                    
        )
    }
}

export default InsightParlamentar