import { Component } from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import { get } from "../../services/http"
import { withRouter } from "react-router-dom"
import { inject } from "mobx-react"

@withRouter
@inject("authStore")
class Query extends Component {
    static propTypes = {
        query: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.array.isRequired
        ]),
        variables: PropTypes.object,
        children: PropTypes.func.isRequired,
        normalize: PropTypes.func
    }

    static defaultProps = { normalize: data => data }

    state = { loaded: false, fetching: false, data: null, error: null }

    constructor(props) {
        super(props)
        this.query = this.query.bind(this)
    }

    componentDidMount = () => {
        this._isMounted = true
        this.query()
    }

    componentDidUpdate = prevProps => {
        if (
            !isEqual(this.props.query, prevProps.query) ||
            !isEqual(this.props.variables, prevProps.variables)
        ) {
            this.query()
        }
    }

    componentWillUnmount = () => (this._isMounted = false)

    query = async () => {
        this.setState({ fetching: true })
        try {
            let data = await (Array.isArray(this.props.query)
                ? Promise.all(
                    this.props.query !== undefined && Array.isArray(this.props.query) && this.props.query.length > 0 ?
                    this.props.query.map(get) : null
                    )
                : get(this.props.query))

            this.safeSetState({
                data: this.props.normalize(data),
                error: null,
                loaded: true,
                fetching: false
            })
        } catch (error) {
            console.error(error)
            this.safeSetState({
                error,
                data: null,
                loaded: false,
                fetching: false
            })
            if (error && error.response && error.response.status === 400) {
                this.props.authStore.logout()
                this.props.history.push("/")
            }
        }
    }

    safeSetState = (...args) => this._isMounted && this.setState(...args)

    render = () => this.props.children({ ...this.state, reload: this.query })
}

export default Query
