import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
// import './tooltip-notification.css'
import moment from 'moment-timezone';
import notificacaoStore from '../../../../stores/notificacaoStore'
import Notificacao  from '../../notificacoesMobile'

moment.tz.setDefault('UTC');

@inject( 'paginacaoStore')
@observer

class TooltipNotification extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props
    if (oldProps.show !== newProps.show) this.setState({ show: newProps.show })

  }
  toggleLida = async (item) => {
    const lida = !item.lida
    await notificacaoStore.alterarLida(item.id, lida)
    this.setState({ show: false })
  }

  fromNow = data => {
    const Y = moment(data).year()
    const M = moment(data).month()
    const D = moment(data).date()
    const HH = moment(data).hour()
    const MM = moment(data).minute()
    const SS = moment(data).second()
    return moment([Y, M, D, HH, MM, SS]).fromNow()
  }

  render() {
    // console.log(notificacaoStore)
    // console.log(notificacaoStore.notificacoes)
    // console.log(notificacaoStore.notificacoes.itens)
    // console.log(notificacaoStore.notificacoes.itens.length)

    return (
      <Fragment>
        <article 
            onMouseLeave={() => { this.setState({ show: false }, () => this.props.onClose(this.state.show)) }} 
            className={this.state.show ? `tooltip-notification show` : `tooltip-notification hide`} 
            onScroll={this.props.paginacaoStore.aoRolar}
        >
          
          <div className='notification-container'>
          <h6 className="ml-4">Notificações </h6>
            {notificacaoStore && notificacaoStore.notificacoes && notificacaoStore.notificacoes.itens && notificacaoStore.notificacoes.itens.length > 0 ?
                    <div className="">
                      <div className='infos '>
                        <Notificacao />
                      </div>   
                  </div>  : <span>Nenhuma notificação</span>
            }    
          </div>
          {/* <NavLink to='/notificacoes' onClick={() => { this.setState({ show: false }, () => this.props.onClose(this.state.show)) }}>
            <p className='link'>Ver todas</p>
          </NavLink> */}
        </article>
      </Fragment>
    )
  }
}

export default TooltipNotification