import React, { Component } from 'react'
import Loading from '../../componentes/loading'
import Accordion from '../../componentes/accordion'
import { get } from '../../services/http'
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

class ParlamentarOutros extends Component {
    state = { 
        loading: true,
        parlamentar: {}, 
       
        mandatos: [], 
        historicoLider: [], 
        frentes: [], 
        eventos: [], 
        situacaoMandato:[],
        primeiroMandato:[],
        ultimoMandato: {},
        
        showModalMandato: false,
        showModalHistorico: false,
        showModalFrentes: false,
        showModalEventos: false,
    }

    async componentDidMount() {
        await this.getParlamentarDados()
    }

    async getParlamentarDados() {
        const idParlamentar  = this.props.idParlamentar
        await get(`parlamentar/outros/${idParlamentar}`)
        .then(response =>{
            this.setState({
                mandatos: response.mandatos,
                historicoLider: response.historico,
                frentes: response.frentes,
                eventos: response.eventos,
                loading: false,
                situacaoMandato:response.dadosParlamentar,
                primeiroMandato: response.mandatos.shift(),
                ultimoMandato: response.mandatos.slice(-1)[0]
            })

            
            //console.log('===>>',this.state.situacaoMandato[0].situacao)

            //const [dados]  = this.state.situacaoMandato

            // console.log('===>>',dados.situacao)console.log('===>>',dados.situacao)

           //const [situacao] = this.state.situacaoMandato.map(item => item.situacao)

           //console.log('===>>',this.state.situacaoMandato[0].situacao )

            
        })
    }

    

    changeShowModalStateMandato(showModalMandato) {
        this.setState({ showModalMandato })
    }

    changeShowModalStateHistorico(showModalHistorico) {
        this.setState({ showModalHistorico })
    }

    changeShowModalStateFrentes(showModalFrentes) {
        this.setState({ showModalFrentes })
    }

    changeShowModalStateEventos(showModalEventos) {
        this.setState({ showModalEventos })
    }
    

    render() {
        
        // console.log('===>>>>', this.state.primeiroMandato.partido)

        const linhaAtiva = this.state.mandatos.length  > 0 ? "timeline-linha" : 'timeline-linha-primeiro'
        return (
            
            this.state.loading ? <Loading /> : 
            <div>
                <div className="row p-2 background-parlamentar-card">
                    <div className="col-12 p-3">

                        <Accordion 
                            mb={''}
                            onClick={() => this.changeShowModalStateMandato(true)}
                            titulo={'Mandatos'} 
                            aberto={this.state.showModalMandato}
                        >
                           
                    <div className="container-global-timeline">
                                      
                            {this.state.situacaoMandato[0].situacao === 'Exercício' ?
                                this.state.primeiroMandato !== undefined ? 
    
                                    <div  className="timeline">                                 
                                        
                                        <div className={`${linhaAtiva}`}  >
                                            <div className="timeline-ativa"></div>
                                        </div>
                                        
                                        
                                        <div className="container-mandato">
                                            <div className="mandato-ativo">{moment(this.state.primeiroMandato.data_inicio).format('YYYY')}-{moment(this.state.primeiroMandato.data_fim).format('YYYY')}</div>
                                            <div className="container-ativo right">
                                                <div className="conteudo-ativo">
                                                    <span className="conteudo-titulo ">{this.state.primeiroMandato.cargo}</span>
                                                    <span className="conteudo-descricao  text-wrap">{this.state.primeiroMandato.uf !== undefined && this.state.primeiroMandato.uf !== null && this.state.primeiroMandato.uf !== '' ? this.state.primeiroMandato.uf + ', ' : ''} {this.state.primeiroMandato.partido !== undefined && this.state.primeiroMandato.partido !== null && this.state.primeiroMandato.partido !== '' ? this.state.primeiroMandato.partido + ' - ' : ''} Data de Posse: {moment(this.state.primeiroMandato.data_inicio).format('DD/MM/YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                    : <p>Não existe registro de dados para esse parlamentar!</p> 
                            
                            : <p>Não existe registro de dados para esse parlamentar!</p>}

                            {Array.isArray(this.state.mandatos) && 
                            this.state.mandatos !== undefined &&
                            this.state.mandatos.length > 0 ?
                             this.state.mandatos.map((item, index) => {
                              
                                return(

                                    
                                    this.state.ultimoMandato.data_inicio  !== item.data_inicio ?
                                    <div key={index} className="timeline">                                   
                                        
                                        <div className="timeline-linha">
                                            <div className="timeline-inativo"></div>
                                        </div>
                                      
                                        
                                        <div className="container-mandato">
                                            <div className="mandato-inativo">{moment(item.data_inicio).format('YYYY')}-{moment(item.data_fim).format('YYYY')}</div>
                                            <div className="container-inativo right">
                                                <div className="conteudo-inativo">
                                                    <span className="conteudo-titulo ">{item.cargo}</span>
                                                    <span className="conteudo-descricao  text-wrap">{item.uf}, {item.partido} - Dt. de Posse: {moment(item.data_inicio).format('DD/MM/YYYY')}</span>
                                                </div>
                                            </div>
                                       </div>
                                           
                                    </div>
                                    
                                    :
                                    <div key={index} className="timeline">
                                        
                                        <div className="timeline-linha-ultimo">
                                            <div className="timeline-inativo"></div>
                                        </div>

                                        <div className="container-mandato">
                                            <div className="mandato-inativo">{moment(item.data_inicio).format('YYYY')}-{moment(item.data_fim).format('YYYY')}</div>
                                            <div className="container-inativo right">
                                                <div className="conteudo-inativo">
                                                <span className="conteudo-titulo">{item.cargo}</span>
                                                <span className="conteudo-descricao">{item.uf}, {item.partido} - Dt. de Posse: {moment(item.data_inicio).format('DD/MM/YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    )
                                })
                            : null }

                           
                    </div>
                  
                        </Accordion>

                        <Accordion 
                            mb={''}
                            onClick={() => this.changeShowModalStateHistorico(true)}
                            titulo={'Histórico como Líder'} 
                            aberto={this.state.showModalHistorico}
                        >
                            {Array.isArray(this.state.historicoLider) && this.state.historicoLider.length > 0 ? this.state.historicoLider.map((item, index) => {
                                return (
                                    <div key={index} className="row p-3 parlamentar-card-historico mt-3 p-2">
                                        <div className="col-12">
                                            <div className="parlamentar-card-historico-partido">
                                                {item.partido}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="parlamentar-card-outros-historico-titulo">
                                                Posição
                                            </div>
                                            <div>
                                                {item.posicao}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mt-3">
                                            <div className="parlamentar-card-outros-historico-titulo">
                                                Período
                                            </div>
                                            <div>
                                                {moment(item.data_inicio).format('DD/MM/YYYY')} a {moment(item.data_fim).format('DD/MM/YYYY')}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <div className="ml-5">Não existe registro de dados para esse parlamentar!</div> }



                        </Accordion>

                        <Accordion 
                            mb={''}
                            onClick={() => this.changeShowModalStateFrentes(true)}
                            titulo={'Frentes'} 
                            aberto={this.state.showModalFrentes}
                        >
                        
                        {Array.isArray(this.state.frentes) && this.state.frentes.length > 0 ? this.state.frentes.map((item, index) => {
                           //console.log('===>',this.state.frentes)
                           return (
                                <div key={index} className="row p-3 parlamentar-card-frentes mt-3">

                                    <div className="col-12 ">
                                        <div className="parlamentar-card-outros-frentes-titulo">
                                            Legislatura
                                        </div>
                                        <div>
                                           {item.legislatura}
                                        </div>
                                    </div>

                                    <div className="col-12 mt-3">
                                        <div>
                                            {item.descricao}
                                        </div>
                                    </div>

                                </div>
                           )
                        }) :<div className="ml-5">Não existe registro de dados para esse parlamentar!</div> }
                            
                        </Accordion>

                        <Accordion 
                            mb={''}
                            onClick={() => this.changeShowModalStateEventos(true)}
                            titulo={'Eventos'} 
                            aberto={this.state.showModalEventos}
                        >

                        {Array.isArray(this.state.eventos) && this.state.eventos.length > 0 ? this.state.eventos.map((item,index)=>{
                            //console.log('===>',this.state.eventos)
                           return(

                            <div key={index}className="row p-3 parlamentar-card-eventos mt-3">

                                <div className="col-12">
                                    <div className="parlamentar-card-outros-eventos">
                                            {moment(item.data).format('DD/MM/YYYY')}                                        
                                    </div>
                                </div>

                                <div className="col-sm-6 mt-3">
                                    <div className="parlamentar-card-outros-eventos-titulo">
                                        Evento
                                    </div>
                                    <div>
                                        {item.evento.replace(/<(?!br\s*\/?)[^>]+>/g, '')}
                                    </div>
                                </div>

                                <div className="col-sm-6 mt-3">
                                    <div className="parlamentar-card-outros-eventos-titulo">
                                        Status
                                    </div>
                                    <div>
                                        {item.status}
                                    </div>
                                </div>

                            </div>


                            )
                        }) : <div className="ml-5">Não existe registro de dados para esse parlamentar!</div> }
                            
                        </Accordion>

                    </div>
                </div>
            </div>
        )
    }
}

export default ParlamentarOutros;