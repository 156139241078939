import './design/framework/app.css'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'

// import * as serviceWorker from './serviceWorker'

import App from './main/App'

import authStore from './stores/authStore'
import alertaStore from './stores/alertaStore'
import menuStore from './stores/menuStore'
import modalStore from './stores/modalStore'
import seguirStore from './stores/seguirStore'
import pesquisarStore from './stores/pesquisarStore'
import paginacaoStore from './stores/paginacaoStore'
import breadcrumbStore from './stores/breadcrumbStore'
import notificacaoStore from './stores/notificacaoStore'
import uploadStore from './stores/uploadStore'

//import 'bootstrap/dist/css/bootstrap.min.css';

const stores = {
    authStore,
    alertaStore,
    menuStore,
    modalStore,
    uploadStore,
    seguirStore,
    pesquisarStore,
    paginacaoStore,
    breadcrumbStore,
    notificacaoStore,
}

// Para o debugger
window._____APP_STATE_____ = stores
window._____ENV_____ = process.env

ReactDOM.render(
    <Provider {...stores}>
        <App />
    </Provider>
    , document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
