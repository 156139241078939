import React from 'react';
// import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Grid from '@material-ui/core/Grid';
// import SelectFiltro from '../../componentes/forms/basic/selectFiltro'
// import AuthStore from '../../stores/authStore'

// import Autocomplete from '@material-ui/lab/Autocomplete';

import GraficoInfoCasasLegislativas from '../graficos/graficosInfoCasasLegislativasProposicao'
import GraficoInfoUniversoProposicao from '../graficos/graficoInfoUniversoProposicao'



export default function FormDialog(props) {

    const [openAtiva, setOpenAtiva] = React.useState(false);
    const [openInativa, setOpenInativa] = React.useState(false);
    const tamanhoTela = window.innerWidth;
    // const [estadoGrafico, estado] = React.useState(true);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpenAtiva = () => {
        setOpenAtiva(true);
    };

    const handleCloseAtiva = () => {
        setOpenAtiva(false);
    }

    const handleClickOpenInativa = () => {
        setOpenInativa(true);
    };

    const handleCloseInativa = () => {
        setOpenInativa(false);
    }



    const selectFiltroProposicao = (e, text, path, type) => {
        window.localStorage.removeItem('filtroProposicao')
        // this.setState({loading: true})
        // console.log('selecao ===>', text, 'do grafico =>', path)
        const data = {
            path: path,
            text: text,
            grafico: false,
            type: type
        }
        window.localStorage.removeItem('filtroProposicao')
        window.localStorage.setItem('filtroGrafico', JSON.stringify(data))
        window.location.reload()
    }

    const tamanhoScreen = window.innerWidth

    return (
        <div>
            <div>
                <span className="d-flex justify-content-center mb-3"><b className="dashboard-graficos-titulo">Universo</b></span>
                <div className={`d-flex justify-content-center flex-wrap`}>

                    <GraficoInfoUniversoProposicao
                        emTramitacao={props.ativas}
                        inativas={props.inativas}
                    />
                    <div className="div-indice">
                        <div className="d-flex">
                            <div className="indice-exito mt-1 mr-2"></div>
                            <div className="mr-2 link-graficos-legenda-proposicao" onClick={handleClickOpenAtiva} style={tamanhoScreen <= 500 ? {fontSize:"13px"} : {}}>Ativos</div>
                            <div>
                                {props.ativas}
                            </div>
                        </div>
                        <div className="d-flex mt-2 mb-2">
                            <div className="indice-sem-exito mt-1 mr-2"></div>
                            <div className="mr-2  link-graficos-legenda-proposicao" onClick={handleClickOpenInativa} style={tamanhoScreen <= 500 ? {fontSize:"13px"} : {}}>Inativas</div>
                            <div>
                                {props.inativas}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Dialog open={openAtiva} fullScreen={fullScreen} onClose={handleCloseAtiva} aria-labelledby="form-dialog-title" >

                <DialogContent
                    style={{
                        width: tamanhoTela > 600 ? 600 : tamanhoTela,
                        backgroundColor: "white",
                        color: '#3E4049'
                    }}
                >
                    <div className="row">
                        <div className="container-grafico">
                            <div className="container-grafico-titulo-universo">
                                <p className="titulo-grafico-universo"><b>Detalhamento de gráfico / </b><span className="titulo-universo"><b>Universo</b></span></p>
                            </div>
                            <div onClick={handleCloseAtiva} style={{ cursor: 'pointer' }}>
                                <div className="fechar-modal-grafico-universo">
                                    <img id="fechar-modal" src={require('../../../design/assets/images/fechar-modal.svg')} alt="fechar-modal" />
                                </div>
                            </div>
                        </div>


                        <div className="graficos-ativo-inativo" >
                        </div>
                        <div className="mt-4">
                            <span className="d-flex justify-content-center mb-3"><b className="dashboard-graficos-titulo">Casas Legislativas Ativas</b></span>
                            <div className="d-flex">
                                <div className={`d-flex justify-content-center flex-wrap`}>
                                    <GraficoInfoCasasLegislativas
                                        propCamara={props.CDAtivo}
                                        propSenado={props.SFAtivo}
                                        propCongresso={props.CNAtivo}
                                    />

                                    <div className="div-indice">

                                        <div className="d-flex">
                                            <div className="indice-exito mt-1 mr-2"></div>
                                            <div className="d-flex">
                                                <div onClick={event => selectFiltroProposicao(event, 'CD', 'casa', 'ativo')} className="mr-2 dasboard-graficos-legenda-proposicao link-graficos-legenda-proposicao">Câmara dos Deputados</div>
                                            </div>

                                        </div>

                                        <div className="d-flex mt-2">
                                            <div className="indice-sem-exito mt-1 mr-2"></div>
                                            <div className="d-flex">
                                                <div onClick={event => selectFiltroProposicao(event, 'SF', 'casa', 'ativo')} className="mr-2 dasboard-graficos-legenda-proposicao link-graficos-legenda-proposicao">Senado Federal</div>
                                            </div>
                                        </div>

                                        <div className="d-flex mt-2 mb-2">
                                            <div className="indice-exito-parcial mt-1 mr-2"></div>
                                            <div className="d-flex">
                                                <div onClick={event => selectFiltroProposicao(event, 'CN', 'casa', 'ativo')} className="mr-2 dasboard-graficos-legenda-proposicao link-graficos-legenda-proposicao">Congresso Nacional</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={openInativa} fullScreen={fullScreen} onClose={handleCloseInativa} aria-labelledby="form-dialog-title" >
                <DialogContent
                    style={{
                        width: tamanhoTela > 600 ? 600 : tamanhoTela,
                        backgroundColor: "white",
                        color: '#3E4049'
                    }}
                >
                    <div className="row">
                        <div className="container-grafico">
                            <div className="container-grafico-titulo-universo">
                                <p className="titulo-grafico-universo"><b>Detalhamento de gráfico / </b><span className="titulo-universo"><b>Universo</b></span></p>
                            </div>
                            <div onClick={handleCloseInativa} style={{ cursor: 'pointer' }}>
                                <div className="fechar-modal-grafico-universo">
                                    <img id="fechar-modal" src={require('../../../design/assets/images/fechar-modal.svg')} alt="fechar-modal" />
                                </div>
                            </div>
                        </div>


                        <div className="graficos-ativo-inativo" >
                        </div>
                        <div className="mt-4">
                            <span className="d-flex justify-content-center mb-3"><b className="dashboard-graficos-titulo">Casas Legislativas Inativas</b></span>
                            <div className="d-flex">
                                <div className={`d-flex justify-content-center flex-wrap`}>
                                    <GraficoInfoCasasLegislativas
                                        propCamara={props.CDInativo}
                                        propSenado={props.SFInativo}
                                        propCongresso={props.CNInativo}
                                    />

                                    <div className="div-indice">

                                        <div className="d-flex">
                                            <div className="indice-exito mt-1 mr-2"></div>
                                            <div className="d-flex">
                                                <div onClick={event => selectFiltroProposicao(event, 'CD', 'casa', 'inativo')} className="mr-2 dasboard-graficos-legenda-proposicao link-graficos-legenda-proposicao">Câmara dos Deputados</div>
                                            </div>

                                        </div>

                                        <div className="d-flex mt-2">
                                            <div className="indice-sem-exito mt-1 mr-2"></div>
                                            <div className="d-flex">
                                                <div onClick={event => selectFiltroProposicao(event, 'SF', 'casa', 'inativo')} className="mr-2 dasboard-graficos-legenda-proposicao link-graficos-legenda-proposicao">Senado Federal</div>
                                            </div>
                                        </div>

                                        <div className="d-flex mt-2 mb-2">
                                            <div className="indice-exito-parcial mt-1 mr-2"></div>
                                            <div className="d-flex">
                                                <div onClick={event => selectFiltroProposicao(event, 'CN', 'casa', 'inativo')} className="mr-2 dasboard-graficos-legenda-proposicao link-graficos-legenda-proposicao">Congresso Nacional</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </DialogContent>

            </Dialog>
        </div>
    );
}

