// import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect } from 'react';
import { get } from '../../../services/http';

export default function FormDialog(props) {

    const [open, setOpen] = React.useState(false);
    const [textoBase, setTextoBase] = React.useState("");
    const [mensagemSemTexto] = React.useState("Não existe texto base para esta proposição");


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        //DidMount
        getTextoBaseLink();

        //WillMount
        return () => {

        }
        // Atualiza o titulo do documento usando a API do browser
        // document.title = `Você clicou ${count} vezes`;
    }, []);

    const getTextoBaseLink = async () => {
        await get(`proposicao/${props.id}/texto`)
            .then(res => {
                //   console.log(res)
                const dados = res || []
                setTextoBase(
                    dados.texto !== "" ? dados.texto : mensagemSemTexto
                );
            })
            .catch(err => err)
    }



    return (

        <div>
            <div onClick={handleClickOpen} style={{ cursor: 'pointer' }} className="header d-flex align-items-start">
                <div className="title" >
                    <p style={{ cursor: "pointer" }} className="mb-3 text-break ">{props.ementa}</p>
                    {/* <span className='Client-titulo-card'>{props.item.tipo} {props.item.numero} {props.item.comissao_sigla}</span> */}
                    {/* <span className="Client-cinza-claro fs-16 font-weight-bold">{moment(props.item.data_apresentacao).format('DD/MM/YYYY')}</span> */}
                </div>
            </div>

            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title" >


                <DialogContent
                    style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: "white",
                        color: '#3E4049'
                    }}
                // <p onClick={this.getTextoBaseLink} style={{cursor:"pointer"}} className="mb-3 text-wrap "></p>

                >

                    <div className="p-3">
                        <div>

                            <div>
                                <h4>{props.title}</h4>
                                <div>

                                    <hr />

                                </div>
                                <p className="text-break">{textoBase}</p>
                            </div>


                        </div>


                        {/* <div>
                            {props.item.texto}
                        </div> */}

                        <div className="text-center p-3 botaoFecharModalEmendas" onClick={handleClose}>
                            FECHAR
                        </div>
                    </div>



                </DialogContent>

            </Dialog>
        </div >

    );
}