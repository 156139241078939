import { observable, action, computed, reaction } from 'mobx'
import { http, put, post } from '../services/http'
import permissao from "../enums/permissoes";
import alertaStore from './alertaStore'
import { set as localStorageSetItem, get as localStorageGetItem, remove as localStorageRemoveItem } from '../services/local'
import Amplify from 'aws-amplify';
import Auth from '@aws-amplify/auth';
import { loginAuth } from '../../src/helpers/loginAuth'
import md5 from 'md5'
//import { useClearCache } from "react-clear-cache";
//import {parse} from 'himalaya'

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:eb698c35-0c77-4b25-841f-8b77e0837226",
    region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    identityPoolRegion: process.env.REACT_APP_AWS_REGION || 'us-east-1',
    userPoolId: "us-east-1_1GDAt3Uhs",
    userPoolWebClientId: "2t83hnujps8rei3kpaat4d4hln"
  }
})

class AuthStore {
  executarDepoisDeAutenticar = []
  executarAoSair = []
  dominioDoCognito = 'https://purpleClient.auth.us-east-1.amazoncognito.com'
  @observable usuarioCognito = null
  @observable user = localStorageGetItem('user')
  @observable loading = false
  constructor() { reaction(() => this.user, user => (user) ? localStorageSetItem('user', user) : localStorageRemoveItem('user')) }
  @computed
  get authenticated() { return this.user && this.user.token !== null }
  @computed
  get id() { return this.user && (this.user.id || null) }
  @computed
  get nome() { return this.user && (this.user.nome || null) }
  @computed
  get idDoCliente() { return this.user && (this.user.idDoCliente || null) }
  @computed
  get ehAdministrador() { return this.user && this.user.idPermissao === permissao.ADMINISTRADOR }
  @computed
  get podeEditar() { return this.user && this.user.idPermissao === permissao.PODE_EDITAR }
  @computed
  get ehVisualizador() { return this.user && this.user.idPermissao === permissao.PODE_VER }
  @computed
  get ehEsforcoColaborativo() { return this.user && this.user.idPermissao === permissao.ESFORCO_COLABORATIVO }
  @computed
  get tipoDeAutenticacao() { return this.user && this.user.token && this.user.token.split(' ')[0] }
  @computed
  get podeRedefinirSenha() { return this.tipoDeAutenticacao === 'cognito' }
  @computed
  get deveCriarNovaSenha() { return this.usuarioCognito && this.usuarioCognito.challengeName === 'NEW_PASSWORD_REQUIRED' }
  @computed
  get parametrosDoSaml() {
    const clientId = '2t83hnujps8rei3kpaat4d4hln'
    const scope = 'openid+email+profile+phone+aws.cognito.signin.user.admin'
    let config;
    if (window.location.hostname.startsWith('dev.purpleClient'))
      config = { redirect: 'https://dev.purpleClient.com.br', idpIdentifier: 'CA-IDP_Novo' }
    else if (window.location.hostname.startsWith('staging.purpleClient'))
      config = { redirect: 'https://staging.purpleClient.com.br', idpIdentifier: 'CA-IDP_Novo' }
    else if (window.location.hostname.startsWith('portal.purpleClient'))
      config = { redirect: 'https://portal.purpleClient.com.br', idpIdentifier: 'LegisIDP' }
    else config = { redirect: 'https://dev.purpleClient.com.br', idpIdentifier: 'CA-IDP_Novo' }

    return `?response_type=token&client_id=${clientId}&redirect_uri=${config.redirect}&scope=${scope}&idp_identifier=${config.idpIdentifier}`
  }

  @action aoAutenticar(fn) { this.executarDepoisDeAutenticar.push(fn) }
  @action aoSair(fn) { this.executarAoSair.push(fn) }
  @action zerar() { this.usuarioCognito = null }

  @action
  async alterarSenha({ senhaAtual, novaSenha }) {
    const usuario = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(usuario, senhaAtual, novaSenha)
  }

  @action
  loginCognito = async (props) => {
    this.loading = true;
    let resposta
    let playerId = '';
    //try { playerId = localStorage.getItem('PushNotificationPlayerId') } catch { }
    const data = { email: props.data.username, password: md5(props.data.password), login: loginAuth(), PlayerId: playerId }
    await post(`/login`, data)
      .then(async response => {

        if (response === undefined) {
          this.loading = false;
          return alertaStore.erro('Usuário ou senha incorretos!')
        } else {
          resposta = response
          this.usuarioCognito = response
          if (this.usuarioCognito.message) return alertaStore.erro("Dados incorretos! Tente novamente")
          // let token = this.usuarioCognito
          this.autenticar(resposta).catch(e => this.tratamentoDeErro(e))
          this.loading = false;
        }
      })
      .catch(error => {
        console.log(error)
      })



    // this.usuarioCognito = await Auth.signIn(props.data.username, props.data.password)
    //   .then(u => u)
    //   .catch(e => this.tratamentoDeErro(e));
    // if (this.deveCriarNovaSenha) return alertaStore.aviso("O usuário deve alterar a sua senha")

    // let token = this.usuarioCognito

    // if (this.usuarioCognito.message) return alertaStore.erro("Usuário não encontrado na base")
    // await this.autenticar(resposta).catch(e => this.tratamentoDeErro(e))
    // this.loading = false;

    // if (this.usuarioCognito.message) return alertaStore.erro("Usuário não encontrado na base")
    // await this.autenticar(resposta).catch(e => this.tratamentoDeErro(e))
    // this.loading = false;
  }

  @action
  loginAD = async (props) => {
    this.loading = true;
    try {
      this.usuarioCognito = await post('/usuario/ad', { username: props.data.username, password: props.data.password })
        .then(u => u)
        .catch(e => this.tratamentoDeErro(e))

      // if (!this.usuarioCognito) return alertaStore.erro("Usuário não encontrado na base");

      const token = this.usuarioCognito && this.usuarioCognito.token;
      await this.autenticar({ token }).catch(e => this.tratamentoDeErro(e))
    } catch (e) { alertaStore.erro("Ops..." + e && e.message ? e.message : e) }
    this.loading = false
  }

  @action
  getUserByToken = async (token) => {
    const response = await post('/token', { token: token });
    if (response) {
      response.token = token;
      response.status = 200;
    }
    return response;
  }

  @action
  autenticar = async (token) => {
    this.loading = true;
    try {
      // let usuarioPremise = await http({ method: 'get', url: 'usuario/eu', headers: { Authorization: token } })
      //   .then(user => user && user.data)
      //   .catch(e => this.tratamentoDeErro(e))

      // if (!usuarioPremise) return new Error('Ops... usuário não encontrado no purpleClient.');
      // if (usuarioPremise.situacao !== 1) return new Error('Ops... usuário desabilitado');

      // this.user = { ...usuarioPremise, token }
      this.user = token
      // await localStorageSetItem('user', { ...usuarioPremise, token })
      await localStorageSetItem('user', token)
    }
    catch (e) { alertaStore.erro("Ops..." + e && e.message ? e.message : e) }

    finally {

      if (this.user) {
        this.executarDepoisDeAutenticar.forEach(acao => acao(this.user))
        window.location.reload()
      }
      this.loading = false;
    }
  }

  @action
  async loginSSOporCliente(email) {
    //const { emptyCacheStorage } = useClearCache();
    this.loading = true;
    //if(email) {

    //const result = await http({ method: 'get', url: `${process.env.REACT_APP_API_NOVA}/login`, responseType: 'text' });
    //console.log(result.request.responseURL);
    //console.log(result.request.responseURL);
    //window.location = 'https://www.uol.com.br'
    //window.location = result.request.responseURL;
    //Event.preventDefault()
    //emptyCacheStorage(); 
    //window.location = `${process.env.REACT_APP_API_NOVA}/login`;
    //window.location = `https://dev.legisnote.com.br/api/login`;
    window.location = process.env.REACT_APP_SSO_REDIRECT; //  'https://idhibrida.prebanco.com.br/adfs/ls/idpinitiatedsignon.aspx?LoginToRP=https://dev.legisnote.com.br/api/login/callback';  
    //window.location.href='https://dev.legisnote.com.br/api/login';
    /*
          try {
            const json = parse(result.data);
            const SAMLResponse = json[0].children[1].children[0].children[0].attributes[2].value;
            if (SAMLResponse && SAMLResponse !== '' && SAMLResponse !== null) {
              const token = await http.post(`https://dev.legisnote.com.br/api/login/callback`, { SAMLResponse }).catch(err => console.log('Post err:',err))
              this.autenticar( token.data);
              window.location.href = 'http://localhost:3000';
            } else {
              window.location.href = result.request.responseURL;  
            }
          } catch {      
            window.location.href = result.request.responseURL;
          }
    */
    //

    // token.data.replace('<token>','')
    // token.data.replace('</token>','')

    this.loading = false;


    // }
    //return window.location.href = `http://localhost:4001/login`; //https://idhibrida.prebanco.com.br/adfs/ls/idpinitiatedsignon.aspx?LoginToRP=https://dev.legisnote.com.br/api/login&UserName=${email}`;
    //return window.location.href = `${this.dominioDoCognito}/authorize${this.parametrosDoSaml}`;


    return this.tratamentoDeErro(new Error('Cliente não encontrado.'))
  }


  @action
  async Mslogin() {
    const res = await http({ method: 'get', url: 'mslogin' });
    return res
  }


  @action
  async loginSSOcomHash() {
    this.loading = true;
    try {
      const hash = window.location.hash.replace('#', '').split('&').map(x => x.split('=')).reduce((p, [c, v]) => ({ ...p, [c]: v }), {})
      // console.log('HASH PARAMS => ', hash)
      await http({ method: 'post', url: 'usuario/saml', headers: { Authorization: hash.id_token } })
      this.autenticar({ token: hash.id_token })
    }
    catch (erro) { console.error(erro); alertaStore.criar('Não foi possível identificar usuário.', 'erro'); }
    finally { this.loading = false; window.history.pushState("", document.title, window.location.pathname + window.location.search); return true }
  }

  @action
  criarNovaSenha = async ({ password: novaSenha }) => {
    try {
      const usuario = this.usuarioCognito
      const { accessToken: { jwtToken } } = await criarNovaSenhaNoCognito({ usuario, novaSenha });
      await http({ method: 'put', url: 'usuario/ativar', headers: { Authorization: jwtToken } });
      await this.autenticar({ token: jwtToken })
    } catch (erro) { console.log(erro); this.tratamentoDeErro(erro); return false; }
  }

  tratamentoDeErro(erro) {
    this.loading = false;
    const { message } = erro
    alertaStore.criar(errosDoCognito(message) || message, 'erro')
  }

  @action
  async logout(redirectUri='/') {
    this.user = null
    localStorage.removeItem('quantidadeNotificacao')
    localStorage.removeItem('APP_VERSION')
    localStorage.removeItem('dominio')
    localStorage.removeItem('user')
    localStorage.removeItem('filtroGrafico')
    window.localStorage.clear()
    // localStorageRemoveItem('user')
    // this.executarAoSair.forEach(acao => acao(this.user))
    // if (this.tipoDeAutenticacao === 'player1')
    //   window.location.href = `${this.dominioDoCognito}/logout${this.parametrosDoSaml.replace('redirect_uri', 'logout_uri')}`
    // window.location.reload()
    if (process.env.REACT_APP_SSO_LOGOUT && process.env.REACT_APP_SSO_REDIRECT && process.env.REACT_APP_SSO_REDIRECT !== '') {
      const res = await http({ method: 'get', url: process.env.REACT_APP_SSO_LOGOUT });
      if (res && res.data && res.data !== '') {
        // console.log(res.data);
        window.location = res.data;// process.env.REACT_APP_SSO_LOGOUT_REDIRECT
      }
      //  else {
      //   window.location.href = '/?invalid=1'
      // }
    }
    else {
      if (window.location.href.endsWith('/logout')) {
        window.location.href = redirectUri || '/';
      }
    }

    return Promise.resolve()
  }

  @action
  async editarPerfil(perfil) {
    try {
      await put('usuario', perfil)
      // Lendo o da dado da localstorage
      let user = localStorageGetItem('user')
      user.nome = perfil.nome
      localStorageSetItem('user', user)
      this.user = user
    } catch (error) {
      console.error(error)
    }
  }
}

const criarNovaSenhaNoCognito = ({ usuario, novaSenha }) => {
  let atributos = usuario.challengeParam.userAttributes;
  delete atributos.email_verified;
  delete atributos.phone_number_verified;
  return new Promise((resolve, reject) => usuario.completeNewPasswordChallenge(novaSenha, atributos, { onSuccess: resolve, onFailure: reject }))
}

const errosDoCognito = mensagem => {
  const senhaInvalida = 'Senha deve conter 8 caracteres, símbolo, número, letra maiúscula e minúscula'
  return ({
    'User does not exist.': 'Usuário não encontrado.',
    'New password is required.': 'Nova senha é obrigatória.',
    'Incorrect username or password.': 'Usuário ou senha incorreto.',
    'Attempt limit exceeded, please try after some time.': 'Você excedeu o limite de tentativas.',
    'Password does not conform to policy: Password must have symbol characters': senhaInvalida,
    'Password does not conform to policy: Password must have uppercase characters': senhaInvalida,
    'Password does not conform to policy: Password must have lowercase characters': senhaInvalida,
    'Password does not conform to policy: Password must have numeric characters': senhaInvalida,
    'Password does not conform to policy: Password not long enough': senhaInvalida,
    'Password reset required for the user': 'Crie outra senha para o usuário.',
  })[mensagem]
}

export default new AuthStore()
