import React, { Component } from 'react'
import Joi from 'joi'
import alertaStore from '../../stores/alertaStore'

export default (ComposedComponent, schema) => class extends Component {

  state = {
    data: {},
    schema: schema,
    errors: [],
    error: null,
    abortEarly: false
  }

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.reset = this.reset.bind(this)
    this.onAppendState = this.onAppendState.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.validate = this.validate.bind(this)
    this.handleError = this.handleError.bind(this)
    this.addCustomError = this.addCustomError.bind(this)
    this.changeAbortEarly = this.changeAbortEarly.bind(this)
  }

  get valid() { return this.state.errors === null || this.state.errors.length === 0 }
  onSchema = (value) => this.setState({ schema: value })
  onChange = (name, value) => this.setState({ data: { ...this.state.data, [name]: value } })
  onAppendState = async (value) => await this.setState({ data: { ...this.state.data, ...value } })
  reset = () => this.setState({ data: {} })

  onSubmit = action => async event => {
    event.preventDefault()
    this.setState({ error: null })

    try {
      this.validate() && await action({ ...this.state.data })
    }
    catch (e) {
      console.error('=> ', e, e.message)
      this.setState({ error: e.message })
    }
  }

  validate() {
    const { error } = Joi.validate(this.state.data, this.state.schema, { abortEarly: this.state.abortEarly })
    const mapError = error => ({ context: error.context.key, message: error.message })
    const errors = error && error.details.map(mapError)
    if (errors && errors.length > 0) alertaStore.erro(errors.map(x => x.message).join(", \n"))
    this.setState({ errors })
    return errors === null
  }

  handleError(value, friendlyName) {
    if (this.valid) return null
    let error = this.state.errors.find((error) => error.context === value)
    if (friendlyName && error && error.message) error.message = error.message.replace('"' + value + '"', friendlyName)
    return error && error.message
  }

  addCustomError = (context, message) => this.setState({ errors: [{ context, message }] })
  changeAbortEarly = () => this.setState({ abortEarly: !this.state.abortEarly })

  render() {
    return <ComposedComponent
      reset={this.reset}
      onChange={this.onChange}
      onSchema={this.onSchema}
      onAppendState={this.onAppendState}
      onSubmit={this.onSubmit}
      validate={this.validate}
      handleError={this.handleError}
      addCustomError={this.addCustomError}
      changeAbortEarly={this.changeAbortEarly}
      valid={this.valid}
      store={this.store}
      {...this.props}
      {...this.state} />
  }
}