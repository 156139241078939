import React, { Component } from 'react'

class ClientCheckbox extends Component {

  state = {
    value: this.props.value || 0
  }

  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  onClick = value => this.setState({value: value ? 1 : 0}, () => this.props.onClick(this.state.value))
  
  render() {
    return (
        <span className={`Client-checkbox ${this.state.value ? 'checked' : ''}`}>
          <input type="checkbox" style={this.props.style || {}}
            defaultChecked={this.state.value}
            onClick={(event) => this.onClick(event.target.checked)}
            className={`${this.props.css}`} id={this.props.id || 'Client-checkbox'}/>
          { this.props.label ? <label className="Client-checkbox-input-label" htmlFor={this.props.id || "Client-checkbox"}>{this.props.label}</label> : null}
        </span>
        )
      }
    }
export default ClientCheckbox