import { observable, action } from "mobx"
//import {get, post, del, put } from "../services/http"
import {post, del, put } from "../services/http"
import { uploadFromFileInput } from "../helpers/arquivo"

import axios from 'axios'; 
import https from "https";

let http = axios.create({ 
    httpsAgent: new https.Agent({rejectUnauthorized: false}),
    baseURL: process.env.REACT_APP_API_ARQUIVOS || 'https://127.0.0.1:4001', 
    timeout: 60 * 4 * 1000 
});

http.interceptors.response.use(response => response, error => Promise.reject(error))


class UploadStore {
    @observable loading = false
    @observable anexos = []
    @observable arquivo = {}

    @action
    async downloadArquivo(item, refs) {
        //console.log('item, refs>>>>>>>',item, refs)
        //let ambienteDesenvolvimentoAnexo = process.env.REACT_APP_API === "http://localhost:4000";
        //const url = await get(`${ambienteDesenvolvimentoAnexo ? "https://a4shqi0632.execute-api.us-east-1.amazonaws.com/test/" : "" }/download/${item.arquivo}`)
        //await http.get(`/arquivo/download/${item.arquivo}`)
        await http({url: `/arquivo/download/${item.arquivo}`, method: 'GET', responseType: 'blob'})
        .then(resp => {
            // console.log("Resp .>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", resp);
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            this.arquivo.url = url;
            this.arquivo.nome = item.nome;
            document.getElementById('download') && document.getElementById('download').click();
        })
        .catch(err => console.log(err))
    }
 
    @action
    async carregarArquivo(event, modulo, id_referencia, x ,id_parlamentar) {
        console.log('uploadStore-id_parlamentar',id_parlamentar)
        this.loading = true
        try {
            const uploadServidor = await uploadFromFileInput(event, modulo, id_referencia, id_parlamentar)
            //console.log('RETRONO>>>',uploadServidor[0])
            if(uploadServidor[0] === true){
                alert('Arquivo tipo video não permitido!')
                this.loading = false
            }else{
                for (const arquivo of uploadServidor) await post(`/anexo`, {...arquivo })
                this.loading = false
                return uploadServidor;
            }
           
        } catch (e) {
            console.log(e);
            this.loading = false
        }
        
    }

    @action
    async removerAnexo(anexo) {
        console.log('<<<ANEXO UPLOADSTORE>>>>',anexo);
        this.loading = true

        Promise.all([
            await http.delete(`/arquivo/${anexo.arquivo}`).then(rep=>rep).catch(err=>err),   
            await del(`/anexo/${anexo.id}`).then(rep=>rep).catch(err=>err)
        ]).then(rep =>{
            this.loading = false
            // window.location.reload()
            return true;

        }).catch(err => {

            console.log(err);
            this.loading = false
        })

        
    }
    @action
    async removerAnexo2(anexo, idParlamentar) {
        console.log('<<<ANEXO UPLOADSTORE>>>>',anexo);
        console.log('<<<ipParlamentar>>>>',idParlamentar);
        
        this.loading = true

        Promise.all([
            await http.delete(`/arquivo/${anexo.arquivo}`).then(rep=>rep).catch(err=>err),   
            await del(`/anexo/${anexo.id}/${idParlamentar}`).then(rep=>rep).catch(err=>err)
        ]).then(rep =>{
            this.loading = false
            // window.location.reload()
            return true;

        }).catch(err => {

            console.log(err);
            this.loading = false
        })

        
    }

    @action
    async removerAnexoSemId(anexo) {
        //console.log('<<<ANEXO UPLOADSTORE>>>>',anexo);
        this.loading = true
        try {
            await put(`/anexo/sem-id`, anexo)
            //await del(`/anexo`, { arquivo: anexo.arquivo })
            this.loading = false
            return true;
        } catch (e) {
            console.log(e)
            this.loading = false
        }
    }
}

const upload = new UploadStore()

export default upload