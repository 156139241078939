import React, { Component, Fragment } from 'react';
import Loading from '../../componentes/loading';
import SelectFiltroProposicao from './form/selectFiltroProposicao';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import { get } from '../../services/http'

 
const inputStyle = {
  border: 'none', 
  backgroundColor: 'transparent', 
  borderBottom: '1px solid #C4C4C4', 
  color: '#000000', 
  marginTop: '10px', 
  width: '100%', 
  placeholder: 'white', 
  outline: 'none'
};
class FormLegislativo extends Component {

   state = {
    loading: true,
    arrayFiltro: [],

    casa_legislativa: {value: '', label: ''},
    casaLegislativaList: [],

    tipoDocumento:{value:'' , label: ''},
    tipoDocumentoList:[],

    numero:'',
    ano:'',
    autor:'',
    ementa:'',
    apelido:'',

    mes: {value: '', label:''},
    mesesListSemAno: [{id: 1, mes: 'Escreva o ano para selecionar o mês'}],
    mesesList: [
        {id: 1, mes:'Janeiro'},
        {id: 2, mes:'Fevereiro'},
        {id: 3, mes:'Março'},
        {id: 4, mes:'Abril'},
        {id: 5, mes:'Maio'},
        {id: 6, mes:'Junho'},
        {id: 7, mes:'Julho'}, 
        {id: 8, mes:'Agosto'},
        {id: 9, mes:'Setembro'}, 
        {id: 10, mes:'Outubro'}, 
        {id: 11, mes:'Novembro'},
        {id: 12, mes:'Dezembro'}
        
      ],

    uf:{value:'' , label: ''},    
    ufList:[],

    municipio:{value:'' , label: ''},
    municipioList:[],

    ufAutor:{value:'' , label: ''},
    ufAutorList:[],

    esfera:{value:'' , label: 'Esfera'},
    esferaList:[],

    fase:{value:'' , label: 'Fase'},
    faseList:[],

    categoria:{value:'' , label: 'Categorias'},
    categoriaList:[],

    grandeArea:{value:'' , label: 'Grande Área'},
    grandeAreaList:[],

    tema:{value:'' , label: 'Tema'},
    temaList:[],

    indexacao:{value:'' , label: 'Indexações'},
    indexacaoList:[],

    impactoJuridico:{value:'' , label: 'Impacto Jurídico'},
    impactosJuridicoList:[],

    estrategiaAtuacao:{value:'' , label: 'Estratégia de Atuação'},
    estrategiaAtuacaoList:[],

    acao:{value:'' , label: 'Ação'},
    acaoList:[],

    ultimasAcoesCheck:false,
    docNotRapidasCheck:false,
    arquivamentoAtivoCheck:false,
    arquivamentoArquivadosCheck:false,
    
    arrayFiltroLegislativo: []

  }
  
  async componentWillMount() {
    this.getDadosFiltroLegislativos()
  }

  async getDadosFiltroLegislativos() {
    await get(`darkBlueClient/legislativo`)
        .then(res => {
            //console.log('RESPONSE ===>',res)
            const arrayCasa = []
            res.casaLegislativa !== undefined && Array.isArray(res.casaLegislativa) && 
            res.casaLegislativa.length > 0 &&
            res.casaLegislativa.map((item, index) => {
                const data = {id: index, casa: item.casa }
                return arrayCasa.push(data)
            })
            const arrayTipo = []
            res.documentosLegislativos !== undefined && Array.isArray(res.documentosLegislativos) && 
            res.documentosLegislativos.length > 0 &&            
            res.documentosLegislativos.map((item, index) => {
                const data = {id: index, tipo: item.tipo_documento }
                return arrayTipo.push(data)
            })

            const arrayUF = []
            res.ufLegislativo !== undefined && Array.isArray(res.ufLegislativo) && 
            res.ufLegislativo.length > 0 &&                
            res.ufLegislativo.map((item,index)=> {
                const data={id: index, uf:item.uf }
                return arrayUF.push(data)

            })

            const arrayMunicipio = []
            res.municipioLegislativo !== undefined && Array.isArray(res.municipioLegislativo) && 
            res.municipioLegislativo.length > 0 &&             
            res.municipioLegislativo.map((item,index)=> {
                const data={id: index, municipio:item.municipio }
                return arrayMunicipio.push(data)

            })

            const arrayUfAutor = []
            res.municipioLegislativo !== undefined && Array.isArray(res.municipioLegislativo) && 
            res.municipioLegislativo.length > 0 &&             
            res.municipioLegislativo.map((item,index)=> {
                const data={id: index, ufAutor:item.municipio }
                return arrayUfAutor.push(data)

            })

            const arrayEsfera = []
            res.esferaLegislativo !== undefined && Array.isArray(res.esferaLegislativo) && 
            res.esferaLegislativo.length > 0 &&              
            res.esferaLegislativo.map((item,index)=> {
                const data={id: index, esfera:item.esfera }
                return arrayEsfera.push(data)

            })

            const arrayFase = []
            res.faseLegislativo !== undefined && Array.isArray(res.faseLegislativo) && 
            res.faseLegislativo.length > 0 &&                
            res.faseLegislativo.map((item,index)=> {
                const data={id: index, fase:item.fase }
                return arrayFase.push(data)

            })

            const arrayCategorias = []
            res.categoriaLegislativo !== undefined && Array.isArray(res.categoriaLegislativo) && 
            res.categoriaLegislativo.length > 0 &&              
            res.categoriaLegislativo.map((item,index)=> {
                const data={id: index, categoria:item.categoria }
                return arrayCategorias.push(data)

            })

            const arrayGrandeArea = []
            res.grandeAreaLegislativo !== undefined && Array.isArray(res.grandeAreaLegislativo) && 
            res.grandeAreaLegislativo.length > 0 &&            
            res.grandeAreaLegislativo.map((item,index)=> {
                const data={id: index, grandeArea:item.area }
                return arrayGrandeArea.push(data)

            })

            const arrayTema = []
            res.temaLegislativo !== undefined && Array.isArray(res.temaLegislativo) && 
            res.temaLegislativo.length > 0 &&                
            res.temaLegislativo.map((item,index)=> {
                const data={id: index, tema:item.tema }
                return arrayTema.push(data)

            })

            const arrayIndexacao = []
            res.indexacao !== undefined && Array.isArray(res.indexacao) && 
            res.indexacao.length > 0 &&              
            res.indexacao.map((item,index)=> {
                const data={id: index, indexacao:item.indexacao }
                return arrayIndexacao.push(data)

            })

            /*const arrayImpactosJuridico = []
            res.categoriaLegislativo.map((item,index)=> {
                const data={id: index, categoria:item.categoria }
                return arrayImpactosJuridico.push(data)

            })

            const arrayEstrategiaAtuacao = []
            res.categoriaLegislativo.map((item,index)=> {
                const data={id: index, categoria:item.categoria }
                return arrayEstrategiaAtuacao.push(data)

            })

            const arrayAcao = []
            res.categoriaLegislativo.map((item,index)=> {
                const data={id: index, categoria:item.categoria }
                return arrayAcao.push(data)

            })*/

            //Alterar os states 
            this.setState({
                casaLegislativaList: arrayCasa,
                tipoDocumentoList: arrayTipo,
                ufList: arrayUF,
                municipioList: arrayMunicipio,
                ufAutorList: arrayUfAutor,
                esferaList: arrayEsfera,
                faseList: arrayFase,
                categoriaList: arrayCategorias,
                grandeAreaList: arrayGrandeArea,
                temaList: arrayTema,
                indexacaoList: arrayIndexacao,
                //impactosJuridicoList: arrayImpactosJuridico,
                //estrategiaAtuacaoList: arrayEstrategiaAtuacao,
                //acaoList: arrayAcao,
                loading: false
                
            })
        })
        .catch(err => console.error(err))
  }

  filtrardarkBlueClientLegislativo = () => {
      const data = {
        tipo: 'legislativo',
        casa_legislativa: this.state.casa_legislativa,
        tipoDocumento: this.state.tipoDocumento,
        numero: this.state.numero.trim(),
        ano: isNaN(this.state.ano) === false ? this.state.ano.trim() : '',
        mes: this.state.mes.label === 'Escreva o ano para selecionar o mês' ? {value:'' , label: ''} : this.state.mes,
        uf: this.state.uf,
        municipio: this.state.municipio,
        autor: this.state.autor.trim(),
        ufAutor: this.state.ufAutor,
        esfera: this.state.esfera.label === 'Esfera' ? {value:'' , label: ''} : this.state.esfera,
        fase: this.state.fase.label === 'Fase' ? {value:'' , label: ''} : this.state.fase,
        ementa: this.state.ementa.trim(),
        categoria: this.state.categoria.label === 'Categorias' ? {value:'' , label: ''} : this.state.categoria,
        apelido: this.state.apelido.trim(),
        grandeArea: this.state.grandeArea.label === 'Grande Área' ? {value:'' , label: ''} : this.state.grandeArea,
        tema: this.state.tema.label === 'Tema' ? {value:'' , label: ''} : this.state.tema,
        indexacao: this.state.indexacao.label === 'Indexações' ? {value:'' , label: ''} : this.state.indexacao,
        impactoJuridico: this.state.impactoJuridico.label === 'Impacto Jurídico' ? {value:'' , label: ''} : this.state.impactoJuridico,
        estrategiaAtuacao: this.state.estrategiaAtuacao.label === 'Estratégia de Atuação' ? {value:'' , label: ''} : this.state.estrategiaAtuacao,
        acao: this.state.acao.label === 'Ação' ? {value:'' , label: ''} : this.state.acao,
        ultimasAcoesCheck: this.state.ultimasAcoesCheck,
        docNotRapidasCheck: this.state.docNotRapidasCheck,
        arquivamentoAtivoCheck: this.state.arquivamentoAtivoCheck,
        arquivamentoArquivadosCheck: this.state.arquivamentoArquivadosCheck,
    }

    window.localStorage.setItem('filtrodarkBlueClient', JSON.stringify(data))
    window.location.reload()
    window.location.href = '/proposicao-estadual-municipal'
  }

  onChangeCasaLegislativa = casa_legislativa => {
    const data = {label: 'Casa Legislativa', value: casa_legislativa.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Casa Legislativa' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ casa_legislativa: casa_legislativa, arrayFiltroLegislativo: arrayFiltro })
  }
  
onChangeTipoDocumento = tipoDocumento => {   
    const data = {label: 'Tipo de Documento', value: tipoDocumento.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Tipo de Documento' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ tipoDocumento: tipoDocumento, arrayFiltroLegislativo: arrayFiltro})
}
  
    
onChangeNumero = (event) => { 
    const data = {label: 'Número', value: event.target.value.replace(/\D/g,"")}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Número' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ numero: event.target.value.replace(/\D/g,""), arrayFiltroLegislativo: arrayFiltro })
    
}

onChangeAno = (event) => {
    const data = {label: 'Ano', value: event.target.value.replace(/\D/g,"")}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Ano' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ ano: event.target.value.replace(/\D/g,""), arrayFiltroLegislativo: arrayFiltro })  
}

onChangeMes = mes => {
    const data = {label: 'Mês', value: mes.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Mês' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ mes: mes, arrayFiltroLegislativo: arrayFiltro })
}
  
onChangeUF = uf => { 
    const data = {label: 'UF', value: uf.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'UF' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ uf: uf, arrayFiltroLegislativo: arrayFiltro })

    this.selectMunicipios(uf)

}

async selectMunicipios(uf) {
    await get('/darkBlueClient/' + uf.label)
    .then( res => {
        this.setState({ municipioList: res })
    })
}
  
onChangeMunicipio = municipio => {
    const data = {label: 'Município', value: municipio.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Município' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ municipio: municipio, arrayFiltroLegislativo: arrayFiltro})
  
}

onChangeAutor = event => {   
    const data = {label: 'Autor', value: event.target.value}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Autor' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ autor: event.target.value, arrayFiltroLegislativo: arrayFiltro })
}
  
onChangeUFAutor = ufAutor => {
    const data = {label: 'UF do Autor', value: ufAutor.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'UF do Autor' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ ufAutor: ufAutor, arrayFiltroLegislativo: arrayFiltro})
}
  
onChangeEsfera = esfera => {
    const data = {label: 'Esfera', value: esfera.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Esfera' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ esfera: esfera, arrayFiltroLegislativo: arrayFiltro })
}

onChangeFase = fase => {
    const data = {label: 'Fase', value: fase.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Fase' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ fase: fase, arrayFiltroLegislativo: arrayFiltro })
}

onChangeEmenta = event => {
    const data = {label: 'Ementa', value: event.target.value}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Ementa' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ ementa: event.target.value, arrayFiltroLegislativo: arrayFiltro })
}

onChangeCategoria = categoria => {
    const data = {label: 'Categoria', value: categoria.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Categoria' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ categoria: categoria, arrayFiltroLegislativo: arrayFiltro})
}

onChangeApelido = event =>{
    const data = {label: 'Apelido', value: event.target.value}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Apelido' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ apelido: event.target.value, arrayFiltroLegislativo: arrayFiltro })
}

onChangeGrandeArea = grandeArea => {
    const data = {label: 'Grande Área', value: grandeArea.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Grande Área' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ grandeArea: grandeArea, arrayFiltroLegislativo: arrayFiltro })
}

onChangeTema = tema => {
    const data = {label: 'Tema', value: tema.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Tema' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ tema: tema, arrayFiltroLegislativo: arrayFiltro })
}

onChangeIndexacao = indexacao => {
    const data = {label: 'Indexação', value: indexacao.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Indexação' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    this.setState({ indexacao: indexacao, arrayFiltroLegislativo: arrayFiltro })
}

onChangeImpactoJuri = impactoJuri => {

    alert('Filtro Desativado')
    const data = {label: 'Impacto Jurídico', value: impactoJuri.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Impacto Jurídico' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    //this.setState({ impactoJuri: impactoJuri, arrayFiltroLegislativo: arrayFiltro })  
}

onChangeEstrategiaAtu = estrategiaAtu => {

    alert('Filtro Desativado')
    const data = {label: 'Estratégia de Atuação', value: estrategiaAtu.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Estratégia de Atuação' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    //this.setState({ estrategiaAtu: estrategiaAtu, arrayFiltroLegislativo: arrayFiltro})
}

onChangeAcao = acao => {

    alert('Filtro Desativado')
    const data = {label: 'Ação', value: acao.label}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Ação' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    //this.setState({ acao: acao, arrayFiltroLegislativo: arrayFiltro })
}

onChangeUltimasAcoes = event => {

    alert('Filtro Desativado')
    const data = {label: 'Ações da Atuação de Campo', value: 'Selecionado'}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Ações da Atuação de Campo' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    //this.setState({ ultimasAcoesCheck: event.target.checked, arrayFiltroLegislativo: arrayFiltro })
}

onChangeDocNotificacaoRap = event => {   

    alert('Filtro Desativado')
    const data = {label: 'Documento', value: 'Selecionado'}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Documento' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    //this.setState({ docNotRapidasCheck: event.target.checked, arrayFiltroLegislativo: arrayFiltro })
}

onChangeArqAtivos = event => {   

    alert('Filtro Desativado')
    const data = {label: 'Arquivamento Ativo', value: 'Selecionado'}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Arquivamento Ativo' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    //this.setState({ arquivamentoAtivoCheck: event.target.checked, arrayFiltroLegislativo: arrayFiltro }) 
}

onChangeArqArquivados = event => { 
    
    alert('Filtro Desativado')
    const data = {label: 'Arquivamento Arquivados', value: 'Selecionado'}
    const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            return i.label !== 'Arquivamento Arquivados' ? arrayFiltro.push(i) : null
      })
    arrayFiltro.push(data)
    //this.setState({ arquivamentoArquivadosCheck: event.target.checked, arrayFiltroLegislativo: arrayFiltro })
}

botaoExecutivoAtivo() {
    window.localStorage.setItem('filtro-selecao', 'executivo')
    window.location.reload()
  
  }

  resetarFiltro(){

    this.setState({
        casa_legislativa: '',
        tipoDocumento: '',
        numero:'',
        ano: '',
        mes: '',
        uf:'',
        municipio: '',
        autor: '',
        ufAutor: '',
        esfera: '',
        fase: '',
        ementa: '',
        categoria: '',
        apelido: '',
        grandeArea: '',
        tema: '',
        indexacao:'',
        impactoJuridico: '',
        estrategiaAtuacao: '',
        acao:'',
        ultimasAcoesCheck: false,
        docNotRapidasCheck: false,
        arquivamentoAtivoCheck: false,
        arquivamentoArquivadosCheck: false, 
        arrayFiltroLegislativo: []

    })
    window.localStorage.removeItem('filtrodarkBlueClient')
  }
  

  excluirSelecao(e, item, index) {
      const arrayFiltro = []
      this.state.arrayFiltroLegislativo.filter(i => {
            const array = i.label !== item.label ? arrayFiltro.push(i) : null
            return array
      })
      this.setState({ arrayFiltroLegislativo: arrayFiltro })
      this.limparFiltroUnico(item)
  }

  limparFiltroUnico(item) {

    //console.log('Filtro LABEL ===>',item.label)
    if(item.label === 'Casa Legislativa') {
        this.setState({ casa_legislativa: '' })
        //console.log('Filtro LIMPO ===>',this.state.casa_legislativa)
    }
    else if(item.label === 'Tipo de Documento') {
        this.setState({ tipoDocumento: '' })
    }
    else if(item.label === 'Número') {
        this.setState({ numero: '' })
    }
    else if(item.label === 'Ano') {
        this.setState({ ano: '' })
    }
    else if(item.label === 'Mês') {
        this.setState({ mes: '' })
    }
    else if(item.label === 'UF') {
        this.setState({ uf: '' })
    }
    else if(item.label === 'Município') {
        this.setState({ municipio: '' })
    }
    else if(item.label === 'Autor') {
        this.setState({ autor: '' })
    }
    else if(item.label === 'UF do Autor') {
        this.setState({ ufAutor: '' })
    }
    else if(item.label === 'Esfera') {
        this.setState({ esfera: '' })
    }
    else if(item.label === 'Fase') {
        this.setState({ fase: '' })
    }
    else if(item.label === 'Ementa') {
        this.setState({ ementa: '' })
    }
    else if(item.label === 'Categorias') {
        this.setState({ categoria: '' })
    }
    else if(item.label === 'Apelido') {
        this.setState({ apelido: '' })
    }
    else if(item.label === 'Grande Área') {
        this.setState({ grandeArea: '' })
    }
    else if(item.label === 'Tema') {
        this.setState({ tema: '' })
    }
    else if(item.label === 'Indexações') {
        this.setState({ indexacao: '' })
    }
    else if(item.label === 'Impacto Jurídico') {
        this.setState({ impactoJuri: '' })
    }
    else if(item.label === 'Estratégia de Atuação') {
        this.setState({ estrategiaAtu: '' })
    }
    else if(item.label === 'Ação') {
        this.setState({ acao: 's' })
    }
    else if(item.label === 'Ações da Atuação de Campo') {
        this.setState({ ultimasAcoesCheck: false })
    }
    else if(item.label === 'Documento') {
        this.setState({ docNotRapidasCheck: false })
    }
    else if(item.label === 'Arquivamento Ativo') {
        this.setState({ arquivamentoAtivoCheck: false })
    }
    else if(item.label === 'Arquivamento Arquivados') {
        this.setState({ arquivamentoArquivadosCheck: false  })
    }    
    
  }
 

  render() {
    const heightScreen = window.innerHeight
    // console.log('this.state.casa_legislativa ===>',this.state.casa_legislativa)
    // console.log('ARRAY FILTRO ===> ',this.state.arrayFiltroLegislativo )
    //const filtroLocal = window.localStorage.getItem('filtrodarkBlueClient')
    //const filtroLocal = window.localStorage.getItem('filtrodarkBlueClient') !== undefined && window.localStorage.getItem('filtrodarkBlueClient') !== null ? JSON.parse(window.localStorage.getItem('filtrodarkBlueClient')) : null
    //console.log('filtroLocal ===>', filtroLocal)
    return this.state.loading ? <Loading /> :
      <Fragment>


<div className="col-sm-9 area-formulario-filtro">

<div className="row">
    <div className="col-12 titulo-filtro titulo-filtro_custom">
        Busca Municipais/Estaduais
    </div>

        <div className="col-md-6 margin-botao">
            <button className="btn-block botao-ativo">Legislativo</button>
        </div>
        <div className="col-md-6 margin-botao">
            <button className="btn-block botao-nao-ativo" onClick={this.botaoExecutivoAtivo}>Executivo</button>
        </div>

        <div className="col-sm-6 form-margin-top">
            <label className="label-form-filtro">Casa Legislativa</label>
            <SelectFiltroProposicao 

                value={(this.state.casa_legislativa) ? this.state.casa_legislativa.id : null}
                onChange={casa_legislativa => this.onChangeCasaLegislativa(casa_legislativa)}
                options={
                    this.state.casaLegislativaList !== undefined && Array.isArray(this.state.casaLegislativaList) && this.state.casaLegislativaList.length > 0 ?
                    this.state.casaLegislativaList.map(item => ({ value: item.id, label: item.casa })):null}
                placeholder={this.state.casa_legislativa.label}

            />
        </div>
        <div className="col-sm-6 form-margin-top">
            <label className="label-form-filtro">Tipo de Documento</label>
            <SelectFiltroProposicao 
                value={(this.state.tipoDocumento) ? this.state.tipoDocumento.id : null}
                onChange={tipoDocumento => this.onChangeTipoDocumento(tipoDocumento)}
                options={
                    this.state.tipoDocumentoList !== undefined && Array.isArray(this.state.tipoDocumentoList) && this.state.tipoDocumentoList.length > 0 ?
                    this.state.tipoDocumentoList.map(item => ({ value: item.id, label: item.tipo })):null}
                placeholder={this.state.tipoDocumento.label}
            />
        </div>
       
        <div className="col-md-4 mt-2">
            <label className="label-form-filtro">Número</label>
            <input 
                style={inputStyle}
                onChange={(event) => this.onChangeNumero(event)} 
                //onFocus={limparCampoNumero}
                placeholder="Escreva"
                value={this.state.numero}
                id="numeroInput"
                autoComplete="off"
                maxlength="20"
            />
        </div>
        <div className="col-md-4 mt-2">
            <label className="label-form-filtro">Ano</label>
            <input 
                style={inputStyle}
                onChange={(event) => this.onChangeAno(event)} 
                //onFocus={limparCampoNumero}
                placeholder="Escreva"
                value={this.state.ano}
                id="anoinput"
                autoComplete="off"
                maxlength="4"
            />
        </div>
        <div className="col-sm-4 mt-2">
            <label className="label-form-filtro">Mês</label>
            <SelectFiltroProposicao 

            value={(this.state.mes) ? this.state.mes : null}
            onChange={mes => this.onChangeMes(mes)}
            options={
                this.state.ano === '' ?
                this.state.mesesListSemAno !== undefined && Array.isArray(this.state.mesesListSemAno) && this.state.mesesListSemAno.length > 0 ?
                this.state.mesesListSemAno.map(item => ({value: item.id, label: item.mes })): null : this.state.mesesList.map(item => ({value: item.id, label: item.mes }))}
            placeholder={this.state.mes.label
            }


            />
        </div>
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro">UF</label>
            <SelectFiltroProposicao 
               value={(this.state.uf) ? this.state.uf : null}
               onChange={uf => this.onChangeUF(uf)}
               options={
                 this.state.ufList !== undefined && Array.isArray( this.state.ufList) &&  this.state.ufList.length > 0 ?   
                this.state.ufList.map(item => ({value: item.id, label: item.uf })):null}
               placeholder={this.state.uf.label
               }
            />
        </div>
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro">Município</label>
            <SelectFiltroProposicao 
              value={(this.state.municipio) ? this.state.municipio : null}
              onChange={municipio => this.onChangeMunicipio(municipio)}
              options={
                this.state.municipioList !== undefined && Array.isArray(this.state.municipioList) && this.state.municipioList.length > 0 ?  
                this.state.municipioList.map(item => ({value: item.id, label: item.municipio })):null}
              placeholder={this.state.municipio.label
              }
            />
        </div>
        <div className="col-md-6 mt-2">
            <label className="label-form-filtro">Autor</label>
            <input 
                style={inputStyle}
                onChange={(event) => this.onChangeAutor(event)} 
                //onFocus={limparCampoNumero}
                placeholder="Escreva"
                value={this.state.autor}
                id="autorInput"
                autoComplete="off"
                maxlength="20"
            />
        </div>
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro">UF do Autor</label>
            <SelectFiltroProposicao 
               value={(this.state.ufAutor) ? this.state.ufAutor : null}
               onChange={ufAutor => this.onChangeUFAutor(ufAutor)}
               options={
                this.state.ufAutorList !== undefined && Array.isArray(this.state.ufAutorList) && this.state.ufAutorList.length > 0 ?   
                this.state.ufAutorList.map(item => ({value: item.id, label: item.ufAutor })):null}
               placeholder={this.state.ufAutor.label
               }
            />
        </div>
        <div className="col-sm-4 mt-2">
            <label className="label-form-filtro"></label>
            <SelectFiltroProposicao 
               value={(this.state.esfera) ? this.state.esfera : null}
               onChange={esfera => this.onChangeEsfera(esfera)}
               options={
                this.state.esferaList !== undefined && Array.isArray(this.state.esferaList) && this.state.esferaList.length > 0 ?   
                this.state.esferaList.map(item => ({value: item.id, label: item.esfera })):null}
               placeholder='Esfera'
            />
        </div>
        <div className="col-sm-4 mt-2">
            <label className="label-form-filtro"></label>
            <SelectFiltroProposicao 
                placeholder='Fase'
                value={(this.state.fase) ? this.state.fase : null}
                onChange={fase => this.onChangeFase(fase)}
                options={
                    this.state.faseList !== undefined && Array.isArray(this.state.faseList) && this.state.faseList.length > 0 ?
                    this.state.faseList.map(item => ({value: item.id, label: item.fase })):null}            
            />
        </div>
        <div className="col-md-4 mt-2" >
            <label className="label-form-filtro"></label>
            <input 
                style={{border: 'none',backgroundColor: 'transparent', borderBottom: '1px solid #C4C4C4',color: '#000000', marginTop: '34px',width: '100%',placeholder: 'white', outline: 'none'}}
                
                
                onChange={(event) => this.onChangeEmenta(event)} 
                //onFocus={limparCampoNumero}
                placeholder="Ementa"
                value={this.state.ementa}
                id="ementainput"
                autoComplete="off"
                maxlength="20"
            />
        </div>
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro"></label>
            <SelectFiltroProposicao 
                placeholder='Categorias'
                value={(this.state.categoria) ? this.state.categoria : null}
                onChange={categoria => this.onChangeCategoria(categoria)}
                options={
                     this.state.categoriaList !== undefined && Array.isArray( this.state.categoriaList) &&  this.state.categoriaList.length > 0 ?
                    this.state.categoriaList.map(item => ({value: item.id, label: item.categoria })):null}
            />
        </div>
        <div className="col-md-6 mt-2">
            <label className="label-form-filtro"></label>
            <input 
                style={{border: 'none',backgroundColor: 'transparent', borderBottom: '1px solid #C4C4C4',color: '#000000', marginTop: '34px',width: '100%',placeholder: '#C4C4C4', outline: 'none'}}
                onChange={(event) => this.onChangeApelido(event)} 
                //onFocus={limparCampoNumero}
                placeholder="Apelido"
                value={this.state.apelido}
                id="apelidoinput"
                autoComplete="off"
                maxlength="20"
           />
        </div>
        <div className="col-sm-4 mt-2">
            <label className="label-form-filtro"></label>
            <SelectFiltroProposicao            
                value={(this.state.grandeArea) ? this.state.grandeArea : null}
                onChange={grandeArea => this.onChangeGrandeArea(grandeArea)}
                options={
                    this.state.grandeAreaList !== undefined && Array.isArray(this.state.grandeAreaList) && this.state.grandeAreaList.length > 0 ?
                    this.state.grandeAreaList.map(item => ({value: item.id, label: item.grandeArea })):null}
                placeholder='Grande Área'
            />
        </div>
        <div className="col-sm-4 mt-2">
            <label className="label-form-filtro"></label>
            <SelectFiltroProposicao 
             value={(this.state.tema) ? this.state.tema : null}
             onChange={tema => this.onChangeTema(tema)}
             options={
                this.state.temaList !== undefined && Array.isArray(this.state.temaList) && this.state.temaList.length > 0 ? 
                this.state.temaList.map(item => ({value: item.id, label: item.tema })):null}
             placeholder='Tema'
            />
        </div>
        <div className="col-sm-4 mt-2">
            <label className="label-form-filtro"></label>
            <SelectFiltroProposicao 
             value={(this.state.indexacao) ? this.state.indexacao : null}
             onChange={indexacao => this.onChangeIndexacao(indexacao)}
             options={
                this.state.indexacaoList !== undefined && Array.isArray(this.state.indexacaoList) && this.state.indexacaoList.length > 0 ? 
                this.state.indexacaoList.map(item => ({value: item.id, label: item.indexacao })):null}
            placeholder='Indexações'/>
        </div>
        <div className="col-sm-12 mt-2">
        <Typography variant="h6" gutterBottom style={{color: '#039EFB', marginTop: '30px'}}>
                    Atuação de Campo
        </Typography>  
        </div>  
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro"></label>
            <SelectFiltroProposicao 
             value={(this.state.impactoJuridico) ? this.state.impactoJuridico : null}
             onChange={impactoJuridico => this.onChangeImpactoJuri(impactoJuridico)}
             options={
                this.state.impactosJuridicoList !== undefined && Array.isArray(this.state.impactosJuridicoList) && this.state.impactosJuridicoList.length > 0 ? 
                this.state.impactosJuridicoList.map(item => ({value: item.id, label: item.impactoJuridico })):null}
             placeholder='Impacto juridico' 
             />
        </div>
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro"></label>
            <SelectFiltroProposicao 
             value={(this.state.estrategiaAtuacao) ? this.state.estrategiaAtuacao : null}
             onChange={estrategiaAtuacao => this.onChangeEstrategiaAtu(estrategiaAtuacao)}
             options={
                this.state.estrategiaAtuacaoList !== undefined && Array.isArray(this.state.estrategiaAtuacaoList) && this.state.estrategiaAtuacaoList.length > 0 ? 
                this.state.estrategiaAtuacaoList.map(item => ({value: item.id, label: item.estrategiaAtuacao })):null}
             placeholder='Estrategia de Atuação' 
             />
        </div>
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro"></label>
            <SelectFiltroProposicao 
             value={(this.state.acao) ? this.state.acao : null}
             onChange={acao => this.onChangeAcao(acao)}
             options={
                this.state.acaoList !== undefined && Array.isArray(this.state.acaoList) && this.state.acaoList.length > 0 ? 
                this.state.acaoList.map(item => ({value: item.id, label: item.acao })):null}
             placeholder='Ação' />
        </div>
        <div className="col-sm-6">
          <label className="label-form-filtro  mt-3" style={{fontSize:'0.8rem'}}>Ações da Atuação de Campo</label>
          <FormGroup row  aria-label="position">                 
                          <FormControlLabel
                              control={<Checkbox style={{color: '#039EFB'}} id="ultimasAcoes" onChange={event => this.onChangeUltimasAcoes(event)} checked={this.state.ultimasAcoesCheck} />}
                              label="Trazer somente últimas ações"
                              value={this.state.ultimasAcoesCheck}
                              
                          />                 
          </FormGroup>   
        </div>
        <div className="col-sm-6 mt-2">
          <label className="label-form-filtro" style={{fontSize:'0.8rem'}}>Documento</label>
          <FormGroup row  aria-label="position">                 
                          <FormControlLabel
                              control={<Checkbox style={{color: '#039EFB'}} id="Documentos" onChange={event => this.onChangeDocNotificacaoRap(event)} checked={this.state.docNotRapidasCheck} />}
                              label="Com Notificação Rápida"
                              value={this.state.docNotRapidasCheck}
                              
                          />                 
          </FormGroup>   
        </div>

        <div className="col-sm-6 mt-2">
        <label className="label-form-filtro" style={{fontSize:'0.8rem'}}>Arquivamento</label>
          <FormGroup row  aria-label="position">                 
                   <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   > 
                        <FormControlLabel
                            control={<Checkbox style={{color: '#039EFB'}} value="1" id="selectTodas"onChange={event => this.onChangeArqAtivos(event)} checked={this.arquivamentoAtivoCheck} />}
                            label="Ativos"
                            value={this.state.arquivamentoAtivoCheck}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   > 
                        <FormControlLabel
                            control={<Checkbox style={{color: '#039EFB'}} value="2" id="selectSeguidas" onChange={event => this.onChangeArqArquivados(event)} checked={this.arquivamentoAtivoCheck}/>}
                            label="Arquivados" 
                            value={this.state.arquivamentoArquivadosCheck}
                        />
                    </Grid>              
          </FormGroup>   
          </div>
          <div className="col-md-12 mb-5 mt-3 d-flex justify-content-center">
         
                
                <button className="botao-ativo mr-2" onClick={this.filtrardarkBlueClientLegislativo}>
                    <img
                    className="mr-2"
                    src={require('../../design/assets/images/lupa_buscar.svg')}
                    alt="anexo"
                    />{' '}                                     
                     Buscar
                </button>
        
                
                <button className="botao-nao-ativo" onClick={() => this.resetarFiltro()}>
                <img
                    className="mr-2"
                    src={require('../../design/assets/images/resetar-filtro.png')}
                    alt="anexo"
                    />{' '}
                    Resetar Filtro Legislativo
                </button>               
          </div> 
        </div>
    /</div>

        <div className="col-sm-3 area-pesquisa-filtro" style={{ height: heightScreen }}>

                        <div className="row padding-pesquisa">
                            <div className="col-12 titulo-pesquisa">
                                Sua pesquisa
                            </div>

                            {this.state.arrayFiltroLegislativo !== null && this.state.arrayFiltroLegislativo.length > 0 ? this.state.arrayFiltroLegislativo.map((filtro, index) => {
                                return (
                                    <div className="col-12 pesquisa-selecao" key={index}>
                                        <div className="tipo-pesquisa-selecao">
                                            {filtro.label}
                                        </div>
                                        <div className="descricao-pesquisa-selecao">
                                            <div className="row">
                                                <div className="col-9 "style={{ fontSize: '12px',textAlign:'left',paddingTop: '6px' }} >
                                                {filtro.value}
                                                </div>
                                                <div onClick={event => this.excluirSelecao(event, filtro, index)} className="col-3 area-excluir-selecao">
                                                    <div className="p area-excluir-selecao-x">x</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : null}
                            
                            
                            <div className="col-12">
                                <button className="btn-block botao-resetar-filtro" onClick={() => this.resetarFiltro()}>
                                <img
                                    className="mr-2"
                                    src={require('../../design/assets/images/resetar-filtro.png')}
                                    alt="anexo"
                                    />{' '}
                                    Resetar Filtro
                                </button>
                            </div>


                        </div>
                    </div>

      </Fragment>
  }

}
export default FormLegislativo