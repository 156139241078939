import axios from "axios"
import { get as localStorageGetItem } from "./local"
import authStore from "../stores/authStore"
import https from "https";

// import alertaStore from "../stores/alertaStore"
//const dominio = window.localStorage.getItem('dominio')
//const apiURL = dominio === 'localhost' ? process.env.REACT_APP_API_NOVA : `https://${dominio}.purpleClient.com.br/api`
//console.log('API URL ===>', apiURL)

let http = axios.create({ 
    httpsAgent: new https.Agent({rejectUnauthorized: false}),
    baseURL: process.env.REACT_APP_API_NOVA, 
    timeout: 60 * 4 * 1000 
})
http.interceptors.response.use(response => response, error => Promise.reject(error))

const request = method => async (url, params = {}) => {
    if (localStorageGetItem("user") && localStorageGetItem("user").token)
        axios.defaults.headers.common['Authorization'] = localStorageGetItem("user") && localStorageGetItem("user").token;

    return http[method](url, params)
        .then(res => res.data)
        .catch(e => {
            const status = (e && e.response && e.response.status)
            // const erro = (e && e.response && e.response.data)
            // const msgErro = (erro && erro.message) ? erro.message : erro

            // alertaStore.erro("Ops... algo deu errado. " + msgErro)

            if (status === 401) {
                window.localStorage.clear()
                // alertaStore.aviso("Ops... sua sessão não está mais ativa. Faça login novamente")
                authStore.logout()
            }
        })
}

const getSemToken = async url => (await axios.get(`${process.env.REACT_APP_API_NOVA}${url}`)).data
const get = request("get")
const post = request("post")
const put = request("put")
const del = request("delete")

export { http, getSemToken, get, post, put, del }