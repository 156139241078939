import React, { Component } from 'react'
import UploadFile from '../componentes/forms/inputs/upload'
// import defaultImg from '../design/assets/images/aviso-vermelho.svg'
import { inject } from 'mobx-react'
import { put, post } from '../services/http'
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

@inject('uploadStore')

class EditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: this.props.show || false,
            arquivo: [],
            anexos: this.props.anexos || [],
            dataHora: this.props.edit.data_acontecimento,
            acontecimento: this.props.edit.acontecimento,
            hasMeeting: false,
            meetingDateTime: this.props.edit.meetingTime !== undefined ? this.props.edit.meetingTime : null,
            originModulo: this.props.idParlamentar ? 'discurso-parlamentar' : 'acontecimentos',
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.show) this.setState({ show: newProps.show })
        if (newProps.edit.statusCheckAgenda) this.setState({ hasMeeting: newProps.edit.statusCheckAgenda })
        if (newProps.edit.meetingTime) this.setState({ meetingDateTime: newProps.edit.meetingTime })
        if (newProps.edit.acontecimento) this.setState({ acontecimento: newProps.edit.acontecimento })


    }

    close(save) {
        this.setState({ show: !this.state.show }, () => this.props.onClose(this.state.show, save, this.props.item))
        window.location.reload()
    }

    editText(event) {
        const acontecimento = event.target.value
        // console.log("Mudando texto funcao", acontecimento);
        this.setState({ acontecimento: acontecimento })

    }

    dataEdit(event) {
        const dataAcontecimento = event.target.value || new Date()
        this.setState({ dataHora: new Date(dataAcontecimento) })
    }

    checkMeeting() {
        this.setState({
            hasMeeting: !this.state.hasMeeting,
            meetingDateTime: null
        })
    }

    mettingDate(event) {
        const dataHoraDaReuniao = event.target.value
        this.setState({
            meetingDateTime: dataHoraDaReuniao
        })
    }

    async edit() {

        //Split para gerar hora e data no put de agendamento
        const dataEHoraDoAgendamento = moment(this.state.meetingDateTime).format('YYYY-MM-DDTHH:mm')
        const dataHoraSplitada = dataEHoraDoAgendamento.split("T")


        // this.props.edit.id_agenda = integer ou null
        if (this.state.meetingDateTime && this.props.edit.id_agenda) {

            // console.log("Campo preenchido + tem id dagendamento = edicao");

            // tem agendamento e esta sendo editado (put) 

            // Edita acontecimento
            const data = {
                acontecimento: this.state.acontecimento,
                data_acontecimento: moment(this.state.dataHora ? this.state.dataHora : this.props.edit.data_acontecimento).format('YYYY-MM-DDTHH:mm'),
                id: this.props.edit.id,
                dataHoraReuniao: this.state.meetingDateTime ? moment(this.state.meetingDateTime).format('YYYY-MM-DDTHH:mm') : null,
            }
            await put('/acontecimento', data)
                .then(res => res
                    // console.log("AAAAAAAAAAAAAAAA Acontecimento Editado", res)
                )

            // Edita Agendamento
            const dataAgendamento = {
                dataInicio: dataHoraSplitada[0],
                descricao: this.state.acontecimento,
                horaInicio: dataHoraSplitada[1],
                id: this.props.edit.id_agenda,
                idPauta: this.props.edit.id_agenda_pauta,
                local: "Evento de Acontecimentos",
                prop_selecionada: { value: this.props.edit.id_preposicao, label: `${this.props.edit.descricao} - ${this.props.edit.nome}` }
            }
            await put("/agenda/editar", dataAgendamento)
                .then(res => {
                    // console.log("AAAAAAAAAAAAAAAA Agendamento Editado", res)
                    this.close(true)
                })

        } else if (this.state.meetingDateTime && !this.props.edit.id_agenda) {

            // console.log("Campo preenchido + sem id dagendamento = criacao novo agendamento");

            // cria agendamento e depois edita o acontecimento com o Id Agendamento

            // Cria o agendamento
            const dataIncluirAgenda = {
                dataInicio: moment(this.state.meetingDateTime).format('YYYY-MM-DD'),
                horaInicio: moment(this.state.meetingDateTime).format('HH:mm'),
                local: "Evento de Acontecimentos",
                descricao: this.state.acontecimento,
                // pauta: "Pauta Acontecimentos Teste",
                prop_selecionada: {
                    id: this.props.edit.id_preposicao,
                    casa: this.props.edit.nome,
                    id_casa: this.props.edit.tipo
                },
                casa: this.props.edit.tipo
            }

            await post("/agenda/cadastro", dataIncluirAgenda)
                .then(res => {

                    // console.log("$%!&¨%$!%$@&¨%$! RESPOSTA DO NOVO AGENDAMENTO", res);

                    // Edita o acontecimento, inclusive com o idAgendamento criado acima
                    const data = {
                        acontecimento: this.state.acontecimento,
                        data_acontecimento: moment(this.state.dataHora ? this.state.dataHora : this.props.edit.data_acontecimento).format('YYYY-MM-DDTHH:mm'),
                        id: this.props.edit.id,
                        dataHoraReuniao: this.state.meetingDateTime ? moment(this.state.meetingDateTime).format('YYYY-MM-DDTHH:mm') : null,
                        id_agenda: res[0].id
                    }

                    put('/acontecimento', data)
                        .then(res => {
                            // console.log("Editou o acontecimento com as novas informacoes e com o ID do Agendamento Criado", res);
                            this.close(true)
                        })
                })

        } else if (!this.state.meetingDateTime && this.props.edit.id_agenda) {

            // console.log("Ado desclicar o checkbox, acontecimento editado + id Agendamento apagado")

            // tem id Agenda e não tem o campo do modal preenchido (checkbox foi desclicado)

            // Atualiza Acontecimento Simples
            const data = {
                acontecimento: this.state.acontecimento,
                data_acontecimento: moment(this.state.dataHora ? this.state.dataHora : this.props.edit.data_acontecimento).format('YYYY-MM-DDTHH:mm'),
                id: this.props.edit.id,
                dataHoraReuniao: this.state.meetingDateTime ? moment(this.state.meetingDateTime).format('YYYY-MM-DDTHH:mm') : null,
                id_agenda: null
            }

            await put('/acontecimento', data)
                .then(res => {
                    // console.log("Editou o acontecimento com as novas informacoes e com o ID do Agendamento Apagado", res);
                })

            // Apaga o meetingTime + agendamento
            await post(`/agenda/delAgendaAcontecimento/${this.props.edit.id_agenda}`)
                .then(res => {
                    // console.log("Agendamento na agenda foi apagado", res);
                    this.close(true)
                })

        } else {

            // console.log("Somente alteração do acontecimento")

            // Edita acontecimento
            const data = {
                acontecimento: this.state.acontecimento,
                data_acontecimento: moment(this.state.dataHora ? this.state.dataHora : this.props.edit.data_acontecimento).format('YYYY-MM-DDTHH:mm'),
                id: this.props.edit.id,
                dataHoraReuniao: this.state.meetingDateTime ? moment(this.state.meetingDateTime).format('YYYY-MM-DDTHH:mm') : null,
            }
            await put('/acontecimento', data)
                .then(res => {
                    // console.log("Acontecimento Editado", res)
                    this.close(true)
                })
        }
    }

    render() {
        // console.log("EDIT PROPS", this.props.edit);
        // console.log("TEXTO", this.state.acontecimento);
        // console.log("TEXTO PROPS", this.props.edit.acontecimento);
        // console.log("--------------------", moment(this.state.meetingDateTime).format('YYYY-MM-DD'));
        // console.log("STATE MEETINGDATATIME", this.state.meetingDateTime);
        // console.log("STATE has meeting", this.state.hasMeeting)
        // console.log("statusCheckAgenda", this.props.edit.statusCheckAgenda);
        // console.log("meetingTime props", this.props.edit.meetingTime);
        // console.log("STATE meetingTime Moment", moment(this.props.edit.meetingTime).format('YYYY-MM-DDTHH:mm'));
        return (
            this.state.show ? this.content() : null
        )
    }
    content() {
        const tamanhoTela = window.innerWidth
        return (
            <div className="background-confirm-modal">

                {
                    tamanhoTela < 600 ?

                        <div>
                            {/* ..................MOBILE.................. */}

                            <div className="" style={{ overflow: "scroll", height: "600px", position: "relative", width: "360px" }}>
                                <div className="confirm-modal">

                                    <div className="modal-header text-center">
                                        <div className="image w-100">
                                            <h5>Editar Acontecimento</h5>
                                        </div>
                                    </div>

                                    <div className="modal-body">
                                        <label htmlFor="data-atual" className="label-data-acontecimento" >Data</label>
                                        <input
                                            type="date"
                                            id="data-atual"
                                            className="form-control input-data-acontecimento"
                                            // this.props.edit.data_acontecimento vem de acontecimento.jsx
                                            defaultValue={moment(this.props.edit.data_acontecimento).format('YYYY-MM-DD')}
                                            onChange={event => this.dataEdit(event)}
                                        />
                                        {this.props.edit.tipo === 1
                                            ?
                                            <div className="row mt-3">
                                                <div className="col-12 col-md-4">
                                                    <div className="row" style={{ paddingLeft: "35px" }}>
                                                        <div style={{ paddingTop: "8px" }}>
                                                            <input className="form-check-input"
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }}
                                                                type="checkbox"
                                                                id="hasMeeting"
                                                                defaultChecked={this.state.hasMeeting}
                                                                onChange={() => this.checkMeeting()}
                                                            />
                                                        </div>
                                                        <label style={{ marginTop: "12px", marginLeft: "8px", fontSize: "15px" }}>
                                                            Incluir na Agenda
                                                        </label>
                                                    </div>
                                                </div>
                                                {this.state.hasMeeting ?
                                                    <div className="col-12 col-md-8">
                                                        <input
                                                            style={{ fontSize: "16px", width: "100%" }}
                                                            type="datetime-local"
                                                            id="data-atual"
                                                            className="form-control input-data-acontecimento"
                                                            defaultValue={moment(this.props.edit.meetingTime).add(3, 'hours').format('YYYY-MM-DDTHH:mm')}
                                                            onChange={event => this.mettingDate(event)}

                                                        />
                                                    </div>
                                                    :
                                                    null}
                                            </div>
                                            :
                                            null}

                                        <textarea className="form-control mt-3" rows="6" onChange={event => this.editText(event)}>{this.props.edit.acontecimento.replace(/<(?!!br\s*\/?)[^>]+>/g, '').replace(/&nbsp;/g, '')}</textarea>

                                        <div className="mt-2">
                                            <UploadFile linkButton={true} modulo={this.state.originModulo} id_referencia={this.props.edit.id_preposicao} onChange={anexo => this.setState({ anexos: [...this.state.anexos, ...anexo] })} />
                                        </div>

                                        {this.state.anexos !== undefined && Array.isArray(this.state.anexos) && this.state.anexos.length > 0 ?
                                            this.state.anexos.map((anexo, index) => {
                                                return (
                                                    anexo.modulo === 'acontecimentos' && anexo.id_referencia === this.props.edit.id ?
                                                        <div>
                                                            <div className="col-12 mt-3 ">
                                                                <div style={{ marginLeft: "-15px", padding: '10px', border: '1px solid #E1E8F2', color: 'var(--linkText)', width: '300px' }}>
                                                                    <div className="row">
                                                                        <div className="text-truncate row">
                                                                            {/* <img src={require('../design/assets/images/clips-red.svg')} alt="anexo" /> */}
                                                                            <div className=" ml-5" style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                                            <span className=" ml-2 mt-1 Client-link text-truncate" style={{ fontSize: "15px", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px", overflow: "hidden" }}>{anexo.nome}</span>
                                                                        </div>
                                                                        <div className="col-2 text-center delete-anexo">
                                                                            <img onClick={() => {
                                                                                this.props.uploadStore.removerAnexo(anexo);
                                                                                const array = this.state.anexos
                                                                                array.splice(index, 1)
                                                                                this.setState({ anexos: array })
                                                                            }} src={require('../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                )
                                            }) : null}

                                    </div>

                                    <hr />

                                    <div className="row text-center">
                                        <div className="col-6 mt-2 mb-4">
                                            <div onClick={() => this.close()}>{this.props.cancelButtonText || 'CANCELAR'}</div>
                                        </div>
                                        <div className="col-6 mt-2 mb-4">
                                            <div onClick={() => this.edit()}>{this.props.confirmButtonText || 'SALVAR'}</div>
                                            {/* <button type='button' onClick={() => this.edit()}>{this.props.confirmButtonText || 'EDITAR'}</button> */}
                                            {/* <button type='button' onClick={() => this.close(true)}>{this.props.confirmButtonText || 'EDITAR'}</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        :

                        <div>
                            {/* ..................DESK.................. */}

                            <div className="parlamentarEspecificoDesk ">
                                <div className="confirm-modal">

                                    <div style={{ width: '600px' }} className="modal-header text-center">
                                        <div className="image w-100">
                                            <h5>Editar Acontecimento</h5>
                                        </div>
                                    </div>

                                    <div className="modal-body">
                                        <label htmlFor="data-atual" className="label-data-acontecimento" >Data</label>
                                        <input
                                            type="date"
                                            id="data-atual"
                                            className="form-control input-data-acontecimento"
                                            // this.props.edit.data_acontecimento vem de acontecimento.jsx
                                            defaultValue={moment(this.props.edit.data_acontecimento).format('YYYY-MM-DD')}
                                            onChange={event => this.dataEdit(event)}
                                        />

                                        {this.props.edit.tipo === 1
                                            ?
                                            <div className="row mt-3">
                                                <div className="col-12 col-md-4">
                                                    <div className="row" style={{ paddingLeft: "35px" }}>
                                                        <div style={{ paddingTop: "8px" }}>
                                                            <input className="form-check-input"
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }}
                                                                type="checkbox"
                                                                id="hasMeeting"
                                                                defaultChecked={this.state.hasMeeting}
                                                                onChange={() => this.checkMeeting()}
                                                            />
                                                        </div>
                                                        <label style={{ marginTop: "12px", marginLeft: "8px", fontSize: "15px" }}>
                                                            Incluir na Agenda
                                                        </label>
                                                    </div>
                                                </div>
                                                {this.state.hasMeeting ?
                                                    <div className="col-12 col-md-8">
                                                        <input
                                                            style={{ fontSize: "16px", width: "100%" }}
                                                            type="datetime-local"
                                                            id="data-atual"
                                                            className="form-control input-data-acontecimento"
                                                            defaultValue={moment(this.state.meetingDateTime).add(3, 'hours').format('YYYY-MM-DDTHH:mm')}
                                                            onChange={event => this.mettingDate(event)}

                                                        />
                                                    </div>
                                                    :
                                                    null}
                                            </div>
                                            :
                                            null
                                        }




                                        <textarea className="form-control mt-3" rows="6" onChange={event => this.editText(event)}>{this.props.edit.acontecimento.replace(/<(?!!br\s*\/?)[^>]+>/g, '').replace(/&nbsp;/g, '')}</textarea>

                                        <div className="mt-2">
                                            <UploadFile linkButton={true} modulo={this.state.originModulo} idParlamentar={this.props.idParlamentar} id_referencia={this.props.edit.id_preposicao} onChange={anexo => this.setState({ anexos: [...this.state.anexos, ...anexo] })} />
                                        </div>

                                        <div className="row" style={{ width: "600px" }}>
                                            {this.state.anexos !== undefined && Array.isArray(this.state.anexos) && this.state.anexos.length > 0 ?
                                                this.state.anexos.map((anexo, index) => {
                                                    return (

                                                        anexo.modulo === this.state.originModulo && anexo.id_referencia === this.props.edit.id ?

                                                            <div className="col-6 mt-3" >
                                                                <div style={{ padding: '10px', border: '1px solid #E1E8F2', color: 'var(--linkText)', width: '100%' }}>
                                                                    <div className="row">
                                                                        <div className="text-truncate row">
                                                                            {/* <img src={require('../design/assets/images/clips-red.svg')} alt="anexo" /> */}
                                                                            <div style={{ content: "var(--clipsAnexos)", width: "11px", marginLeft: "30px" }}></div>
                                                                            <span className=" ml-2 mt-1 Client-link text-truncate" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px", overflow: "hidden" }}>{anexo.nome}</span>
                                                                        </div>
                                                                        <div className="col-2 text-center delete-anexo">
                                                                            <img onClick={() => {
                                                                                this.state.originModulo === 'acontecimentos' ?
                                                                                    this.props.uploadStore.removerAnexo(anexo) :
                                                                                    this.props.uploadStore.removerAnexo2(anexo, this.props.idParlamentar)
                                                                                const array = this.state.anexos
                                                                                array.splice(index, 1)
                                                                                this.setState({ anexos: array })
                                                                            }} src={require('../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            :
                                                            null
                                                    )
                                                }) : null}

                                        </div>



                                    </div>

                                    <div className="modal-footer">
                                        <div className="modal-footer-item">
                                            <button type='button' onClick={() => this.close()}>{this.props.cancelButtonText || 'CANCELAR'}</button>
                                        </div>
                                        <div className="modal-footer-item">
                                            <button type='button' onClick={() => this.edit()}>{this.props.confirmButtonText || 'SALVAR'}</button>
                                            {/* <button type='button' onClick={() => this.edit()}>{this.props.confirmButtonText || 'EDITAR'}</button> */}
                                            {/* <button type='button' onClick={() => this.close(true)}>{this.props.confirmButtonText || 'EDITAR'}</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default EditModal