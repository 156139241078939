import React, { useContext } from "react"
import { MidiaNewsContext } from "./index"
import { Newspaper, Media } from "./newspaper"
import isList from "../../componentes/hoc/isList"

const card = ({ keyword }) => {
    return (
        <div className="card bg-transparent">
            <Newspaper url={keyword} />
        </div>
    )
}

const cardMedia = ({ search }) => (
    <div className="row">{isList({ dados: search })(Media)}</div>
)

const Accordion = () => {
    const { seguidas } = useContext(MidiaNewsContext)
    return isList({ dados: seguidas })(card)
}

const SearchList = () => {
    const { search } = useContext(MidiaNewsContext)
    return cardMedia({ search })
}

export { Accordion, SearchList }
