import React from 'react'

const spinner = ({ message = 'Carregando' }) =>
    <div className="wrapper">
        <div className="loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <p className="text-center fs-20 font-weight-bold">{message}...</p>
    </div>

export default spinner