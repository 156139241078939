import React, { Component } from 'react'
import { get, post, put } from '../../services/http'
// import Pagination from "react-js-pagination";
import Loading from '../../componentes/loading'
// import { get } from 'http'
// import { NavLink } from 'react-router-dom'
import moment from 'moment-timezone';
import ClientSelect from '../../componentes/forms/basic/clientSelect'
import { parlamentarQualificacao } from '../../enums/parlamentarQualificacao'
import { inject } from 'mobx-react'
import ModalDiscurso from './modalDiscurso'

moment.tz.setDefault('UTC');

@inject('authStore')

class ParlamentarDiscursos extends Component {
    state = { 
        loading: true,
        parlamentar: {}, 
        discursos: [], 
        qualificacao: [],
        paginaAtual: 1,
        limite: 50,
        quantidade: 100,
    }

    async componentWillMount() {
        await Promise.all([
            this.getParlamentarDados(),
            this.getParlamentarDiscursos()
        ]).then(x => this.setState({ loading: false }))
    }

    async getParlamentarDados() {
        // console.log('entrei aqui getParlamentarDados')
    }

    async getParlamentarDiscursos() {
        await get(`parlamentar/discurso/${this.props.dados.id}`)
        .then(response => {
            // console.log("discurso", response)
            this.setState({ discursos: response })
        })
    }

    async descartarDiscurso(event, item) {
        this.setState({ loading: true })
        const data = {
            item: item
        }
        if(item.id_parlamentar_discurso === null) {
            await post('parlamentar/discurso/descatar', data)
            .then(response => {
                this.getParlamentarDiscursos()
                this.setState({ loading: false })
            })
        } else {
            await put('parlamentar/discurso/descatar', data)
            .then(response => {
                this.getParlamentarDiscursos()
                this.setState({ loading: false })
            })
        }
    }

    async alterarQualificacao(newValue, item) {
        // console.log(newValue, item)
        const data = {
            item: item,
            newValue: newValue
        }
        if(item.id_parlamentar_discurso === null) {
            await post('parlamentar/discurso/qualificar', data)
            .then(response => {
                this.getParlamentarDiscursos()
                this.setState({ loading: false })
            })
        } else {
            await put('parlamentar/discurso/qualificar', data)
            .then(response => {
                this.getParlamentarDiscursos()
                this.setState({ loading: false })
            })
        }
    }


    render() {
        return (
            this.state.loading ? <Loading /> : 
            <div>

                {this.state.discursos !== undefined
                && Array.isArray(this.state.discursos) && this.state.discursos.length > 0
                ? this.state.discursos.map((item, index) => {
                    const icon = parlamentarQualificacao.filter(icon => icon.value === item.qualificacao)
                    const icone =  icon.length > 0 ? icon[0].icon : icon
                    // console.log(item)
                    return (
                        
                        <div className="row p-2 background-parlamentar-card" key={index}>
                            <div className="col-12 p-3">
                                <div className="parlamentar-card-discursos">

                                    <div className="row p-3">

                                        <div className="col-sm-4">
                                            <div className="parlamentar-card-discursos-titulo">
                                                Data
                                            </div>
                                            <div>
                                                {moment(item.data).format('DD/MM/YYYY')}
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="parlamentar-card-discursos-titulo">
                                                Hora
                                            </div>
                                            <div>
                                            {moment(item.data).format('HH:mm')}
                                            </div>
                                        </div>

                                        {this.props.authStore.ehEsforcoColaborativo === true || this.props.authStore.ehVisualizador === true ? null :
                                            <div className="col-sm-4">
                                                <div className="parlamentar-card-discursos-titulo">
                                                    Qualificação
                                                </div>
                                                <div className="d-flex">
                                                {item.qualificacao !== null ? <p className={`Client-circulo ${icone} mt-3 m-0`}></p> : null } 
                                                <ClientSelect 
                                                    items={parlamentarQualificacao}
                                                    value={this.state.qualificacao}
                                                    onChange={(newValue) => this.alterarQualificacao(newValue, item)}
                                                    placeholder={item.qualificacao}
                                                />
                                                </div>
                                            </div>
                                        }
                                        {this.props.authStore.ehVisualizador === true ? 
                                            item.qualificacao !== null ?
                                            <div>
                                                <div className="parlamentar-card-discursos-titulo">
                                                    Qualificação
                                                </div>
                                                <div className="d-flex">
                                                    <p className={`Client-circulo ${icone}`}></p>
                                                    <p>{item.qualificacao}</p>
                                                </div>
                                            </div>
                                            : null
                                        : null}

                                        <div className="col-12 mt-3">
                                            <div className="parlamentar-card-discursos-titulo">
                                                Sumário
                                            </div>
                                            <div>
                                                {item.sumario === '' || item.sumario === null ?
                                                    <a href={item.url} target="_blank" rel="noopener noreferrer" className="link-discurso">Clique aqui para ver o discurso!</a>
                                                :
                                                    <ModalDiscurso
                                                        dados={item}
                                                        usuarioEsforco={this.props.authStore.ehEsforcoColaborativo }
                                                    />
                                                }
                                            </div>
                                        </div>

                                    </div>

                                    {this.props.authStore.ehEsforcoColaborativo === true || this.props.authStore.ehVisualizador === true ? null :
                                        <div className="text-right parlamentar-card-discursos-descartar p-3">
                                            <span onClick={event => this.descartarDiscurso(event, item)} className="parlamentar-card-discursos-click">
                                                Descartar
                                            </span>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>


                    )
                }) : <div className="d-flex justify-content-center mt-5 parlamentar-discurso-mensagem p-3"><b>Nenhum discurso registrado para este parlamentar</b></div> }

                {/* <div className="pagination"> Exibindo resultados {(this.state.paginaAtual - 1) * this.state.limite} - {this.state.paginaAtual * this.state.limite} de um total de {this.state.quantidade}</div>
                  <Pagination
                    activePage={this.state.paginaAtual}
                    itemsCountPerPage={this.state.limite}
                    totalItemsCount={this.state.quantidade}
                    pageRangeDisplayed={10}
                    hideFirstLastPages={true}
                    onChange={this.carregarPaginaNova}
                    prevPageText={<div className="prev-page"><img src={require('../../design/assets/images/prev-page-pagination.svg')} alt="prev-page"/></div>}
                    nextPageText={<div className="next-page"><img src={require('../../design/assets/images/next-page-pagination.svg')} alt="next-page"/></div>}
                  />  */}
                
            </div>
        )
    }
}

export default ParlamentarDiscursos;