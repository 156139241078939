import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { put } from '../../../services/http'

export default function FormDialog(props) {

    const [open, setOpen] = React.useState(false);
    const [palavras, setPalavras] = React.useState(props.dados)
    // console.log(palavras)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    }

    const selecionarExcluir = (item) => {
        const index = palavras.indexOf(item)
        let selecionado = palavras[index]
        selecionado.excluir = true
        selecionado.relevante = false

        let novoPalavras = palavras
        novoPalavras.splice(index, 1)

        novoPalavras.push(selecionado)

        function compare(a, b) {

            const dataA = a.palavra
            const dataB = b.palavra

            let comparison = 0;
            if (dataA > dataB) {
                comparison = 1;
            } else if (dataA < dataB) {
                comparison = -1;
            }
            return comparison;
        }

        setPalavras(novoPalavras.sort(compare))

    }

    const selecionarRelevante = (item) => {
        const index = palavras.indexOf(item)
        let selecionado = palavras[index]
        selecionado.relevante = true
        selecionado.excluir = false

        let novoPalavras = palavras
        novoPalavras.splice(index, 1)

        novoPalavras.push(selecionado)

        function compare(a, b) {

            const dataA = a.palavra
            const dataB = b.palavra

            let comparison = 0;
            if (dataA > dataB) {
                comparison = 1;
            } else if (dataA < dataB) {
                comparison = -1;
            }
            return comparison;
        }

        setPalavras(novoPalavras.sort(compare))
    }

    const salvarAlteracoes = async () => {

        const data = { palavras: palavras }
        await put('/ia/palavras/alterar', data)
            .then(res => setOpen(false))

    }

    return (
        <div>
            <button className="btn btn-danger btn-block" variant="outlined" onClick={handleClickOpen}>
                Configurar Correlatas
            </button>

            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title" >


                <DialogContent
                    style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: "white",
                        color: '#3E4049'
                    }}
                >
                    <div className="mb-3">
                        <p className='tituloModal' style={{ fontSize: '23px' }}>
                            Configure as palavras relevantes para treinamento da IA em correlatas
                        </p>
                    </div>

                    {/* <div>
                        <hr />
                    </div> */}

                    <div className="row mb-3 bg-dark pt-2 pb-2 pl-2 pr-4 font-correlatas" style={{ color: 'white', borderRadius: '3px' }}>
                        <div className="col-8">
                            Palavra
                        </div>
                        <div className="col-2">
                            Excluir
                        </div>
                        <div className="col-2">
                            Relevante
                        </div>
                    </div>

                    {/* <div>
                        <hr />
                    </div> */}

                    {palavras && palavras.length > 0 ? palavras.map((item, index) => {
                        return (
                            item.palavra.length > 2 ?
                                <div key={index} className="row">
                                    <div className="col-8 mt-1">
                                        {item.palavra}
                                    </div>
                                    <div className="col-2 mt-1">
                                        <input type="radio" name={`select_${index}`} defaultChecked={item.excluir} onChange={() => selecionarExcluir(item)} />
                                    </div>
                                    <div className="col-2 mt-1">
                                        <input type="radio" name={`select_${index}`} defaultChecked={item.relevante} onChange={() => selecionarRelevante(item)} />
                                    </div>
                                    <div className='col-12' >
                                        <hr style={{ marginTop: '2px', marginBottom: '3px' }} />
                                    </div>
                                </div>


                                : null
                        )
                    }) : null}

                    {/* <div>
                        <hr />
                    </div> */}

                    <div className="mt-3 mb-3">
                        <button className="btn btn-danger btn-block pt-3 pb-3 " onClick={salvarAlteracoes}>SALVAR ALTERAÇÕES</button>
                    </div>


                </DialogContent>

            </Dialog>
        </div >
    );
}