import React,{ useEffect } from 'react';
import { get, put } from "../../services/http";
// import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import ClientSelect from '../../componentes/forms/basic/clientSelectModalCadUser'
import Loading from "../../componentes/loading";
import alertaStore from '../../stores/alertaStore'
import LoadingCircle  from '../../componentes/loadingCircle'
//import { get } from 'lodash';




export default function FormDialog(props) {
  const [loading, setLoading] = React.useState(false);
  const [loadingCircle, setLoadingCircle] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [actions, setActions] = React.useState('');
  const [usuariosDep, setUsuariosDep] = React.useState([])  
  const [membros, setMembros] = React.useState([])  
  const [arrayMembros, setArrayMembros] = React.useState([])
  const [usuarioSelecionado, setUsuariosSelecionado] = React.useState([])  
  
  const [nomeDepartamento, setNomeDepartamento] = React.useState({id_departamento:'', nome_departamento:''})  
  const [arrayUsuarioDepto, setArrayUsuarioDepto] = React.useState([])

  //armazena os erros dos campos em um objeto
  const [errors, setErrors] = React.useState({})
  //Faço a validação do Form, se existir erro >> true, se nao false, seto inicialmente com true para nao chamar a função dentro do UseEffect
  const [validaErrors, setValidaErrors] = React.useState(true)

  //Se houver usuarios nao selecionados para transferencia de departamentos
  const [errorUsuariosNS, setErrorUsuariosNS] = React.useState(false)



  // Metodos que iniciam logo no inicio da montagem do componente deve ser coloca primeiro

  const getUsuariosPorIdDepartamento  = async (id) => {
     
    setLoading(true)
    await get(`/departamento/listar-por-idDepartamento/${id}`)
    .then( resp =>{
        //console.log('RESP USUARIOSSS >>>>>',resp)
        setArrayUsuarioDepto(resp)
        setLoading(false)

    })
    .catch(err => err)
  }


  const getMembrosDepto = async () => {
    setLoadingCircle(true)
    await get(`/departamento/lista-membros`)
    .then(res => {
      //console.log('DEPARTAMENTO>>',res)
      setLoadingCircle(false)
      setArrayMembros(res)
    })
    .catch(err => err)

  }

  const getUsuariosDepto = async (id_departamento) => {
      
    if(id_departamento !== undefined){
        setLoadingCircle(true)
        await get(`/departamento/usuarios-departamentos/${id_departamento}`)
        .then(res => {
          //console.log('DEPARTAMENTO>>',res)
          setLoadingCircle(false)
          setUsuariosDep(res)
        })
        .catch(err => err)
    }

  }

  const transferirUsuarios = async ()=>{
      
    let quantidade_usSelecionado =  Number (usuarioSelecionado.length)
    let quantidade_arrayUsSelecionado =  Number (arrayUsuarioDepto.length)

    //Comparação condicional ES6
    if(Object.is(quantidade_usSelecionado,quantidade_arrayUsSelecionado)){

        const data = { departamentos_selecionados: usuarioSelecionado }

        setLoading(true)

        await put(`/departamento/transferir-apagar`, data )
        .then(resp => {
            //console.log('Transferir USUARIOS >>>',resp)

            if(resp.length > 0) {

                alertaStore.criar('Usuários transferidos com sucesso.')
                setUsuariosSelecionado([])
                setArrayUsuarioDepto([])
                setLoading(false)
                
            } else {
                alertaStore.erro('Erro ao tentar transferir usuarios.')
            }

        })
        .catch(err => err)
    } else{
         //alert('sou dif, nao posso excluir')
         setErrorUsuariosNS(true)
    }
  }




  useEffect(() => {

     //Se não existir erros, e validaErrors false
    //coloco a consta validaErrors, para ficar verifcando se ouve atualização nela.
    if(Object.keys(errors).length === 0 && validaErrors === false){
        handleSave()
      }  
      
    //willMount
    return () => {
      
    }

  

  }, [validaErrors])



  function validate(){

    //console.log(dadosForm.id_departamento)
    let messageError = '*Campo Obrigatorio'
    let errors = {}

    if(!nomeDepartamento){
      errors.departamento = messageError
    }    
    //atualizo a const, se existir erros, true, se não false
    //Object.keys(errors).length verifica se existe conteudo dentro do objeto
    Object.keys(errors).length === 0 ? setValidaErrors(false): setValidaErrors(true)

    return errors
  }

  //função intermediaria que auxilia na validação dos campos
  const handleSubmit = () => {
    
    setErrors(validate())  
    //console.log(validate())   
    //retorna um objeto de errors
    //exemplo {departamento: "Erro" , nome: "Erro"}    
  }

  const handleSave = () => {
      
    let data = ''

    if(actions === 'Editar'){
        setLoading(true)
        
        data={

            nome_departamento: nomeDepartamento.nome_departamento,
            id_departamento:nomeDepartamento.id_departamento,
            array_usuarios : arrayUsuarioDepto,
           
        }

        props.callbackParent(data,actions)
        setOpen(false);
        setLoading(false)
    

        // let quantidade_usSelecionado =  Number (usuarioSelecionado.length)
        // let quantidade_arrayUsSelecionado =  Number (arrayUsuarioDepto.length)

        
        // //Comparação condicional ES6
        // if(Object.is(quantidade_usSelecionado,quantidade_arrayUsSelecionado)){
        //     alert('sou igual, posso excluir')
        //     data={
        //         nome_departamento: nomeDepartamento.nome_departamento,
        //         array_usuarios : arrayUsuarioDepto,
        //         departamentos_selecionados: [usuarioSelecionado],
        //     }
    
        //     props.callbackParent(data,actions)
        //     setOpen(false);
        //     setLoading(false)
        // }else{
        //      alert('sou dif, nao posso excluir')
        //     //  setErrorUsuariosNS(true)
        //     //  setLoading(false)

        // }


      

    }else if(actions === 'Excluir'){
      
        let quantidade_usSelecionado =  Number (usuarioSelecionado.length)
        let quantidade_arrayUsSelecionado =  Number (arrayUsuarioDepto.length)
        
      
        //Comparação condicional ES6
        if(Object.is(quantidade_usSelecionado,quantidade_arrayUsSelecionado)){
            //console.log('sou igual, posso excluir',quantidade_usSelecionado,quantidade_arrayUsSelecionado)
            data={
                id_departamento:nomeDepartamento.id_departamento,
                nome_departamento: nomeDepartamento.nome_departamento,
                array_usuarios : arrayUsuarioDepto,
                departamentos_selecionados: usuarioSelecionado,
            }
        props.callbackParent(data,actions)
        setOpen(false);
            
        }else{
            //alert('sou dif, nao posso excluir')
            setErrorUsuariosNS(true)
            
        }
        

    }
    

    
   
    // console.log('SALVAR USUARIO',dadosForm)
  }

  const handleClickOpen = (action) => {
   
  

    setOpen(true);
    setActions(action)
    setNomeDepartamento({id_departamento: props.dadosDepartamento.id, nome_departamento:props.dadosDepartamento.descricao})
    setErrorUsuariosNS(false)  
    getUsuariosPorIdDepartamento(props.dadosDepartamento.id)
    setValidaErrors(true)


    if(action === 'Editar'){
        getMembrosDepto();

    } else if (action === 'Excluir'){
        getUsuariosDepto(props.dadosDepartamento.id);
    }
       
   
  }

  const handleClose = () => {
    setOpen(false);
  };


 
  const handleChange = (event) => {
    console.log()
    setNomeDepartamento({id_departamento: props.dadosDepartamento.id, nome_departamento:event.target.value})
 
  }

  const handleChangeSelectMembros= (event) => {
      console.log(event)
    setMembros(event)
  }

  const addUsuario = () => {
    //console.log(membros)
    
    if(membros.length !== 0){

         setArrayUsuarioDepto([...arrayUsuarioDepto, 
        { 
          situacao: membros.situacao,
          id:membros.id_departamento,
          descricao: membros.descricao,
          id_departamento: membros.id_departamento,
          id_usuario:membros.value,
          nome_completo:  membros.nome, 
          url_foto:membros.url_foto,     
        
        }])
        setMembros([])

    }
   
    
  }

  const handleChangeArray = (event,id_usuario) => {
    
    //console.log('Aux>>>>',auxSelUsuario)  
    //monto objeto dentro do estatado
    setUsuariosSelecionado([...usuarioSelecionado,{departamento_selecionado: event, id_usuario:id_usuario }])
    
    //Nessa seleção é necessario comparar a duplicidade de id's de usuario

    let auxSelect = ''
    let newArrayDeptos = ''

    //Fiz a verificação, pois ao selecionar a primeira vez, o estado fica vazio
    //Assim, eu uso a variavel auxSelect para para obter o valor no momento do clique
    // depois concateno a mesma, com o estado atual
    if(usuarioSelecionado.length === 0){
        
        auxSelect = [{departamento_selecionado: event, id_usuario:id_usuario }]
        //Faço uma copia do array
        newArrayDeptos = auxSelect.concat(usuarioSelecionado.slice(0))
        setUsuariosSelecionado([...newArrayDeptos])
        //console.log('newArrayDeptos',newArrayDeptos)
    }else{

        //entra aqui, quando não for mais a  primeira vez 

        //Faço uma copia do array
        newArrayDeptos = usuarioSelecionado.slice(0)

        //busco o index que ja existe, comparando pelo id do usuario
        const index = newArrayDeptos.findIndex(item => item.id_usuario === id_usuario)

        //se existir duplicidade de id's de usuario
            if(index > -1){
                newArrayDeptos.splice(index, 1)
                newArrayDeptos.push({departamento_selecionado: event, id_usuario:id_usuario })
                //console.log('>>>>',newArrayDeptos)
                setUsuariosSelecionado([...newArrayDeptos])
                //console.log('newArrayDeptos -1 >>>>',newArrayDeptos)
                
            }
    }
  
  }

 

  const useStylesButtonFooter = makeStyles({
        root: {
            color: '#3E4049',
            backgroundColor: 'White',
            '&:hover': {
                backgroundColor: '#FFF',
            },
        },

    })(Button);

     const inputStyle = {
      border: 'none', 
      backgroundColor: 'transparent', 
      borderBottom: '1px solid #C4C4C4', 
      color: '#000000', 
      marginTop: '10px', 
      width: '100%', 
      placeholder: 'red', 
      outline: 'none'
    };
  //console.log('props>>>>>',props)

  const tamanhoTela = window.innerWidth

   //console.log('usuarioSelecionado',usuarioSelecionado)
   //console.log('arrayUsuarioDepto',arrayUsuarioDepto)
  //console.log('EDITAR>>>',arrayUsuarioDepto)
    


  return (

    
    <div>
          
          <div className="">
            <i className='icon-lixeira cursor-pointer mr-3' style={{color:'#9B9B9B'}} onClick={() => handleClickOpen('Excluir')}></i>   
            <i className='icon-editar-gray cursor-pointer  mr-3' style={{color:'#9B9B9B'}} onClick={() => handleClickOpen('Editar')}></i>
         </div>
 
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} maxWidth={'sm'}>
       {
           actions === 'Editar' 
            ?
                <div className="mt-3 ml-3"><h5>Editar Departamento</h5></div>
            :
                <div className="mt-3 ml-3"><h5>Excluir Departamento - <span style={{color:'#DC2D41', fontSize:'16px', fontWeight:'bold'}}>{nomeDepartamento.nome_departamento}</span></h5></div>
       }
        <DialogContent
             style={{
                height: tamanhoTela > 700 ? 600 : tamanhoTela,
                width: tamanhoTela > 700 ? 600 : tamanhoTela,
                //backgroundColor: "#3E4049",
                //color: 'white'

            }}
            overflow="hidden"
        
        >
            
            {
                actions === 'Editar'
                    ?
                        <>
                        <div className="row">
                            <div className="col-md-12 mt-1">
                            <label className="label-form-filtro-usuario">Nome do Depatartamento</label>
                            <input 
                                name={'departamento'} 
                                type="text"
                                style={inputStyle}
                                onChange={handleChange} 
                                //onFocus={limparCampoNumero}
                                placeholder ="Digite"
                                value={nomeDepartamento.nome_departamento}
                                id="docrelanoInput"
                                autoComplete="off"
                                
                            />
                            {errors.departamento && <span style={{color:'#DC2D41', fontSize:'12px', fontWeight:'bold'}}>{errors.departamento}</span>}
                            
                            </div>
                            <div className="col-md-9 mt-2">
                            <label className="label-form-filtro-usuario" style={{fontSize:'16px',color:'#3E4049'}}>Membros</label>
                                <p style={{fontSize:'12px', color:'#DC2D41'}}>Transferir usuário para este departamento</p>

                                {
                                   loadingCircle ? 
                            
                                    <div className="d-flex justify-content-center mt-2" >
                                        <LoadingCircle color={'#DC2D41'} size={'30px'} />
                                    </div>
                                :
                                    <ClientSelect 
                                        
                                        css="no-border"
                                        value={membros}
                                        options={
                                            arrayMembros !== null && arrayMembros !== undefined && Array.isArray(arrayMembros) && arrayMembros.length > 0  ?
                                            arrayMembros.map((item) => ({ value:item.id_usuario , label: item.nome_completo+ ' - ' + item.descricao, url_foto: item.url_foto, nome: item.nome_completo, id_departamento: item.id_departamento, descricao: item.descricao,situacao: item.situacao })):null}
                                        onChange={(newValue) => handleChangeSelectMembros(newValue)} 
                                        placeholder={'Selecione...'}
                                    />       
                                }                 
                            
                            </div>
                            <div className="col-md-3 mt-5">
                            <button type="submit" className='btn-usuario btn-block' onClick={addUsuario}>+ Adicionar</button>   
                            </div>
                                        
                        </div>

                        {errorUsuariosNS === true
                            ?
                                <div className="row mt-4 d-flex justify-content-center" style={{ fontSize: '14px', fontWeight:'bold', color: '#DC2D41'}}>
                                    <p>*Ainda existe usuários a serem transferidos de departamento.</p>
                                </div>
                            :
                                null

                        }
                                                

                                   
                            <div className='mt-4 overflow-auto listaPalavras mh-100' style={{height:`${tamanhoTela > 1400 ? '320px' :  '220px'}`}}>     
                            
                                
                                {
                                    
                                    loading ? <div style={{ marginTop: '200px' }}><Loading /></div> :
                                    arrayUsuarioDepto.length > 0 ?
                                    arrayUsuarioDepto.map((item,index)=>{
                                        
                                        
                                        return(

                                        item.situacao === 1 
                                            ?    
                                                <div key={index}>

                                                        <div className='mr-2 mb-3 p-2  d-flex justify-content-between' style={{ border: '1px solid #E1E8F2', borderRadius: '6px' }}>

                                                            <div className={"d-flex justify-content-center mt-1 "}>

                                                                <div className="icon-list-user ">
                                                                { item.url_foto === '' || item.url_foto === null 

                                                                    ?
                                                                        <div className="icon-list-user">
                                                                            <i className="icon-icon_identity_24px" style={{fontSize:'24px', color:'#FFF'}}></i>
                                                                        </div>
                                                                    :
                                                                        <div>
                                                                            <img className="img-foto-list-usuario" src={item.url_foto} alt=''/>
                                                                        </div>
                                                                }       
                                                                </div>                     
                                                                <div className='fontsCitacoes p-2' style={{ fontSize: '14px' }} >{item.nome_completo}</div>  
                                                            
                                                            </div> 

                                                            <div className={"d-flex flex-column  mt-1 "} >
                                                            
                                                                    <div style={{fontSize:'12px', color:'#DC2D41', width:'220px'}}>Departamento</div>
                                                                
                                                                        <div>{item.descricao}</div>
                                                                        {/* <ClientSelect 
                                                                        name={'departamento_selecionado'}
                                                                        css="no-border"
                                                                        //defaultValue={item.descricao}
                                                                        value={usuarioSelecionado.departamento_selecionado}
                                                                        // options={
                                                                        //     usuariosDep !== undefined && Array.isArray(usuariosDep) && usuariosDep.length > 0 ?
                                                                        //     usuariosDep.map((item,index) => ({ value:item.id , label: item.title })):null}
                                                                        //onChange={(newValue) => handleChangeArray(newValue, item.id_usuario)} 
                                                                        placeholder={item.descricao}
                                                                        />  */}    
                                                                
                                                            </div>
                                                    
                                                    
                                                    </div>
                                                </div>

                                            :
                                                <div key={index}>
                                                    
                                                    <div className='mr-2 mb-3 p-2  d-flex justify-content-between' style={{ border: '1px solid #DC2D41', borderRadius: '6px' }}>    
                                                   
                                                        <div className={"d-flex justify-content-center mt-3 "}>

                                                            <div className="icon-list-user ">
                                                            { item.url_foto === '' || item.url_foto === null 

                                                                ?
                                                                    <div className="icon-list-user">
                                                                        <i className="icon-icon_identity_24px" style={{fontSize:'24px', color:'#FFF'}}></i>
                                                                    </div>
                                                                :
                                                                    <div>
                                                                        <img className="img-foto-list-usuario" src={item.url_foto} alt=''/>
                                                                    </div>
                                                            }       
                                                            </div>                     
                                                            <div className='fontsCitacoes p-2' style={{ fontSize: '14px',fontWeight:'bold',color:'#DC2D41'  }} >{item.nome_completo} - ( Inativo )</div>  
                                                        
                                                        </div> 

                                                        <div className={""}>
                                                        
                                                                <label className="label-form-filtro-usuario" style={{fontSize:'12px', color:'#DC2D41', width:'220px'}}>Departamento</label>
                                                               
                                                                    <div>{item.descricao}</div>
                                                                        {/* <ClientSelect 
                                                                        readOnly
                                                                        name={'departamento_selecionado'}
                                                                        css="no-border"
                                                                        //defaultValue={item.descricao}
                                                                        value={usuarioSelecionado.departamento_selecionado}
                                                                        // options={
                                                                        //     usuariosDep !== undefined && Array.isArray(usuariosDep) && usuariosDep.length > 0 ?
                                                                        //     usuariosDep.map((item,index) => ({ value:item.id , label: item.title })):null}
                                                                        //onChange={(newValue) => handleChangeArray(newValue, item.id_usuario)} 
                                                                        placeholder={item.descricao}
                                                                        />  */}
                                                                    
                                                        </div>                                             
                                                
                                                </div>
                                            </div>

                                        )
                                    })
                                    :
                                    <div className='m-3 d-flex flex-column text-center fontsCitacoes' style={{ fontSize: '16px' }}>
                                        <span>Este departamento ainda não possui usuarios cadastrados.</span>
                                    </div>
                                }
                                
                            

                        </div>
                    </>
                    
                    :
                    <>
                        <div className="row" style={{ fontSize: '18px', fontWeight:'bold'}}>
                            <p>Para que a exclusão seja feita, transfira os seguintes usuários para outro departamento.</p>
                        </div>
                        {errorUsuariosNS === true
                            ?
                                <div className="row" style={{ fontSize: '18px', fontWeight:'bold', color: '#DC2D41'}}>
                                    <p>*Ainda existe usuarios a serem transferidos de departamento!!!</p>
                                </div>
                            :
                                null

                        } 
                        <div className='mt-2 overflow-auto listaPalavras mh-100' style={{height:`${tamanhoTela > 1400 ? '460px' :  '320px'}`}}>
                            {
                            arrayUsuarioDepto.length > 0 ?
                                arrayUsuarioDepto.map((item,index)=>{
                                    return(
                                    <div key={index}>
                                            <div className='mr-2 mb-3 p-2  d-flex justify-content-between' style={{ border: '1px solid #E1E8F2', borderRadius: '6px' }}>

                                            <div className={"d-flex justify-content-center mt-3 "}>

                                            <div className="icon-list-user ">
                                            { item.url_foto === '' || item.url_foto === null 

                                                ?
                                                    <div className="icon-list-user">
                                                        <i className="icon-icon_identity_24px" style={{fontSize:'24px', color:'#FFF'}}></i>
                                                    </div>
                                                :
                                                    <div>
                                                        <img className="img-foto-list-usuario" src={item.url_foto} alt=''/>
                                                    </div>
                                            }       
                                            </div>                     
                                            <div className='fontsCitacoes p-2' style={{ fontSize: '14px' }} >{item.nome_completo}</div>  
                                            
                                            </div> 

                                            <div className={""}>
                                            
                                                    <label className="label-form-filtro-usuario" style={{fontSize:'12px', color:'#DC2D41', width:'220px'}}>Departamento</label>
                                                        {
                                                            loadingCircle 
                                                            
                                                            ? 
                                                    
                                                                <div className="d-flex justify-content-center mt-2" >
                                                                    <LoadingCircle color={'#DC2D41'} size={'30px'} />
                                                                </div>
                                                            :
                                                                <ClientSelect 
                                                                    
                                                                    css="no-border"
                                                                    defaultValue={item.descricao}
                                                                    value={usuarioSelecionado.departamento_selecionado}
                                                                    options={
                                                                        usuariosDep !== undefined && Array.isArray(usuariosDep) && usuariosDep.length > 0 ?
                                                                        usuariosDep.map((item,index) => ({ value:item.id , label: item.title })):null}
                                                                    onChange={(newValue) => handleChangeArray(newValue,item.id_usuario)} 
                                                                    placeholder={item.descricao}
                                                                />    
                                                        }                    
                                                    
                                                
                                            </div>
                                        
                                        
                                        </div>
                                    </div>

                                    )
                                })
                                :
                                <div className='m-3 d-flex flex-column text-center fontsCitacoes' style={{ fontSize: '16px' }}>
                                <span style={{ fontSize: '14px', fontWeight:'bold'}}>Este departamento não possui usuarios cadastrados.</span>
                                <span style={{ fontSize: '16px', fontWeight:'bold', color: '#DC2D41'}} >Clique em CONCLUIR para excluir definitivamente o departamento.</span>
                                </div>
                            }            

                     </div>

                </>                

            }
                    

          
          
        </DialogContent>

        <Divider variant="middle" />

        <DialogActions >

            <Button fullWidth={true} autoFocus onClick={handleClose} className={useStylesButtonFooter.root} >
                CANCELAR
                </Button>

            <div className="linha"></div>

            {
                actions === 'Editar' 
                    ?
                        /*arrayUsuarioDepto.length > 0
                            ?*/
                                <Button fullWidth={true} autoFocus onClick={handleSubmit} className={useStylesButtonFooter.root} >
                                SALVAR
                                </Button>
                           /* :
                                <Button fullWidth={true} disabled >
                                SALVAR
                                </Button>*/
                    :
                        arrayUsuarioDepto.length > 0
                        ?
                            <Button fullWidth={true} autoFocus onClick={transferirUsuarios} className={useStylesButtonFooter.root} >
                            SALVAR
                            </Button>
                        :
                            <Button fullWidth={true} autoFocus  onClick={handleSave} className={useStylesButtonFooter.root}>
                            CONCLUIR
                            </Button>

            }
          
        </DialogActions>


  
      </Dialog>
    </div>
  );
}