import React, { Component, Fragment } from 'react';
import Loading from '../../componentes/loading';
// import { NavLink } from 'react-router-dom';
import moment from 'moment-timezone';
import  'moment/locale/pt-br.js'

class CardProposicoes extends Component {
  state = {
    loading: false
  }


  render() {
      const proposicoes = this.props.dados || []
        return this.state.loading ? <Loading /> :
        <Fragment>

            
        {
        proposicoes !== undefined && Array.isArray(proposicoes) && proposicoes.length > 0 ? 
            proposicoes.map((item, index) => {
                
                return (

                    <div className="col-12 mt-3" key={index}>
                        <div className="card-proposicao-estadual-municipal p-3">
                            <div className="row">
                                <div className="col-md-6 d-flex">
                                    <div><i className="icon-editorial-small mr-3 icone-proposicao"></i></div>
                                    <div className="name line-heigt08 mt-2">
                                        {/* <NavLink to={`/proposicao-estadual-municipal/1`}> */}
                                            <h5 className="mb-1 descricao-proposicao">{item.tipo_documento}{item.numero}/{item.ano}</h5>
                                            <span className='date fs-12 d-none d-sm-block data-atualizacao-proposicao'>Última atualização: {moment(item.data_atualizacao).format('DD/MM/YYYY')}</span>
                                        {/* </NavLink> */}
                                    </div>
                                </div>

                                <div className="col-md-6 text-right">
                                    <div>
                                        <span className="tipo-proposicao">{item.planilha}</span>
                                    </div>
                                </div>
                                <div className="col-12 mt-2 mb-2">{item.ementa}</div>

                                <div className="col-md-2 col-6 mt-2">
                                    <div className="subtitulo-proposicao">Autor:</div>
                                    <div>{item.autor}</div>
                                </div>
                                <div className="col-md-2 col-6 mt-2">
                                    <div className="subtitulo-proposicao">Situação:</div>
                                    <div>{item.andamento}</div>
                                </div>
                                <div className="col-md-2 col-6 mt-2">
                                    <div className="subtitulo-proposicao">Tema:</div>
                                    <div>{item.tema}</div>
                                </div>
                                <div className="col-md-2 col-6 mt-2">
                                    <div className="subtitulo-proposicao">Casa Legislativa:</div>
                                    <div>{item.casa}</div>
                                </div>
                                <div className="col-md-2 col-6 mt-2">
                                    <div className="subtitulo-proposicao">UF:</div>
                                    <div>{item.uf}</div>
                                </div>
                                <div className="col-md-2 col-6 mt-2">
                                    <div className="subtitulo-proposicao">Município:</div>
                                    <div>{item.municipio}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                )

            }) : null}


        </Fragment>
  }

}
export default CardProposicoes