import React, { Component, Fragment } from 'react'
import { get } from '../../../services/http'
import If from '../../helpers/If';

class searchUserInput extends Component {

    state = {
        lista: [],
        temp: null,
        filtro: "",
        open: false,
        value: this.props.value || null,
        mobx: null,
        multi: this.props.multi || false,
        online: this.props.online || false
    }

    constructor(props) {
        super(props)
        this.iptFocus = React.createRef()
        this.intervalo = null
        this.focus = this.focus.bind(this)
        this.autocomplete = this.autocomplete.bind(this)
        this.preencher = this.preencher.bind(this)
    }

    componentWillMount = async () => {
        await this.preencher()
        if (!this.state.online) {
            try {
                let loading = await get(this.props.url)
                let lista = 
                loading !== undefined && Array.isArray(loading) && loading.length > 0 ?
                loading.map(item => { return { id: item.id, nome: item.nome || item.titulo || item.descricao } }) : null
                let multi = this.props.multi || false
                this.setState({ lista: lista, multi: multi })

            } catch (error) {
                console.log(error)
            }
        }

    }

    componentDidUpdate = async () => {
        this.preencher()
    }

    preencher = async () => {
        if (this.props.value && this.state.value !== this.props.value) {

            let ids = Array.isArray(this.props.value) ? this.props.value : [this.props.value]
            let lista = this.props.online ? await get(`${this.props.url}?ids=${ids.join(',')}`) : await get(this.props.url)

            if (!this.state.multi) {
                let temp = lista.find(item => item.id === this.props.value)
                await this.setState({ value: this.props.value, temp })
            } else {
                let temp = lista.filter(item => this.props.value.includes(item.id))
                await this.setState({ value: this.props.value, temp })
            }
        }
    }

    async autocomplete(event) {
        const { keyCode, target: { value } } = event;
        if (keyCode === 13) {
            event.preventDefault()
            event.stopPropagation()
        }
        if (this.iptFocus.current.value !== "") {
            this.setState({ open: true })
        } else {
            this.setState({ open: false })
        }
        if (this.props.online) {
            clearInterval(this.intervalo)
            this.intervalo = await setTimeout(() => this.filtroOnline(value), 800)
        }
        this.setState({ filtro: value })
    }

    filtro = () => {
        let disponiveis = this.state.lista;
        if (this.state.temp && !this.state.multi) disponiveis = this.state.lista.filter(u => u.id !== this.state.temp.id);
        if (this.state.temp && this.state.multi) disponiveis = this.state.lista.filter(u => !this.state.temp.map(s => s.id).includes(u.id));

        return disponiveis.filter(elem => {
            let data = (elem.nome || elem.nomeCivil || elem.titulo || elem.descricao).toLowerCase()
            return data.match(new RegExp(this.state.filtro.toLowerCase()))
        })
    }

    filtroOnline = async (value) => {
        let loading = await get(`${this.props.url}?${this.props.filtro}=${value.toLowerCase()}`)
        if (!Array.isArray(loading)) loading = loading.itens

        let lista = loading !== undefined && Array.isArray(loading) && loading.length > 0 ? 
        loading.map(
            item => { return { id: item.id, nome: item.nome || item.titulo || item.descricao } }
        ) : null
        let multi = this.props.multi || false
        this.setState({ lista: lista, multi: multi })

        return this.state.lista.filter(elem => {
            let data = (elem.nome || elem.titulo || elem.descricao).toLowerCase()
            return data.match(new RegExp(this.state.filtro.toLowerCase()))
        })
    }

    getIds = () => {
        if (this.props.multi) {
            return (
            this.state.temp !== undefined && Array.isArray(this.state.temp) && 
            this.state.temp.length > 0 ?
            this.state.temp.map(elem => elem.id) : null 
            )
        }
        return this.state.temp.id
    }

    openList = () => this.setState({ open: !this.state.open })

    selecionar = (item) => {
        if (this.state.multi) {
            let selects = this.state.temp || []
            if (!selects.includes(item)) {
                selects.push(item)
                this.setState({ temp: selects, open: false }, () => {
                    this.props.onChange(this.getIds())
                })
            }
        } else {
            this.setState({ temp: item, open: false }, () => {
                this.props.onChange(this.getIds())
            })
        }
        this.iptFocus.current.value = ""
        this.focus()
    }

    removerItem = (item) => {
        let { temp } = this.state
        let index = temp.indexOf(item)
        if (index > -1) {
            temp.splice(index, 1)
            this.setState({ temp })
        }
        this.focus()
    }

    renderSelect = () => {
        if (Array.isArray(this.state.temp)) {
            let { temp } = this.state
            return temp !== undefined && Array.isArray(temp) && temp.length > 0 ? temp.map((item, index) =>
                <span className="badge badge-multi fs-12 cursor-pointer"
                 key={index} onClick={() => this.removerItem(item)}>
                     {item.nome || item.titulo || item.descricao}
                      <span className="">X</span></span>) : null
        }
        else
            return <span className="badge badge-multi fs-12 cursor-pointer ">{this.state.temp.nome || this.state.temp.titulo || this.state.temp.descricao} <span className="">X</span></span>

    }

    focus = () => {
        this.iptFocus.current.focus()
        this.iptFocus.current.value = ""
        this.setState({ open: true })
    }

    focusOut = () => setTimeout(() => this.setState({ open: false }), 3000)

    render() {
        return (
            <Fragment>
                <div className={`control-multi cursor-pointer ${this.state.open && 'active'}`}
                    onClick={() => this.focus()}
                    onBlur={() => this.focusOut()}>
                    {this.state.temp && this.renderSelect()}
                    <input type="text"
                        autoComplete="off"
                        onKeyUp={this.autocomplete}
                        ref={this.iptFocus} />
                    <If test={this.state.open}>
                        <div className="mascara">
                            <ul className="list-group">
                                {
                                    (this.filtro() || []).map((item, index) =>
                                        <li className="list-group-item" key={`${index}-search`} onClick={() => this.selecionar(item)}>{item.nome || item.titulo || item.descricao}</li>)}
                            </ul>
                        </div>
                    </If>
                </div>

            </Fragment>
        )
    }
}

export default searchUserInput