import React, { Component } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import NaoEncontrado from '../paginas/naoEncontrada'
import ParlamentarRouter from '../paginas/parlamentar/routes'
import AgendaRouter from '../paginas/agenda/router'
import UsuarioRouter from '../paginas/usuario/router'
import DashboardRouter from '../paginas/dashboard/router'
import TemasRouter from '../paginas/temas/router'
import MidiaNews from '../paginas/midia/routes'
import NotificacoesRouter from '../paginas/notificacoes/router'
import ProposicaoEstMunRouter from '../paginas/proposicaoEstadualMunicipal/routes'
import InsightsRouter from '../paginas/insights/router'
import RelatorioRouter from '../paginas/relatorio/router'
import InteligenciaArtificial from '../paginas/inteligenciaArtificial/routes'
import ProposicaoNova from '../paginas/proposicaoNova/routes'
import ClassificacaoTemas from '../paginas/classificacaoTemas/routes'
import FeedRouter from '../paginas/feed/router'
import { Token } from '../paginas/login/router/index'

@inject('menuStore', 'authStore', 'paginacaoStore', 'uploadStore')
@withRouter
@observer

class Routes extends Component {
    render() {
        return <div className={`content ${this.props.menuStore.open ? 'open' : ''}`} onScroll={this.props.paginacaoStore.aoRolar}>
            <Switch>
                <Route exact path="/token" component={Token} />
                <Route exact path="/logout" onEnter={() => this.props.authStore.logout()} />
                <Route path="/parlamentar" component={ParlamentarRouter} />
                <Route path="/classificacao-tema" component={ClassificacaoTemas} />
                <Route path="/agenda" component={AgendaRouter} />
                <Route path="/agenda-jornal" component={AgendaRouter} />
                <Route path="/agenda-operacional" component={AgendaRouter} />
                <Route path="/usuario" component={UsuarioRouter} />
                <Route path="/notificacoes" component={NotificacoesRouter} />
                <Route path="/temas" component={TemasRouter} />
                <Route path="/midia" component={MidiaNews} />
                <Route path="/proposicao-estadual-municipal" component={ProposicaoEstMunRouter} />
                <Route path="/insights" component={InsightsRouter} />
                <Route path="/relatorio" component={RelatorioRouter} />
                <Route path="/inteligencia-artificial" component={InteligenciaArtificial} />
                <Route path="/proposicao" component={ProposicaoNova} />
                <Route exact path="/nao-encontrado" component={NaoEncontrado} />
                <Route path="/" component={DashboardRouter} />
                <Route path="/feed" component={FeedRouter} />
                <Redirect from="**" to="/nao-encontrado" />
            </Switch>
            <a ref='download' id='download' download={this.props.uploadStore.arquivo.nome} href={this.props.uploadStore.arquivo.url} className="d-none">download</a>
        </div>
    }
}
export default Routes
