import React, { Component, Fragment } from 'react'
import Query from '../../../componentes/hoc/query'
import Loading from '../../../componentes/loading'
import isList from '../../../componentes/hoc/isList'
import Item from '../items/proximoEvento'

class Eventos extends Component {
    render = () => 0 !== 2 ? null :
        <Query query={`parlamentar/${this.props.idParlamentar}/agenda`} >
            {({ fetching, data, error }) =>
                error ? <p> Error </p>
                    : fetching ? <Loading />
                        : data ?
                            data.itens.length > 0
                                ? <Fragment>
                                    <h6>Próximos Eventos</h6>
                                    {isList(this.props)(Item)}
                                </Fragment>
                                : <Fragment>
                                    <h6>Próximos Eventos</h6>
                                    <p className="text-blue bg-light p-2">Até o momento não existem próximos eventos registrados.</p>
                                </Fragment>
                            : <p>Entre em contato com o administrador</p>
            }
        </Query>
}
export default Eventos
