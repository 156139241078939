import React, { Component } from 'react'
// import GraficoLinha from './graficoLinhaParlamentar'
import GraficoLinha2 from './graficoLinhaParlamentar2'
import GraficoPizza from './graficoPizzaParlamentar'
import Accordion from '../../componentes/accordion'
import moment from 'moment-timezone';
import { get } from '../../services/http';
import ClientCheckboxWithSelect from '../../componentes/forms/basic/clientSelectWithSearch'
import { inject } from 'mobx-react'

moment.tz.setDefault('UTC');

@inject('authStore')

class ParlamentarDefault extends Component {
    state = { 
        loading: true,
        parlamentar: {}, 
        proposicoes: [], 
        ambitos: [],
        showAcaoInfluencia: false,
        showAtiva: false,
        showConcluida: false,
        acaoAtiva: false,
        acaoConcluida: false,
        dadosGraficoLinha: [
            {x: new Date(2020, 0), y: 0},
            {x: new Date(2020, 1), y: 0},
            {x: new Date(2020, 2), y: 0},
            {x: new Date(2020, 3), y: 0},
            {x: new Date(2020, 4), y: 0},
            {x: new Date(2020, 5), y: 0}
        ],
        dadosGraficoDonut: {
            pendente: 0,
            exito: 0,
            sem_exito: 0,
            exito_parcial: 0,
            null: 0
        },
        filtroSemestral:[],
        // filtroSemestral: [
        //     // {id: '2020-06-01', data: '2º Semestre / 2020'},
        //     {id: '2020-01-01', data: '1º Semestre / 2020'},
        //     {id: '2019-07-01', data: '2º Semestre / 2019'},
        //     {id: '2019-01-01', data: '1º Semestre / 2019'}
        // ],
        semestreDefault: {},
    }

    filtroDataSemestre(){
        
        let data_inicial = new Date(2019,0,1)
        let data_atual = new Date()

        let anoAtual = (data_atual.getFullYear());
        let ano = ''
        let arrayQtdAnos = []

        //OBTENHO A QTD DE ANOS APARTIR DA DATA INICIAL INFORMADA
        //NECESSARIO PARA MONTAR O COMBO DE SEMESTRES
        //[ [ 2019 ], [ 2020 ], [ 2021 ], [ 2022 ] ]
        // QTD >>>> 3
        for (let index = 0; index < 100;) {

            if (ano !== anoAtual){        
                ano = ((data_inicial.getFullYear())+ index)       
                index++;              
            }else{
                //console.log("Stop!");
                break;
            }
                
            arrayQtdAnos.push([ano])        
            
        }

  
        //QUANTIDADE DE ANOS PARA APRESENTAR NO COMBO 
        let qtd_anos = (arrayQtdAnos.length-1)
        let qtd_semestres = 1

        let arraySemestre = []
        let arraySemestreDefault = []

         //**********PREENCHEDO O COMBO DE SEMESTRES ************ 

                //PRIMEIRO FOR PARA ITERAR O ANO DE ACORDO COM A QUANTIDADE INFORMADA
                for (let i = 0; i < qtd_anos; i++) { 

                    let ano = (data_inicial.getFullYear()) + i;
                    // console.log(ano)

                    //SEGUNDO FOR PARA VERIFICAR OS SEMESTRES DO RESPECTIVO ANO 
                    //NA QUANTIDADE DE SEMESTRES O VALOR INICIA COM 0 ATE CHEGAR AO VALOR DE SEMESTRE INFORMADO ACIMA, QUE SERIA  let qtd_semestres = 1
                    for(let i = 0; i <= qtd_semestres; i++){

                        let arrayDatas = []
                        let mes = ''
                        let textoSemestre = ''

                        //SE O INDEX FOR IGUAL A 0, 1 SEMESTRE
                        if(i === 0){
                            mes = ('0'+(data_inicial.getMonth(0)+1) + '-01')
                            textoSemestre = '1º Semestre /'+ ano
                        
                        //SE NAO É SEGUNDO SEMESTRE
                        }else{
                            mes = '0'+ ((data_inicial.getMonth()+1) + 6) + '-01' 
                            textoSemestre = '2º Semestre /'+ ano
                        }

                        //MONTO O ARRAY DE OBJETOS
                        arrayDatas = {id : ano +'-'+ mes, data: textoSemestre }

                        //JOGO O ARRAY DENTRO DO ARRAY DE SEMESTRES
                        arraySemestre.push(arrayDatas)
                    }
                
                    //ATUALIZO O STATE PARA CARREGAR O COMBO
                    this.setState({filtroSemestral:arraySemestre})
                }

    
    //**********PREENCHEDO O SEMESTRE ATUAL DEFAULT ************ 
                  
            //OBTENHO O SEMESTRE ATUAL
            let semestreAtual = (data_atual.getMonth())

           
            let primeiroSemestre = {id :  data_atual.getFullYear() +'-01-01', data: '1º Semestre /'+ data_atual.getFullYear() } 
            let segundoSemestre = {id :  data_atual.getFullYear() +'-07-01', data: '2º Semestre /'+ data_atual.getFullYear() } 


            //SE SEMESTRE É MENOR QUE 6, OU SEJA MENOR QUE JUNHO
            if(semestreAtual < 6){

                arraySemestreDefault = {value: data_atual.getFullYear() +'-01-01', label: '1º Semestre /'+ data_atual.getFullYear() }
                arraySemestre.push(primeiroSemestre)

            //SE SEMESTRE É MAIOR IGUAL QUE 6, JUNHO EM DIANTE...
            }else if (semestreAtual >= 6){
            
                arraySemestreDefault = {value: data_atual.getFullYear() +'-07-01', label: '2º Semestre /'+ data_atual.getFullYear() }
                arraySemestre.push( primeiroSemestre,segundoSemestre )
            }

            //ATUALIZO A STATE
            this.setState({semestreDefault : arraySemestreDefault})
    }

    async componentWillMount() {
        this.filtroDataSemestre()
        if(this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = `/parlamentar/${this.props.dados.id}/proposicao`
        }
        await Promise.all([
            this.getDadosGraficoInteracoes(),
            this.getDadosGraficoIndice(),
            this.getAmbitos()
        ]).then(x => this.setState({ loading: false }))
    }

    async getDadosGraficoInteracoes() {
        var mes = moment(new Date()).format('MM')
        var ano = moment(new Date()).format('YYYY')
        await get(`parlamentar/${this.props.dados.id}/grafico-interacoes/${mes}/${ano}`)
        .then(response => {
            //console.log('response ==>',response)
            const mesReferencia = parseInt(mes) < 7 ? 1 : 7
            const dadosGrafico = [
                {x: new Date(ano, mesReferencia === 1 ? 0 : 6), y: response.mes1},
                {x: new Date(ano, mesReferencia === 1 ? 1 : 7), y: response.mes2},
                {x: new Date(ano, mesReferencia === 1 ? 2 : 8), y: response.mes3},
                {x: new Date(ano, mesReferencia === 1 ? 3 : 9), y: response.mes4},
                {x: new Date(ano, mesReferencia === 1 ? 4 : 10), y: response.mes5},
                {x: new Date(ano, mesReferencia === 1 ? 5 : 11), y: response.mes6}
            ]
            this.setState({ dadosGraficoLinha: dadosGrafico })
        })
        .catch(err => err)
    }

    async getDadosGraficoIndice() {
        await get(`parlamentar/${this.props.dados.id}/grafico-indice`)
        .then(response => {
            // console.log('resposta do grafico ===>',response)
            this.setState({ dadosGraficoDonut: response })
        })
        .catch(err => err)
    }

    async buscarInteracoesPorData(newValue) {
        //console.log(newValue)
        const mes = newValue.value !== undefined ? newValue.value.slice(5, 7) : null
        const ano = newValue.value !== undefined ? newValue.value.slice(0, 4) : null
        await get(`parlamentar/${this.props.dados.id}/grafico-interacoes/${mes}/${ano}`)
        .then(response => {
            //console.log(response)
            const mesReferencia = parseInt(mes) < 7 ? 1 : 7
            const dadosGrafico = [
                {x: new Date(ano, mesReferencia === 1 ? 0 : 6), y: response.mes1},
                {x: new Date(ano, mesReferencia === 1 ? 1 : 7), y: response.mes2},
                {x: new Date(ano, mesReferencia === 1 ? 2 : 8), y: response.mes3},
                {x: new Date(ano, mesReferencia === 1 ? 3 : 9), y: response.mes4},
                {x: new Date(ano, mesReferencia === 1 ? 4 : 10), y: response.mes5},
                {x: new Date(ano, mesReferencia === 1 ? 5 : 11), y: response.mes6}
            ]
            this.setState({ dadosGraficoLinha: dadosGrafico })
        })
        .catch(err => err)
    }

    getAmbitos = async () => {
        [1, 2, 3].forEach(casaLegislativa => get(`ambito/${casaLegislativa}`)
        .then(r => {
            this.setState({ ambitos: this.state.ambitos.concat(r) })
        }));
      }

    abreAcaoInfluencia(showAcaoInfluencia, index) {
        this.setState({ showAcaoInfluencia: showAcaoInfluencia })
    }

    abreAtiva(showAtiva) {
        this.setState({ showAtiva: showAtiva })
    }

    abreConcluida(showConcluida) {
        this.setState({ showConcluida: showConcluida })
    }

    selecionarSemestre(newValue) {
        this.setState({ semestreDefault: newValue })
        this.buscarInteracoesPorData(newValue)
    }


    render() {
        //console.log(this.state.filtroSemestral)
        const proposicoes = this.props.proposicoes
        const influencia = this.props.influencia
        const idsAcoes = this.props.idsAcoes
        //console.log('idsAcoes', idsAcoes)
        //console.log('proposicoes', proposicoes)
        return (
            <div>
                <div className="p-5 bg-white mt-4">
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <p className="titulo-grafico-linha">Interações</p>
                        </div>
                        <div className="col-md-6 text-left">
                            <ClientCheckboxWithSelect 
                                css="no-border"
                                value={this.state.filtroSemestral[0].id}
                                options={
                                    this.state.filtroSemestral !== undefined &&
                                    Array.isArray(this.state.filtroSemestral) && 
                                    this.state.filtroSemestral.length > 0 ?
                                     this.state.filtroSemestral.map(
                                         item => ({ value: item.id, label: item.data })): null}
                                onChange={(newValue) => this.selecionarSemestre(newValue)} 
                                placeholder={this.state.semestreDefault.label}
                                label={'Visualizar'}
                            />
                        </div>
                    </div>
                    <GraficoLinha2 
                        dados={this.state.dadosGraficoLinha}
                    />
                </div>

                <div className="p-5 bg-white mt-4 mb-4">
                    <div className="text-center titulo-grafico-pizza mb-5">
                        Índice de Resultado de Ações
                    </div>
                    <div className="row">
                        {/* <div className="col-xl-1 d-none d-xl-block"></div> */}
                        <div className="col-lg-6">
                            <GraficoPizza 
                                dados={this.state.dadosGraficoDonut}
                            />
                        </div>
                        {
                            this.state.dadosGraficoDonut.pendente === 0 &&
                            this.state.dadosGraficoDonut.exito === 0 &&
                            this.state.dadosGraficoDonut.sem_exito === 0 &&
                            this.state.dadosGraficoDonut.exito_parcial === 0 &&
                            this.state.dadosGraficoDonut.null === 0 ?

                            <div className="text-center col-12">
                                <div style={{ padding: '30px', border: '1px solid grey', fontSize: '1.3rem', position: 'relative', top: '-250px' }}>
                                    Não Existem dados de Ações de Influência para este parlamentar!
                                </div>
                            </div>
                            :
                            
                            <div className="col-lg-6 div-indice pl-5">
                                <div className="row">
                                    <div className="col-1">
                                        <div className="indice-exito mt-1"></div>
                                    </div>
                                    <div className="col-10 indice-descricao">
                                        <div>Êxito</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <div className="indice-sem-exito mt-1"></div>
                                    </div>
                                    <div className="col-10 indice-descricao">
                                        <div>Sem Êxito</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <div className="indice-exito-parcial mt-1"></div>
                                    </div>
                                    <div className="col-10 indice-descricao">
                                        <div>Êxito Parcial</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <div className="indice-pendente mt-1"></div>
                                    </div>
                                    <div className="col-10 indice-descricao">
                                        <div>Pendente</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                        <div className="indice-sem-info mt-1"></div>
                                    </div>
                                    <div className="col-10 indice-descricao">
                                        <div>Sem Informação</div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </div>

                {proposicoes.length === 0 ? null :

                    <div className="p-5 bg-white mt-4">
                        <div className="mb-3">

                            {idsAcoes !== undefined &&
                            Array.isArray(idsAcoes) && idsAcoes.length > 0 ?
                            idsAcoes.map((id, index) => {
                                //console.log("id",id)
                                return (
                                    <div key={id.id}>
                                        <Accordion 
                                            mb={''}
                                            onClick={() => this.abreAcaoInfluencia(true, index)}
                                            titulo={'Ação de Influencia'} 
                                            aberto={this.state.showAcaoInfluencia}
                                            proposicaoDescricao={id.nome}
                                            proposicaoId={id.id}
                                        >
                                            <div style={{ backgroundColor: '#E8E8E8'}}>
                                                <hr />
                                            </div>

                                            <Accordion 
                                                mb={''}
                                                onClick={() => this.abreAtiva(true)}
                                                titulo={'Ativas'} 
                                                aberto={this.state.showAtiva}
                                                numero={proposicoes.filter(item => (item.resultado === 0 || item.resultado === 99 || item.resultado === null) && item.id_proposicao_novo === id.id).length}
                                            >
                                                {
                                                proposicoes !== undefined && 
                                                Array.isArray(proposicoes) && proposicoes.length > 0 ?
                                                proposicoes.map((item, index) => {
                                                    var resultado = ''
                                                    var classeResultado = ''
                                                    switch (item.resultado) {
                                                        case 0 || 99:
                                                            resultado = 'Pendente';
                                                            classeResultado = 'indice-pendente';
                                                            break;
                                                        case 1:
                                                            resultado = 'Êxito';
                                                            classeResultado = 'indice-exito';
                                                            break;
                                                        case 2:
                                                            resultado = 'Sem êxito';
                                                            classeResultado = 'indice-sem-exito';
                                                            break;
                                                        case 3:
                                                            resultado = 'Êxito parcial';
                                                            classeResultado = 'indice-exito-parcial';
                                                            break;
                                                        case null:
                                                            resultado = 'Sem informação';
                                                            classeResultado = 'indice-sem-info';
                                                            break;
                                                        default:
                                                            resultado = 'Sem informação';
                                                            classeResultado = 'indice-sem-info'
                                                    }
                                                    var casaLegislativa = ''
                                                        switch (item.casa_legislativa) {
                                                            case 1:
                                                                casaLegislativa = 'Senado Federal';
                                                                break;
                                                            case 2:
                                                                casaLegislativa = 'Câmara dos Deputados';
                                                                break;
                                                            case 3:
                                                                casaLegislativa = 'Congresso Nacional';
                                                                break;
                                                            default:
                                                                casaLegislativa = 'Sem informação'
                                                        }
                                                    return (
                                                        item.id_proposicao_novo === id.id && (item.resultado === 0 || item.resultado === 99 || item.resultado === null) ?
                                                        <div className="card-proposicao-parlamentar mb-3" key={item.id_proposicao_novo}>
                                                            <Accordion 
                                                                mb={''}
                                                                onClick={() => this.abreAtiva(true)}
                                                                titulo={'Ação de Influência'} 
                                                                subtitle={`criado em ${moment(item.criado_em).format('DD/MM/YYYY')}`}
                                                                aberto={this.state.showConcluida}
                                                            >
                                                                <div className="row p-3">
                                                                    <div className="col-md-4 col-12">
                                                                        <div className="titulo-card-acao-influencia">Responsável</div>
                                                                        <div>{item.responsavel}</div>
                                                                    </div>
                                                                    <div className="col-md-4 col-12">
                                                                        <div className="titulo-card-acao-influencia">Prazo</div>
                                                                        <div>{moment(item.prazo).format('DD/MM/YYYY')}</div>
                                                                    </div>
                                                                    <div className="col-md-4 col-12">
                                                                        <div className="titulo-card-acao-influencia">Resultado</div>
                                                                        <div className="d-flex">
                                                                            <div className={`${classeResultado} mt-1 mr-2`}></div>
                                                                            <div>{resultado}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-12 mt-4">
                                                                        <div className="titulo-card-acao-influencia">Influência sobre</div>
                                                                        {influencia !== undefined &&
                                                                         Array.isArray(influencia) && influencia.length > 0 ?
                                                                        influencia.map((infSobre, indexInf) => {
                                                                            const nomeParlamentar = infSobre.nome.toLowerCase().replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
                                                                            return (
                                                                                infSobre.id_acao_influencia === item.id ?
                                                                                <div key={indexInf}>{nomeParlamentar}</div>
                                                                                : null
                                                                            )
                                                                        }): null}
                                                                    </div>
                                                                    <div className="col-md-4 col-12 mt-4">
                                                                        <div className="titulo-card-acao-influencia">Casa Legislativa</div>
                                                                        <div>{casaLegislativa}</div>
                                                                    </div>
                                                                    <div className="col-md-4 col-12 mt-4">
                                                                        <div className="titulo-card-acao-influencia">Âmbito</div>
                                                                        {this.state.ambitos !== undefined &&
                                                                         Array.isArray(this.state.ambitos) && this.state.ambitos.length > 0 ?
                                                                        this.state.ambitos.map((ambito, indexAmbito) => {
                                                                            return (
                                                                                ambito.id === item.ambito_id ?
                                                                                <div key={indexAmbito}>{ambito.sigla + ' ' + ambito.siglaTipo + ' ' + ambito.nome}</div>
                                                                                : null
                                                                            )
                                                                        }): null}
                                                                    </div>

                                                                    <div className="col-12 mt-4">
                                                                        <div className="text-truncate text-wrap">
                                                                        {item.descricao}
                                                                        </div>
                                                                    </div>

                                                                    {this.props.anexos !== undefined
                                                                    && Array.isArray(this.props.anexos) && this.props.anexos.length > 0 ?
                                                                    this.props.anexos.map((anexo,item) => {
                                                                        return (
                                                                            anexo.id_referencia !== item.id ? null :
                                                                            <div className="col-md-6 mt-4" key={index}>
                                                                                <div className="card-anexo-pag-parlamentar">
                                                                                <div className=" ml-2" style={{content: "var(--clipsAnexos)", width:"11px"}}></div>
                                                                                    <span className="align-middle Client-link">{' '}{anexo.nome.substring(0,30)}</span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }) : null}
                                                                </div>
                                                            </Accordion>
                                                        </div>
                                                        : null
                                                    )
                                                }):null}
                                            </Accordion>

                                            <Accordion 
                                                mb={''}
                                                onClick={() => this.abreAtiva(true)}
                                                titulo={'Concluidas'} 
                                                aberto={this.state.showAtiva}
                                                numero={proposicoes.filter(item => (item.resultado === 1 || item.resultado === 2 || item.resultado === 3) && item.id_proposicao_novo === id.id).length}
                                            >

                                                {proposicoes !== undefined &&
                                                Array.isArray(proposicoes) && proposicoes.length > 0 ?
                                                proposicoes.map((item, index) => {
                                                    var resultado = ''
                                                    var classeResultado = ''
                                                    switch (item.resultado) {
                                                        case 0 || 99:
                                                            resultado = 'Pendente';
                                                            classeResultado = 'indice-pendente';
                                                            break;
                                                        case 1:
                                                            resultado = 'Êxito';
                                                            classeResultado = 'indice-exito';
                                                            break;
                                                        case 2:
                                                            resultado = 'Sem êxito';
                                                            classeResultado = 'indice-sem-exito';
                                                            break;
                                                        case 3:
                                                            resultado = 'Êxito parcial';
                                                            classeResultado = 'indice-exito-parcial';
                                                            break;
                                                        case null:
                                                            resultado = 'Sem informação';
                                                            classeResultado = 'indice-sem-info';
                                                            break;
                                                        default:
                                                            resultado = 'Sem informação';
                                                            classeResultado = 'indice-sem-info'
                                                    }
                                                    var casaLegislativa = ''
                                                        switch (item.casa_legislativa) {
                                                            case 1:
                                                                casaLegislativa = 'Senado Federal';
                                                                break;
                                                            case 2:
                                                                casaLegislativa = 'Câmara dos Deputados';
                                                                break;
                                                            case 3:
                                                                casaLegislativa = 'Congresso Nacional';
                                                                break;
                                                            default:
                                                                casaLegislativa = 'Sem informação'
                                                        }
                                                    return (
                                                        item.id_proposicao_novo === id.id && (item.resultado === 1 || item.resultado === 2 || item.resultado === 3) ?
                                                        <div className="card-proposicao-parlamentar mb-3" key={item.id_proposicao_novo}>
                                                            <Accordion 
                                                                mb={''}
                                                                onClick={() => this.abreAtiva(true)}
                                                                titulo={'Ação de Influência'} 
                                                                subtitle={`criado em ${moment(item.criado_em).format('DD/MM/YYYY')}`}
                                                                aberto={this.state.showConcluida}
                                                            >
                                                                <div className="row p-3">
                                                                    <div className="col-md-4 col-12">
                                                                        <div className="titulo-card-acao-influencia">Responsável</div>
                                                                        <div>{item.responsavel}</div>
                                                                    </div>
                                                                    <div className="col-md-4 col-12">
                                                                        <div className="titulo-card-acao-influencia">Prazo</div>
                                                                        <div>{moment(item.prazo).format('DD/MM/YYYY')}</div>
                                                                    </div>
                                                                    <div className="col-md-4 col-12">
                                                                        <div className="titulo-card-acao-influencia">Resultado</div>
                                                                        <div className="d-flex">
                                                                            <div className={`${classeResultado} mt-1 mr-2`}></div>
                                                                            <div>{resultado}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-12 mt-4">
                                                                        <div className="titulo-card-acao-influencia">Influência sobre</div>
                                                                        {influencia !== undefined &&
                                                                        Array.isArray(influencia) && influencia.length > 0 ?
                                                                        influencia.map((infSobre, indexInf) => {
                                                                            const nomeParlamentar = infSobre.nome.toLowerCase().replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
                                                                            return (
                                                                                infSobre.id_acao_influencia === item.id ?
                                                                                <div key={indexInf}>{nomeParlamentar}</div>
                                                                                : null
                                                                            )
                                                                        }): null}
                                                                    </div>
                                                                    <div className="col-md-4 col-12 mt-4">
                                                                        <div className="titulo-card-acao-influencia">Casa Legislativa</div>
                                                                        <div>{casaLegislativa}</div>
                                                                    </div>
                                                                    <div className="col-md-4 col-12 mt-4">
                                                                        <div className="titulo-card-acao-influencia">Âmbito</div>
                                                                        {this.state.ambitos !== undefined &&
                                                                        Array.isArray(this.state.ambitos) && this.state.ambitos.length > 0 ?
                                                                        this.state.ambitos.map((ambito, indexAmbito) => {
                                                                            return (
                                                                                ambito.id === item.ambito_id ?
                                                                                <div key={indexAmbito}>{ambito.sigla + ' ' + ambito.siglaTipo + ' ' + ambito.nome}</div>
                                                                                : null
                                                                            )
                                                                        }): null}
                                                                    </div>

                                                                    <div className="col-12 mt-4">
                                                                        <div className="text-truncate text-wrap">
                                                                        {item.descricao}
                                                                        </div>
                                                                    </div>

                                                                    {this.props.anexos !== undefined &&
                                                                     Array.isArray(this.props.anexos) && this.props.anexos.length > 0 ? 
                                                                    this.props.anexos.map(anexo => {
                                                                        return (
                                                                            anexo.id_referencia !== item.id ? null :
                                                                            <div className="col-md-6 mt-4" key={ item.id}>
                                                                                <div className="card-anexo-pag-parlamentar">
                                                                                <div className=" ml-2" style={{content: "var(--clipsAnexos)", width:"11px"}}></div>
                                                                                    <span className="align-middle Client-link">{' '}{anexo.nome.substring(0,30)}</span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }) : null}
                                                                </div>
                                                                </Accordion>
                                                        </div>
                                                        : null
                                                    )
                                                }) : null}

                                            </Accordion>

                                        </Accordion>
                                    </div>
                                )
                            }):null}

                        </div>
                    </div>

                }
            </div>
        )
    }
}

export default ParlamentarDefault;