import React, { Component } from 'react'
import ContentEditable from 'react-contenteditable'
import { inject, observer } from 'mobx-react'
import UploadFile from '../../componentes/forms/inputs/upload'
import { post } from "../../services/http"

@inject('alertaStore', 'uploadStore', 'authStore')
@observer


class AcontecimentosNovoComentarioParlamentar extends Component {
    constructor(props) {
        super(props);
        this.salvar = this.salvar.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
            salvando: false,
            arquivo: [],
            formatado: props.valor || '',
            dataHora: ''
        }
    }

    componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.valor !== this.props.valor) {
            this.setState({ formatado: this.props.valor })
            document.getElementById(this.props.id).focus()
        }
    }

    onChange({ target: { value } }) {
        let formatado = value.replace(/<(?!br\s*\/?)[^>]+>/g, '')
        this.setState({ formatado })
    }


    dataAtual(event) {
        const dataAcontecimento = event.target.value || new Date()
        this.setState({ dataHora: dataAcontecimento })
    }



    async salvar() {

        const data = {
            data: this.state.dataHora === '' ? new Date() : this.state.dataHora,
            texto: this.state.formatado,
            idParlamentar: this.props.idParlamentar,
            modulo: 'acontecimentos-parlamentar'
        }
        await post('/acontecimento/parlamentar', data)
            .then(res => {
                if (res.length > 0) {
                    window.location.reload()
                }
            })
    }

    render() {
        const { formatado } = this.state
        const tamanhoTela = window.innerWidth
        console.log('this.props--------------->',this.props)
        return <div>
            <div className="p-4">
                <div className="row">
                    {
                        this.props.authStore.ehVisualizador === true ? null :
                            <>

                                {

                                    tamanhoTela < 600

                                        ?

                                        <div className="col-12 col-sm-6 col-lg-6 col-xl-6 mb-4">
                                            <div>
                                                <label htmlFor="data-atual" style={{ fontSize: "12px", fontWeight: "normal", color: "var(--linkText)" }}>Data</label>
                                            </div>
                                            <div>
                                                <input
                                                    style={{ fontSize: "16px", marginTop: "-10px", width: "100%", color: "lightgray", borderBottom: "1px solid lightgray" }}
                                                    type="date"
                                                    id="data-atual"
                                                    className="form-control"
                                                    onChange={event => this.dataAtual(event)}
                                                />
                                            </div>

                                        </div>

                                        :

                                        <div className="col-12 col-sm-6 col-lg-6 col-xl-6 mb-4">
                                            <div>
                                                <label htmlFor="data-atual" style={{ fontSize: "12px", fontWeight: "normal", color: "var(--linkText)" }}>Data</label>
                                            </div>
                                            <div>
                                                <input
                                                    style={{ fontSize: "16px", marginTop: "-10px", width: "100%", color: "lightgray", borderBottom: "1px solid lightgray" }}
                                                    type="date"
                                                    id="data-atual"
                                                    className="form-control"
                                                    onChange={event => this.dataAtual(event)}
                                                />
                                            </div>

                                        </div>
                                }

                                {/* // <div className="col-12 col-sm-6 col-lg-6 col-xl-6 mb-4">
                                    //     <label htmlFor="data-atual" className="label-data-acontecimento">Data</label>
                                //     <input
                                        type="date"
                                        id="data-atual"
                                        className="form-control input-data-acontecimento"
                                        onChange={event => this.dataAtual(event)}
                                    />

                                </div>

                                <div className="col-12 col-sm-12 col-lg-12 col-xl-12 text-truncate text-wrap overflow-auto">
                                    <ContentEditable
                                        // id={this.props.id}
                                        id="acao-preparatoria-acontecimento-comentario"
                                        className="border-none shadow-none"
                                        html={formatado}
                                        placeholder={"Digite algo..."}
                                        onChange={this.onChange} />
                                </div> */}

                                {
                                    tamanhoTela < 600 ?
                                        // =====MOBILE======
                                        <div style={{ marginLeft: "-10px", marginRight: "-10px", width: "108%" }}>
                                            <hr style={{ width: "100%" }} />
                                        </div>
                                        :
                                        null
                                }


                                <div className="col-12 col-sm-12 col-lg-12 col-xl-12 text-truncate text-wrap overflow-auto">
                                    <ContentEditable
                                        // id={this.props.id}
                                        id="acao-preparatoria-acontecimento-comentario"
                                        className="border-none shadow-none"
                                        html={formatado}
                                        placeholder={"Digite aqui"}
                                        onChange={this.onChange} />
                                </div>



                            </>
                    }
                    <div className="row"></div>
                </div>
            </div>



            {

                tamanhoTela < 600

                    ?

                    // ======MOBILE======

                    <div className="p-4 ">
                        <div className="row mb-2 d-flex justify-content-start">
                            {
                                this.state.arquivo !== undefined &&
                                    Array.isArray(this.state.arquivo) &&
                                    this.state.arquivo.length > 0 ?
                                    this.state.arquivo.map((item, index) =>
                                        <div className="mt-2 mb-2 mr-3" key={item.arquivo} >
                                            {/* <div className="mw-200 d-flex border p-2">

                                        <div className="float-left mt-1 row w-100 cursor-pointer" onClick={() => this.props.uploadStore.downloadArquivo(item, this.refs).then(x => x)}>
                                            <div className=" ml-2" style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                            <span className=" ml-2 Client-link text-truncate" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px", overflow: "hidden" }}>{item.nome}</span>

                                        </div>

                                        {this.props.authStore.ehVisualizador === true ? null :
                                            <span className="float-right ml-2 cursor-pointer" onClick={() => {
                                                if (item.id === undefined) {
                                                    item = { nome: item.nome, id_referencia: 0 }
                                                    this.props.uploadStore.removerAnexoSemId(item);
                                                    this.state.arquivo.splice(index, 1)
                                                    this.setState({ arquivo: this.state.arquivo });
                                                } else {
                                                    this.props.uploadStore.removerAnexo(item);
                                                    this.setState({ arquivo: this.state.arquivo.filter(anexo => anexo.arquivo !== item.arquivo) });
                                                }
                                            }}>
                                                <img src={require('../../design/assets/images/trash-gray.svg')} alt="excluir anexo" />
                                            </span>
                                        }
                                    </div> */}
                                            <div className=" d-flex border p-2 " style={{ borderRadius: "2px" }}>
                                                <div className=" mt-1 pl-2 row cursor-pointer" style={{ width: "290px" }} onClick={() => this.props.uploadStore.downloadArquivo(item, this.refs).then(x => x)}>
                                                    <div className=" ml-2" style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                    <span className=" ml-2 Client-link text-truncate" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px", overflow: "hidden", fontSize: "12px" }}>{item.nome}</span>

                                                </div>

                                                {this.props.authStore.ehVisualizador === true ? null :
                                                    <span className="float-right ml-2 cursor-pointer" onClick={() => {
                                                        if (item.id === undefined) {
                                                            item = { nome: item.nome, id_referencia: item.id_referencia }
                                                            this.props.uploadStore.removerAnexoSemId(item);
                                                            this.state.arquivo.splice(index, 1)
                                                            this.setState({ arquivo: this.state.arquivo });
                                                        } else {
                                                            this.props.uploadStore.removerAnexo(item);
                                                            this.setState({ arquivo: this.state.arquivo.filter(anexo => anexo.arquivo !== item.arquivo) });
                                                        }
                                                    }}>
                                                        <img src={require('../../design/assets/images/trash-gray.svg')} alt="excluir anexo" />
                                                    </span>
                                                }
                                            </div>

                                        </div>) : null}
                        </div>
                    </div>

                    :

                    // =======desk=========

                    <div className="pl-4">
                        <div className="row mb-2 d-flex justify-content-start">
                            {
                                this.state.arquivo !== undefined &&
                                    Array.isArray(this.state.arquivo) &&
                                    this.state.arquivo.length > 0 ?
                                    this.state.arquivo.map((item, index) =>
                                        <div className=" mt-2 mb-2 mr-3 " key={item.arquivo} >
                                            <div className=" d-flex border p-2 " style={{ borderRadius: "2px" }}>

                                                <div className=" mt-1 pl-2 row cursor-pointer" style={{ width: "315px" }} onClick={() => this.props.uploadStore.downloadArquivo(item, this.refs).then(x => x)}>
                                                    <div className=" ml-2" style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                    <span className=" ml-2 Client-link text-truncate" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px", overflow: "hidden", fontSize: "12px" }}>{item.nome}</span>

                                                </div>

                                                {this.props.authStore.ehVisualizador === true ? null :
                                                    <span className="float-right ml-2 cursor-pointer" onClick={() => {
                                                        // this.props.uploadStore.removerAnexo(item);
                                                        // this.setState({ arquivo: this.state.arquivo.filter(anexo => anexo.arquivo !== item.arquivo) });
                                                        if (item.id === undefined) {
                                                            item = { nome: item.nome, id_referencia: item.id_referencia }
                                                            this.props.uploadStore.removerAnexoSemId(item);
                                                            this.state.arquivo.splice(index, 1)
                                                            this.setState({ arquivo: this.state.arquivo });
                                                        } else {
                                                            this.props.uploadStore.removerAnexo(item);
                                                            this.setState({ arquivo: this.state.arquivo.filter(anexo => anexo.arquivo !== item.arquivo) });
                                                        }
                                                    }}>
                                                        <img src={require('../../design/assets/images/trash-gray.svg')} alt="excluir anexo" />
                                                    </span>
                                                }
                                            </div>
                                        </div>) : null}
                        </div>
                    </div>

            }


            {
                tamanhoTela < 600 ?
                    // =======MOBILE======
                    <div>
                        <hr className="mb-0" />
                        <div className="w-100 text-right pl-3 pb-2 pt-2 clearfix">
                            <div className="float-right mr-4">
                                {this.props.authStore.ehVisualizador === true ? null :
                                    <div>
                                        <img className='mr-2 send-icon' src={formatado.length > 0 ? require('../../design/assets/images/send.svg') : require('../../design/assets/images/send.svg')} alt="" />
                                        <span style={{ textDecorationLine: "underline", color: "#9B9B9B" }} onClick={this.salvar}>
                                            Enviar
                                        </span>
                                        {/* <span className={formatado.length > 0 ? 'Client-cinza-claro cursor-pointer' : 'Client-cinza-claro'} style={{textDecoration:"undeline"}} onClick={this.salvar}>
                                            {this.state.salvando ? 'Enviando' : 'Enviar'}
                                        </span> */}
                                    </div>
                                }
                            </div>
                            {this.props.authStore.ehVisualizador === true ? null :
                                <div className="float-left">
                                    <UploadFile linkButton={true} modulo={'acontecimentos'} id_referencia={999} onChange={anexo => this.setState({ arquivo: [...this.state.arquivo, ...anexo] })} />
                                </div>
                            }
                        </div>
                    </div>
                    :
                    // =======DESK======
                    <div>
                        <hr className="mb-0" />
                        <div className="w-100 text-right pl-3 pb-2 pt-2 clearfix">
                            <div className="float-right mr-4">
                                {this.props.authStore.ehVisualizador === true ? null :
                                    <div>
                                        <img className='mr-2 send-icon' src={formatado.length > 0 ? require('../../design/assets/images/send.svg') : require('../../design/assets/images/send.svg')} alt="" />
                                        <span style={{ textDecorationLine: "underline", color: "#9B9B9B" }} onClick={this.salvar}>
                                            Enviar
                                        </span>
                                        {/* <span className={formatado.length > 0 ? 'Client-cinza-claro cursor-pointer' : 'Client-cinza-claro'} style={{textDecoration:"undeline"}} onClick={this.salvar}>
                                            {this.state.salvando ? 'Enviando' : 'Enviar'}
                                        </span> */}
                                    </div>
                                }
                            </div>
                            {this.props.authStore.ehVisualizador === true ? null :
                                <div className="float-left">
                                    <UploadFile linkButton={true} modulo={'acontecimentos-parlamentar'} idParlamentar={this.props.idParlamentar} id_referencia={999} onChange={anexo => this.setState({ arquivo: [...this.state.arquivo, ...anexo] })} />
                                    {/* <UploadFile linkButton={true} modulo={'acontecimentos'} id_referencia={item.id} onChange={anexo => this.setState({ anexos: [...this.state.anexos, ...anexo], showEdit: false })} /> */}

                                </div>
                            }
                        </div>
                    </div>




            }


            {/* <hr className="mb-0" />
            <div className="w-100 text-right p-4 clearfix">
                <div className="float-right mr-4">
                    {this.props.authStore.ehVisualizador === true ? null :
                        <div>
                            <img className='mr-2 send-icon' src={formatado.length > 0 ? require('../../design/assets/images/send-red.svg') : require('../../design/assets/images/send.svg')} alt="" />
                            <span className={formatado.length > 0 ? 'Client-vermelho cursor-pointer' : 'Client-cinza-claro'} onClick={this.salvar}>
                                {this.state.salvando ? 'Enviando' : 'Enviar'}
                            </span>
                        </div>
                    }
                </div>
                {this.props.authStore.ehVisualizador === true ? null :
                    <div className="float-right mr-4" >
                        <UploadFile linkButton={true} modulo={'acontecimentos'} id_referencia={this.props.idParlamentar} onChange={anexo => this.setState({ arquivo: [...this.state.arquivo, ...anexo] })} />
                    </div>
                }
            </div> */}


        </div>;
    }
}

export default props => <AcontecimentosNovoComentarioParlamentar {...props} />