import React, { Component } from 'react'
import { inject, observer } from "mobx-react"
//import { firstBy } from "thenby";
import { get, put, post } from "../../services/http";
// import MediaItem from "../../componentes/mediaItem";
//import Accordion from '../../componentes/accordionCustomUsuarios'
import Loading from "../../componentes/loading";
//import permissao, { permissoes } from "../../enums/permissoes";
//import SelectBasicInput from "../../componentes/forms/basic/selectBasicInput";
import ClientCheckboxWithSelect from '../../componentes/forms/basic/clientSelectWithSearch'
//import CheckboxInput from "../../componentes/forms/basic/checkboxInput";
import ModalCadUsuario from './modal-cadastro-usuario'
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import alertaStore from '../../stores/alertaStore';
import Pagination from "react-js-pagination";
import ConfirmModal from '../../componentes/confirmModal';
// import searchUserInput from '../../componentes/forms/basic/searchUserInputOffline';




@inject('authStore')
@observer

class GerenciamentoUsuarios extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingTable: false,
            status: 2,
            listarUsuarios: [],
            listarUsuariosFiltro: [],
            situacao: [
                { id: 1, situacao: 'Todos' },
                { id: 2, situacao: 'Ativo' },
                { id: 3, situacao: 'Inativo' }
            ],
            situacaoDefault: { value: 0, label: 'Todos' },
            departamento: { value: 0, label: 'Todos' },
            departamentoList: [],

            paginaAtual: 1,
            limite: 10,
            quantidade: 0,
            palavraFiltro: '',
            showModalDelete: false,
            itemDelete: [],
        }
    }

    async componentDidMount() {
        // if(this.props.authStore.ehAdministrador !== true) {
        //     window.location.href = '/usuario/perfil'
        //   }
        await this.carregarUsuarios(1)
        this.getDepartamentos()
    }

    async carregarUsuarios(pagina) {

        this.setState({ loadingTable: true })

        const offset = pagina === 1 ? 0 : (pagina - 1) * this.state.limite
        await get(`/usuarios/listar/usuario/${this.state.limite}/${offset}`)
            .then(resp => {
                // console.log('RESP CARR>>>>>',resp)
                this.setState({ listarUsuarios: resp.data, quantidade: Number(resp.totalCount), loadingTable: false })
            })
            .catch(err => err)
    }

    carregarPaginaNova = async (pagina) => {
        //console.log('PAGINA>>>>>',pagina)
        this.setState({ paginaAtual: pagina })
        await this.carregarUsuarios(pagina)
    }

    async alternarSituacao(id, idPermissao, newValue) {
        if (parseInt(idPermissao) === parseInt(newValue)) return;
        await this.setState({ loading: true })
        await put(`/usuario/${id}/situacao`)
        await this.setState({ loading: false })
        await this.carregarUsuarios();
    }


    selecionarSituacao(situacaoDefault) {
        // USUARIOS ATIVOS
        if (situacaoDefault.value === 2) {
            this.setState({ situacaoDefault: situacaoDefault, status: 1 })
        }
        // USUARIOS INATIVOS
        if (situacaoDefault.value === 3) {
            this.setState({ situacaoDefault: situacaoDefault, status: 0 })
        }
        // TODOS OS USUARIOS
        if (situacaoDefault.value === 1) {
            this.setState({ situacaoDefault: situacaoDefault, status: 2 })
        }
    }

    handleUser = async (tipoSave, infoUser, showModalDelete) => {

        const data = infoUser
        const tipo = tipoSave

        this.setState({ loadingTable: true })

        if (tipo === 'NovoUsuario') {

            // console.log(data)
            await post(`/usuarios/cadastrar/usuarionovo`, data)
                .then(resp => {
                    // console.log('RESP', resp)
                    if (resp === undefined) {
                        alertaStore.erro("Limite de cadastro atingido. Não é possível cadastrar usuário")
                        this.setState({
                            loadingTable: false
                        })
                        setTimeout(() => {
                            window.location.reload()
                        }, 2000);

                    }

                    if (resp.name === 'error') {
                        alertaStore.erro(`Erro: Campo: ${resp.detail} já está cadastrado no banco!!`)
                        window.location.reload()
                    } else {
                        this.setState({ loadingTable: false, paginaAtual: 1 })
                        alertaStore.criar('Usuário criado com sucesso.')
                        window.location.reload()

                    }

                })
                .catch(err => err)

        } else if (tipo === 'AtualizarUsuario') {

            //console.log('XXXXXX>>>>>',data)

            await put(`/usuarios/editar/usuarionovo/${data.id_usuario}`, data)
                .then(resp => {
                    //console.log('RESP',resp)

                    if (resp[0] !== '') {
                        alertaStore.criar('Usuário alterado com sucesso.')
                        this.setState({ loadingTable: false })
                        //this.carregarUsuarios(this.state.paginaAtual)
                        window.location.reload()

                    } else {

                        alertaStore.erro(`Erro ao atualizar usuario!!`)
                        window.location.reload()
                    }

                })
                .catch(err => err)

        } else if (tipo === 'DeletarUsuario') {
            this.setState({ showModalDelete: showModalDelete, loadingTable: false, itemDelete: data })

        }

    }

    async excluir(showModal, save, data) {
        //console.log('XXX>>>',data)

        this.setState({ showModalDelete: showModal })
        if (save) {
            this.setState({ loadingTable: !this.state.loadingTable })
            await put(`/usuarios/apagar/usuarionovo/${data.id_usuario}`, data)
                .then(resp => {
                    //console.log('RESP',resp)
                    this.setState({ loadingTable: false })
                    this.carregarUsuarios(this.state.paginaAtual)
                    alertaStore.criar('Usuário excluido com sucesso.')


                })
                .catch(err => err)
        }
    }

    async getDadosUsuarioFiltro() {

        this.setState({ loadingTable: true })
        await get(`/usuarios/buscar-usuario/${this.state.palavraFiltro}`)
            .then(resp => {
                // console.log('RESP >>>>>', resp)
                this.setState({ listarUsuarios: resp, quantidade: 0, loadingTable: false, departamento: { value: 0, label: 'Todos' } })
            })
            .catch(err => err)

    }

    selecionarBusca(value) {
        if (this._buscarInput.value === '') {
            this.carregarUsuarios(1)

        } else {
            const palavra = value
            this.setState({ palavraFiltro: palavra })
        }
    }

    async getDepartamentos() {
        await get(`/usuarios/relatorios/departamentos-filtro`)
            .then(res => {
                // console.log('DEPARTAMENTO>>', res)
                this.setState({ departamentoList: res })
            })
            .catch(err => err)
    }

    handleChangeSelectDep(newValue) {

        // console.log("newValue", newValue)

        this.setState({ departamento: newValue })
        this._buscarInput.value = ''
        this.filtroGeral(1, newValue)

    }

    async filtroGeral(pagina, newValue) {

        if (newValue.value !== 0) {
            const data = newValue

            this.setState({ loadingTable: true })

            const offset = pagina === 1 ? 0 : (pagina - 1) * this.state.limite
            await get(`/usuarios/listar-departamento/${this.state.limite}/${offset}/${data.value}`)
                .then(resp => {
                    //console.log('RESP CARR>>>>>',resp)
                    this.setState({
                        listarUsuarios: resp,
                        quantidade: 1,
                        loadingTable: false
                    })
                })
                .catch(err => err)

        } else {
            await this.carregarUsuarios(1)
        }

    }

    render() {

        const StatusSwitch = withStyles({
            switchBase: {
                color: '#FFF',
                '&$checked': {
                    color: '#4CD964',
                },
                '&$checked + $track': {
                    backgroundColor: '#4CD964',
                },
            },
            checked: {},
            track: {},
        })(Switch);

        // //const usuarios = this.state.status === 2 && this.state.usuarios !== undefined && this.state.usuarios.length > 0 ? this.state.usuarios : this.state && this.state.usuarios && this.state.usuarios.length > 0 &&
        //     this.state.usuarios.filter(u => u.id !== this.props.authStore.id && u.situacao === this.state.status && this.state.status !== 2)
        //         .sort(firstBy("idPermissao").thenBy("nome", { ignoreCase: true }));

        //console.log(this.state.departamento)

        return (

            <div className="container-fluid">

                <div style={{ backgroundColor: 'white', padding: '40px 3px 3px 3px' }}>

                    <div className="row mb-3">

                        <div className="input-group col-12 col-sm-5  col-lg-5 col-xl-5 p-3">
                            <input className="form-control py-2 border-right-0 border" type="search" autoComplete="off"
                                value={this.state.descricao}
                                onKeyUp={(event) => { if (event.which === 13) { this.getDadosUsuarioFiltro() } }}
                                // onKeyPress={(event) => this.clickEnter(event)}
                                onChange={(event) => this.selecionarBusca(event.target.value)}
                                placeholder="Buscar o nome do usuário " aria-label="Pesquisar"
                                aria-describedby="search"

                                ref={(event) => this._buscarInput = (event)}
                            />

                            <span id="icone-pesquisar-proposicao" className="input-group-append">
                                <button className="btn btn-outline-secondary border-left-0 border" type="button"
                                    onClick={() => this.getDadosUsuarioFiltro()}>
                                    <i className="icon-search-small"></i>
                                </button>
                            </span>
                        </div>

                        <div className="col-12 col-sm-4  col-lg-4 col-xl-4">

                            <div>
                                <label>Departamento</label>
                                <ClientCheckboxWithSelect
                                    label="Departamento"
                                    css="no-border"
                                    value={this.state.departamento}
                                    options={
                                        this.state.departamentoList !== undefined && Array.isArray(this.state.departamentoList) && this.state.departamentoList.length > 0 ?
                                            this.state.departamentoList.map(item => ({ value: item.id, label: item.title })) : null}
                                    onChange={(newValue) => this.handleChangeSelectDep(newValue)}
                                    placeholder={'Selecione'}
                                />
                            </div>



                        </div>
                        {/* <div className="col-12 col-sm-2  col-lg-2 col-xl-2">      

                            <div>
                                <label>Mostrar</label>                
                                <ClientCheckboxWithSelect 
                                    label="Mostrar"
                                    css="no-border"
                                    value={this.state.situacao[0].id}
                                    options={
                                        this.state.situacao !== undefined && Array.isArray(this.state.situacao) && this.state.situacao.length > 0 ?
                                        this.state.situacao.map(item => ({ value: item.id, label: item.situacao })):null}
                                    onChange={(newValue) => this.selecionarSituacao(newValue)} 
                                    placeholder={this.state.situacaoDefault.label}
                                />       
                            </div>  
                                

                                            
                        </div> */}


                        <div className="col-12 col-sm-3  col-lg-3 col-xl-3 mt-2">
                            <ModalCadUsuario
                                callbackParent={this.handleUser}
                                create={true}
                            />
                        </div>

                        {/* <div className="col-md-6">
                                <ModalCadDepartamento  callbackParent={this.saveCadDepto}  />    
                            </div>        */}

                    </div>

                    {/* <div className="col-12 col-md-12 my-3">
                        <nav className="nav mb-3 fs-12 text-uppercase">
                            <div className={this.state.status === 1 ? 'nav-link active' : 'nav-link cursor-pointer'} onClick={() => this.setState({ status: 1 })} >Membros Ativos</div>
                            <div className={this.state.status === 0 ? 'nav-link active' : 'nav-link cursor-pointer'} onClick={() => this.setState({ status: 0 })} >Membros Inativos</div>
                            <div className={this.state.status === 2 ? 'nav-link active' : 'nav-link cursor-pointer'} onClick={() => this.setState({ status: 2 })} >Todos os Membros</div>
                        </nav>
                    </div> */}


                    {/* </div><div id={`_${index}`} className="col-12 mb-2 bordasCitacoes" key={index}> */}
                    <ConfirmModal
                        text={'Tem certeza que deseja excluir este usuário?'}
                        description={'Esta ação não poderá ser desfeita no futuro'}
                        onClose={(show, save, item) => this.excluir(show, save, item)}
                        show={this.state.showModalDelete}
                        item={this.state.itemDelete}
                    />


                    {this.state.loadingTable ? <div style={{ marginTop: '200px' }}><Loading /></div> :
                        <div className="mt-1">

                            <div className="table-responsive">
                                <table className="table table-striped table-responsive-sm" style={{ borderBottom: '1px solid #d6d6d6' }}>
                                    <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                        <tr>

                                            <th scope="col" colSpan="2" >Nome</th>
                                            <th scope="col">Usuário</th>
                                            <th scope="col">E-mail</th>
                                            <th scope="col">Departamento</th>
                                            <th scope="col">Permissão</th>
                                            <th scope="col" colSpan="4">Status</th>

                                        </tr>
                                    </thead>
                                    <tbody>


                                        {
                                            this.state.listarUsuarios !== undefined && Array.isArray(this.state.listarUsuarios) && this.state.listarUsuarios.length > 0

                                                ?
                                                this.state.listarUsuarios.map((item, index) => {
                                                    return (

                                                        <tr key={index}>

                                                            <td style={{ verticalAlign: 'middle' }} >
                                                                {item.url_foto === '' || item.url_foto === null

                                                                    ?
                                                                    <div className="icon-list-user">
                                                                        <i className="icon-icon_identity_24px" style={{ fontSize: '24px', color: '#FFF' }}></i>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <img className="img-foto-list-usuario" src={item.url_foto} alt='' />
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td style={{ verticalAlign: 'middle', borderRight: '1px solid #d6d6d6' }}>{item.nome}</td>
                                                            <td style={{ verticalAlign: 'middle' }}>{item.usuario}</td>
                                                            <td style={{ verticalAlign: 'middle' }}>{item.email}</td>
                                                            <td style={{ verticalAlign: 'middle' }}>{item.departamento}</td>
                                                            <td style={{ verticalAlign: 'middle' }} width="250px">{item.permissao}</td>
                                                            <td style={{ verticalAlign: 'middle' }}>
                                                                <StatusSwitch
                                                                    checked={item.situacao === 1 ? true : false}
                                                                    //onChange={handleChangeStatus}
                                                                    //size="small"
                                                                    name="checkedA"
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                            </td>
                                                            <td style={{ verticalAlign: 'middle' }}>{item.situacao === 1 ? 'Ativo' : 'Inativo'}</td>
                                                            <td style={{ verticalAlign: 'middle' }}>

                                                                <ModalCadUsuario
                                                                    create={false}
                                                                    dadosUsuario={item}
                                                                    callbackParent={this.handleUser}

                                                                />

                                                            </td>


                                                        </tr>

                                                    )
                                                })

                                                :
                                                null
                                        }






                                    </tbody>
                                </table>

                            </div>

                        </div>
                    }

                    <div className="d-flex justify-content-center paginacao-citacoes">
                        <Pagination
                            activePage={this.state.paginaAtual}
                            itemsCountPerPage={this.state.limite}
                            totalItemsCount={this.state.quantidade}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNova}
                            prevPageText={<div className="prev-page"><img src={require('../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default GerenciamentoUsuarios