import React, {} from "react"
import { useForm } from "react-hook-form"

const modalEnviarEmail = ({ abrirFechar, abrirFecharSucesso}) => {
    const { handleSubmit, register, errors, formState } = useForm({  mode: 'onChange'})
    const onSubmit = async (data) => {
        // console.log(data)
        abrirFechar()
        abrirFecharSucesso()       
    }
    return (        
        <div className={'background-confirm-modal'}>
            <div className="confirm-modal">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-body">
                    <p className="Client-titulo-modal-t mt-3 mb-3">Esqueci minha senha</p>
                    <p className="Client-titulo-modal-s mt-3 mb-3">Você receberá um e-mail com as instruções para criar uma nova senha.</p>
                    <h6 className="w-75  mt-3 mb-3">
                        Endereço de e-mail
                    </h6>
                    <input type="text" name="email" className="form-control"
                        ref={register({
                        required: "Precisa preencher",
                        maxLength: { value: 40, message: 'E-mail com no máximo 40 letras'},                        
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "E-mail invalido"
                        }
                        })}
                    />
                {errors.email && <p style={{color:'red'}}> {errors.email.message}</p> }
                </div>
                <div className="modal-footer">
                    <div className="modal-footer-item" >
                        <button type='button' onClick={abrirFechar}>CANCELAR</button>
                    </div>
                    <div className="modal-footer-item" >
                        <button disabled={!formState.isValid} type="submit">ENVIAR E-MAIL</button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    )
}

export default modalEnviarEmail 