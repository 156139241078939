// import { get } from 'mobx';
import { get } from '../../src/services/http'



export const prioridades = [
  { "id": 1, "nome": "Mínima", "spanclass": "acao-red", outline: "danger" },
  { "id": 2, "nome": "Baixa", "spanclass": "acao-orange", outline: "info" },
  { "id": 3, "nome": "Média", "spanclass": "acao-yellow", outline: "warning" },
  { "id": 4, "nome": "Alta", "spanclass": "acao-blue", outline: "primary" },
  { "id": 5, "nome": "Máxima", "spanclass": "acao-green", outline: "success" },
];


// export const ClientPrioridades = [
//   { id: 1, value: 'Mínima', icon: 'prioridade-1' },
//   { id: 2, value: 'Baixa', icon: 'prioridade-2' },
//   { id: 3, value: 'Média', icon: 'prioridade-3' },
//   { id: 4, value: 'Alta', icon: 'prioridade-4' },
//   { id: 5, value: 'Máxima', icon: 'prioridade-5' },
// ];

export const buscarListaPrioridades = async () => {
  return await get('/tema-proposicoes/prioridade/lista')
      .then(res => {
          return res
      })
}

export default {
  // ClientPrioridades,
  prioridades,
  buscarListaPrioridades
}