import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { post, put } from '../../services/http'
import ClientCheckboxWithSelect from '../../componentes/forms/basic/clientSelectWithSearch'
import posicionamento from "../../enums/posicionamento"
// import prioridades from "../../enums/prioridades"
import FormatInput from '../../componentes/forms/inputs/formatInput'
import { buscarListaPrioridades } from "../../../src/enums/prioridades";



export default function FormDialog(props) {

    const [open, setOpen] = React.useState(false);
    const [modalSucesso, setModalSucesso] = React.useState(false);

    const [novoApelido, setNovoApelido] = React.useState(props.path === 'ia' ? props.dados.apelido : '');
    const [novoTexto, setNovoTexto] = React.useState(props.path === 'ia' ? props.dados.texto_integra : '');

    const [novoPosicionamento, setNovoPosicionamento] = React.useState(props.path === 'ia' ? {
        value: props.dados.posicionamento, label:
            props.path === 'ia' ?
                posicionamento.posicionamentosClient.map(i => i.id === props.dados.posicionamento ? i.value : null).filter(x => x)[0]
                : null
    } : { label: 'Selecione' });

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const [supportNumber, setSupportNumber] = React.useState(1)

    const [ClientPrioridades, setClientPrioridades] = React.useState([])

    buscarListaPrioridades()
        .then(res => {
            if (supportNumber === 1) {
                setClientPrioridades(res)
                setSupportNumber(0)
            }
        })

    const [novoPrioridade, setNovoPrioridade] = React.useState(props.path === 'ia' ? {
        value: props.dados.prioridade, label:
            props.path === 'ia' ?
                ClientPrioridades.map(i => i.id === props.dados.prioridade ? i.value : null).filter(x => x)[0]
                : null
    } : { label: 'Selecione' });


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setModalSucesso(false)
        setOpen(false)
    }

    const handleCloseModalSuccess = () => {
        setModalSucesso(false)
        setOpen(false)
        if (props.path === 'ia') {
            window.location.reload()
        }
    }

    const selecionarPrioridade = (newValue) => {
        setNovoPrioridade(newValue)
    }

    const selecionarPosicionamento = (newValue) => {
        setNovoPosicionamento(newValue)
    }

    const salvar = () => {
        if (novoTexto === '' || novoApelido === '' || novoPosicionamento.value === undefined || novoPrioridade.value === undefined) {
            alert('Favor preencher todos os campos')
        } else {
            const data = {
                texto: novoTexto,
                apelido: novoApelido,
                posicionamento: novoPosicionamento.value,
                prioridade: novoPrioridade.value,
            }

            if (props.path === 'ia') {

                data.id = props.dados.id

                put('/ia', data)
                    .then(response => {
                        setModalSucesso(true)
                    })

            } else {

                post('/ia', data)
                    .then(response => {
                        setModalSucesso(true)
                        setNovoApelido('')
                        setNovoTexto('')
                        setNovoPosicionamento({ label: 'Selecione' })
                        setNovoPrioridade({ label: 'Selecione' })
                    })

            }
        }
    }

    const handleApelido = (value) => {
        setNovoApelido(value)
    }

    const handleTexto = (value) => {
        setNovoTexto(value)
    }

    return (
        <div>
            {props.path === 'ia' ?
                <div className="text-right mr-3" onClick={handleClickOpen}>
                    <img src={require('../../design/assets/images/editar-gray.svg')} alt="" />
                </div>
                :
                <div>
                    <button className="btn btn-dark btn-block" variant="outlined" onClick={handleClickOpen}>
                        <img
                            className="mr-2"
                            src={require('../../design/assets/images/ia-icon.svg')}
                            alt="anexo"
                        />{' '}
                        Inteligência Artificial
                    </button>
                </div>
            }

            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title" style={{ height: '520px', }}>


                <DialogContent
                    style={{
                        // width: 580,
                        backgroundColor: "white",
                        color: '#3E4049'

                    }}
                >
                    {modalSucesso === false ?
                        <div>
                            <h3>Inteligência Artificial</h3>
                            <div className='p-3'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <FormatInput
                                            label="Prioridade"
                                        >
                                            <ClientCheckboxWithSelect
                                                css="no-border"
                                                options={ClientPrioridades.map(item => ({ value: item.id, label: item.value }))}
                                                onChange={(newValue) => selecionarPrioridade(newValue)}
                                                placeholder={novoPrioridade.label}
                                            />
                                        </FormatInput>
                                    </div>

                                    <div className='col-md-6'>
                                        <FormatInput
                                            label="Posicionamento"
                                        >
                                            <ClientCheckboxWithSelect
                                                css="no-border"
                                                options={posicionamento.posicionamentosClient.map(item => ({ value: item.id, label: item.value }))}
                                                onChange={(newValue) => selecionarPosicionamento(newValue)}
                                                placeholder={novoPosicionamento.label}
                                            />
                                        </FormatInput>
                                    </div>


                                    <div className="col-12 p-3">
                                        <label style={{ fontSize: '12px' }}>Apelido</label>
                                        <input type="text" className='form-control input-apelido'
                                            style={{ border: 'none', borderBottom: '1px solid rgb(62, 64, 73)', borderRadius: '0px' }}
                                            onChange={(e) => handleApelido(e.target.value)} placeholder='Ex. Aumento da CSLL dos Bancos'
                                            value={novoApelido}
                                        />
                                    </div>

                                    <div className="col-12 p-3">
                                        <label style={{ fontSize: '12px' }}>Texto</label>
                                        <textarea type="text" className='form-control input-texto' onChange={(e) => handleTexto(e.target.value)}
                                            style={{ border: 'none', borderBottom: '1px solid rgb(62, 64, 73)', borderRadius: '0px' }}
                                            placeholder='Digite'
                                        >
                                            {novoTexto}
                                        </textarea>
                                    </div>



                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6 col-6' style={{ borderTop: '1px solid #E3E3E3', paddingTop: '10px', paddingBottom: '10px' }}>
                                    <div onClick={handleClose} className='text-center' style={{ marginTop: '10px', cursor: 'pointer' }}>
                                        CANCELAR
                                    </div>
                                </div>

                                <div className='col-md-6 col-6' style={{ borderTop: '1px solid #E3E3E3', borderLeft: '1px solid #E3E3E3', paddingTop: '10px', paddingBottom: '10px' }}>
                                    <div onClick={salvar} className='text-center' style={{ marginTop: '10px', cursor: 'pointer' }}>
                                        SALVAR
                            </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='pt-3 pl-3 pr-3'>
                            <div className='text-center p-3'>
                                <img
                                    className="mr-3" style={{ width: '50px' }}
                                    src={require('../../design/assets/images/icone_sucesso.svg')}
                                    alt="anexo"
                                />{' '}
                            </div>
                            <div className='p-3'>
                                <h4 className='text-center'>
                                    {props.path === 'ia' ? 'Texto editado com sucesso' : 'Texto cadastrado com sucesso'}
                                </h4>
                            </div>
                            <div className='row'>
                                <div className='col-12' style={{ borderTop: '1px solid #E3E3E3', paddingTop: '10px', paddingBottom: '10px' }}>
                                    <div onClick={handleCloseModalSuccess} className='text-center' style={{ marginTop: '10px', cursor: 'pointer' }}>
                                        FECHAR
                                    </div>
                                </div>
                            </div>

                        </div>
                    }

                </DialogContent>

            </Dialog>
        </div >
    );
}