import { observable, action } from "mobx"

class BreadCrumbStore {
    @observable indices = {}

    @action 
    adicionar(tipo, chave, valor) {
        if(!tipo || !chave || this.obter(tipo, chave)) return

        this.indices = { 
            ...this.indices, 
            [tipo.toLowerCase()]: {
                ...(this.indices[tipo.toLowerCase()] || {}),
                [chave]: valor
            }
        }
    }

    obter(tipo, chave) {
        if(!tipo || !chave) return
        return (this.indices[tipo.toLowerCase()] || {})[chave] || null
    }
}

export default  new BreadCrumbStore()