import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { post, get } from "../../services/http";
import AlertaStore from "../../stores/alertaStore";
// import { NavLink } from 'react-router-dom'
import ClientSelect from "../../componentes/forms/basic/clientSelectModalCadUser";
import Loading from "../../componentes/loading";

const inputStyle = {
	border: "none",
	marginLeft: "0px",
	backgroundColor: "transparent",
	borderBottom: "1px solid #C4C4C4",
	color: "#000000",
	marginTop: "11px",
	width: "100%",
	outline: "none",
};

@inject("authStore")
@observer
class Insights extends Component {
	state = {
		loading: false,
		user: JSON.parse(window.localStorage.getItem("user")),
		status: true,

		// listaGruposTemas: {},
		selectedOption: "",
		paginaAtual: 2,

		// Alterar Subtema

		idSubtema: this.props.match.params.id,
		idDoTema: 0,
		idPrioridade: this.props.match.params.idPrioridade,
		descricaoSubtema: "",
		statusSubtema: false,

		nomeDoTema: this.props.match.params.tName,
		nomeDoGrupo: this.props.match.params.groupName,
		erroDescricaoSubtema: "",
		prioridadeSelecionada: "",
		listaPrioridades: [],
		etiqueta: "",
	};

	async componentWillMount() {
		this.buscarListaPrioridades();
		this.buscarSubtemaPorId();
	}

	// ...................CHAMADA DO SUBTEMA ID ................

	buscarSubtemaPorId = async () => {
		const data = {
			data: {
				subTemaId: this.state.idSubtema,
			},
		};
		await post(`/tema-proposicoes/subtema/obterId`, data).then((resp) => {
			// console.log(resp)
			this.setState({
				loading: true,
			});
			this.setState({
				loading: false,
				idSubtema: resp[0].id,
				idDoTema: resp[0].id_tema,
				idPrioridade: resp[0].id_prioridade,
				descricaoSubtema: resp[0].descricao,
				statusSubtema: resp[0].ativo,
			});

			this.setState({
				loading: false,
			});
		});
	};

	buscarListaPrioridades = async () => {
		await get(`/tema-proposicoes/prioridade/lista`)
			.then((res) => {
				// console.log("resposta", res)

				const optionsPrioridadeEmSubtema = res.map((item) => ({
					value: item.id,
					label: item.value,
				}));
				this.setState({
					listaPrioridades: optionsPrioridadeEmSubtema,
				});
			})
			.catch((e) => {
				console.log(e);
			});
	};

	handleChangeStatus = async (position) => {
		if (this.state.status === position) {
			this.setState({
				status: false,
			});
		}
		if (this.state.status !== position) {
			this.setState({
				status: true,
			});
		}
	};

	// .................EDITAR DE SUBTEMA................

	alterarSubtema = async () => {
		if (this.state.descricaoSubtema !== "") {
			if (
				this.state.prioridadeSelecionada === "" ||
				this.state.prioridadeSelecionada === null ||
				this.state.prioridadeSelecionada === undefined
			) {
				const data = {
					data: {
						id: this.state.idSubtema,
						prioridadeId: this.state.idPrioridade,
						ativo: this.state.statusSubtema,
						descricao: this.state.descricaoSubtema,
					},
				};

				post("/tema-proposicoes/subtema/alterar", data).then(
					(response) => {
						if (response === 1) {
							AlertaStore.criar("Subtema alterado com sucesso!");
							window.location.href = `/classificacao-tema/nome-do-tema/${this.state.idDoTema}/${this.state.nomeDoGrupo}`;
							// console.log(">>>>>>>>>>>>>>>>>>>> alteração s", response)
						} else {
							AlertaStore.erro(
								"Subtema não alterado, tente novamente!"
							);
						}
					}
				);
			} else {
				const data = {
					data: {
						id: this.state.idSubtema,
						prioridadeId: this.state.prioridadeSelecionada.value,
						ativo: this.state.statusSubtema,
						descricao: this.state.descricaoSubtema,
					},
				};

				post("/tema-proposicoes/subtema/alterar", data).then(
					(response) => {
						if (response === 1) {
							AlertaStore.criar("Subtema alterado com sucesso!");
							window.location.href = `/classificacao-tema/nome-do-tema/${this.state.idDoTema}/${this.state.nomeDoGrupo}`;
							// console.log(">>>>>>>>>>>>>>>>>>>> alteração s", response)
						} else {
							AlertaStore.erro(
								"Subtema não alterado, tente novamente!"
							);
						}
					}
				);
			}
		} else {
			this.setState({
				erroDescricaoSubtema: "Campo vazio. Favor preencher!",
			});
		}
	};

	cancel = async () => {
		window.location.href = `/classificacao-tema/nome-do-tema/${this.state.idDoTema}/${this.state.nomeDoGrupo}/`;
	};

	handleTextoInsertDescricaoSubtema = async (descricaoTexto) => {
		this.setState({
			descricaoSubtema: descricaoTexto,
		});
	};

	handleStatusInsertDescricaoSubtema = async (descricaoStatus) => {
		this.setState({
			statusSubtema: descricaoStatus,
		});
	};

	changeSelectPrioridadeEmSubtema = (itemSelecionado) => {
		this.setState({
			prioridadeSelecionada: itemSelecionado,
		});
	};

	render() {
		// console.log(this.state.listaPrioridades)
		// console.log(this.state.prioridadeSelecionada)

		const StatusSwitch = withStyles({
			switchBase: {
				color: "#FFF",
				"&$checked": {
					color: "#4CD964",
				},
				"&$checked + $track": {
					backgroundColor: "#4CD964",
				},
			},
			checked: {},
			track: {},
		})(Switch);

		return this.state.loading === true ? (
			<div style={{ marginTop: "400px" }}>
				<Loading />
			</div>
		) : (
			<div style={{ backgroundColor: "#f6f6f6", height: "100%" }}>
				<div
					className=" bg-white pl-2 marginTopXs"
					style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}
				>
					<p
						style={{
							fontSize: "10px",
							color: "var(--linkText)",
							fontWeight: "bold",
							padding: "15px",
						}}
					>
						<span>
							<a
								style={{
									fontSize: "10px",
									color: "var(--linkText)",
								}}
								href={"/classificacao-tema"}
							>
								CLASSIFICAÇÃO DE TEMAS
							</a>
						</span>{" "}
						/
						<span>
							<a
								style={{
									fontSize: "10px",
									color: "var(--linkText)",
								}}
								href={`/classificacao-tema/nome-do-grupo/${this.state.idDoGrupo}`}
							>
								{" "}
								GRUPO {this.state.nomeDoGrupo}
							</a>
						</span>{" "}
						/
						<span>
							<a
								style={{
									fontSize: "10px",
									color: "var(--linkText)",
								}}
								href={`/classificacao-tema/nome-do-tema/${this.state.idDoTema}/${this.state.nomeDoGrupo}`}
							>
								{" "}
								TEMA {this.state.nomeDoTema}
							</a>
						</span>{" "}
						/ EDITAR SUBTEMA
					</p>
				</div>

				<div className="container">
					{/* ................head.................... */}
					<div className="row" style={{ width: "100%" }}>
						<div className="col-12 col-12">
							<p
								style={{
									fontSize: "24px",
									fontWeight: "bold",
									paddingTop: "30px",
									color: "#1E0736",
								}}
							>
								Editar Subtema
							</p>
						</div>
					</div>

					{/* ................Background White.................... */}
					<div
						className="bg-white container"
						style={{
							paddingLeft: "40px",
							paddingRight: "40px",
							paddingTop: "25px",
							paddingBottom: "25px",
						}}
					>
						<div style={{ fontSize: "16px", fontWeight: "400" }}>
							Informações Gerais
						</div>

						{/* ................Formulários.................... */}

						<div>
							<div className="row">
								<div className="col-md-6 mt-3">
									<label
										style={{
											color: "var(--linkText)",
											fontSize: "12px",
										}}
									>
										Descrição
									</label>
									<input
										value={this.state.descricaoSubtema}
										onChange={(e) =>
											this.handleTextoInsertDescricaoSubtema(
												e.target.value
											)
										}
										name={"DescriçãoSubtema"}
										style={inputStyle}
										type="text"
										placeholder="Digite o nome desejado"
										autoComplete="off"
										maxLength="40"
									/>

									<span
										style={{
											color: "#DC2D41",
											fontSize: "12px",
											fontWeight: "bold",
										}}
									>
										{this.state.erroDescricaoSubtema}
									</span>
								</div>

								<div className="col-md-6 mt-3">
									<label
										style={{
											color: "var(--linkText)",
											fontSize: "12px",
										}}
									>
										Prioridade
									</label>
									<ClientSelect
										css="no-border"
										name="prioridade"
										value={this.state.prioridadeSelecionada}
										options={this.state.listaPrioridades}
										onChange={(newValue) =>
											this.changeSelectPrioridadeEmSubtema(
												newValue
											)
										}
										placeholder={
											this.state.idPrioridade === 1
												? "Máxima"
												: this.state.idPrioridade === 2
													? "Alta"
													: this.state.idPrioridade === 3
														? "Média"
														: this.state.idPrioridade === 4
															? "Baixa"
															: "mínima"
										}
									/>
									<span
										style={{
											color: "#DC2D41",
											fontSize: "12px",
											fontWeight: "bold",
										}}
									>
										{this.state.erroEscolhaPrioridade}
									</span>
								</div>

								<div className="col-md-12 mt-3">
									<div>
										<label
											style={{
												color: "var(--linkText)",
												fontSize: "12px",
											}}
										>
											Status
										</label>
									</div>
									<div className="row">
										<div>
											<StatusSwitch
												checked={
													this.state.statusSubtema
												}
												onChange={(e) =>
													this.handleStatusInsertDescricaoSubtema(
														e.target.checked
													)
												}
												name="checkedA"
												inputProps={{
													"aria-label":
														"secondary checkbox",
												}}
											/>
										</div>
										<div
											style={{
												alignItems: "center",
												display: "flex",
											}}
										>
											{this.state
												.statusDescricaoCadastroTema ===
												true
												? "Ativo"
												: "Inativo"}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* .................... BOTOES ....................... */}
					<div className="container">
						<div className="row" style={{ float: "right" }}>
							<div
								onClick={() => this.cancel()}
								className="cursor-pointer"
								style={{
									padding: "10px",
									color: "var(--botaoPesquisar)",
									marginTop: "25px",
								}}
							>
								Cancelar
							</div>
							<div style={{ padding: "10px" }}>
								<button
									type="button"
									onClick={() => this.alterarSubtema()}
									className="btn"
									style={{
										width: "100%",
										marginTop: "10px",
										padding: "15px",
										backgroundColor:
											"var(--botaoPesquisar)",
										color: "var(--corTextoBotao)",
										fontWeight: "lighter",
									}}
									variant="outlined"
								>
									<img
										className="mr-2"
										style={{
											width: "20px",
											marginTop: "-3px",
										}}
										src={require("../../design/assets/images/diskSave.svg")}
										alt="btn-pesquisar"
									/>{" "}
									Salvar
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Insights;
