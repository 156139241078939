import React from 'react';
import Loading from '../../../componentes/loading';
// import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import SelectFiltro from '../../../componentes/forms/basic/selectFiltro'
import { get, post } from '../../../services/http';
import AuthStore from '../../../stores/authStore'
import alertaStore from '../../../stores/alertaStore'


// import Autocomplete from '@material-ui/lab/Autocomplete';

export default function FormDialog(props) {

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [temas, setTemas] = React.useState([]);
    const [grupo, setGrupo] = React.useState([]);
    //const [listaTemaA, setListaTemaA] = React.useState([]);
    //const [listaSubTemaA, setListaSubTemaA] = React.useState([]);
    const [ano, setAno] = React.useState([]);

    const [tipoSelected, setTipoSelected] = React.useState([]);
    const [numeroSelected, setNumeroSelected] = React.useState('Escreva');
    const [anoSelected, setAnoSelected] = React.useState([]);
    const [temaSelected, setTemaSelected] = React.useState([]);
    const [apelidoSelected, setApelidoSelected] = React.useState('Escreva');
    const [posicionamentoSelected, setPosicionamentoSelected] = React.useState([]);
    const [prioridadeSelected, setPrioridadeSelected] = React.useState([]);
    const [relatorSelected, setRelatorSelected] = React.useState('Escreva');
    const [casaSelected, setCasaSelected] = React.useState([]);
    const [grupoSelected, setGrupoSelected] = React.useState([]);
    const [temaGrupoSelected, setTemaGrupoSelected] = React.useState([]);
    const [subTemaGrupoSelected, setSubTemaGrupoSelected] = React.useState([]);
    const [ambitoSelected, setAmbitoSelected] = React.useState([]);
    const [nomeSelected, setNomeSelected] = React.useState('Escreva');
    const [partidoSelected, setPartidoSelected] = React.useState([]);
    const [tramitacaoSimSelected, setTramitacaoSimSelected] = React.useState(false);
    const [tramitacaoNaoSelected, setTramitacaoNaoSelected] = React.useState(false);

    const [todasSelected, setTodasSelected] = React.useState(true);
    const [seguidasSelected, setSeguidasSelected] = React.useState(false);
    const [novasSelected, setNovasSelected] = React.useState(false);
    const [atualizacaoSelected, setAtualizacaoSelected] = React.useState(false);

    const [aguardandoDesignacao, setAguardandoDesignacao] = React.useState(false);
    const [prazoEmendas, setPrazoEmendas] = React.useState(false);
    const [aguardandoParecer, setAguardandoParecer] = React.useState(false);
    const [aguardandoDeliberacao, setAguardandoDeliberacao] = React.useState(false);
    const [ambitoSelect, setAmbitoSelect] = React.useState([{ value: 0, label: 'Selecione a casa' }])
    const [temaGrupoSelect, setTemaGrupoSelect] = React.useState([])
    const [subTemaGrupoSelect, setSubTemaGrupoSelect] = React.useState([])

    const [uf, setUf] = React.useState('');
    const [ufLista, setUfLista] = React.useState([]);

    const [casaSelecionada, setCasaSelecionada] = React.useState([]);
    const [listaCasas, setListaCasas] = React.useState([]);

    // const [AtribuidasMim, setAtribuidasMimSelected] = React.useState(false);
    // const [AtribuidasAoDepartamento, setAtribuidasAoDepartamentoSelected] = React.useState(false);

    // const [NoPrazoAcoesSelected, setNoPrazoAcoesSelected] = React.useState(false);
    // const [EmAtrasoAcoesSelected, setEmAtrasoAcoesSelected] = React.useState(false);
    // const [FinalizadasAcoesSelected, SetFinalizadasAcoesSelected] = React.useState(false);
    // const [PendentesAcoesSelected, setPendentesAcoesSelected] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {

        let url_atual = window.location.pathname

        if (url_atual === '/proposicao') {
            setOpen(true);
            getDataFiltro()
            getDataFiltroGrupo()
            getDataFiltroListaTemaA()
            getDataFiltroListaSubTemaA()
            setLoading(true)
            obterEstados()
        } else if (url_atual === '/proposicao/lista') {
            const proposicao_deleted = JSON.parse(window.localStorage.getItem('prop_deleted'))

            if (proposicao_deleted) {
                alertaStore.aviso('Por Favor, Clique em " Limpar Filtro " para fazer uma nova pesquisa!')
            } else {
                setOpen(true);
                getDataFiltro()
                getDataFiltroGrupo()
                getDataFiltroListaTemaA()
                getDataFiltroListaSubTemaA()
                setLoading(true)
                obterEstados()
            }
        }

    };

    const selecionarVisualizar = (newValue) => {
        // console.log(newValue.value)
        setCasaSelecionada(newValue)
        // this.setState({ casaSelecionada: newValue })
        // this.setState({ casaSelectedFiltro: newValue.value })
        // this.filtroSelectCasa(newValue.value)
    }


    const onChangeObterCasasByEstado = async (obj) => {

        setCasaSelecionada([])
        setUf(obj)
        await get(`/proposicao/casas-estado/${obj.value}`)
            .then(response => {
                setListaCasas(response)
            })
            .catch(e => {
                console.log(e)
            })

    }

    const obterEstados = async () => {
        await get(`/proposicao/estados`)
            .then(res => {
                setUfLista(res)
            })
            .catch(e => {
                console.log(e)
            })
    }

    const getDataFiltro = async () => {
        //tirar o paramentro para nova api ('/proposicao/dados-filtro-proposicao')
        //await get('/proposicao/dados-filtro-proposicao/1')
        await get('/proposicao/dados-filtro-proposicao')
            .then(res => {
                if (res !== undefined) {
                    setLoading(false)
                    setTemas(res.temas)
                    setAno(res.ano)
                }

            })
    }

    const getDataFiltroGrupo = async () => {
        await get('/tema-proposicoes/grupo/lista')
            .then(res => {
                setGrupo(res)

            })
    }

    const getDataFiltroListaTemaA = async () => {
        await get('/tema-proposicoes/tema/lista')
            .then(res => {
                //console.log('res',res)
                //setListaTemaA(res)

            })
    }

    const getDataFiltroListaSubTemaA = async () => {
        await get('/tema-proposicoes/subtema/lista')
            .then(res => {
                //console.log('res',res)
                //setListaSubTemaA(res)

            })
    }

    const handleClose = () => {
        setOpen(false);
    }

    const tipos = [
        { value: 1, label: 'PLN' },
        { value: 2, label: 'MCN' },
        { value: 3, label: 'MSF' },
        { value: 4, label: 'PDC' },
        { value: 5, label: 'RQS' },
        { value: 6, label: 'REQ' },
        { value: 7, label: 'PEC' },
        { value: 8, label: 'PLP' },
        { value: 9, label: 'PL' },
        { value: 10, label: 'PLC' },
        { value: 11, label: 'PLS' },
        { value: 12, label: 'PRC' },
        { value: 13, label: 'PLV' },
        { value: 15, label: 'MPV' },
        { value: 16, label: 'PFC' },
        { value: 17, label: 'RCP' },
        { value: 18, label: 'SCD' },
        { value: 19, label: 'PRS' },
        { value: 20, label: 'PFS' },
        { value: 21, label: 'MSC' },
        { value: 22, label: 'VET' },
        { value: 23, label: 'PDS' },
        { value: 23, label: 'PDL' },
    ]

    const busca = window.localStorage.getItem('filtroInput') !== null ? window.localStorage.getItem('filtroInput') : ''


    const posicionamento = [
        { value: 1, label: 'Contrário' },
        { value: 2, label: 'Contrário com emenda saneadora' },
        { value: 3, label: 'Neutro' },
        { value: 4, label: 'Favorável com emenda saneadora' },
        { value: 5, label: 'Favorável' },
        { value: 99, label: 'Pendente' }
    ]
    const prioridade = [
        { value: 1, label: 'Mínima' },
        { value: 2, label: 'Baixa' },
        { value: 3, label: 'Média' },
        { value: 4, label: 'Alta' },
        { value: 5, label: 'Máxima' },
    ]

    const casa = [
        { value: 1, label: 'Senado Federal' },
        { value: 2, label: 'Câmara dos Deputados' },
        { value: 3, label: 'Congresso Nacional' }
    ]

    const selecionarTipo = (newValue) => {
        setTipoSelected(newValue)
    }

    const selecionarNumero = (event) => {
        setNumeroSelected(event.target.value)
    }

    const selecionarApelido = (event) => {
        setApelidoSelected(event.target.value)
    }

    const selecionarAno = (newValue) => {
        setAnoSelected(newValue)
    }

    const selecionarTema = (newValue) => {
        setTemaSelected(newValue)
    }

    const selecionarPosicionamento = (newValue) => {
        setPosicionamentoSelected(newValue)
    }

    const selecionarPrioridade = (newValue) => {
        setPrioridadeSelected(newValue)
    }

    const selecionarRelator = (event) => {
        setRelatorSelected(event.target.value)
    }


    const getAmbitoPorCasa = async (casa) => {
        var arrayAmbito = []
        await get(`/ambito/${casa}/cliente`)
            .then(res => {
                res.map(async item => {
                    const data = { value: item.id, label: item.sigla + ' - ' + item.descricao }
                    await arrayAmbito.push(data)
                })
                setAmbitoSelect(arrayAmbito)
            })
    }

    const getTemaPorGrupo = async (grupo) => {

        const arrayTemaGrupo = { data: { idGrupo: grupo } }
        await post(`/tema-proposicoes/tema/lista/grupoId`, arrayTemaGrupo)
            .then(res => {
                if (res.length === 0 || res === [] || res === undefined) {
                    alertaStore.erro('Grupo sem temas cadastrados!')
                }

                setTemaGrupoSelect(res)
            })
    }

    const getSubTemaPorTema = async (tema) => {

        const arraySubTemaGrupo = { data: { idTema: tema } }
        console.log('arraySubTemaGrupo', arraySubTemaGrupo)

        await post(`/tema-proposicoes/subtema/lista/temaId`, arraySubTemaGrupo)
            .then(res => {
                if (res.length === 0 || res === [] || res === undefined) {
                    alertaStore.erro('Tema sem subtemas cadastrados!')
                }

                setSubTemaGrupoSelect(res)
            })
    }

    const selecionarCasa = (newValue) => {

        setAmbitoSelected([])
        getAmbitoPorCasa(newValue.value)
        setCasaSelected(newValue)

    }

    const selecionarGrupo = (newValue) => {

        //setAmbitoSelected([])
        setTemaGrupoSelected([])
        getTemaPorGrupo(newValue.value)
        setGrupoSelected(newValue)

    }

    const selecionarTemaGrupo = (newValue) => {
        setSubTemaGrupoSelected([])
        getSubTemaPorTema(newValue.value)
        setTemaGrupoSelected(newValue)
    }

    const selecionarSubTemaGrupo = (newValue) => {
        setSubTemaGrupoSelected(newValue)
    }

    const selecionarAmbito = (newValue) => {
        setAmbitoSelected(newValue)
    }

    const selecionarNome = (event) => {
        setNomeSelected(event.target.value)
    }

    const setTramitacaoSim = (event) => {
        if (event.target.checked === true) {
            setTramitacaoSimSelected(true)
            setTramitacaoNaoSelected(false)
        } else {
            setTramitacaoSimSelected(event.target.checked)
        }
    }

    const setTramitacaoNao = (event) => {
        if (event.target.checked === true) {
            setTramitacaoSimSelected(false)
            setTramitacaoNaoSelected(true)
        } else {
            setTramitacaoNaoSelected(event.target.checked)
        }
    }

    const setTodas = (event) => {
        setTodasSelected(event.target.checked)
        setSeguidasSelected(false)
        setNovasSelected(false)
        setAtualizacaoSelected(false)
    }


    const setSeguidas = (event) => {
        setSeguidasSelected(event.target.checked)
        setNovasSelected(false)
        setAtualizacaoSelected(false)
        setTodasSelected(false)
    }

    const setNovas = (event) => {
        setNovasSelected(event.target.checked)
        setTodasSelected(false)
        setSeguidasSelected(false)
        setAtualizacaoSelected(false)
    }

    const setAtualizacao = (event) => {
        setAtualizacaoSelected(event.target.checked)
        setNovasSelected(false)
        setTodasSelected(false)
        setSeguidasSelected(false)
    }


    // const setNoPrazoAcoes = (event) => {
    //     setNoPrazoAcoesSelected(event.target.checked)
    //     setEmAtrasoAcoesSelected(false)
    //     SetFinalizadasAcoesSelected(false)
    //     setPendentesAcoesSelected(false)
    // }

    // const EmAtrasoAcoes = (event) => {
    //     setNoPrazoAcoesSelected(false)
    //     setEmAtrasoAcoesSelected(event.target.checked)
    //     SetFinalizadasAcoesSelected(false)
    //     setPendentesAcoesSelected(false)
    // }

    // const FinalizadasAcoes = (event) => {
    //     setNoPrazoAcoesSelected(false)
    //     setEmAtrasoAcoesSelected(false)
    //     SetFinalizadasAcoesSelected(event.target.checked)
    //     setPendentesAcoesSelected(false)
    // }

    // const PendentesAcoes = (event) => {
    //     setNoPrazoAcoesSelected(false)
    //     setEmAtrasoAcoesSelected(false)
    //     SetFinalizadasAcoesSelected(false)
    //     setPendentesAcoesSelected(event.target.checked)
    // }

    const aguardandoDesignacaoSelected = (event) => {
        setAguardandoDesignacao(event.target.checked)
    }

    const aguardandoPrazoSelected = (event) => {
        setPrazoEmendas(event.target.checked)
    }

    const aguardandoParecerSelected = (event) => {
        setAguardandoParecer(event.target.checked)
    }

    const aguardandoDeliberacaoSelect = (event) => {
        setAguardandoDeliberacao(event.target.checked)
    }

    const limparCampoNumero = () => {
        if (numeroSelected === 'Escreva') {
            setNumeroSelected('')
        }
    }

    const limparCampoRelatores = () => {
        if (relatorSelected === 'Escreva') {
            setRelatorSelected('')
        }
    }

    const limparCampoNome = () => {
        if (nomeSelected === 'Escreva') {
            setNomeSelected('')
        }
    }

    const limparCampoApelido = () => {
        if (apelidoSelected === 'Escreva') {
            setApelidoSelected('')
        }
    }

    // const atribuidasMim = (event) => {
    //     setAtribuidasMimSelected(event.target.checked)
    //     setAtribuidasAoDepartamentoSelected(false)
    // }

    // const atribuidasAoDepartamento = (event) => {
    //     setAtribuidasAoDepartamentoSelected(event.target.checked)
    //     setAtribuidasMimSelected(false)
    // }

    const limparFiltro = () => {
        setTramitacaoSimSelected(false)
        setTramitacaoNaoSelected(false)
        setTodasSelected(false)
        setSeguidasSelected(false)
        setNovasSelected(false)
        setAtualizacaoSelected(false)

        // setNoPrazoAcoesSelected(false)
        // setEmAtrasoAcoesSelected(false)
        // SetFinalizadasAcoesSelected(false)
        // setPendentesAcoesSelected(false)

        setAguardandoDesignacao(false)
        setPrazoEmendas(false)
        setAguardandoParecer(false)
        setAguardandoDeliberacao(false)

        // setAtribuidasMimSelected(false)
        // setAtribuidasAoDepartamentoSelected(false)

        setTipoSelected([])
        setAnoSelected([])
        setTemaSelected([])
        setPosicionamentoSelected([])
        setPrioridadeSelected([])
        setCasaSelected([])
        setGrupoSelected([])
        setAmbitoSelected([])
        setUf([])
        setCasaSelecionada([])
        setPartidoSelected([])

        setApelidoSelected('Escreva')
        setNumeroSelected('Escreva')
        setNomeSelected('Escreva')
        setRelatorSelected('Escreva')

        window.localStorage.removeItem('filtroProposicao')
        window.localStorage.removeItem('filtroSelectedCasa')

        window.location.reload()
    }

    const filtrar = async () => {

        const data = {
            filtroTipo: tipoSelected,
            filtroNumero: numeroSelected !== 'Escreva' ? numeroSelected : '',
            filtroAno: anoSelected,
            filtroTema: temaSelected,
            filtroApelido: apelidoSelected !== 'Escreva' ? apelidoSelected : '',
            filtroPosicionamento: posicionamentoSelected,
            filtroPrioridade: prioridadeSelected,
            filtroRelator: relatorSelected !== 'Escreva' ? relatorSelected : '',
            filtroCasa: casaSelected,
            filtroGrupo: grupoSelected,
            filtroTemaGrupo: temaGrupoSelected,
            filtroSubTemaGrupo: subTemaGrupoSelected,
            filtroAmbito: ambitoSelected,
            filtroNome: nomeSelected !== 'Escreva' ? nomeSelected : '',
            filtroPartido: partidoSelected,
            filtroTramitacaoSim: tramitacaoSimSelected,
            filtroTramitacaoNao: tramitacaoNaoSelected,
            filtroTodas: todasSelected,
            filtroSeguidas: seguidasSelected,
            filtroNovas: novasSelected,
            filtroAtualizacao: atualizacaoSelected,
            filtroAguardandoDesignacao: aguardandoDesignacao,
            filtroPrazoEmendas: prazoEmendas,
            filtroAguardandoParecer: aguardandoParecer,
            filtroAguardandoDeliberacao: aguardandoDeliberacao,
            filtroBusca: busca,
            filtroEstado: uf,
            filtroCasaEstado: casaSelecionada,

            // filtroAtribuicaoMim: AtribuidasMim,
            // filtroAtribuicaoAoDepartamento: AtribuidasAoDepartamento,

            // filtroNoPrazoAcoes: NoPrazoAcoesSelected,
            // filtroEmAtrasoAcoes: EmAtrasoAcoesSelected,
            // filtroFinalizadasAcoes: FinalizadasAcoesSelected,
            // filtroPendentesAcoes: PendentesAcoesSelected
        }


        await window.localStorage.removeItem('filtroGrafico')
        await window.localStorage.removeItem('filtroInput')
        await window.localStorage.removeItem('filtroSelectedCasa')
        await window.localStorage.setItem('filtroProposicao', JSON.stringify(data))
        window.location.reload()

    }

    const user = JSON.parse(window.localStorage.getItem('user'))
    const tamanhoTela = window.innerWidth
    const url_atual = window.location.pathname
    return (


        <div>
            {
                tamanhoTela > 600

                    ?
                    url_atual === '/proposicao'

                        ?
                        <button className="btn mt-3" style={{ width: "100%", backgroundColor: "var(--botaoFiltro)", color: "var(--corTextoBotao)", fontWeight: "lighter", padding: "15px" }} variant="outlined" onClick={handleClickOpen}>
                            <img
                                className="mr-3"
                                src={require('../../../design/assets/images/filtar.png')}
                                alt="anexo"
                            />{' '}
                            Filtrar
                        </button>

                        :

                        <button className="btn" style={{ marginTop: "20px", padding: "15px", backgroundColor: "var(--botaoFiltro)", color: "var(--corTextoBotao)", fontWeight: "lighter" }} variant="outlined" onClick={handleClickOpen}>
                            <img
                                className="mr-2"
                                style={{ width: "20px" }}
                                src={require('../../../design/assets/images/filtroProp.svg')}
                                alt="anexo"
                            />{' '}
                            Filtro Avançado
                        </button>
                    :

                    <button className="btn mt-3 text-center" style={{ width: "100%", backgroundColor: "var(--botaoFiltro)", color: "var(--corTextoBotao)", fontWeight: "lighter", padding: "5px", paddingBottom: "8px" }} variant="outlined" onClick={handleClickOpen}>
                        <img
                            style={{ width: "60%" }}
                            src={require('../../../design/assets/images/filtar.png')}
                            alt="anexo"
                        />
                    </button>

            }



            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title" >

                <DialogContent
                    style={{
                        width: tamanhoTela > 600 ? 600 : tamanhoTela,
                        height: loading === true ? 800 : null,
                        backgroundColor: "#3E4049",
                        color: 'white'
                    }}
                >
                    {loading ? <Loading /> :
                        <div>

                            {busca === '' ? null :
                                <div>
                                    <Typography variant="h6" gutterBottom style={{ color: 'white', marginTop: '30px' }}>
                                        Resultado da Busca:
                                    </Typography>
                                    <div className="mb-2">
                                        <p style={{ color: 'white', fontSize: '1.5rem' }}>"{busca}"</p>
                                        {/* <input type="text" readonly value={busca} className="form-control" />     */}
                                    </div>
                                    <hr style={{ backgroundColor: 'black' }} />
                                </div>


                            }


                            <Typography variant="h6" gutterBottom style={{ color: 'white', marginTop: '30px' }}>
                                Visualizar:
                            </Typography>

                            <FormGroup row aria-label="position">
                                <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   >
                                    <div className="mt-3">
                                        <label className="container-new-checkbox"> Todos
                                            <input type="checkbox" value="1" id="selectTodas" onChange={event => setTodas(event)} checked={todasSelected} />
                                            <span className="checkmark-new-checkbox"></span>
                                        </label>
                                    </div>
                                    {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="1" id="selectTodas" onChange={event => setTodas(event)} checked={todasSelected}/>}
                                label="Todas"
                            /> */}
                                </Grid>
                                {AuthStore.ehEsforcoColaborativo === false ?
                                    <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   >
                                        <div className="mt-3">
                                            <label className="container-new-checkbox"> Seguidas
                                                <input type="checkbox" value="2" id="selectSeguidas" onChange={event => setSeguidas(event)} checked={seguidasSelected} />
                                                <span className="checkmark-new-checkbox"></span>
                                            </label>
                                        </div>
                                        {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="2" id="selectSeguidas" onChange={event => setSeguidas(event)} checked={seguidasSelected}/>}
                                label="Seguidas" 
                            /> */}
                                    </Grid> : null}
                                <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   >
                                    <div className="mt-3">
                                        <label className="container-new-checkbox"> Novas
                                            <input type="checkbox" value="3" id="selectNovas" onChange={event => setNovas(event)} checked={novasSelected} />
                                            <span className="checkmark-new-checkbox"></span>
                                        </label>
                                    </div>
                                    {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="3" id="selectNovas" onChange={event => setNovas(event)} checked={novasSelected}/>}
                                label="Novas" 
                            /> */}
                                </Grid>
                                <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   >
                                    <div className="mt-3">
                                        <label className="container-new-checkbox"> Atualização Recente
                                            <input type="checkbox" value="3" id="selectAtualizacaoRecente" onChange={event => setAtualizacao(event)} checked={atualizacaoSelected} />
                                            <span className="checkmark-new-checkbox"></span>
                                        </label>
                                    </div>
                                    {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="3" id="selectAtualizacaoRecente" onChange={event => setAtualizacao(event)} checked={atualizacaoSelected}/>}
                                label="Atualização Recente" 
                            /> */}
                                </Grid>
                            </FormGroup>

                            <hr style={{ backgroundColor: 'black' }} />

                            <Typography variant="h6" style={{ color: 'white', marginTop: '30px' }} >
                                Em tramitação:
                            </Typography>

                            <FormGroup row aria-label="position">
                                <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   >
                                    <div className="mt-3">
                                        <label className="container-new-checkbox"> Sim
                                            <input type="checkbox" id="tramitacaoSim" onChange={event => setTramitacaoSim(event)} checked={tramitacaoSimSelected} />
                                            <span className="checkmark-new-checkbox"></span>
                                        </label>
                                    </div>
                                    {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} id="tramitacaoSim" onChange={event => setTramitacaoSim(event)} checked={tramitacaoSimSelected} />}
                                label="Sim"
                            /> */}
                                </Grid>
                                <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   >
                                    <div className="mt-3">
                                        <label className="container-new-checkbox"> Não
                                            <input type="checkbox" id="tramitacaoNao" onChange={event => setTramitacaoNao(event)} checked={tramitacaoNaoSelected} />
                                            <span className="checkmark-new-checkbox"></span>
                                        </label>
                                    </div>
                                    {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} id="tramitacaoNao" onChange={event => setTramitacaoNao(event)} checked={tramitacaoNaoSelected} />}
                                label="Não" 
                            /> */}
                                </Grid>
                            </FormGroup>

                            <hr style={{ backgroundColor: 'black' }} />

                            <Typography variant="h6" style={{ color: 'white', marginTop: '30px' }} >
                                Momentos críticos:
                            </Typography>

                            <FormGroup row aria-label="position">
                                <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}   >
                                    <div className="mt-3">
                                        <label className="container-new-checkbox"> Aguardando designação do relator
                                            <input type="checkbox" value="1" id="aguardandoDesignacao" onChange={event => aguardandoDesignacaoSelected(event)} checked={aguardandoDesignacao} />
                                            <span className="checkmark-new-checkbox"></span>
                                        </label>
                                    </div>
                                    {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="1" id="aguardandoDesignacao" onChange={event => aguardandoDesignacaoSelected(event)} checked={aguardandoDesignacao} />}
                                label="Aguardando designação do relator"
                            /> */}
                                </Grid>
                                <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}   >
                                    <div className="mt-3">
                                        <label className="container-new-checkbox"> Prazo para Emendas
                                            <input type="checkbox" value="2" id="prazoEmendas" onChange={event => aguardandoPrazoSelected(event)} checked={prazoEmendas} />
                                            <span className="checkmark-new-checkbox"></span>
                                        </label>
                                    </div>
                                    {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="2" id="prazoEmendas" onChange={event => aguardandoPrazoSelected(event)} checked={prazoEmendas} />}
                                label="Prazo para Emendas" 
                            /> */}
                                </Grid>
                                <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}   >
                                    <div className="mt-3">
                                        <label className="container-new-checkbox"> Aguardando parecer do relator
                                            <input type="checkbox" value="3" id="aguardandoParecer" onChange={event => aguardandoParecerSelected(event)} checked={aguardandoParecer} />
                                            <span className="checkmark-new-checkbox"></span>
                                        </label>
                                    </div>
                                    {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="3" id="aguardandoParecer" onChange={event => aguardandoParecerSelected(event)} checked={aguardandoParecer} />}
                                label="Aguardando parecer do relator" 
                            /> */}
                                </Grid>
                                <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}   >
                                    <div className="mt-3">
                                        <label className="container-new-checkbox"> Aguardando deliberação
                                            <input type="checkbox" value="3" id="aguardandoDeliberacao" onChange={event => aguardandoDeliberacaoSelect(event)} checked={aguardandoDeliberacao} />
                                            <span className="checkmark-new-checkbox"></span>
                                        </label>
                                    </div>
                                    {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="3" id="aguardandoDeliberacao" onChange={event => aguardandoDeliberacaoSelect(event)} checked={aguardandoDeliberacao} />}
                                label="Aguardando deliberação" 
                            /> */}
                                </Grid>
                            </FormGroup>

                            <hr style={{ backgroundColor: 'black' }} />

                            <Typography variant="h6" style={{ color: 'white', marginTop: '30px' }} >
                                Proposição:
                            </Typography>

                            <div className="row">

                                <div className="col-md-4 mt-2">
                                    <label style={{ fontSize: '0.8rem' }}>Tipo</label>
                                    <SelectFiltro
                                        css="no-border"
                                        value={tipoSelected}
                                        options={
                                            tipos !== undefined && Array.isArray(tipos) && tipos.length > 0 ?
                                                tipos.map(item => ({ value: item.value, label: item.label })) : null}
                                        onChange={(newValue) => selecionarTipo(newValue)}
                                        placeholder="Selecione"
                                    />
                                </div>

                                <div className="col-md-4 mt-2">
                                    <label style={{ fontSize: '0.8rem' }}>Número</label>
                                    <input
                                        style={{ border: 'none', backgroundColor: 'transparent', borderBottom: '1px solid #C4C4C4', color: '#C4C4C4', marginTop: '10px', width: '97%', placeholder: 'white', outline: 'none' }}
                                        onChange={(event) => selecionarNumero(event)}
                                        onFocus={limparCampoNumero}
                                        placeholder="Escreva"
                                        value={numeroSelected}
                                        id="numeroInput"
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="col-md-4 mt-2">
                                    <label style={{ fontSize: '0.8rem' }}>Ano</label>
                                    <SelectFiltro
                                        css="no-border"
                                        value={anoSelected}
                                        options={
                                            ano !== undefined && Array.isArray(ano) && ano.length > 0 ?
                                                ano.map(item => ({ value: item.value, label: item.label })) : null}
                                        onChange={(newValue) => selecionarAno(newValue)}
                                        placeholder="Selecione"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4 mt-2">
                                    <label style={{ fontSize: '0.8rem' }}>Tema</label>
                                    <SelectFiltro
                                        css="no-border"
                                        value={temaSelected}
                                        options={
                                            temas !== undefined && Array.isArray(temas) && temas.length > 0 ?
                                                temas.map(item => ({ value: item.value, label: item.label })) : null}
                                        onChange={(newValue) => selecionarTema(newValue)}
                                        placeholder="Selecione"
                                    />
                                </div>

                                <div className="col-md-4 mt-2">
                                    <label style={{ fontSize: '0.8rem' }}>Apelido</label>
                                    <input
                                        style={{ border: 'none', backgroundColor: 'transparent', borderBottom: '1px solid #C4C4C4', color: '#C4C4C4', marginTop: '10px', width: '97%', placeholder: 'white', outline: 'none' }}
                                        onChange={(event) => selecionarApelido(event)}
                                        onFocus={limparCampoApelido}
                                        placeholder="Escreva"
                                        value={apelidoSelected}
                                        id="apelidoInput"
                                    />
                                    {/* <SelectFiltro 
                                css="no-border"
                                value={apelidoSelected}
                                options={apelido.map(item => ({ value: item.value, label: item.label }))}
                                onChange={(newValue) => selecionarApelido(newValue)} 
                                placeholder="Selecione"
                            /> */}
                                </div>

                                <div className="col-md-4 mt-2">
                                    <label style={{ fontSize: '0.8rem' }}>Relator</label>
                                    <input
                                        style={{ border: 'none', backgroundColor: 'transparent', borderBottom: '1px solid #C4C4C4', color: '#C4C4C4', marginTop: '10px', width: '97%', placeholder: 'white' }}
                                        onChange={(event) => selecionarRelator(event)}
                                        onFocus={limparCampoRelatores}
                                        value={relatorSelected}
                                        placeholder="Escreva"
                                        id="relatoresInput"
                                    />
                                </div>
                            </div>
                            {/*tema-proposicao*/}
                            <div className="row">
                                <div className="col-md-4 mt-2">
                                    <label style={{ fontSize: '0.8rem' }}>Grupo</label>
                                    <SelectFiltro
                                        css="no-border"
                                        value={grupoSelected}
                                        options={
                                            grupo !== undefined && Array.isArray(grupo) && grupo.length > 0 ?
                                                grupo.map(item => ({ value: item.id, label: item.descricao })) : null}
                                        onChange={(newValue) => selecionarGrupo(newValue)}
                                        placeholder="Selecione"
                                    />
                                </div>
                                <div className="col-md-4 mt-2">
                                    <label style={{ fontSize: '0.8rem' }}>Tema Grupo</label>
                                    <SelectFiltro
                                        css="no-border"
                                        value={temaGrupoSelected}
                                        options={
                                            temaGrupoSelect !== undefined && Array.isArray(temaGrupoSelect) &&
                                                temaGrupoSelect.length > 0 ?
                                                temaGrupoSelect.map(item => ({ value: item.id, label: item.descricao })) : null}
                                        onChange={(newValue) => selecionarTemaGrupo(newValue)}
                                        placeholder="Selecione"
                                    />
                                </div>


                                <div className="col-md-4 mt-2">
                                    <label style={{ fontSize: '0.8rem' }}>Subtema</label>
                                    <SelectFiltro
                                        css="no-border"
                                        value={subTemaGrupoSelected}
                                        options={
                                            subTemaGrupoSelect !== undefined && Array.isArray(subTemaGrupoSelect) &&
                                                subTemaGrupoSelect.length > 0 ?
                                                subTemaGrupoSelect.map(item => ({ value: item.id, label: item.descricao })) : null}
                                        onChange={(newValue) => selecionarSubTemaGrupo(newValue)}
                                        placeholder="Selecione"
                                    />
                                </div>

                            </div>

                            <div className="row">
                                {user && user.codigoPermissao === 4 ? null :
                                    <div className="col-md-6 mt-2">
                                        <label style={{ fontSize: '0.8rem' }}>Posicionamento</label>
                                        <SelectFiltro
                                            css="no-border"
                                            value={posicionamentoSelected}
                                            options={
                                                posicionamento !== undefined && Array.isArray(posicionamento) &&
                                                    posicionamento.length > 0 ?
                                                    posicionamento.map(item => ({ value: item.value, label: item.label })) : null}
                                            onChange={(newValue) => selecionarPosicionamento(newValue)}
                                            placeholder="Selecione"
                                        />
                                    </div>
                                }
                                {user && user.codigoPermissao === 4 ? null :
                                    <div className="col-md-6 mt-2">
                                        <label style={{ fontSize: '0.8rem' }}>Nível de prioridade</label>
                                        <SelectFiltro
                                            css="no-border"
                                            value={prioridadeSelected}
                                            options={
                                                prioridade !== undefined && Array.isArray(prioridade) && prioridade.length > 0 ?
                                                    prioridade.map(item => ({ value: item.value, label: item.label })) : null}
                                            onChange={(newValue) => selecionarPrioridade(newValue)}
                                            placeholder="Selecione"
                                        />
                                    </div>
                                }
                            </div>

                            <div className="row">
                                {user && user.codigoPermissao === 4
                                    ? null

                                    :
                                    <>
                                        <div className="col-md-6 mt-2">
                                            <label style={{ fontSize: '0.8rem' }}>Casa legislativa</label>
                                            <SelectFiltro
                                                css="no-border"
                                                value={casaSelected}
                                                options={
                                                    casa !== undefined && Array.isArray(casa) && casa.length > 0 ?
                                                        casa.map(item => ({ value: item.value, label: item.label })) : null}
                                                onChange={(newValue) => selecionarCasa(newValue)}
                                                placeholder="Selecione"
                                            />
                                        </div>

                                        <div className="col-md-6 mt-2">
                                            <label style={{ fontSize: '0.8rem' }}>Âmbito</label>
                                            <SelectFiltro
                                                css="no-border"
                                                value={ambitoSelected}
                                                options={
                                                    ambitoSelect !== undefined && Array.isArray(ambitoSelect) &&
                                                        ambitoSelect.length > 0 ?
                                                        ambitoSelect.map(item => ({ value: item.value, label: item.label })) : null}
                                                onChange={(newValue) => selecionarAmbito(newValue)}
                                                placeholder="Selecione"
                                            />
                                        </div>
                                    </>
                                }
                            </div>


                            <div className="row">
                                {user && user.codigoPermissao === 4
                                    ? null

                                    :
                                    <>
                                        <div className="col-md-6 mt-2">
                                            <label style={{ fontSize: '0.8rem' }}>Estado</label>
                                            <SelectFiltro
                                                css="no-border"
                                                name="estado"
                                                value={uf}
                                                options={
                                                    ufLista !== undefined && Array.isArray(ufLista) && ufLista.length > 0 ?
                                                        ufLista.map((item) => ({
                                                            value: item.id,
                                                            label: item.sigla
                                                        })) : null}
                                                onChange={(uf) => onChangeObterCasasByEstado(uf)}
                                                placeholder="Selecione"
                                            />
                                        </div>

                                        <div className="col-md-6 mt-2">
                                            <label style={{ fontSize: '0.8rem' }}>Casa</label>
                                            <SelectFiltro
                                                css="no-border"
                                                value={casaSelecionada}
                                                onChange={(newValue) => selecionarVisualizar(newValue)}
                                                options={
                                                    listaCasas !== undefined && Array.isArray(listaCasas) && listaCasas.length > 0 ?
                                                        listaCasas.map((item) => ({ value: item.id, label: item.sigla + ' - ' + item.nome, sigla: item.sigla, id: item.id })) : []}
                                                placeholder={"Selecionar"}
                                            />
                                        </div>
                                    </>
                                }
                            </div>


                            <hr style={{ backgroundColor: 'black' }} />

                            <Typography variant="h6" style={{ color: 'white', marginTop: '30px' }} >
                                Autor:
                            </Typography>

                            <div className="row">

                                <div className="col-md-6 mt-2">
                                    <label style={{ fontSize: '0.8rem' }}>Nome</label>
                                    <input
                                        style={{ border: 'none', backgroundColor: 'transparent', borderBottom: '1px solid #C4C4C4', color: '#C4C4C4', marginTop: '10px', width: '97%', placeholder: 'white' }}
                                        onChange={(event) => selecionarNome(event)}
                                        onFocus={limparCampoNome}
                                        value={nomeSelected}
                                        placeholder="Escreva"
                                        id="nomeInput"
                                    />
                                </div>

                                {/* <div className="col-md-4 mt-2">
                            <label style={{ fontSize: '0.8rem' }}>Estado</label>
                            <SelectFiltro 
                                css="no-border"
                                value={estadoSelected}
                                options={estado.map(item => ({ value: item.value, label: item.label }))}
                                onChange={(newValue) => selecionarEstado(newValue)} 
                                placeholder="Selecione"
                            />
                        </div> */}

                                {/* <div className="col-md-4 mt-2">
                            <label style={{ fontSize: '0.8rem' }}>Partido</label>
                            <SelectFiltro 
                                css="no-border"
                                value={partidoSelected}
                                options={partido.map(item => ({ value: item.value, label: item.label }))}
                                onChange={(newValue) => selecionarPartido(newValue)} 
                                placeholder="Selecione"
                            />
                        </div> */}
                            </div>

                            {/* <hr style={{  backgroundColor: 'black' }}/> */}


                            {/* <Typography variant="h6" style={{color: 'white', marginTop: '30px'}} >
                        Atribuição:
                    </Typography>  */}

                            {/* <FormGroup row  aria-label="position">
                        <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}   > 
                            <div className="mt-3">
                                <label className="container-new-checkbox"> Atribuídas à mim
                                    <input type="checkbox" value="4" id="aguardandoDesignacao" onChange={event => atribuidasMim(event)} checked={AtribuidasMim} />
                                    <span className="checkmark-new-checkbox"></span>
                                </label>
                            </div>
                            {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="1" id="aguardandoDesignacao" onChange={event => aguardandoDesignacaoSelected(event)} checked={aguardandoDesignacao} />}
                                label="Aguardando designação do relator"
                            /> */}
                            {/* </Grid> */}
                            {/* <Grid container item xs={12} sm={12} md={12} lg={6} xl={6}   >
                            <div className="mt-3">
                                <label className="container-new-checkbox"> Atribuídas ao Departamento
                                    <input type="checkbox" value="5" id="prazoEmendas" onChange={event => atribuidasAoDepartamento(event)} checked={AtribuidasAoDepartamento} />
                                    <span className="checkmark-new-checkbox"></span>
                                </label>
                            </div> */}
                            {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="2" id="prazoEmendas" onChange={event => aguardandoPrazoSelected(event)} checked={prazoEmendas} />}
                                label="Prazo para Emendas" 
                            /> */}
                            {/* </Grid>
                        
                    </FormGroup>  */}

                            {/* <hr style={{  backgroundColor: 'black' }}/> */}


                            {/* <Typography variant="h6" gutterBottom style={{color: 'white', marginTop: '30px'}}>
                        Ações Preparatórias:
                    </Typography>      */}

                            {/* <FormGroup row  aria-label="position"> */}
                            {/* <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   >  */}
                            {/* <div className="mt-3">
                                <label className="container-new-checkbox"> No Prazo
                                    <input type="checkbox" value="1" id="selectTodas" onChange={event => setNoPrazoAcoes(event)} checked={NoPrazoAcoesSelected} />
                                    <span className="checkmark-new-checkbox"></span>
                                </label>
                            </div> */}
                            {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="1" id="selectTodas" onChange={event => setTodas(event)} checked={todasSelected}/>}
                                label="Todas"
                            /> */}
                            {/* </Grid> */}
                            {/* <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   > */}
                            {/* <div className="mt-3">
                                <label className="container-new-checkbox"> Em Atraso
                                    <input type="checkbox" value="2" id="selectSeguidas" onChange={event => EmAtrasoAcoes(event)} checked={EmAtrasoAcoesSelected} />
                                    <span className="checkmark-new-checkbox"></span>
                                </label>
                            </div>  */}
                            {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="2" id="selectSeguidas" onChange={event => setSeguidas(event)} checked={seguidasSelected}/>}
                                label="Seguidas" 
                            /> */}
                            {/* </Grid> */}
                            {/* <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   >  */}
                            {/* <div className="mt-3">
                                <label className="container-new-checkbox"> Finalizadas
                                    <input type="checkbox" value="3" id="selectNovas" onChange={event => FinalizadasAcoes(event)} checked={FinalizadasAcoesSelected} />
                                    <span className="checkmark-new-checkbox"></span>
                                </label>
                            </div> */}
                            {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="3" id="selectNovas" onChange={event => setNovas(event)} checked={novasSelected}/>}
                                label="Novas" 
                            /> */}
                            {/* </Grid> */}
                            {/* <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   > */}
                            {/* <div className="mt-3">
                                <label className="container-new-checkbox"> Pendentes
                                    <input type="checkbox" value="3" id="selectAtualizacaoRecente" onChange={event => PendentesAcoes(event)} checked={PendentesAcoesSelected} />
                                    <span className="checkmark-new-checkbox"></span>
                                </label>
                            </div> */}
                            {/* <FormControlLabel
                                control={<Checkbox style={{color: 'white'}} value="3" id="selectAtualizacaoRecente" onChange={event => setAtualizacao(event)} checked={atualizacaoSelected}/>}
                                label="Atualização Recente" 
                            /> */}
                            {/* </Grid> */}
                            {/* </FormGroup>  */}

                            <hr style={{ backgroundColor: 'black' }} />

                            {
                                tamanhoTela > 600
                                    ?
                                    <div className="row mt-5">
                                        <div className="col-6 text-left">
                                            <span onClick={limparFiltro} style={{ calor: 'white', backgroundColor: 'transparent', cursor: 'pointer' }}>Limpar Filtro</span>
                                        </div>
                                        <div className="col-6 text-right">
                                            <button onClick={filtrar} variant="contained" className="btn btn-dark">
                                                <img
                                                    className="mr-3"
                                                    src={require('../../../design/assets/images/filtar.png')}
                                                    // src={require('../../design/assets/images/teste-botao-filtro.svg')}
                                                    alt="anexo"
                                                />{' '}
                                                Filtrar
                                            </button>
                                        </div>
                                    </div>

                                    :

                                    <div className="row mt-4 pb-4">

                                        <div className="col-4 text-left">
                                            <span onClick={limparFiltro} style={{ calor: 'white', backgroundColor: 'transparent', cursor: 'pointer' }}>Limpar Filtro</span>
                                        </div>
                                        <div className="col-5 text-right">
                                            <button onClick={filtrar} variant="contained" className="btn btn-dark">
                                                <img
                                                    className="mr-2"
                                                    src={require('../../../design/assets/images/filtar.png')}
                                                    // src={require('../../design/assets/images/teste-botao-filtro.svg')}
                                                    alt="anexo"
                                                />{' '}
                                                Filtrar
                                            </button>
                                        </div>
                                        <div className="col-3 text-right pr-2">
                                            <button onClick={handleClose} variant="contained" className="btn btn-dark">
                                                Fechar
                                            </button>
                                        </div>
                                    </div>
                            }




                        </div>
                    }


                </DialogContent>

            </Dialog>
        </div>
    );
}

