import moment from 'moment-timezone';
import 'moment/locale/pt-br'

moment.tz.setDefault('UTC');
const localizacao = 'pt-BR'
moment.locale(localizacao)

const formatoPadrao = 'DD/MM/YYYY'
const formatoDeUrl = 'DD-MM-YYYY'
const formatoDataHora = 'DD/MM/YYYY [às] HH:mm'
const formatoDataHoraNoBancoDeDados = 'YYYY[-]MM[-]DD[T]H:mm:ss'
const formatoDataString = 'ddd MMM DD YYYY HH:mm:ss'
const ehData = d => moment.isMoment(d)
export const temHorario = d => formatarHora(d) !== '00:00'
export const formatarData = d => moment(d, formatoDataHoraNoBancoDeDados, 'en').format('DD/MM/YYYY')
export const formatarHora = d => moment(d, formatoDataHoraNoBancoDeDados, 'en').format('HH:mm')

export const dataHora = d => moment(d, formatoDataHoraNoBancoDeDados, 'en').format('DD/MM/YYYY [às] HH:mm')

export const data = d => moment(d, formatoDataHoraNoBancoDeDados, 'en').format('DD/MM/YYYY')
export const calcularIdade = d => moment().diff(moment(d, formatoDataHoraNoBancoDeDados, 'en'), 'years')

export const hojeDataUrl = () => moment().format(formatoDeUrl)
export const ontem = () => moment().subtract(1, 'day')
export const amanha = () => moment().add(1, 'day')
export const segunda = () => moment().startOf('week').add(1, 'day')
export const umaSemanaAtras = () => moment().subtract(7, 'days')
export const mesPassado = () => moment().subtract(1, 'months')
export const anoPassado = () => moment().subtract(1, 'year')

export const compararDia = (a, b) => formatar(a) === formatar(b)

export const converterGenerico = str => {
    if (!str || ehData(str)) return str;
    let data = bancoDeDadosParaData(str)
    if(data && data.isValid()) return bancoDeDadosParaData(str)
    data = stringParaData(str) 
    if(data && data.isValid()) return stringParaData(str)
    return null
}

export const bancoDeDadosParaData = str =>
    str ? ehData(str) ? str : moment(str, formatoDataHoraNoBancoDeDados) : null

export const converterDataHora = str =>
    str ? ehData(str) ? str : moment(str, formatoDataHora) : null

export const stringParaData = str =>
    str ? ehData(str) ? str : moment(str.split(' GMT')[0], formatoDataString, 'en') : null

export const formatar = data => data && data.clone().locale(localizacao).format(formatoPadrao)
export const formatarParaUrl = data => data && data.clone().locale(localizacao).format(formatoDeUrl)
export const formatarDataHora = data => data && data.clone().locale(localizacao).format(formatoDataHora)
export const formatarParaBancoDeDados = data => data && data.clone().locale('en').format(formatoDataHoraNoBancoDeDados)

export const descricaoDiasDaSemana = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']

export const meses = [
    { id: 1, sigla: 'JAN', nome: 'Janeiro' },
    { id: 2, sigla: 'FEV', nome: 'Fevereiro' },
    { id: 3, sigla: 'MAR', nome: 'Março' },
    { id: 4, sigla: 'ABR', nome: 'Abril' },
    { id: 5, sigla: 'MAI', nome: 'Maio' },
    { id: 6, sigla: 'JUN', nome: 'Junho' },
    { id: 7, sigla: 'JUL', nome: 'Julho' },
    { id: 8, sigla: 'AGO', nome: 'Agosto' },
    { id: 9, sigla: 'SET', nome: 'Setembro' },
    { id: 10, sigla: 'OUT', nome: 'Outubro' },
    { id: 11, sigla: 'NOV', nome: 'Novembro' },
    { id: 12, sigla: 'DEZ', nome: 'Dezembro' }
]