import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { get, put } from '../../../services/http'
//import SelectFiltro from '../../../componentes/forms/basic/selectFiltro'
import { posicionamentosClient } from "../../../enums/posicionamento";
// import { ClientPrioridades } from "../../../enums/prioridades";
import { buscarListaPrioridades } from "../../../../src/enums/prioridades";
import { impactoFinanceiroOptions } from "../../../enums/impactoFinanceiro";
import Loading from '../../../componentes/loading'
import FormatInput from '../../../componentes/forms/inputs/formatInput'
import ClientCheckbox from '../../../componentes/forms/basic/clientCheckbox'
import ClientSelectModalEsfCol from '../../../componentes/forms/basic/clientSelectModalEsfCol'
import CurrencyFormat from 'react-currency-format';
import { makeStyles } from '@material-ui/core/styles';
// import clientSelect from '../../../componentes/forms/basic/clientSelect'
// import SelectInfluenciarSobre from '../../../componentes/forms/basic/selectInfluenciarSobre'
// import { resultadoclient } from '../../../enums/resultadoDaAcao';
import Anexos from '../components/anexos'

export default function FormDialog(props) {

    const user = JSON.parse(window.localStorage.getItem('user'))

    const [open, setOpen] = useState(false);
    const [posicionamento, setPosicionamento] = useState(props.create ? '' : props.posicionamento);
    const [prioridade, setPrioridade] = useState(props.create ? '' : props.prioridade);
    const [impactoFinanceiro, setImpactoFinanceiro] = useState(props.create ? '' : props.tipoImpactoFinanceiro);
    const [valorFinanceiro, setValorFinanceiro] = useState(props.create ? 0 : props.valorImpactoFinanceiro);
    //const [profit, setProfit] = useState(props.create ? '' : props.valorImpactoFinanceiro)
    const [justificativa, setJustificativa] = useState(props.create ? '' : props.justificativa);
    const [aprovado, setAprovado] = useState(props.create ? 0 : props.aprovado)
    const [loading, setLoading] = useState(false);

    const responsavel = user.nome
    //const [respDepartamento, setRespDepartamento] = React.useState('')
    const [departamento, setDepartamento] = React.useState(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const _posicionamentoGestor = useRef(null)
    const _prioridade = useRef(null)
    const _impactoFinanceiro = useRef(null)
    const _descricao = useRef(null)

    const [formErrorPG, setFormErrorPG] = useState(false)
    const [formErrorPR, setFormErrorPR] = useState(false)
    const [formErrorIM, setFormErrorIM] = useState(false)
    const [formErrorDS, setFormErrorDS] = useState(false)

    const [ClientPrioridades, setClientPrioridades] = useState([])

    useEffect(() => {
        getDepartamento()

        //chamando a lista de prioridades do banco
        buscarListaPrioridades()
            .then(res => {
                setClientPrioridades(res)
            })

    }, [formErrorPG, formErrorPR, formErrorIM, valorFinanceiro])
    const handleClickOpen = () => {
        setOpen(true);
        getDepartamento()
    };

    const handleClose = () => setOpen(false);

    const handleOnChange = (tipo, value, event) => {

        switch (tipo) {
            case 'posicionamento':
                setPosicionamento(value.id)
                break;

            case 'prioridade':
                setPrioridade(value.id)
                break;

            case 'impactoFinanceiro':
                setImpactoFinanceiro(value.id)
                break;

            case 'valorImpactoFinanceiro':
                setValorFinanceiro(value)
                break;

            case 'justificativa':
                setJustificativa(event.target.value)
                break;

            case 'aprovado':
                setAprovado(value)
                break;


            default:
                break;
        }


    }

    // apelido: null
    // criado_em: "2019-11-18T13:21:44.791Z"
    // email: "roberto.lima@spread.com.br"
    // id_cliente: 1
    // departamento: 1
    // id_licenca: 1
    // id_permissao: 1
    // id_usuario: 14
    // nome: "roberto.lima"
    // password: "be4d81ecff8e84dbfbda45140edbbdd6"
    // situacao: 1
    // url_foto: null
    // usa_apelido: false
    // usuario: "roberto.lima"

    const getDepartamento = async () => {
        await get(`departamento/${user.departamento}`)
            .then(response => {

                const data = response[0].descricao || {}
                //console.log('DESCRICAO>>>>>',response.descricao)
                //console.log('DEPPPP>>>>>',response)
                //const dep = response && response.map(i => i.id_departamento === user.departamento ? i.descricao : null).filter(x => x)[0]

                setDepartamento(data)

            })
            .catch(err => err)
    }

    const tamanhoTela = window.innerWidth

    //console.log('DEPARTAMENTO>>>>', departamento)

    const validateForm = () => {

        //console.log('>>>>>>>>>>>>>>>',_descricao.current.value)

        _posicionamentoGestor.current.state.selected === undefined ? setFormErrorPG(true) : setFormErrorPG(false)
        _prioridade.current.state.selected === undefined ? setFormErrorPR(true) : setFormErrorPR(false)
        _impactoFinanceiro.current.state.selected === undefined ? setFormErrorIM(true) : setFormErrorIM(false)
        _descricao.current.value === '' ? setFormErrorDS(true) : setFormErrorDS(false)

        if (_posicionamentoGestor.current.state.selected !== undefined &&
            _prioridade.current.state.selected !== undefined &&
            _impactoFinanceiro.current.state.selected !== undefined &&
            _descricao.current.value !== ''
        ) {

            save();
        }

    }
    const save = async () => {

        //console.log(props.dadosAcaoPreparatoria.notas_tecnicas[0].id_responsavel)
        const data = {
            departamento: departamento,
            posicionamento,
            prioridade,
            tipo_impacto_financeiro: impactoFinanceiro,
            valor_impacto_financeiro: valorFinanceiro,
            justificativa,
            aprovado,
            id: props.create ? props.idNotaTecnica : props.dadosNotasTecnicas.id,
            id_acao_preparatoria: props.create ? props.dadosAcaoPreparatoria.id : props.dadosNotasTecnicas.id_acao_preparatoria,
            // id_responsavel: props.create ? props.dadosAcaoPreparatoria.id_responsavel : props.dadosNotasTecnicas.id_responsavel,
            id_responsavel: JSON.parse(localStorage.getItem('user')).id,
            id_cliente: props.create ? props.dadosAcaoPreparatoria.id_cliente : props.dadosNotasTecnicas.id_cliente,
            arquivada: props.create ? props.dadosAcaoPreparatoria.arquivada : props.dadosNotasTecnicas.arquivada,
            id_proposicao: props.dadosAcaoPreparatoria.id_proposicao,
            validacao: props.create,
            id_proposicao_novo: props.idNovo
        }

        setLoading(true)
        // console.log(JSON.parse(localStorage.getItem('user')))
        // console.log('DATA SAVE>>>>', props.dadosAcaoPreparatoria)
        await put(`/acao-preparatoria/${props.dadosAcaoPreparatoria.id}/nota-tecnica/${props.idNotaTecnica}`, data)
            .then(res => {
                setLoading(false)
                setOpen(false)
                props.onClick()
            })
            .catch(err => err)




    }

    const useStylesButtonFooter = makeStyles({
        root: {
            color: '#3E4049',
            backgroundColor: 'White',
            '&:hover': {
                backgroundColor: '#FFF',
            },
        },

    })(Button);


    //console.log(_prioridade.current)
    // console.log('DADOS NOTAS TECNICAS >>>',props.dadosNotasTecnicas)
    // console.log('DADOS ACAO PREPARATORIA >>>',props.dadosAcaoPreparatoria)
    //console.log('props.idProposicaoCasaExterna>>>>>>>>>>>',props.idProposicaoCasaExterno)



    return (


        <div>
            {props.create === true ?
                <div onClick={handleClickOpen}>
                    <span className='client-texto mr-2 fs-18'>+</span>
                    <span className="client-link fs-12">Adicionar</span>
                </div>
                :
                <div onClick={handleClickOpen} className="col-1 cursor-pointer" >
                    <img src={require('../../../design/assets/images/editar-gray.svg')} alt="" />
                </div>
            }

            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title" >

                <DialogContent
                    style={{
                        width: tamanhoTela > 600 ? 600 : tamanhoTela,
                        backgroundColor: "white",
                        color: '#3E4049',
                        //height: 500
                    }}
                >

                    {

                        loading ? <div style={{ marginTop: '500px' }}><Loading /></div> :

                            <div>

                                <h5 style={{ color: "black", fontWeight: "bold" }}>Esforço Colaborativo</h5>

                                <div className="row mt-4">
                                    <FormatInput cols="6"  >
                                        {responsavel ? <p className="label-form-modal-esfcol">Responsável</p> : null}
                                        <input
                                            style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid lightgray", width: "100%" }}
                                            id="esfcol_modal_resp"
                                            name="responsavel"
                                            readOnly={true}
                                            value={responsavel}
                                            className={`client-input client-texto-form`}
                                        />
                                    </FormatInput>

                                    <FormatInput
                                        cols="6"
                                    >
                                        {departamento ? <p className="label-form-modal-esfcol">Departamento</p> : null}
                                        <input
                                            style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid lightgray", width: "100%" }}
                                            id="esfcol_modal_dep"
                                            name="departamento"
                                            readOnly={true}
                                            defaultValue={departamento}
                                            className={`client-input client-texto-form`}
                                            placeholder="Departamento"
                                            autoComplete="off"
                                        />
                                    </FormatInput>
                                </div>

                                <div className="row mt-4">
                                    {/* <div className="col-sm-6 mt-2">
                                <label className="label-form-filtro">Posicionamento do gestor</label>
                                <SelectFiltro
                                    value={posicionamento}
                                    onChange={(posicionamento) => handleOnChange('posicionamento',posicionamento)}
                                    options={posicionamentosclient.map(item => ({ value: item.id, label: item.value }))}
                                    placeholder={'Posicionamento do gestor'}
                                />
                            </div> */}

                                    <FormatInput
                                        //css={(this.state.casa_legislativa) ? "" : "label-disabled"}
                                        cols="12 12 6"
                                    //label="Posicionamento do gestor"
                                    //error={handleError("ambito")}
                                    >
                                        <label className="label-form-modal-esfcol">Posicionamento do gestor</label>
                                        <ClientSelectModalEsfCol
                                            borderBottomColor="lightgray"
                                            id="esfcol_modal_pos"
                                            label="Posicionamento do gestor"
                                            items={posicionamentosClient}
                                            value={posicionamento}
                                            placeholder={''}
                                            onChange={(posicionamento) => handleOnChange('posicionamento', posicionamento)}
                                            ref={_posicionamentoGestor}
                                        />

                                        {
                                            formErrorPG
                                                ?
                                                <small className="form-text text-danger">campo obrigatório *</small>
                                                :
                                                null
                                        }


                                    </FormatInput>

                                    <FormatInput
                                        //css={(this.state.casa_legislativa) ? "" : "label-disabled"}
                                        cols="12 12 6"
                                    //label="Prioridade"
                                    //error={this.props.handleError("ambito")}
                                    >
                                        <label className="label-form-modal-esfcol">Prioridade</label>
                                        <ClientSelectModalEsfCol
                                            borderBottomColor="lightgray"
                                            id="esfcol_modal_prio"
                                            label="Prioridade"
                                            items={ClientPrioridades}
                                            placeholder={''}
                                            value={prioridade}
                                            onChange={(prioridade) => handleOnChange('prioridade', prioridade)}
                                            ref={_prioridade}
                                        />
                                        {
                                            formErrorPR
                                                ?
                                                <small className="form-text text-danger">campo obrigatório *</small>
                                                :
                                                null
                                        }
                                    </FormatInput>
                                </div>

                                {
                                    prioridade === 5

                                        ?
                                        <FormatInput
                                            cols="12"
                                        //label="Aprovado pela diretoria?" 
                                        >
                                            <label style={{ fontSize: '16px' }}>Aprovado pela diretoria?</label>
                                            <p style={{ marginLeft: '3px', fontSize: '16px' }} className={!aprovado ? "client-vermelho-aprovado-diretoria" : ""}>"Projeto com prioridade maxima exigem o "de acordo" da diretoria do seu departamento".</p>
                                            <ClientCheckbox
                                                label="Sim"
                                                //value={aprovado}
                                                value={true}
                                                onClick={(aprovado) => handleOnChange('aprovado', aprovado, '')}


                                            />
                                        </FormatInput>

                                        :
                                        null
                                }


                                <div className="row mt-2">

                                    <div className='col-12 col-sm-6 col-lg-6 col-xl-6'>
                                        <label className="label-modal-impacto-financeiro">Impacto financeiro</label>
                                        <ClientSelectModalEsfCol
                                            borderBottomColor="lightgray"
                                            id="esfcol_modal_tipimpf"
                                            label="Impacto financeiro"
                                            items={impactoFinanceiroOptions}
                                            className={'select-modal-impacto-financeiro'}
                                            placeholder={''}
                                            value={impactoFinanceiro}
                                            onChange={(impactoFinanceiro) => handleOnChange('impactoFinanceiro', impactoFinanceiro)}
                                            ref={_impactoFinanceiro}
                                        />
                                        {
                                            formErrorIM
                                                ?
                                                <small className="form-text text-danger">campo obrigatório *</small>
                                                :
                                                null
                                        }



                                    </div>

                                    <div className='col-12 col-sm-6 col-lg-6 col-xl-6' style={{ marginTop: "20px", padding: "none" }}>
                                        <FormatInput
                                            //css={(this.state.casa_legislativa) ? "" : "label-disabled"}
                                            cols="12"
                                        //label="Valor do impacto financeiro"
                                        //error={this.props.handleError("ambito")}
                                        >

                                            <label className="label-form-modal-esfcol" style={{ marginLeft: "-15px", width: "100%" }}>Valor do impacto financeiro</label>
                                            <CurrencyFormat
                                                style={{ marginLeft: "-15px", borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid lightgray", width: "112%", }}
                                                thousandSeparator={true}
                                                isNumericString={true}
                                                prefix={'R$ '}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setValorFinanceiro(value, () => handleOnChange('valorImpactoFinanceiro', valorFinanceiro, ''))
                                                }}
                                                id="esfcol_modal_valimpf"
                                                type='text'
                                                minLength="0"
                                                maxLength="15"
                                                name="valor_impacto_financeiro"
                                                //label="Valor do impacto financeiro"
                                                // className={`client-input-esfor-valor-impact`}
                                                value={valorFinanceiro}
                                                placeholder=''
                                                //onChange={(event) => handleOnChange('valorImpactoFinanceiro', profit, event)}
                                                //onKeyDown={event => this.addValue(event)}
                                                autoComplete="off"
                                            />
                                        </FormatInput>
                                    </div>





                                </div>

                                <div className="row mt-4">

                                    <FormatInput cols="12"
                                        label="Descrição"
                                    //error={true}

                                    >
                                        {
                                            formErrorDS
                                                ?
                                                <small className="form-text text-danger">campo obrigatório *</small>
                                                :
                                                null
                                        }
                                        <textarea
                                            ref={_descricao}
                                            label="Justificativa"
                                            name="justificativa"
                                            rows="3"
                                            maxLength={4000}
                                            value={justificativa}
                                            className={`form-control form-control-lg`}
                                            placeholder="Digite aqui"
                                            autoComplete="off"
                                            onChange={(event) => handleOnChange('justificativa', '', event)}
                                        //onMouseLeave={event => this.contadorCaracter(event)}
                                        //onBlur={event => this.contadorCaracter(event)}     

                                        >
                                        </textarea>
                                    </FormatInput>


                                </div>

                                <div className="mt-4">
                                    {props.create === true ?
                                        <Anexos
                                            idParametro={props.create ? props.idNotaTecnica : props.dadosNotasTecnicas.id}
                                            idProposicaoCasaExterna={props.create ? props.idNotaTecnica : props.dadosNotasTecnicas.id}
                                            modulo={'esforco-colaborativo'}
                                            create={props.create}
                                        />
                                        :
                                        null
                                    }
                                </div>



                                <Divider variant="middle" />
                                <DialogActions >

                                    <Button fullWidth={true} autoFocus onClick={handleClose} className={useStylesButtonFooter.root} >
                                        CANCELAR
                            </Button>

                                    <div className="linha"></div>

                                    <Button fullWidth={true} autoFocus onClick={validateForm} className={useStylesButtonFooter.root}>
                                        SALVAR
                            </Button>

                                </DialogActions>


                            </div>
                    }

                </DialogContent>

            </Dialog>
        </div>
    );
}

