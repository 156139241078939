import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                labels: ['Câmara dos Deputados', 'Senado Federal', 'Congresso Nacional'],
                colors: ['#4CD964','#F63854', '#5856D7'],
                legend: {
                    show: false,
                }
            }
        }
    }


    render() {
        const larguraTela = window.innerWidth
        const larguraGrafico = larguraTela > 375 ? '330' : '260'
        const series1 = [
            this.props.propCamara !== undefined ? parseInt(this.props.propCamara) : 0,
            this.props.propSenado !== undefined ? parseInt(this.props.propSenado) : 0,
            this.props.propCongresso !== undefined ? parseInt(this.props.propCongresso) : 0,
        ]
        const series2 = [0,0,0]
        const series = this.props.propCamara !== undefined && this.props.propSenado !== undefined && this.props.propCongresso !== undefined ? series1 : series2
        // console.log('TESTE AQUI DONUT ===>',this.props.dados)

        return (
            <div className="donut">
                <Chart 
                    options={this.state.options} 
                    series={series} 
                    type="donut" 
                    width={larguraGrafico}
                />
            </div>
        );
    }
}

export default Donut;
