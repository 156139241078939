import React, { useEffect } from 'react';
import { get } from "../../services/http";
// import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FileUploadPhotoBase64 from './FileUploadPhotoBase64'
import ClientSelect from '../../componentes/forms/basic/clientSelectModalCadUser'
//import { get } from 'lodash';




export default function FormDialog(props) {

  const [open, setOpen] = React.useState(false);
  const [imgFile, setImgFile] = React.useState([])
  const [listDepartamento, setLisDepartamento] = React.useState([])
  const [listPermissao, setListPermissao] = React.useState([])

  const [departamento, setDepartamento] = React.useState([])
  const [permissao, setPermissao] = React.useState([])

  //armazena os erros dos campos em um objeto
  const [errors, setErrors] = React.useState({})
  //Faço a validação do Form, se existir erro >> true, se nao false, seto inicialmente com true para nao chamar a função dentro do UseEffect
  const [validaErrors, setValidaErrors] = React.useState(true)

  const [dadosForm, setDadosForm] = React.useState(
    {

      nome: '',
      id_usuario: '',
      id_departamento: '',
      email: '',
      usuario: '',
      password: '',
      id_permissao: '',
      status: false,
      url_foto: '',
      id_licenca: 1,


    })


  useEffect(() => {

    //Se não existir erros, e validaErrors false
    //coloco a consta validaErrors, para ficar verifcando se ouve atualização nela.
    if (Object.keys(errors).length === 0 && validaErrors === false) {
      handleSaveUser()
    }


  }, [validaErrors])

  function validate() {

    //console.log(dadosForm.id_departamento)
    let messageError = '*Campo Obrigatorio'
    let errors = {}

    if (!dadosForm.nome) {
      errors.nome = messageError
    }

    if (!dadosForm.id_departamento) {
      errors.departamento = messageError
    }

    if (!dadosForm.email) {
      errors.email = messageError
    }

    if (!dadosForm.usuario) {
      errors.usuario = messageError
    }

    if (props.create === true) {
      if (!dadosForm.password) {
        errors.password = messageError
      }
    }

    if (!dadosForm.id_permissao) {
      errors.permissao = messageError
    }

    //atualizo a const, se existir erros, true, se não false
    //Object.keys(errors).length verifica se existe conteudo dentro do objeto
    Object.keys(errors).length === 0 ? setValidaErrors(false) : setValidaErrors(true)

    return errors
  }

  //função intermediaria que auxilia na validação dos campos
  const handleSubmit = () => {

    setErrors(validate())
    //console.log(validate())   
    //retorna um objeto de errors
    //exemplo {departamento: "Erro" , nome: "Erro"}



  }
  const handleSaveUser = () => {

    //console.log('PROPS CREATE',props.create)
    //console.log('SALVAR USUARIO',dadosForm)

    //console.log('Sem Erros')
    props.create === true ? props.callbackParent('NovoUsuario', dadosForm) : props.callbackParent('AtualizarUsuario', dadosForm)
    setOpen(false);

  }

  const handleClickDelete = () => {
    props.callbackParent('DeletarUsuario', props.dadosUsuario, true)
  }

  const handleClickOpen = () => {
    //console.log(props.dadosUsuario)

    getDepartamento()
    getPermissao()

    setOpen(true);

    if (props.create === false) {

      //faço destruct para carregar os campos quando estiver editando
      let {
        departamento,
        email,
        id_departamento,
        id_permissao,
        id_usuario,
        nome,
        permissao,
        situacao,
        url_foto,
        usuario

      } = props.dadosUsuario

      //console.log(email)

      setDadosForm({
        ...dadosForm,
        nome: nome,
        email: email,
        usuario: usuario,
        status: situacao === 1 ? true : false,
        id_usuario: id_usuario,
        url_foto: url_foto,
        id_departamento: id_departamento,
        id_permissao: id_permissao,

      })
      setDepartamento({ value: id_departamento, label: departamento })
      setPermissao({ value: id_permissao, label: permissao })

    } else {

      setDadosForm({

        nome: '',
        id_usuario: '',
        id_departamento: '',
        email: '',
        usuario: '',
        password: '',
        id_permissao: '',
        status: false,
        url_foto: '',
        id_licenca: 1,


      })
      setDepartamento([])
      setPermissao([])
      setImgFile([])

    }

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeSelectDep = (event) => {

    //console.log(event.value)
    setDepartamento(event)
    setDadosForm({
      ...dadosForm,
      id_departamento: event.value,
    })


  }


  const handleChangeSelectPer = (event) => {
    setPermissao(event)
    setDadosForm({
      ...dadosForm,
      id_permissao: event.value,

    })


  }

  const handleChange = (event) => {
    //console.log('Senha >>', event.target.name, event.target.value)

    setDadosForm({
      ...dadosForm,
      [event.target.name]: event.target.name === 'status' ? event.target.checked : event.target.value
    })

  }

  function RemoverFoto() {
    setImgFile([])
  }

  const getFiles = (imgPhotoFile) => {
    //console.log('getFiles>>>>>',imgFile)
    setImgFile(imgPhotoFile)
    setDadosForm({ ...dadosForm, url_foto: imgPhotoFile[0].base64 })

  }

  const getDepartamento = async () => {
    await get(`/usuarios/relatorios/departamentos`)
      .then(res => {
        //console.log('DEPARTAMENTO>>',res)
        setLisDepartamento(res)
      })
      .catch(err => err)

  }

  const getPermissao = async () => {
    await get(`/usuarios/permissoes`)
      .then(res => {
        //console.log('PERMISSAO>>',res)
        setListPermissao(res)
      })
      .catch(err => err)

  }

  const useStylesButtonFooter = makeStyles({
    root: {
      color: '#3E4049',
      backgroundColor: 'White',
      '&:hover': {
        backgroundColor: '#FFF',
      },
    },

  })(Button);

  // var InputCadUser = withStyles({
  //     root: {

  //       '& label.Mui-focused': {
  //         color: '#DC2D41',
  //       },
  //       '& .MuiInput-underline:after': {
  //         borderBottomColor: '#DC2D41',
  //       },

  //     },
  //   })(TextField);

  const StatusSwitch = withStyles({
    switchBase: {
      color: '#FFF',
      '&$checked': {
        color: '#4CD964',
      },
      '&$checked + $track': {
        backgroundColor: '#4CD964',
      },
    },
    checked: {},
    track: {},
  })(Switch);


  const inputStyle = {
    border: 'none',
    backgroundColor: 'transparent',
    borderBottom: '1px solid #C4C4C4',
    color: '#000000',
    marginTop: '10px',
    width: '100%',
    placeholder: 'red',
    outline: 'none'
  };
  //console.log('props>>>>>',props)

  const tamanhoTela = window.innerWidth
  const URLpreview = imgFile.length > 0 ? imgFile[0].base64 : null
  const classStatusCheck = dadosForm.status ? "#DC2D41" : "#9B9B9B"

  //console.log('ARRAY IMG>>>', imgFile)
  //console.log('PROPS DADOS USUARIOS CLICADO>>>>', props.dadosUsuario)

  //console.log('>>>>>', dadosForm.url_foto)

  return (


    <div>



      {props.create === true
        ?
        <button className='btn-usuario btn-block' style={{ borderRadius: "7px" }} onClick={handleClickOpen}>Cadastrar Usuário</button>
        :
        <div>
          <i className="icon-editar-gray mr-2" onClick={handleClickOpen} style={{ backgroundColor: '#3E4049', color: '#FFF', padding: '10px', borderRadius: '3px', cursor: 'pointer' }}></i>
          <i className="icon-lixeira " onClick={handleClickDelete} style={{ backgroundColor: '#3E4049', color: '#FFF', padding: '10px', borderRadius: '3px', cursor: 'pointer' }}></i>
        </div>
      }
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} maxWidth={'sm'}>

        {props.create === true
          ?
          <div className="mt-2 ml-3"><h5>Cadastro de Novo Usuário</h5></div>
          :
          <div className="mt-2 ml-3"><h5>Editar Usuário</h5></div>
        }
        <DialogContent
          style={{
            height: tamanhoTela > 700 ? 950 : tamanhoTela,
            //backgroundColor: "#3E4049",
            //color: 'white'

          }}
          overflow="hidden"

        >



          <div className="d-flex justify-content-center align-items-center flex-column">

            {imgFile.length > 0

              ?

              <div className="foto-usuario-preview">
                <span className="d-flex justify-content-center align-items-center">
                  <img id="img-foto-usuario-preview" src={URLpreview} alt='' />
                </span>
              </div>

              :
              props.create === true
                ?
                <div className="foto-usuario">
                  <span className="d-flex justify-content-center align-items-center" style={{ marginTop: '5px' }}>
                    <i className="icon-photo-user-default photo-user-default"></i>
                  </span>
                </div>

                :

                dadosForm.url_foto !== null && dadosForm.url_foto !== ""
                  ?
                  <div className="foto-usuario-preview">
                    <span className="d-flex justify-content-center align-items-center">
                      <img id="img-foto-usuario-preview" src={dadosForm.url_foto} alt='' />
                    </span>
                  </div>
                  :
                  <div className="foto-usuario">
                    <span className="d-flex justify-content-center align-items-center" style={{ marginTop: '5px' }}>
                      <i className="icon-photo-user-default photo-user-default"></i>
                    </span>
                  </div>
            }

            <FileUploadPhotoBase64
              onDone={getFiles}
            />

            <div style={{ width: '150px', marginTop: '50px' }}>
              <span onClick={RemoverFoto} style={{ cursor: 'pointer' }}>
                <p className="text-center" style={{ color: '#9B9B9B', fontSize: '12px' }}>Remover Foto</p>
              </span>
            </div>

          </div>

          <div className="row">
            <div className="col-md-6 mt-1">
              <label className="label-form-filtro-usuario">Nome</label>
              <input
                name={'nome'}
                type="text"
                style={inputStyle}
                onChange={handleChange}
                //onFocus={limparCampoNumero}
                placeholder="Digite"
                value={dadosForm.nome}
                id="docrelanoInput"
                autoComplete="off"
                maxLength={'40'}

              />
              {errors.nome && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.nome}</span>}
              {/* <TextField
                        //autoFocus
                        color="secondary"
                        name={'nome'}                       
                        value={dadosForm.nome}
                        onChange={handleChange}
                        margin="dense"
                        id="nome"
                        label="Nome"
                        type="text"
                        autoComplete="off"
                        fullWidth
                    /> */}
            </div>
            <div className="col-md-6 mt-1">
              <label className="label-form-filtro-usuario">Departamento</label>
              <ClientSelect

                css="no-border"
                name="departamento"
                value={departamento}
                options={
                  listDepartamento !== undefined && Array.isArray(listDepartamento) && listDepartamento.length > 0 ?
                    listDepartamento.map((item) => ({ value: item.id, label: item.title })) : null}
                onChange={(newValue) => handleChangeSelectDep(newValue)}
                placeholder={'Selecione'}
              />
              {errors.departamento && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.departamento}</span>}

            </div>
            <div className="col-md-6 mt-1">
              <label className="label-form-filtro-usuario">E-mail</label>
              <input
                name={'email'}
                type="text"
                style={inputStyle}
                onChange={handleChange}
                //onFocus={limparCampoNumero}
                placeholder="Digite"
                value={dadosForm.email}
                id="docrelanoInput"
                autoComplete="off"
                maxLength={'60'}

              />
              {errors.email && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.email}</span>}
              {/* <TextField
                        //autoFocus
                        color="secondary"
                        name={'email'}
                        margin="dense"
                        id="email"
                        label="E-mail"
                        type="email"
                        autoComplete="off"
                        fullWidth
                        value={dadosForm.email}
                        onChange={handleChange}
                    /> */}
            </div>

            <div className="col-md-6 mt-1">
              <label className="label-form-filtro-usuario">Usuário</label>
              <input
                name={'usuario'}
                type="text"
                style={inputStyle}
                onChange={handleChange}
                //onFocus={limparCampoNumero}
                placeholder="Digite"
                value={dadosForm.usuario}
                id="docrelanoInput"
                autoComplete="off"
                maxLength={'20'}

              />
              {errors.usuario && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.usuario}</span>}
              {/* <TextField
                        //autoFocus
                        color="secondary"
                        name={'usuario'}
                        margin="dense"
                        id="usuario"
                        label="Usuário"
                        type="text"
                        autoComplete="off"
                        fullWidth
                        value={dadosForm.usuario}
                        onChange={handleChange}
                    /> */}
            </div>
            <div className="col-md-6 mt-1">
              <label className="label-form-filtro-usuario">Senha</label>
              <input
                name={'password'}
                type="password"
                style={inputStyle}
                onChange={handleChange}
                //onFocus={limparCampoNumero}
                placeholder="Digite"
                value={dadosForm.password}
                id="docrelanoInput"
                autoComplete="off"
              //maxLength={'8'}

              />
              {errors.password && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.password}</span>}
              {/* <TextField
                        //autoFocus
                        color="secondary"
                        name={'senha'}
                        margin="dense"
                        id="senha"
                        label="Senha"
                        type="password"
                        autoComplete="off"
                        fullWidth
                        value={dadosForm.senha}
                        onChange={handleChange}
                    /> */}
            </div>
            <div className="col-md-6 mt-1">
              <label className="label-form-filtro-usuario">Permissão</label>
              <ClientSelect

                css="no-border"
                value={permissao}
                onChange={(newValue) => handleChangeSelectPer(newValue)}
                options={
                  listPermissao !== undefined && Array.isArray(listPermissao) && listPermissao.length > 0 ?
                    listPermissao.map(item => ({ value: item.id_permissao, label: item.nome })) : null}
                placeholder='Selecione'


              />
              {errors.permissao && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.permissao}</span>}
            </div>
            <div className="col-md-6">

              <div className="mb-2">
                <label style={{ fontSize: '12px', color: `${classStatusCheck}` }}>Status</label>
                <StatusSwitch
                  checked={dadosForm.status}
                  onChange={handleChange}
                  //size="small"
                  name="status"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                {dadosForm.status ? <p>Usuario Ativo</p> : <p>Usuario Inativo</p>}
              </div>


            </div>

          </div>




        </DialogContent>

        <Divider variant="middle" />

        <DialogActions >

          <Button fullWidth={true} autoFocus onClick={handleClose} className={useStylesButtonFooter.root} >
            CANCELAR
                </Button>

          <div className="linha"></div>

          <Button fullWidth={true} autoFocus onClick={handleSubmit} className={useStylesButtonFooter.root}>
            SALVAR
            </Button>

        </DialogActions>



      </Dialog>
    </div>
  );
}