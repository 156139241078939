import React, { Component } from 'react'
import defaultImg from '../design/assets/images/aviso-vermelho.svg'
class ConfirmModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: this.props.show || false,
      // reloadAcontecimentos vem de acontecimento.jsx e parlamentarAcontecimento.jsx
      reloadAcontecimentos: this.props.reloadKey
    }
  }
  componentWillReceiveProps(newProps) {
    if (newProps.show) this.setState({ show: newProps.show })
  }

  close(save) {
    this.setState({ show: !this.state.show }, () => this.props.onClose(this.state.show, save, this.props.item))
    if (this.state.reloadAcontecimentos === true) {
      window.location.reload()
    }
  }

  render() {
    return (
      this.state.show ? this.content() : null
    )
  }
  content() {
    return (
      <div className="background-confirm-modal" onClick={() => this.close()}>
        <div className="confirm-modal">
          <div className="modal-header text-center">
            <div className="image w-100">
              <img src={this.props.imgUrl || defaultImg} alt="" />
            </div>
          </div>
          <div className="modal-body">
            <p className="Client-titulo-card">{this.props.text}</p>
            <p className="w-75 mt-0 mb-0 mr-auto ml-auto Client-cinza-claro text-center">{this.props.description}</p>
          </div>
          <div className="modal-footer">
            <div className="modal-footer-item">
              <button type='button' onClick={() => this.close()}>{this.props.cancelButtonText || 'CANCELAR'}</button>
            </div>
            <div className="modal-footer-item">
              <button type='button' onClick={() => this.close(true)}>{this.props.confirmButtonText || 'EXCLUIR'}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmModal