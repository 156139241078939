import React, { Component } from 'react'
// import { post } from '../../services/http'
// import Pagination from "react-js-pagination";
import Loading from '../../componentes/loading'
import { get } from '../../services/http'
// import { NavLink } from 'react-router-dom'
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

class ParlamentarComissoes extends Component {
    state = { 
        loading: true,
        parlamentar: {}, 
        comissoes: [], 
        paginaAtual: 1,
        limite: 50,
        quantidade: 100,
    }

    async componentWillMount() {
        await Promise.all([
            this.getParlamentarDados(),
            this.getParlamentarComissoes()
        ]).then(x => this.setState({ loading: false }))
    }

    async getParlamentarDados() {
        // console.log('entrei aqui getParlamentarDados')
    }

    async getParlamentarComissoes() {
        // console.log(this.props.dados.id)
        await get(`/parlamentar/${this.props.dados.id}/comissoes`)
        .then(response => {
            // console.log(response)
            this.setState({ comissoes: response })
        })
    }


    render() {
        return (
            this.state.loading ? <Loading /> : 
            <div>

                 <div className="row p-2 background-parlamentar-card">
                    {this.state.comissoes !== undefined 
                    && Array.isArray(this.state.comissoes) && this.state.comissoes.length > 0 ?
                     this.state.comissoes.map((item, index) => {
                        // console.log(item)
                        // const proposicaoId = item.id_proposicao
                        return (
                            <div className="col-12 p-3" key={index}>
                                <div className="parlamentar-card-comissoes">

                                    <div className="parlamentar-card-comissoes-descricao p-3">
                                        {item.sigla}
                                    </div>

                                    <div className="row p-3">

                                        <div className="col-sm-4">
                                            <div className="parlamentar-card-comissoes-titulo">
                                                Entrada
                                            </div>
                                            <div>
                                                {item.data_entrada !== null ? moment(new Date(item.data_entrada)).format('DD/MM/YYYY') : '-'}
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="parlamentar-card-comissoes-titulo">
                                                Saída
                                            </div>
                                            <div>
                                            {item.data_saida !== null && item.data_saida !== undefined ? moment(new Date(item.data_saida)).format('DD/MM/YYYY') : '-'}
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="parlamentar-card-comissoes-titulo">
                                                Status
                                            </div>
                                            <div>
                                                {item.participacao}
                                            </div>
                                        </div>

                                        <div className="col-12 mt-3">
                                            <div className="parlamentar-card-comissoes-titulo">
                                                Comissão
                                            </div>
                                            <div>
                                                {item.nome}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    }) : null }
                </div>

                {/* <div className="pagination"> Exibindo resultados {(this.state.paginaAtual - 1) * this.state.limite} - {this.state.paginaAtual * this.state.limite} de um total de {this.state.quantidade}</div>
                  <Pagination
                    activePage={this.state.paginaAtual}
                    itemsCountPerPage={this.state.limite}
                    totalItemsCount={this.state.quantidade}
                    pageRangeDisplayed={10}
                    hideFirstLastPages={true}
                    onChange={this.carregarPaginaNova}
                    prevPageText={<div className="prev-page"><img src={require('../../design/assets/images/prev-page-pagination.svg')} alt="prev-page"/></div>}
                    nextPageText={<div className="next-page"><img src={require('../../design/assets/images/next-page-pagination.svg')} alt="next-page"/></div>}
                  />  */}
                
            </div>
        )
    }
}

export default ParlamentarComissoes;