import React, { Component } from 'react'
import EsforcoColaborativo from '../form/esforcoColaborativo'
import { get, put } from '../../../services/http'
import moment from 'moment-timezone';
import Comentarios from '../../../componentes/comentarios'
// import { NavLink } from 'react-router-dom'
// import ReactTooltip from 'react-tooltip'
import Accordion from '../../../componentes/accordion'
import authStore from "../../../stores/authStore"
import { inject, observer } from 'mobx-react'
// import alertaStore from "../../../stores/alertaStore"
import Loading from '../../../componentes/loading';
import ConfirmModal from '../../../componentes/confirmModal';
import ModalAcaoPreparatoria from '../modal/modalAcaoPreparatoria'
// import { async } from 'q'
import Anexos from '../components/anexos'

moment.tz.setDefault('UTC');

@inject('alertaStore', 'uploadStore', 'authStore')
@observer

class AcaoPreparatoria extends Component {
    state = {
        acao: {},
        loading: true,
        showModal: false,
        idAcao: [],
        acoesPreparatorias: [],
        idUsuarioLogado: 0,
        mensagensEsforco: '',
        usuarioDepartamento: []
      }

    componentDidMount() {
        this.getAcao()
        const user = JSON.parse(localStorage.getItem('user'));
        this.setState({ idUsuarioLogado: user.id })
        this.getUsuarioDepartamento()
    }

     async getUsuarioDepartamento() {
        // this.setState({ loading: true })
        await get(`/acao-preparatoria/usuario-departamento`)
        .then(async res  =>  {
            const acoes = res || []  
            this.setState({ usuarioDepartamento: acoes })
        })
        .catch(err => err)
    }

    async getAcao() {
        this.setState({ loading: true })
        await get(`/acao-preparatoria/all/${this.props.idProposicaoCasaExterna}`)
        .then(async res  =>  {
          
            const acoes = res || []  
            //console.log('AÇÕES >>>>>>',acoes)
            this.setState({ acoesPreparatorias: acoes, loading: false })
        })
        .catch(err => err) 
    }

    async notaTecnicaEmAberto(timeoutMensagem, idAcao, index)  {
            this.setState({ fecharAccordion: timeoutMensagem, indice: index})
            if(timeoutMensagem === true && idAcao) {
                await get(`/acao-preparatoria/esforco-aberto/${idAcao}`)
                    .then(res =>  this.setState({ mensagensEsforco: res }))
                    .catch(err => err) 
            }
        
    }

    async excluir(showModal, save, data) {
        this.setState({ showModal })
        if (save) {
            this.setState({ loading: !this.state.loading })
            await put(`/acao-preparatoria/${data.id}`)
                .then(res => {
                    this.props.alertaStore.criar('Ação preparatória excluída com sucesso.')
                    this.setState({ loading: !this.state.loading })
                    this.getAcao()
                })
                .catch (err => err)
        }
    }

    
    changeShowModalState(showModal, itemToDelete) {
        const idAcao = itemToDelete.acao
        this.setState({ showModal, idAcao })
    }

    obterResponsavel(id) {  
        // console.log("usuarios", this.props.usuarios, "id :", id)
        return this.props.usuarios.find(x => x.id === id).nome || ''
    }


    render() {

        //console.log('>>>>>',this.props)
        return (
            this.state.loading ? <Loading /> :
            <div className="bg-white p-md-3 mb-3 fs-14">
                <header className=''>
                    {this.props.authStore.ehEsforcoColaborativo === true &&  this.state.acoesPreparatorias.length === 0  ?
                         <div className="container-fluid text-center bg-white p-4 mb-4">
                         <h4 className="mb-0 Client-titulo">Ainda não existe ação preparatória</h4>
                     </div> :
                     null
                   }
                    {this.props.authStore.ehVisualizador === false && this.props.authStore.ehEsforcoColaborativo === false ?
                        <div className={``}>
                            <ModalAcaoPreparatoria 
                                modal={this.state.acoesPreparatorias.length}
                                usuarios={this.props.usuarios}
                                usuarioDepartamento={this.state.usuarioDepartamento}
                                acoes={this.state.acoesPreparatorias}
                                interiorTeor={this.props.interiorTeor}
                                idProposicaoCasaExterna={this.props.idProposicaoCasaExterna}
                                idNovo = {this.props.idProposicao}
                                descricao={this.props.descricao}
                                button={false}
                                create={true}
                                onClick={() =>   this.getAcao()}
                                usuarioLogado={this.state.idUsuarioLogado}
                                mensagens={() => this.props.alertaStore.criar('Ação preparatória criado com sucesso.')}
                            />
                         </div>
                        : null
                     } 
                </header>

                <ConfirmModal
                    text={'Tem certeza que deseja excluir uma ação de preparatória?'}
                    description={'Esta ação não poderá ser desfeita no futuro'}
                    onClose={(show, save, item) => this.excluir(show, save, item)}
                    show={this.state.showModal}
                    item={this.state.idAcao} 
                />

                {this.state.acoesPreparatorias.length > 0  ?  
                    this.state.acoesPreparatorias.map((acao, index) => {
                        const responsaveisEsforco = JSON.parse(acao.responsaveis_esforco) || '[]'
                        return (

                            <section className='acao border mb-2 p-4' key={index} >
                                <div className="row " >
                                   {/* { this.state.indice === index ? */}
                                    <Accordion
                                         bottom={'p-2'}
                                         id={`acordion-acao-preparatoria-${index}`}
                                         subtitle={`criado em ${moment(acao.criado_em).format('DD/MM/YYYY')}`}
                                         url={`/proposicao/${this.props.idProposicaoCasaExterna}/acao-preparatoria/${acao.id}`} size={'h5'}
                                         titulo={'Ação Preparatória'}  enableIcons={authStore.ehAdministrador || authStore.podeEditar}
                                         dados={this.state.acoesPreparatorias}
                                         alerta={() => this.notaTecnicaEmAberto(true, acao.id, index)}  
                                         aberto={''}
                                         idAcao={acao.id}
                                         index={index}
                                         indexAtual={this.state.indice}
                                    >
                                    <div className="accordion-body">
                                        {this.props.authStore.ehVisualizador === false && this.props.authStore.ehEsforcoColaborativo === false ?
                                            <div className="d-flex justify-content-end">
                                                <ModalAcaoPreparatoria
                                                    text={true}
                                                    create={false}
                                                    prazo={acao.prazo}
                                                    descricaoAtualizar={acao.descricao}
                                                    usuarioDepartamento={this.state.usuarioDepartamento}
                                                    idResponsavel={acao.id_responsavel}
                                                    idAcaoPrepatoria={acao.id}
                                                    responsaveisEsforco={responsaveisEsforco}
                                                    usuarios={this.props.usuarios}
                                                    idProposicaoCasaExterna={this.props.idProposicaoCasaExterna}
                                                    onClick={() => this.getAcao()}
                                                    interiorTeor={this.props.interiorTeor}
                                                    button={true}
                                                    mensagens={() => this.props.alertaStore.criar('Ação preparatória alterada com sucesso.')}

                                                />
                                                <div className="col-1 text-center">
                                                    <span id="proposicao-remover-acao-influencia" className={'cursor-pointer'} onClick={() => this.changeShowModalState(true, this.itemToDelete = { acao })}>
                                                    <img src={require('../../../design/assets/images/trash-gray.svg')} alt="" />
                                                    </span>
                                                </div>
                                                
                                            </div>
                                        : null }
                                        {this.state.mensagensEsforco === 'prioridade-posicionamento-nao-preenchidos' ? 
                                            <div className="p-3 rounded-pill m-3 d-flex justify-align-center" style={{ backgroundColor: '#DC2D41' }}>
                                                <img className="mr-4" src={require('../../../design/assets/images/alerta-acao-preparatoria.svg')} alt="" />
                                                <div className="text-light text-center fs-16"><b>Há pendências a serem enviados.</b><span className="fs-12"> Prazo: {moment(acao.prazo).format('DD/MM/YYYY')}</span> </div>
                                            </div>
                                        : null}
                                        <div className="row">
                                            <div className="col-12 col-sm-5">
                                                <div className="text-navy font-weight-bold">Responsável</div>
                                                <div>{this.obterResponsavel(acao.id_responsavel)}</div>
                                            </div>
                                            <div className="col-12 col-sm-5">
                                                <div className="text-navy font-weight-bold">Prazo </div>
                                                <div>{moment(acao.prazo).format('DD/MM/YYYY [às] HH:mm') || 'Não identificado'}</div>
                                            </div>
                                            </div>

                                            <div className="row mt-4 mb-4 text-wrap text-truncate">
                                            <div className="col-12 text-wrap text-truncate">
                                                <span>{acao.descricao} </span>
                                            </div>
                                            </div>
                                        </div>
                                        < Anexos 
                                            idParametro={acao.id}
                                            idProposicaoCasaExterna={acao.id}
                                            create={false}
                                            modulo={'acao-preparatoria'}
                                        
                                        />
                                        <hr className='mb-2 mt-2' />
                                        <h5 className="mt-4 mb-4 text-uppercase Client-paragrafo">Esforço Colaborativo</h5>

                                        <EsforcoColaborativo idNovo={this.props.idProposicao} id={this.props.idProposicaoCasaExterna} usuarios={this.props.usuarios} acaoPreparatoria={acao} onEdit={async (event) => this.getAcao()}  onClick={() =>   this.getAcao()} />
                                        <hr />
                                        <h5 className="mt-4 mb-2 Client-titulo-agrupador">Registros</h5>
                                        <Comentarios usuarios={this.props.usuarios} contexto={`proposicao-${acao.id}-acao-preparatoria`} />
                                    </Accordion>
                                   
                                </div> 
                            </section>
                        )
                    })
                : 
                    null
                }
           </div>
        )    

    }
}

export default AcaoPreparatoria