import React, { Component } from 'react';
// import Select from 'react-select';
import Select from 'react-select';

const selectStyle = {
  control: styles => ({
    ...styles,
    boxShadow: "none",
    backgroundColor: '#3E4049',
    border: 0,
    borderRadius: 0,
    borderBottom: "1px solid #C4C4C4",
    color: 'white',
    "&:hover": {
      border: 0,
      borderRadius: 0,
      borderBottom: "1px solid #C4C4C4",
      cursor: "pointer"
    },
    "&:active": {
      border: 0,
      borderRadius: 0,
      borderBottom: "1px solid #C4C4C4"
    }
  }),
  option: styles => ({ color: '#3E4049', marginBottom: '5px', marginLeft: '5px' }),
  input: styles => ({ color: 'white' }),
  placeholder: styles => ({ color: '#C4C4C4' }),
  singleValue: styles => ({ color: 'white' }),
  indicatorSeparator: styles => ({ display: 'none' }),
  dropdownIndicator: styles => ({ color: "#dc2d41" })
};

class ClientCheckboxWithSelect extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);

    this.state = {
      value: this.props.value || 0
    }
  }

  onClick = value => this.setState({ value: value ? 1 : 0 }, () => this.props.onClick(this.state.value))

  render() {
    return (
      <Select
        value={this.props.value}
        onChange={this.props.onChange}
        options={this.props.options}
        styles={selectStyle}
        placeholder={this.props.placeholder || ""}
        isDisabled={this.props.disabled || false}
      />
    )
  }
}
export default ClientCheckboxWithSelect