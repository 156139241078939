// import { useForm } from "react-hook-form"
// import ModalAviso from './modalAvisoCadastroProposicao'
import React, { useState } from "react"
import { get, post } from "../../services/http";
import ClientSelect from '../../componentes/forms/basic/clientSelectModalCadUser'
import { useEffect } from 'react';
import alertaStore from '../../stores/alertaStore'
import LoadingCircle from '../../componentes/loadingCircle'
import ConfirmModal from '../../componentes/confirmModal';






const inputStyle = {
    border: 'none',
    backgroundColor: 'transparent',
    borderBottom: '1px solid #C4C4C4',
    color: '#000000',
    marginTop: '5px',
    width: '100%',
    placeholder: 'red',
    outline: 'none'
};



const cadastroProposicao = (props) => {

    const [uf, setUf] = useState('')
    const [estadoLista, setEstadoLista] = useState([])
    const [casaSelecionada, setCasaSelecionada] = useState(["uioiu", "lkjhlk"])
    const [listaCasas, setListaCasas] = useState([])
    const [loadingCircle, setLoadingCircle] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [dataForm, setDataForm] = useState({
        //apelido:"",
        dataInicio: '',
        horaInicio: '',
        local: "",
        descricao: "",
        pauta: ""

    })
    const [valorProp, setValorProp] = useState('')
    const [listaProposicoes, setListaProposicoes] = useState([])
    const [propSelecionada, setPropSelecionada] = useState([])

    //armazena os erros dos campos em um objeto
    const [errors, setErrors] = useState({})
    //Faço a validação do Form, se existir erro >> true, se nao false, seto inicialmente com true para nao chamar a função dentro do UseEffect
    const [validaErrors, setValidaErrors] = useState(true)




    useEffect(() => {
        obterEstados()
        //Se não existir erros, e validaErrors false
        //coloco a consta validaErrors, para ficar verifcando se ouve atualização nela.
        //console.log(Object.keys(errorsLink).length,Object.keys(errors).length,validaErrors,validaErrorsLinksLinkExterno,validaErrorsLinksInteiroTeor)
        if (Object.keys(errors).length === 0 && validaErrors === false) {

            onSubmit()
        }
    }, [validaErrors]);




    const handleSubmit = (actions) => {
        setErrors(validate())
        // console.log(validate())   
        //retorna um objeto de errors
        //exemplo {departamento: "Erro" , nome: "Erro"}

    }

    async function onChangeObterCasasByEstado(obj) {
        // console.log(obj)
        setUf(obj)
        // setCasaSelecionada([])
        await get(`/proposicao/casas-estado/${obj.value}`)
            .then(response => {
                setListaCasas(response)
            })
            .catch(e => {
                console.log(e)
            })
    }

    function handleChangeSelectCasa(value) {
        //  console.log("casa selecionada:>>>>>>>>>>>", value)
        setCasaSelecionada(value)
        setDataForm({
            ...dataForm,
            casa: value.id
                // sigla: value.sigla 
            
        })

    }

    const obterEstados = async () => {
        await get(`/proposicao/estados`)
            .then(res => {
                setEstadoLista(res)

            })
            .catch(e => {
                console.log(e)
            })
    }


    function validate(event) {
        obterEstados()


        let messageError = '*Campo Obrigatorio'
        let errors = {}

        // if(!dataForm.apelido){
        //     errors.apelido = messageError
        // }
        if (!dataForm.dataInicio) {
            errors.dataInicio = messageError
        }
        if (!dataForm.horaInicio) {
            errors.horaInicio = messageError
        }
        if (!dataForm.local) {
            errors.local = messageError
        }
        if (!dataForm.descricao) {
            errors.descricao = messageError
        }
        if (!dataForm.pauta) {
            errors.pauta = messageError
        }
        if (!dataForm.casa) {
            errors.casa = messageError
        }


        //atualizo a const, se existir erros, true, se não false
        //Object.keys(errors).length verifica se existe conteudo dentro do objeto
        Object.keys(errors).length === 0 ? setValidaErrors(false) : setValidaErrors(true)

        return errors
    }


    const onSubmit = async () => {
        // console.log("dataForm", dataForm)

        await post("/agenda/cadastro", dataForm)
            // console.log(dataForm)
            .then(response => {
                // console.log("response: ", response)

                if (response !== undefined) {
                    setLoadingCircle(true)
                    alertaStore.criar("Evento cadastrado com sucesso!")
                    setTimeout(() => window.location.href = '/agenda', 2000)
                } else {
                    alertaStore.erro('Erro ao criar Evento')
                    setDataForm({})
                    setLoadingCircle(false)
                }
            })
            .catch(err => err)


    }


    const obterProposicoes = async (propEscrita) => {

        setPropSelecionada([])
        setValorProp(propEscrita)
        await get(`agenda/proposicoes/?tipo=${propEscrita}`)
            .then(res => {
                // console.log("propEscrita", res)
                setListaProposicoes(res)

            })
            .catch(e => {
                console.log(e)
            })
    }


    function handleChangeSelectProp(value) {
// console.log("value ==========", value)
        setPropSelecionada(value)
        setDataForm({
            ...dataForm,
            prop_selecionada: { id: value.value, descricao: value.label, casa: value.casa, id_casa: value.id_casa }
        })

    }


    const handleChange = (event) => {

        setDataForm({
            ...dataForm,
            [event.target.name]: event.target.value
        })
    }

    function changeShowModalState(showModal) {
        setShowModal(showModal)
    }

    function cancelarEvento(showModal, save, id) {
        setShowModal(showModal)

        if (save) {
            setTimeout(() => window.location.href = '/agenda', 100)
        }


    }

// console.log( ">>>>>>>>>>>>.", listaProposicoes)
    return (
        
        <>
            <ConfirmModal
                text={'Cancelar cadastro de evento?'}
                description={'Esta ação não poderá ser desfeita no futuro'}
                onClose={(show, save, item) => cancelarEvento(show, save, item)}
                show={showModal}
                cancelButtonText={'Voltar'}
                confirmButtonText={'Cancelar'}
            />


            <div className="col-12 bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                    AGENDA
                    </p>
            </div>

            <div className="ml-2 mr-2 mt-2 mb-2">
                {

                    loadingCircle

                        ?
                        <div className=" " style={{ marginTop: "100px", height: "470px", alignItems: "center", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                            <div className="row text-center">
                                <div className="col-12 text-center">
                                    <h4>Aguardando a validação...</h4>
                                </div>
                                <div className="col-12 text-center">
                                    <LoadingCircle color={'#DC2D41'} size={'30px'} />
                                </div>
                            </div>
                        </div>
                        :

                        <div className="container-fluid">
                            <h3 className="mb-2 topSpaceCadastroProp">Novo Evento</h3>

                            <div className="bg-white marginCadastroProp">
                                <form>

                                    <div style={{ backgroundColor: "#f2f2f2", padding: "10px", borderRadius: "3px" }} >

                                        <div className="row flex-column">
                                            <label className="m-2" style={{ color: "var(--linkText)" }} >Relacionar Evento a Proposição</label>
                                            <div className="input-group col-12 col-sm-12  col-lg-12 col-xl-12">

                                                <input className="form-control p-4 border-right-0 border" type="search" autoComplete="off"
                                                    value={valorProp}
                                                    onKeyUp={(event) => { if (event.which === 13) { obterProposicoes(event.target.value.toUpperCase()) } }}
                                                    // onKeyPress={(event) => this.clickEnter(event)}
                                                    onChange={(event) => obterProposicoes(event.target.value.toUpperCase())}
                                                    placeholder="Buscar proposição" aria-label="Pesquisar"
                                                    aria-describedby="search"
                                                    style={{ fontSize: '16px' }}


                                                />

                                                <span id="icone-pesquisar-proposicao" className="input-group-append">
                                                    <button
                                                        className="btn  border-left-0 border bg-white"
                                                        // type="button"
                                                        //onClick={(event) => obterProposicoes(event.target.value.toUpperCase())}
                                                        style={{}}
                                                    >
                                                        <i className="icon-search-small" style={{ fontSize: '20px', color: '#BBB' }}></i>
                                                    </button>
                                                </span>
                                            </div>

                                        </div>



                                        <div className="row mt-2">
                                            {
                                                listaProposicoes.length > 0
                                                    ?
                                                    <div className="col-sm-12  col-lg-12 col-xl-12 ">
                                                        <div>
                                                            <label style={{ color: "var(--linkText)" }}>Selecionar Proposição</label>
                                                            <ClientSelect
                                                                css="no-border"
                                                                name="casa"
                                                                value={propSelecionada}
                                                                options={
                                                                    listaProposicoes !== undefined && Array.isArray(listaProposicoes) && listaProposicoes.length > 0 ? 
                                                                        listaProposicoes.map((item) => ({ value: item.id, label: item.descricao + ' ' + item.apelido  + ' - ' + item.casa, casa: item.casa, id_casa: item.numero_casa })) : null}
                                                                onChange={(newValue) => handleChangeSelectProp(newValue)}
                                                                placeholder={"Selecionar"}
                                                            />
                                                            {errors.casa && errors.casa.message}
                                                        </div>
                                                    </div>
                                                    :

                                                    valorProp !== ""
                                                        ?
                                                        <div className="col-sm-12  col-lg-12 col-xl-12 p-3 d-flex flex-column text-center">
                                                            <div className="col-12 text-center">
                                                                <LoadingCircle color={'#DC2D41'} size={'30px'} />
                                                            </div>
                                                            <div className="col-12 text-center fontsCitacoes">
                                                                Continue escrevendo, estamos buscando!
                                                                  </div>
                                                        </div>
                                                        :
                                                        <div className='col-sm-12  col-lg-12 col-xl-12 p-3 d-flex flex-column text-center fontsCitacoes' style={{ fontSize: '16px' }}>
                                                            <span>Será exibido aqui o resultado da busca</span>
                                                            <span style={{ color: "var(--linkText)", fontSize: "12px" }}>Busque pela proposição desejada (Ex: PL81/2020)</span>
                                                        </div>

                                            }
                                        </div>

                                    </div>



                                    {/* <div className="row" >
                                                       <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                                            <label style={{color:"var(--linkText)", placeholder:"color red"}}>Apelido</label>
                                                            <input
                                                                value={dataForm.apelido}  
                                                                onChange={handleChange}
                                                                name={'apelido'}
                                                                style={inputStyle}
                                                                type="text"
                                                                placeholder="Ex: Apelido "
                                                                autoComplete="off"
                                                             
                                                                
                                                            />
                                                            
                                                            {errors.apelido && <span style={{color:'#DC2D41', fontSize:'12px', fontWeight:'bold'}}>{errors.apelido}</span>}
                                                        </div>
                                                 </div> */}
                                    <div className="row mt-4" >
                                        <div className="col-md-6" >
                                            <label style={{ color: "var(--linkText)" }}>Unidades da Federação</label>
                                            <ClientSelect
                                                css="no-border"
                                                name="estado"
                                                value={uf}
                                                options={
                                                    estadoLista !== undefined && Array.isArray(estadoLista) && estadoLista.length > 0 ?
                                                        estadoLista.map((item) => ({
                                                            value: item.id,
                                                            label: item.sigla
                                                        })) : null}
                                                onChange={(uf) => onChangeObterCasasByEstado(uf)}
                                                placeholder={"Selecionar"}
                                            />
                                            {/* {errors.estado && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.estado}</span>} */}
                                        </div>


                                        <div className="col-md-6 MarginTopCampoCadastroProp">
                                            <label style={{ color: "var(--linkText)" }}>Casa Legislativa</label>

                                            <ClientSelect
                                                css="no-border"
                                                name="casa"
                                                value={casaSelecionada}
                                                options={
                                                    listaCasas !== undefined && Array.isArray(listaCasas) && listaCasas.length > 0 ?
                                                        listaCasas.map((item) => ({ value: item.id, label: item.sigla + ' - ' + item.nome, sigla: item.sigla, id: item.id })) : null}
                                                onChange={(newValue) => handleChangeSelectCasa(newValue)}
                                                placeholder={"Selecionar"}
                                            />
                                            {errors.casa && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.casa}</span>}


                                        </div>

                                        <div className="col-12 col-sm-6  col-lg-6 col-xl-6 mt-3 datetime-agenda">
                                            <div style={{ color: "var(--linkText)", placeholder: "color red" }}>Data Inicial</div>
                                            <input
                                                id="data-inicial"
                                                name="dataInicio"
                                                className="p-1"
                                                type="date"
                                                onChange={handleChange}
                                                value={dataForm.dataInicio}
                                            />
                                            {errors.dataInicio && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.dataInicio}</span>}
                                        </div>




                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                                            <label style={{ color: "var(--linkText)" }}>Horário de Início</label>
                                            <input

                                                value={dataForm.horaInicio}
                                                onChange={handleChange}
                                                id={'horaInicio'}
                                                name={'horaInicio'}
                                                style={inputStyle}
                                                type="time"


                                            />

                                            {errors.horaInicio && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.horaInicio}</span>}
                                        </div>
                                    </div>

                                    <div className="row" >
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                            <label style={{ color: "var(--linkText)", placeholder: "color red" }}>Local</label>
                                            <input
                                                value={dataForm.local}
                                                onChange={handleChange}
                                                name={'local'}
                                                style={inputStyle}
                                                type="text"
                                                placeholder="Endereço do Evento"
                                                autoComplete="off"


                                            />

                                            {errors.local && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.local}</span>}
                                        </div>
                                        
                                    </div>
                                    <div className="row" >
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                            <label style={{ color: "var(--linkText)", placeholder: "color red" }}>Descrição</label>
                                            <input
                                                value={dataForm.descricao}
                                                onChange={handleChange}
                                                name={'descricao'}
                                                style={inputStyle}
                                                type="text"
                                                placeholder="Digite Aqui"
                                                autoComplete="off"


                                            />

                                            {errors.descricao && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.descricao}</span>}
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                            <label style={{ color: "var(--linkText)", placeholder: "color red" }}>Pauta</label>
                                            <input
                                                value={dataForm.pauta}
                                                onChange={handleChange}
                                                name={'pauta'}
                                                style={inputStyle}
                                                type="text"
                                                placeholder="Digite Aqui"
                                                autoComplete="off"

                                            />

                                            {errors.pauta && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.pauta}</span>}
                                        </div>
                                    </div>



                                    <div className="row">
                                        <div className="col-md-6">
                                            <button type="button" className=" mt-4 btn bg-white btn-block text-button p-3" onClick={() => changeShowModalState(true)} style={{ border: '1px solid #1E0736' }}>
                                                Cancelar Evento
                                                              </button>
                                        </div>

                                        {}

                                        <div className="col-md-6">
                                            <button type="button" onClick={() => handleSubmit()} className="btn mt-4" style={{ width: "100%", padding: "16px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter" }} variant="outlined" >
                                                <img
                                                    className="mr-2"
                                                    style={{ width: "20px", marginTop: "-3px" }}
                                                    src={require('../../design/assets/images/diskSave.svg')}
                                                    alt="btn-pesquisar"
                                                />{' '}
                                                                            Salvar Evento
                                                                </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                }
            </div>
        </>




    )
}
export default cadastroProposicao 