const resultados = {
    PENDENTE: 0,
    EXITO: 1,
    SEM_EXITO: 2,
    EXITO_PARCIAL: 3,

    0: 'Pendente',
    1: 'Êxito',
    2: 'Sem êxito',
    3: 'Êxito parcial',
}

export default resultados
export const itens = [0, 1, 2, 3]
export const resultado = [
    { id: 0, nome: 'Pendente', spanclass: "acao-blue" },
    { id: 1, nome: 'Êxito', spanclass: "acao-green", outline: "success" },
    { id: 2, nome: 'Sem êxito', spanclass: "acao-red", outline: "danger" },
    { id: 3, nome: 'Êxito parcial', spanclass: "acao-yellow", outline: "warning" }
]


export const resultadoClient = [
    { id: 99, value: 'Pendente', icon: "aviso" },
    { id: 1, value: 'Êxito', icon: "sucesso" },
    { id: 2, value: 'Sem êxito', icon: "negativo" },
    { id: 3, value: 'Êxito parcial', icon: "pendente" }
]