import React, { useState } from 'react';
import { get } from '../../services/http'
// import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import uuid from 'uuid/v1'

// import Autocomplete from '@material-ui/lab/Autocomplete';
export default function FormDialog(props) {


    const [open, setOpen] = useState(false)
    const [arrayPalavras, setArrayPalavras] = useState([])
    const [inputPalavras, setInputPalavras] = useState('')
    const [error, setError] = useState(false)
    const [errorPalavraDuplicada, setErrorPalavraDuplicada] = useState(false)

    const themeReponsive = useTheme();
    const fullScreen = useMediaQuery(themeReponsive.breakpoints.down('sm'));


    const handleClickOpen = () => {
        setOpen(true);
        setError(false)
        setErrorPalavraDuplicada(false)
    };
    const handleClose = () => {
        setOpen(false);
        setArrayPalavras([])
    }

    const handleAdd = async (e) => {
        e.preventDefault()

        //console.log(inputPalavras)

        if (inputPalavras !== '') {
            //console.log('entrei no if')
            await get(`insights/citacoes/buscar-palavra/${inputPalavras}`)
                .then(response => {
                    switch (response) {
                        case true:
                            setErrorPalavraDuplicada(true)
                            setInputPalavras('')
                            break;
                        case false:

                            setArrayPalavras([...arrayPalavras, { id: uuid(), descricao: inputPalavras }])
                            setInputPalavras('')
                            setError(false)
                            setErrorPalavraDuplicada(false)


                            break;

                        default:
                            break;
                    }



                }).catch(err => err)

        } else {
            //console.log('entrei no else')
            //setArrayPalavras([...arrayPalavras]) 
            setInputPalavras('')
            setErrorPalavraDuplicada(false)
            setError(true)
        }

    }
    const handleDel = (id) => {

        //faço uma copia do array antigo e add em um novo array
        const newArrayPalavras = arrayPalavras.slice(0)
        //console.log('>>',newArrayPalavras)

        //Pego o index do array escolhido para exclusao da lista
        const index = newArrayPalavras.findIndex(palavra => palavra.id === id)
        //console.log('>>',index)

        //removo a palavra selecionada apartir do seu index do array
        newArrayPalavras.splice(index, 1)
        //console.log ('>>',newArrayPalavras)

        //add o novo array ao estado atual do componente
        setArrayPalavras([...newArrayPalavras])

        //console.log ('>>',arrayPalavras)


    }

    const gravarListaPalavras = async () => {
        if (arrayPalavras.length > 0) {
            props.callbackParent(true, arrayPalavras)
            setOpen(false);
        } else {
            setError(true)
        }
    }


    const inputStyle = {
        border: 'none',
        backgroundColor: 'transparent',
        borderBottom: '1px solid #C4C4C4',
        color: '#000000',
        marginTop: '10px',
        width: '100%',
        placeholder: 'red',
        outline: 'none'
    };

    const useStylesButtonFooter = makeStyles({
        root: {
            color: '#3E4049',
            backgroundColor: 'White',
            '&:hover': {
                backgroundColor: '#FFF',
            },
        },

    })(Button);



    const tamanhoTela = window.innerWidth

    return (

        <div>
            <button className='btn btn-block btn-config-citacoes' variant="outlined" onClick={handleClickOpen}>
                <img
                    className="mr-1 "
                    width='20px'
                    src={require('../../design/assets/images/config.png')}
                    alt="anexo"
                    id='img-config'
                    style={{ backgroundImage: '#FFF' }}

                />{' '}
                {/* <i className='icon-icon_settings_24px' style={{width:'60px', marginRight:'7px', marginTop:'6px' }}></i> */}
                Configurações
            </button>




            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} maxWidth={'md'}>
                <DialogTitle >
                    <div className='fontsCitacoes' style={{ fontSize: '23px' }}> <p>Configurações</p> </div>
                    <div className='mt-3 fontsCitacoes' style={{ fontSize: '18px' }}> <p>Adicionar palavra-chave para busca por citação</p> </div>
                </DialogTitle>

                <DialogContent
                    style={{
                        width: tamanhoTela > 700 ? 750 : tamanhoTela,
                        //backgroundColor: "#3E4049",
                        //color: 'white'

                    }}
                    overflow="hidden"
                >

                    <div className="row">
                        <div className="col-12 col-sm-12  col-lg-12 col-xl-12 ">
                            <label className='fontsCitacoes' style={{ fontSize: '12px' }}>Palavra-chave</label>
                            <input
                                style={inputStyle}
                                onChange={(e) => setInputPalavras(e.target.value)}
                                onKeyUp={(e) => { if (e.which === 13) { handleAdd(e) } }}
                                //onFocus={limparCampoNumero}
                                placeholder="Digite uma palavra, termo, frase..."
                                value={inputPalavras}
                                id="palavra-chave"
                                autoComplete="off"
                            //maxlength="20"
                            />
                        </div>
                        <div className='col-12 col-sm-12  col-lg-12 col-xl-12 mt-3 '>
                            <span className="input-group-append">
                                <button className='btn btn-block btn-config-citacoes'
                                    onClick={handleAdd}
                                >
                                    ADICIONAR
                                        </button>
                            </span>
                        </div>
                    </div>


                    <div className='mt-2 overflow-auto listaPalavras '>


                        {
                            errorPalavraDuplicada ? <div className='mt-3 text-center' style={{ fontSize: '16px', color: 'red', fontWeight: 'bold' }}><p>Palavra já existente no banco de dados!</p></div> :
                                error ? <div className='mt-3 text-center' style={{ fontSize: '16px', color: 'red', fontWeight: 'bold' }}><p>Necessário adicionar uma palavra!</p></div> :
                                    arrayPalavras.length > 0 ?
                                        <>
                                            <div className='fontsCitacoes sticky-top titulo-listaPalvras' style={{ fontSize: '18px' }}><p>Suas palavras-chave</p></div>

                                            {
                                                arrayPalavras.map((palavras, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className='mb-3 p-2  d-flex justify-content-between' style={{ border: '1px solid #E1E8F2', borderRadius: '6px' }}>
                                                                <span className='fontsCitacoes' style={{ fontSize: '14px' }} >{palavras.descricao}</span>
                                                                <i className='icon-lixeira pr-2 cursor-pointer ' style={{ color: '#9B9B9B' }} onClick={() => handleDel(palavras.id)}></i>
                                                            </div>
                                                        </div>

                                                    )
                                                })
                                            }

                                        </>
                                        :

                                        <div className='m-3 d-flex flex-column text-center fontsCitacoes' style={{ fontSize: '16px' }}>
                                            <span>As palavras-chave aparecerão aqui.</span>
                                            <span>Você pode adicionar ou excluir a qualquer momento.</span>
                                        </div>
                        }




                    </div>





                </DialogContent>

                <Divider variant="middle" />
                {/* <div className='row'>
                                <div className='col-md-6 col-6' style={{ borderTop: '1px solid #E3E3E3', paddingTop: '10px', paddingBottom: '10px' }}>
                                    <div onClick={handleClose} className='text-center' style={{ marginTop: '10px', cursor: 'pointer' }}>
                                        CANCELAR
                                    </div>
                                </div>

                                <div className='col-md-6 col-6' style={{ borderTop: '1px solid #E3E3E3', borderLeft: '1px solid #E3E3E3', paddingTop: '10px', paddingBottom: '10px' }}>
                                    <div onClick={saveListPalavras} className='text-center' style={{ marginTop: '10px', cursor: 'pointer' }}>
                                        SALVAR
                            </div>
                                </div>
                            </div>           */}
                <DialogActions >

                    <Button fullWidth={true} autoFocus onClick={handleClose} className={useStylesButtonFooter.root} >
                        CANCELAR
                        </Button>

                    <div className="linha"></div>

                    <Button fullWidth={true} autoFocus onClick={gravarListaPalavras} className={useStylesButtonFooter.root}>
                        SALVAR
                        </Button>

                </DialogActions>

            </Dialog>

        </div>
    );
}
