export default {
    'proposicoes': 'proposições',
    'proposicao': 'proposições',
    'recomendacao': 'recomendações',
    'recomendacoes': 'recomendações',
    'acao': 'ações',
    'acoes': 'ações',
    'tramitacao': 'tramitações',
    'tramitacoes': 'tramitações',
    'parlamentar': 'parlamentares',
    'parlamentares': 'parlamentares',
}