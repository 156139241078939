import React, { Component, Fragment } from 'react'
//import { NavLink } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { posicionamentosClient } from "../../../enums/posicionamento";
// import { ClientPrioridades } from "../../../enums/prioridades";
import { buscarListaPrioridades } from "../../../../src/enums/prioridades";
import { impactoFinanceiroOptions } from "../../../enums/impactoFinanceiro";
import Loading from '../../../componentes/loading'
import { inject, observer } from 'mobx-react'
// import { withRouter } from 'react-router-dom'
import { put, get } from "../../../services/http";
import moment from 'moment-timezone';
import ClientSelect from '../../../componentes/forms/basic/clientSelect';
import ConfirmModal from '../../../componentes/confirmModal';
import CurrencyFormat from 'react-currency-format';
import ModalEsforcoColaborativo from '../modal/modalEsforcoColaborativo'
import Anexos from '../components/anexos'

moment.tz.setDefault('UTC');

// @withRouter
@inject('alertaStore', 'uploadStore', 'authStore')
@observer

class EsforcoColaborativo extends Component {
    state = {
        loading: false,
        showModal: false,
        itemToDelete: {},
        id_responsavel: 0,
        ClientPrioridades: []
    }


    async componentDidMount() {
        let esforcos = this.props.acaoPreparatoria.notas_tecnicas;
        if (esforcos && esforcos.length > 0)
            for (let i = esforcos.length - 1; i >= 0; i--) {
                //console.log('esforcos[i].anexos>>>', esforcos[i])
                esforcos[i].anexos = await this.getAnexos(esforcos[i].id)
            }
        this.setState({ esforcos })


        //chamando a lista de prioridades do banco
        buscarListaPrioridades()
            .then(res => {
                this.setState({
                    ClientPrioridades: res,
                })
            })


    }

    async excluir(showModal, save, item) {
        this.setState({ showModal })
        if (save) {
            this.setState({ loading: !this.state.loading })
            try {
                await put(`/acao-preparatoria/${item.acaoId}/nota-tecnica/${item.esforcoId}`, { id: item.esforcoId, id_acao_preparatoria: item.acaoId, arquivada: 1, id_proposicao_novo: this.props.idNovo, id_responsavel: this.state.id_responsavel })
                this.props.alertaStore.criar('Esforço colaborativo excluído com sucesso.')
                this.props.onEdit(true)
                this.setState({ loading: !this.state.loading })
            } catch (error) { console.error(error); }
        }
    }

    async getAnexos(id) {
        //console.log('id>>>>>>',id, this.props.id)
        return get(`anexo/esforco-colaborativo/${id}`);
    }
    changeShowModalState(showModal, itemToDelete, id_responsavel) {
        //console.log('id_responsavel', id_responsavel)
        this.setState({ showModal, itemToDelete, id_responsavel: id_responsavel })
    }



    render() {
        const acaoPreparatoria = this.props.acaoPreparatoria;
        const userLocal = JSON.parse(localStorage.getItem('user'))


        //console.log(this.props.acaoPreparatoria) 

        return (
            <Fragment>
                {
                    this.state.loading ? <Loading /> : <>
                        <ConfirmModal
                            text={'Tem certeza que deseja excluir o esforço colaborativo?'}
                            description={'Esta ação não poderá ser desfeita no futuro'}
                            onClose={(show, save, item) => this.excluir(show, save, item)}
                            show={this.state.showModal}
                            item={this.state.itemToDelete}
                        />
                        {
                            this.state.esforcos !== undefined && Array.isArray(this.state.esforcos) &&
                                this.state.esforcos.length > 0 ?
                                this.state.esforcos.map((item, key) => {
                                    //console.log('ITEM >>>>', this.props.usuarios.find(x => x.id === item.id_responsavel).nome)
                                    const user = JSON.parse(window.localStorage.getItem('user'))
                                    return (
                                        this.props.authStore.ehEsforcoColaborativo === true && item.id_responsavel !== user.id ? null :
                                            <div className="modal--notas-items" key={key}>
                                                <div className="d-flex justify-content-between">
                                                    <div className="modal--notas-items-title fs-13 Client-bg-cinza-header text-white">

                                                        {<span>{item.departamento} | </span>}{this.props.usuarios && this.props.usuarios.length > 0 && this.props.usuarios.find(x => x.id === item.id_responsavel) && this.props.usuarios.find(x => x.id === item.id_responsavel).nome ? this.props.usuarios.find(x => x.id === item.id_responsavel).nome : 'Responsável excluido'}
                                                    </div>
                                                    <div className="Client-detalhe">
                                                        <span>{moment(item.criado_em).format('DD/MM/YYYY [ás] HH:mm')}</span>
                                                    </div>
                                                </div>

                                                <div className="modal--notas-items-row flex-column fs-14">
                                                    {item.posicionamento ?
                                                        <div className="row ml-1 mr-1">

                                                            <div className="col-10 d-flex align-items-center" style={{ height: '25px' }}>
                                                                <span className="text-navy font-weight-bold mr-2">Tentativa de Ação de Influência: </span>
                                                                <span >{item.influencia || 'Pendente'}</span>
                                                            </div>

                                                            {this.props.authStore.ehVisualizador === true ? null :
                                                                userLocal.id !== item.id_responsavel ? null :
                                                                    <div className="col-1 text-center">
                                                                        <span id="proposicao-remover-esforco-colaboraivo" className={'cursor-pointer'} onClick={() => this.changeShowModalState(true, { acaoId: acaoPreparatoria.id, esforcoId: item.id }, item.id_responsavel)}>
                                                                            <img src={require('../../../design/assets/images/trash-gray.svg')} alt="" />
                                                                        </span>
                                                                    </div>
                                                            }

                                                            {this.props.authStore.ehVisualizador === true ? null :
                                                                userLocal.id !== item.id_responsavel ? null :

                                                                    <ModalEsforcoColaborativo
                                                                        create={false}
                                                                        idProposicaoCasaExterna={this.props.id}
                                                                        dadosAcaoPreparatoria={this.props.acaoPreparatoria}
                                                                        dadosNotasTecnicas={item}
                                                                        usuarios={this.props.usuarios}
                                                                        idNotaTecnica={item.id}
                                                                        posicionamento={item.posicionamento}
                                                                        prioridade={item.prioridade}
                                                                        tipoImpactoFinanceiro={item.tipo_impacto_financeiro}
                                                                        valorImpactoFinanceiro={item.valor_impacto_financeiro}
                                                                        justificativa={item.justificativa}
                                                                        aprovado={item.aprovado}
                                                                        onClick={() => this.props.onClick()}
                                                                        idNovo={this.props.idNovo}
                                                                    />

                                                            }

                                                            <div className="col-12 mt-4 mb-4">
                                                                <span className='text-navy font-weight-bold'>Argumentos para justificar Posição: </span>
                                                                <span className="text-wrap text-truncate">{item.justificativa}</span>
                                                            </div>

                                                            <div className="col-6">
                                                                <div className="text-navy font-weight-bold">Prioridade</div>
                                                                <ClientSelect disabled={true} items={this.state.ClientPrioridades} value={item.prioridade} />
                                                            </div>

                                                            <div className="col-6">
                                                                <div className="text-navy font-weight-bold">Posicionamento</div>
                                                                <ClientSelect items={posicionamentosClient} value={item.posicionamento} disabled={true} />
                                                            </div>
                                                            <div className="mt-2 col-6">
                                                                <div className="text-navy font-weight-bold">Impacto Financeiro</div>
                                                                <ClientSelect items={impactoFinanceiroOptions} value={item.tipo_impacto_financeiro} disabled={true} />
                                                            </div>
                                                            <div className="mt-2 col-6">
                                                                <div className="text-navy font-weight-bold mb-2">Impacto Financeiro</div>
                                                                <CurrencyFormat
                                                                    className="tirar-borda-input-Client"
                                                                    thousandSeparator={true}
                                                                    prefix={'R$'}
                                                                    value={item.valor_impacto_financeiro}
                                                                />
                                                            </div>

                                                            <div className="col-12 mt-4 mb-4">
                                                                < Anexos
                                                                    idParametro={item.id}
                                                                    idProposicaoCasaExterna={item.id}
                                                                    create={false}
                                                                    modulo={'esforco-colaborativo'}

                                                                />
                                                            </div>

                                                        </div>
                                                        :

                                                        <div className="row">
                                                            <div className="col-8">
                                                                <span className="text-navy font-weight-bold">Esforço ainda não cadastrado </span>
                                                            </div>
                                                            <div className="col-3">
                                                                {this.props.authStore.ehVisualizador === true ? null :
                                                                    userLocal.id !== item.id_responsavel ? null :

                                                                        <ModalEsforcoColaborativo
                                                                            create={true}
                                                                            idProposicaoCasaExterna={this.props.id}
                                                                            dadosAcaoPreparatoria={this.props.acaoPreparatoria}
                                                                            usuarios={this.props.usuarios}
                                                                            idNotaTecnica={item.id}
                                                                            onClick={() => this.props.onClick()}
                                                                            idNovo={this.props.idNovo}
                                                                        />

                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                    )
                                }
                                ) : null}
                    </>
                }
            </Fragment>
        )
    }
}

export default EsforcoColaborativo
