import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get } from "../../services/http";

export const FormDialog = () => {
    const [open, setOpen] = React.useState(false);
    const [criacao, setCriacao] = React.useState(false);
    const [edicao, setEdicao] = React.useState(false);
    const [exclusao, setExclusao] = React.useState(false);
    const [arrayAutocomplete] = React.useState([]);
    const [departamento, setDepartamento] = React.useState([]);

    useEffect(() => {

        buscarDepartamentos()

    }, []);

    const criarArrayAutocomplete = (valor) => {
        arrayAutocomplete.push(valor)
    }

    const meuFiltro = (arrayDados) => {
        // setDataFiltro(arrayDados)
        dadosDoFiltro(arrayDados)
    }


    const buscarDepartamentos = async () => {
        await get(`/usuarios/relatorios/departamentos`)
            .then(res => setDepartamento(res || []))
            .catch(err => err)
    }

    // const departamentosClient = [
    //     { title: 'Financeiro' },
    //     { title: 'Recursos Humanos' },
    //     { title: 'Jurídico' },
    //     { title: 'TI' },
    //     { title: 'Econômico' }
    // ];

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }

    const limparFiltro = () => {

        document.getElementById('dataFinal').value = ""
        document.getElementById('dataInicial').value = ""
        setCriacao(false)
        setEdicao(false)
        setExclusao(false)
        const auto = document.getElementsByClassName("MuiChip-deletable")

        if (auto[0] !== undefined) auto[0].innerText = ''
        if (auto[1] !== undefined) auto[1].innerText = ''
        if (auto[2] !== undefined) auto[2].innerText = ''
        if (auto[3] !== undefined) auto[3].innerText = ''
        if (auto[4] !== undefined) auto[4].innerText = ''
        if (auto[5] !== undefined) auto[5].innerText = ''
        if (auto[6] !== undefined) auto[6].innerText = ''
        if (auto[7] !== undefined) auto[7].innerText = ''
        if (auto[8] !== undefined) auto[8].innerText = ''
        if (auto[9] !== undefined) auto[9].innerText = ''
        if (auto[10] !== undefined) auto[10].innerText = ''

        window.localStorage.removeItem('filtroLogs')

    }

    const filtrar = () => {

        const dataInicial = document.getElementById('dataInicial').value
        const dataFinal = document.getElementById('dataFinal').value

        const criacaoFiltro = criacao === true ? 'I' : null
        const edicaoFiltro = edicao === true ? 'A' : null
        const exclusaoFiltro = exclusao === true ? 'E' : null

        const filtroAcoes = [criacaoFiltro, edicaoFiltro, exclusaoFiltro].filter(el => el !== null)

        var arrayDados = {
            dataInicial: dataInicial,
            dataFinal: dataFinal,
            departamento: arrayAutocomplete,
            tipo: filtroAcoes
        }

        meuFiltro(arrayDados)
        setOpen(false)
        window.location.reload()

    }

    const CheckboxCriacao = event => {
        const criacaoFiltro = event.target.checked
        setCriacao(criacaoFiltro)
    }

    const CheckboxEdicao = event => {
        const edicaoFiltro = event.target.checked
        setEdicao(edicaoFiltro)
    }

    const CheckboxExclusao = event => {
        const exclusaoFiltro = event.target.checked
        setExclusao(exclusaoFiltro)
    }

    const autocompleteValue = (event) => {
        var valor = event.target.innerText
        criarArrayAutocomplete(valor)
    }

    return (
        <div>
            <button className="btn btn-dark btn-block" variant="outlined" onClick={handleClickOpen}>
                <img
                    className="mr-3"
                    src={require('../../design/assets/images/filtar.png')}
                    alt="anexo"
                />{' '}
        Filtrar
      </button>
            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title" >

                <DialogContent
                    style={{
                        width: 500,
                        backgroundColor: "#3E4049",
                        color: 'white'
                    }}
                >

                    <Typography variant="h6" gutterBottom style={{ color: 'white', marginTop: '30px' }}>
                        Atividade
                </Typography>

                    <FormGroup row aria-label="position">
                        <Grid container item xs={12} sm={12} md={4} lg={4} xl={4}   >
                            <div className="mt-3">
                                <label className="container-new-checkbox"> Criação
                                <input type="checkbox" value="1" id="CheckboxCriacao" onChange={event => CheckboxCriacao(event)} checked={criacao} />
                                    <span className="checkmark-new-checkbox"></span>
                                </label>
                            </div>
                            {/* <FormControlLabel
                            control={<Checkbox style={{color: 'white'}} value="1" id="CheckboxCriacao" onChange={event => CheckboxCriacao(event)} checked={criacao} />}
                            label="Criação"
                        /> */}
                        </Grid>
                        <Grid container item xs={12} sm={12} md={4} lg={4} xl={4}   >
                            <div className="mt-3">
                                <label className="container-new-checkbox"> Edição
                                <input type="checkbox" value="2" id="CheckboxEdicao" onChange={event => CheckboxEdicao(event)} checked={edicao} />
                                    <span className="checkmark-new-checkbox"></span>
                                </label>
                            </div>
                            {/* <FormControlLabel
                            control={<Checkbox style={{color: 'white'}} value="2" id="CheckboxEdicao" onChange={event => CheckboxEdicao(event)} checked={edicao} />}
                            label="Edição" 
                        /> */}
                        </Grid>
                        <Grid container item xs={12} sm={12} md={4} lg={4} xl={4}   >
                            <div className="mt-3">
                                <label className="container-new-checkbox"> Exclusão
                                <input type="checkbox" value="3" id="CheckboxExclusao" onChange={event => CheckboxExclusao(event)} checked={exclusao} />
                                    <span className="checkmark-new-checkbox"></span>
                                </label>
                            </div>
                            {/* <FormControlLabel
                            control={<Checkbox style={{color: 'white'}} value="3" id="CheckboxExclusao" onChange={event => CheckboxExclusao(event)} checked={exclusao} />}
                            label="Exclusão" 
                        /> */}
                        </Grid>
                    </FormGroup>

                    <hr style={{ backgroundColor: 'black' }} />

                    <Typography variant="h6" style={{ color: 'white', marginTop: '30px' }} >
                        Período
                </Typography>

                    <div className="row">
                        <div className="col-md-6">
                            <label style={{ color: 'white' }}>De:</label>
                            <div>
                                <input type="date" id="dataInicial" style={{ color: 'white', backgroundColor: '#3E4049', border: 'none', borderBottom: '2px solid white', outline: 'none' }} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label style={{ color: 'white' }}>Para:</label>
                            <div>
                                <input type="date" id="dataFinal" style={{ color: 'white', backgroundColor: '#3E4049', border: 'none', borderBottom: '2px solid white', outline: 'none' }} />
                            </div>
                        </div>
                    </div>

                    <hr style={{ backgroundColor: 'black' }} />

                    <div className="mt-5 col-8 col-sm-12">
                        <Autocomplete
                            style={{ backgroundColor: 'white', padding: '10px' }}
                            id="departamento"
                            multiple
                            options={departamento}
                            getOptionLabel={option => option.title}
                            defaultValue={[]}
                            onChange={event => autocompleteValue(event)}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Buscar por departamentos"
                                    placeholder="Selecione"
                                    fullWidth
                                />
                            )}
                        />
                    </div>

                    <div className="row mt-5">
                        <div className="col-6 text-left">
                            <span onClick={limparFiltro} style={{ calor: 'white', backgroundColor: 'transparent', cursor: 'pointer' }}>Limpar Filtro</span>
                        </div>
                        <div className="col-6 text-right">
                            <button onClick={filtrar} variant="contained" className="btn btn-dark">
                                <img
                                    className="mr-3"
                                    src={require('../../design/assets/images/filtar.png')}
                                    alt="anexo"
                                />{' '}
                            Filtrar
                        </button>
                        </div>
                    </div>

                </DialogContent>

            </Dialog>
        </div>
    );
}

export const dadosDoFiltro = dados => {
    window.localStorage.setItem('filtroLogs', JSON.stringify(dados))
}
