import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                labels: ['Ação Preparatória', 'Ação de Influência',],
                colors: ['#4CD964', '#F63854',],
                legend: {
                    show: false,
                }

            },
        }
    }


    render() {
        const seriesProps = [
            this.props.dados !== undefined ? this.props.dados[0] : 0,
            this.props.dados !== undefined ? this.props.dados[1] : 0,
        ]

        return (
            <div className="donut divGraficoBuilding">
                <Chart
                    options={this.state.options}
                    series={seriesProps}
                    type="donut"
                />
            </div>
        );
    }
}

export default Donut;
