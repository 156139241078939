import React, { Component } from 'react';
import Loading from '../../../componentes/loading';
import moment from 'moment-timezone';
import { get } from "../../../services/http"
import Grafico from '../graficos/graficoParlamentarPorOrgaos'
import { inject, observer } from 'mobx-react'

moment.tz.setDefault('UTC');

@inject('authStore')
@observer

class ParlamentarPorOrgao extends Component {
    state = {
        loading: true,
        graficoCD: 0,
        tabelaCD: [],
        graficoSF: 0,
        tabelaSF: []
    }

    imprimir() {
        window.print()
    }

    componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        this.getDadosParlamentaresOrgaos()
    }

    async getDadosParlamentaresOrgaos() {
        await get(`/relatorio/parlamentares/orgaos/${this.props.match.params.dtIni}/${this.props.match.params.dtFim}`)
            .then(response => {
                const dados = response || []
                this.setState({
                    graficoCD: dados.dadosGraficoCD,
                    tabelaCD: dados.dadosTabelaCD,
                    graficoSF: dados.dadosGraficoSF,
                    tabelaSF: dados.dadosTabelaSF,
                    loading: false
                })

            })
            .catch(err => console.error(err))
    }



    render() {
        const dados = [this.state.graficoCD, this.state.graficoSF]
        const dataInicio = moment(this.props.match.params.dtIni).format('DD/MM/YYYY')
        const dataFim = moment(this.props.match.params.dtFim).format('DD/MM/YYYY')
        const dataDaPagina = `Período de ${dataInicio} a ${dataFim}`
        // const dataDaPagina = `Parlamentares em Exercício`

        // const dominio = window.localStorage.getItem('dominio')
        // var logo = null
        // switch (dominio) {
        //     case 'Client':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'blueClient':
        //         logo = 'icon-purpleClient corblueClient'
        //         break

        //     case 'darkBlueClient':
        //         logo = 'icon-logo_darkBlueClient cordarkBlueClient'
        //         break

        //     case 'dev':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'localhost':
        //         logo = `icon-purpleClient cordarkBlueClient`
        //         break

        //     default:
        //         logo = 'icon-logo-Client corClient'
        // }
        return (
            this.state.loading ? <Loading /> :
                <div className="container paddingGeralXS">
                    <div id="non-printable" className="mt-5">
                        <button className="btn btn-danger" onClick={this.imprimir}>
                            <img
                                src={require('../../../design/assets/images/impressora.svg')}
                                alt="anexo"
                            />
                        </button>
                    </div>

                    <div className="row pt-5">
                            <div className="col-1" >
                            <a href="/">
                                <div className=" logoMenuRelatorios text-center"></div>
                                    {/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
                                </a>
                            </div>
                        <div className="col-11">
                            <div>
                                <p className="tituloRelatorio">
                                    Relatório
                                </p>
                            </div>
                            <div>
                                <p className="descricaoTituloRelatorio">
                                    Quantidade de parlamentares distribuidos por suas participações nos órgãos técnicos
                                </p>
                            </div>
                        </div>
                        <div className="col-12 text-center periodoFiltro">
                            <div className="">
                                {dataDaPagina}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center flex-wrap">
                        <Grafico
                            dados={dados}
                        />
                        <div className="d-flex align-items-center ">
                            <div className="d-flex flex-column ">
                                <div className="d-flex mb-1">
                                    <div className="corIndiceAcaoPreparatoria mt-2 mr-4"></div>
                                    <div className="mr-2 textoIndiceAcaoPreparatoria">Câmara</div>
                                    <div className="totalIndiceAcaoDeInfluencia">{this.state.graficoCD}</div>
                                </div>
                                <div className="d-flex">
                                    <div className="corIndiceAcaoDeInfluencia mt-2 mr-4"></div>
                                    <div className="mr-2 textoIndiceAcaoPreparatoria">Senado</div>
                                    <div className="totalIndiceAcaoDeInfluencia">{this.state.graficoSF}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <p className="tituloTabelaAcaoPreparatoria">
                            Câmara
                        </p>
                    </div>
                    <table className="table table-striped">
                        <thead className="theadStyleAcaoPreparatoria">
                            <tr>
                                <th className="headTipoAcaoPreparatoria">Órgãos Técnicos</th>
                                <th className="headQuantAcaoPreparatoria">Quantidade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tabelaCD !== undefined && Array.isArray(this.state.tabelaCD) && this.state.tabelaCD.length > 0 ?
                                this.state.tabelaCD.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="dadosColunaTipoAcaoPreparatoria">{`${item.sigla} - ${item.nome}`}</td>
                                            <td className="dadosColunaQuantAcaoPreparatoria">{item.quantidades}</td>
                                        </tr>
                                    )
                                })
                                : null}
                        </tbody>
                    </table>
                    <div className="mt-5">
                        <p className="tituloTabelaAcaoPreparatoria">
                            Senado
                        </p>
                    </div>
                    <table className="table table-striped ">
                        <thead className="theadStyleAcaoPreparatoria">
                            <tr >
                                <th className="headTipoAcaoPreparatoria">Órgãos Técnicos</th>
                                <th className="headQuantAcaoPreparatoria">Quantidade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tabelaSF !== undefined && Array.isArray(this.state.tabelaSF) && this.state.tabelaSF.length > 0 ?
                                this.state.tabelaSF.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="dadosColunaTipoAcaoPreparatoria">{`${item.sigla} - ${item.nome}`}</td>
                                            <td className="dadosColunaQuantAcaoPreparatoria">{item.quantidades}</td>
                                        </tr>
                                    )
                                })
                                : null}
                        </tbody>
                    </table>
                </div>

        )
    }
}

export default ParlamentarPorOrgao
