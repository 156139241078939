import React from 'react'
import { Switch, Route } from 'react-router-dom'

import CadastrarNovaClassificacao from '../classificacaoTemas/cadastro-nova-classificacao'
import EditarTema from '../classificacaoTemas/editar-tema'
import EditarSubtema from '../classificacaoTemas/editar-subtema'
import EditarGrupo from '../classificacaoTemas/editar-grupo'
import ClassificacaoTema from '../classificacaoTemas/temas-classificacao'
import NomeDoGrupo from '../classificacaoTemas/nome-do-grupo'
import NomeDoTema from '../classificacaoTemas/nome-do-tema'

export default () =>
    <Switch>
        <Route exact path="/classificacao-tema" component={ClassificacaoTema} />
        <Route exact path="/classificacao-tema/cadastro-nova-classificacao" component={CadastrarNovaClassificacao} />
        <Route exact path="/classificacao-tema/editar-tema/:id/:groupName/:Origin" component={EditarTema} />
        <Route exact path="/classificacao-tema/editar-subtema/:id/:groupName/:tName/:idPrioridade" component={EditarSubtema} />
        <Route exact path="/classificacao-tema/editar-grupo/:id/:Origin" component={EditarGrupo} />
        <Route exact path="/classificacao-tema/nome-do-grupo/:id" component={NomeDoGrupo} />
        <Route exact path="/classificacao-tema/nome-do-tema/:id/:groupName" component={NomeDoTema} />
    </Switch>
