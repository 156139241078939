import React, { Component } from 'react'
// import ReactTooltip from 'react-tooltip'
import { NavLink } from 'react-router-dom'

export class Accordion extends Component {
    state = { aberto: this.props.aberto || false }

    handleClick(e) {
        this.setState({ aberto: !this.state.aberto })
        if (this.props.alerta) {
            this.props.alerta(this.state.aberto)
        }
    }

    render() {
        const tituloClasse = `d-flex justify-content-between align-items-center ${this.props.uppercase ? 'text-uppercase' : ''} ${this.props.size ? this.props.size : '18px'}`
        const background = this.props.background ? this.props.background : 'bg-white'
        // const marginbottom = this.props.mb ? this.props.mb : 'mb-0'
        // const margintop = this.props.mt ? this.props.mt : 'mt-3'
        const indice = this.props.index === this.props.indexAtual
        // const iconTrash = this.props.iconTrash ? this.props.iconTrash : require('../design/assets/images/trash-gray.svg');
        // const iconEdit = this.props.iconEdit ? this.props.iconEdit : require('../design/assets/images/editar-gray.svg');
        return <div className={`w-100 ${this.props.bottom ? this.props.bottom : 'mb-3'}`}>
            <div className={`${background}`} >
                <div className={`${tituloClasse} d-flex justify-content-center align-items-start`} >
                    <p className={`w-80`} style={{ marginTop: "5px", marginBottom: "-8px" }}>
                        <NavLink id="proposicao-acao-preparatoria" to={`/proposicao/${this.props.navLink}/acao-preparatoria`}>
                            <span className="d-flex flex-wrap" style={{ paddingLeft: "5px", maxWidth: "100vw", fontWeight: "bold", fontSize: "18px", color:"black", cursor:"pointer" }} >{this.props.titulo}</span>
                        </NavLink>
                        <span className="d-flex flex-wrap" style={{ paddingLeft: "5px", maxWidth: "100vw", color: "#bfbebe", fontSize: "13px" }} >{this.props.atualizacao}</span>
                        <p className="d-flex flex-wrap" style={{ marginTop: "7px", marginLeft: "-3px", fontSize: "5px", marginBottom: "-12px" }} >{this.props.casa}</p>
                    </p>
                    <div style={{ paddingTop: "15px", marginBottom: "-5px", paddingRight: "5px", color: "var(--BorderSelectExpandButtom)" }} className={`d-flex align-items-center w-25 ${this.props.enableIcons ? 'justify-content-end' : 'justify-content-end'}`}>
                        <i id={this.props.id} onClick={() => this.handleClick()}
                            className={`${this.state.aberto && indice ? 'icon-arrow-up-small' : 'icon-arrow-down-small'} ${this.props.iconFont ? this.props.iconFont : 'fs-8'} cursor-pointer`} />
                    </div>
                </div>
            </div>

            {indice ?
                <div className={`${this.props.bodyBackground ? this.props.bodyBackground : ''} ${this.state.aberto ? null : null}`}>
                    {this.state.aberto && this.props.children}
                </div>
                : null}
        </div>
    }
}

export default Accordion
