import React from 'react'
import { observer } from 'mobx-react'
import alertaStore, { ALERTA_SUCESSO, ALERTA_ERRO, ALERTA_AVISO } from '../../stores/alertaStore';

// import './alertas.css'

const Alerta = props => (
    <div className={'Alerta ' + props.alerta.tipo}>
        <div className="icone">
            {obterIcone(props.alerta.tipo)}
        </div>
        <div className="texto">
            <strong>{obterTitulo(props.alerta.tipo)}</strong>
            <p>{props.alerta.mensagem}</p>
        </div>

        <button type="button" className="fechar"
            onClick={() => alertaStore.destruir(props.alerta.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" /></svg>
        </button>
    </div>);

const Alertas = observer(() =>
alertaStore.alertas !== undefined && Array.isArray(alertaStore.alertas) &&
 alertaStore.alertas.length > 0 ?
    <div className="Alertas">
        {alertaStore.alertas !== undefined
         && Array.isArray(alertaStore.alertas)
         && alertaStore.alertas.length > 0
         ? alertaStore.alertas.map((alerta, index) =>
            <Alerta key={index} alerta={alerta} />) : null }
    </div> : null
);

const obterIcone = tipo => ({
    [ALERTA_SUCESSO]: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z" /></svg>,
    [ALERTA_ERRO]: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 5h2v10h-2v-10zm1 14.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" /></svg>,
    [ALERTA_AVISO]: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 5h2v10h-2v-10zm1 14.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" /></svg>
})[tipo];

const obterTitulo = tipo => ({
    [ALERTA_SUCESSO]: 'Sucesso!',
    [ALERTA_ERRO]: 'Ops!',
    [ALERTA_AVISO]: 'Aviso!'
})[tipo];

export default Alertas;