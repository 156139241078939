import React, { Component } from 'react';

class ClientSelectModalEsfCol extends Component {

  state = {
    items: [],
    disabled: this.props.disabled,
    selected: {},
    value: this.props.value || null 
  }

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setState({ items: this.props.items, value: this.props.value, selected: this.props.items.find(item => item.id === this.state.value) || this.props.defaultValue });
  }
  onChange = selected => this.setState({ selected, showMenu: !this.state.showMenu }, () => this.props.onChange(this.state.selected))

  toggleMenu = () => this.setState({ showMenu: !this.state.showMenu })

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) this.setState({ value: nextProps.value, 
      selected: nextProps.items.find(item => item.id === nextProps.value) || nextProps.defaultValue
    });
    if (nextProps.items) this.setState({ items: nextProps.items });
  }
  render() {
    //console.log("XXXXXXXXXX",this.props)
    const items = this.state.items || [];
    return (
      <div className="Client-select-modal-esforco Client-texto-form">
        {
          !this.props.disabled ?  <>
          {/* {(this.state.value || this.state.selected) && this.props.label ? <p className="label-form-modal-esfcol">{this.props.label}</p> : null} */}
            <div id={this.props.id} className="select" onClick={() => this.toggleMenu()} style={{borderBottom:`1px solid ${this.props.borderBottomColor}`}}>
              {
                !this.state.value || !this.state.selected ? <p>{this.props.placeholder}</p> : <>
                    
                    <div className="option">
                    <p className={`Client-circulo ${this.state.selected.icon}`} style={{marginBottom:"-0px"}}></p>
                    <p className="text-truncate d-inline-block" style={{maxWidth:"200px", marginBottom:"-0px"}}>{this.state.selected.value}</p>
                    
                  </div>
                  </>
              }
              <i className="Client-angulo down mb-2 ml-2"></i>
            </div> 
            </>
            :
            <div className="select disable">
              {
                !this.state.value || !this.state.selected ? <p>{this.props.placeholder}</p> :
                  <div className="option">
                    <p className={`Client-circulo ${this.state.selected.icon} mb-1`}></p> 
                    {this.state.selected.value}
                  </div>
              }
            </div>
        }
        {
          this.state.showMenu ?
            <div className="options" onMouseLeave={() => this.toggleMenu()}>
              {
                items !== undefined && Array.isArray(items) && items.length > 0 ? items.map(item =>
                  <div className="option" key={item.id} onClick={() => this.onChange(item)}>
                    <p className={`Client-circulo ${item.icon} mb-1`}></p>
                    {item.value}
                  </div>
                )
              : null }
            </div> : null
        }
      </div>
    )
  }
}
export default ClientSelectModalEsfCol