import React, {Component} from 'react';
import moment from 'moment-timezone';
// import Breadcrumb from '../../estruturas/breadcrumb'
// import  'moment/locale/pt-br.js'
// import {get} from '../../services/http';
// import {inject, observer } from 'mobx-react'
import Loading from '../../componentes/loading';
import { NavLink } from 'react-router-dom'
import ClientSelect from '../../componentes/forms//basic/clientSelect'
import  TiposRelatorios  from '../../enums/tiposRelatorios'
import { inject, observer } from 'mobx-react'
import { anoPassado } from '../../helpers/date'

moment.tz.setDefault('UTC');

@inject('alertaStore', 'authStore')
@observer

class Relatorio extends Component {
    state = {
        loading: false,
        dataInicialRelatorio: '',
        dataFinalRelatorio: '',
        nomeTiposRelatorios: '',
    }

    componentDidMount() {
        if(this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
    }

    dataInicial (event) {
        this.setState({ dataInicialRelatorio: event.target.value })
    }

    dataFinal(event) {
        this.setState({ dataFinalRelatorio: event.target.value })
    }

    getTiposRelatorios(tipos){
        this.setState({ nomeTiposRelatorios: tipos })
    }

    relatorioAlert() {
        alert('Relatório em desenvolvimento')
    }

    render() {
        const dataHoje = new Date()
        const umMesAtras = anoPassado(dataHoje)
        const hoje = moment(dataHoje).format('YYYY-MM-DD')
        const umMesPassado = moment(umMesAtras).format('YYYY-MM-DD')
        const  dataInicio  = this.state.dataInicialRelatorio ? this.state.dataInicialRelatorio : umMesPassado
        const  dataFim = this.state.dataFinalRelatorio ? this.state.dataFinalRelatorio : hoje

        // console.log("dataInicio", dataInicio)
        // console.log("dataFim", dataFim)
     

        const proposicao = this.state.nomeTiposRelatorios.value === "Proposição" ? this.state.nomeTiposRelatorios.value : ""
        const parlamentar = this.state.nomeTiposRelatorios.value === "Parlamentar" ? this.state.nomeTiposRelatorios.value : ""
        const todos  = this.state.nomeTiposRelatorios.value === "Todos" ? this.state.nomeTiposRelatorios.value : ""
        // const path = this.props.match.params.assunto
 
        return (
            <>
                <div className=" bg-white pl-2 marginTopXs" style={{boxShadow: "-3px 0px 10px #C0C0C0"}}>
                        <p style={{fontSize:"10px", color: "var(--linkText)", fontWeight:"bold", padding:"15px" }}>
                        RELATÓRIOS
                        </p>
                    </div>
                <article className=''>
                    <section className="container-fluid">
                        {
                            this.state.loading ? <Loading/> :
                            <div>
                                <div className="row mb-3 mt-4"> 
                                    <div className="col-12 col-sm-12 col-lg-3 col-xl-3">
                                        <div className="insights-titulo-dashboard ml-3 mt-4">
                                            Relatórios
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6  col-lg-3 col-xl-3 mb-2">
                                        <div className="label-relatorio">Data Inicial</div> 
                                        <input id="data-inicial" className="p-2"  type="date" onChange={event => this.dataInicial(event)} /> 
                                    </div>
                                    <div className="col-12 col-sm-6  col-lg-3 col-xl-3 "> 
                                        <div className="label-relatorio ">Data Final</div>  
                                        <input id="data-final" className="p-2"  type="date" onChange={event => this.dataFinal(event)} />
                                    </div>
                                    <div className="col-12 col-sm-12  col-lg-3 col-xl-3">
                                        <span  className="label-insight">Tipo de Relatório</span>
                                        <ClientSelect 
                                            id="tipos-relatorios"
                                            items={TiposRelatorios}
                                            value={this.state.nomeTiposRelatorios.id || this.state.nomeTiposRelatorios}
                                            onChange={tipo => this.getTiposRelatorios(tipo)} 
                                            // placeholder={this.state.nomeTiposRelatorios === '' ?  this.state.nomeTiposRelatorios.value : this.state.nomeTiposRelatorios}    
                                        />
                                    </div>  
                                    <hr/>                                
                                </div>
                                
                                <div className="row">
                                    {todos === "Todos" || proposicao === "Proposição" || this.state.nomeTiposRelatorios === "" ?
                                            <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                                <NavLink  to={`/relatorio/proposicao-por-casa-legislativa/${dataInicio}/${dataFim}`}>
                                                    <div className="card-relatorio" >
                                                        <div className="relatorio-center">
                                                            <div className="text-center">
                                                                <img  src={require('../../design/assets/images/icone-proposicao-relatorio.svg')}  alt="proposicaoPorCasa"/>
                                                            </div>
                                                            <div className="text-insights">
                                                                Quantidade total de proposições, distribuídas em cada Casa Legislativa
                                                            </div>
                                                        </div>   
                                                    </div>
                                                </NavLink>
                                            </div>
                                            
                                        : null
                                        
                                     }
                                     {todos === "Todos" || proposicao === "Proposição" || this.state.nomeTiposRelatorios === "" ?
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                            <NavLink to={`/relatorio/proposicao-temas/${dataInicio}/${dataFim}`}>
                                                <div className="card-relatorio" >
                                                    <div className="relatorio-center"> 
                                                        <div className="text-center">
                                                            <img  src={require('../../design/assets/images/icone-proposicao-relatorio.svg')}  alt="proposicaoPorTemas"/>
                                                        </div>
                                                        <div className="text-insights">
                                                            Quantidade total de proposições divididos por temas
                                                        </div>
                                                    </div>   
                                                </div>
                                            </NavLink>
                                        </div>
                                        : null 
                                    }
                                    {todos === "Todos" || proposicao === "Proposição" || this.state.nomeTiposRelatorios === "" ?
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                            <NavLink to={`/relatorio/proposicao-por-nivel-prioridade/${dataInicio}/${dataFim}`} >
                                                <div className="card-relatorio">
                                                    <div className="relatorio-center">
                                                        <div className="text-center">
                                                            <img  src={require('../../design/assets/images/icone-proposicao-relatorio.svg')}  alt="proposicaoPorPrioridade"/>
                                                        </div>
                                                        <div  className="text-insights" >
                                                            Quantidade de proposições classificadas a partir do seu nível de prioridade
                                                        </div>

                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                        : null
                                    }
                                    {todos === "Todos" || proposicao === "Proposição" || this.state.nomeTiposRelatorios === "" ?
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                            <NavLink to={`/relatorio/proposicao-por-posicionamento/${dataInicio}/${dataFim}`}>
                                                <div className="card-relatorio">
                                                    <div className="relatorio-center">
                                                        <div className="text-center">
                                                            <img  src={require('../../design/assets/images/icone-proposicao-relatorio.svg')}  alt="proposicaoPorPosicionamento"/>
                                                        </div>
                                                        <div   className="text-insights">
                                                            Quantidade de proposições classificadas a partir do seu posicionamento

                                                        </div>

                                                    </div>
                                                </div>
                                            </NavLink>

                                        </div>
                                        : null
                                    }
                                    {todos === "Todos" || proposicao === "Proposição" || this.state.nomeTiposRelatorios === "" ?
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                            <NavLink to={`/relatorio/proposicao-ativo-inativo/${dataInicio}/${dataFim}`} >
                                                <div className="card-relatorio">
                                                    <div className="relatorio-center">
                                                        <div className="text-center">
                                                            <img  src={require('../../design/assets/images/icone-proposicao-relatorio.svg')}  alt="proposicaoPorAtivoInativo"/>
                                                        </div>
                                                        <div  className="text-insights">
                                                            Quantidade de proposições classificadas a partir do estado "Ativo" ou "Inativo"
                                                        </div>

                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                        : null
                                    }
                                    {/* {todos === "Todos" || proposicao === "Proposição" || this.state.nomeTiposRelatorios === "" ?
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                            <NavLink to={`/relatorio/eventos-por-periodo/${dataInicio}/${dataFim}`} >
                                                <div onClick={this.relatorioAlert} className="card-relatorio">
                                                    <div className="relatorio-center">
                                                        <div className="text-center">
                                                            <img  src={require('../../design/assets/images/icone-proposicao-relatorio.svg')}  alt="proposicaoPorResultado"/>
                                                        </div>
                                                        <div  className="text-insights">
                                                            Quantidade de proposições a partir do critério de resultado
                                                        </div>

                                                    </div>
                                                </div>
                                            </NavLink>

                                        </div>
                                        : null 
                                    } */}
                                    {todos === "Todos" || proposicao === "Proposição" || this.state.nomeTiposRelatorios === "" ?
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                            <NavLink to={`/relatorio/nova-proposicao-por-periodo/${dataInicio}/${dataFim}`} >
                                                <div className="card-relatorio">
                                                    <div className="relatorio-center">
                                                        <div className="text-center">
                                                            <img  src={require('../../design/assets/images/icone-proposicao-relatorio.svg')}  alt="proposicaoNovas"/>
                                                        </div>
                                                        <div  className="text-insights">
                                                            Quantidade de novas proposições inseridas no purpleClient por período
                                                        </div>

                                                    </div>
                                                </div>
                                            </NavLink>
                                            
                                        </div>
                                        : null
                                    }
                                    {todos === "Todos" || proposicao === "Proposição" || this.state.nomeTiposRelatorios === "" ?
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                            <NavLink to={`/relatorio/acoes-por-periodo/${dataInicio}/${dataFim}`}>
                                                <div className="card-relatorio">
                                                    <div className="relatorio-center">
                                                        <div className="text-center">
                                                            <img  src={require('../../design/assets/images/icone-acoes-relatorio.svg')}  alt="proposicaoAcoesPeriodo"/>
                                                        </div>
                                                        <div  className="text-insights">
                                                        Quantidade de ações realizadas por período 
                                                        </div>

                                                    </div>
                                                </div>
                                            </NavLink>

                                        </div>
                                        : null 
                                    }
                                    {todos === "Todos" || proposicao === "Proposição" || this.state.nomeTiposRelatorios === "" ?
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                            <NavLink to={`/relatorio/acoes-influencia-por-resultado/${dataInicio}/${dataFim}`} >
                                                <div className="card-relatorio">
                                                    <div className="relatorio-center">
                                                        <div className="text-center">
                                                            <img  src={require('../../design/assets/images/icone-acoes-relatorio.svg')}  alt="proposicaoAcoesPorResultado"/>
                                                        </div>
                                                        <div  className="text-insights">
                                                        Quantidade de ações de influência realizadas por período a partir do resultado
                                                        </div>

                                                    </div>
                                                </div>
                                            </NavLink>

                                        </div>
                                        :null
                                    }
                                    {todos === "Todos" || parlamentar === "Parlamentar" || this.state.nomeTiposRelatorios === "" ?
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                            <NavLink to={`/relatorio/parlamentar-por-casas/${dataInicio}/${dataFim}`} >
                                                <div className="card-relatorio">
                                                    <div className="relatorio-center">
                                                        <div className="text-center">
                                                            <img  src={require('../../design/assets/images/icone-parlamentar-relatorio.svg')}  alt="parlamentarCasas"/>
                                                        </div>
                                                        <div  className="text-insights">
                                                            Quantidade de parlamentares, divididos pelas Casas Legislativas
                                                        </div>

                                                    </div>
                                                </div >
                                            </NavLink>

                                        </div>
                                        :null
                                    }
                                    {todos === "Todos" || parlamentar === "Parlamentar" || this.state.nomeTiposRelatorios === "" ?
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                            <NavLink to={`/relatorio/parlamentar-por-profissao/${dataInicio}/${dataFim}`}>
                                                <div className="card-relatorio">
                                                    <div className="relatorio-center">
                                                        <div className="text-center">
                                                            <img  src={require('../../design/assets/images/icone-parlamentar-relatorio.svg')}  alt="parlamentarProfissao"/>
                                                        </div>
                                                        <div  className="text-insights">
                                                            Quantidade de parlamentares, classificados a partir da profissão
                                                        </div>

                                                    </div>
                                                </div >
                                            </NavLink>

                                        </div> 
                                        :null
                                    }
                                    {todos === "Todos" || parlamentar === "Parlamentar" || this.state.nomeTiposRelatorios === "" ?
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                            <NavLink to={`/relatorio/parlamentar-por-partido/${dataInicio}/${dataFim}`} >
                                                <div className="card-relatorio">
                                                    <div className="relatorio-center">
                                                        <div className="text-center">
                                                            <img  src={require('../../design/assets/images/icone-parlamentar-relatorio.svg')}  alt="parlamentarPartido"/>
                                                        </div>
                                                        <div  className="text-insights">
                                                            Quantidade de parlamentares distribuídos por partido político
                                                        </div>

                                                    </div>
                                                </div >
                                            </NavLink>

                                        </div>
                                        : null
                                    }
                                    {todos === "Todos" || parlamentar === "Parlamentar" || this.state.nomeTiposRelatorios === "" ?
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                            <NavLink to={`/relatorio/parlamentar-por-qualficacao/${dataInicio}/${dataFim}`} >
                                                <div className="card-relatorio">
                                                    <div className="relatorio-center">
                                                        <div className="text-center">
                                                            <img  src={require('../../design/assets/images/icone-parlamentar-relatorio.svg')}  alt="parlamentarQualificacao"/>
                                                        </div>
                                                        <div  className="text-insights">
                                                            Quantidade de parlamentares distribuídos pelo critério de qualificação
                                                        </div>

                                                    </div>
                                                </div >
                                            </NavLink>

                                        </div>
                                        : null
                                    }
                                    {todos === "Todos" || parlamentar === "Parlamentar" || this.state.nomeTiposRelatorios === "" ?
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 ">
                                            <NavLink to={`/relatorio/parlamentar-por-orgao/${dataInicio}/${dataFim}`} >
                                                <div  className="card-relatorio">
                                                    <div className="relatorio-center">
                                                        <div className="text-center">
                                                            <img  src={require('../../design/assets/images/icone-parlamentar-relatorio.svg')}  alt="parlamentarOrgaos"/>
                                                        </div>
                                                        <div  className="text-insights">
                                                            Quantidade de parlamentares distribuídos por suas participações nos órgãos técnicos
                                                        </div>

                                                    </div>
                                                </div >
                                            </NavLink>

                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                           
                        }
                    </section>
                </article>
            </>
        )
    }
}

export default Relatorio