import React, { Component, Fragment } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import MenuDropdown from '../componentes/links/menu-dropdown'
import TooltipNotificaton from '../paginas/notificacoes/components/tooltip-notification/tooltip-notification'
// import notificacaoStore from '../stores/notificacaoStore'
import ReactTooltip from 'react-tooltip'
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { get } from '../services/http'
// import LogopurpleClient from '../../src/design/assets/images/purpleClient/logo_purpleClient.svg'
// import IconProposicaoMunicipalEstadual from '../design/assets/images/proposicao-icone.svg'


@inject('menuStore', 'authStore')
@withRouter
@observer

class Menu extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showNotification: false,
            showDropMenu: false,
            menuIcones: false,
            showMenu: false,
            width: 0,
            renderMenu: false,
            total: null
        }
        this.toggle = this.toggle.bind(this)
        this.showDropMenu = this.showDropMenu.bind(this);
        window.addEventListener("resize", this.updateWidth)
    }

    componentDidMount() {
        this.renderMenu()
        this.updateWidth();
        this.renderQuantidade()
    }



    async renderQuantidade() {
        await get(`notificacao-quantidade`)
            .then(res => this.setState({ total: res.totalCount }))
            .catch(err => err)
    }

    renderMenu() {
        const user = window.localStorage.getItem('user') !== undefined && window.localStorage.getItem('user') !== null ? true : false
        this.setState({ renderMenu: user })
    }

    routerActive(path) {
        if (path) {
            const { pathname } = this.props.location
            const split = pathname.split("/", 2).slice(1)
            if (split.length > 0 && "/" + split[0] === path)
                return 'active'
        }
        return ''
    }

    toggleNotification() {
        this.setState({ showNotification: !this.state.showNotification })
    }

    toggleMenuMobile() {
        this.setState({ showMenu: !this.state.showMenu })
    }

    enviarDasboard() {
        this.toggleNotification()
        return window.location.href = '/'
    }

    renderRows = () => {
        return this.props.menuStore.menu.filter((item) => item.posicao === 1).map((item, index) =>
            <Fragment key={index}>
                <ReactTooltip />
                <a data-tip={item.name} href={item.path} className={this.routerActive(item.path)} key={`menu-${index}`}
                    to={item.path} onClick={(e) => this.toggle(e, item)}>
                    <i className={item.icon}></i>
                </a>
            </Fragment>)

    }

    renderRowsFooter() {

        return this.props.menuStore.menu
            .filter((item) => item.posicao === 2)
            .map((item, index) => <MenuDropdown {...item} key={`link-menu-${index}`} />)

    }


    toggle(e, item) {
        this.props.menuStore.toggleMenu(item.id) //coloco esse menu no observable
        e.preventDefault();
        this.props.history.push(item.path)
    }

    updateWidth = () => {
        this.setState({ width: window.innerWidth });
    }

    sumirMenu() {
        window.location.reload()
    }

    showDropMenu(event) {
        event.preventDefault();

        this.setState({
            showDropMenu: true,
        });
    }
    renderSwitch(dominio, clientecolor = null) {
        switch (dominio) {
            case 'localhost':
                clientecolor = '#3E4049'
                break;
            case 'dev':
                clientecolor = '#3E4049'
                break;
            case 'Client':
                clientecolor = '#3E4049'
                break;
            case 'blueClient':
                clientecolor = '#172b4d'
                break;
            case 'darkBlueClient':
                clientecolor = '#3E4049'
                break;
            default:
                clientecolor = '#3E4049'
        }
        return clientecolor
    }


    hrefMenu() {
        window.location.href = "/";
    }

    render() {
        // const dominio = window.localStorage.getItem('dominio')
        // const colorTooltip = this.renderSwitch(dominio)
        // var logo = null
        // switch(dominio){
        //     case 'Client': 
        //     logo = 'icon-logo-Client fs-24'
        //     break

        //     case 'blueClient': 
        //     logo = 'icon-purpleClient fs-24'
        //     break

        //     case 'darkBlueClient': 
        //     logo = 'icon-logo_darkBlueClient fs-24'
        //     break

        //     case 'dev': 
        //     logo = 'icon-logo-Client fs-24'
        //     break

        //     case 'localhost': 
        //     // logo = LogopurpleClient
        //     // logo = 'icon-purpleClient fs-24'
        //     break

        //     case 'purpleClient': 
        //     logo = 'icon-purpleClient fs-24'
        //     break

        //     default: 
        //     logo = 'icon-logo-Client fs-24'
        // }

        /*Estilizando o Tooltip Menu*/
        const StyledTooltip = withStyles(() => ({
            arrow: {
                color: 'var(--colorToolTip)',
            },
            tooltip: {
                backgroundColor: 'var(--colorToolTip)',
                color: '#FFF',
                fontSize: 15,
                fontFamily: 'NewJune-Book'
            },
        }))(Tooltip);


        this.props.menuStore.menu.filter((item) => item.posicao === 1).map((item, index) => item)
        return (
            <div>
                {this.state.renderMenu === true ? this.state.width < 696 ?
                    <div>
                        <nav className="menu" >
                            <div className="imagem-dash-menu">
                                <a href="/">
                                    {/* <img id="logo-dash" src={require('../design/assets/images/logo-menu.svg')} className="fs-30" alt="logo" /> */}
                                    <div style={{ paddingBottom: "60px", paddingTop: "13px", paddingLeft: "5px" }}>
                                        <div className="logoMenu text-center"></div>
                                    </div>
                                    {/* <i className="icon-purpleClient fs-30"></i>   */}
                                </a>
                            </div>
                            <div className="imagem-menu-mobile">

                                <div className="mr-3">
                                    {this.props.authStore.ehEsforcoColaborativo === true ? null :

                                        <NavLink to={'/insights/citacao'}>
                                            <span><img style={{ width: "18px" }} src={require('../design/assets/images/citacoes-menu.svg')} alt="icon-citacoes-menu"></img></span>
                                        </NavLink>

                                    }
                                </div>

                                <div className='btn-menu-dropdown' onClick={() => this.toggleNotification()}>
                                    <button className='btn-menu blue'>
                                        <i className='icon-icon-notification  '></i>
                                        {this.state.total ?
                                            <p className="badge badge-danger fs-14">{this.state.total}</p> : null
                                        }
                                    </button>
                                </div>

                                <div className="notificacao-menu ">
                                    <TooltipNotificaton onClose={(showNotification) => this.setState({ showNotification })}
                                        show={this.state.showNotification}></TooltipNotificaton>
                                </div>
                                <div >
                                    {this.state.showMenu === false ?
                                        <div>
                                            <img alt="menu-mobile" className="mr-2" onClick={() => this.toggleMenuMobile()} style={{ content: "var(--colapseIconAbrir)", display: "block", width: "25px", marginTop: "12px", marginLeft: "15px" }} />
                                        </div>
                                        // <img id="menu-mobile" src={require('var(--colapseIconAbrir)')} className="" alt="menu-mobile" onClick={() => this.toggleMenuMobile()} />
                                        :
                                        // <img id="menu-mobile-fechar" src={require('../design/assets/images/fechar-menu-mobile.svg')} className="" alt="menu-mobile-fechar" onClick={() => this.toggleMenuMobile()} />
                                        <img alt="menu-mobile" className="mr-2" onClick={() => this.toggleMenuMobile()} style={{ content: "var(--colapseIconFechar)", display: "block", width: "25px", marginTop: "12px", marginLeft: "15px" }} />

                                    }
                                </div>

                            </div>

                            <div className="margin-dash"></div>
                        </nav>

                        <div className={this.state.showMenu === false ? "toggle-menu-hide" : "toggle-menu-show  position-menu"}>

                            <div style={{ height: "20px" }}>
                                <ul className="ul-item-menu ul-menu">

                                    <li className="li-item-menu li-item-menu-mobile" onClick={this.sumirMenu}>
                                        <NavLink to={'/proposicao'}>
                                            <span><img id="rota-proposicao-mobile" style={{color:"black"}} className="mr-3" src={require('../design/assets/images/menuProposicoesIcon.svg')} alt="icon-proposicao"></img></span><span className="menu-texto-mobile">Proposições</span>
                                        </NavLink>
                                    </li>

                                    {/* <li className="li-item-menu li-item-menu-mobile" onClick={this.sumirMenu}>
                                        <NavLink to={'/proposicao-estadual-municipal'}>
                                            <span><img id="rota-proposicao-mobile" className="mr-3" src={require('../design/assets/images/proposicao-menu-mobile.svg')} alt="icon-proposicao"></img></span><span className="menu-texto-mobile">Municipais/Estaduais</span>
                                        </NavLink>
                                    </li> */}

                                    <li className="li-item-menu li-item-menu-mobile" onClick={this.sumirMenu}>
                                        <NavLink to={'/agenda'}>
                                            <span><img id="rota-agenda-mobile" className="mr-3" src={require('../design/assets/images/menuAgendaIcon.svg')} alt="icon-agenda"></img></span><span className="menu-texto-mobile">Agenda</span>
                                        </NavLink>
                                    </li>

                                    <li className="li-item-menu li-item-menu-mobile" onClick={this.sumirMenu}>
                                        <NavLink to={'/parlamentar'}>
                                            <span><img id="rota-parlamentar-mobile" className="mr-3" src={require('../design/assets/images/manuParlamentaresIcon.svg')} alt="icon-parlamentar"></img></span><span className="menu-texto-mobile">Parlamentares</span>
                                        </NavLink>
                                    </li>



                                    <li className="li-item-menu li-item-menu-mobile" onClick={this.sumirMenu}>
                                        <NavLink to={'/midia'}>
                                            <span><img className="mr-3" src={require('../design/assets/images/menuMidiaNewsIcon.svg')} alt="icon-parlamentar"></img></span><span className="menu-texto-mobile" >Midia News</span>
                                        </NavLink>
                                    </li>

                                    {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                        <li className="li-item-menu li-item-menu-mobile" onClick={this.sumirMenu}>
                                            <NavLink to={'/relatorio'}>
                                                <span><img className="mr-3" src={require('../design/assets/images/menuRelatoriosIcon.svg')} alt="icon-parlamentar"></img></span><span className="menu-texto-mobile" >Relatório</span>
                                            </NavLink>
                                        </li>
                                    }

                                    {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                        <li className="li-item-menu li-item-menu-mobile" onClick={this.sumirMenu}>
                                            <NavLink to={'/insights/tema'}>
                                                <span><img className="mr-3" src={require('../design/assets/images/menuInsightIcon.svg')} alt="icon-parlamentar"></img></span><span className="menu-texto-mobile">Insights</span>
                                            </NavLink>
                                        </li>
                                    }

                                    <div style={{ color: "white", paddingTop: "5px" }}>
                                        ______________________________________
                                    </div>
                                    
                                    {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                        <li className="li-item-menu li-item-menu-mobile" onClick={this.sumirMenu}>
                                            <NavLink to={'/inteligencia-artificial'}>
                                                <span><img className="mr-3" src={require('../design/assets/images/classBranco.svg')} alt="icon-parlamentar"></img></span><span className="menu-texto-mobile">Classificação de Temas</span>
                                            </NavLink>
                                        </li>
                                    }

                                    {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                        <li className="li-item-menu li-item-menu-mobile" onClick={this.sumirMenu}>
                                            <NavLink to={'/inteligencia-artificial'}>
                                                <span><img className="mr-3" src={require('../design/assets/images/menuIaIcon.svg')} alt="icon-parlamentar"></img></span><span className="menu-texto-mobile">Inteligência Artificial</span>
                                            </NavLink>
                                        </li>
                                    }

                                    <li className="li-item-menu" onClick={this.sumirMenu}>
                                        <NavLink to={'/usuario/perfil'}>
                                            <span><img id="rota-perfil-mobile" className="mr-3" src={require('../design/assets/images/menuPerfilIcon.svg')} alt="icon-perfil"></img></span><span className="menu-texto-mobile">Gerenciamento de Usuário</span>
                                        </NavLink>
                                    </li>
                                    <li className="li-item-menu">
                                        {/* <NavLink to={'/#section'}> */}
                                        <a href="#section" onClick={e => { e.preventDefault(); this.props.authStore.logout() }}>
                                            <span><img id="rota-sair-mobile" className="mr-3" src={require('../design/assets/images/sair-menu-mobile.svg')} alt="icon-sair"></img></span><span className="menu-texto-mobile">Sair</span>
                                        </a>
                                        {/* </NavLink> */}
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    :
                    /*LER O ARQUIVO README.MD-->> Informações sobre o layout de icones no menu*/
                    <nav className="menu d-print-none">

                        <div style={{ paddingBottom: "60px", paddingTop: "30px", paddingLeft: "5px" }}>
                            <a href="/">
                                <div className="logoMenu text-center"></div>
                            </a>

                        </div>

                        {/* <a href="/"> */}
                        {/* <div id="logoMenu">som</div> */}
                        {/* <img className="fs-30" style={{width:"35px", backgroundImage:"var(--logoMenu)"}}  id="rota-proposicao-principal" alt="logo"/> */}

                        {/* <i className={logo}></i> */}

                        {/* {(dominio === 'localhost') ? <i className="icon-purpleClient fs-24"></i> : ''}
                            {(dominio === 'dev') ? <i className="icon-logo-Client fs-24"></i> : ''}
                            {(dominio === 'Client') ? <i className="icon-logo-Client fs-24"></i> : ''}
                            {(dominio === 'blueClient') ? <i className="icon-purpleClient fs-24"></i> : ''}
                            {(dominio === 'darkBlueClient') ? <i className="icon-logo_darkBlueClient fs-24"></i> : ''}
                            {(dominio !== 'darkBlueClient' || dominio !== 'blueClient' || dominio !== 'Client' || dominio !== 'dev' || dominio !== 'localhost') ? <i className="icon-logo-Client fs-24 "></i> : ''} */}

                        {/* </a> */}

                        {/* <StyledTooltip title="Home" arrow placement="right" TransitionComponent={Zoom}>
                            <a className="icones-menu-principal"  >
                                <i className="icon-menu-proposicao fs-20"></i>
                            </a>
                        </StyledTooltip> */}

                        {/* <StyledTooltip title="Feed" arrow placement="right" TransitionComponent={Zoom}>
                            <a className="icones-menu-principal" href="/feed" >
                            <i className="icon-menu-proposicao fs-20"></i>
                            </a>
                        </StyledTooltip> */}

                        <StyledTooltip title="Proposições" arrow placement="right" TransitionComponent={Zoom}>
                            <a className="icones-menu-principal" href="/proposicao" >
                                <i className="icon-menu-agenda fs-20"></i>
                            </a>
                        </StyledTooltip>

                        {/* <StyledTooltip title="Proposições Estaduais e Municipais" arrow placement="right" TransitionComponent={Zoom}>
                            <a className="icones-menu-principal" href="/proposicao-estadual-municipal">
                                <i className="icon-logo-proposicao-darkBlueClient fs-20"></i>
                            </a>
                        </StyledTooltip> */}

                        <StyledTooltip title="Agenda" arrow placement="right" TransitionComponent={Zoom}>
                            <a className="icones-menu-principal" href="/agenda">
                                <i className="icon-menu-parlamentares fs-20"></i>
                            </a>
                        </StyledTooltip>

                        <StyledTooltip title="Parlamentar" arrow placement="right" TransitionComponent={Zoom}>
                            <a className="icones-menu-principal" href="/parlamentar">
                                <i className="icon-menu-parlamentares-new fs-20"></i>
                            </a>
                        </StyledTooltip>

                        <StyledTooltip title="Mídia" arrow placement="right" TransitionComponent={Zoom}>
                            <a className="icones-menu-principal" href="/midia">
                                <i className="icon-menu-megafone fs-20"></i>
                            </a>
                        </StyledTooltip>

                        {this.props.authStore.ehEsforcoColaborativo === true ? null :
                            <StyledTooltip title="Relatório" arrow placement="right" TransitionComponent={Zoom}>
                                <a className="icones-menu-principal" href="/relatorio">
                                    <i className="icon-menu-relatorios fs-20"></i>
                                </a>
                            </StyledTooltip>
                        }


                        {this.props.authStore.ehEsforcoColaborativo === true ? null :
                            <StyledTooltip title="Insights" arrow placement="right" TransitionComponent={Zoom}>
                                <a href="/insights/tema">
                                    <i className="icon-menu-insights fs-22"></i>
                                </a>
                            </StyledTooltip>
                        }



                        {this.props.authStore.ehEsforcoColaborativo === true ? null :
                            <StyledTooltip style={{ marginTop: "195px" }} title="Citações" arrow placement="right" TransitionComponent={Zoom}>
                                <a href="/insights/citacao">
                                    <i className="icon-menu-msg fs-22"></i>
                                </a>
                            </StyledTooltip>
                        }



                        <div className="footer">
                            {/*                             
                            
                                <div  className='btn-menu-dropdown d-none d-sm-block' >                               
                                        <button className='btn-menu blue' onClick={this.showDropMenu}>
                                        <i  className='icon-user-small fs-24'></i>
                                        </button>                   
                                </div>  

                                {this.state.showDropMenu ? 
                                    (
                                        <div className=" SubDropMenu d-flex align-self-start ">
                                            <a href='/usuario/relatorios'>
                                                <i className="icon-user-small fs-24"></i>Perfil Usuario  
                                            </a>
                                            <hr/>
                                            <a href="/logout">Sair</a>
                                        
                                        </div>
                                    ) 
                                    :
                                    (null)
                                }                          */}



                            <div id="notificacao-principal" className='btn-menu-dropdown d-none d-sm-block' onClick={() => this.enviarDasboard()}>


                                <div className="d-flex ml-2 mb-0">
                                    <div className="d-flex flex-column ">
                                        {/* <button className='btn-menu blue'> */}
                                        <button className='btn-menu '>
                                            <i className='icon-icon-notification fs-24'></i>
                                        </button>
                                    </div>
                                    <div className="d-flex flex-column" >

                                        {this.state.total ?
                                            <p className="badge badge-danger fs-14">{this.state.total}</p> : null
                                        }

                                    </div>
                                </div>
                            </div>
                            {/* <div className=" ml-2 " style={{ marginTop: '-12px' }}>
                                <TooltipNotificaton
                                    onClose={(showNotification) => this.setState({ showNotification })}
                                    show={this.state.showNotification}>
                                </TooltipNotificaton>
                            </div> */}


                            <div>
                                {this.renderRowsFooter()}
                            </div>

                            {/* <div className="mb-4"></div> */}
                        </div>
                    </nav>
                    : null
                }
            </div>
        )
    }
}

export default withRouter(Menu)