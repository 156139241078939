import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function CircularIndeterminate(props) {

    const useStyles = makeStyles(theme => ({
        root: {    
          color:`${props.color}`, 
        }
      }));
 
  const classes = useStyles();
  return (
    
    <div>
       <CircularProgress className={classes.root} size={`${props.size}`} />
    </div>
  );
}
