import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                labels: ['Pendente', 'Êxito', 'Sem Êxito', 'Êxito Parcial'],
                colors: ['#FFD200','#4CD964', '#F63854', '#5856D7'  ],
                legend: {
                    show: false,
                }
            },
            series: [
                0, 1, 2, 3, 4
            ],
        }
    }


    render() {
        const larguraTela = window.innerWidth
        const larguraGrafico = larguraTela > 375 ? '330' : '260'
        const series1 = [
            this.props.propResultadoClientPendente !== undefined ? parseInt(this.props.propResultadoClientPendente) : 0,
            this.props.propResultadoClientExito !== undefined ? parseInt(this.props.propResultadoClientExito) : 0,
            this.props.propResultadoClientSemExito !== undefined ? parseInt(this.props.propResultadoClientSemExito) : 0,
            this.props.propResultadoClientExitoParcial !== undefined ? parseInt(this.props.propResultadoClientExitoParcial) : 0,
        ]
        const series2 = [0, 0, 0, 0]
        const series = this.props.propResultadoClientPendente !== undefined && this.props.propResultadoClientExito !== undefined && this.props.propResultadoClientSemExito !== undefined && this.props.propResultadoClientExitoParcial !== undefined  ? series1 : series2
        // console.log('TESTE AQUI DONUT ===>',this.props.dados)

        return (
            <div className="donut">
                <Chart 
                    options={this.state.options} 
                    series={series} 
                    type="donut" 
                    width={larguraGrafico}
                />
            </div>
        );
    }
}

export default Donut;