import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                labels: ['Câmara', 'Senado', 'Congresso Nacional'],
                colors: ['#4CD964', '#F63854', '#5856D7'],
                legend: {
                    show: false,

                }

            },
            // series: [
            //     0, 1, 2, 3, 4
            // ],
        }
    }


    render() {
        // const larguraTela = window.innerWidth

        // var larguraGrafico = "";

        // if (larguraTela > 300 && larguraTela < 767) {
        //     larguraGrafico = "180"
        // } else if (larguraTela > 767) {
        //     larguraGrafico = "300"
        // } else if (larguraTela <= 300) {
        //     larguraGrafico = "50"
        // }

        // console.log(larguraGrafico);


        // const larguraGrafico = larguraTela > 375 ? '330' : '50'
        // larguraTela > 375 ? '330' : '260'
        const seriesProps = [
            this.props.dados !== undefined ? this.props.dados[0] : 0,
            this.props.dados !== undefined ? this.props.dados[1] : 0,
            this.props.dados !== undefined ? this.props.dados[2] : 0,
        ]

        return (
            <div className="donut divGraficoBuilding">
                <Chart
                    options={this.state.options}
                    series={seriesProps}
                    type="donut"
                // width={larguraGrafico}
                />
            </div>
        );
    }
}

export default Donut;
