import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Bar extends Component {

    constructor(props) {
        super(props);

        this.state = {
                
            responsive: [
              {
                breakpoint: 500,
                
                options: {
                  
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]

          };         
    }

 
    render() {
      // console.log('===>>>',this.props.quantidade)
      // console.log('===>>>',this.props.nome)

     const series =  [{ name: '', data: this.props.quantidade.length > 0 ? this.props.quantidade.slice(0,20)  :  [] }]
    // const series =  [{ name: '', data: this.props.quantidade.length > 0 ? this.props.quantidade   :  [] }]
     const options = { 
              colors: ["#86A239"],
                dataLabels: {
                  enabled: false
                },
             
              xaxis: {
                categories: this.props.nome.length > 0 ?  this.props.nome.slice(0,20) : [],      
                //categories: this.props.nome.length > 0 ?  this.props.nome : [],  
                labels: {
                  maxHeight: 600,
                  rotate: -70,
                  style: {
                    colors: ["#000000"],
                    fontSize: '12px'
                  }
                },
               
              },
             
               //   plotOptions: {
              //     bar: {
              //         dataLabels: {
              //             position: 'top', // top, center, bottom
              //        },
              //     }
              //   },
                
              //   dataLabels: {
              //     enabled: true,
              //     formatter: function (val) {
              //       return val + "%";
              //     },
              //     offsetY: -10,
              //     style: {
              //       fontSize: '12px',
              //       colors: ["#304758"]
              //     }
              //   },

              yaxis: {
                //tickAmount:5,
                tickAmount: this.props.quantidade.length > 0 ? this.props.quantidade[0] : 0,
                min: 0,
                max: this.props.quantidade.length > 0 ? this.props.quantidade[0] : 0,
               
                labels: {
                  show: true,
                  formatter: function (val) {
                    //return val + "%";
                    return val + "";
                  }
                }
              
              },

              tooltip: {
               
                style: {
                    fontSize: '15px',
                    fontFamily: undefined,
                    color:'#000000'
                  },
                x: {
                    show: false,
                  },
                              
                y: {
                    title: {
                        formatter:'TESTE',
                    },
                  formatter: function (val) {
                    //return val + "%";
                    return val + " - CPI";
                  }
                }
              }

            };              
                 
         //const larguraTela = window.innerWidth
         //const larguraGrafico = larguraTela > 375 ? '1000' : '400'
         const larguraGrafico = '100%'
         //console.log('>>>>>',larguraTela);
       

        return (
            <div className="donut">
                <Chart 
                    options={options} 
                    series={series}
                    type="bar" 
                    height={400}
                    width={larguraGrafico}
                  
                />
            </div>
        );
    }
}

export default Bar;
