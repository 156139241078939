import React, { Component } from "react";
import { get } from "../../services/http";
import moment from 'moment-timezone';
import "moment/locale/pt-br.js";
import Loading from "../../componentes/loading";
import { inject, observer } from "mobx-react";
import { NavLink } from 'react-router-dom'
// import { InputAdornment } from "@material-ui/core";

moment.tz.setDefault('UTC');

@inject("authStore")
@observer
class AgendaJornal extends Component {
	state = {
		eventosSemana: [],
		casa: this.props.match.params.casa,
		loading: true,
	};

	async componentDidMount() {
		if (this.props.authStore.ehEsforcoColaborativo === true) {
			window.location.href = "/agenda";
		}
		//await get(`/agenda?dataInicio=${this.props.match.params.dataInicial+'T00:00:00'}&dataFim=${this.props.match.params.dataFinal+'T23:59:59'}`).then(response => this.setState({ eventosSemana: response, loading: false }))
		await get(
			`/agenda/${this.props.match.params.dataInicial + "T00:00:00"}/${this
				.props.match.params.dataFinal + "T23:59:59"}/eventos`
		).then((response) =>
			this.setState({
				eventosSemana: response,
				loading: false,
			})
		);
	}

	imprimir() {
		window.print();
	}

	renderProposicao(id) {
		window.location.href = `/proposicao/${id}/acao-preparatoria`;
	}

	// test(id) {
	// 	console.log("chegou", id);
	// }


	render() {
		// console.log("eventos da semana:", this.state.eventosSemana)
		// console.log("casa:", casaDescricao)

		moment.updateLocale("pt", {
			months: [
				"Janeiro",
				"Fevereiro",
				"Março",
				"Abril",
				"Maio",
				"Junho",
				"Julho",
				"Agosto",
				"Setembro",
				"Outubro",
				"Novembro",
				"Dezembro",
			],
		});
		const now = new Date();
		const dataHoje = moment(now).format("DD [de] MMMM [de] YYYY");
		// const diaInicio = moment(new Date(this.props.match.params.dataInicial)).format('DD [de] MMMM [de] YYYY');
		// const diaFim = moment(new Date(this.props.match.params.dataFinal).getTime() + 156400000).format('DD [de] MMMM [de] YYYY');

		const diaEntrada = moment(
			new Date(this.props.match.params.dataInicial)
		).format("DD");
		const mesEntrada = moment(
			new Date(this.props.match.params.dataInicial)
		).format("MMMM");
		const anoEntrada = moment(
			new Date(this.props.match.params.dataInicial)
		).format("YYYY");
		const diaSaida = moment(
			new Date(this.props.match.params.dataFinal).getTime() + 156400000
		).format("DD");
		const mesSaida = moment(
			new Date(this.props.match.params.dataFinal).getTime() + 156400000
		).format("MMMM");
		const anoSaida = moment(
			new Date(this.props.match.params.dataFinal).getTime() + 156400000
		).format("YYYY");
		// const mes = moment(this.props.match.params.dataFinal).format('MMMM');
		const diaSemana = now.getDay();
		var diaDaSemana = "";
		switch (diaSemana) {
			case 0:
				diaDaSemana = "Domingo";
				break;
			case 1:
				diaDaSemana = "segunda-feira";
				break;
			case 2:
				diaDaSemana = "terça-feira";
				break;
			case 3:
				diaDaSemana = "quarta-feira";
				break;
			case 4:
				diaDaSemana = "quinta-feira";
				break;
			case 5:
				diaDaSemana = "sexta-feira";
				break;
			case 6:
				diaDaSemana = "sábado";
				break;
			default:
				console.log("dia da semana");
		}

		// var casaDescricao = '';
		// switch (parseInt(this.props.match.params.casa)) {
		//     case 0:
		//         casaDescricao = 'Agenda de Todas as Casas';
		//         break;
		//     case 3:
		//         casaDescricao = 'Agenda do Senado Federal';
		//         break;
		//     case 1:
		//         casaDescricao = 'Agenda da Câmara dos Deputados';
		//         break;
		//     case 2:
		//         casaDescricao = 'Agenda do Congresso Nacional';
		//         break;
		//     default:
		//         console.log('Casa Descrição');
		// }

		// console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", this.state.eventosSemana)

		return (
			<div
				className="container pagebreak"
				style={{ background: "white" }}
			>
				<div
					className="d-none d-md-block"
					id="non-printable"
					style={{ paddingLeft: "30px", paddingTop: "15px" }}
				>
					<button className="btn btn-danger" onClick={this.imprimir}>
						<img
							src={require("../../design/assets/images/impressora.svg")}
							alt="anexo"
						/>
					</button>
				</div>
				<div
					className="space-header-jornal"
					style={{ padding: "30px" }}
					id="printable"
				>
					<div className="row pt-3">
						<div className="col-md-1 col-12 div-logo-Client">
							<div className="logoAgendaJornal text-center"></div>
							{/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
						</div>

						<div className="col-md-11 col-12 text-left">
							<h1 className="titulo-evento">
								{parseInt(this.props.match.params.casa) === 1
									? "Agenda da Câmara dos Deputados"
									: parseInt(this.props.match.params.casa) ===
										3
										? "Agenda do Senado Federal"
										: parseInt(this.props.match.params.casa) ===
											2
											? "Agenda do Congresso Nacional"
											: parseInt(this.props.match.params.casa) ===
												0
												? "Agenda Federal, Estadual e Municipal"
												: "Agenda dos Estados e Municípios"}
							</h1>
							<h5 className="data-eventos">
								Brasilia, {diaDaSemana}, {dataHoje}
							</h5>
						</div>

						<div className=" col-12 text-center pt-4">
							<h4 className="semana-eventos">
								Semana de{" "}
								{mesEntrada !== mesSaida
									? `${diaEntrada} de ${mesEntrada} ${anoEntrada !== anoSaida
										? " de " + anoEntrada
										: ""
									} a ${diaSaida} de ${mesSaida} de ${anoSaida}`
									: `${diaEntrada} a ${diaSaida} de ${mesEntrada} de ${anoSaida}`}
							</h4>
						</div>
					</div>
				</div>
				<div >
					<hr />
				</div>
				<div className="row">
					{this.state.loading ? (<Loading />)
						: (
							<div className="myDivToPrint">
								{this.state.eventosSemana && this.state.eventosSemana.map &&
									this.state.eventosSemana.map((item, index) => {
										const diaEvento = moment(item.data_inicio)
											.format("DD");
										const horaEvento = moment(item.data_inicio)
											.format("HH");
										console.log("item.propSeguidas>>>>>>>>>>>>>>>>", item);
										const minutoEvento = moment(item.data_inicio)
											.format("mm");

										var diaDaSemanaProp = "";

										switch (
										new Date(item.data_inicio).getDay()
										) {
											case 0:
												diaDaSemanaProp = "Domingo";
												break;
											case 1:
												diaDaSemanaProp = "SEGUNDA";
												break;
											case 2:
												diaDaSemanaProp = "TERÇA";
												break;
											case 3:
												diaDaSemanaProp = "QUARTA";
												break;
											case 4:
												diaDaSemanaProp = "QUINTA";
												break;
											case 5:
												diaDaSemanaProp = "SEXTA";
												break;
											case 6:
												diaDaSemanaProp = "sábado";
												break;
											default:
												console.log("dia da semana");
										}
										return item.proposicoesSeguidas !== undefined && item.proposicoesSeguidas.length > 0 && parseInt(this.props.match.params.casa) !== 0 &&
											parseInt(this.props.match.params.casa) === item.casa ? (
											<div className="col-12" key={index}>
												<div>
													<div
														className="text-center"
														style={{
															marginTop: "50px",
														}}
													>
														<h5 className="titulo-card">
															{item.nome}
														</h5>
													</div>
												</div>

												<div
													className="text-left"
													style={{
														padding: "30px",
														marginTop: "70px",
													}}
												>
													<h5 className="item-descricao">
														<span className="data-numero">
															{diaEvento}
														</span>{" "}
														{diaDaSemanaProp}
													</h5>
													<div className="espaco-texto"></div>
													<h5 className="data-comissao">
														- {horaEvento}h
														{minutoEvento}{" "}
														{
															item && item.orgaos[0] ? item.orgaos[0].comissao_nome : null
														}
													</h5>
													<p className="texto-evento">
														{item.localCamara !==
															undefined &&
															item.localCamara.nome !==
															undefined
															? item.localCamara.nome
															: null}
													</p>
												</div>

												{item !== undefined &&
													item.proposicoesSeguidas !==
													undefined &&
													Array.isArray(
														item.proposicoesSeguidas
													) &&
													item.proposicoesSeguidas.length > 0
													? item.proposicoesSeguidas.map(
														(propSeg, index) => {
															var posicionamento =
																"";
															switch (propSeg.posicaoDaOrganizacao) {
																case 1:
																	posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																		<p className="texto-evento">
																			<span className="seta"></span>
																			<span className="titulo-items">
																				{" "}
																				POSICIONAMENTO CONSOLIDADO:{" "}
																			</span>
																		</p>
																		<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "red" }}></div>
																		<div>Contrário</div>
																	</div>;
																	break;
																case 2:
																	posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																		<p className="texto-evento">
																			<span className="seta"></span>
																			<span className="titulo-items">
																				{" "}
																				POSICIONAMENTO CONSOLIDADO:{" "}
																			</span>
																		</p>
																		<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#007aff" }}></div>
																		<div>Contrário com emenda saneadora</div>
																	</div>;
																	break;
																case 3:
																	posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																		<p className="texto-evento">
																			<span className="seta"></span>
																			<span className="titulo-items">
																				{" "}
																				POSICIONAMENTO CONSOLIDADO:{" "}
																			</span>
																		</p>
																		<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#e1e8f2" }}></div>
																		<div>Neutro</div>
																	</div>;
																	break;
																case 4:
																	posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																		<p className="texto-evento">
																			<span className="seta"></span>
																			<span className="titulo-items">
																				{" "}
																				POSICIONAMENTO CONSOLIDADO:{" "}
																			</span>
																		</p>
																		<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#5856d7" }}></div>
																		<div>Favorável com emenda saneadora</div>
																	</div>;
																	break;
																case 5:
																	posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																		<p className="texto-evento">
																			<span className="seta"></span>
																			<span className="titulo-items">
																				{" "}
																				POSICIONAMENTO CONSOLIDADO:{" "}
																			</span>
																		</p>
																		<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#4cd964" }}></div>
																		<div>Favorável</div>
																	</div>;
																	break;
																case 99:
																	posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																		<p className="texto-evento">
																			<span className="seta"></span>
																			<span className="titulo-items">
																				{" "}
																				POSICIONAMENTO CONSOLIDADO:{" "}
																			</span>
																		</p>
																		<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#ffd200" }}></div>
																		<div>Pendente</div>
																	</div>;
																	break;
																case 0:
																	posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																		<p className="texto-evento">
																			<span className="seta"></span>
																			<span className="titulo-items">
																				{" "}
																				POSICIONAMENTO CONSOLIDADO:{" "}
																			</span>
																		</p>
																		<div>{" "}Sem Posicionamento</div>
																	</div>;
																	break;
																default:
																	posicionamento =
																		" Sem Posicionamento";
															}
															return propSeg !==
																null ? (
																<div key={index}>
																	<div>
																		<div style={{ paddingLeft: "30px", }}>
																			<div className="espaco-texto"></div>
																			<h5 className="item-descricao">
																				PAUTA
																			</h5>
																			<p className="texto-evento">
																				{item.pauta[0].regime}
																			</p>
																			<div className="espaco-texto2"></div>
																			<h5 className="item-descricao">
																				PROPOSIÇÃO
																			</h5>
																		</div>
																	</div>
																	<div style={{ paddingLeft: "30px", marginBottom: "50px", }}>
																		<p className="item-evento">
																			ITEM.
																			{propSeg.item}
																		</p>
																		{/* <Link to="/about">About</Link> */}
																		<p onClick={() => this.renderProposicao(propSeg.id)} className="texto-evento" style={{ cursor: "pointer" }}>
																			{propSeg.apelido !== null && propSeg.apelido !== "" && propSeg.apelido !== undefined ? propSeg.apelido + " / " : null}{" "}
																			{/* {propSeg.tipo + propSeg.numero + "/" + propSeg.ano} */}
																			{propSeg.descricao}
																			<span style={{ marginLeft: "10px", color: "red", fontWeight: "bold" }}>{propSeg.item_votado && propSeg.item_votado !== "" ? `(Em pauta: ${propSeg.item_votado})` : null}</span>
																		</p>
																		<p className="texto-evento">
																			<span className="seta"></span>
																			<span className="titulo-items">
																				{" "}
																				AUTOR:{" "}
																			</span>
																			{propSeg.autores.map((item, index) => {
																				return (
																					<span key={index}>
																						<span>
																							<NavLink style={{ color: "black", textDecoration: "underline" }} id="url-foto-classificadas" to={`/parlamentar/${item.id_parlamentar}/acao-influencia`} >
																								{item.descricao_partido !== null ? `${item.nome} (${item.descricao_partido} ${item.ufExercicio ? item.ufExercicio : ""})` : `${item.nome}`}
																							</NavLink>
																							{", "}
																						</span>
																					</span>
																				)
																			})}
																		</p>

																		{propSeg.parecer_relator && propSeg.parecer_relator !== null && propSeg.parecer_relator !== undefined && propSeg.parecer_relator !== "" ?

																			<p className="texto-evento">
																				<span className="seta"></span>
																				<span className="titulo-items">
																					{" "}
																					PARECER DO RELATOR:{" "}
																				</span>
																				{" "}
																				{propSeg.parecer_relator}
																			</p>

																			: null
																		}

																		<p className="texto-evento">
																			<span className="seta"></span>
																			<span className="titulo-items">
																				{" "}
																				EMENTA:{" "}
																			</span>
																			{propSeg.ementa}
																		</p>
																		{propSeg.posicaoDaOrganizacao !== null && propSeg.posicaoDaOrganizacao !== "" ?
																			<p className="texto-evento">
																				{posicionamento}
																			</p>
																			:
																			null}


																		<p className="texto-evento">
																			<span className="seta"></span>
																			<span className="titulo-items">
																				{" "}
																				POSICIONAMENTO DETALHADO:{" "}<span style={{ fontWeight: "normal", fontSize: "20px" }}>{propSeg.posicionamentoDetalhado.length > 0 ? null : "Sem Posicionamento"}</span>
																			</span>

																			{propSeg.posicionamentoDetalhado.map((item, index) => {
																				var switchPosicionamento = "";

																				switch (item.posicionamento) {
																					case 1: switchPosicionamento = <div className="row">
																						<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "red" }}></div>
																						<div>Contrário</div>
																					</div>;
																						break;
																					case 2: switchPosicionamento = <div className="row">
																						<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#007aff" }}></div>
																						<div>Contrário com emenda saneadora</div>
																					</div>;
																						break;
																					case 3: switchPosicionamento = <div className="row">
																						<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#e1e8f2" }}></div>
																						<div>Neutro</div>
																					</div>;
																						break;
																					case 4: switchPosicionamento = <div className="row">
																						<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#5856d7" }}></div>
																						<div>Favorável com emenda saneadora</div>
																					</div>;
																						break;
																					case 5: switchPosicionamento = <div className="row">
																						<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#4cd964" }}></div>
																						<div>Favorável</div>
																					</div>;
																						break;
																					case 99: switchPosicionamento = <div className="row">
																						<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#ffd200" }}></div>
																						<div>Pendente</div>
																					</div>;
																						break;
																					case 0: switchPosicionamento = <div className="row">
																						<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#ffd200" }}></div>
																						<div>Pendente</div>
																					</div>;
																						break;
																					default: switchPosicionamento = " Sem Posicionamento";
																				}
																				return (
																					<div key={index} className="row ml-1">
																						<div style={{ marginRight: "10px" }}>{`${item.departamento}`}</div>
																						<div>|</div>
																						<div style={{ marginLeft: "10px" }}>{`${item.nome}:`}</div>
																						{item.cor_icon !== null ?
																							<div className={`Client-circulo ${item.cor_icon}`} style={{ marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px" }}></div>
																							:
																							<div style={{ marginLeft: "10px" }}></div>
																						}
																						<div>{`${item.descricao}`}</div>
																						<div style={{ marginLeft: "10px", marginRight: "10px" }}> / </div>
																						<div>{switchPosicionamento}</div>
																					</div>
																				)
																			})}

																		</p>

																	</div>
																</div>
															) : null;
														}
													)
													: null}
											</div>
										) : (
											<div className="col-12" key={index}>
												{item.proposicoesSeguidas.length >
													0 && item.casa < 3 ? (
													<div>
														{/* data do mes e dia da semana do evento */}
														<h5 className="item-descricao">
															<span className="data-numero">
																{diaEvento}
															</span>{" "}
															{diaDaSemanaProp}
														</h5>

														<div className="text-center">
															<h5 className="titulo-card">
																{
																	item.casa_descricao
																}
															</h5>
														</div>

														{item.proposicoesSeguidas
															.length > 0 ? (
															<h5 className="data-comissao">
																- {horaEvento}h
																{minutoEvento}{" "}
																{
																	item && item.orgaos[0] ? item.orgaos[0].comissao_nome : null
																}
															</h5>
														) : null}

														<p className="texto-evento">
															{item.localCamara !==
																undefined &&
																item.localCamara
																	.nome !== undefined
																? item.localCamara
																	.nome
																: null}
														</p>

														{item !== undefined && item.proposicoesSeguidas !== undefined && item.proposicoesSeguidas.length > 0
															? item.proposicoesSeguidas.map((propSeg, index) => {
																var posicionamento = "";
																switch (propSeg.posicaoDaOrganizacao) {
																	case 1:
																		posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																			<p className="texto-evento">
																				<span className="seta"></span>
																				<span className="titulo-items">
																					{" "}
																					POSICIONAMENTO CONSOLIDADO:{" "}
																				</span>
																			</p>
																			<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "red" }}></div>
																			<span>Contrário</span>
																		</div>;
																		break;
																	case 2:
																		posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																			<p className="texto-evento">
																				<span className="seta"></span>
																				<span className="titulo-items">
																					{" "}
																					POSICIONAMENTO CONSOLIDADO:{" "}
																				</span>
																			</p>
																			<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#007aff" }}></div>
																			<span>Contrário com emenda saneadora</span>
																		</div>;
																		break;
																	case 3:
																		posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																			<p className="texto-evento">
																				<span className="seta"></span>
																				<span className="titulo-items">
																					{" "}
																					POSICIONAMENTO CONSOLIDADO:{" "}
																				</span>
																			</p>
																			<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#e1e8f2" }}></div>
																			<span>Neutro</span>
																		</div>;
																		break;
																	case 4:
																		posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																			<p className="texto-evento">
																				<span className="seta"></span>
																				<span className="titulo-items">
																					{" "}
																					POSICIONAMENTO CONSOLIDADO:{" "}
																				</span>
																			</p>
																			<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#5856d7" }}></div>
																			<span>Favorável com emenda saneadora</span>
																		</div>;
																		break;
																	case 5:
																		posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																			<p className="texto-evento">
																				<span className="seta"></span>
																				<span className="titulo-items">
																					{" "}
																					POSICIONAMENTO CONSOLIDADO:{" "}
																				</span>
																			</p>
																			<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#4cd964" }}></div>
																			<span>Favorável</span>
																		</div>;
																		break;
																	case 99:
																		posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																			<p className="texto-evento">
																				<span className="seta"></span>
																				<span className="titulo-items">
																					{" "}
																					POSICIONAMENTO CONSOLIDADO:{" "}
																				</span>
																			</p>
																			<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#ffd200" }}></div>
																			<span>Pendente</span>
																		</div>;
																		break;
																	case 0:
																		posicionamento = <div className="row" style={{ marginLeft: "0px" }}>
																			<p className="texto-evento">
																				<span className="seta"></span>
																				<span className="titulo-items">
																					{" "}
																					POSICIONAMENTO CONSOLIDADO:{" "}
																				</span>
																			</p>
																			<div>{" "}Sem Posicionamento</div>
																		</div>;
																		break;
																	default:
																		posicionamento =
																			" Sem Posicionamento";
																}
																return propSeg !== null ? (
																	<div key={index}>
																		<div>
																			<div style={{ paddingLeft: "30px", }}>
																				<div className="espaco-texto"></div>
																				<h5 className="item-descricao">
																					PAUTA
																				</h5>
																				<p className="texto-evento">
																					{item.pauta[0].regime}
																				</p>
																				<div className="espaco-texto2"></div>
																				<h5 className="item-descricao">
																					PROPOSIÇÃO
																				</h5>
																			</div>
																		</div>
																		<div style={{ paddingLeft: "30px", paddingRight: "30px", marginBottom: "50px", }}>
																			<p className="item-evento">
																				ITEM.
																				{propSeg.item}
																			</p>
																			<p onClick={() => this.renderProposicao(propSeg.id)} className="texto-evento" style={{ cursor: "pointer" }}>
																				{propSeg.apelido !== null && propSeg.apelido !== "" && propSeg.apelido !== undefined ? propSeg.apelido + " - " : null}{" "}
																				<span style={{ textDecoration: "underline" }}>
																					{/* {propSeg.tipo + propSeg.numero + "/" + propSeg.ano} */}
																					{propSeg.descricao}
																				</span>
																				<span style={{ marginLeft: "10px", color: "red", fontWeight: "bold" }}>{propSeg.item_votado && propSeg.item_votado !== "" ? `(Em pauta: ${propSeg.item_votado})` : null}</span>
																			</p>
																			<p className="texto-evento"><span className="seta"></span><span className="titulo-items">
																				{" "}AUTOR:{" "}</span>
																				{propSeg.autores.map((item, index) => {
																					return (
																						<span key={index}>
																							<span>
																								<NavLink style={{ color: "black", textDecoration: "underline" }} id="url-foto-classificadas" to={`/parlamentar/${item.id_parlamentar}/acao-influencia`} >
																									{item.descricao_partido !== null ? `${item.nome} (${item.descricao_partido} ${item.ufExercicio ? item.ufExercicio : ""})` : `${item.nome}`}
																								</NavLink>
																								{", "}
																							</span>
																						</span>
																					)
																				})}
																			</p>
																			{propSeg.parecer_relator && propSeg.parecer_relator !== null && propSeg.parecer_relator !== undefined && propSeg.parecer_relator !== "" ?

																				<p className="texto-evento">
																					<span className="seta"></span>
																					<span className="titulo-items">
																						{" "}
																						PARECER DO RELATOR:{" "}
																					</span>
																					{" "}
																					{propSeg.parecer_relator}
																				</p>

																				: null
																			}

																			<p className="texto-evento">
																				<span className="seta"></span>
																				<span className="titulo-items">
																					{" "}
																					EMENTA:
																				</span>
																				{propSeg.ementa}
																			</p>
																			{propSeg.posicaoDaOrganizacao !== null && propSeg.posicaoDaOrganizacao !== "" ?
																				<span className="texto-evento">
																					{posicionamento}
																				</span>
																				:
																				null}



																			<span className="texto-evento">
																				<span className="seta"></span>
																				<span className="titulo-items">
																					{" "}
																					POSICIONAMENTO DETALHADO:{" "}<span style={{ fontWeight: "normal", fontSize: "20px" }}>{propSeg.posicionamentoDetalhado.length > 0 ? null : "Sem Posicionamento"}</span>
																				</span>
																				{propSeg.posicionamentoDetalhado.map((item, index) => {
																					// console.log("Posicionamento Detalhado", item);
																					var switchPosicionamento = "";
																					switch (item.posicionamento) {
																						case 1: switchPosicionamento = <div className="row">
																							<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "red" }}></div>
																							<div>Contrário</div>
																						</div>;
																							break;
																						case 2: switchPosicionamento = <div className="row">
																							<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#007aff" }}></div>
																							<div>Contrário com emenda saneadora</div>
																						</div>;
																							break;
																						case 3: switchPosicionamento = <div className="row">
																							<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#e1e8f2" }}></div>
																							<div>Neutro</div>
																						</div>;
																							break;
																						case 4: switchPosicionamento = <div className="row">
																							<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#5856d7" }}></div>
																							<div>Favorável com emenda saneadora</div>
																						</div>;
																							break;
																						case 5: switchPosicionamento = <div className="row">
																							<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#4cd964" }}></div>
																							<div>Favorável</div>
																						</div>;
																							break;
																						case 99: switchPosicionamento = <div className="row">
																							<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#ffd200" }}></div>
																							<div>Pendente</div>
																						</div>;
																							break;
																						case 0: switchPosicionamento = <div className="row">
																							<div style={{ borderRadius: "100px", marginTop: "11px", marginLeft: "17px", marginRight: "10px", height: "10px", width: "10px", backgroundColor: "#ffd200" }}></div>
																							<div>Pendente</div>
																						</div>;
																							break;
																						default: switchPosicionamento = " Sem Posicionamento";
																					}
																					return (
																						<div key={index} className="row ml-1">
																							<div style={{ marginRight: "10px" }}>{`${item.departamento}`}</div>
																							<div>|</div>
																							<div style={{ marginLeft: "10px" }}>{`${item.nome}:`}</div>
																							{item.cor_icon !== null ?
																								<div className={`Client-circulo ${item.cor_icon}`} style={{ marginTop: "11px", marginLeft: "10px", marginRight: "10px", height: "10px", width: "10px" }}></div>
																								:
																								<div style={{ marginLeft: "10px" }}></div>
																							}
																							<div>{`${item.descricao}`}</div>
																							<div style={{ marginLeft: "10px", marginRight: "10px" }}> / </div>
																							<div>{switchPosicionamento}</div>
																						</div>
																					)
																				})}
																			</span>



																		</div>
																	</div>
																) : null;
															})
															:
															null}

														<hr />
													</div>
												) : null}
												{/* {console.log("CARAMBA", item)} */}
												{parseInt(this.props.match.params.casa) > 3 || parseInt(this.props.match.params.casa) === 0 ?
													(item.casa > 3 ? (

														// INFORMACOES DE EVENTOS CADASTRADOS NO FORMULARIO DE NOVOS EVENTOS
														<div>
															{/* data do mes e dia da semana do evento */}
															<h5 className="item-descricao">
																<span className="data-numero">
																	{diaEvento}
																</span>{" "}
																{diaDaSemanaProp}
															</h5>

															<div className="text-center mt-4">
																<h5 className="titulo-card">
																	{item.nome}
																</h5>
															</div>

															{<h5 className="data-comissao">
																{item.localCamara.nome}
															</h5>}

															<p className="texto-evento mt-3">
																<span className="seta"></span>
																<span className="titulo-items">
																	{" "}
																	HORÁRIO:{" "}
																</span>
																{horaEvento}h{minutoEvento}
															</p>



															<p className="texto-evento">
																<span className="seta"></span>
																<span className="titulo-items">
																	{" "}
																	TIPO DO EVENTO:{" "}
																</span>
																{
																	item.descricao_tipo
																}
															</p>

															<p className="texto-evento">
																<span className="seta"></span>
																<span className="titulo-items">
																	{" "}
																	DESCRIÇÃO DO EVENTO:{" "}
																</span>
																{item.descricao}
															</p>

															<hr />
														</div>
													) : item.localCamara.nome === "Evento de Acontecimentos" ?

														// INFORMACOES DE EVENTOS CADASTRADOS EM ACONTECIMENTOS
														(<div>
															{/* data do mes e dia da semana do evento */}
															<h5 className="item-descricao">
																<span className="data-numero">
																	{diaEvento}
																</span>{" "}
																{diaDaSemanaProp}
															</h5>

															<div className="text-center mt-4">
																<h5 className="titulo-card" >
																	{item.nome}
																</h5>
															</div>

															{<h5 className="data-comissao">
																{item.localCamara.nome}
															</h5>}

															<p className="texto-evento" >
																{item.proposicao}
															</p>

															<p className="texto-evento mt-1">
																<span className="seta"></span>
																<span className="titulo-items">
																	{" "}
																	HORARIO:{" "}
																</span>
																{horaEvento}h{minutoEvento}
															</p>



															<p className="texto-evento">
																<span className="seta"></span>
																<span className="titulo-items">
																	{" "}
																	TIPO DO EVENTO:{" "}
																</span>
																{
																	item.descricao_tipo
																}
															</p>

															<p className="texto-evento">
																<span className="seta"></span>
																<span className="titulo-items">
																	{" "}
																	DESCRIÇÃO DO EVENTO:{" "}
																</span>
																{item.descricao}
															</p>

															<hr />
														</div>) : null
													) : null}
											</div>
										);

										// item.proposicoesSeguidas !== undefined &&
										// item.proposicoesSeguidas.length > 0 &&
										// parseInt(this.props.match.params.casa) === 0 ? (

										//     <div className="col-12" key={index}>

										//         {/* De que casa? */}
										//         <div>
										//             <div className="text-center" style={{ marginTop: '50px' }}>
										//                 <h5 className="titulo-card">
										//                     {item.casa === 2
										//                         ? 'Câmara dos Deputados'
										//                         : item.casa === 1
										//                             ? 'Senado Federal'
										//                             : item.casa === 3
										//                                 ? 'Congresso Nacional'
										//                                 : 'Estados e Municípios'}
										//                 </h5>
										//             </div>
										//         </div>

										//         <div
										//             className="text-left"
										//             style={{ padding: '30px', marginTop: '70px' }}
										//         >

										//             {/* data do mes e dia da semana do evento */}
										//             <h5 className="item-descricao">
										//                 <span className="data-numero">
										//                     {diaEvento}
										//                 </span>
										//                 {' '}
										//                 {diaDaSemanaProp}
										//             </h5>

										//             <div className="espaco-texto"></div>

										//             {
										//                 item.proposicoesSeguidas.length > 0 ?
										//                     <h5 className="data-comissao">
										//                         - {horaEvento}h{minutoEvento}{' '}
										//                         		item && item.orgaos[0] ? item.orgaos[0].comissao_nome : null

										//                     </h5>
										//                     :
										//                     null
										//             }

										//             <p className="texto-evento">
										//                 {item.localCamara !== undefined &&
										//                     item.localCamara.nome !== undefined
										//                     ? item.localCamara.nome
										//                     : null}
										//             </p>
										//         </div>

										//         {item !== undefined &&
										//             item.proposicoesSeguidas !== undefined &&
										//             item.proposicoesSeguidas.length > 0
										//             ? item.proposicoesSeguidas.map((propSeg, index) => {
										//                 var posicionamento = '';
										//                 switch (propSeg.posicaoDaOrganizacao) {
										//                     case 1:
										//                         posicionamento = 'Contrário';
										//                         break;
										//                     case 2:
										//                         posicionamento = 'Contrário com emenda saneadora';
										//                         break;
										//                     case 3:
										//                         posicionamento = 'Neutro';
										//                         break;
										//                     case 4:
										//                         posicionamento = 'Favorável com emenda saneadora';
										//                         break;
										//                     case 5:
										//                         posicionamento = 'Favorável';
										//                         break;
										//                     case 99:
										//                         posicionamento = 'Pendente';
										//                         break;
										//                     case 0:
										//                         posicionamento = 'Pendente';
										//                         break;
										//                     default:
										//                         posicionamento = 'Pendente';
										//                 }
										//                 return propSeg !== null ? (
										//                     <div key={index}>
										//                         <div>
										//                             <div style={{ paddingLeft: '30px' }}>
										//                                 <div className="espaco-texto"></div>
										//                                 <h5 className="item-descricao">PAUTA</h5>
										//                                 <p className="texto-evento">
										//                                     {item.pauta[0].regime}
										//                                 </p>
										//                                 <div className="espaco-texto2"></div>
										//                                 <h5 className="item-descricao">
										//                                     PROPOSIÇÕES
										//                                 </h5>
										//                             </div>
										//                         </div>
										//                         <div
										//                             style={{
										//                                 paddingLeft: '30px',
										//                                 paddingRight: '30px',
										//                                 marginBottom: '50px',
										//                             }}
										//                         >
										//                             <p className="item-evento">
										//                                 ITEM.{propSeg.item}
										//                             </p>
										//                             <p className="texto-evento">
										//                                 {propSeg.apelido !== null && propSeg.apelido !== '' && propSeg.apelido !== undefined
										//                                     ? propSeg.apelido + ' / '
										//                                     : null}{' '}
										//                                 {propSeg.tipo +
										//                                     propSeg.numero +
										//                                     '/' +
										//                                     propSeg.ano}
										//                             </p>
										//                             <p className="texto-evento">
										//                                 <span className="seta"></span>
										//                                 <span className="titulo-items"> AUTOR: </span>
										//                                 {propSeg.autores}
										//                             </p>
										//                             <p className="texto-evento">
										//                                 <span className="seta"></span>
										//                                 <span className="titulo-items">
										//                                     {' '}
										//                                     PARECER DO RELATOR:{' '}
										//                                 </span>{' '}
										//                                 {propSeg.parecer_relator}
										//                             </p>
										//                             <p className="texto-evento">
										//                                 <span className="seta"></span>
										//                                 <span className="titulo-items">
										//                                     {' '}
										//                                 EMENTA:{' '}
										//                                 </span>
										//                                 {propSeg.ementa}
										//                             </p>
										//                             {propSeg.posicaoDaOrganizacao !== null &&
										//                                 propSeg.posicaoDaOrganizacao !== '' ? (
										//                                     <p className="texto-evento">
										//                                         <span className="seta"></span>
										//                                         <span className="titulo-items">
										//                                             {' '}
										//                                             POSICIONAMENTO:{' '}
										//                                         </span>
										//                                         {posicionamento}
										//                                     </p>
										//                                 ) : null}
										//                         </div>
										//                     </div>
										//                 ) : null;
										//             })
										//             : null}
										//     </div>
										// )

										// : null;
									})}
							</div>
						)}
				</div>
			</div>
		);
	}
}

export default AgendaJornal;
