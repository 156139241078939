const casa = {
    SENADO: 1,
    CAMARA: 2,
    COMISSAO: 3,

    1: 'Senado Federal',
    2: 'Câmara dos Deputados',
    3: 'Congresso nacional',
}

export const ambitos = [
    { id: 1, nome: 'Senado', spanclass: "acao-green" },
    { id: 2, nome: 'Câmara', spanclass: "acao-blue" },
    { id: 3, nome: 'Congresso nacional', spanclass: "acao-yellow" }
]

export default {
    ...casa,
    casas: [1, 2, 3].map(id => ({ id, titulo: casa[id] }))
}