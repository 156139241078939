import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
// import { post } from '../../../services/http';
import Loading from '../../../componentes/loading'
import { inject, observer } from 'mobx-react'
import ModalEmendas from '../modal/modalEmendas'
import ClientSelect from '../../../componentes/forms/basic/clientSelect'
import { posicionamentosClient } from "../../../enums/posicionamento";
import { put, post } from '../../../services/http';
import alertaStore from '../../../../src/stores/alertaStore';


@inject('authStore')
@observer

@withRouter

class Emendas extends Component {

  constructor(props) {
    super(props)
    this.state = {

      items: [],
      proposicaoId: null,
      loading: true,
      dadosProposicao: {},
      analiseImpacto: {},
      indexMudanca: [],

    }
  }

  async componentDidMount() {
    await this.setState({ proposicaoId: this.props.idProposicao })
    await this.obterEmendas()
  }

  async obterEmendas() {
   
    const data = { id: this.props.idProposicao, pagina: 1 }
    await post('proposicao/emendas', data)
      .then(response => {
        //console.log('>>>>>>',response)
        this.setState({ items: response, loading: false})
      })
      .catch(err => err)
  }

//   async getProposicao () {
//     await get(`proposicao/especifica/${this.props.match.params.id}`)
//       .then(res => {
//         console.log(res)
//         const dados = res || {}
//         this.setState({ 
//             analiseImpacto: dados.analiseImpacto,
          
           
//         })
//       })
//       .catch(err => err)

//   }

  async alterarPosicionamento(novoPosicionamento, id) {

    //console.log('novoPosicionamento, id', novoPosicionamento, id)

    const data = { "posicionamento": novoPosicionamento}
    await put(`/proposicao/emenda/posicionamento/${id}`, data)
      .then(res => {
        //console.log('RES >>>>>>>', res)
        if(res.length > 0){
          alertaStore.criar('Posicionamento alterado com sucesso.')
        }else{
           alertaStore.erro("Erro ao alterar posicionamento")
        }
        
       
      })
      .catch(err => err)  
  }

  render() {

    //console.log('MAP ITEMS',this.state.items )
    return (
      this.state.loading ? <Loading /> :
        <Fragment>
          {this.state.items !== undefined && Array.isArray(this.state.items) && this.state.items.length > 0 ?
            this.state.items.map((item, index) => { 
              
              return (
                <div key={index} className={`Client-mini-card mb-4`}>

                  <ModalEmendas
                    item={item}
                  />

                  <p className="text-wrap fs-16 mt-2">{item.texto.substring(0, 200) + '...'}</p>

                  {this.props.authStore.ehEsforcoColaborativo !== true 
                      ?
                        <div className="col-12 col-sm-8 col-md-10 col-lg-8 col-xl-7">
                          {/* <span className="label-analise-impacto-proposicao">Posicionamento</span> */}
                          <ClientSelect
                            padding="p-0"
                            marginLeft="ml-1"
                            id="analise-posicionamento-proposicao"
                            items={posicionamentosClient}
                            value={item.posicionamento !== null ? item.posicionamento : posicionamentosClient}
                            // label = 'Posicionamento'
                            placeholder="Posicionamento"
                            onChange={this.props.authStore.ehVisualizador === true ? null : novoPosicionamento => this.alterarPosicionamento( novoPosicionamento.id, item.id_emenda)}
                            disabled={this.props.authStore.ehVisualizador === true ? true : false}
                          />
                        </div>
                      :
                        null
                  } 
                 
                </div>

              )
            }) :
            <div className="container-fluid text-center bg-white p-4 mb-4">
              <h4 className="mb-0 Client-titulo">Nâo foram encontradas Emendas</h4>
            </div>
          }
        </Fragment>
    )
  }
}

export default props => <Emendas {...props} semPaginacao={true} />
