import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import { NavLink } from 'react-router-dom'

export class Accordion extends Component {
  state = { aberto: this.props.aberto || false }

  handleClick(e){
    this.setState({ aberto: !this.state.aberto })
    if(this.props.alerta) {
      this.props.alerta(this.state.aberto)
    }
  }

  render() {
    const tituloClasse = `m-2 d-flex justify-content-between align-items-center ${this.props.uppercase ? 'text-uppercase' : ''} ${this.props.size ? this.props.size : 'h5'}`
    const background = this.props.background ? this.props.background : 'bg-white'
    const padding = this.props.p ? this.props.p : 'p-3'
    const marginbottom = this.props.mb ? this.props.mb : 'mb-0'
    const margintop = this.props.mt ? this.props.mt : 'mt-2'
    const indice = this.props.index === this.props.indexAtual
    // const iconTrash = this.props.iconTrash ? this.props.iconTrash : require('../design/assets/images/trash-gray.svg');
    // const iconEdit = this.props.iconEdit ? this.props.iconEdit : require('../design/assets/images/editar-gray.svg');
    return <div className={`w-100 ${this.props.bottom ? this.props.bottom : 'mb-3'}`}>
      <div className={`${background} ${padding} ${marginbottom}`} >
        <ReactTooltip />
        <div data-tip={this.props.tooltip} className={`${tituloClasse} d-flex justify-content-center align-items-start`} >
          <p className={`w-75 ${marginbottom} ${margintop}`}>
            {this.props.numero === undefined ? null :
              <span className="numero-acoes-influencia">{this.props.numero}{' '}</span>
            }
            <span className="d-flex flex-wrap" style={{maxWidth: "100vw"}} >{this.props.titulo }</span>
             <span className='Client-detalhe p-2'>{this.props.subtitle}</span>
            {this.props.proposicaoDescricao === undefined ? null :
              <NavLink id="proposicao-acao-influencia" to={`/proposicao/${this.props.proposicaoId}/acoes-influencia`}>
                <span className="proposicao-descricao-acoes-influencia">{' '}{this.props.proposicaoDescricao}</span>
              </NavLink>
            }
          </p>
          <div className={`pt-2 d-flex align-items-center w-25 ${this.props.enableIcons ? 'justify-content-end' : 'justify-content-end'}`}>
            <i id={this.props.id} onClick={() => this.handleClick() }
              className={`${this.state.aberto && indice   ? 'icon-arrow-up-small' : 'icon-arrow-down-small'} ${this.props.iconFont ? this.props.iconFont : 'fs-16'} mt-1 cursor-pointer`} />
          </div>
        </div>
      </div>
      
     {indice ? 
      <div className={`${this.props.bodyBackground ? this.props.bodyBackground : ''} ${this.state.aberto ? margintop : 'mt-0'} ${this.props.mb ? this.props.mb : ''}`}>
        {this.state.aberto && this.props.children }
      </div>
    : null }
    </div>
  }
}

export default Accordion
