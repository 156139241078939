import React, {Component} from 'react';
// import Breadcrumb from '../../estruturas/breadcrumb'
// import {get} from '../../services/http';
// import {inject, observer } from 'mobx-react'
import Loading from '../../componentes/loading';
import InsightParlamentar from './insightParlamentar'
import InsightTema from './tema/insightTema'
import InsightProposicao from './insightProposicao'
import InsightCitacao from './insightCitacoes'
import { NavLink } from 'react-router-dom'
import Slider from 'react-slick'
import { inject, observer } from 'mobx-react'

const sliderSettingsInsights = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

@inject('authStore')
@observer

class Insights extends Component {
    state = {
        loading: false,
    }

    
    componentDidMount() {
        if(this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
    }

    render() {

        const path = this.props.match.params.assunto

        //console.log('path>>>>',path)
 
        return (
            <>
                {/* <Breadcrumb /> */}
                <div className=" bg-white pl-2 marginTopXs" style={{boxShadow: "-3px 0px 10px #C0C0C0"}}>
                        <p style={{fontSize:"10px", color: "var(--linkText)", fontWeight:"bold", padding:"15px" }}>
                        INSIGHTS
                        </p>
                    </div>
                <article className='insigths d-block d-print-none mt-5'>
                    <section className="container" >
                        {
                            this.state.loading ? <Loading/> :
                            <div >
                                <div className="titulo-insights p-3">
                                    <b>Insights</b> 
                                </div>
                                <div className="slider-insigths">
                                    <Slider {...sliderSettingsInsights}>  
                                        <div className="bordas-links-insights ">
                                            <NavLink  to={`/insights/tema`} className="nav-link" activeClassName="active">
                                                <div className="remove-link-insigths">
                                                    Temas
                                                </div>
                                            </NavLink>
                                        </div>
                                    
                                    
                                        <div className="bordas-links-insights">
                                            <NavLink  to={`/insights/parlamentar`} className="nav-link" activeClassName="active">
                                                <div className="remove-link-insigths">
                                                    Parlamentares
                                                </div>
                                            </NavLink>
                                        </div>
                                    
                                    
                                        <div className="bordas-links-insights">
                                            <NavLink to={`/insights/proposicao`} className="nav-link" activeClassName="active">
                                                <div className="remove-link-insigths">
                                                    Proposições
                                                </div>
                                            </NavLink>
                                        </div>
                                    
                                    
                                        <div className="bordas-links-insights">
                                            <NavLink to={`/insights/citacao`} className="nav-link" activeClassName="active">
                                                <div className="remove-link-insigths">
                                                    Citações
                                                </div>
                                            </NavLink>
                                        </div>
                                    </Slider>
                                </div>
                                <div className="row mt-2" style={{backgroundColor: 'white', borderRadius:"3px"}}>
                                    <div className="col-12" >

                                        {path === 'parlamentar' ? 
                                            <InsightParlamentar />
                                        : null}

                                        {path === 'tema' ? 
                                            <InsightTema />
                                        : null}

                                        {path === 'proposicao' ? 
                                            <InsightProposicao />
                                        : null}

                                        {path === 'citacao' ? 
                                            <InsightCitacao />
                                        : null}

                                    </div>
                                </div>
                            </div>
                        }
                    </section>
                </article>
            </>
        )
    }
}

export default Insights