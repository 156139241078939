import React, { useState, useContext } from "react"
import { withRouter } from "react-router-dom"
import { MidiaNewsContext } from "./index"

const form = ({ history }) => {
   
    const { setSearch, loadSearch } = useContext(MidiaNewsContext)
    const [keyword, setKeyword] = useState("")
    const openAdd = () => {
        setSearch([])
        history.push("/midia/add")
       
    }
    
    const addEnter = ({ key }) => key === "Enter" && loadSearch({ keyword })
    return (
        <nav className="navbar navbar-light bg-trasnparent p-0 mt-2 col-12">
            <div className="col-6 col-sm-6 col-lg-9 col-xl-10">
                {/* <label className="mr-2 ">Buscar:</label> */}
                <div id="remover-bordas-inputs-midia" className="input-group ">
                    <input 
                        required
                        maxLength="30"
                        autoComplete="off"
                        className="form-control"
                        type="text"
                        placeholder="Buscar artigo"
                        value={keyword}
                        onChange={({ target }) => setKeyword(target.value.trimLeft())}
                        onKeyPress={({ key }) => addEnter({ key })}
                    />
                    <div className="input-group-append" onClick={() => loadSearch({ keyword })} style={{ cursor: "pointer"}}>
                        <span className="input-group-text bg-white">
                            <i className="icon-search-small"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-2 d-flex justify-content-xl-end justify-content-lg-end">
                <div
                    className="btn btn-danger my-2 my-sm-0 "
                    type="submit"
                    onClick={() => openAdd() }
                >
                    <i className="icon-chave-midia fs-14 mr-2"></i>      
                    <span className="font-weight-normal"> Palavra chave</span>
                    
                </div>
            </div>
        </nav>
        
    )
}

export default withRouter(form)
