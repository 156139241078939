import React, { Component, Fragment } from 'react';
import Loading from '../../componentes/loading';
import SelectFiltroProposicao from './form/selectFiltroProposicao';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import { get } from '../../services/http'

moment.tz.setDefault('UTC');

const inputStyle = {
  border: 'none', 
  backgroundColor: 'transparent', 
  borderBottom: '1px solid #C4C4C4', 
  color: '#000000', 
  marginTop: '10px', 
  width: '100%', 
  placeholder: 'red', 
  outline: 'none'
};

class FormExecutivo extends Component {
    state = {
        loading: true,
        arrayFiltro: [],

        tipoDocumento: {value: '', label: ''},
        tipoDocumentoList: [],

        esfera:{value:'' , label: ''},
        esferaList:[],

        numero:'',
        ano:'',
        ementa:'',
        autor:'',
        dataPublicacao:'',
        docRelacionadoNum:'',
        docRelacionadoAno:'',
        idInterno:'',

        partido:{value: '', label: ''},
        partidoList:[],

        uf:{value:'' , label: ''},    
        ufList:[],

        municipio:{value:'' , label: ''},
        municipioList:[],
        
        docRelacionadoTipo:{value:'' , label: ''},
        docRelacionadoTipoList:[],

        grandeArea:{value:'' , label: ''},
        grandeAreaList:[],

        tema:{value:'' , label: ''},
        temaList:[],

        atualizadoUltSCheck: false,

        indexacao:{value:'' , label: ''},
        indexacaoList:[],
        
        impactoJuridico:{value:'' , label: 'Impacto Jurídico'},
        impactosJuridicoList:[],

        estrategiaAtuacao:{value:'' , label: 'Estratégia de Atuação'},
        estrategiaAtuacaoList:[],    
        
        acao:{value:'' , label: 'Ação'}, 
        acaoList:[],

        ultimasAcoesCheck: false,
        docNotRapidasCheck: false,
        arquivamentoAtivoCheck: false,
        arquivamentoArquivadosCheck: false,

        arrayFiltroExecutivo: []

    }

    async componentDidMount() {
        this.getDadosFiltroLegislativos()
    }

    async getDadosFiltroLegislativos() {
        await get(`darkBlueClient/executivo`)
            .then(res => {

                console.log('ACAO ===>',res)

                const tipoDocumentoArray = []
                res.tipoDocumentoExecutivo !== undefined && Array.isArray(res.tipoDocumentoExecutivo) 
                && res.tipoDocumentoExecutivo.length > 0 &&
                res.tipoDocumentoExecutivo.map((item, index) => {
                    const data = {id: index, tipo: item.tipo_documento}
                    return tipoDocumentoArray.push(data)
                })
                const esferaArray = []
                res.esferaExecutivo !== undefined && Array.isArray(res.esferaExecutivo) 
                && res.esferaExecutivo.length > 0 &&                
                res.esferaExecutivo.map((item, index) => {
                    const data = {id: index, esfera: item.esfera}
                    return esferaArray.push(data)
                })
                const partidoArray = []
                res.partidoExecutivo !== undefined && Array.isArray(res.partidoExecutivo) 
                && res.partidoExecutivo.length > 0 &&                 
                res.partidoExecutivo.map((item, index) => {
                    const data = {id: index, partido: item.partido}
                    return partidoArray.push(data)
                })
                const ufArray = []
                res.ufExecutivo !== undefined && Array.isArray(res.ufExecutivo) 
                && res.ufExecutivo.length > 0 &&                 
                res.ufExecutivo.map((item, index) => {
                    const data = {id: index, uf: item.uf}
                    return ufArray.push(data)
                })
                const municipioArray = []
                res.municipioExecutivo !== undefined && Array.isArray(res.municipioExecutivo) 
                && res.municipioExecutivo.length > 0 &&                   
                res.municipioExecutivo.map((item, index) => {
                    const data = {id: index, municipio: item.municipio}
                    return municipioArray.push(data)
                })
                const docRelacionadoTipoArray = []
                res.documentoRelacionadoExecutivo !== undefined && Array.isArray(res.documentoRelacionadoExecutivo) 
                && res.documentoRelacionadoExecutivo.length > 0 &&                 
                res.documentoRelacionadoExecutivo.map((item, index) => {
                    const data = {id: index, docRelacionadoTipo: item.tipo_documento}
                    return docRelacionadoTipoArray.push(data)
                })
                const grandeAreaArray = []
                res.areaExecutico !== undefined && Array.isArray(res.areaExecutico) 
                && res.areaExecutico.length > 0 &&                  
                res.areaExecutico.map((item, index) => {
                    const data = {id: index, grandeArea: item.grande_area}
                    return grandeAreaArray.push(data)
                })
                const temaArray = []
                res.temaExecutivo !== undefined && Array.isArray(res.temaExecutivo) 
                && res.temaExecutivo.length > 0 &&                 
                res.temaExecutivo.map((item, index) => {
                    const data = {id: index, tema: item.tema}
                    return temaArray.push(data)
                })
                const indexacaoArray = []
                res.indexacaoExecutivo !== undefined && Array.isArray(res.indexacaoExecutivo) 
                && res.indexacaoExecutivo.length > 0 &&                 
                res.indexacaoExecutivo.map((item, index) => {
                    const data = {id: index, indexacao: item.indexacao}
                    return indexacaoArray.push(data)
                })
                const impactosJuridicoArray = []
                const estrategiaAtuacaoArray = []
                const acaoArray = []

                this.setState({
                    tipoDocumentoList: tipoDocumentoArray,
                    esferaList: esferaArray,
                    partidoList: partidoArray,
                    ufList: ufArray,
                    municipioList: municipioArray,
                    docRelacionadoTipoList: docRelacionadoTipoArray,
                    grandeAreaList: grandeAreaArray,
                    temaList: temaArray,
                    indexacaoList: indexacaoArray,
                    impactosJuridicoList: impactosJuridicoArray,
                    estrategiaAtuacaoList: estrategiaAtuacaoArray,
                    acaoList: acaoArray,
                    loading: false
                })
            })
            .catch(err => console.error(err))
    }

    filtrardarkBlueClientExecutivo = () => {
        const data = {
            tipo: 'executivo',
            tipoDocumento: this.state.tipoDocumento,
            esfera: this.state.esfera,
            numero: this.state.numero.trim(),
            ano: this.state.ano.trim(),
            ementa: this.state.ementa.trim(),
            autor: this.state.autor.trim(),
            partido: this.state.partido,
            uf: this.state.uf,
            municipio: this.state.municipio,
            dataPublicacao: this.state.dataPublicacao.trim(),
            docRelacionadoTipo: this.state.docRelacionadoTipo,
            docRelacionadoNum: this.state.docRelacionadoNum.trim(),
            docRelacionadoAno: this.state.docRelacionadoAno.trim(),
            grandeArea: this.state.grandeArea,
            tema: this.state.tema,
            idInterno: this.state.idInterno.trim(),
            atualizadoUltSCheck: this.state.atualizadoUltSCheck,
            indexacao: this.state.indexacao,
            impactoJuridico: this.state.impactoJuridico.label === 'Impacto Jurídico' ? {value:'' , label: ''} : this.state.impactoJuridico,
            estrategiaAtuacao: this.state.estrategiaAtuacao.label === 'Estratégia de Atuação' ? {value:'' , label: ''} : this.state.estrategiaAtuacao,
            acao: this.state.acao.label === 'Ação' ? {value:'' , label: ''} : this.state.acao,
            ultimasAcoesCheck: this.state.ultimasAcoesCheck,
            docNotRapidasCheck: this.state.docNotRapidasCheck,
            arquivamentoAtivoCheck: this.state.arquivamentoAtivoCheck,
            arquivamentoArquivadosCheck:  this.state.arquivamentoArquivadosCheck
        }

        // console.log('DATA AQUI ===>',data)

        window.localStorage.setItem('filtrodarkBlueClient', JSON.stringify(data))
        window.location.reload()
        window.location.href = '/proposicao-estadual-municipal'
    }

    onChangeTipoDocumento = tipoDocumento => {
        const data = {label: 'Tipo de Documento', value: tipoDocumento.label}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Tipo de Documento' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        this.setState({ tipoDocumento: tipoDocumento, arrayFiltroExecutivo: arrayFiltro})
    }
    
    onChangeEsfera = esfera => {
        const data = {label: 'Esfera', value: esfera.label}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Esfera' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        this.setState({ esfera: esfera, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangeNumero = (event) => {
        const data = {label: 'Número', value: event.target.value.replace(/\D/g,"")}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Número' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        this.setState({ numero: event.target.value.replace(/\D/g,""), arrayFiltroExecutivo: arrayFiltro })
        
    }
    onChangeAno = (event) => {
        const data = {label: 'Ano', value: event.target.value.replace(/\D/g,"")}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Ano' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        this.setState({ ano: event.target.value.replace(/\D/g,""), arrayFiltroExecutivo: arrayFiltro })   
    }

    onChangeEmenta = event => {
        const data = {label: 'Ementa', value: event.target.value}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Ementa' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        this.setState({ ementa: event.target.value, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangeAutor = event => {
        const data = {label: 'Autor', value: event.target.value}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Autor' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        this.setState({ autor: event.target.value, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangePartido = partido => {   
        const data = {label: 'Partido', value: partido.label}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Partido' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        this.setState({ partido: partido, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangeUF = uf => {    
        const data = {label: 'UF', value: uf.label}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'UF' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        this.setState({ uf: uf, arrayFiltroExecutivo: arrayFiltro })

        this.selectMunicipios(uf)
    }

    async selectMunicipios(uf) {
        await get('/darkBlueClient/' + uf.label)
        .then( res => {
            this.setState({ municipioList: res })
        })
    }
     

    onChangeMunicipio = municipio => {
        const data = {label: 'Município', value: municipio.label}
        const arrayFiltro = []
          this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Município' ? arrayFiltro.push(i) : null
          })
        arrayFiltro.push(data)
        this.setState({ municipio: municipio, arrayFiltroExecutivo: arrayFiltro})
    }

    onChangeDataPublicacao = event => {
        const date = moment(event.target.value).format('YYYY-MM-DD')      
        const data = {label: 'Data de Publicação', value:date}
        //console.log(data)
        const arrayFiltro = []
          this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Data de Publicação' ? arrayFiltro.push(i) : null
          })
        arrayFiltro.push(data)
        this.setState({ dataPublicacao: date, arrayFiltroExecutivo: arrayFiltro})
        
    }

    onChangeDocRelacionadoTipo = docRelacionadoTipo => {
        const data = {label: 'Documento Relacionado-Tipo', value: docRelacionadoTipo.label}
        const arrayFiltro = []
          this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Documento Relacionado-Tipo' ? arrayFiltro.push(i) : null
          })
        arrayFiltro.push(data)
        this.setState({ docRelacionadoTipo: docRelacionadoTipo, arrayFiltroExecutivo: arrayFiltro})
    }

    onChangeDocRelNum = event => {
        const data = {label: 'Documento Relacionado - N°', value: event.target.value}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Documento Relacionado - N°' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        this.setState({ docRelacionadoNum: event.target.value, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangeDocRelAno = event => {
        const data = {label: 'Documento Relacionado - Ano', value: event.target.value}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Documento Relacionado - Ano' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        this.setState({ docRelacionadoAno: event.target.value, arrayFiltroExecutivo: arrayFiltro })
    }


    onChangeGrandeArea = grandeArea => {
        const data = {label: 'Grande Área', value: grandeArea.label}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Grande Área' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        this.setState({ grandeArea: grandeArea, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangeTema = tema => {
        const data = {label: 'Tema', value: tema.label}
        const arrayFiltro = []
          this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Tema' ? arrayFiltro.push(i) : null
          })
        arrayFiltro.push(data)
        this.setState({ tema: tema, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangeIdInterno = event => {
        const data = {label: 'ID Interno', value: event.target.value}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'ID Interno' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        this.setState({ idInterno: event.target.value, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangeAtuSeteDias = event => {
        alert('Filtro Desativado')
        const data = {label: 'Atualizado nos últimos 7 dias', value: 'Selecionado'}
        const arrayFiltro = []
          this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Atualizado nos últimos 7 dias' ? arrayFiltro.push(i) : null
          })
        arrayFiltro.push(data)
        // this.setState({ atualizadoUltSCheck: event.target.checked, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangeIndexacao = indexacao => {   
        const data = {label: 'Indexação', value: indexacao.label}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Indexação' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        this.setState({ indexacao: indexacao, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangeImpactoJuri = impactoJuri => {
        alert('Filtro Desativado')
        const data = {label: 'Impacto Jurídico', value: impactoJuri.label}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Impacto Jurídico' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        // this.setState({ impactoJuri: impactoJuri, arrayFiltroExecutivo: arrayFiltro })  
    }

    onChangeEstrategiaAtu = estrategiaAtu => {
        alert('Filtro Desativado')
        const data = {label: 'Estratégia de Atuação', value: estrategiaAtu.label}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Estratégia de Atuação' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        // this.setState({ estrategiaAtu: estrategiaAtu, arrayFiltroExecutivo: arrayFiltro})
    }

    onChangeAcao = acao => {
        alert('Filtro Desativado')
        const data = {label: 'Ação', value: acao.label}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Ação' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        // this.setState({ acao: acao, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangeUltimasAcoes = event => {
        alert('Filtro Desativado')
        const data = {label: 'Ações da Atuação de Campo', value: 'Selecionado'}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Ações da Atuação de Campo' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        // this.setState({ ultimasAcoesCheck: event.target.checked, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangeDocNotificacaoRap = event => {
        alert('Filtro Desativado')
        const data = {label: 'Documento', value: 'Selecionado'}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Documento' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        // this.setState({ docNotRapidasCheck: event.target.checked, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangeArqAtivos = event => {
        alert('Filtro Desativado')
        const data = {label: 'Arquivamento Ativo', value: 'Selecionado'}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Arquivamento Ativo' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        // this.setState({ arquivamentoAtivoCheck: event.target.checked, arrayFiltroExecutivo: arrayFiltro })
    }

    onChangeArqArquivados = event => {
        alert('Filtro Desativado')
        const data = {label: 'Arquivamento Arquivados', value: 'Selecionado'}
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
                return i.label !== 'Arquivamento Arquivados' ? arrayFiltro.push(i) : null
        })
        arrayFiltro.push(data)
        // this.setState({ arquivamentoArquivadosCheck: event.target.checked, arrayFiltroExecutivo: arrayFiltro })
    }

    botaoLegislativoAtivo() {
        window.localStorage.setItem('filtro-selecao', 'legislativo')
        window.location.reload()
    }
    excluirSelecao(e, item, index) {
        const arrayFiltro = []
        this.state.arrayFiltroExecutivo.filter(i => {
            const array = i.label !== item.label ? arrayFiltro.push(i) : null
              return array
        })
        this.setState({ arrayFiltroExecutivo: arrayFiltro })
        this.limparFiltroUnico(item)
     
    }

    resetarFiltro() {
        this.setState({
            tipoDocumento:'',
            esfera:'',
            numero:'',
            ano:'',
            ementa:'',
            autor:'',
            partido:'',
            uf:'',
            municipio:'',
            dataPublicacao:'',
            docRelacionadoTipo:'',
            docRelacionadoNum:'',
            docRelacionadoAno:'',
            grandeArea:'',
            tema:'',
            atualizadoUltSCheck: false,
            indexacao:'',
            impactoJuridico:'',
            estrategiaAtuacao:'',
            acao:'',
            ultimasAcoesCheck: false,
            docNotRapidasCheck: false,
            arquivamentoAtivoCheck: false,
            arquivamentoArquivadosCheck: false,
            arrayFiltroExecutivo: []
        })
        document.getElementById('datapublicacaoInput').value = ''
        window.localStorage.removeItem('filtrodarkBlueClient')
    }
  
limparFiltroUnico(item) {
    
    if(item.label === 'Tipo de Documento') {
        this.setState({ tipoDocumento: {value: '', label: ''} })
    }
    else if(item.label === 'Esfera') {
        this.setState({ esfera: {value: '', label: ''} })
    }
    else if(item.label === 'Número') {
        this.setState({numero: ''})
    }
    else if(item.label === 'Ano') {
        this.setState({ano: ''})
    }
    else if(item.label === 'Ementa') {
        this.setState({ementa: ''})
    }
    else if(item.label === 'Autor') {
        this.setState({autor: ''})
    }
    else if(item.label === 'Partido') {
        this.setState({partido: {value: '', label: ''}})
    }
    else if(item.label === 'UF') {
        this.setState({ uf: {value: '', label: ''} })
    }
    else if(item.label === 'Município') {
        this.setState({ municipio:{value: '', label: ''} })
    }      
    else if(item.label === 'Data de Publicação') {
     
        this.setState({dataPublicacao :'' })
        document.getElementById("datapublicacaoInput").value = "";
      
        
    }     
    else if(item.label === 'Documento Relacionado-Tipo') {
        this.setState({ docRelacionadoTipo: {value: '', label: ''} })
    }    
    else if(item.label === 'Documento Relacionado - N°') {
        this.setState({docRelacionadoNum: ''})
    }
    else if(item.label === 'Documento Relacionado - Ano') {
        this.setState({docRelacionadoAno: ''})
    }
    else if(item.label === 'Grande Área') {
        this.setState({ grandeArea: {value: '', label: ''} })
    }
    else if(item.label === 'Tema') {
        this.setState({ tema: {value: '', label: ''} })
    }
    else if(item.label === 'ID Interno') {
        this.setState({idInterno: ''})
    }
    else if(item.label === 'Atualizado nos últimos 7 dias') {
        this.setState({ atualizadoUltSCheck: false })
    }
    else if(item.label === 'Indexações') {
        this.setState({ indexacao: {value: '', label: ''} })
    }
    else if(item.label === 'Impacto Jurídico') {
        this.setState({ impactoJuri: {value: '', label: ''} })
    }
    else if(item.label === 'Estratégia de Atuação') {
        this.setState({ estrategiaAtu: {value: '', label: ''} })
    }
    else if(item.label === 'Ação') {
        this.setState({ acao: {value: '', label: ''} })
    }
    else if(item.label === 'Ações da Atuação de Campo') {
        this.setState({ ultimasAcoesCheck: false })
    }
    else if(item.label === 'Documento') {
        this.setState({ docNotRapidasCheck: false })
    }
    else if(item.label === 'ArquivamentoAtivos') {
        this.setState({ arquivamentoAtivoCheck: false })
    }
    else if(item.label === 'ArquivamentoArquivados') {
        this.setState({ arquivamentoArquivadosCheck: false })
    }    
    
}
   
  render() {
      console.log(this.state.grandeAreaList)
    const heightScreen = window.innerHeight
 
    return this.state.loading ? <Loading /> :
        <Fragment>



<div className="col-sm-9 area-formulario-filtro">

<div className="row">
    <div className="col-12 titulo-filtro titulo-filtro_custom">
        Busca Municipais/Estaduais
    </div>

        <div className="col-md-6 margin-botao">
            <button className="btn-block botao-nao-ativo "  onClick={this.botaoLegislativoAtivo}>Legislativo</button>
        </div>
        <div className="col-md-6 margin-botao">
            <button className="btn-block botao-ativo">Executivo</button>
        </div>

        <div className="col-sm-6 form-margin-top">
            <label className="label-form-filtro">Tipo de Documento</label>
            <SelectFiltroProposicao 
                value={(this.state.tipoDocumento) ? this.state.tipoDocumento.id : null}
                onChange={tipoDocumento => this.onChangeTipoDocumento(tipoDocumento)}
                options={
                    this.state.tipoDocumentoList !== undefined && Array.isArray(this.state.tipoDocumentoList) && this.state.tipoDocumentoList.length > 0 ?
                    this.state.tipoDocumentoList.map(item => ({ value: item.id, label: item.tipo })):null}
                placeholder={this.state.tipoDocumento.label}
            />
        </div>
        <div className="col-sm-6 form-margin-top">
            <label className="label-form-filtro">Esfera</label>
            <SelectFiltroProposicao 
               value={(this.state.esfera) ? this.state.esfera : null}
               onChange={esfera => this.onChangeEsfera(esfera)}
               options={
                this.state.esferaList !== undefined && Array.isArray(this.state.esferaList) && this.state.esferaList.length > 0 ?                   
                this.state.esferaList.map(item => ({value: item.id, label: item.esfera })):null}
               placeholder='Esfera'
            />
        </div>
      
        <div className="col-md-4 mt-2">
            <label className="label-form-filtro">Número</label>
            <input 
                style={inputStyle}
                onChange={(event) => this.onChangeNumero(event)} 
                //onFocus={limparCampoNumero}
                placeholder="Escreva"
                value={this.state.numero}
                id="numeroInput"
                autoComplete="off"
                maxlength="20"
            />
        </div>
        <div className="col-md-4 mt-2">
            <label className="label-form-filtro">Ano</label>
            <input 
                style={inputStyle}
                onChange={(event) => this.onChangeAno(event)} 
                //onFocus={limparCampoNumero}
                placeholder="Escreva"
                value={this.state.ano}
                id="anoinput"
                autoComplete="off"
                maxlength="4"
            />
        </div>
        <div className="col-md-4 mt-2">
            <label className="label-form-filtro">Ementa</label>
            <input 
                style={inputStyle}                
                onChange={(event) => this.onChangeEmenta(event)} 
                //onFocus={limparCampoNumero}
                placeholder="Escreva"
                value={this.state.ementa}
                id="ementainput"
                autoComplete="off"
                maxlength="20"
            />
        </div>
        
        <div className="col-md-6 mt-2">
            <label className="label-form-filtro">Autor</label>
            <input 
                style={inputStyle}
                onChange={(event) => this.onChangeAutor(event)} 
                //onFocus={limparCampoNumero}
                placeholder="Escreva"
                value={this.state.autor}
                id="autorInput"
                autoComplete="off"
                maxlength="20"
            />
        </div>
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro">Partido</label>
            <SelectFiltroProposicao 
                value={(this.state.partido) ? this.state.partido.id : null}
                onChange={partido => this.onChangePartido(partido)}
                options={
                     this.state.partidoList !== undefined && Array.isArray( this.state.partidoList) &&  this.state.partidoList.length > 0 ?
                    this.state.partidoList.map(item => ({ value: item.id, label: item.partido })):null}
                placeholder={this.state.partido.label}
            />
        </div>

        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro">UF</label>
            <SelectFiltroProposicao 
               value={(this.state.uf) ? this.state.uf : null}
               onChange={uf => this.onChangeUF(uf)}
               options={
                 this.state.ufList !== undefined && Array.isArray( this.state.ufList) &&  this.state.ufList.length > 0 ?  
                this.state.ufList.map(item => ({value: item.id, label: item.uf })):null}
               placeholder={this.state.uf.label
               }
            />
        </div>
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro">Município</label>
            <SelectFiltroProposicao 
              value={(this.state.municipio) ? this.state.municipio : null}
              onChange={municipio => this.onChangeMunicipio(municipio)}
              options={
                this.state.municipioList !== undefined && Array.isArray(this.state.municipioList) && this.state.municipioList.length > 0 ?  
                this.state.municipioList.map(item => ({value: item.id, label: item.municipio })):null}
              placeholder={this.state.municipio.label
              }
            />
        </div>

        <div className="col-sm-3 mt-2">
            <label className="label-form-filtro ">Data de Publicação</label>
            <input 
                style={inputStyle}
                onChange={(event) => this.onChangeDataPublicacao(event)} 
                //onFocus={limparCampoNumero}
                // placeholder="Escreva"
                value={this.state.datapublicacao}
                id="datapublicacaoInput"
                autoComplete="off"
                type="date"
                maxlength="20"
            />
            {/* <SelectFiltroProposicao  placeholder='00/00/0000'/> */}
        </div>
        <div className="col-sm-3 mt-2">
            <label className="label-form-filtro">Documento Relacionado-Tipo</label>
            <SelectFiltroProposicao 
              value={(this.state.docRelacionadoTipo) ? this.state.docRelacionadoTipo : null}
              onChange={docRelacionadoTipo => this.onChangeDocRelacionadoTipo(docRelacionadoTipo)}
              options={
                this.state.docRelacionadoTipoList !== undefined && Array.isArray(this.state.docRelacionadoTipoList) && this.state.docRelacionadoTipoList.length > 0 ? 
                this.state.docRelacionadoTipoList.map(item => ({value: item.id, label: item.docRelacionadoTipo })):null}
              placeholder={this.state.docRelacionadoTipo.label
              }
            />
        </div>
        <div className="col-md-3 mt-2" >
            <label className="label-form-filtro">Documento Relacionado - N°</label>
            <input 
                style={inputStyle}
                onChange={(event) => this.onChangeDocRelNum(event)} 
                //onFocus={limparCampoNumero}
                placeholder="Escreva"
                value={this.state.docRelacionadoNum}
                id="docrelnumInput"
                autoComplete="off"
                maxlength="20"
            />
        </div>
        <div className="col-md-3 mt-2" >
            <label className="label-form-filtro">Documento Relacionado - Ano</label>
            <input 
                style={inputStyle}
                onChange={(event) => this.onChangeDocRelAno(event)} 
                //onFocus={limparCampoNumero}
                placeholder="Escreva"
                value={this.state.docRelacionadoAno}
                id="docrelanoInput"
                autoComplete="off"
                maxlength="20"
            />
        </div>
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro">Grande Área</label>
            <SelectFiltroProposicao            
                value={(this.state.grandeArea) ? this.state.grandeArea : null}
                onChange={grandeArea => this.onChangeGrandeArea(grandeArea)}
                options={
                    this.state.grandeAreaList !== undefined && Array.isArray(this.state.grandeAreaList) && this.state.grandeAreaList.length > 0 ?
                    this.state.grandeAreaList.map(item => ({value: item.id, label: item.grandeArea })):null}
                placeholder='Grande Área'
            />
        </div>
        <div className="col-md-6 mt-2">
            <label className="label-form-filtro">Tema</label>
            <SelectFiltroProposicao 
             value={(this.state.tema) ? this.state.tema : null}
             onChange={tema => this.onChangeTema(tema)}
             options={
                this.state.temaList !== undefined && Array.isArray(this.state.temaList) && this.state.temaList.length > 0 ? 
                this.state.temaList.map(item => ({value: item.id, label: item.tema })):null}
             placeholder='Tema'
            />
        </div>
        <div className="col-md-12 mt-2" >
            <label className="label-form-filtro">ID Interno</label>
            <input 
                style={{border: 'none',backgroundColor: 'transparent', borderBottom: '1px solid #C4C4C4',color: '#C4C4C4', marginTop: '10px',width: '100%',placeholder: 'white', outline: 'none'}}
                onChange={(event) => this.onChangeIdInterno(event)} 
                //onFocus={limparCampoNumero}
                placeholder="Escreva"
                value={this.state.idInterno}
                id="idinternoinput"
                autoComplete="off"
                maxlength="20"
            />
        </div>
        <div className="col-sm-6 mt-2">
          <label className="label-form-filtro" style={{fontSize:'0.8rem'}}>Atualizado nos últimos 7 dias</label>
          <FormGroup row  aria-label="position">                 
                          <FormControlLabel
                              control={<Checkbox style={{color: '#039EFB'}} id="ultimasAcoes" onChange={event => this.onChangeAtuSeteDias(event)} checked={this.state.atualizadoUltSCheck} />}
                              label="Sim"
                              value={this.state.atualizadoUltSCheck}
                              
                          />                 
          </FormGroup>   
        </div>
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro">Indexação</label>
            <SelectFiltroProposicao 
             value={(this.state.indexacao) ? this.state.indexacao : null}
             onChange={indexacao => this.onChangeIndexacao(indexacao)}
             options={
                this.state.indexacaoList !== undefined && Array.isArray(this.state.indexacaoList) && this.state.indexacaoList.length > 0 ? 
                this.state.indexacaoList.map(item => ({value: item.id, label: item.indexacao })):null}
            placeholder='Indexações'/>
        </div>
       
        <div className="col-sm-12 mt-2">
        <Typography variant="h6" gutterBottom style={{color: '#039EFB', marginTop: '20px'}}>
                    Atuação de Campo
        </Typography>  
        </div>  
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro"></label>
            <SelectFiltroProposicao 
             value={(this.state.impactoJuridico) ? this.state.impactoJuridico : null}
             onChange={impactoJuridico => this.onChangeImpactoJuri(impactoJuridico)}
             options={
                this.state.impactosJuridicoList !== undefined && Array.isArray(this.state.impactosJuridicoList) && this.state.impactosJuridicoList.length > 0 ? 
                this.state.impactosJuridicoList.map(item => ({value: item.id, label: item.impactoJuridico })):null}
             placeholder='Impacto juridico' 
             />
        </div>
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro"></label>
            <SelectFiltroProposicao 
             value={(this.state.estrategiaAtuacao) ? this.state.estrategiaAtuacao : null}
             onChange={estrategiaAtuacao => this.onChangeEstrategiaAtu(estrategiaAtuacao)}
             options={
                this.state.estrategiaAtuacaoList !== undefined && Array.isArray(this.state.estrategiaAtuacaoList) && this.state.estrategiaAtuacaoList.length > 0 ? 
                this.state.estrategiaAtuacaoList.map(item => ({value: item.id, label: item.estrategiaAtuacao })):null}
             placeholder='Estrategia de Atuação' 
             />
        </div>
        <div className="col-sm-6 mt-2">
            <label className="label-form-filtro"></label>
            <SelectFiltroProposicao 
             value={(this.state.acao) ? this.state.acao : null}
             onChange={acao => this.onChangeAcao(acao)}
             options={
                this.state.acaoList !== undefined && Array.isArray(this.state.acaoList) && this.state.acaoList.length > 0 ? 
                this.state.acaoList.map(item => ({value: item.id, label: item.acao })):null}
             placeholder='Ação' />
        </div>
        <div className="col-sm-6">
          <label className="label-form-filtro  mt-3" style={{fontSize:'0.8rem'}}>Ações da Atuação de Campo</label>
          <FormGroup row  aria-label="position">                 
                          <FormControlLabel
                             control={<Checkbox style={{color: '#039EFB'}} id="ultimasAcoes" onChange={event => this.onChangeUltimasAcoes(event)} checked={this.state.ultimasAcoesCheck} />}
                             label="Trazer somente últimas ações"
                             value={this.state.ultimasAcoesCheck}
                              
                          />                 
          </FormGroup>   
        </div>
        <div className="col-sm-6 mt-2">
          <label className="label-form-filtro" style={{fontSize:'0.8rem'}}>Documento</label>
          <FormGroup row  aria-label="position">                 
                          <FormControlLabel
                                 control={<Checkbox style={{color: '#039EFB'}} id="Documentos" onChange={event => this.onChangeDocNotificacaoRap(event)} checked={this.state.docNotRapidasCheck} />}
                                 label="Com Notificação Rápida"
                                 value={this.state.docNotRapidasCheck}
                              
                          />                 
          </FormGroup>   
        </div>

        <div className="col-sm-6 mt-2">
        <label className="label-form-filtro" style={{fontSize:'0.8rem'}}>Arquivamento</label>
          <FormGroup row  aria-label="position">                 
                  <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   > 
                        <FormControlLabel
                            control={<Checkbox style={{color: '#039EFB'}} value="1" id="selectTodas"onChange={event => this.onChangeArqAtivos(event)} checked={this.arquivamentoAtivoCheck} />}
                            label="Ativos"
                            value={this.state.arquivamentoAtivoCheck}
                        />
                    </Grid>
                    <Grid container item xs={12} sm={12} md={6} lg={3} xl={3}   > 
                        <FormControlLabel
                            control={<Checkbox style={{color: '#039EFB'}} value="2" id="selectSeguidas" onChange={event => this.onChangeArqArquivados(event)} checked={this.arquivamentoArquivadosCheck}/>}
                            label="Arquivados" 
                            value={this.state.arquivamentoArquivadosCheck}
                        />
                    </Grid>              
          </FormGroup>   
          </div>
          <div className="col-md-12 mb-5 mt-3 d-flex justify-content-center">
        
                
                <button className="botao-ativo mr-2" onClick={this.filtrardarkBlueClientExecutivo}>
                    <img
                        className="mr-2"
                        src={require('../../design/assets/images/lupa_buscar.svg')}
                        alt="anexo"
                    />
                    {' '}
                    Buscar
                </button>
        
                
                <button className="botao-nao-ativo" onClick={() => this.resetarFiltro()}>
                    <img
                        className="mr-2"
                        src={require('../../design/assets/images/resetar-filtro.png')}
                        alt="anexo"
                    />
                    {' '}
                    Resetar Filtro Executivo
                </button>


                
          </div>


          </div>

        </div>







        <div className="col-sm-3 area-pesquisa-filtro" style={{ height: heightScreen }}>
                        <div className="row padding-pesquisa">
                            <div className="col-12 titulo-pesquisa">
                                Sua pesquisa
                            </div>



                            {this.state.arrayFiltroExecutivo !== null && Array.isArray(this.state.arrayFiltroExecutivo) && this.state.arrayFiltroExecutivo.length > 0 ? this.state.arrayFiltroExecutivo.map((filtro, index) => {
                                    return (
                                        <div className="col-12 pesquisa-selecao" key={index}>
                                            <div className="tipo-pesquisa-selecao">
                                                {filtro.label}
                                            </div>
                                            <div className="descricao-pesquisa-selecao">
                                                <div className="row">
                                                    <div className="col-9 "style={{ fontSize: '12px',textAlign:'left',paddingTop: '6px' }} >
                                                    {filtro.value}
                                                    </div>
                                                    <div onClick={event => this.excluirSelecao(event, filtro, index)} className="col-3 area-excluir-selecao">
                                                        <div className="p area-excluir-selecao-x">x</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : null}
                             
                            
                            
                            <div className="col-12">
                                <button className="btn-block botao-resetar-filtro" onClick={() => this.resetarFiltro()}>
                                <img
                                    className="mr-2"
                                    src={require('../../design/assets/images/resetar-filtro.png')}
                                    alt="anexo"
                                    />{' '}
                                    Resetar Filtro
                                </button>
                            </div>


                        </div>
                    </div>



      </Fragment>
  }

}
export default FormExecutivo