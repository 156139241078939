import React, { Component } from 'react'
import UploadFile from '../componentes/forms/inputs/upload'
// import defaultImg from '../design/assets/images/aviso-vermelho.svg'
import { inject } from 'mobx-react'
import { put } from '../services/http'
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

@inject('uploadStore')

class EditModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: this.props.show || false,
            arquivo: [],
            anexos: this.props.anexos || [],
            dataHora: this.props.edit.data_acontecimento,
            acontecimento: this.props.edit.acontecimento,
            hasMeeting: this.props.edit.meetingTime !== null ? true : false,
            meetingDateTime: this.props.edit.meetingTime !== null ? moment(this.props.edit.meetingTime).format('YYYY/MM/DD HH:mm') : null
        }
    }
    componentWillReceiveProps(newProps) {
        if (newProps.show) this.setState({ show: newProps.show })
    }

    close(save) {
        this.setState({ show: !this.state.show }, () => this.props.onClose(this.state.show, save, this.props.item))
    }

    editText(event) {
        const acontecimento = event.target.value
        this.setState({ acontecimento: acontecimento })
    }

    dataEdit(event) {
        const dataAcontecimento = event.target.value || new Date()
        this.setState({ dataHora: new Date(dataAcontecimento) })
    }

    checkMeeting() {
        this.setState({
            hasMeeting: !this.state.hasMeeting,
            meetingDateTime: null
        })
    }

    mettingDate(event) {
        const dataHoraDaReuniao = event.target.value
        this.setState({
            meetingDateTime: dataHoraDaReuniao
        })
    }

    async edit() {
        const data = {
            acontecimento: this.state.acontecimento,
            data_acontecimento: this.state.dataHora ? this.state.dataHora : this.props.edit.data_acontecimento,
            id: this.props.edit.id,
            dataHoraReuniao: this.state.hasMeeting === false ? null : moment(this.state.meetingDateTime).format('YYYY-MM-DDTHH:mm'),
        }
        await put('/acontecimento', data)
            .then(res => {
                this.close(true)
                window.location.reload()
            })
    }

    render() {
        return (
            this.state.show ? this.content() : null
        )
    }
    content() {
        return (
            <div className="background-confirm-modal">

                <div className="confirm-modal">

                    <div style={{ width: '280px' }} className="modal-header text-center">
                        <div className="image w-100">
                            <h5>Editar Acontecimento</h5>
                        </div>
                    </div>

                    <div className="modal-body">
                        <label htmlFor="data-atual" className="label-data-acontecimento">Data</label>
                        <input
                            type="date"
                            id="data-atual"
                            className="form-control input-data-acontecimento"
                            defaultValue={moment(this.state.dataHora).format('YYYY-MM-DD')}
                            onChange={event => this.dataEdit(event)}
                        />
                        {this.props.edit.meetingTime !== null ?
                            <div className="row mt-3">
                                <div className="col-12 col-md-4">
                                    <div className="row" style={{ paddingLeft: "35px" }}>
                                        <div style={{ paddingTop: "8px" }}>
                                            <input className="form-check-input"
                                                style={{
                                                    width: "20px",
                                                    height: "20px"
                                                }}
                                                type="checkbox"
                                                id="hasMeeting"
                                                defaultChecked={this.props.edit.meetingTime !== null ? true : false}
                                                onChange={() => this.checkMeeting()}
                                            />
                                        </div>
                                        <label style={{ marginTop: "12px", marginLeft: "8px", fontSize: "15px" }}>
                                            Incluir na Agenda
                                        </label>
                                    </div>
                                </div>
                                {this.state.hasMeeting === true ?
                                    <div className="col-12 col-md-8">
                                        <input
                                            style={{ fontSize: "16px", width: "100%" }}
                                            type="datetime-local"
                                            id="data-atual"
                                            className="form-control input-data-acontecimento"
                                            defaultValue={moment(this.props.edit.meetingTime).add(3, 'hours').format('YYYY-MM-DDTHH:mm')}
                                            onChange={event => this.mettingDate(event)}

                                        />
                                    </div>
                                    :
                                    null}
                            </div>
                            :
                            null}
                        <textarea className="form-control mt-3" rows="6" onChange={event => this.editText(event)}>{this.props.edit.acontecimento.replace(/<(?!!br\s*\/?)[^>]+>/g, '').replace(/&nbsp;/g, '')}</textarea>

                        <div className="mt-2">
                            <UploadFile linkButton={true} modulo={'acontecimentos'} id_referencia={this.props.edit.id} onChange={anexo => this.setState({ anexos: [...this.state.anexos, ...anexo] })} />
                        </div>

                        {this.state.anexos !== undefined && Array.isArray(this.state.anexos) && this.state.anexos.length > 0 ?
                            this.state.anexos.map((anexo, index) => {
                                return (
                                    anexo.modulo === 'acontecimentos' && anexo.id_referencia === this.props.edit.id ?
                                        <div>
                                            <div className="col-12 mt-3 d-flex">
                                                <div style={{ padding: '10px', border: '1px solid #E1E8F2', color: 'var(--linkText)', width: '100%' }}>
                                                    <div className="row">
                                                        <div className="text-truncate row">
                                                            {/* <img src={require('../design/assets/images/clips-red.svg')} alt="anexo" /> */}
                                                            <div className=" ml-2" style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                            <span className=" ml-2 mt-1 Client-link text-truncate" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px", overflow: "hidden" }}>{anexo.nome}</span>
                                                        </div>
                                                        <div className="col-2 text-center delete-anexo">
                                                            <img onClick={() => {
                                                                this.props.uploadStore.removerAnexo(anexo);
                                                                const array = this.state.anexos
                                                                array.splice(index, 1)
                                                                this.setState({ anexos: array })
                                                            }} src={require('../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                )
                            }) : null}

                    </div>

                    <div className="modal-footer">
                        <div className="modal-footer-item">
                            <button type='button' onClick={() => this.close()}>{this.props.cancelButtonText || 'CANCELAR'}</button>
                        </div>
                        <div className="modal-footer-item">
                            <button type='button' onClick={() => this.edit()}>{this.props.confirmButtonText || 'EDITAR'}</button>
                            {/* <button type='button' onClick={() => this.edit()}>{this.props.confirmButtonText || 'EDITAR'}</button> */}
                            {/* <button type='button' onClick={() => this.close(true)}>{this.props.confirmButtonText || 'EDITAR'}</button> */}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default EditModal