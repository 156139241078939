import React, { Component } from 'react';
import Loading from '../../componentes/loading'
import { post } from '../../services/http'
import { inject, observer } from 'mobx-react'
import ModalSuccess from '../inteligenciaArtificial/modalSuccess'
import ModalCancelar from '../inteligenciaArtificial/modalCancelar'


@inject('authStore')
@observer

class TemaIA extends Component {

    state = {
        loading: false,
        dadosIA: [],
        checado: false,
        dadosTemaIA: [],
        dadosTemaIASelecionados: [],
        buscarTemas: '',
        idRelacionado: null,
        selecionarTema: [],
        buscarTemasId: null,
        message: '',
        abrirModalSucesso: false,
        abrirModalCancelar: false,
    }

    componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        this.getDadosIaTemas()
    }

    async getDadosIaTemas() {
        this.setState({ loading: true })
        const data = { busca: this.state.buscarTemas, id: this.state.buscarTemasId }

        await post('/ia/temas', data)
            .then(res => {
                this.setState({
                    selecionarTema: res.selecionarTema,
                    dadosTemaIA: res.dadosTema,
                    dadosTemaIASelecionados: res.dadosTemaRelacionados,
                    loading: false,
                    idRelacionado: res.id,
                    message: res.message
                })
            })
    }

    buscarTemas(value) {
        this.setState({ buscarTemas: value, buscarTemasId: null })
    }

    checkBoxItem(item) {
        const array = this.state.dadosTemaIASelecionados
        array.push(item)
        this.setState({ dadosTemaIASelecionados: array })
    }

    selecionarCheckbox(checked, item) {
        if (checked === true) {
            this.checkBoxItem(item)
        } else {
            this.deletarTema(item)
        }
    }

    deletarTema(item) {
        const array = this.state.dadosTemaIASelecionados
        const index = array.indexOf(item)
        array.splice(index, 1)
        this.setState({ dadosTemaIASelecionados: array })
    }

    async salvarTemas() {
        this.setState({ loading: true })
        const data = {
            salvar: this.state.dadosTemaIASelecionados,
            idRelacionado: this.state.idRelacionado
        }
        await post('ia/temas-salvar', data)
            .then(res => {
                this.getDadosIaTemas()
            }).then(this.setState({ abrirModalSucesso: true }))

    }

    async selecionaNovoTema(item) {
        await this.setState({ buscarTemasId: item.id, buscarTemas: '' })
        this.getDadosIaTemas()
    }

    async cancelarProcesso() {
        if (this.state.abrirModalCancelar === true) {
            await this.setState({ abrirModalCancelar: false })
        }
        await this.setState({ abrirModalCancelar: true })
    }

    render() {
        return (
            <div>
                {this.state.loading ? <Loading /> :
                    <div className="p-3" style={{ background: 'white' }}>
                        <div className='pt-3'>
                            <div className="input-group  icon-input pb-2">
                                <input className="form-control py-2 border-right-0 border" id="pesquisar-proposicao" type="search" autoComplete="off"
                                    value={this.state.buscarTemas}
                                    onKeyUp={(event) => { if (event.which === 13) { this.getDadosIaTemas() } }}
                                    // onKeyPress={(event) => this.clickEnter(event)}
                                    onChange={(event) => this.buscarTemas(event.target.value)} placeholder="Buscar Texto" aria-label="Pesquisar" aria-describedby="search" />
                                <span id="icone-pesquisar-proposicao" className="input-group-append">
                                    <button className="btn btn-outline-secondary border-left-0 border" type="button"
                                        onClick={() => { this.getDadosIaTemas() }}>
                                        <i className="icon-search-small"></i>
                                    </button>
                                </span>
                            </div>


                        </div>

                        {this.state.selecionarTema.length === 0 && this.state.dadosTemaIA.length === 0
                            ?
                            <div className='text-center'>
                                <h5 style={{ paddingBottom: '250px', paddingTop: '200px', }}>
                                    Busque por um tema para prosseguir com a relação.
                                </h5>
                            </div>
                            :
                            null
                        }

                        {this.state.message === '' ? null :
                            <div className="text-center p-3">
                                <h3 className="text-danger">{this.state.message}</h3>
                            </div>
                        }

                        <div className='row'>
                            <div className='col-12'>
                                {this.state.selecionarTema.length === 0 ? null :
                                    <h4 className='pt-3 pl-2 mb-3'> Selecione um tema para configurar Temas Relacionados:</h4>
                                }

                                {this.state.selecionarTema.length === 0 ?
                                    null
                                    :
                                    <table>
                                        <tbody>
                                            {
                                                this.state.selecionarTema.map((item, index) => {
                                                    return (
                                                        <tr key={index} className="table table-striped">
                                                            <td><input type="checkbox" onClick={() => this.selecionaNovoTema(item)} /></td>
                                                            <td>{item.descricao}</td>
                                                            <td>{item.casa === 1 ? 'Senado Federal' : 'Câmara dos Deputados'}</td>
                                                        </tr>

                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>

                        {this.state.dadosTemaIA.length === 0 ? null :
                            <div className='row'>
                                <div className='col-md-6'>
                                    <table className="table table-striped">
                                        <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                            <tr>
                                                <th style={{ width: '40px' }}></th>
                                                <th>Casa</th>
                                                <th>Tema</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.dadosTemaIA && this.state.dadosTemaIA.length > 0 ?

                                                this.state.dadosTemaIA.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <input type='checkbox' onChange={(e) => this.selecionarCheckbox(e.target.checked, item)} />
                                                            </td>
                                                            <td>{item.casa}</td>
                                                            <td>{item.tema}</td>
                                                        </tr>
                                                    );
                                                })

                                                : null

                                            }

                                        </tbody>
                                    </table>
                                    <div className='mt-3'>
                                        {/* window.location.reload() */}
                                        <button className='btn btn-outline-dark btn-block p-3'
                                            onClick={() => this.cancelarProcesso()}
                                        >CANCELAR</button>
                                    </div>
                                </div>

                                <div className='col-md-6'>

                                    <div className='p-3' style={{ border: '1px dashed gray' }}>

                                        <h4>Temas Selecionados</h4>

                                        {this.state.dadosTemaIASelecionados && this.state.dadosTemaIASelecionados.length > 0 ?

                                            this.state.dadosTemaIASelecionados.map((item, index) => {
                                                return (
                                                    <div key={index} className='p-2'>
                                                        <div className='row p-2' style={{ border: '1px solid gray', borderRadius: '5px', }}>
                                                            <div className='col-10'>
                                                                <div>
                                                                    Casa: {item.casa}
                                                                </div>
                                                                <div>
                                                                    Tema: {item.tema}
                                                                </div>
                                                            </div>

                                                            <div className='col-2'>
                                                                {item.salvo === false ? null :
                                                                    <span className="cursor-pointer pt-2" onClick={() => this.deletarTema(item)}>
                                                                        <img src={require('../../design/assets/images/trash-gray.svg')} alt='' />
                                                                    </span>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })

                                            : null

                                        }

                                        {this.state.dadosTemaIASelecionados.length === 0 ?

                                            <div>
                                                <div className='text-center' style={{ marginTop: '100px', marginBottom: '30px' }}>
                                                    <img src={require('../../design/assets/images/seta-selecionar.svg')} alt='' />
                                                </div>
                                                <div className='text-center' style={{ marginBottom: '100px' }}>
                                                    <h4>Selecione um ítem ao lado</h4>
                                                </div>

                                            </div>

                                            : null}

                                    </div>
                                    <div className='mt-3'>
                                        <button
                                            disabled={this.state.dadosTemaIASelecionados.length === 0 ? true : false}
                                            className={this.state.dadosTemaIASelecionados.length === 0 ? 'btn btn-secondary text-light btn-block p-3' : 'btn btn-danger btn-block p-3'}
                                            onClick={() => this.salvarTemas()}>
                                            SALVAR
                                        </button>
                                    </div>
                                </div>

                            </div>
                        }

                    </div>
                }


                {this.state.abrirModalSucesso === true ?
                    <div className='col-md-3 marginTopBotaoInt'>
                        <ModalSuccess
                            abrirModalSucesso={this.state.abrirModalSucesso}
                        />
                    </div>
                    : null
                }

                {this.state.abrirModalCancelar === true ?
                    <div className='col-md-3 marginTopBotaoInt'>
                        <ModalCancelar
                            abrirModalCancelar={this.state.abrirModalCancelar}
                        />
                    </div>
                    : null
                }


            </div>
        );
    }

}

export default TemaIA