import { action } from 'mobx'
// import { get } from '../services/http'

class PesquisarStore {
    @action
    async pesquisar({ descricao }) {
        try {
            //this.user = await post('usuario/login', { usuarioOuEmail, senha })
            console.log(descricao)
        } catch (error) {
            console.error(error)
        }
    }
}

export default new PesquisarStore()
