import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ProposicaoDefault from './proposicaoDefault'
import ProposicaoLista from './proposicaoLista'
import ProposicaoEspecifico from './proposicaoEspecifica'
import CadastroNovaProposicao from '../proposicaoNova/cadastroProposicao'
import EditarProposicao from '../proposicaoNova/editarProposicao'

export default () =>
    <Switch>
        <Route exact path="/proposicao" component={ProposicaoDefault} />
        <Route exact path="/proposicao/:id/editar-proposicao" component={EditarProposicao} />
        <Route exact path="/proposicao/lista" component={ProposicaoLista} />
        <Route exact path="/proposicao/:id/:link" component={ProposicaoEspecifico} />
        <Route exact path="/proposicao/cadastro-proposicao" component={CadastroNovaProposicao} />
       
    </Switch>