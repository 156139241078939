import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

@withRouter
class MediaItem extends Component {
  render() {
    const novaComissao = this.props.novaComissao
    // console.log('dentro do componente ===>',novaComissao)
    const mobile = window.innerWidth
    const info = this.props.info || []
    return (
      <React.Fragment>
        {mobile < 600 ?
        <div className={`media-item d-flex justify-content-between align-items-center ${this.props.border} text-left`}>

          {
            this.props.icon &&
            <div className={`mr-icon d-sm-flex w-50 align-items-end ${this.props.cssIcon ? this.props.cssIcon : ''}`}>
              {/* <i className={`mr-4 d-flex ${this.props.icon} ${this.props.size}`}></i> */}
              <div className="" onClick={() => this.props.route && this.props.history.push(this.props.route)}>
                <h6 className={`mb-0 cursor-pointer ${this.props.css}`}>
                  {this.props.title}
                  {this.props.status && <span className="badge badge-warning">{this.props.status.toUpperCase()}</span>}
                  {this.props.statusParlamentar && <span className="badge badge-dark">{this.props.statusParlamentar.toUpperCase()}</span>}
                </h6>

                {novaComissao !== undefined && novaComissao !== ''  ?
                  <div className="mt-2" style={{color: '#172b4d', fontWeight: 'bold'}}>
                    {novaComissao}
                  </div>
                :
                  <ul className="list-inline m-1">
                  {
                  info !== undefined && Array.isArray(info) && info.length > 0 ?
                    info.map((item, index) => {
                      return (
                        <li key={`index-${index}`} className="list-inline-item text-secondary mr-3 fs-18 ">
                          {
                            item.titulo ?
                              <span style={{fontSize: '0.9rem'}} className={`text-gray-600 font-weight-bold ${item.tituloCss || ''}`}>
                                {item.titulo}
                              </span> : null}
                          <span style={{fontSize: '0.9rem'}} className={this.props.valorCss || item.valorCss || ''}>{item.value}</span>
                        </li>
                      )
                    }):null
                  }
                </ul>
                }

               

              </div>
              {this.props.linkApensadas !== "" && this.props.linkApensadas !== null && this.props.linkApensadas !== undefined ?
                <div className="text-wrap">
                  <a id={this.props.idsApensadas} 
                    href={`/proposicao/${this.props.idsApensadas}/acao-preparatoria`}
                    className="fs-16 text-dark "><b>Apensada ao</b><span className="text-uppercase Client-link ml-1">{this.props.linkApensadas}</span></a>
                </div>
              : null} 
            </div>
          }

          <div className="w-32">{this.props.children}</div>
        </div>
        :
        <div className={`media-item d-flex justify-content-between align-items-center ${this.props.border}`}>

        {
          this.props.icon &&
          <div className={`mr-icon d-flex w-50 align-items-end ${this.props.cssIcon ? this.props.cssIcon : ''}`}>
            {/* <i className={`mr-4 d-flex ${this.props.icon} ${this.props.size}`}></i> */}
            <div className="" onClick={() => this.props.route && this.props.history.push(this.props.route)}>
              <span style={{fontSize:'23px'}} className={`mb-1 cursor-pointer ${this.props.css}`}>
                {this.props.title}
                {this.props.status && <span className="badge badge-warning ml-2">{this.props.status.toUpperCase()}</span>}
              </span>
              <span style={{fontSize:'16px'}} className={`mb-1 cursor-pointer ${this.props.css}`}>
                {this.props.statusParlamentar && <span className="badge badge-dark ml-2">{this.props.statusParlamentar.toUpperCase()}</span>}
              </span>
            

              {novaComissao !== undefined && novaComissao !== '' ?
                <div className="mt-2" style={{color: '#172b4d', fontWeight: 'bold'}}>
                  {novaComissao}
                </div>
              :
              <ul className="list-inline m-0">
                {
                  info !== undefined && Array.isArray(info) && info.length > 0 ?
                  info.map((item, index) => {
                    return (
                      <li key={`index-${index}`} className="list-inline-item text-secondary mr-3 fs-18 ">
                        {
                          item.titulo ?
                            <span className={`text-gray-600 font-weight-bold ${item.tituloCss || ''}`}>
                              {item.titulo}
                            </span> : null}
                        <span className={this.props.valorCss || item.valorCss || ''}>{item.value}</span>
                      </li>
                    )
                  }):null
                }
              </ul>
              }

            

              {this.props.linkApensadas !== "" && this.props.linkApensadas !== null && this.props.linkApensadas !== undefined ?
                // <span>{this.props.linkApensadas}</span>
                <div className="text-wrap">
                  <a id={this.props.idsApensadas} 
                    href={`/proposicao/${this.props.idsApensadas}/acao-preparatoria`}
                    className="fs-16 text-dark "><b>Apensada ao</b><span className="text-uppercase Client-link ml-1">{this.props.linkApensadas}</span></a>
                </div>
              : null} 
            </div>
          </div>

          
        }

        <div className="w-30">{this.props.children}</div>
        </div>
        }

      </React.Fragment>
    )
  }
}

export default MediaItem