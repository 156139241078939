import React, { Component } from 'react';
import { inject, observer } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { post } from '../../services/http'
import AlertaStore from '../../stores/alertaStore'
// import { NavLink } from 'react-router-dom'
import Loading from '../../componentes/loading';







const inputStyle = {
    border: 'none',
    marginLeft: "0px",
    backgroundColor: 'transparent',
    borderBottom: '1px solid #C4C4C4',
    color: '#000000',
    marginTop: '5px',
    width: '100%',
    outline: 'none'
};





@inject('authStore')
@observer

class Insights extends Component {
    state = {
        loading: false,
        user: JSON.parse(window.localStorage.getItem('user')),
        status: true,

        // listaGruposTemas: {},
        selectedOption: "",
        paginaAtual: 2,

        // Alterar Tema
        origemDemanda: this.props.match.params.Origin,
        idDoGrupo: 0,
        nomeDoGrupo: this.props.match.params.groupName,
        idDoTema: this.props.match.params.id,
        insertDescricaoGrupo: "",
        statusDescricaoCadastroGrupo: false,
        erroDescricaoTema: "",

    }


    componentWillMount() {
        this.buscarTemaPorId()
    }



    // ...................CHAMADA DO GRUPO ID ................

    buscarTemaPorId = async () => {
        const data = {
            data: {
                idTema: this.props.match.params.id
            }
        }
        await post(`/tema-proposicoes/tema/obter/id`, data)
            .then(resp => {
                // console.log(resp)
                this.setState({
                    loading: true
                })
                this.setState({
                    idDoGrupo: resp[0].id_tema_grupo,
                    idDoTema: resp[0].id,
                    insertDescricaoTema: resp[0].descricao,
                    statusDescricaoCadastroTema: resp[0].ativo
                })

                this.setState({
                    loading: false
                })
            })

    }



    handleChangeStatus = async (position) => {
        if (this.state.status === position) {
            this.setState({
                status: false
            })
        } if (this.state.status !== position) {
            this.setState({
                status: true
            })
        }
    }




    // .................EDITAR DE TEMA................

    alterarTema = async () => {

        if (this.state.insertDescricaoTema === "" || this.state.insertDescricaoTema === null || this.state.insertDescricaoTema === undefined) {
            this.setState({
                erroDescricaoTema: "Campo vazio. Favor preencher!"
            })
        } else {

            const data = {
                data: {
                    id: this.state.idDoTema,
                    descricao: this.state.insertDescricaoTema,
                    ativo: this.state.statusDescricaoCadastroTema
                }
            }

            post('/tema-proposicoes/tema/alterar', data)
                .then(response => {
                    if (response === undefined || response === null || response === "") {
                        this.setState({
                            erroDescricaoTema: "Tema ja cadastrado, escolha outro nome!"
                        })
                    } else {
                        AlertaStore.criar("Tema alterado com sucesso!")
                        if (this.state.origemDemanda === "lista") {
                            window.location.href = `/classificacao-tema/nome-do-grupo/${this.state.idDoGrupo}`
                        } else {
                            window.location.href = `/classificacao-tema/nome-do-tema/${this.state.idDoTema}/${this.state.nomeDoGrupo}`
                        }
                    }
                    // console.log(">>>>>>>>>>>>>>>>>>>> alteração", response)
                })

        }


    }

    cancel = async () => {
        console.log("origin:", this.state.origemDemanda)
        if (this.state.origemDemanda === "lista") {
            window.location.href = `/classificacao-tema/nome-do-grupo/${this.state.idDoGrupo}`
        } else {
            window.location.href = `/classificacao-tema/nome-do-tema/${this.state.idDoTema}/${this.state.nomeDoGrupo}`
        }
    }


    handleTextoInsertDescricaoTema = async (descricaoTexto) => {
        this.setState({
            insertDescricaoTema: descricaoTexto
        })
    }

    handleStatusInsertDescricaoTema = async (descricaoStatus) => {
        this.setState({
            statusDescricaoCadastroTema: descricaoStatus
        })
    }




    render() {

        // console.log(this.state.nomeDoGrupo)


        const StatusSwitch = withStyles({
            switchBase: {
                color: '#FFF',
                '&$checked': {
                    color: '#4CD964',
                },
                '&$checked + $track': {
                    backgroundColor: '#4CD964',
                },
            },
            checked: {},
            track: {},
        })(Switch);



        return (
            this.state.loading === true ? <div style={{ marginTop: '400px' }}><Loading /></div> :
                <div style={{ backgroundColor: "#f6f6f6", height: "100%" }}>
                    <div className=" bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                        <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                            <span><a style={{ fontSize: "10px", color: "var(--linkText)" }} href={'/classificacao-tema'}>CLASSIFICAÇÃO DE TEMAS</a></span> /
                            <span><a style={{ fontSize: "10px", color: "var(--linkText)" }} href={`/classificacao-tema/nome-do-grupo/${this.state.idDoGrupo}`}> GRUPO {this.state.nomeDoGrupo}</a></span> / EDITAR TEMA
                        </p>
                    </div>


                    <div className="container">



                        {/* ................head.................... */}
                        <div className='row' style={{ width: "100%" }}>
                            <div className='col-12 col-12'>
                                <p style={{ fontSize: "24px", fontWeight: "bold", paddingTop: "30px", color: "#1E0736" }}>
                                    Editar Tema
                        </p>
                            </div>
                        </div>


                        {/* ................Background White.................... */}
                        <div className='bg-white container' style={{ paddingLeft: "40px", paddingRight: "40px", paddingTop: "25px", paddingBottom: "25px" }}>
                            
                            <div style={{ fontSize: "16px", fontWeight: "400" }}>
                                Informações Gerais
                            </div>

                            {/* ................Formulários.................... */}

                            <div>
                                <div className="row">
                                    <div className="col-md-6 mt-3" >
                                        <label style={{ color: "var(--linkText)", fontSize:"12px" }}>Descrição</label>
                                        <input
                                            value={this.state.insertDescricaoTema}
                                            onChange={(e) => this.handleTextoInsertDescricaoTema(e.target.value)}
                                            name={'DescriçãoTema'}
                                            style={inputStyle}
                                            type="text"
                                            placeholder="Digite o nome desejado"
                                            autoComplete="off"
                                            maxLength="40"
                                        />

                                        <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{this.state.erroDescricaoTema}</span>
                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <div>
                                            <label style={{color: "var(--linkText)", fontSize:"12px"}}>Status</label>
                                        </div>
                                        <div className="row">
                                            <div>
                                                <StatusSwitch
                                                    checked={this.state.statusDescricaoCadastroTema}
                                                    onChange={(e) => this.handleStatusInsertDescricaoTema(e.target.checked)}
                                                    name="checkedA"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                            </div>
                                            <div style={{ alignItems: "center", display: "flex" }}>
                                                {
                                                    this.state.statusDescricaoCadastroTema === true ?
                                                        "Ativo"
                                                        :
                                                        "Inativo"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* .................... BOTOES ....................... */}
                        <div className="container">
                            <div className="row" style={{ float: "right" }}>
                                <div onClick={() => this.cancel()} className="cursor-pointer" style={{ padding: "10px", color: "var(--botaoPesquisar)", marginTop: "25px" }}>
                                    Cancelar
                                </div>
                                <div style={{ padding: "10px" }}>
                                    <button type="button" onClick={() => this.alterarTema()} className="btn" style={{ width: "100%", marginTop: "10px", padding: "15px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter" }} variant="outlined" >
                                        <img
                                            className="mr-2"
                                            style={{ width: "20px", marginTop: "-3px" }}
                                            src={require('../../design/assets/images/diskSave.svg')}
                                            alt="btn-pesquisar"
                                        />{' '}
                                    Salvar
                                </button>
                                </div>
                            </div>
                        </div>


                    </div>




                </div>
        )
    }
}

export default Insights