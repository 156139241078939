import React, { Component } from 'react'
import Loading from '../../componentes/loading'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import { post } from "../../services/http"
import Pagination from "react-js-pagination"
import { inject, observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'
// import AlertaStore from '../../stores/alertaStore'


const inputStyleA = {
    border: "none",
    backgroundColor: 'transparent',
    color: '#000000',
    width: '100%',
    placeholder: 'red',
    outline: 'none',
    height: "100%"
};



@inject('authStore')
@observer

class Insights extends Component {
    state = {
        loading: false,
        user: JSON.parse(window.localStorage.getItem('user')),
        status: true,
        getGeralLista: {},

        listaSubtemas: [],

        paginaAtual: 1,
        limite: 0,
        quantidade: 0,
        valorBusca: "",


        // ...........DADOS INICIAIS TEMA........
        idGrupoDesseTema: 0,
        nomeDoGrupoDesseTema: this.props.match.params.groupName,
        idDoTema: this.props.match.params.id,
        nomeDoTema: "",
        statusDoTema: false,

        // ............WIDGETS...........
        totalSubtemasCadastrados: "0",
        totalSubtemasAtivos: "0"

    }


    componentWillMount() {
        this.getListaSubtemasRender()
        this.chamadaDadosTemaEscolhido()
    }

    // ............. CHAMADA POPULAR GRUPO ECOLHIDO ...............

    async chamadaDadosTemaEscolhido() {

        const data = {
            data: {
                idTema: this.state.idDoTema
            }
        }

        post(`/tema-proposicoes/tema/obter/id`, data)
            .then(resp => {
                // console.log(">>>>>>>>>>>>>>>>>>>>", resp)
                this.setState({
                    nomeDoTema: resp[0].descricao,
                    statusDoTema: resp[0].ativo,
                    idGrupoDesseTema: resp[0].id_tema_grupo
                })
            })
    }







    // .............POPULAR WIDGET TEMA..........

    async getListaSubtemasRender() {
        this.setState({
            loading: true
        })

        const data = {
            data: {
                pagina: 1,
                idTema: this.state.idDoTema
            }
        }
        // console.log(data)
        await post(`/tema-proposicoes/subTema/obter`, data)
            .then(resp => {
                this.setState({
                    getGeralLista: resp,
                    listaSubtemas: resp.resultados,
                    limite: resp.itensPagina,
                    quantidade: resp.totalItens,
                    totalSubtemasCadastrados: resp.subtemasCadastrados,
                    totalSubtemasAtivos: resp.subtemasAtivos
                })
                this.setState({
                    loading: false
                })
            })
            .catch(err => err)
    }


    carregarPaginaNova = async (pagina) => {
        this.setState({ paginaAtual: pagina })
        await this.getListaSubtemas(pagina)
    }


    async getListaSubtemas(page) {
        this.setState({
            loading: true
        })

        const data = {
            data: {
                pagina: page,
                idTema: this.state.idDoTema
            }
        }
        await post(`/tema-proposicoes/subTema/obter`, data)
            .then(resp => {
                this.setState({
                    getGeralLista: resp,
                    listaSubtemas: resp.resultados,
                    limite: resp.itensPagina,
                    quantidade: resp.totalItens,
                    totalSubtemasCadastrados: resp.subtemasCadastrados,
                    totalSubtemasAtivos: resp.subtemasAtivos
                })
                this.setState({
                    loading: false
                })
            })
            .catch(err => err)
    }

    handleChangeStatus = async (position) => {
        if (this.state.status === position) {
            this.setState({
                status: false
            })
        } if (this.state.status !== position) {
            this.setState({
                status: true
            })
        }
    }

    selecionarBusca = async (e) => {
        if (await e === "") {
            this.getListaSubtemas(1)
        } else {
            this.setState({
                valorBusca: e
            })
        }

    }


    buscarSubtemas = async () => {
        // if (this.state.valorBusca === "" || this.state.valorBusca === null || this.state.valorBusca === undefined) {
        //     AlertaStore.erro("Campo de busca está em branco")
        // } else {
            const data = {
                data: {
                    descricao: this.state.valorBusca,
                    idTema: this.state.idDoTema
                }
            }
            post('/tema-proposicoes/subTema/busca', data)
                .then(resp => {
                    this.setState({
                        listaSubtemas: resp,
                    })
                    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>.", resp)
                })
        // }
    }


    limparBusca = () => {
        this.getListaSubtemas(1)
    }

    render() {

        // console.log("o", this.state.listaSubtemas)

        var alturaDeTela = ""
        switch (this.state.listaSubtemas.length) {
            case 0:
                alturaDeTela = "800px";
                break;
            case 1:
                alturaDeTela = "850px";
                break;
            case 2:
                alturaDeTela = "850px";
                break;
            case 3:
                alturaDeTela = "950px";
                break;
            case 4:
                alturaDeTela = "1050px";
                break;
            case 5:
                alturaDeTela = "1150px";
                break;
            case 6:
                alturaDeTela = "1250px";
                break;
            case 7:
                alturaDeTela = "1350px";
                break;
            case 8:
                alturaDeTela = "1450px";
                break;
            case 9:
                alturaDeTela = "1550px";
                break;
            case 10:
                alturaDeTela = "1650px";
                break;
            default:
                console.log('ALtura de Tela');
        }

        const StatusSwitch = withStyles({
            switchBase: {
                color: '#FFF',
                '&$checked': {
                    color: '#4CD964',
                },
                '&$checked + $track': {
                    backgroundColor: '#4CD964',
                },
            },
            checked: {},
            track: {},
        })(Switch);



        return (
            this.state.loading ? <div style={{ marginTop: '400px' }}><Loading /></div> :
                <div style={{ backgroundColor: "#f6f6f6", height: `${alturaDeTela}` }}>
                    <div className=" bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                        <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                            <span><a style={{ fontSize: "10px", color: "var(--linkText)" }} href={'/classificacao-tema'}>CLASSIFICAÇÃO DE TEMAS / </a></span> <span><a style={{ fontSize: "10px", color: "var(--linkText)" }} href={`/classificacao-tema/nome-do-grupo/${this.state.idGrupoDesseTema}`}> GRUPO {this.state.nomeDoGrupoDesseTema} </a></span> / TEMA {this.state.nomeDoTema}
                        </p>
                    </div>

                    <div className="container">

                        {/* ................NOME DO TEMA.................... */}
                        <div className='row' style={{ width: "100%" }}>
                            <div className='col-md-6 col-12'>
                                <p style={{ fontSize: "24px", fontWeight: "bold", paddingTop: "30px", color: "#1E0736" }}>
                                    {this.state.nomeDoTema}
                                </p>
                            </div>

                        </div>



                        <div style={{ margin: "15px" }}>

                            <div className="row" style={{ borderRadius: "6px", backgroundColor: "#ECECEC", height: "70px", alignItems: "center", display: "flex" }}>
                                <div className="col-md-10 col-6" style={{ alignItems: "center", display: "flex" }}>
                                    <div className="row" style={{ paddingLeft: "20px" }}>
                                        <StatusSwitch
                                            checked={this.state.statusDoTema}
                                            name="checkedA"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                        <div style={{ alignItems: "center", display: "flex" }}>
                                            {this.state.statusDoTema === true ? "Ativo" : "Inativo"}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    < div className="row" style={{ marginTop: "5px", marginBottom: "3px" }}>
                                        <div style={{ textAlign: "end", marginRight: "10px", }}>
                                            <NavLink to={`/classificacao-tema/editar-tema/${this.state.idDoTema}/${this.state.nomeDoGrupoDesseTema}/atalho`}>
                                                <div className="cursor-pointer" style={{ backgroundColor: "#BDA0CB", borderRadius: "3px", padding: "10px", width: "45px" }}>
                                                    <i className='icon-editar-gray' style={{ color: '#4B1196', marginRight: "3px" }} ></i>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div style={{ textAlign: "end", marginleft: "0px" }}>
                                            <NavLink to={`/classificacao-tema/cadastro-nova-classificacao`}>
                                                <button type="button" className="btn" style={{ padding: "10px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter" }} variant="outlined" >
                                                    Cadastrar
                                            </button>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        {/* .................WIDGETS COUNTS................. */}

                        <div className="row" style={{ height: "140px" }}>
                            <div className="col-12">
                                <div style={{ backgroundColor: "white", height: "120px", borderRadius: "7px" }}>
                                    <div style={{ color: "#9B9B9B", fontSize: "12px", fontWeight: "bold", paddingLeft: "20px", paddingTop: "20px" }}>
                                        SUBTEMAS
                                    </div>
                                    <div className="row" style={{ paddingLeft: "20px", paddingTop: "10px" }}>
                                        <div className="col-md-6" style={{ borderRight: "1px solid #CACACA" }}>
                                            <div style={{ fontWeight: "bold", fontSize: "22px" }}>
                                                {this.state.totalSubtemasCadastrados}
                                            </div>
                                            <div style={{ color: "#9B9B9B" }}>
                                                Cadastrados
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div style={{ fontWeight: "bold", fontSize: "22px" }}>
                                                {this.state.totalSubtemasAtivos}
                                            </div>
                                            <div style={{ color: "#9B9B9B" }}>
                                                Ativos
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* ................Background White.................... */}
                        <div className='bg-white container' style={{ paddingLeft: "40px", paddingRight: "40px", paddingTop: "25px", paddingBottom: "25px" }}>
                            
                            <div style={{ marginBottom: "20px" }}>
                                <h5 style={{ color: "var(--titulosTemas)" }}>
                                    Lista de Subtemas
                                </h5>
                            </div>

                            <div style={{ width: "100%", marginBottom: "40px" }}>
                                <div className="row" style={{ border: "1px solid lightgray", height: "60px", backgroundColor: "white", paddingLeft: "5px", paddingRight: "5px", borderRadius: "7px" }}>
                                    <div className="col-11">
                                        <input type="search" autoComplete="off"
                                            style={inputStyleA}
                                            onChange={(event) => this.selecionarBusca(event.target.value)}
                                            onKeyPress={() => this.buscarSubtemas()}
                                            placeholder="Buscar Subtema" aria-label="Pesquisar"
                                            aria-describedby="search"
                                        />
                                    </div>
                                    <div onClick={() => this.buscarSubtemas()} className="col-1 text-right cursor-pointer" style={{ alignItems: "center", display: "flex", justifyContent: "center" }} >
                                        <img style={{ width: "30px" }} src={require('../../design/assets/images/icon-search.png')} alt="excluir anexo" />
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.listaSubtemas.length === 0 ?
                                    <div className="row">
                                        <div className="col-12 text-right">
                                            <p onClick={() => this.limparBusca()} style={{ cursor: "pointer", fontSize: "10px", color: "var(--linkText)", marginTop: "-20px" }}>Limpar Busca</p>
                                        </div>
                                        <div className="col-12 text-center">
                                            <h4 style={{ marginTop: "20px" }}>Não encontramos nenhuma referência para a sua busca</h4>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            {this.state.listaSubtemas.map((item, index) => {
                                return (
                                    <div key={index} className="row" style={{ border: "1px solid lightgray", padding: "10px", borderRadius: "7px", marginTop: "20px" }}>
                                        <div className="col-md-9 col-6" style={{ alignItems: "center", display: "flex" }}>
                                            {/* <NavLink to={`/classificacao-tema/nome-do-grupo/${item.id}`}> */}
                                                <p style={{ color: "black", padding: "0px", margin: "0px" }}>{item.descricao}</p>
                                            {/* </NavLink> */}
                                        </div>
                                        <div className="col-md-3 col-6" style={{ paddingLeft: "100px" }}>
                                            <div className="row">
                                                <div className="col-md-8 col-6">
                                                    <div className="row">
                                                        <StatusSwitch
                                                            checked={item.ativo}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                        <div style={{ alignItems: "center", display: "flex" }}>
                                                            {item.ativo === true ? "Ativo" : "Inativo"}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-6" style={{ alignItems: "center", display: "flex" }}>
                                                    <NavLink to={`/classificacao-tema/editar-subtema/${item.id}/${this.state.nomeDoGrupoDesseTema} /${this.state.nomeDoTema}/${item.id_prioridade}`} >
                                                        <div className="cursor-pointer" style={{ border: "1px solid lightgray", borderRadius: "3px", paddingTop: "7px", paddingLeft: "7px", paddingRight: "7px" }}>
                                                            <i className='icon-editar-gray' style={{ color: '#9B9B9B' }} ></i>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                )
                            })

                            }



                            <div style={{ marginBottom: "-40px" }}>
                                <div className="d-flex justify-content-center paginacao-citacoes">
                                    <Pagination
                                        activePage={this.state.paginaAtual}
                                        itemsCountPerPage={this.state.limite}
                                        totalItemsCount={parseInt(this.state.quantidade)}
                                        pageRangeDisplayed={10}
                                        hideFirstLastPages={true}
                                        onChange={this.carregarPaginaNova}
                                        prevPageText={<div className="prev-page"><img src={require('../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                                        nextPageText={<div className="next-page"><img src={require('../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                                    />
                                </div>
                            </div>





                        </div>

                    </div>




                </div>
        )
    }
}

export default Insights