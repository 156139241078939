import axios from 'axios'; 
import https from "https";

let http = axios.create({ 
    httpsAgent: new https.Agent({rejectUnauthorized: false}),
    baseURL: process.env.REACT_APP_API_ARQUIVOS || 'https://127.0.0.1:4001', 
    timeout: 60 * 4 * 1000 
});

http.interceptors.response.use(response => response, error => Promise.reject(error))

const createKey = filename => {
    const parsed = filename.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z.])/g, '');
    return `${(new Date()).getTime()}-${parsed}`
}
const upload = async (data) => {

    //console.log('<<<<<<XXXX ARQUIVO XXXX>>>>>',data.tipo_arquivo.startsWith('video'))

    if(data.tipo_arquivo.startsWith('video') === true){       
        return true
    }else{ 
        const { original, Key, modulo, id_referencia, id_parlamentar } = data
        return await http.post('/arquivo/upload', data)
        .then(res => {return {nome: original,arquivo: Key,modulo, id_referencia, id_parlamentar}})
        .catch(err => {return {nome: original,arquivo: Key,modulo, id_referencia, id_parlamentar}})
    }

   
} 

const blobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result.split(',')[1])
    reader.onerror = reject
    reader.readAsDataURL(blob)
})
 
export const uploadFromFileInput = async (event, modulo, id_referencia, id_parlamentar) =>
    Promise.all(Array.from(event.target.files)
        .map(async file => {
            console.log('teste-id_parlamentar',id_parlamentar)
            return await upload({
                    tipo_arquivo: file.type,
                    original: file.name,
                    Key: createKey(file.name),
                    modulo: modulo,
                    id_referencia: id_referencia,
                    id_parlamentar: id_parlamentar,
                    Body: new Buffer(await blobToBase64(file), 'base64')})
        }))