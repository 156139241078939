import React, { Component } from 'react';
import Loading from '../../../componentes/loading';
import Grafico from '../graficos/graficoProposicaoPorCasaLegislativa'
import moment from 'moment-timezone';
// import Pagination from "react-js-pagination";
import { get } from "../../../services/http"
import { inject, observer } from 'mobx-react'

moment.tz.setDefault('UTC');

@inject('authStore')
@observer

class ProposicaoPorCasaLegislativa extends Component {
    state = {
        loading: true,
        dataInicio: '',
        tiposQuantidade: [],
        resultadoRelatorioCamara: [],
        resultadoRelatorioSenado: [],
        paginaAtuaCamara: 1,
        limiteCamara: 3,
        quantidadeCamara: 0,
        paginaAtuaSenado: 1,
        limiteSenado: 3,
        quantidadeSenado: 0,
        totalQuantidadeCamara: 0,
        totalQuantidadeSenado: 0,
        totalQuantidadeCongressoNacional: 0,
        dataIni: '',
        dataFim: '',
    }

    async componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        await this.getDataRelatorio(1)

        this.setState({
            dataInicio: moment(this.props.match.params.dtIni).format('DD/MM/YYYY'),
            dataFim: moment(this.props.match.params.dtFim).format('DD/MM/YYYY'),
        })
    }

    async getDataRelatorio(page) {
        const dtIni = this.props.match.params.dtIni
        const dtFim = this.props.match.params.dtFim
        await get(`/relatorio/proposicoes/casa/${dtIni}/${dtFim}`)
            .then(res => {
                const dados = res || []
                // console.log(res)
                this.setState({
                    tiposQuantidade: dados.tabela,
                    totalQuantidadeCamara: dados.grafico.totalCamara,
                    totalQuantidadeSenado: dados.grafico.totalSenado,
                    totalQuantidadeCongressoNacional: dados.grafico.totalCongresso,
                    loading: false
                })
            })
    }

    // carregarPaginaNovaCamara() {

    // }
    // carregarPaginaNovaSenado() {

    // }

    // imprimir() {

    //     var printContent = document.getElementById('proposicao-por-casa-legislativa');
    //     var windowUrl = 'about:blank';
    //     var uniqueName = new Date();
    //     var windowName = 'Print' + uniqueName.getTime();

    //     var printWindow = window.open(windowUrl, windowName, 'left=50000,top=50000,width=800,height=600');
    //     printWindow.document.body.innerHTML = printContent.innerHTML;
    //     var strContent = "<html><head>";
    //         strContent = strContent + "<link rel=\"stylesheet\" type=\"text/css\" charset=\"UTF-8\" href=\"https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css\" />";
    //         strContent = strContent + "<link rel=\"stylesheet\" type=\"text/css\" href=\"https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css\" />";
    //         strContent = strContent + "</head><body>";
    //         strContent = strContent + "<div style='width:100%;'>";
    //         strContent = strContent + document.getElementById('proposicao-por-casa-legislativa').innerHTML;
    //         strContent = strContent + "</div>";
    //         strContent = strContent + "</body>";
    //         printWindow.document.write(strContent);
    //         printWindow.print();
    //         printWindow.document.close();
    //         printWindow.focus();
    //         return false;
    // }

    imprimir() {
        window.print()
    }

    render() {

        // const dominio = window.localStorage.getItem('dominio')
        // var logo = null
        // switch (dominio) {
        //     case 'Client':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'blueClient':
        //         logo = 'icon-purpleClient corblueClient'
        //         break

        //     case 'darkBlueClient':
        //         logo = 'icon-logo_darkBlueClient cordarkBlueClient'
        //         break

        //     case 'dev':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'localhost':
        //         logo = `icon-purpleClient cordarkBlueClient`
        //         break

        //     default:
        //         logo = 'icon-logo-Client corClient'
        // }

        const dados = [
            this.state.totalQuantidadeCamara,
            this.state.totalQuantidadeSenado,
            this.state.totalQuantidadeCongressoNacional
        ]
        const dataDaPagina = `Período de Apresentação: ${this.state.dataInicio} a ${this.state.dataFim}`

        return (
            this.state.loading ? <Loading /> :

                <div className="container paddingGeralXS">

                    <div id="non-printable" className="mt-5">
                        <button className="btn btn-danger" onClick={this.imprimir}>
                            <img
                                src={require('../../../design/assets/images/impressora.svg')}
                                alt="anexo"
                            />
                        </button>
                    </div>

                    {/* <div>
                        <button onClick={this.imprimir}>IMPRIMIR</button>
                    </div> */}

                    <div id="proposicao-por-casa-legislativa" className="pt-5">
                        <div className="row">
                        <div className="col-1" >
                        <a href="/">
                                <div className=" logoMenuRelatorios text-center"></div>
                                    {/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
                                </a>
                            </div>
                            <div className="col-11">
                                <div>
                                    <p className="tituloRelatorio">
                                        Relatório
                                        </p>
                                </div>
                                <div>
                                    <p className="descricaoTituloRelatorio">
                                        Quantidade total de proposições, distribuídas em cada Casa Legislativa
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 text-center periodoFiltro">
                                <div className="">
                                    {dataDaPagina}
                                </div>
                            </div>
                        </div>


                        {/* GRAFICO  */}

                        <div className="row">

                            <div className="col-md-6 divGrafico" id="align-grafico">
                                <Grafico
                                    dados={dados}
                                />
                            </div>
                            <div className="col-md-6 align-self-center divIndice" id="divAlignIndice">
                                <div className="row">

                                    <div className="corIndiceCamara"></div>
                                    <div>
                                        <p className="textoIndiceCamara">
                                            Câmara
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceCamara">
                                            {this.state.totalQuantidadeCamara}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceSenado"></div>
                                    <div>
                                        <p className="textoIndiceSenado">
                                            Senado
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceSenado">
                                            {this.state.totalQuantidadeSenado}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceCongresso"></div>
                                    <div>
                                        <p className="textoIndiceCongresso">
                                            Congresso Nacional
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceCongresso">
                                            {this.state.totalQuantidadeCongressoNacional}
                                        </p>
                                    </div>

                                </div>
                            </div>

                        </div>

                        {/* GRAFICO CAMARA */}


                        <div className="mt-5">
                            <p className="tituloTabelaCamara">
                                Câmara
                            </p>
                        </div>


                        <table className="table table-striped">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleCamara">
                                <tr>
                                    <th className="headTipoCamara">Tipo de Proposição</th>
                                    <th className="headQuantCamara">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                    this.state.tiposQuantidade.map((item, index) => {
                                        return (
                                            item.casa === 'CD' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoCamara">{item.tipo}</td>
                                                    <td className="dadosColunaQuantCamara">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualCamara}
                            itemsCountPerPage={this.state.limiteCamara}
                            totalItemsCount={parseInt(this.state.quantidadeCamara)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaCamara}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}

                        {/* GRAFICO SENADO */}


                        <div className="mt-5">
                            <p className="tituloTabelaSenado">
                                Senado
                            </p>
                        </div>
                        <table className="table table-striped">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleSenado">
                                <tr>
                                    <th className="headTipoSenado">Tipo de Proposição</th>
                                    <th className="headQuantSenado">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                    this.state.tiposQuantidade.map((item, index) => {
                                        return (
                                            item.casa === 'SF' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoSenado">{item.tipo}</td>
                                                    <td className="dadosColunaQuantSenado">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualSenado}
                            itemsCountPerPage={this.state.limiteSenado}
                            totalItemsCount={parseInt(this.state.quantidadeSenado)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaSenado}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}

                        {/* GRAFICO CONGRESSO NACIONAL */}


                        <div className="mt-5">
                            <p className="tituloTabelaCongresso">
                                Congresso Nacional
                            </p>
                        </div>
                        <table className="table table-striped table-responsive-sm">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleCongresso">
                                <tr>
                                    <th className="headTipoCongresso">Tipo de Proposição</th>
                                    <th className="headQuantCongresso">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                    this.state.tiposQuantidade.map((item, index) => {
                                        return (
                                            item.casa === 'CN' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoCongresso">{item.tipo}</td>
                                                    <td className="dadosColunaQuantCongresso">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualSenado}
                            itemsCountPerPage={this.state.limiteSenado}
                            totalItemsCount={parseInt(this.state.quantidadeSenado)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaSenado}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}


                    </div>

                </div>

        )
    }
}

export default ProposicaoPorCasaLegislativa