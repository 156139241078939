import React, { Component } from 'react';
import Loading from '../../componentes/loading';
import { inject, observer } from 'mobx-react';
import { NavLink, Link } from 'react-router-dom';
import Seguir from '../../componentes/seguir';
import Pagination from "react-js-pagination";
import { post, get } from '../../services/http'
import moment from 'moment-timezone';
import { posicionamentosClient } from "../../enums/posicionamento";
import FormatInput from '../../componentes/forms/inputs/formatInput'
import ClientCheckboxWithSelect from '../../componentes/forms/basic/clientSelectWithSearch'
// modal
import FiltroProposicao from './modal/filtroAvancadoProposicao';
import AccordionMobileListProp from "../../componentes/accordionMobilePropList"
//import FiltroProposicaoMobile from './modal/filtroProposicaoMobile';
import { buscarListaPrioridades } from "../../../src/enums/prioridades"

moment.tz.setDefault('UTC');

@inject('alertaStore', 'authStore')
@observer

class ProposicaoLista extends Component {
    state = {
        loading: true,
        botaoLimparFiltro: false,
        limite: 20,
        listaProposicao: [],
        quantidade: 0,
        message: '',
        paginaAtual: 1,
        autores: [],
        temas: [],
        descricao: '',
        relatores: [],
        casaSelected: [
            { value: 1, label: 'Federal' },
            { value: 2, label: 'Estadual' },
            { value: 3, label: 'Municipal' },
        ],
        casaSelectedFiltro: "",
        uf: "",
        listaCasas: [],
        estadoLista: [],
        casaSelecionada: [],
        ClientPrioridades: [],
        // tamanhoBotaoSeguir: "420px"
    }

    async componentDidMount() {
        const filtroInput = window.localStorage.getItem('filtroInput')
        const filtroGrafico = window.localStorage.getItem('filtroGrafico')
        const filtroProposicao = window.localStorage.getItem('filtroProposicao')
        const filtroPendencia = window.localStorage.getItem('filtroPendencia')
        const filtroSelectedCasa = window.localStorage.getItem('filtroSelectedCasa')
        this.obterEstados()

        //chamando a lista de prioridades do banco
        buscarListaPrioridades()
            .then(res => {
                this.setState({
                    ClientPrioridades: res,
                })
            })





        if (filtroInput !== null || filtroGrafico !== null || filtroProposicao !== null || filtroPendencia !== null || filtroSelectedCasa !== null) {
            await this.setState({ botaoLimparFiltro: true })
            this.listarProposicoes(1)
        } else {
            window.location.href = '/proposicao'
        }
    }

    async buscarRelatores(proposicoes) {
        await post('/proposicao/obter-relatores', proposicoes)
            .then(res => {
                const dados = res || []
                this.setState({ relatores: dados })
            })
    }

    async listarProposicoes(pagina, actions) {
        // console.log("cheguei")

        const data = {
            limite: this.state.limite,
            offset: pagina === 1 ? 0 : (pagina - 1) * this.state.limite,
            filtroInput: window.localStorage.getItem('filtroInput') !== null ? window.localStorage.getItem('filtroInput') : null,
            filtroProposicao: window.localStorage.getItem('filtroProposicao') !== null ? JSON.parse(window.localStorage.getItem('filtroProposicao')) : null,
            filtroGrafico: window.localStorage.getItem('filtroGrafico') !== null ? JSON.parse(window.localStorage.getItem('filtroGrafico')) : null,
            filtroPendencia: window.localStorage.getItem('filtroPendencia') !== null ? JSON.parse(window.localStorage.getItem('filtroPendencia')) : null,
            filtroCasas: window.localStorage.getItem('filtroSelectedCasa') !== null ? JSON.parse(window.localStorage.getItem('filtroSelectedCasa')) : null
            // filtroCasas: 1,
            // filtroCasa: null

        }
        //console.log("data: ",data)


        await post('proposicao/lista-paginada', data)
            .then(res => {
                //console.log("res", res.dados.length)
                if (res.dados && res.dados.resultados && res.dados.resultados.length > 0) {
                    // if(res.dados.length > 0) {
                    this.setState({
                        listaProposicao: res.dados.resultados,
                        quantidade: res.dados.quantidade,
                        message: res.message,
                        autores: res.autores,
                        temas: res.temas,
                        loading: false
                    })
                } else {
                    window.localStorage.removeItem('filtroInput')
                    window.localStorage.removeItem('filtroProposicao')
                    window.localStorage.removeItem('filtroGrafico')
                    window.localStorage.removeItem('filtroPendencia')
                    window.localStorage.removeItem('filtroSelectedCasa')
                    window.localStorage.setItem('no-info', true)
                    window.location.href = '/proposicao'
                }
                this.buscarRelatores(res.dados.resultados)
            })



    }

    async limparFiltroLocaStorage() {
        await window.localStorage.removeItem('filtroProposicao')
        await window.localStorage.removeItem('filtroInput')
        await window.localStorage.removeItem('filtroGrafico')
        await window.localStorage.removeItem('filtroPendencia')
        await window.localStorage.removeItem('filtroSelectedCasa')
        await window.localStorage.setItem('prop_deleted', false)
        await window.localStorage.removeItem('prop_deleted_decricao')
        window.location.href = '/proposicao'
    }

    carregarPaginaNova = async pagina => {
        this.setState({ paginaAtual: pagina, loading: true })
        await this.listarProposicoes(pagina)
        // console.log(pagina)
    }

    async aplicarFiltroInput(event) {
        // console.log(event)
        await this.setState({ descricao: event.target.value })
    }

    async filtroInputSelected() {
        const proposicao_deleted = await JSON.parse(window.localStorage.getItem('prop_deleted'))

        if (proposicao_deleted) {
            this.props.alertaStore.aviso('Por Favor, Clique em " Limpar Filtro " para fazer uma nova pesquisa!')
        } else {
            await this.setState({ loading: true })
            await window.localStorage.removeItem('filtroProposicao')
            await window.localStorage.removeItem('filtroGrafico')
            await window.localStorage.removeItem('filtroPendencia')
            await window.localStorage.setItem('filtroInput', this.state.descricao)

            this.listarProposicoes(1)
        }

    }

    async selecionarVisualizar(newValue) {
        // console.log(newValue.value)
        this.setState({ casaSelecionada: newValue })
        this.setState({ casaSelectedFiltro: newValue.value })
        this.filtroSelectCasa(newValue.value)
    }

    async filtroSelectCasa(value) {
        await window.localStorage.removeItem('filtroProposicao')
        await window.localStorage.removeItem('filtroInput')
        await window.localStorage.removeItem('filtroGrafico')
        await window.localStorage.removeItem('filtroPendencia')
        await window.localStorage.setItem('filtroSelectedCasa', value)
        this.listarProposicoes(1)
        //window.location.reload()
    }

    // async selecionarVisualizarEsforcoColaborativo(newValue) {
    //     this.setState({ visualizar: newValue })
    //     this.obterTodosParlamentares(1, newValue)
    // }

    async onChangeObterCasasByEstado(obj) {
        // console.log(obj)
        const proposicao_deleted = await JSON.parse(window.localStorage.getItem('prop_deleted'))

        if (proposicao_deleted) {
            this.props.alertaStore.aviso('Por Favor, Clique em " Limpar Filtro " para fazer uma nova pesquisa!')
        } else {
            this.setState({ uf: obj })
            await get(`/proposicao/casas-estado/${obj.value}`)
                .then(response => {
                    this.setState({ listaCasas: response })
                })
                .catch(e => {
                    console.log(e)
                })
        }

    }



    async obterEstados() {
        await get(`/proposicao/estados`)
            .then(res => {
                // console.log(res)
                this.setState({ estadoLista: res })

            })
            .catch(e => {
                console.log(e)
            })
    }



    // handleChangeSelectCasa(value){
    //     // console.log(value)
    //     this.setState({casaSelecionada: value})
    //     this.setState({casaSelected: [{value: Value.Value, label: value.label }]})
    // }

    // tamanhoBotao () {
    //     var w = window.innerWidth
    //     if(w > 1200){
    //         this.setState({ tamanhoBotaoSeguir: "400px" })
    //     }else if(w <= 1199.98){
    //         this.setState({ tamanhoBotaoSeguir: "300px" })
    //     }else if(w <= 991.98){
    //         this.setState({ tamanhoBotaoSeguir: "200px" })
    //     }else if(w <= 767.98){
    //         this.setState({ tamanhoBotaoSeguir: "100px" })
    //     }else if(w <= 575.98){
    //         this.setState({ tamanhoBotaoSeguir: "200px" })
    //     }  
    // }



    render() {

        const { ClientPrioridades } = this.state
        const tamanhoTela = window.innerWidth
        const filtroLocal = JSON.parse(window.localStorage.getItem('filtroProposicao'))

        const momentoCriticoAguardandoDel = filtroLocal !== null && filtroLocal.filtroAguardandoDeliberacao !== false ? 1 : 0
        const momentoCriticoAguardandoDes = filtroLocal !== null && filtroLocal.filtroAguardandoDesignacao !== false ? 1 : 0
        const momentoCriticoAguardandoPar = filtroLocal !== null && filtroLocal.filtroAguardandoParecer !== false ? 1 : 0
        const filtroAmbito = filtroLocal !== null && filtroLocal.filtroAmbito.length > 0 ? 1 : 0
        const filtroAno = filtroLocal !== null && filtroLocal.filtroAno.length > 0 ? 1 : 0
        const filtroApelido = filtroLocal !== null && filtroLocal.filtroApelido !== "" ? 1 : 0
        const filtroAtualizacao = filtroLocal !== null && filtroLocal.filtroAtualizacao !== false ? 1 : 0
        // const filtroBusca = filtroLocal !== null && filtroLocal.filtroBusca !== "" ? 1 : 0
        const filtroCasa = filtroLocal !== null && filtroLocal.filtroCasa.length > 0 ? 1 : 0
        const filtroEstado = filtroLocal !== null && filtroLocal.filtroEstado.length > 0 ? 1 : 0
        const filtroNome = filtroLocal !== null && filtroLocal.filtroNome !== "" ? 1 : 0
        const filtroNovas = filtroLocal !== null && filtroLocal.filtroNovas !== false ? 1 : 0
        const filtroNumero = filtroLocal !== null && filtroLocal.filtroNumero !== "" ? 1 : 0
        const filtroPartido = filtroLocal !== null && filtroLocal.filtroPartido.length > 0 ? 1 : 0
        const filtroPosicionamento = filtroLocal !== null && filtroLocal.filtroPosicionamento.length > 0 ? 1 : 0
        const filtroPrazoEmendas = filtroLocal !== null && filtroLocal.filtroPrazoEmendas !== false ? 1 : 0
        const filtroPrioridade = filtroLocal !== null && filtroLocal.filtroPrioridade.length > 0 ? 1 : 0
        const filtroRelator = filtroLocal !== null && filtroLocal.filtroRelator !== "" ? 1 : 0
        const filtroSeguidas = filtroLocal !== null && filtroLocal.filtroSeguidas !== false ? 1 : 0
        const filtroTema = filtroLocal !== null && filtroLocal.filtroTema.length > 0 ? 1 : 0
        const filtroTipo = filtroLocal !== null && filtroLocal.filtroTipo.length > 0 ? 1 : 0
        const filtroTodas = filtroLocal !== null && filtroLocal.filtroTodas !== false ? 1 : 0
        const filtroTramitacaoNao = filtroLocal !== null && filtroLocal.filtroTramitacaoNao !== false ? 1 : 0
        const filtroTramitacaoSim = filtroLocal !== null && filtroLocal.filtroTramitacaoSim !== false ? 1 : 0


        const numeroDeFiltros = momentoCriticoAguardandoDel + momentoCriticoAguardandoDes + momentoCriticoAguardandoPar + filtroAmbito
            + filtroAno + filtroApelido + filtroAtualizacao + filtroCasa + filtroEstado + filtroNome + filtroNovas + filtroNumero + filtroPartido + filtroPosicionamento +
            filtroPrazoEmendas + filtroPrioridade + filtroRelator + filtroSeguidas + filtroTema + filtroTipo + filtroTipo + filtroTodas + filtroTramitacaoNao + filtroTramitacaoSim


        var tamanhoTexto = ''
        if (tamanhoTela <= 991.98) {
            tamanhoTexto = '12px'
        } else {
            tamanhoTexto = '24px'
        }

        const prop_excluida = window.localStorage.getItem('prop_deleted')
        const prop_descricao_excluida = window.localStorage.getItem('prop_deleted_decricao')


        //    console.log(this.state.tamanhoBotaoSeguir)

        return (

            this.state.loading ? <Loading /> :
                <div>

                    <div className="col-12 bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                        <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                            PROPOSIÇÃO
                        </p>
                    </div>



                    <div className="container-fluid">


                        {/* >>>>>>>>>>>>MOBILE<<<<<<<<<<<<< */}

                        <div className="d-block d-sm-none">
                            <div className="row">
                                <div className="col-md-4 col-10" style={{ marginTop: "14px" }}>
                                    <div className="input-group  icon-input">
                                        <input className="form-control  border-right-0 border" id="pesquisar-proposicao" type="search" autoComplete="off"
                                            value={this.state.descricao}
                                            onKeyUp={(event) => { if (event.which === 13) { this.listarProposicao() } }}
                                            onChange={(event) => this.aplicarFiltroInput(event)} placeholder="Buscar proposição" aria-label="Pesquisar" aria-describedby="search" />
                                        <span id="icone-pesquisar-proposicao" className="input-group-append">
                                            <button style={{ paddingTop: "8.5px" }} className="btn btn-outline-secondary border-left-0 border" type="button"
                                                onClick={() => { this.listarProposicao(); this.setState({ filtroInput: true }) }}>
                                                <i className="icon-search-small"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div className=" col-2">
                                    <FiltroProposicao
                                        numeros={this.state.numeros}
                                        temas={this.state.temas}
                                        ano={this.state.ano}
                                        apelido={this.state.apelido}
                                        partido={this.state.partido}
                                        estado={this.state.estado}
                                        ambito1={this.state.ambito1}
                                        ambito2={this.state.ambito2}
                                        ambito3={this.state.ambito3}
                                        busca={this.state.descricao}
                                    />
                                </div>


                                <div className="col-12 pt-1 " style={{ marginBottom: "-10px" }}>
                                    <FormatInput
                                        label="Estado"
                                    >
                                        <ClientCheckboxWithSelect
                                            css="no-border"
                                            name="estado"
                                            value={this.state.uf}
                                            options={
                                                this.state.estadoLista !== undefined && Array.isArray(this.state.estadoLista) && this.state.estadoLista.length > 0 ?
                                                    this.state.estadoLista.map((item) => ({
                                                        value: item.id,
                                                        label: item.sigla
                                                    })) : null}
                                            onChange={(uf) => this.onChangeObterCasasByEstado(uf)}
                                            placeholder={"Selecionar"}
                                        />

                                    </FormatInput>
                                </div>

                                <div className="col-12 ">
                                    <FormatInput
                                        label="Casa"
                                    >
                                        <ClientCheckboxWithSelect
                                            value={this.state.casaSelecionada}
                                            onChange={(newValue) => this.selecionarVisualizar(newValue)}
                                            options={
                                                this.state.listaCasas !== undefined && Array.isArray(this.state.listaCasas) && this.state.listaCasas.length > 0 ?
                                                    this.state.listaCasas.map((item) => ({ value: item.id, label: item.sigla + ' - ' + item.nome, sigla: item.sigla, id: item.id })) : []}
                                            placeholder={"Selecionar"}
                                        />

                                    </FormatInput>
                                </div>
                                {
                                    this.props.authStore.ehVisualizador === false ?
                                        <div className=" col-12 text-center pl-2 pr-2">

                                            <Link to="/proposicao/cadastro-proposicao" className="btn" style={{ marginTop: "3px", padding: "15px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter", width: "100%" }} variant="outlined">
                                                <img
                                                    className="mr-2"
                                                    style={{ width: "20px" }}
                                                    src={require('../../design/assets/images/editar.svg')}
                                                    alt="btn-pesquisar"
                                                />{' '}
                                                        Cadastrar Proposição
                                        </Link>
                                            {/* <a href="/proposicao/cadastro-proposicao"> */}
                                            {/* <button id="pesquisa-dash" className="btn" style={{marginTop:"20px", padding:"15px", backgroundColor: "var(--botaoPesquisar)", color:"var(--corTextoBotao)", fontWeight:"lighter"}} variant="outlined" >
                                                        <img
                                                            className="mr-2"
                                                            style={{width:"20px"}}
                                                            src={require('../../design/assets/images/editar.svg')}
                                                            alt="btn-pesquisar"
                                                        />{' '}
                                                        Cadastrar Proposição
                                                    </button> */}
                                            {/* </a> */}
                                        </div>

                                        : null
                                }


                            </div>
                        </div>

                        <div className="d-none d-sm-block">

                            <div className="row">
                                <div className="col-12 col-sm-3 mt-3 col-lg-3 ">
                                    <div className="input-group  icon-input" style={{ marginTop: "20px" }}>
                                        <input className="form-control py-2 border-right-0 border" id="pesquisar-proposicao" type="search" autoComplete="off"
                                            value={this.state.descricao}
                                            onKeyUp={(event) => { if (event.which === 13) { this.filtroInputSelected() } }}
                                            onChange={(event) => this.aplicarFiltroInput(event)} placeholder="Buscar proposição" aria-label="Pesquisar" aria-describedby="search" />
                                        <span id="icone-pesquisar-proposicao" className="input-group-append">
                                            <button className="btn btn-outline-secondary border-left-0 border" type="button"
                                                onClick={() => this.filtroInputSelected()}>
                                                <i className="icon-search-small"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>

                                <div className=" col-12 col-sm-9 col-lg-9">

                                    <div className="row">

                                        <div className="col-md-2 mt-2 ">
                                            <FormatInput
                                                label="Estado"
                                            >
                                                <ClientCheckboxWithSelect
                                                    css="no-border"
                                                    name="estado"
                                                    value={this.state.uf}
                                                    options={
                                                        this.state.estadoLista !== undefined && Array.isArray(this.state.estadoLista) && this.state.estadoLista.length > 0 ?
                                                            this.state.estadoLista.map((item) => ({
                                                                value: item.id,
                                                                label: item.sigla
                                                            })) : null}
                                                    onChange={(uf) => this.onChangeObterCasasByEstado(uf)}
                                                    placeholder={"Selecionar"}
                                                />

                                            </FormatInput>
                                        </div>

                                        <div className="col-md-5 mt-2 ">
                                            <FormatInput
                                                label="Casa"
                                            >
                                                <ClientCheckboxWithSelect
                                                    value={this.state.casaSelecionada}
                                                    onChange={(newValue) => this.selecionarVisualizar(newValue)}
                                                    options={
                                                        this.state.listaCasas !== undefined && Array.isArray(this.state.listaCasas) && this.state.listaCasas.length > 0 ?
                                                            this.state.listaCasas.map((item) => ({ value: item.id, label: item.sigla + ' - ' + item.nome, sigla: item.sigla, id: item.id })) : []}
                                                    placeholder={"Selecionar"}
                                                />

                                            </FormatInput>
                                        </div>
                                        {
                                            this.props.authStore.ehVisualizador === false ?
                                                <div className=" text-center pl-2 pr-2">

                                                    <Link to="/proposicao/cadastro-proposicao" className="btn" style={{ marginTop: "20px", padding: "15px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter" }} variant="outlined">
                                                        <img
                                                            className="mr-2"
                                                            style={{ width: "20px" }}
                                                            src={require('../../design/assets/images/editar.svg')}
                                                            alt="btn-pesquisar"
                                                        />{' '}
                                                        Cadastrar Proposição
                                        </Link>
                                                    {/* <a href="/proposicao/cadastro-proposicao"> */}
                                                    {/* <button id="pesquisa-dash" className="btn" style={{marginTop:"20px", padding:"15px", backgroundColor: "var(--botaoPesquisar)", color:"var(--corTextoBotao)", fontWeight:"lighter"}} variant="outlined" >
                                                        <img
                                                            className="mr-2"
                                                            style={{width:"20px"}}
                                                            src={require('../../design/assets/images/editar.svg')}
                                                            alt="btn-pesquisar"
                                                        />{' '}
                                                        Cadastrar Proposição
                                                    </button> */}
                                                    {/* </a> */}
                                                </div>

                                                : null
                                        }
                                        <div className="pl-2">
                                            <FiltroProposicao
                                                numeros={this.state.numeros}
                                                temas={this.state.temas}
                                                ano={this.state.ano}
                                                apelido={this.state.apelido}
                                                partido={this.state.partido}
                                                estado={this.state.estado}
                                                ambito1={this.state.ambito1}
                                                ambito2={this.state.ambito2}
                                                ambito3={this.state.ambito3}
                                                busca={this.state.descricao}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="d-none d-sm-block">
                        {this.state.botaoLimparFiltro === false ? null :
                            <div className="p-3">
                                <span onClick={() => this.limparFiltroLocaStorage()} style={{ color: 'var(--linkText)', borderBottom: '1px solid var(--linkText)', fontSize: '0.8rem', cursor: 'pointer' }}>Limpar Filtro</span>
                            </div>
                        }
                    </div>

                    <div className="pl-3 pr-3 d-block d-sm-none" style={{ marginTop: "20px", marginBottom: "-10px" }}>
                        <hr />
                    </div>

                    <div className="d-block d-sm-none" style={{ marginBottom: "-40px" }} >
                        <div className="row p-3" style={{ marginTop: "-10px" }}>
                            <div className="col-7">
                                <p><span style={{ fontSize: "16px", fontWeight: "bold" }}>{this.state.quantidade}</span> Resultados</p>
                            </div>
                            <div className="col-5">
                                {numeroDeFiltros === 0 ? null :
                                    <div className="text-right">
                                        {filtroLocal !== null && filtroLocal !== undefined ?
                                            <div><span style={{ fontSize: "16px", fontWeight: "bold" }}>{numeroDeFiltros}</span> Filtros Aplicados</div>
                                            : false}
                                    </div>
                                }
                                <div className="text-right" style={numeroDeFiltros === 0 ? null : { marginTop: "5px", marginBottom: "20px" }} >
                                    {this.state.botaoLimparFiltro === false ? null :
                                        <div>
                                            <span onClick={() => this.limparFiltroLocaStorage()} style={{ color: 'var(--linkText)', borderBottom: '1px solid var(--linkText)', fontSize: '0.8rem', cursor: 'pointer' }}>Limpar Filtro</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>



                    {prop_excluida === 'true'
                        ?
                        <div className=" m-3 p-2 d-flex flex-column justify-content-center align-items-center border" style={{ height: '700px', background: '#FFF', borderRadius: '4px' }}>
                            <span style={{ color: 'var(--linkText)', fontSize: `${tamanhoTexto}` }}> A proposição {prop_descricao_excluida} foi excluida.</span>
                            <span style={{ color: 'var(--linkText)', fontSize: `${tamanhoTexto}` }}> Refaça sua pequisa.</span>
                        </div>

                        :
                        <>
                            <div className="p-3">
                                {this.state.listaProposicao.length > 0 && Array.isArray(this.state.listaProposicao) && this.state.listaProposicao.map((item, index) => {
                                    //  console.log("item Id Prop", item)
                                    const infoData = item.status_data_hora !== null ? moment(
                                        item.status_data_hora === null ? item.atualizado_em : item.status_data_hora).format('DD/MM/YYYY [às] HH:MM') : ' Sem informação'
                                    // console.log("item Id Prop", item)
                                    // console.log("infoData", item.c_nome)

                                    return (

                                        <div key={index}>

                                            {/* >>>>>>>>>>>>MOBILE<<<<<<<<<<<<<<<<<<< */}

                                            <div className="d-block d-sm-none">

                                                <div key={index} style={{ border: "1px solid #E0E0E0", borderRadius: "5px", backgroundColor: '#fff', marginTop: "10px" }} className="p-2">
                                                    <AccordionMobileListProp
                                                        mb={'mb-0'}
                                                        p={'p-0'}
                                                        titulo={item.descricao}
                                                        casa={item.c_nome !== null ? <span className="casaStyle"> | {item.c_nome}</span> : ""}
                                                        atualizacao={`Atualização: ${infoData}`}
                                                        navLink={item.id}
                                                    >
                                                        <div style={{ marginTop: "20px" }}>
                                                            <hr />
                                                        </div>



                                                        <div className="col-xl-6 col-lg-6 col-md-3 col-sm-12 col-12 text-right">
                                                            <div className="botaoSeguir">
                                                                {this.props.authStore.ehEsforcoColaborativo === true ?
                                                                    <div></div>
                                                                    :
                                                                    <div className="botaoSeguir">
                                                                        <Seguir id={this.props.key} status={item.seguindo} url={`/proposicao/${item.id}/seguir`}></Seguir>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-xl-12 col-lg-12 col-md-12 mt-3 text-wrap">
                                                            <NavLink id="proposicao-acao-preparatoria" to={`/proposicao/${item.id}/acao-preparatoria`}>
                                                                <p className="text-justify" style={{ fontSize: "16px", color: "black", cursor: "pointer" }}>
                                                                    {item.ementa}
                                                                </p>
                                                            </NavLink>
                                                        </div>

                                                        <div className="col-xl-2 col-lg-2 col-md-4 mt-2 text-wrap">
                                                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                Autor
                                                            </p>
                                                            {this.state.autores.length > 0 && this.state.autores.map(autor => autor.id_proposicao === item.id ? true : null).filter(x => x).length > 0 ? null :
                                                                <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                            }
                                                            <span className="d-block">
                                                                {this.state.autores.length > 0 && this.state.autores.map((autor, index) => {
                                                                    return (
                                                                        autor.id_proposicao === item.id ?
                                                                            <div style={{ fontSize: "16px", fontWeight: "lighter", }} key={index}>{autor.nome}</div>
                                                                            : null
                                                                    )
                                                                })}
                                                            </span>
                                                        </div>

                                                        <div className="col-xl-2 col-lg-2 col-md-4 mt-2 text-wrap">
                                                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                Situação
                                                            </p>
                                                            <span className="d-block">
                                                                {item.status_descricao_situacao === null ?
                                                                    <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                                    :
                                                                    <div style={{ fontSize: "16px", fontWeight: "lighter", }}>{item.status_descricao_situacao}</div>
                                                                }
                                                            </span>
                                                        </div>

                                                        <div className="col-xl-2 col-lg-2 col-md-4 mt-2 text-wrap">
                                                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                Tema
                                                            </p>
                                                            {this.state.temas.length > 0 && this.state.temas.map(tema => tema.id_proposicao === item.id ? true : null).filter(x => x).length > 0 ? null :
                                                                <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                            }
                                                            <span className="d-block">
                                                                {this.state.temas.length > 0 && this.state.temas.map((tema, index) => {
                                                                    return (
                                                                        tema.id_proposicao === item.id ?
                                                                            <div style={{ fontSize: "16px", fontWeight: "lighter", }} key={index}>{tema.tema}</div>
                                                                            : null

                                                                    )
                                                                })}
                                                            </span>
                                                        </div>

                                                        <div className="col-xl-2 col-lg-2 col-md-4 mt-2 text-wrap">
                                                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                Órgão
                                                            </p>
                                                            {item.status_sigla_orgao === null ?
                                                                <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                                :
                                                                <div style={{ fontSize: "16px", fontWeight: "lighter", }}>{item.status_sigla_orgao}</div>
                                                            }
                                                        </div>

                                                        <div className="col-xl-2 col-lg-2 col-md-4 mt-2 text-wrap">
                                                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                Tramitação
                                                            </p>
                                                            {item.status_descricao_tramitacao === null ?
                                                                <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                                :
                                                                <div style={{ fontSize: "16px", fontWeight: "lighter", lineHeight: "18px", paddingTop: "5px" }}>{item.status_descricao_tramitacao}</div>
                                                            }
                                                        </div>

                                                        <div className="col-xl-2 col-lg-2 col-md-4 mt-2 text-wrap">
                                                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                Relatores
                                                            </p>
                                                            <span className="d-block">
                                                                {item.id_proposicao === item.id && item.status_ultimo_relator !== null ?
                                                                    this.state.relatores && this.state.relatores.map((item, index) => {
                                                                        // console.log("index:",index) 
                                                                        return (
                                                                            item.id_proposicao === item.id ?
                                                                                <div style={{ fontSize: "16px", fontWeight: "lighter", }} key={index}>{item.status_ultimo_relator}</div>
                                                                                : null
                                                                        )
                                                                    })
                                                                    :
                                                                    <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                                }
                                                            </span>
                                                        </div>

                                                        <div className="col-md-2 col-6 mt-3 text-wrap">
                                                            {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                                                <div>
                                                                    <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                        Prioridade
                                                                    </p>
                                                                    {item.prioridade !== null ?
                                                                        ClientPrioridades !== undefined &&
                                                                            Array.isArray(ClientPrioridades) && ClientPrioridades.length > 0 ?
                                                                            ClientPrioridades.map((prioridade, index) => {
                                                                                return (
                                                                                    <div className="d-flex" key={index}>
                                                                                        {prioridade !== null && prioridade !== "" && prioridade !== undefined && item.prioridade === prioridade.id ? <span style={{ marginTop: "6px", marginLeft: "2px" }} className={`Client-circulo ${prioridade.icon}`}></span> : null}
                                                                                        {prioridade !== null && prioridade !== "" && prioridade !== undefined && item.prioridade === prioridade.id ? <span style={{ fontSize: "16px", fontWeight: "lighter", }}>{prioridade.value}</span> : null}
                                                                                    </div>
                                                                                )
                                                                            }) : null
                                                                        :
                                                                        <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="col-md-2 col-6 mt-3 text-wrap">
                                                            {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                                                <div>
                                                                    <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                        Posicionamento
                                                                    </p>
                                                                    {item.prioridade !== null ?
                                                                        posicionamentosClient !== undefined && Array.isArray(posicionamentosClient) &&
                                                                            posicionamentosClient.length > 0 ?
                                                                            posicionamentosClient.map((posicionamento, index) => {
                                                                                // console.log(posicionamento.icon)
                                                                                return (
                                                                                    <div className="d-flex" key={index}>
                                                                                        {posicionamento !== null && posicionamento !== "" && posicionamento !== undefined && item.posicaoDaOrganizacao === posicionamento.id ? <span style={{ marginTop: "6px", marginLeft: "2px" }} className={`Client-circulo ${posicionamento.icon}`}></span> : null}
                                                                                        {posicionamento !== null && posicionamento !== "" && posicionamento !== undefined && item.posicaoDaOrganizacao === posicionamento.id ? <span style={{ fontSize: "16px", fontWeight: "lighter", }}>{posicionamento.value}</span> : null}
                                                                                    </div>
                                                                                )
                                                                            }) : null
                                                                        :
                                                                        <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>



                                                    </AccordionMobileListProp>

                                                </div>
                                            </div>


                                            <div className="d-none d-sm-block">


                                                <div key={index} className="proposicao-container mb-2 fs-13 mt-2 bg-white" style={{ padding: "20px" }}>
                                                    <div className="row mb-2">


                                                        <div className="col-xl-6 col-lg-6 col-md-9 col-sm-12 col-12" style={{ paddingTop: "10px" }}>
                                                            <NavLink id="proposicao-acao-preparatoria" to={`/proposicao/${item.id}/acao-preparatoria`}>
                                                                <div className="row">
                                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                                        <div className="row">
                                                                            <h4 style={{ paddingLeft: "7px" }}>{item.descricao}</h4>
                                                                            {item.c_nome !== null ? <span className="casaStyle"> | {item.c_nome}</span> : ""}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginTop: "-10px" }}>
                                                                        <span className='date' style={{ color: "#9B9B9B", fontSize: "16px" }}>Atualização:
                                                                        {/* {item.status_data_hora !== null ? */}
                                                                                <span className="ml-2">{moment(
                                                                                    item.status_data_hora === null ? item.data_apresentacao : item.status_data_hora).format('DD/MM/YYYY')}</span>
                                                                                {/* : 'Sem Informação'} */}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </NavLink>
                                                        </div>

                                                        <div className="col-xl-6 col-lg-6 col-md-3 col-sm-12 col-12 text-right">
                                                            <div className="botaoSeguir">
                                                                {this.props.authStore.ehEsforcoColaborativo === true ?
                                                                    <div></div>
                                                                    :
                                                                    <div className="botaoSeguir">
                                                                        <Seguir id={this.props.key} status={item.seguindo} url={`/proposicao/${item.id}/seguir`}></Seguir>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="col-xl-12 col-lg-12 col-md-12 mt-3 text-wrap">
                                                            <p className="text-justify" style={{ fontSize: "16px" }}>
                                                                {item.ementa}
                                                            </p>
                                                        </div>

                                                        <div className="col-xl-2 col-lg-2 col-md-4 mt-2 text-wrap">
                                                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                Autor
                                                            </p>
                                                            {this.state.autores.length > 0 && this.state.autores.map(autor => autor.id_proposicao === item.id ? true : null).filter(x => x).length > 0 ? null :
                                                                <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                            }
                                                            <span className="d-block">
                                                                {this.state.autores.length > 0 && this.state.autores.map((autor, index) => {
                                                                    return (
                                                                        autor.id_proposicao === item.id ?
                                                                            <div style={{ fontSize: "16px", fontWeight: "lighter", }} key={index}>{autor.nome}</div>
                                                                            : null
                                                                    )
                                                                })}
                                                            </span>
                                                        </div>

                                                        <div className="col-xl-2 col-lg-2 col-md-4 mt-2 text-wrap">
                                                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                Situação
                                                            </p>
                                                            <span className="d-block">
                                                                {item.status_descricao_situacao === null ?
                                                                    <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                                    :
                                                                    <div style={{ fontSize: "16px", fontWeight: "lighter", }}>{item.status_descricao_situacao}</div>
                                                                }
                                                            </span>
                                                        </div>

                                                        <div className="col-xl-2 col-lg-2 col-md-4 mt-2 text-wrap">
                                                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                Tema
                                                            </p>
                                                            {this.state.temas.length > 0 && this.state.temas.map(tema => tema.id_proposicao === item.id ? true : null).filter(x => x).length > 0 ? null :
                                                                <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                            }
                                                            <span className="d-block">
                                                                {this.state.temas.length > 0 && this.state.temas.map((tema, index) => {
                                                                    return (
                                                                        tema.id_proposicao === item.id ?
                                                                            <div style={{ fontSize: "16px", fontWeight: "lighter", }} key={index}>{tema.tema}</div>
                                                                            : null

                                                                    )
                                                                })}
                                                            </span>
                                                        </div>

                                                        <div className="col-xl-2 col-lg-2 col-md-4 mt-2 text-wrap">
                                                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                Órgão
                                                            </p>
                                                            {item.status_sigla_orgao === null ?
                                                                <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                                :
                                                                <div style={{ fontSize: "16px", fontWeight: "lighter", }}>{item.status_sigla_orgao}</div>
                                                            }
                                                        </div>

                                                        <div className="col-xl-2 col-lg-2 col-md-4 mt-2 text-wrap">
                                                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                Tramitação
                                                            </p>
                                                            {item.status_descricao_tramitacao === null ?
                                                                <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                                :
                                                                <div style={{ fontSize: "16px", fontWeight: "lighter", lineHeight: "18px", paddingTop: "5px" }}>{item.status_descricao_tramitacao}</div>
                                                            }
                                                        </div>

                                                        <div className="col-xl-2 col-lg-2 col-md-4 mt-2 text-wrap">
                                                            <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                Relatores
                                                            </p>
                                                            <span className="d-block">
                                                                {item.id_proposicao === item.id && item.status_ultimo_relator !== null ?
                                                                    this.state.relatores && this.state.relatores.map((item, index) => {
                                                                        // console.log("index:",index) 
                                                                        return (
                                                                            item.id_proposicao === item.id ?
                                                                                <div style={{ fontSize: "16px", fontWeight: "lighter", }} key={index}>{item.status_ultimo_relator}</div>
                                                                                : null
                                                                        )
                                                                    })
                                                                    :
                                                                    <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                                }
                                                            </span>
                                                        </div>

                                                        <div className="col-md-2 col-6 mt-3 text-wrap">
                                                            {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                                                <div>
                                                                    <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                        Prioridade
                                                                    </p>
                                                                    {item.prioridade !== null ?
                                                                        ClientPrioridades !== undefined &&
                                                                            Array.isArray(ClientPrioridades) && ClientPrioridades.length > 0 ?
                                                                            ClientPrioridades.map((prioridade, index) => {
                                                                                return (
                                                                                    <div className="d-flex" key={index}>
                                                                                        {prioridade !== null && prioridade !== "" && prioridade !== undefined && item.prioridade === prioridade.id ? <span style={{ marginTop: "6px", marginLeft: "2px" }} className={`Client-circulo ${prioridade.icon}`}></span> : null}
                                                                                        {prioridade !== null && prioridade !== "" && prioridade !== undefined && item.prioridade === prioridade.id ? <span style={{ fontSize: "16px", fontWeight: "lighter", }}>{prioridade.value}</span> : null}
                                                                                    </div>
                                                                                )
                                                                            }) : null
                                                                        :
                                                                        <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="col-md-2 col-6 mt-3 text-wrap">
                                                            {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                                                <div>
                                                                    <p style={{ fontSize: "16px", fontWeight: "bolder", marginBottom: "-5px" }}>
                                                                        Posicionamento
                                                                    </p>
                                                                    {item.prioridade !== null ?
                                                                        posicionamentosClient !== undefined && Array.isArray(posicionamentosClient) &&
                                                                            posicionamentosClient.length > 0 ?
                                                                            posicionamentosClient.map((posicionamento, index) => {
                                                                                // console.log(posicionamento.icon)
                                                                                return (
                                                                                    <div className="d-flex" key={index}>
                                                                                        {posicionamento !== null && posicionamento !== "" && posicionamento !== undefined && item.posicaoDaOrganizacao === posicionamento.id ? <span style={{ marginTop: "6px", marginLeft: "2px" }} className={`Client-circulo ${posicionamento.icon}`}></span> : null}
                                                                                        {posicionamento !== null && posicionamento !== "" && posicionamento !== undefined && item.posicaoDaOrganizacao === posicionamento.id ? <span style={{ fontSize: "16px", fontWeight: "lighter", }}>{posicionamento.value}</span> : null}
                                                                                    </div>
                                                                                )
                                                                            }) : null
                                                                        :
                                                                        <div style={{ color: "#9B9B9B", fontSize: "16px", fontWeight: "lighter" }}>Não definido</div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    )
                                })}


                            </div>

                            <div>
                                <div className="pagination"> Exibindo resultados {(this.state.paginaAtual - 1) * this.state.limite} - {this.state.paginaAtual * this.state.limite} de um total de {this.state.quantidade}</div>
                                <Pagination
                                    activePage={this.state.paginaAtual}
                                    itemsCountPerPage={this.state.limite}
                                    totalItemsCount={parseInt(this.state.quantidade)}
                                    pageRangeDisplayed={10}
                                    hideFirstLastPages={true}
                                    onChange={this.carregarPaginaNova}
                                    prevPageText={<div className="prev-page"><img src={require('../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                                    nextPageText={<div className="next-page"><img src={require('../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                                />
                            </div>
                        </>
                    }


                </div>
        )
    }
}

export default ProposicaoLista