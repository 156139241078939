import React, { Component } from "react";
import { get } from "../../services/http";
import moment from 'moment-timezone';
import "moment/locale/pt-br.js";
import Loading from "../../componentes/loading";
import { inject, observer } from "mobx-react";
import { NavLink } from 'react-router-dom';

moment.tz.setDefault('UTC');

@inject("authStore")
@observer
class AgendaJornal extends Component {
	state = {
		eventosSemana: [],
		casa: this.props.match.params.casa,
		loading: true,
	};

	async componentDidMount() {
		if (this.props.authStore.ehEsforcoColaborativo === true) {
			window.location.href = "/agenda";
		}
		await get(
			`/agenda/${this.props.match.params.dataInicial + "T00:00:00"}/${this
				.props.match.params.dataFinal + "T23:59:59"}/eventos`
		).then((response) =>
			this.setState({ eventosSemana: response, loading: false })
		);
	}

	imprimir() {
		window.print();
	}

	render() {
		// console.log("casa>>>>", parseInt(this.props.match.params.casa))

		moment.updateLocale("pt", {
			months: [
				"Janeiro",
				"Fevereiro",
				"Março",
				"Abril",
				"Maio",
				"Junho",
				"Julho",
				"Agosto",
				"Setembro",
				"Outubro",
				"Novembro",
				"Dezembro",
			],
		});
		const now = new Date();
		const dataHoje = moment(now).format("DD [de] MMMM [de] YYYY");
		// const diaInicio = moment(
		// 	new Date(this.props.match.params.dataInicial)
		// ).format("DD [de] MMMM [de] YYYY");
		// const diaFim = moment(
		// 	new Date(this.props.match.params.dataFinal).getTime() + 156400000
		// ).format("DD [de] MMMM [de] YYYY");

		const diaEntrada = moment(
			new Date(this.props.match.params.dataInicial)
		).format("DD");
		const mesEntrada = moment(
			new Date(this.props.match.params.dataInicial)
		).format("MMMM");
		const anoEntrada = moment(
			new Date(this.props.match.params.dataInicial)
		).format("YYYY");
		const diaSaida = moment(
			new Date(this.props.match.params.dataFinal).getTime() + 156400000
		).format("DD");
		const mesSaida = moment(
			new Date(this.props.match.params.dataFinal).getTime() + 156400000
		).format("MMMM");
		const anoSaida = moment(
			new Date(this.props.match.params.dataFinal).getTime() + 156400000
		).format("YYYY");
		// const mes = moment(this.props.match.params.dataFinal).format('MMMM');
		const diaSemana = now.getDay();
		var diaDaSemana = "";
		switch (diaSemana) {
			case 0:
				diaDaSemana = "Domingo";
				break;
			case 1:
				diaDaSemana = "segunda-feira";
				break;
			case 2:
				diaDaSemana = "terça-feira";
				break;
			case 3:
				diaDaSemana = "quarta-feira";
				break;
			case 4:
				diaDaSemana = "quinta-feira";
				break;
			case 5:
				diaDaSemana = "sexta-feira";
				break;
			case 6:
				diaDaSemana = "sábado";
				break;
			default:
				console.log("dia da semana");
		}
		// var casa_nome = '';
		// switch (parseInt(this.props.match.params.casa)) {
		//     case 0:
		//         casa_nome = 'Senado, Câmara e Congresso';
		//         break;
		//     case 1:
		//         casa_nome = 'Senado Federal';
		//         break;
		//     case 2:
		//         casa_nome = 'Câmara dos Deputados';
		//         break;
		//     case 3:
		//         casa_nome = 'Congresso Nacional';
		//         break;
		//     default:
		//         console.log('Nome da Casa');
		// }
		return (
			<div className="container pagebreak" style={{ background: "white" }}>
				<div className="d-none d-md-block" id="non-printable" style={{ paddingLeft: "30px", paddingTop: "15px" }}>
					<button className="btn btn-danger" onClick={this.imprimir}>
						<img src={require("../../design/assets/images/impressora.svg")} alt="anexo" />
					</button>
				</div>
				<div className="space-header-jornal" style={{ padding: "30px" }} id="printable">
					<div className="row pt-3">
						{/* <div className="d-block d-md-none col-sm-1 col-0 d-none d-sm-block"></div> */}

						<div className="col-md-1 col-12 div-logo-Client">
							<div className="logoAgendaJornal text-center"></div>
							{/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
						</div>

						<div className="col-md-11 col-sm-10 col-12">
							<p className="departamento-titulo" style={{ lineHeight: "25px" }}>
								Departamento de Relações Institucionais
							</p>
							<div>
								<h1 className="titulo-evento">
									{parseInt(this.props.match.params.casa) === 1 ?
										"Agenda da Câmara dos Deputados" :
										parseInt(this.props.match.params.casa) === 3 ?
											"Agenda do Senado Federal" :
											parseInt(this.props.match.params.casa) === 2 ?
												"Agenda do Congresso Nacional" :
												parseInt(this.props.match.params.casa) === 0 ?
													"Agenda Federal, Estadual e Municipal" :
													"Agenda dos Estados e Municípios"}
								</h1>
							</div>
							{/* <h1 className="titulo-evento">Agenda do {casa_nome}</h1> */}
							<h5 className="data-eventos">
								Brasilia, {diaDaSemana}, {dataHoje}
							</h5>
							<div className="text-center">
								<h4 className="semana-eventos">
									Semana de{" "}
									{mesEntrada !== mesSaida ? `${diaEntrada} de ${mesEntrada} ${anoEntrada !== anoSaida ? " de " + anoEntrada : ""} a ${diaSaida} de ${mesSaida} de ${anoSaida}`
										: `${diaEntrada} a ${diaSaida} de ${mesEntrada} de ${anoSaida}`}
								</h4>
							</div>
						</div>
					</div>
				</div>

				<div>
					<hr />
				</div>

				{this.state.loading ? (<Loading />)
					: (
						<div className="myDivToPrint" style={{ background: "white" }}>
							{this.state.eventosSemana !== undefined &&
								Array.isArray(this.state.eventosSemana) && this.state.eventosSemana.length > 0
								? this.state.eventosSemana.map((item, index) => {
									// console.log("Evento semana>>>>>>>>>>>>>>", this.state.eventosSemana)
									const diaProp = moment(item.data_inicio).format("DD");
									const horaProp = moment(item.data_inicio).format("HH");
									const minutoProp = moment(item.data_inicio).format("mm");
									var diaDaSemanaProp = "";
									switch (
									new Date(item.data_inicio).getDay()
									) {
										case 0:
											diaDaSemanaProp = "Domingo";
											break;
										case 1:
											diaDaSemanaProp = "segunda-feira";
											break;
										case 2:
											diaDaSemanaProp = "terça-feira";
											break;
										case 3:
											diaDaSemanaProp = "quarta-feira";
											break;
										case 4:
											diaDaSemanaProp = "quinta-feira";
											break;
										case 5:
											diaDaSemanaProp = "sexta-feira";
											break;
										case 6:
											diaDaSemanaProp = "sábado";
											break;
										default:
											console.log("dia da semana");
									}
									return item.proposicoesSeguidas !== undefined && item.proposicoesSeguidas.length > 0 ?
										(parseInt(this.props.match.params.casa) !== 0 && parseInt(this.props.match.params.casa) === item.casa ?
											(<div id="paginaTotal" className="row" key={index}>
												<div className="col-12">
													<div className="text-left" style={{ paddingBottom: "10px", paddingTop: "50px", }}>
														<h5 className="titulo-card">
															{item.nome}
														</h5>
													</div>
													<div style={{ padding: "30px", }}>
														<div className="text-left">
															<h5 className="titulo-comissao">
																{item.orgaos && item.orgaos.length && item.orgaos[0].comissao_nome || ''}
															</h5>
														</div>
														<div className="text-left">
															<h5 className="titulo-data">
																Sessão{" "}
																{diaDaSemanaProp}, dia {diaProp}{" "} às {horaProp}h{minutoProp}
															</h5>
														</div>
													</div>
												</div>

												{item.proposicoesSeguidas.length > 0 ?
													item.proposicoesSeguidas.map((propSeg, index) => {
														var posicionamento = "";
														switch (propSeg.posicaoDaOrganizacao) {
															case 1:
																posicionamento = "Contrário";
																break;
															case 2:
																posicionamento = "Contrário com emenda saneadora";
																break;
															case 3:
																posicionamento = "Neutro";
																break;
															case 4:
																posicionamento = "Favorável com emenda saneadora";
																break;
															case 5:
																posicionamento = "Favorável";
																break;
															case 99:
																posicionamento = "Pendente";
																break;
															case 0:
																posicionamento = "Pendente";
																break;
															default:
																posicionamento = "Pendente";
														}

														return (
															// <div className="col-md-6" key={index}>
															<div className="col-12" key={index}>
																<div className="justify-content box-card-eventos" style={{ paddingLeft: "40px", paddingRight: "40px", paddingTop: "35px", }}>
																	<span className="item-evento">
																		ITEM.
																		{propSeg.item}
																	</span>
																	<span className="item-descricao">
																		{" "}
																		{propSeg.descricaoTipo}
																	</span>
																	<span className="texto-evento">
																		{propSeg.apelido !== null && propSeg.apelido !== "" && propSeg.apelido !== undefined ? propSeg.apelido + " / " : null}
																		{propSeg.tipo + propSeg.numero + "/" + propSeg.ano}{" "}{propSeg.ementa}
																	</span>
																	<span className="texto-evento">
																		AUTOR:{" "}
																		{propSeg.autores.map((item, index) => {
																			return (
																				<span key={index}>
																					<span>
																						<NavLink style={{ color: "black", textDecoration: "underline" }} id="url-foto-classificadas" to={`/parlamentar/${item.id_parlamentar}/acao-influencia`} >
																							{item.descricao_partido !== null ? `${item.nome} (${item.descricao_partido} ${item.ufExercicio})` : `${item.nome}`}
																						</NavLink>
																						{", "}
																					</span>
																				</span>
																			)
																		})}
																	</span>
																	<span className="texto-evento">
																		PARECER DO RELATOR:{" "}
																		{propSeg.parecer_relator}
																	</span>
																	<span className="texto-evento">
																		POSICIONAMENTO:{" "}
																		{posicionamento}
																	</span>
																	{propSeg !== null && propSeg.status_despacho !== null ? (
																		<p className="aviso-departamento">
																			<span className="seta"></span>{" "}
																			{propSeg.status_despacho}
																		</p>
																	) : null}
																</div>
															</div>
														);
													}
													)
													: null}
											</div>
											)
											:
											parseInt(this.props.match.params.casa) === 0 ? (
												<div id="paginaTotal" className="row" key={index}>
													{/* <div className="col-md-6"> */}
													<div className="col-12">
														<div className="text-left" style={{ paddingBottom: "10px", paddingTop: "50px", marginLeft: "30px", }}>
															<h5 className="titulo-card">
																{item.casa_descricao}
															</h5>
														</div>
														<div style={{ padding: "30px", }}>
															<div className="text-left">
																<h5 className="titulo-comissao">
																{item.orgaos && item.orgaos.length && item.orgaos[0].comissao_nome || ''}
																</h5>
															</div>
															<div className="text-left">
																<h5 className="titulo-data">
																	Sessão{" "}{diaDaSemanaProp}, dia {diaProp}{" "}às {horaProp}h{minutoProp}
																</h5>
															</div>
														</div>
													</div>

													{item.proposicoesSeguidas.length > 0 ?
														item.proposicoesSeguidas.map((propSeg, index) => {
															var posicionamento = "";
															switch (propSeg.posicaoDaOrganizacao) {
																case 1:
																	posicionamento = "Contrário";
																	break;
																case 2:
																	posicionamento = "Contrário com emenda saneadora";
																	break;
																case 3:
																	posicionamento = "Neutro";
																	break;
																case 4:
																	posicionamento = "Favorável com emenda saneadora";
																	break;
																case 5:
																	posicionamento = "Favorável";
																	break;
																case 99:
																	posicionamento = "Pendente";
																	break;
																case 0:
																	posicionamento = "Pendente";
																	break;
																default:
																	posicionamento = "Pendente";
															}
															return (
																// <div className="col-md-6" key={index}>
																<div className="col-12" key={index}>
																	<div className="justify-content box-card-eventos" style={{ paddingLeft: "40px", paddingRight: "40px", paddingTop: "35px", }}>
																		<span className="item-evento">
																			ITEM.
																			{propSeg.item}
																		</span>
																		<span className="item-descricao">
																			{" "}
																			{propSeg.descricaoTipo}
																		</span>
																		<span className="texto-evento">
																			{propSeg.apelido !== null && propSeg.apelido !== "" && propSeg.apelido !== undefined ? propSeg.apelido + " / " : null}
																			{propSeg.tipo + propSeg.numero + "/" + propSeg.ano}{" "}{propSeg.ementa}
																		</span>
																		<span className="texto-evento">
																			AUTOR:{" "}
																			{propSeg.autores.map((item, index) => {
																				return (
																					<span key={index}>
																						<span>
																							<NavLink style={{ color: "black", textDecoration: "underline" }} id="url-foto-classificadas" to={`/parlamentar/${item.id_parlamentar}/acao-influencia`} >
																								{item.descricao_partido !== null ? `${item.nome} (${item.descricao_partido} ${item.ufExercicio})` : `${item.nome}`}
																							</NavLink>
																							{", "}
																						</span>
																					</span>
																				)
																			})}
																		</span>
																		<span className="texto-evento">
																			PARECER DO RELATOR:{" "}
																			{propSeg.parecer_relator}
																		</span>
																		<span className="texto-evento">
																			POSICIONAMENTO:{" "}
																			{posicionamento}
																		</span>
																		{propSeg !== null && propSeg.status_despacho !== null ? (
																			<p className="aviso-departamento">
																				<span className="seta"></span>{" "}
																				{propSeg.status_despacho}
																			</p>
																		) : null}
																	</div>
																</div>
															);
														}
														)
														: null}
												</div>
											)
												:
												(
													<div className="col-12" key={index}>
														{parseInt(this.props.match.params.casa) > 3 || parseInt(this.props.match.params.casa) === 0 ? (
															item.casa > 3 ? (
																<div style={{ paddingLeft: "35px", }} key={index}>
																	{/* data do mes e dia da semana do evento */}
																	{/* <h5 className="item-descricao pt-3">
                                                                        <span className="data-numero">
                                                                            {diaEvento}
                                                                        </span>
                                                                        {' '}
                                                                        {diaDaSemanaProp}
                                                                    </h5> */}

																	<div className="text-left pt-5">
																		<h5 className="titulo-card">
																			{item.nome}
																		</h5>
																	</div>

																	<div className="text-left">
																		<h5 className="titulo-comissao">
																			{item.localCamara.nome}
																		</h5>
																	</div>

																	<div className="text-left">
																		<h5 className="titulo-data">
																			Sessão{" "}
																			{diaDaSemanaProp}, dia{" "}{diaProp} às{" "}{horaProp}h{minutoProp}
																		</h5>
																	</div>

																	{
																		// <h5 className="data-comissao">
																		//     {/* - {horaEvento}h{minutoEvento}{' '} */} dia hora evento
																		//     {item.localCamara.nome}
																		// </h5>
																	}

																	<p className="texto-evento">
																		<span className="seta"></span>
																		<span className="titulo-items">
																			{" "}
																			TIPO DO EVENTO:{" "}
																		</span>
																		{item.descricao_tipo}

																		<br />
																		<span className="seta"></span>
																		<span className="titulo-items">
																			{" "}
																			DESCRIÇÃO DO EVENTO:{" "}
																		</span>
																		{item.descricao}
																	</p>

																	{/* <hr /> */}
																</div>
															) : null
														) : null}
													</div>
												)
										) : parseInt(this.props.match.params.casa) > 3 || parseInt(this.props.match.params.casa) === 0 ? (
											item.casa > 3 ?

												// INFORMACOES DO AGENDAMENTO FEITO PELO FORMULARIO DE CADASTRO DE EVENTO

												(
													<div style={{ paddingLeft: "35px", }} key={index}>

														<div className="text-left pt-5">
															<h5 className="titulo-card">
																{item.nome}
															</h5>
														</div>

														<div className="text-left">
															<h5 className="titulo-comissao">
																{item.localCamara.nome}
															</h5>
														</div>

														<div className="text-left">
															<h5 className="titulo-data">
																Sessão {diaDaSemanaProp}, dia {diaProp} às{" "}{horaProp}h{minutoProp}
															</h5>
														</div>

														{
															// <h5 className="data-comissao">
															//     {/* - {horaEvento}h{minutoEvento}{' '} */} dia hora evento
															//     {item.localCamara.nome}
															// </h5>
														}

														<p className="texto-evento">
															<span className="seta"></span>
															<span className="titulo-items">
																{" "}
																TIPO DO EVENTO:{" "}
															</span>
															{item.descricao_tipo}

															<br />
															<span className="seta"></span>
															<span className="titulo-items">
																{" "}
																DESCRIÇÃO DO EVENTO:{" "}
															</span>
															{item.descricao}
														</p>

														{/* <hr /> */}
													</div>
												) : item.localCamara.nome === "Evento de Acontecimentos" ?

													// INFORMACOES DO AGENDAMENTO FEITO ATRAVES DO ACONTECIMENTO EM PROPOSICAO ESPECIFICA
													(
														<div style={{ paddingLeft: "35px", }} key={index}>
															{/* data do mes e dia da semana do evento */}
															{/* <h5 className="item-descricao pt-3">
                                                                        <span className="data-numero">
                                                                            {diaEvento}
                                                                        </span>
                                                                        {' '}
                                                                        {diaDaSemanaProp}
                                                                    </h5> */}

															<div className="text-left pt-5">
																<h5 className="titulo-card">
																	{item.nome}
																</h5>
															</div>

															<div className="text-left">
																<h5 className="titulo-comissao">
																	{item.localCamara.nome}
																</h5>
															</div>

															<div className="text-left">
																<h5 className="titulo-data">
																	Sessão {diaDaSemanaProp}, dia {diaProp} às{" "}{horaProp}h{minutoProp}
																</h5>
															</div>

															<p className="texto-evento">
																<span className="seta"></span>
																<span className="titulo-items">
																	{" "}
																	PROPOSIÇÃO:{" "}
																</span>
																{item.proposicao}

																<br />
																<span className="seta"></span>
																<span className="titulo-items">
																	{" "}
																	TIPO DO EVENTO:{" "}
																</span>
																{item.descricao_tipo}

																<br />
																<span className="seta"></span>
																<span className="titulo-items">
																	{" "}
																	DESCRIÇÃO DO EVENTO:{" "}
																</span>
																{item.descricao}
															</p>

															{/* <hr /> */}
														</div>
													)
													: null
										) : null;
								})
								: null}
						</div>
					)}
			</div>
		);
	}
}

export default AgendaJornal;
