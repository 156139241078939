import React from 'react';
// import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Grid from '@material-ui/core/Grid';
// import SelectFiltro from '../../componentes/forms/basic/selectFiltro'
// import AuthStore from '../../stores/authStore'
import moment from 'moment-timezone';
import { post, put } from '../../services/http'
// import Autocomplete from '@material-ui/lab/Autocomplete';

moment.tz.setDefault('UTC');

export default  function  FormDialog(props) {
    
    
    const [open, setOpen] = React.useState(false);
    // const [loading, setLoading] = React.useState(true);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }


    const descartarDiscurso = async ()  => {
        // console.log('item', item)
        const data = {
            item: props.dados
        }
        if( props.dados.id_parlamentar_discurso === null) {
            await post('parlamentar/discurso/descatar', data)
            .then(response => {
                // this.setState({ loading: false })
                window.location.reload()
            })
            .catch(err => err)
        } else {
            await put('parlamentar/discurso/descatar', data)
            .then(response => {
                // this.setState({ loading: false })
                window.location.reload()
            })
            .catch(err => err)
        }
    }

    // const videoDiscurso = async () => {
    //     // props.dados.url_video = "https://escriba.camara.leg.br/escriba-servicosweb/jsonVideo?urlJson=auditorio2_2020-02-13-14-00-00-000_240000"
    //     if(props.dados.url_video !== null && props.dados.url_video !== ""){
    //         let video =  props.dados.url_video !== null && props.dados.url_video !== "" ? props.dados.url_video : "" 
    //         window.open(`${video}`, "", "width=400,height=300, left=500, top=100");
    //     }
    // }

    // const audioDiscurso = async () => {
    //     // props.dados.url_audio = "https://escriba.camara.leg.br/escriba-servicosweb/jsonVideo?urlJson=auditorio2_2020-02-13-14-00-00-000_240000"
    //     if(props.dados.url_audio !== null && props.dados.url_audio !== ""){
    //         let audio = props.dados.url_audio !== null && props.dados.url_audio !== "" ? props.dados.url_audio : ""       
    //         window.open(`${audio}`, "", "width=400,height=300, left=500, top=100");
    //     }
    // }


    const tamanhoTela = window.innerWidth
    const dados = props.dados
    // console.log(dados)
    const usuarioEsforco = props.usuarioEsforco
    // console.log("usuário", usuarioEsforco)
    
    return (
        <div>
            <div onClick={handleClickOpen} style={{cursor: 'pointer'}}>
                {dados.sumario}
            </div>
            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title" >
                
                <DialogContent
                    style={{
                        width: tamanhoTela > 600 ? 600 : tamanhoTela,
                        backgroundColor: "white",
                        color: '#3E4049'
                    }}
                >
                    <div className="row">
                        <div className="col-md-3">
                            <div className="tituloModalDiscurso">
                                <b>Data</b> 
                            </div>
                            <div className="textoModalDiscurso">
                                {moment(dados.data).format('DD/MM/YYYY')}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tituloModalDiscurso">
                                <b>Hora</b> 
                            </div>
                            <div className="textoModalDiscurso">
                                {moment(dados.data).format('HH:mm')}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tituloModalDiscurso">
                                <b>Sessão</b> 
                            </div>
                            <div className="textoModalDiscurso text-center">
                                -
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="tituloModalDiscurso">
                                <b>Fase</b> 
                            </div>
                            <div className="textoModalDiscurso text-center">
                                -
                            </div>
                        </div>
                        {usuarioEsforco === false ?  
                            <div className="col-md-6 mt-2">
                                <div><b>Qualificação</b></div>
                                <div className="qualificacao-modal-parlamentar p-2 d-flex">
                                    <p className={`Client-circulo
                                        ${dados.qualificacao === "Convergente" ? "sucesso" : ""}
                                        ${dados.qualificacao === "Divergente" ? "negativo" : ""}
                                        ${dados.qualificacao === "Não Classificado" ? "pendente" : ""}
                                        ${dados.qualificacao === "Neutro" ? "neutro" : ""}
                                    `}></p>
                                    {dados.qualificacao ? dados.qualificacao : "Discursos não qualificado"}
                                </div> 
                            </div>
                            : null
                        }
                        <div className="col-12">
                            <hr/>
                        </div>

                       
                        <div className="col-md-6 mb-4">
                            <div className="tituloDiscurso">
                                <b>Texto na íntegra</b>
                            </div>
                        </div>
                        {/* <div className="col-md-6 d-flex justify-content-end mb-4">
                            <div className="border p-2">
                                <div className="d-flex discurso-audio-video"  onClick={audioDiscurso}>
                                    <img className="mr-1" alt="audio-discurso" src={require('../../design/assets/images/audio_icon.svg')} />
                                    <img alt="audio-discurso" src={require('../../design/assets/images/audio-discurso.svg')} />
                                </div>
                            </div>
                            <div className="ml-2 border p-2">
                                <div className="d-flex discurso-audio-video" onClick={videoDiscurso} >
                                    <img className="mr-1" alt="video-discurso" src={require('../../design/assets/images/videoplay_icon.svg')} />
                                    <img alt="video-discurso" src={require('../../design/assets/images/video-discurso.svg')} />
                                </div>
                            </div>
                        
                        </div> */}

                       
                        <div className="col-12 discurso" style={{ height: '250px', overflowX: 'auto' }}>
                            {dados.transcricao}
                        </div>

                        <hr/>

                        <div onClick={descartarDiscurso}  className="col-6 col-sm-6 col-lg-6 col-xl-6 p-3 text-center" style={{ cursor: 'pointer'}}>
                            DESCARTAR
                        </div>
                        <div onClick={handleClose} className="col-6 col-sm-6 col-lg-6 col-xl-6 p-3 text-center" style={{ cursor: 'pointer'}}>
                            FECHAR
                        </div>
                    
                    </div>
                
                </DialogContent>

            </Dialog>
        </div>
    );
}

