import React, { Component } from 'react'
import ContentEditable from 'react-contenteditable'
import { inject, observer } from 'mobx-react'
import UploadFile from '../../componentes/forms/inputs/upload'
import { post, get } from "../../services/http"
// import { post } from '../../services/apiNova'
// import DataHoraAcontecimento  from './dataHoraAcontecimento'
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

@inject('alertaStore', 'uploadStore', 'authStore')
@observer


class AcontecimentosNovoComentario extends Component {
    constructor(props) {
        super(props);
        this.salvar = this.salvar.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
            salvando: false,
            arquivo: [],
            formatado: props.valor || '',
            dataHora: '',
            hasMeeting: false,
            meetingDateTime: '',

            idProposicao: "",
            casaProposicao: 0,
            nomeCasaProposicao: "",
            siglaCasaProposicao: ""


        }
    }

    componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        this.getDadosProposicao(this.props.idProposicao)
        this.getDadosCasaProposicao(this.props.idProposicao)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.valor !== this.props.valor) {
            this.setState({ formatado: this.props.valor })
            document.getElementById(this.props.id).focus()
        }
    }

    onChange({ target: { value } }) {
        let formatado = value.replace(/<(?!br\s*\/?)[^>]+>/g, '')
        //let teste = formatado.match(regex.limpar)
        //console.log(teste)
        // if (formatado.match(regex.limpar))
        //     formatado = formatado.replace(regex.limpar, '$1') 
        this.setState({ formatado })

    }


    dataAtual(event) {
        const dataAcontecimento = event.target.value || new Date()
        this.setState({ dataHora: dataAcontecimento })
    }

    checkMeeting() {
        this.setState({
            hasMeeting: !this.state.hasMeeting
        })
    }

    mettingDate(event) {
        const dataHoraDaReuniao = event.target.value
        this.setState({
            meetingDateTime: dataHoraDaReuniao
        })
    }

    async getDadosProposicao(idProp) {
        await get(`/proposicao/especifica/${idProp}`)
            .then(res => {
                // console.log(res)
            })
    }

    async getDadosCasaProposicao(idProp) {
        await get(`/proposicao/obter-dados-casa/${idProp}`)
            .then(res => {
                // console.log(res)
                this.setState({
                    casaProposicao: res.casa,
                    nomeCasaProposicao: res.nome,
                    siglaCasaProposicao: res.sigla
                })
            })
    }



    async salvar() {

        if (this.state.hasMeeting === true) {
            const dataIncluirAgenda = {
                dataInicio: moment(this.state.meetingDateTime).format('YYYY-MM-DD'),
                horaInicio: moment(this.state.meetingDateTime).format('HH:mm'),
                local: "Evento de Acontecimentos",
                descricao: this.state.formatado,
                // pauta: "Pauta Acontecimentos Teste",
                prop_selecionada: {
                    id: this.props.idProposicao,
                    casa: this.state.nomeCasaProposicao,
                    id_casa: this.state.casaProposicao
                },
                casa: this.state.casaProposicao
            }

            post("/agenda/cadastro", dataIncluirAgenda)
                .then(res => {

                    var now = new Date()
                    const dataAcontecimento = {
                        data: this.state.dataHora === '' ? moment(now).format('YYYY-MM-DD') : this.state.dataHora,
                        texto: this.state.formatado,
                        idProposicao: this.props.idProposicao,
                        dataHoraReuniao: moment(res[0].data_inicio).format('YYYY-MM-DD HH:mm:ss'),
                        idEventoAgendado: res[0].id
                    }

                    post('/acontecimento', dataAcontecimento)
                        .then(res => {
                            if (res !== []) {
                                this.props.alertaStore.criar("Acontecimento e Agendamento foram criados com sucesso")
                                setTimeout(() => {
                                    window.location.reload()
                                }, 500);
                            } else {
                                this.props.alertaStore.erro("Seu Acontecimento e Agendamento não foram criados")
                                setTimeout(() => {
                                    window.location.reload()
                                }, 500);
                            }
                        })
                })
        }
        if (this.state.hasMeeting === false) {
            var now = new Date()
            const dataAcontecimento = {
                data: this.state.dataHora === '' ? moment(now).format('YYYY-MM-DD') : this.state.dataHora,
                texto: this.state.formatado,
                idProposicao: this.props.idProposicao,
                dataHoraReuniao: null,
                idEventoAgendado: null
            }

            post('/acontecimento', dataAcontecimento)
                .then(res => {
                    if (res !== []) {
                        this.props.alertaStore.criar("Acontecimento criado com sucesso")
                        setTimeout(() => {
                            window.location.reload()
                        }, 500);
                    } else {
                        this.props.alertaStore.erro("Seu Acontecimento não foi criado")
                        setTimeout(() => {
                            window.location.reload()
                        }, 500);
                    }
                })
        }
    }



    render() {
        // console.log("TTTTTTTTTTTTTTTTTTTTTTT ggg", this.state.formatado)
        // console.log('ID Proposicao ===>', this.props.idProposicao)
        // console.log("Data:", this.state.dataHora, "Data Reunião", this.state.meetingDate, "hora reunião", this.state.meetingTime);
        const { formatado } = this.state
        const tamanhoTela = window.innerWidth

        return <div>
            <div className="pl-4 pr-4 pt-4">
                <div className="row">
                    {
                        this.props.authStore.ehVisualizador === true ? null :
                            <>

                                {
                                    tamanhoTela < 600

                                        ?
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-lg-6 col-xl-6 mb-4">
                                                    <div>
                                                        <label htmlFor="data-atual" style={{ fontSize: "12px", fontWeight: "normal", color: "var(--linkText)" }}>Data de Registro</label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            style={{ fontSize: "16px", marginTop: "-10px", width: "100%", color: "lightgray", borderBottom: "1px solid lightgray" }}
                                                            type="date"
                                                            id="data-atual"
                                                            className="form-control"
                                                            onChange={event => this.dataAtual(event)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-md-4">
                                                            <div className="row" style={{ paddingLeft: "35px" }}>
                                                                <div style={{ paddingTop: "11.5px" }}>
                                                                    <input className="form-check-input"
                                                                        type="checkbox"
                                                                        id="hasMeeting"
                                                                        defaultChecked={this.state.hasMeeting}
                                                                        onChange={() => this.checkMeeting()}
                                                                    />
                                                                </div>
                                                                <label style={{ marginTop: "12px", marginLeft: "4px", fontSize: "14px" }}>
                                                                    Incluir na Agenda
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {this.state.hasMeeting === true ?
                                                            <div className="col-12 col-md-4">
                                                                <input
                                                                    style={{ fontSize: "16px", marginTop: "-10px", width: "100%", color: "lightgray", borderBottom: "1px solid lightgray" }}
                                                                    type="datetime-local"
                                                                    id="data-atual"
                                                                    className="form-control"
                                                                    onChange={event => this.mettingDate(event)}

                                                                />
                                                            </div>
                                                            :
                                                            null
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-lg-6 col-xl-6 mb-4">
                                                    <div>
                                                        <label htmlFor="data-atual" style={{ fontSize: "12px", fontWeight: "normal", color: "var(--linkText)" }}>Data de Registro</label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            style={{ fontSize: "16px", marginTop: "-10px", width: "100%", color: "lightgray", borderBottom: "1px solid lightgray" }}
                                                            type="date"
                                                            id="data-atual"
                                                            className="form-control"
                                                            onChange={event => this.dataAtual(event)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-md-4">
                                                            <div className="row" style={{ paddingLeft: "35px" }}>
                                                                <div style={{ paddingTop: "8px" }}>
                                                                    <input className="form-check-input"
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px"
                                                                        }}
                                                                        type="checkbox"
                                                                        id="hasMeeting"
                                                                        defaultChecked={this.state.hasMeeting}
                                                                        onChange={() => this.checkMeeting()}
                                                                    />
                                                                </div>
                                                                <label style={{ marginTop: "12px", marginLeft: "8px", fontSize: "15px" }}>
                                                                    Incluir na Agenda
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {this.state.hasMeeting === true ?
                                                            <div className="col-12 col-md-8">
                                                                <input
                                                                    style={{ fontSize: "16px", marginTop: "-10px", width: "100%", color: "lightgray", borderBottom: "1px solid lightgray" }}
                                                                    type="datetime-local"
                                                                    id="data-atual"
                                                                    className="form-control"
                                                                    onChange={event => this.mettingDate(event)}

                                                                />
                                                            </div>
                                                            :
                                                            null
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                }

                                {
                                    tamanhoTela < 600 ?
                                        // =====MOBILE======
                                        <div style={{ marginLeft: "-10px", marginRight: "-10px", width: "108%" }}>
                                            <hr style={{ width: "100%" }} />
                                        </div>
                                        :
                                        null
                                }



                                <div className="col-12 col-sm-12 col-lg-12 col-xl-12 text-truncate text-wrap overflow-auto" style={{ backgroundColor: "#f7f7f7", marginTop: "10px", borderRadius: "3px", minHeight: "70px" }}>
                                    <div className="pt-2 pb-2">
                                        <ContentEditable
                                            // id={this.props.id}
                                            id="acao-preparatoria-acontecimento-comentario"
                                            className="border-none shadow-none"
                                            html={formatado}
                                            placeholder={"Digite aqui"}
                                            onChange={this.onChange} />
                                    </div>
                                </div>



                            </>
                    }
                    <div className="row"></div>

                </div>
            </div>

            {
                tamanhoTela < 600
                    ?
                    // ======MOBILE======
                    <div className="pl-4">
                        <div className="row mb-2 d-flex justify-content-start">
                            {
                                this.state.arquivo !== undefined &&
                                    Array.isArray(this.state.arquivo) &&
                                    this.state.arquivo.length > 0 ?
                                    this.state.arquivo.map((item, index) =>
                                        <div className=" mt-2 mb-2 mr-3 " key={item.arquivo} >
                                            <div className=" d-flex border p-2 " style={{ borderRadius: "2px" }}>

                                                <div className=" mt-1 pl-2 row cursor-pointer" style={{ width: "290px" }} onClick={() => this.props.uploadStore.downloadArquivo(item, this.refs).then(x => x)}>
                                                    <div className=" ml-2" style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                    <span className=" ml-2 Client-link text-truncate" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px", overflow: "hidden", fontSize: "12px" }}>{item.nome}</span>

                                                </div>

                                                {this.props.authStore.ehVisualizador === true ? null :
                                                    <span className="float-right ml-2 cursor-pointer" onClick={() => {
                                                        // this.props.uploadStore.removerAnexo(item);
                                                        // this.setState({ arquivo: this.state.arquivo.filter(anexo => anexo.arquivo !== item.arquivo) });
                                                        if (item.id === undefined) {
                                                            item = { nome: item.nome, id_referencia: this.props.idProposicao }
                                                            this.props.uploadStore.removerAnexoSemId(item);
                                                            this.state.arquivo.splice(index, 1)
                                                            this.setState({ arquivo: this.state.arquivo });
                                                        } else {
                                                            this.props.uploadStore.removerAnexo(item);
                                                            this.setState({ arquivo: this.state.arquivo.filter(anexo => anexo.arquivo !== item.arquivo) });
                                                        }
                                                    }}>
                                                        <img src={require('../../design/assets/images/trash-gray.svg')} alt="excluir anexo" />
                                                    </span>
                                                }
                                            </div>
                                        </div>) : null}
                        </div>
                    </div>

                    :
                    // =======desk=========
                    <div className="pl-4">
                        <div className="row mb-2 d-flex justify-content-start">
                            {
                                this.state.arquivo !== undefined &&
                                    Array.isArray(this.state.arquivo) &&
                                    this.state.arquivo.length > 0 ?
                                    this.state.arquivo.map((item, index) =>
                                        <div className=" mt-2 mb-2 mr-3 " key={item.arquivo} >
                                            <div className=" d-flex border p-2 " style={{ borderRadius: "2px" }}>

                                                <div className=" mt-1 pl-2 row cursor-pointer" style={{ width: "315px" }} onClick={() => this.props.uploadStore.downloadArquivo(item, this.refs).then(x => x)}>
                                                    <div className=" ml-2" style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                    <span className=" ml-2 Client-link text-truncate" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px", overflow: "hidden", fontSize: "12px" }}>{item.nome}</span>

                                                </div>

                                                {this.props.authStore.ehVisualizador === true ? null :
                                                    <span className="float-right ml-2 cursor-pointer" onClick={() => {
                                                        // this.props.uploadStore.removerAnexo(item);
                                                        // this.setState({ arquivo: this.state.arquivo.filter(anexo => anexo.arquivo !== item.arquivo) });
                                                        if (item.id === undefined) {
                                                            item = { nome: item.nome, id_referencia: this.props.idProposicao }
                                                            this.props.uploadStore.removerAnexoSemId(item);
                                                            this.state.arquivo.splice(index, 1)
                                                            this.setState({ arquivo: this.state.arquivo });
                                                        } else {
                                                            this.props.uploadStore.removerAnexo(item);
                                                            this.setState({ arquivo: this.state.arquivo.filter(anexo => anexo.arquivo !== item.arquivo) });
                                                        }
                                                    }}>
                                                        <img src={require('../../design/assets/images/trash-gray.svg')} alt="excluir anexo" />
                                                    </span>
                                                }
                                            </div>
                                        </div>) : null}
                        </div>
                    </div>
            }


            {
                tamanhoTela < 600 ?
                    // =======MOBILE======
                    <div>
                        <hr className="mb-0" />
                        <div className="w-100 text-right pl-3 pb-2 pt-2 clearfix">
                            <div className="float-right mr-4">
                                {this.props.authStore.ehVisualizador === true ? null :
                                    <div>
                                        <img className='mr-2 send-icon' src={formatado.length > 0 ? require('../../design/assets/images/send.svg') : require('../../design/assets/images/send.svg')} alt="" />
                                        <span style={{ textDecorationLine: "underline", color: "#9B9B9B" }} onClick={this.salvar}>
                                            Enviar
                                        </span>
                                        {/* <span className={formatado.length > 0 ? 'Client-cinza-claro cursor-pointer' : 'Client-cinza-claro'} style={{textDecoration:"undeline"}} onClick={this.salvar}>
                                            {this.state.salvando ? 'Enviando' : 'Enviar'}
                                        </span> */}
                                    </div>
                                }
                            </div>
                            {this.props.authStore.ehVisualizador === true ? null :
                                <div className="float-left">
                                    <UploadFile linkButton={true} modulo={'acontecimentos'} id_referencia={this.props.idProposicao} onChange={anexo => this.setState({ arquivo: [...this.state.arquivo, ...anexo] })} />
                                </div>
                            }
                        </div>
                    </div>
                    :
                    // =======DESK======
                    <div>
                        <hr className="mb-0" />
                        <div className="w-100 text-right pl-3 pb-2 pt-2 clearfix">
                            <div className="float-right mr-4">
                                {this.props.authStore.ehVisualizador === true ? null :
                                    <div>
                                        <img className='mr-2 send-icon' src={formatado.length > 0 ? require('../../design/assets/images/send.svg') : require('../../design/assets/images/send.svg')} alt="" />
                                        <span style={{ textDecorationLine: "underline", color: "#9B9B9B" }} onClick={this.salvar}>
                                            Enviar
                                        </span>
                                        {/* <span className={formatado.length > 0 ? 'Client-cinza-claro cursor-pointer' : 'Client-cinza-claro'} style={{textDecoration:"undeline"}} onClick={this.salvar}>
                                            {this.state.salvando ? 'Enviando' : 'Enviar'}
                                        </span> */}
                                    </div>
                                }
                            </div>
                            {this.props.authStore.ehVisualizador === true ? null :
                                <div className="float-left">
                                    <UploadFile linkButton={true} modulo={'acontecimentos'} id_referencia={this.props.idProposicao} onChange={anexo => this.setState({ arquivo: [...this.state.arquivo, ...anexo] })} />
                                </div>
                            }
                        </div>
                    </div>




            }
        </div>
    }
}

export default props => <AcontecimentosNovoComentario {...props} />