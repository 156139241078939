import React, { Component, Fragment } from 'react';
import Loading from '../../componentes/loading';

class DetalharProposicoes extends Component {
  state = {
    loading: false
  }

  componentDidMount() {
    const user = JSON.parse(window.localStorage.getItem('user'))
    if(user.id !== 449) {
      window.location.href = "/"
    }
  }

  render() {
    return this.state.loading ? <Loading /> :
      <Fragment>
        <div>
            {/* Detalhar proposicao aqui! */}
        </div>
      </Fragment>
  }

}
export default DetalharProposicoes