import React, { Component } from 'react';
import Loading from '../../componentes/loading';

import GerenciamentoUsuarios from './gerenciamento-usuarios'
import RelatorioUsuarios from './relatorio-usuarios'
import Departamentos from './departamentos'
import { get } from "../../services/http";
// import Gerenciamento from '../gerenciamento'
// import Perfil from '../perfil'
// import FormUsuario from '../form/formUsuario'


import { NavLink } from 'react-router-dom'
import Slider from 'react-slick'
import { inject, observer } from 'mobx-react'

const sliderSettingsInsights = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 4,
    initialSlide: 0,
    usuarioDepartamento: [],
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false,
                dots: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

@inject('authStore')
@observer

class Insights extends Component {
    state = {
        loading: false,
        user: JSON.parse(window.localStorage.getItem('user')),
    }


    componentWillMount() {
        this.getDepartamentos()
        // if(this.props.authStore.ehEsforcoColaborativo === true) {
        //     window.location.href = '/'
        // }
    }

    async getDepartamentos() {
        await get(`/usuarios/usuario-departamento`)
            .then(resp => {
                //console.log('RESP>>>>>',resp)
                this.setState({ usuarioDepartamento: resp[0].descricao })
            })
            .catch(err => err)
    }

    imprimirPagina() {
        window.print()
    }



    render() {

        //console.log(this.state.user)

        const path = this.props.match.params.assunto
        //console.log('path>>>>',path)


        var permissao = ''
        switch (this.state.user.codigoPermissao) {
            case 1:
                permissao = 'Master';
                break;
            case 2:
                permissao = 'Normal';
                break;
            case 3:
                permissao = 'Read Only';
                break;
            case 4:
                permissao = 'Esforço Colaborativo';
                break;
            default:
                console.log('permissao');
        }

        //console.log(this.state.usuarioDepartamento)

        return (
            <div >
                <div className=" bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                    <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                        CONFIGURAÇÕES DO USUÁRIO
                        </p>
                </div>
                <div className="no-print container-fluid pt-2" style={{ marginTop: '30px', marginBottom: '50px' }}>
                    <div style={{ background: 'white', padding: '20px' }}>
                        <div className="row">

                            <div className="col-md-1"></div>
                            <div className="col-md-2">
                                <p><b>Usuário ID</b></p>
                                <p>{this.state.user.id}</p>
                            </div>
                            <div className="col-md-2">
                                <p><b>Nome</b></p>
                                <p>{this.state.user.nome}</p>
                            </div>
                            <div className="col-md-2">
                                <p><b>Departamento</b></p>
                                <p>{this.state.usuarioDepartamento}</p>
                                {/* {
                                departamentos !== undefined && Array.isArray(departamentos) && departamentos.length > 0 ?
                                departamentos.map((dep, index) => {
                                    return (
                                        this.state.user.departamento === dep.id ?
                                        <div key={index}>
                                            <p>{dep.descricao}</p>
                                        </div>
                                        : null
                                    )
                                }) : null} */}
                            </div>
                            <div className="col-md-2">
                                <p><b>Permissão</b></p>
                                <p>{permissao}</p>
                            </div>
                            <div className="col-md-2">
                                <p><b>Email</b></p>
                                <p>{this.state.user.email}</p>
                            </div>
                            <div className="col-md-1"></div>

                        </div>
                    </div>
                </div>
                <div style={{ paddingRight: "20px" }}>
                    <div style={{ float: "right" }}>
                        <button style={{ width: "150px" }} className="btn btn-danger btn-block" onClick={this.imprimirPagina}>
                            <img
                                className="mr-3"
                                src={require('../../design/assets/images/impressora.svg')}
                                alt="anexo"
                            />{' '}
                        IMPRIMIR
                        </button>
                    </div>
                </div>


                {/* <Breadcrumb /> */}
                {
                    this.props.authStore.ehAdministrador === true
                        ?
                        <article className=' d-block mt-2' style={{ clear: "both" }}>
                            <section className="container-fluid pt-5">
                                {
                                    this.state.loading ? <Loading /> :
                                        <div >

                                            <div className="slider-usuario" style={{ margin: '3px' }}>
                                                <Slider {...sliderSettingsInsights}>
                                                    <div className="bordas-links-usuario ">
                                                        <NavLink to={`/usuario/gerenciamento-usuarios`} className="nav-link" activeClassName="active">
                                                            <div className="remove-link-usuario">
                                                                Usuários
                                                                    </div>
                                                        </NavLink>
                                                    </div>


                                                    <div className="bordas-links-usuario">
                                                        <NavLink to={`/usuario/departamentos`} className="nav-link" activeClassName="active">
                                                            <div className="remove-link-usuario">
                                                                Departamentos
                                                                    </div>
                                                        </NavLink>
                                                    </div>

                                                    <div className="bordas-links-usuario">
                                                        <NavLink to={`/usuario/relatorios`} className="nav-link" activeClassName="active">
                                                            <div className="remove-link-usuario">
                                                                Histórico de Atividades
                                                                    </div>
                                                        </NavLink>
                                                    </div>



                                                </Slider>
                                            </div>
                                            <div className="row mt-2" style={{ backgroundColor: 'white', borderRadius: "3px", margin: '3px 3px 15px 3px' }}>
                                                <div className="col-12" >

                                                    {path === 'gerenciamento-usuarios' ?
                                                        <GerenciamentoUsuarios />
                                                        : null}

                                                    {path === 'departamentos' ?
                                                        <Departamentos />
                                                        : null}

                                                    {path === 'relatorios' ?
                                                        <RelatorioUsuarios />
                                                        : null}



                                                </div>
                                            </div>
                                        </div>
                                }
                            </section>
                        </article>
                        :
                        null
                }


            </div>
        )
    }
}

export default Insights