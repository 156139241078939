import React, { Component, Fragment } from 'react';
import Loading from '../../componentes/loading';
import FormLegislativo from './formLegislativo'
import FormExecutivo from './formExecutivo'

class FiltrarProposicoes extends Component {
  state = {
    loading: false,
    filtroAtivo: 'legislativo',
    filtrodarkBlueClient: []
  }

  componentDidMount() {
    // const user = JSON.parse(window.localStorage.getItem('user'))
    // if(user.id !== 449) {
    //   window.location.href = "/"
    // }
    const filtroLocal = window.localStorage.getItem('filtrodarkBlueClient')
      //const filtroLocal = window.localStorage.getItem('filtrodarkBlueClient') !== undefined && window.localStorage.getItem('filtrodarkBlueClient') !== null ? JSON.parse(window.localStorage.getItem('filtrodarkBlueClient')) : null
    //   console.log('filtroLocal', filtroLocal)
      const filtroEscolhido = window.localStorage.getItem('filtro-selecao')
    //   console.log('filtro-selecao ===>', filtroEscolhido)
      this.setState({ filtroAtivo: filtroEscolhido, filtrodarkBlueClient: filtroLocal })
  }
  

  render() {
    //   console.log('filtroAtivo ===>',this.state.filtroAtivo)
    //   console.log('filtrodarkBlueClient ===>',this.state.filtrodarkBlueClient)
    // const heightScreen = window.innerHeight
    return this.state.loading ? <Loading /> :
      <Fragment>
        <div className="area-total">
            <div className="container-proposicao">

                <div className="row">

                    {/* AREA DE FORMULARIO */}
                    {/* <div className="col-sm-9 area-formulario-filtro">

                        <div className="row">
                            <div className="col-12 titulo-filtro titulo-filtro_custom">
                                Busca Municipais/Estaduais
                            </div> */}

                            {
                                this.state.filtroAtivo === 'legislativo' 
                                ? 
                                    <FormLegislativo />
                                :
                                    <FormExecutivo />
                            }
{/* 
                        </div>

                    </div> */}

                    {/* AREA DE PESQUISA */}
                    {/* <div className="col-sm-3 area-pesquisa-filtro" style={{ height: heightScreen }}>
                        <div className="row padding-pesquisa">
                            <div className="col-12 titulo-pesquisa">
                                Sua pesquisa
                            </div>



                            {this.state.filtrodarkBlueClient !== null && this.state.filtrodarkBlueClient.length > 0 ? this.state.filtrodarkBlueClient.map(filtro => {
                                return (
                                    <div className="col-12 pesquisa-selecao">
                                        <div className="tipo-pesquisa-selecao">
                                            {filtro.label}
                                        </div>
                                        <div className="descricao-pesquisa-selecao">
                                            <div className="row">
                                                <div className="col-9 "style={{ fontSize: '12px',textAlign:'left',paddingTop: '6px' }} >{filtro.value}</div>
                                                <div onClick={this.excluirSelecao} className="col-3 area-excluir-selecao">
                                                    <div className="p area-excluir-selecao-x">x</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : null}
                            
                            
                            <div className="col-12">
                                <button className="btn-block botao-resetar-filtro" onClick={this.resetarFiltro}>
                                <img
                                    className="mr-2"
                                    src={require('../../design/assets/images/resetar-filtro.png')}
                                    alt="anexo"
                                    />{' '}
                                    Resetar Filtro
                                </button>
                            </div>


                        </div>
                    </div> */}
                </div>

            </div>
        </div>
      </Fragment>
  }

}
export default FiltrarProposicoes