import moment from 'moment-timezone';
// import ModalAviso from './modalAvisoCadastroProposicao'
import React, { useState, useCallback } from "react"
import { get, post, put } from "../../services/http";
import ClientSelect from '../../componentes/forms/basic/clientSelectModalCadUser'
import { useEffect } from 'react';
import uuid from 'uuid/v1'
import alertaStore from '../../stores/alertaStore'
import LoadingCircle from '../../componentes/loadingCircle'

moment.tz.setDefault('UTC');

const inputStyle = {
    border: 'none',
    backgroundColor: 'transparent',
    borderBottom: '1px solid #C4C4C4',
    color: '#000000',
    marginTop: '10px',
    width: '100%',
    placeholder: 'red',
    outline: 'none'
};

const inputStyleA = {
    border: 'none',
    backgroundColor: 'transparent',
    borderBottom: '1px solid #C4C4C4',
    color: '#000000',
    marginTop: '13px',
    width: '100%',
    placeholder: 'red',
    outline: 'none',
    height: "25px"
};


const editarProposicao = ({ match }) => {

    const idProposicao = match.params.id


    const [uf, setUf] = useState('')
    const [estadoLista, setEstadoLista] = useState([])
    const [casaSelecionada, setCasaSelecionada] = useState([])
    const [listaCasas, setListaCasas] = useState([])
    const [loadingCircle, setLoadingCircle] = React.useState(false);
    const [docType, setDocType] = useState('')
    const [inputPalavras, setInputPalavras] = useState('')
    const [inputTemas, setInputTemas] = useState('')
    const [arrayPalavras, setArrayPalavras] = useState([])
    const [arrayTemas, setArrayTemas] = useState([])
    const [dataForm, setDataForm] = useState({
        numero: "",
        data_apresentacao: "",
        ementa: "",
        texto: "",
        tipo_documento: "",
        casa_legislativa: [],
        prop_selecionada: [],
        autor: [],
        tema: [],
        linkExterno: "",
        inteiroTeor: "",
    })
    const [valorProp, setValorProp] = useState('')
    const [listaProposicoes, setListaProposicoes] = useState([])
    const [propSelecionada, setPropSelecionada] = useState([])



    //armazena os erros dos campos em um objeto
    const [errors, setErrors] = useState({})
    const [errorsLink, setErrorsLink] = useState({})
    //Faço a validação do Form, se existir erro >> true, se nao false, seto inicialmente com true para nao chamar a função dentro do UseEffect
    const [validaErrors, setValidaErrors] = useState(true)
    const [validaErrorsLinksLinkExterno, setValidaErrorsLinksLinkExterno] = useState(false)
    const [validaErrorsLinksInteiroTeor, setValidaErrorsLinksInteiroTeor] = useState(false)


    const [is_Mounted, setIs_Mounted] = useState(false)

    const tipos = [
        { value: 1, label: 'PLN' },
        { value: 2, label: 'MCN' },
        { value: 3, label: 'MSF' },
        { value: 4, label: 'PDC' },
        { value: 5, label: 'RQS' },
        { value: 6, label: 'REQ' },
        { value: 7, label: 'PEC' },
        { value: 8, label: 'PLP' },
        { value: 9, label: 'PL' },
        { value: 10, label: 'PLC' },
        { value: 11, label: 'PLS' },
        { value: 12, label: 'PRC' },
        { value: 13, label: 'PLV' },
        { value: 15, label: 'MPV' },
        { value: 16, label: 'PFC' },
        { value: 17, label: 'RCP' },
        { value: 18, label: 'SCD' },
        { value: 19, label: 'PRS' },
        { value: 20, label: 'PFS' },
        { value: 21, label: 'MSC' },
        { value: 22, label: 'VET' },
        { value: 23, label: 'PDS' },
        { value: 23, label: 'PDL' },
    ]




    useEffect(() => {

        if (is_Mounted === false) {
            obterEstados()
            obterDadosProposicao()
        }


        //Se não existir erros, e validaErrors false
        //coloco a consta validaErrors, para ficar verifcando se ouve atualização nela.
        if (Object.keys(errorsLink).length === 0 && Object.keys(errors).length === 0 && validaErrors === false && validaErrorsLinksLinkExterno === false && validaErrorsLinksInteiroTeor === false) {

            onSubmit()
        }
    }, [validaErrors, errorsLink, validaErrorsLinksLinkExterno, validaErrorsLinksInteiroTeor]);


    const obterEstados = async () => {

        await get(`/proposicao/estados`)
            .then(res => {
                setEstadoLista(res)

            })
            .catch(e => {
                console.log(e)
            })
    }

    // const obterEstados =  useCallback(
    //     async () => {
    //         await get(`/proposicao/estados`)
    //         .then(res => {
    //             setEstadoLista(res)

    //         })
    //         .catch(e => {
    //             console.log(e)
    //         })  
    //     },
    //     [estadoLista],
    // )
    const obterDadosProposicao = useCallback(
        async () => {


            setLoadingCircle(true)

            await get(`/proposicao/obter-dados-editar/${idProposicao}`)
                .then(res => {

                    // console.log('RES>>>>', res)


                    let data_apresentacao = moment(res.dadosProposicao[0].data_apresentacao).format('YYYY-MM-DD')

                    setDataForm({
                        numero: res.dadosProposicao[0].numero,
                        data_apresentacao,
                        ementa: res.dadosProposicao[0].ementa,
                        texto: res.dadosProposicao[0].texto,
                        tipo_documento: tipos.map(p => p.value === res.dadosProposicao[0].cod_tipo ? { value: p.value, label: p.label } : null).filter(x => x),
                        casa_legislativa: res.casa_legislativa,
                        autor: res.autores,
                        tema: res.temas,
                        prop_selecionada: { id: res.prop_selecionada.value },
                        linkExterno: res.dadosProposicao[0].uri,
                        inteiroTeor: res.dadosProposicao[0].uri_inteiro_teor

                    })
                    setValorProp(res.prop_selecionada.label)
                    setPropSelecionada(res.prop_selecionada)
                    setListaProposicoes([res.prop_selecionada])
                    setCasaSelecionada(res.casa_legislativa)
                    setDocType(tipos.map(p => p.value === res.dadosProposicao[0].cod_tipo ? { value: p.value, label: p.label } : null).filter(x => x))
                    setArrayPalavras(res.autores)
                    setArrayTemas(res.temas)
                    //setUf(res.estado)
                    onChangeObterCasasByEstado(res.estado)
                    setLoadingCircle(false)
                    setIs_Mounted(true)

                })
                .catch(e => {
                    console.log(e)
                })



        },
        [dataForm],
    )



    const obterProposicoes = async (propEscrita) => {
        //console.log("propEscrita", propEscrita)
        setValorProp(propEscrita)
        await get(`insights/proposicoes/?tipo=${propEscrita}`)
            //   await get(`insights/proposicoes/?tipo=PL1978/2020`)

            .then(res => {
                //console.log("propEscrita", res)
                setListaProposicoes(res)

            })
            .catch(e => {
                console.log(e)
            })
    }


    async function onChangeObterCasasByEstado(obj) {
        //console.log('Cheguei')
        setUf(obj)
        await get(`/proposicao/casas-estado/${obj.value}`)
            .then(response => {
                //console.log("response >>>>>", response)
                setListaCasas(response)
            })
            .catch(e => {
                console.log(e)
            })
    }

    function handleChangeSelectCasa(value) {
        //console.log(value)
        setCasaSelecionada(value)
        setDataForm({
            ...dataForm,
            casa_legislativa: { id: value.id, sigla: value.sigla }
        })

    }

    function handleChangeSelectProp(value) {
        // console.log("handle: ", value)
        setPropSelecionada(value)
        setDataForm({
            ...dataForm,
            prop_selecionada: { id: value.value }
        })

    }

    function handleChangeSelectTipoDoc(value) {
        // console.log(value)
        setDocType(value)
        setDataForm({
            ...dataForm,
            tipo_documento: value
        })
    }



    const handleChange = (event) => {

        setDataForm({
            ...dataForm,
            [event.target.name]: event.target.value
        })

    }



    function validate() {

        //console.log(dadosForm.id_departamento)
        let messageError = '*Campo Obrigatorio'
        let errors = {}

        if (!uf) {
            errors.estado = messageError
        }
        if (casaSelecionada.length === 0) {
            errors.casa = messageError
        }
        if (!docType) {
            errors.tipoDoc = messageError
        }
        if (!dataForm.numero) {
            errors.numero = messageError
        }
        if (!dataForm.data_apresentacao) {
            errors.data_apresentacao = messageError
        }
        if (!dataForm.ementa) {
            errors.ementa = messageError
        }
        if (!dataForm.texto) {
            errors.texto = messageError
        }

        if (arrayPalavras.length === 0) {
            errors.autor = messageError
        }

        if (arrayTemas.length === 0) {
            errors.tema = messageError
        }



        //atualizo a const, se existir erros, true, se não false
        //Object.keys(errors).length verifica se existe conteudo dentro do objeto
        Object.keys(errors).length === 0 ? setValidaErrors(false) : setValidaErrors(true)

        return errors
    }


    //função intermediaria que auxilia na validação dos campos
    const handleSubmit = () => {

        setErrors(validate())
        //console.log(validate())   
        //retorna um objeto de errors
        //exemplo {departamento: "Erro" , nome: "Erro"}

    }

    const onSubmit = async () => {

        setLoadingCircle(true)

        dataForm["id_proposicao"] = idProposicao
        await put("/proposicao-em/atualizar-proposicao", dataForm)
            .then(response => {
                //console.log("response: ", response)
                if (response === undefined) {
                    setLoadingCircle(false)
                    alertaStore.erro("Erro ao atualizar proposicao")
                }
                else {

                    alertaStore.criar("Proposição atualizada com sucesso")
                    setLoadingCircle(true)
                    setIs_Mounted(false)
                    setTimeout(() => window.location.href = '/proposicao/lista', 1500)

                }
            })
            .catch(err => err)


    }

    const handleAdd = async (e) => {
        e.preventDefault()

        setArrayPalavras([...arrayPalavras, { id: uuid(), nome: inputPalavras }])
        setInputPalavras('')

        setDataForm({
            ...dataForm,
            autor: [...arrayPalavras, { id: uuid(), nome: inputPalavras }]
        })

    }

    const handleDel = async (nome, id) => {

        await post(`/proposicao/editar-excluir-autor/${nome}/${idProposicao}`)
            .then(response => {
                console.log(response)
            })
            .catch(e => {
                console.log(e)
            })

        //faço uma copia do array antigo e add em um novo array
        const newArrayPalavras = arrayPalavras.slice(0)
        //console.log('>>',newArrayPalavras)

        //Pego o index do array escolhido para exclusao da lista
        const index = newArrayPalavras.findIndex(palavra => palavra.id === id)
        //console.log('>>',index)

        //removo a palavra selecionada apartir do seu index do array
        newArrayPalavras.splice(index, 1)
        //console.log ('>>',newArrayPalavras)

        //add o novo array ao estado atual do componente
        setArrayPalavras([...newArrayPalavras])

        setDataForm({
            ...dataForm,
            autor: [...newArrayPalavras]
        })

    }


    const handleDelTemas = async (tema, id) => {

        await post(`/proposicao/editar-excluir-tema/${tema}/${idProposicao}`)
            .then(response => {
                console.log(response)
            })
            .catch(e => {
                console.log(e)
            })

        //faço uma copia do array antigo e add em um novo array
        const newArrayTemas = arrayTemas.slice(0)
        //console.log('>>',newArrayPalavras)

        //Pego o index do array escolhido para exclusao da lista
        const index = newArrayTemas.findIndex(tema => tema.id === id)
        //console.log('>>',index)

        //removo a palavra selecionada apartir do seu index do array
        newArrayTemas.splice(index, 1)
        //console.log ('>>',newArrayPalavras)

        //add o novo array ao estado atual do componente
        setArrayTemas([...newArrayTemas])

        setDataForm({
            ...dataForm,
            tema: [...newArrayTemas]
        })

    }

    const handleAddTemas = async (e) => {


        e.preventDefault()

        setArrayTemas([...arrayTemas, { id: uuid(), nome: inputTemas }])
        setInputTemas('')

        setDataForm({
            ...dataForm,
            tema: [...arrayTemas, { id: uuid(), nome: inputTemas }]
        })


    }

    const validaLinks = (event) => {

        //console.log(0)

        var regex = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");// eslint-disable-line

        if (event.target.name === 'linkExterno') {
            //console.log(1, event.target.value)



            if (event.target.value !== '') {
                //console.log(2)


                if (!regex.test(event.target.value)) {
                    //console.log(3)


                    errorsLink.linkExterno = '*URL Inválida'

                    setErrorsLink({ ...errorsLink })
                    setValidaErrorsLinksLinkExterno(true)
                } else {
                    delete errorsLink.linkExterno
                    setErrorsLink({ ...errorsLink })
                    setValidaErrorsLinksLinkExterno(false)
                }
            }

        }
        if (event.target.name === 'inteiroTeor') {

            if (event.target.value !== '') {

                if (!regex.test(event.target.value)) {

                    errorsLink.inteiroTeor = '*URL Inválida'

                    setErrorsLink({ ...errorsLink })
                    setValidaErrorsLinksInteiroTeor(true)
                } else {
                    delete errorsLink.inteiroTeor
                    setErrorsLink({ ...errorsLink })
                    setValidaErrorsLinksInteiroTeor(false)
                }

            }

        }


    }

    // console.log("DATA>>>", dataForm)
    //console.log(">>>",valorProp)  
    //console.log("dataForm: ", errorsLink)     
    return (
        <div>

            <div className="col-12 bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                    EDITAR PROPOSIÇÃO
                    </p>
            </div>

            {

                loadingCircle
                    ?

                    <div className=" " style={{ height: "470px", alignItems: "center", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                        <div className="row text-center">
                            <div className="col-12 text-center">
                                <h4>Aguardando a validação...</h4>
                            </div>
                            <div className="col-12 text-center">
                                <LoadingCircle color={'#DC2D41'} size={'30px'} />
                            </div>
                        </div>
                    </div>
                    :

                    <div className="container pl-3 pr-3">
                        <h3 className="mb-3 topSpaceCadastroProp">Editar Proposição</h3>
                        <div className="bg-white marginCadastroProp">

                            <form>
                                <div className="row">
                                    <div className="col-md-4 ">
                                        <label style={{ color: "var(--linkText)" }}>Unidade Federativa</label>
                                        <ClientSelect
                                            css="no-border"
                                            name="estado"
                                            value={uf}
                                            options={
                                                estadoLista !== undefined && Array.isArray(estadoLista) && estadoLista.length > 0 ?
                                                    estadoLista.map((item) => ({
                                                        value: item.id,
                                                        label: item.sigla
                                                    })) : null}
                                            onChange={(uf) => onChangeObterCasasByEstado(uf)}
                                            placeholder={"Selecionar"}
                                        />
                                        {errors.estado && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.estado}</span>}
                                    </div>


                                    <div className="col-md-4 MarginTopCampoCadastroProp">
                                        <label style={{ color: "var(--linkText)" }}>Casa Legislativa</label>

                                        <ClientSelect
                                            css="no-border"
                                            name="casa"
                                            value={casaSelecionada}
                                            options={
                                                listaCasas !== undefined && Array.isArray(listaCasas) && listaCasas.length > 0 ?
                                                    listaCasas.map((item) => ({ value: item.id, label: item.sigla + ' - ' + item.nome, sigla: item.sigla, id: item.id })) : null}
                                            onChange={(newValue) => handleChangeSelectCasa(newValue)}
                                            placeholder={"Selecionar"}
                                        />
                                        {errors.casa && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.casa}</span>}


                                    </div>
                                    <div className="col-md-4 MarginTopCampoCadastroProp">
                                        <label style={{ color: "var(--linkText)" }}>Tipo do Documento</label>
                                        <ClientSelect
                                            css="no-border"
                                            name="tipoDoc"
                                            value={docType}
                                            options={
                                                tipos !== undefined && Array.isArray(tipos) && tipos.length > 0 ?
                                                    tipos.map((item) => ({ value: item.value, label: item.label })) : null
                                            }
                                            onChange={(value) => handleChangeSelectTipoDoc(value)}
                                            placeholder={"Selecionar"}
                                        />
                                        {errors.tipoDoc && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.tipoDoc}</span>}

                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <label style={{ color: "var(--linkText)", placeholder: "color red" }}>Número</label>
                                        <input
                                            value={dataForm.numero}
                                            onChange={handleChange}
                                            name={'numero'}
                                            style={inputStyle}
                                            type="number"
                                            placeholder="Número da Proposição"
                                            autoComplete="off"
                                            maxLength={'8'}

                                        />

                                        {errors.numero && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.numero}</span>}
                                    </div>



                                    <div className="col-md-6 mt-3">
                                        <label style={{ color: "var(--linkText)" }} >Data de Apresentação</label>
                                        <input
                                            value={dataForm.data_apresentacao}
                                            name="data_apresentacao"
                                            onChange={handleChange}
                                            style={inputStyle}
                                            type="date"
                                            placeholder="Data em que foi apresentada"
                                            id="numeroInput"
                                            autoComplete="off"

                                        />
                                        {errors.data_apresentacao && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.data_apresentacao}</span>}
                                    </div>


                                    <div className="col-md-12 mt-3">
                                        <label style={{ color: "var(--linkText)" }} >Ementa</label>
                                        <textarea
                                            value={dataForm.ementa}
                                            style={inputStyle}
                                            onChange={handleChange}
                                            placeholder="Escreva o texto principal da Proposição"
                                            name="ementa"
                                            id="numeroInput"
                                            autoComplete="off"
                                        />
                                        {errors.ementa && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.ementa}</span>}
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <label style={{ color: "var(--linkText)" }} >Inteiro Teor</label>
                                        <textarea
                                            value={dataForm.texto}
                                            style={inputStyle}
                                            onChange={handleChange}
                                            placeholder="Escreva o texto do Inteiro Teor"
                                            name="texto"
                                            id="numeroInput2"
                                            autoComplete="off"
                                        />
                                        {errors.texto && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.texto}</span>}
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <label style={{ color: "var(--linkText)" }} >Link do Inteiro Teor</label>
                                        <textarea
                                            value={dataForm.inteiroTeor}
                                            style={inputStyle}
                                            onChange={handleChange}
                                            placeholder="Escreva um endereço válido"
                                            name="inteiroTeor"
                                            id="numeroInput"
                                            autoComplete="off"
                                            onBlur={validaLinks}

                                        />
                                        {errorsLink.inteiroTeor && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errorsLink.inteiroTeor}</span>}
                                    </div>


                                    <div className="col-md-6 mt-3">
                                        <label style={{ color: "var(--linkText)" }} >Autor
                                            {inputPalavras !== "" ?
                                                <button type="button" onClick={handleAdd} className="btn" style={{ marginLeft: "5px", padding: "0px 5px 0px 5px", fontSize: "12px", border: "1px solid var(--linkText)", color: "var(--linkText)" }}>
                                                    +
                                                </button>
                                                :
                                                <button type="button" className="btn" style={{ marginLeft: "5px", padding: "0px 5px 0px 5px", fontSize: "12px", border: "1px solid var(--linkText)", color: "var(--linkText)" }}>
                                                    +
                                                </button>
                                            }
                                        </label>
                                        <input
                                            value={inputPalavras}
                                            onChange={(e) => setInputPalavras(e.target.value)}
                                            onKeyUp={(e) => { if (e.which === 13) { handleAdd(e) } }}
                                            style={inputStyle}
                                            placeholder="Adicione os Autores"
                                            id="palavra-chave"
                                            autoComplete="off"
                                            name="autor"

                                        />
                                        {errors.autor && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.autor}</span>}

                                        <div className='p-1 mt-2 overflow-auto' style={{ maxHeight: "100px" }}>

                                            {arrayPalavras.length > 0 ?
                                                <>

                                                    <div className="pl-2" style={{ fontSize: "11px", fontWeight: "lighter" }}>Autores Cadastrados:</div>
                                                    {arrayPalavras.map((autores, index) => {
                                                        return (
                                                            <div key={index} className="p-1" >
                                                                <div className=' p-1 d-flex justify-content-between' style={{ border: '1px solid #9B9B9B', borderRadius: '6px' }}>
                                                                    <span className='fontsCitacoes pl-2' style={{ fontSize: '14px' }} >{autores.nome}</span>
                                                                    <i className='icon-lixeira pr-2 cursor-pointer ' style={{ color: '#9B9B9B' }} onClick={() => handleDel(autores.nome, autores.id)}></i>
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                    }



                                                </>
                                                :

                                                <div className='p-3 d-flex flex-column text-center fontsCitacoes' style={{ fontSize: '16px' }}>
                                                    <span>Você não tem Autor adicionado!</span>
                                                    <span style={{ color: "var(--linkText)", fontSize: "12px" }}>Adicione os Autores no campo acima e, caso necessário, exclua nesta região.</span>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-6 mt-3">
                                        <label style={{ color: "var(--linkText)" }} >Tema
                                            {inputTemas !== "" ?
                                                <button type="button" onClick={handleAddTemas} className="btn" style={{ marginLeft: "5px", padding: "0px 5px 0px 5px", fontSize: "12px", border: "1px solid var(--linkText)", color: "var(--linkText)" }}>
                                                    +
                                                </button>
                                                :
                                                <button type="button" className="btn" style={{ marginLeft: "5px", padding: "0px 5px 0px 5px", fontSize: "12px", border: "1px solid var(--linkText)", color: "var(--linkText)" }}>
                                                    +
                                                </button>
                                            }
                                        </label>
                                        <input
                                            value={inputTemas}
                                            onChange={(e) => setInputTemas(e.target.value)}
                                            onKeyUp={(e) => { if (e.which === 13) { handleAddTemas(e) } }}
                                            style={inputStyle}
                                            placeholder="Adicione os Temas"
                                            id="Temas"
                                            autoComplete="off"
                                            name="tema"

                                        />
                                        {errors.tema && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.tema}</span>}

                                        <div className='p-1 mt-2 overflow-auto' style={{ maxHeight: "100px" }}>

                                            {arrayTemas.length > 0 ?
                                                <>

                                                    <div className="pl-2" style={{ fontSize: "11px", fontWeight: "lighter" }}>Temas Relacionados:</div>
                                                    {arrayTemas.map((temas, index) => {
                                                        // console.log(temas, index)
                                                        return (
                                                            <div key={index} className="p-1" >
                                                                <div className=' p-1 d-flex justify-content-between' style={{ border: '1px solid #9B9B9B', borderRadius: '6px' }}>
                                                                    <span className='fontsCitacoes pl-2' style={{ fontSize: '14px' }} >{temas.nome}</span>
                                                                    <i className='icon-lixeira pr-2 cursor-pointer ' style={{ color: '#9B9B9B' }} onClick={() => handleDelTemas(temas.nome, temas.id)}></i>
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                    }



                                                </>
                                                :

                                                <div className='p-3 d-flex flex-column text-center fontsCitacoes' style={{ fontSize: '16px' }}>
                                                    <span>Você não tem Tema Adicionado!</span>
                                                    <span style={{ color: "var(--linkText)", fontSize: "12px" }}>Adicione os Temas no campo acima e, caso necessário, exclua nesta região.</span>
                                                </div>
                                            }
                                        </div>
                                    </div>


                                    <div className="col-md-12 mt-2 mb-2 pl-3 pr-3">
                                        <div className="row" style={{ backgroundColor: "#f2f2f2", padding: "20px", borderRadius: "3px" }} >
                                            <div className="input-group col-12 col-sm-6  col-lg-6 col-xl-6">
                                                <label style={{ color: "var(--linkText)" }} >Proposição Apensada à</label>
                                                <input type="search" autoComplete="off"
                                                    style={inputStyleA}
                                                    value={valorProp}
                                                    // onKeyUp={(event) => {if (event.which === 13)  {this.getDataProposicao()} }}
                                                    // onKeyPress={(event) => this.clickEnter(event)}
                                                    onChange={(event) => obterProposicoes(event.target.value.toUpperCase())}
                                                    placeholder="Buscar proposição" aria-label="Pesquisar"
                                                    aria-describedby="search"

                                                // ref={(event) => this._buscarInput = (event) }
                                                />

                                                {/* <span id="icone-pesquisar-proposicao" className="input-group-append">
                                                        <button className="btn btn-outline-secondary border-left-0 border" type="button"
                                                        // onClick={() => this.getDataProposicao()}
                                                        >                
                                                            <i className="icon-search-small"></i>  
                                                        </button>
                                                        </span> */}
                                            </div>

                                            {listaProposicoes.length > 0
                                                ?
                                                <div className="col-md-6">
                                                    <div>
                                                        <label style={{ color: "var(--linkText)" }}>Selecionar Apensada</label>
                                                        <ClientSelect
                                                            css="no-border"
                                                            name="casa"
                                                            value={propSelecionada}
                                                            options={
                                                                listaProposicoes !== undefined && Array.isArray(listaProposicoes) && listaProposicoes.length > 0 ?
                                                                    listaProposicoes.map((item) => ({ value: item.id, label: item.descricao })) : null}
                                                            onChange={(newValue) => handleChangeSelectProp(newValue)}
                                                            placeholder={"Selecionar"}
                                                        />
                                                        {errors.casa && errors.casa.message}
                                                    </div>
                                                </div>
                                                :

                                                valorProp !== "" ?
                                                    <div className="col-md-6 p-3 d-flex flex-column text-center">
                                                        <div className="col-12 text-center">
                                                            <LoadingCircle color={'#DC2D41'} size={'30px'} />
                                                        </div>
                                                        <div className="col-12 text-center fontsCitacoes">
                                                            Continue escrevendo, estamos buscando!
                                                    </div>
                                                    </div>
                                                    :
                                                    <div className='col-md-6 p-3 d-flex flex-column text-center fontsCitacoes' style={{ fontSize: '16px' }}>
                                                        <span>Será exibido aqui o resultado da busca</span>
                                                        <span style={{ color: "var(--linkText)", fontSize: "12px" }}>Busque pela proposição desejada (Ex: PL81/2020)</span>
                                                    </div>

                                            }
                                        </div>
                                    </div>



                                    <div className="col-md-6 mt-3">
                                        <label style={{ color: "var(--linkText)" }} >Link Externo</label>
                                        <textarea
                                            value={dataForm.linkExterno}
                                            style={inputStyle}
                                            onChange={handleChange}
                                            placeholder="Escreva um endereço válido"
                                            name="linkExterno"
                                            id="numeroInput"
                                            autoComplete="off"
                                            onBlur={validaLinks}
                                        />
                                        {errorsLink.linkExterno && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errorsLink.linkExterno}</span>}

                                    </div>


                                </div>


                                <div className="row">

                                    <div className="col-md-6 text-center">
                                        <div onClick={() => window.history.back()} id="pesquisa-dash" className="btn" style={{ borderRadius: '5px', border: '1px solid #1E0736', width: "100%", marginTop: "20px", padding: "15px", backgroundColor: "#FFFFFF", color: "#1E0736", fontWeight: "lighter" }} variant="outlined" >
                                            Cancelar
                                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div onClick={handleSubmit} id="pesquisa-dash" className="btn" style={{ width: "100%", marginTop: "20px", padding: "15px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter" }} variant="outlined" >
                                            <img
                                                className="mr-2"
                                                style={{ width: "20px", marginTop: "-8px" }}
                                                src={require('../../design/assets/images/salvar-propNova.svg')}
                                                alt="btn-pesquisar"
                                            />{' '}
                                                            Atualizar Proposição
                                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
            }
        </div>




    )
}
export default editarProposicao