import React, { Component } from "react";
import moment from 'moment-timezone';
// import Breadcrumb from '../../estruturas/breadcrumb'
import "moment/locale/pt-br.js";
import { get, del } from "../../services/http";
import { inject, observer } from "mobx-react";
import Loading from "../../componentes/loading";
import FormatInputButtonSelect from "../../componentes/forms/inputs/formatInputButtonSelect";
import FormatInput from "../../componentes/forms/inputs/formatInput";
import ClientCheckboxWithSelectButton from "../../componentes/forms/basic/clientSelectWithSearchButton";
import ClientCheckboxWithSelect from "../../componentes/forms/basic/clientSelectWithSearch";
import { NavLink } from "react-router-dom";
import ConfirmModal from "../../componentes/confirmModal";

moment.tz.setDefault('UTC');

@inject("alertaStore", "authStore")
@observer
class Agenda extends Component {
	state = {
		options: [1, 2, 3],
		loading: false,
		eventos: [],
		segunda: {},
		sexta: {},
		semana: "",
		showCalendar: false,
		selectedDate: new Date(),
		dias: [
			{ semana: "domingo" },
			{ semana: "segunda" },
			{ semana: "terça" },
			{ semana: "quarta" },
			{ semana: "quinta" },
			{ semana: "sexta" },
			{ semana: "sábado" },
		],
		diaHoje: null,
		meusEventos: [],
		casa_legislativa: { value: 0, label: "Todos" },
		casasLegislativas: [
			{ id: 0, casa: "Todos" },
			{ id: 2, casa: "Câmara" },
			{ id: 3, casa: "Congresso" },
			{ id: 1, casa: "Senado" },
			{ id: 4, casa: "Outros" },
		],
		eventosSemana: [],
		changeButton: false,
		buttonInactiveInit: true,
		showModal: false,
		idExcluirEvento: "",
	};

	tipoDaCasa(casa) {
		return casa === 1 ? "Senado" : "Câmara";
	}

	formatDate(date, format) {
		return moment(date).format(format);
	}

	splitFormatedDate(dateFormated, fullDate) {
		let split = dateFormated.split("/");
		return { day: +split[0], month: +split[1], year: +split[2], fullDate };
	}

	async getEventos(segunda, sexta) {
		// console.log("segunda está:", segunda, "e sexta está:", sexta)
		return await get(`/agenda/${segunda.fullDate.format("YYYY-MM-DD")}/${sexta.fullDate.format("YYYY-MM-DD")}/eventos`
		);
	}

	onChangeCasaLegislativa = (casa) => {
		// console.log("casaLegislativa", casa)
		this.setState({ casa_legislativa: casa });
	};

	async mountDiasArray(segunda, sexta) {
		// console.log("cheguei no MountDiasArray", segunda, sexta)
		this.setState({ loading: true });
		let eventos = (await this.getEventos(segunda, sexta)) || [];

		// console.log("eventos*****", eventos)

		let dias = this.state.dias;
		for (let i = 0; i < dias.length; i++) {
			dias[i].fullDate = moment(segunda.fullDate)
				.startOf("week")
				.add(i, "day");
			dias[i].eventos =
				eventos && eventos.length > 0
					? eventos.filter(
						(evento) =>
							this.formatDate(
								evento.data_inicio,
								"DD-MM-YYYY"
							) ===
							this.formatDate(dias[i].fullDate, "DD-MM-YYYY")
					)
					: [];
		}
		this.setState({ eventos, loading: false });
		// console.log();
		return dias;
	}

	async componentDidMount() {
		// console.log("props params data undefined ou nao", this.props.match.params.data);
		if (this.props.match.params.data === undefined) {
			// console.log("cheguei no if")
			let segunda = this.splitFormatedDate(
				this.formatDate(
					moment()
						.startOf("week"),
					// .add(1, "day"),
					"DD/MM/YYYY"
				),
				moment()
					.startOf("week")
				// .add(1, "day")
			);
			//RELACIONADO AO DADO QUE FAZ O GET DO CONTEUDO DOS EVENTOS
			let sexta = this.splitFormatedDate(
				this.formatDate(
					moment()
						.startOf("week")
						.add(5, "day"),
					"DD/MM/YYYY"
				),
				moment()
					.startOf("week")
					.add(5, "day")
			);
			let dias = await this.mountDiasArray(segunda, sexta);


			let obterDomingoMilissegundos =
				new Date(this.formatDate(segunda.fullDate)).getTime();
			// RELACIONADO AO RESULTADO QUE SERA MOSTRADO NO RANGE DE DIAS 
			let obterSabadoMilissegundos =
				new Date(this.formatDate(sexta.fullDate)).getTime() + 86400000;

			let diaEntrada = moment(new Date(obterDomingoMilissegundos)).format(
				"DD"
			);
			let mesEntrada = moment(new Date(obterDomingoMilissegundos)).format(
				"MMMM"
			);
			let anoEntrada = moment(new Date(obterDomingoMilissegundos)).format(
				"YYYY"
			);
			let diaSaida = moment(new Date(obterSabadoMilissegundos)).format(
				"DD"
			);
			let mesSaida = moment(new Date(obterSabadoMilissegundos)).format(
				"MMMM"
			);
			let anoSaida = moment(new Date(obterSabadoMilissegundos)).format(
				"YYYY"
			);

			let semana = `${mesEntrada !== mesSaida
				? `${diaEntrada} de ${mesEntrada} ${anoEntrada !== anoSaida ? ` de ${anoEntrada}` : ""
				} a ${diaSaida} de ${mesSaida} de ${anoSaida}`
				: `${diaEntrada} ${anoEntrada !== anoSaida
					? ` de ${mesEntrada} de ${anoEntrada}`
					: ""
				} a ${diaSaida} de ${mesSaida} de ${anoSaida}`
				}`;
			this.setState({ dias, segunda, sexta, semana });
			// console.log(segunda.fullDate.format("YYYY-MM-DD"));
			await get(
				`/agenda/${segunda.fullDate.format(
					"YYYY-MM-DD"
				)}/${sexta.fullDate.format("YYYY-MM-DD")}/eventos`
			) //nova
				.then((response) => this.setState({ eventosSemana: response }));
		} else {
			// console.log("else");
			// let obtendoDia = (new Date(this.props.match.params.data).getDay() - 1) * 86400000;
			// let obtendoMilissegundos = new Date(this.props.match.params.data).getTime();
			// let obterSegunda = new Date(obtendoMilissegundos - obtendoDia);
			// let sextaMilissegundos = 4 * 86400000;
			// let obterSexta = new Date(obterSegunda.getTime() + sextaMilissegundos);

			let obtendoDia = (new Date(this.props.match.params.data).getDay() - 1) * 86400000;
			let obtendoMilissegundos = new Date(this.props.match.params.data).getTime();
			let obterSegunda = new Date(obtendoMilissegundos - obtendoDia);
			let sextaMilissegundos = 4 * 86400000;
			let obterSexta = new Date(obterSegunda.getTime() + sextaMilissegundos);

			let segunda = this.splitFormatedDate(
				this.formatDate(
					moment(obterSegunda)
						.startOf("week")
						.add(1, "day"),
					"DD/MM/YYYY"
				),
				moment(obterSegunda)
					.startOf("week")
					.add(1, "day")
			);

			let sexta = this.splitFormatedDate(
				this.formatDate(
					moment(obterSexta)
						.startOf("week")
						.add(5, "day"),
					"DD/MM/YYYY"
				),
				moment(obterSexta)
					.startOf("week")
					.add(5, "day")
			);
			let dias = await this.mountDiasArray(segunda, sexta);

			let obterDomingoMilissegundos =
				new Date(this.formatDate(segunda.fullDate)).getTime() -
				86400000;
			let obterSabadoMilissegundos =
				new Date(this.formatDate(sexta.fullDate)).getTime() + 86400000;

			let diaEntrada = moment(new Date(obterDomingoMilissegundos)).format(
				"DD"
			);
			let mesEntrada = moment(new Date(obterDomingoMilissegundos)).format(
				"MMMM"
			);
			let anoEntrada = moment(new Date(obterDomingoMilissegundos)).format(
				"YYYY"
			);
			let diaSaida = moment(new Date(obterSabadoMilissegundos)).format(
				"DD"
			);
			let mesSaida = moment(new Date(obterSabadoMilissegundos)).format(
				"MMMM"
			);
			let anoSaida = moment(new Date(obterSabadoMilissegundos)).format(
				"YYYY"
			);

			let semana = `${mesEntrada !== mesSaida
				? `${diaEntrada} de ${mesEntrada} ${anoEntrada !== anoSaida ? ` de ${anoEntrada}` : ""
				} a ${diaSaida} de ${mesSaida} de ${anoSaida}`
				: `${diaEntrada} ${anoEntrada !== anoSaida
					? ` de ${mesEntrada} de ${anoEntrada}`
					: ""
				} a ${diaSaida} de ${mesSaida} de ${anoSaida}`
				}`;
			this.setState({ dias, segunda, sexta, semana });
			// console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXX", semana);
			await get(
				`/agenda/${segunda.fullDate.format("YYYY-MM-DD")}/${sexta.fullDate.format("YYYY-MM-DD")}/eventos`
			).then((response) => this.setState({ eventosSemana: response }));
		}
	}

	changeOption(option) {
		// console.log("option>>>>", option)
		let options = this.state.options;
		let index = options.indexOf(option);

		if (!options[index]) options.push(option);
		else options.splice(index, 1);

		let dias = this.state.dias;
		dias.forEach(
			(dia) =>
			(dia.eventos = this.state.eventos.filter(
				(evento) =>
					options.find((option) => evento.casa === option) &&
					this.formatDate(evento.data_inicio, "DD-MM-YYYY") ===
					this.formatDate(dia.fullDate, "DD-MM-YYYY")
			))
		);
		this.setState({ options, dias });
	}

	async changeWeek(next) {
		if (next) {
			this.setState({ changeButton: false });
		} else {
			this.setState({ changeButton: true });
		}

		let segunda = next
			? this.state.segunda.fullDate.clone().add(1, "week")
			: this.state.segunda.fullDate.clone().subtract(1, "week");
		let sexta = moment(segunda).add(5, "day");
		segunda = this.splitFormatedDate(
			this.formatDate(segunda, "DD/MM/YYYY"),
			segunda
		);
		sexta = this.splitFormatedDate(
			this.formatDate(sexta, "DD/MM/YYYY"),
			sexta
		);
		// console.log("VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV", sexta.fullDate)
		let dias = await this.mountDiasArray(segunda, sexta);

		let obterDomingoMilissegundos =
			new Date(this.formatDate(segunda.fullDate)).getTime();
		let obterSabadoMilissegundos =
			new Date(this.formatDate(sexta.fullDate)).getTime() + 86400000;

		let diaEntrada = moment(new Date(obterDomingoMilissegundos)).format(
			"DD"
		);
		let mesEntrada = moment(new Date(obterDomingoMilissegundos)).format(
			"MMMM"
		);
		let anoEntrada = moment(new Date(obterDomingoMilissegundos)).format(
			"YYYY"
		);
		let diaSaida = moment(new Date(obterSabadoMilissegundos)).format("DD");
		let mesSaida = moment(new Date(obterSabadoMilissegundos)).format("MMMM");
		let anoSaida = moment(new Date(obterSabadoMilissegundos)).format("YYYY");

		let semana = `${mesEntrada !== mesSaida
			? `${diaEntrada} de ${mesEntrada} ${anoEntrada !== anoSaida ? ` de ${anoEntrada}` : ""
			} a ${diaSaida} de ${mesSaida} de ${anoSaida}`
			: `${diaEntrada} ${anoEntrada !== anoSaida
				? ` de ${mesEntrada} de ${anoEntrada}`
				: ""
			} a ${diaSaida} de ${mesSaida} de ${anoSaida}`
			}`;

		this.setState({ segunda, sexta, semana, dias });
	}

	async changeDay(selectedDate) {
		let segunda = this.splitFormatedDate(
			this.formatDate(
				moment(selectedDate)
					.startOf("week")
					.add(1, "day"),
				"DD/MM/YYYY"
			),
			moment(selectedDate)
				.startOf("week")
				.add(1, "day")
		);
		let sexta = this.splitFormatedDate(
			this.formatDate(
				moment(selectedDate)
					.startOf("week")
					.add(5, "day"),
				"DD/MM/YYYY"
			),
			moment(selectedDate)
				.startOf("week")
				.add(5, "day")
		);
		let dias = await this.mountDiasArray(segunda, sexta);
		this.setState({
			dias,
			segunda,
			sexta,
			selectedDate,
			showCalendar: false,
		});
		// console.log("WWWWWWWWWWWWWWWWW", dias)
	}

	botaoDesabilitado() {
		alert("Não existem eventos anterior a essa data!");
	}

	changeShowModalState(showModal, id) {
		this.setState({ showModal, idExcluirEvento: id });
	}

	async excluirProposicao(showModal, save, id) {
		//console.log(showModal, save,id)
		this.setState({ showModal });

		if (save) {
			await del(`/agenda/excluir/${id}`)
				.then((res) => {
					if (res.length > 0) {
						this.props.alertaStore.criar(
							"Evento excluido com sucesso."
						);
						setTimeout(() => window.location.reload(), 2000);
					}
				})
				.catch((err) => err);
		}
	}

	// contarEventos(){
	// 	this.state.eventos.filter(item => item[0].integrada !== true)

	// }

	// eventCard(item, index) {
	//     let {localCamara} = item;
	//     return (
	//         <div className="event-card bg-linear" key={index}>
	//             <div className="header">
	//                 <p className='hour'>{moment(item.data_inicio).add(3, 'hours').format('HH:mm')}h</p>
	//                 <p>{this.tipoDaCasa(item.casa)}</p>
	//             </div>
	//             <p>{item.descricao !== undefined && item.descricao !== null ? item.descricao : null}</p>
	//             <p>{item.orgaos !== undefined && item.orgaos[0] !== undefined && item.orgaos[0].comissao_nome !== undefined ? item.orgaos[0].comissao_nome : null}</p>

	//             <div className="infos">
	//                 <p>Tipo do evento: {item.descricaoTipo !== null ? item.descricaoTipo : null}</p>
	//                 <p>Local do evento: {localCamara.nome}</p>
	//                 <p>Situação do evento: {item.situacao}</p>
	//             </div>
	//         </div>
	//     )
	// }

	render() {
		// console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", this.state.semana)
		// console.log('Eventos ===> ', this.state.eventos)
		const limiteDeData = new Date(1574640000000);
		const segunda =
			(this.state.segunda !== undefined || this.state.segunda !== {}) &&
				this.state.segunda.fullDate !== undefined
				? this.state.segunda.fullDate.format("YYYY-MM-DD")
				: null;
		const sexta =
			(this.state.sexta !== undefined || this.state.sexta !== {}) &&
				this.state.sexta.fullDate !== undefined
				? this.state.sexta.fullDate.format("YYYY-MM-DD")
				: null;
		const horaCorreta = new Date().getTime();
		//console.log(this.state.casa_legislativa)
		// const numerosEventosCasa0 = this.state.eventos.filter(item => item && item.proposicoesSeguidas.length > 0)
		// const numerosEventosCasa1 = this.state.eventos.filter(item => item.casa === 1 && item.proposicoesSeguidas.length > 0)
		// const numerosEventosCasa2 = this.state.eventos.filter(item => item.casa === 2 && item.proposicoesSeguidas.length > 0)
		// const numerosEventosCasa3 = this.state.eventos.filter(item => item.casa === 3 && item.proposicoesSeguidas.length > 0)

		const buttonActiveWeekDown = this.state.changeButton
			? "change-button-week-inactive"
			: "";
		const buttonActiveWeekUp = this.state.changeButton
			? ""
			: "change-button-week-inactive";

		const tamanhoTela = window.innerWidth;

		return (
			<>
				<ConfirmModal
					text={"Tem certeza que deseja excluir esse Evento?"}
					description={"Esta ação não poderá ser desfeita no futuro"}
					onClose={(show, save, item) =>
						this.excluirProposicao(show, save, item)
					}
					show={this.state.showModal}
					item={this.state.idExcluirEvento}
				/>

				<div
					className="col-12 bg-white pl-2 marginTopXs"
					style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}
				>
					<p
						style={{
							fontSize: "10px",
							color: "var(--linkText)",
							fontWeight: "bold",
							padding: "15px",
						}}
					>
						AGENDA
					</p>
				</div>

				<div className="m-4">
					<span style={{ fontSize: "24px", fontWeight: "600" }}>
						Agenda
					</span>
				</div>

				{tamanhoTela > 600 ? (
					<>
						{/* >>>>>>>>>>>>>>>>>>>>> DESK <<<<<<<<<<<<<<<<<<<<<<<<< */}

						<div className="d-flex ml-3 mr-3">
							{this.props.authStore.ehEsforcoColaborativo ===
								true ? (
								<div classNane="col-md-3"></div>
							) : this.state.eventos &&
								this.state.eventos.length === 0 ? (
								<div className="col-md-3 col-lg-3 col-xl-3 d-none d-lg-block">
									<button
										style={{
											backgroundColor:
												"rgb(236, 236, 236)",
										}}
										className="btn btn-block text-button p-3"
									>
										<img
											style={{ marginRight: "15px" }}
											src={require("../../design/assets/images/impressora.svg")}
											alt="anexo"
										/>
										<span style={{ fontSize: "16px" }}>
											AGENDA JORNAL
										</span>
									</button>
								</div>
							) : (
								<div className="col-md-3 col-lg-3 col-xl-3 d-none d-lg-block">
									<NavLink
										to={`/agenda-jornal/${segunda}/${sexta}/${this.state.casa_legislativa.value}`}
									>
										<button className="btn btn-danger btn-block text-button p-3">
											<img
												style={{ marginRight: "15px" }}
												src={require("../../design/assets/images/impressora.svg")}
												alt="anexo"
											/>
											<span style={{ fontSize: "16px" }}>
												AGENDA JORNAL
											</span>
										</button>
									</NavLink>
								</div>
							)}

							{this.props.authStore.ehEsforcoColaborativo ===
								true ? (
								<div classNane="col-md-3"></div>
							) : this.state.eventos &&
								this.state.eventos.length === 0 ? (
								<div className="col-md-3 col-lg-3 col-xl-3 d-none d-lg-block">
									<button
										style={{
											backgroundColor:
												"rgb(236, 236, 236)",
										}}
										className="btn btn-block text-button p-3"
									>
										<img
											style={{ marginRight: "15px" }}
											src={require("../../design/assets/images/impressora.svg")}
											alt="anexo"
										/>
										<span style={{ fontSize: "16px" }}>
											AGENDA OPERACIONAL
										</span>
									</button>
								</div>
							) : (
								<div className="col-md-3 col-lg-3 col-xl-3 d-none d-lg-block">
									<NavLink
										to={`/agenda-operacional/${segunda}/${sexta}/${this.state.casa_legislativa.value}`}
									>
										<button className="btn btn-danger btn-block text-button p-3">
											<img
												style={{ marginRight: "15px" }}
												src={require("../../design/assets/images/impressora.svg")}
												alt="anexo"
											/>
											<span style={{ fontSize: "16px" }}>
												AGENDA OPERACIONAL
											</span>
										</button>
									</NavLink>
								</div>
							)}

							<div className="col-md-3 col-lg-2 col-xl-3 d-none d-lg-block">
								<NavLink
									to={`/agenda/agenda-cadastro/cadastro`}
								>
									<button
										className="btn bg-white btn-block text-button p-3"
										style={{ border: "1px solid #1E0736" }}
									>
										<span style={{ fontSize: "16px" }}>
											Cadastrar Evento
										</span>
									</button>
								</NavLink>
							</div>
						</div>

						<div style={{ padding: "8px" }}>
							<hr />
						</div>

						<div
							className="ml-4 mr-4 p-4 d-flex justify-content-between"
							style={{
								borderRadius: "6px",
								backgroundColor: "#ECECEC",
							}}
						>
							<div className="  col-lg-5 col-sm-7 col-12 d-flex justify-content-start mt-2">
								{segunda === null ? (
									<div className="change-button-week d-flex justify-content-center align-items-center">
										<i className="icon-arrow-up-small-copy"></i>
									</div>
								) : (
									<div
										className={`change-button-week  ${buttonActiveWeekUp} d-flex justify-content-center align-items-center`}
										onClick={
											new Date(limiteDeData).getTime() ===
												new Date(segunda).getTime()
												? () => this.botaoDesabilitado()
												: () => this.changeWeek(false)
										}
									>
										<i
											className="icon-arrow-up-small-copy"
											style={{ fontSize: "18px" }}
										></i>
									</div>
								)}

								<div
									className={`change-button-week  ${buttonActiveWeekDown} d-flex justify-content-center align-items-center`}
									onClick={() => this.changeWeek(true)}
								>
									<i className="icon-arrow-down-small-copy"></i>
								</div>

								<div className="d-flex flex-column  ml-3">
									<span
										className="text-week"
										onClick={() =>
											this.setState({
												showCalendar: !this.state
													.showCalendar,
											})
										}
									>
										{this.state.semana}
									</span>

									<div className="text-week">
										<span>
											{" "}
											{this.state.eventos.length > 0
												? `${this.state.eventos.filter(item => item.integrada === true && item.proposicoesSeguidas.length > 0).length + this.state.eventos.filter(item => item.integrada === false).length}
												eventos`
												: `0 eventos`}
											{/* {
                                                                        this.state.casa_legislativa.value === 0 ? 
                                                                        `${numerosEventosCasa0.length} eventos` : null

                                                                    }
                                                                    {
                                                                        this.state.casa_legislativa.value === 1 ? 
                                                                        `${numerosEventosCasa1.length} eventos` : null

                                                                    }
                                                                    {
                                                                        this.state.casa_legislativa.value === 2 ? 
                                                                        `${numerosEventosCasa2.length} eventos` : null

                                                                    }
                                                                    {
                                                                        this.state.casa_legislativa.value === 3 ? 
                                                                        `${numerosEventosCasa3.length} eventos` : null

                                                                    } */}
										</span>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-md-4">
								<FormatInput
									className="Client-select "
									label="Visualizar"
								>
									<ClientCheckboxWithSelect
										value={this.state.casa_legislativa ? this.state.casa_legislativa.id : null}
										onChange={(casa_legislativa) => this.onChangeCasaLegislativa(casa_legislativa)}
										options={this.state.casasLegislativas !== undefined && Array.isArray(this.state.casasLegislativas) && this.state.casasLegislativas.length > 0
											? this.state.casasLegislativas.map((item) => ({ value: item.id, label: item.casa, })) : null}
										placeholder={this.state.casa_legislativa.label}
									/>
								</FormatInput>
							</div>
						</div>
					</>
				) : (
					<>
						{/* >>>>>>>>>>>>>>>>>>Mobile<<<<<<<<<<<<<<<<<< */}

						{this.props.authStore.ehEsforcoColaborativo === true ? (
							<div className="row">
								<div classNane="col-sm-12"></div>
							</div>
						) : this.state.eventos &&
							this.state.eventos.length === 0 ? (
							<div className="row">
								<div className="col-sm-12 m-2 ">
									<button
										style={{
											backgroundColor:
												"rgb(236, 236, 236)",
										}}
										className="btn btn-block text-button p-3"
									>
										<img
											style={{ marginRight: "15px" }}
											src={require("../../design/assets/images/impressora.svg")}
											alt="anexo"
										/>
										AGENDA JORNAL
									</button>
								</div>
							</div>
						) : (
							<div className="row">
								<div className="col-sm-12 m-2 ">
									<NavLink
										to={`/agenda-jornal/${segunda}/${sexta}/${this.state.casa_legislativa.value}`}
									>
										<button className="btn btn-danger btn-block text-button p-3">
											<img
												style={{ marginRight: "15px" }}
												src={require("../../design/assets/images/impressora.svg")}
												alt="anexo"
											/>
											AGENDA JORNAL
										</button>
									</NavLink>
								</div>
							</div>
						)}

						{this.props.authStore.ehEsforcoColaborativo === true ? (
							<div className="row">
								<div classNane="col-sm-12"></div>
							</div>
						) : this.state.eventos &&
							this.state.eventos.length === 0 ? (
							<div className="row">
								<div className="col-sm-12 m-2">
									<button
										style={{
											backgroundColor:
												"rgb(236, 236, 236)",
										}}
										className="btn btn-block text-button p-3"
									>
										<img
											style={{ marginRight: "15px" }}
											src={require("../../design/assets/images/impressora.svg")}
											alt="anexo"
										/>
										AGENDA OPERACIONAL
									</button>
								</div>
							</div>
						) : (
							<div className="row">
								<div className="col-sm-12 m-2">
									<NavLink
										to={`/agenda-operacional/${segunda}/${sexta}/${this.state.casa_legislativa.value}`}
									>
										<button className="btn btn-danger btn-block text-button p-3">
											<img
												style={{ marginRight: "15px" }}
												src={require("../../design/assets/images/impressora.svg")}
												alt="anexo"
											/>
											AGENDA OPERACIONAL
										</button>
									</NavLink>
								</div>
							</div>
						)}

						<div className="row">
							<div className="col-sm-12 m-2">
								<NavLink
									to={`/agenda/agenda-cadastro/cadastro`}
								>
									<button
										className="btn bg-white btn-block text-button p-3"
										style={{ border: "1px solid #1E0736" }}
									>
										Cadastrar Evento
									</button>
								</NavLink>
							</div>
						</div>

						<div style={{ padding: "8px" }}>
							<hr />
						</div>

						<div
							className="ml-2 pb-2 pt-3 justify-content-start"
							style={{
								borderRadius: "6px",
								backgroundColor: "#ECECEC",
							}}
						>
							<div className="row pt-1 ml-3 mb-2">
								<div className="col-sm-12 d-flex justify-content-start mt-2">
									{tamanhoTela < 600 ? (
										// =======MOBILE:Botao esquerdo========
										segunda === null ? (
											<div className="change-button-week-mobile d-flex justify-content-center align-items-center">
												<i
													className="icon-arrow-up-small-copy"
													style={{
														fontSize: "15px",
														marginTop: "5px",
													}}
												></i>
											</div>
										) : (
											<div
												className={`change-button-week-mobile ${buttonActiveWeekUp} d-flex justify-content-center align-items-center`}
												onClick={
													new Date(
														limiteDeData
													).getTime() ===
														new Date(segunda).getTime()
														? () =>
															this.botaoDesabilitado()
														: () =>
															this.changeWeek(
																false
															)
												}
											>
												<i
													className="icon-arrow-up-small-copy"
													style={{
														fontSize: "15px",
														marginTop: "5px",
													}}
												></i>
											</div>
										)
									) : // =======DESK:Botao Esquerdo========
										segunda === null ? (
											<div className="change-button-week d-flex justify-content-center align-items-center">
												<i className="icon-arrow-up-small-copy"></i>
											</div>
										) : (
											<div
												className={`change-button-week  ${buttonActiveWeekUp} d-flex justify-content-center align-items-center`}
												onClick={
													new Date(
														limiteDeData
													).getTime() ===
														new Date(segunda).getTime()
														? () =>
															this.botaoDesabilitado()
														: () =>
															this.changeWeek(
																false
															)
												}
											>
												<i
													className="icon-arrow-up-small-copy"
													style={{ fontSize: "18px" }}
												></i>
											</div>
										)}

									{tamanhoTela < 600 ? (
										// =======MOBILE:Botao Direito========
										<div
											className={`change-button-week-mobile ${buttonActiveWeekDown} d-flex justify-content-center align-items-center`}
											onClick={() =>
												this.changeWeek(true)
											}
										>
											<i
												className="icon-arrow-down-small-copy"
												style={{
													fontSize: "15px",
													marginTop: "5px",
												}}
											></i>
										</div>
									) : (
										// =======DESK:Botao Direito========
										<div
											className={`change-button-week  ${buttonActiveWeekDown} d-flex justify-content-center align-items-center`}
											onClick={() =>
												this.changeWeek(true)
											}
										>
											<i className="icon-arrow-down-small-copy"></i>
										</div>
									)}

									<div className="d-flex flex-column  ml-3">
										{tamanhoTela < 600 ? (
											// =======MOBILE:Texto ao lado dos Botoes Direita e esq.========
											<span
												style={{
													fontSize: "15px",
													fontWeight: "bold",
												}}
												onClick={() =>
													this.setState({
														showCalendar: !this
															.state.showCalendar,
													})
												}
											>
												{this.state.semana}
											</span>
										) : (
											// =======DESK:Texto ao lado dos Botoes Direita e esq.========
											<span
												className="text-week"
												onClick={() =>
													this.setState({
														showCalendar: !this
															.state.showCalendar,
													})
												}
											>
												{this.state.semana}
											</span>
										)}

										<div className="d-flex flex-column">
											<div>
												<span
													style={{
														fontSize: "15px",
														color:
															"var(--linkText)",
														marginTop: "-2px",
													}}
												>
													{" "}
													{this.state.eventos.length > 0
														? `${this.state.eventos.filter(item => item.integrada === true && item.proposicoesSeguidas.length > 0).length + this.state.eventos.filter(item => item.integrada === false).length}
												eventos`
														: `0 eventos`}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12" style={{ width: "100%" }}>
								<FormatInputButtonSelect
								// className="Client-select "
								// label="Visualizar"
								>
									<ClientCheckboxWithSelectButton
										value={
											this.state.casa_legislativa
												? this.state.casa_legislativa.id
												: null
										}
										onChange={(casa_legislativa) =>
											this.onChangeCasaLegislativa(
												casa_legislativa
											)
										}
										options={
											this.state.casasLegislativas !==
												undefined &&
												Array.isArray(
													this.state.casasLegislativas
												) &&
												this.state.casasLegislativas
													.length > 0
												? this.state.casasLegislativas.map(
													(item) => ({
														value: item.id,
														label: item.casa,
													})
												)
												: null
										}
										placeholder={
											this.state.casa_legislativa.label
										}
									/>
								</FormatInputButtonSelect>
							</div>
						</div>
					</>
				)}

				{tamanhoTela < 600 ? (
					<article className="agenda d-block d-print-none mt-4 mb-4">
						<section
							className="container-fluid bg-white"
							style={{ borderRadius: "6px" }}
						>
							{this.state.loading ? (
								<Loading />
							) : (
								<div className="schedule mr-2">
									<div className="days p-1">
										{this.state.dias !== undefined &&
											Array.isArray(this.state.dias) &&
											this.state.dias.length > 0
											? this.state.dias.map(
												(dia, index) => (
													<div
														className="day"
														key={index}
													>
														<div className="calendar-days">
															<p
																className={`number ${this.formatDate(
																	this
																		.state
																		.selectedDate,
																	"DD-MM-YYYY"
																) ===
																	this.formatDate(
																		dia.fullDate,
																		"DD-MM-YYYY"
																	)
																	? "active"
																	: ""
																	}`}
															>
																{this.formatDate(
																	dia.fullDate,
																	"DD"
																)}
															</p>
															<p
																className={`day-of-week ${this
																	.state
																	.dias[
																	new Date().getDay()
																]
																	.semana ===
																	dia.semana &&
																	this.formatDate(
																		this
																			.state
																			.selectedDate,
																		"DD-MM-YYYY"
																	) ===
																	this.formatDate(
																		dia.fullDate,
																		"DD-MM-YYYY"
																	)
																	? "active"
																	: ""
																	}`}
															>
																{dia.semana}
															</p>
														</div>
														<div className="events">
															{this.state
																.eventos !==
																undefined &&
																Array.isArray(
																	this.state
																		.eventos
																) &&
																this.state
																	.eventos
																	.length > 0
																? this.state.eventos.map(
																	(
																		item,
																		index
																	) => {
																		if (
																			item.integrada ===
																			true
																		) {
																			return item.proposicoesSeguidas !==
																				undefined &&
																				item
																					.proposicoesSeguidas
																					.length >
																				0 &&
																				(this
																					.state
																					.casa_legislativa
																					.value ===
																					item.casa ||
																					this
																						.state
																						.casa_legislativa
																						.value ===
																					0) &&
																				moment(
																					item.data_inicio
																				).format(
																					"DD"
																				) ===
																				this.formatDate(
																					dia.fullDate,
																					"DD"
																				) ? (
																				horaCorreta >
																					new Date(
																						item.data_inicio
																					).getTime() +
																					9200000 ? (
																					<div
																						key={
																							index
																						}
																					>
																						{/* <div className="visualizado box-agenda col-12 d-none d-sm-block" style={{borderRadius:"4px"}}>
                                                                                                    <h5 className="textH5" >{(moment(item.data_inicio).format('HH')) + 'h' + moment(item.data_inicio).format('mm')} {item.casa === 2 ? 'Câmara dos Deputados' : item.casa === 1 ? 'Senado Federal' : 'Congresso Nacional'}</h5>
                                                                                                    {item.localCamara !== undefined ?
                                                                                                        <p className="mt-3">LOCAL: {item.localCamara !== undefined && item.localCamara.nome !== undefined ? item.localCamara.nome : null}</p>
                                                                                                        : null}
                                                                                                    <h5 className="textH5 mt-2">{item.orgaos[0].comissao_nome}</h5>
                                                                                                    <h6 className="textH5 mt-2">{item.orgaos[0].tipoOrgao}</h6>
                                                                                                    <p className="mt-3">{item.descricao !== undefined ? item.descricao : null}</p>

                                                                                                </div> */}
																						<div
																							className="visualizado box-agenda col-12 d-block d-sm-none"
																							style={{
																								borderRadius:
																									"4px",
																								marginLeft:
																									"3px",
																							}}
																						>
																							<p
																								style={{
																									fontSize:
																										"13px",
																									marginTop:
																										"0px",
																									fontWeight:
																										"bold",
																								}}
																								className="textH5"
																							>
																								{moment(
																										item.data_inicio
																									)
																									.format(
																										"HH"
																									) +
																									"h" +
																									moment(
																										item.data_inicio
																									).format(
																										"mm"
																									)}{" "}
																							</p>
																							<p
																								style={{
																									fontSize:
																										"13px",
																									marginTop:
																										"-15px",
																									fontWeight:
																										"bold",
																								}}
																							>
																								{" "}
																								{item.casa ===
																									2
																									? "Câmara dos Deputados"
																									: item.casa ===
																										1
																										? "Senado Federal"
																										: "Congresso Nacional"}
																							</p>

																							<hr />

																							{item.localCamara !==
																								undefined ? (
																								<p
																									className="mt-3"
																									style={{
																										fontSize:
																											"13px",
																									}}
																								>
																									LOCAL:{" "}
																									{item.localCamara !==
																										undefined &&
																										item
																											.localCamara
																											.nome !==
																										undefined
																										? item
																											.localCamara
																											.nome
																										: null}
																								</p>
																							) : null}
																							<h5
																								className="textH5 mt-2"
																								style={{
																									fontSize:
																										"13px",
																									marginTop:
																										"-15px",
																								}}
																							>
																								{" "}
																								COMISSÃO:{" "}
																								{
																									item
																										.orgaos[0]
																										.comissao_nome
																								}
																							</h5>
																							<h6 className="textH5 mt-2">
																								{
																									item
																										.orgaos[0]
																										.tipoOrgao
																								}
																							</h6>
																						</div>
																					</div>
																				) : (
																					<div
																						key={
																							index
																						}
																					>
																						{/* <div className="nao-visualizado box-agenda col-12 d-none d-sm-block">
                                                                                                    <h5>{(moment(item.data_inicio).format('HH')) + 'h' + moment(item.data_inicio).format('mm')} {item.casa === 2 ? 'Câmara dos Deputados' : item.casa === 1 ? 'Senado Federal' : 'Congresso Nacional'}</h5>
                                                                                                    {item.localCamara !== undefined ?
                                                                                                        <p className="mt-3">LOCAL: {item.localCamara !== undefined && item.localCamara.nome !== undefined ? item.localCamara.nome : null}</p>
                                                                                                        : null}
                                                                                                    <h5 className="mt-2">{item.orgaos[0].comissao_nome}</h5>
                                                                                                    <h6 className="mt-2">{item.orgaos[0].tipoOrgao}</h6>
                                                                                                    <p className="mt-3">{item.descricao !== undefined && item.descricao !== null ? item.descricao : null}</p>

                                                                                                </div> */}
																						<div className="nao-visualizado box-agenda col-12 d-block d-sm-none text-wrap">
																							<p
																								style={{
																									fontSize:
																										"13px",
																									marginTop:
																										"0px",
																									fontWeight:
																										"bold",
																								}}
																							>
																								{moment(
																										item.data_inicio
																									)
																									.format(
																										"HH"
																									) +
																									"h" +
																									moment(
																										item.data_inicio
																									).format(
																										"mm"
																									)}{" "}
																							</p>
																							<p
																								style={{
																									fontSize:
																										"13px",
																									marginTop:
																										"0px",
																									fontWeight:
																										"bold",
																								}}
																							>
																								{item.casa ===
																									2
																									? "Câmara dos Deputados"
																									: item.casa ===
																										1
																										? "Senado Federal"
																										: "Congresso Nacional"}
																							</p>
																							{item.localCamara !==
																								undefined ? (
																								<div className="mt-3 ">
																									{item.localCamara !==
																										undefined &&
																										item
																											.localCamara
																											.nome !==
																										undefined
																										? item
																											.localCamara
																											.nome
																										: null}
																								</div>
																							) : null}

																							<h5 className="mt-2">
																								{
																									item
																										.orgaos[0]
																										.comissao_nome
																								}
																							</h5>
																							<h6 className="mt-2">
																								{
																									item
																										.orgaos[0]
																										.tipoOrgao
																								}
																							</h6>
																						</div>
																					</div>
																				)
																			) : null;
																		} else {
																			return (this
																				.state
																				.casa_legislativa
																				.value >
																				3 ||
																				this
																					.state
																					.casa_legislativa
																					.value ===
																				0) &&
																				moment(
																					item.data_inicio
																				).format(
																					"DD"
																				) ===
																				this.formatDate(
																					dia.fullDate,
																					"DD"
																				) ? (
																				horaCorreta >
																					new Date(
																						item.data_inicio
																					).getTime() +
																					9200000 ? (
																					<div
																						key={
																							index
																						}
																					>
																						<div className="visualizado box-agenda d-none d-sm-block">
																							<div className="col-sm-12 col-lg-12 col-xl-12">
																								<h5>
																									{moment(
																											item.data_inicio
																										)
																										.format(
																											"HH"
																										) +
																										"h" +
																										moment(
																											item.data_inicio
																										).format(
																											"mm"
																										)}{" "}
																									{item.descricao_prop &&
																										item
																											.descricao_prop
																											.length >
																										0 &&
																										item
																											.descricao_prop[0]
																											.nome
																										? item
																											.descricao_prop[0]
																											.nome
																										: ""}
																								</h5>
																								{item.localCamara !==
																									undefined ? (
																									<p className="mt-3">
																										{item.localCamara !==
																											undefined &&
																											item
																												.localCamara
																												.nome !==
																											undefined
																											? item
																												.localCamara
																												.nome
																											: null}
																									</p>
																								) : null}
																							</div>

																							<hr />

																							<div className="col-sm-12 col-lg-12 col-xl-12 text-wrap">
																								<div className="mt-3 flex-column">
																									<div
																										style={{
																											fontSize:
																												"20px",
																										}}
																									>
																										{item.descricao_prop &&
																											item
																												.descricao_prop
																												.length >
																											0 &&
																											item
																												.descricao_prop[0]
																												.descricao
																											? item
																												.descricao_prop[0]
																												.descricao
																											: ""}
																									</div>
																									<div>
																										{
																											item.apelido
																										}
																									</div>
																								</div>
																							</div>

																							<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																								{/* {item.localCamara !== undefined ? 
                                                                                                    <p className="mt-3">LOCAL: {item.localCamara !== undefined && item.localCamara.nome !== undefined ? item.localCamara.nome : null}</p>
                                                                                                : null} */}

																								<div>
																									<span
																										style={{
																											fontSize:
																												"16px",
																										}}
																									>
																										Descricao
																									</span>
																									<div>
																										{item.descricao !==
																											undefined
																											? item.descricao
																											: null}
																									</div>
																								</div>
																							</div>
																							<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																								<span
																									style={{ fontSize: "16px", }}
																								>
																									Pauta
																								</span>
																								<div>
																									{
																										item
																											.pauta[0]
																											.regime
																									}
																								</div>
																							</div>

																							<hr />

																							<div className="row">
																								<div className="col-sm-6 col-md-6 col-xl-6">
																									<button
																										className="btn bg-white btn-block text-button p-2"
																										style={{
																											border:
																												"1px solid #D8D8D8",
																										}}
																										onClick={() =>
																											this.changeShowModalState(
																												true,
																												item.id
																											)
																										}
																									>
																										<img
																											style={{
																												marginRight:
																													"5px",
																											}}
																											src={require("../../design/assets/images/trash-gray.svg")}
																											alt="anexo"
																										/>
																										<span
																											style={{
																												color:
																													"#9B9B9B",
																											}}
																										>
																											Apagar
																										</span>
																									</button>
																								</div>
																								<div className="col-sm-6 col-md-6 col-xl-6">
																									<NavLink
																										to={`/agenda/agenda-cadastro/editar/${item.id}`}
																									>
																										<button
																											className="btn bg-white btn-block text-button p-2"
																											style={{
																												border:
																													"1px solid #D8D8D8",
																											}}
																										>
																											<img
																												style={{
																													marginRight:
																														"5px",
																												}}
																												src={require("../../design/assets/images/editar-gray.svg")}
																												alt="anexo"
																											/>
																											<span
																												style={{
																													color:
																														"#9B9B9B",
																												}}
																											>
																												Editar
																											</span>
																										</button>
																									</NavLink>
																								</div>
																							</div>
																						</div>

																						<div className="ml-1 visualizado box-agenda col-12 d-block d-sm-none text-wrap">
																							<div>
																								<h5
																									style={{
																										fontSize:
																											"13px",
																										marginTop:
																											"0px",
																										fontWeight:
																											"bold",
																									}}
																								>
																									{moment(
																											item.data_inicio
																										)
																										.format(
																											"HH"
																										) +
																										"h" +
																										moment(
																											item.data_inicio
																										).format(
																											"mm"
																										)}
																								</h5>
																								<h5
																									style={{
																										fontSize:
																											"13px",
																										marginTop:
																											"0px",
																										fontWeight:
																											"bold",
																									}}
																								>
																									{item.descricao_prop &&
																										item
																											.descricao_prop
																											.length >
																										0 &&
																										item
																											.descricao_prop[0]
																											.nome
																										? item
																											.descricao_prop[0]
																											.nome
																										: ""}
																								</h5>
																								<hr />
																								{item.localCamara !==
																									undefined ? (
																									<p
																										style={{
																											fontSize:
																												"13px",
																											marginTop:
																												"0px",
																										}}
																										className="mt-3"
																									>
																										LOCAL:{" "}
																										{item.localCamara !==
																											undefined &&
																											item
																												.localCamara
																												.nome !==
																											undefined
																											? item
																												.localCamara
																												.nome
																											: null}
																									</p>
																								) : null}
																							</div>

																							<div className="mt-2 col-sm-12 col-lg-12 col-xl-12">
																								{/* {item.localCamara !== undefined ? 
                                                                                                    <p className="mt-3">LOCAL: {item.localCamara !== undefined && item.localCamara.nome !== undefined ? item.localCamara.nome : null}</p>
                                                                                                : null} */}

																								<div>
																									<span
																										style={{
																											fontSize:
																												"13px",
																											marginTop:
																												"0px",
																										}}
																									>
																										Descricao:{" "}
																									</span>
																									<div
																										style={{
																											fontSize:
																												"13px",
																											marginTop:
																												"0px",
																										}}
																									>
																										{item.descricao !==
																											undefined
																											? item.descricao
																											: null}
																									</div>
																								</div>
																							</div>
																							<div className="mt-2 col-sm-12 col-lg-12 col-xl-12">
																								<span
																									style={{
																										fontSize:
																											"13px",
																										marginTop:
																											"0px",
																									}}
																								>
																									Pauta:{" "}
																									{
																										item
																											.pauta[0]
																											.regime
																									}
																								</span>
																							</div>

																							<hr />

																							<div className="row">
																								<div className="col-sm-12 col-md-6 col-xl-6">
																									<button
																										className="btn bg-white btn-block text-button p-2"
																										style={{
																											border:
																												"1px solid #D8D8D8",
																										}}
																										onClick={() =>
																											this.changeShowModalState(
																												true,
																												item.id
																											)
																										}
																									>
																										<img
																											style={{
																												marginRight:
																													"5px",
																											}}
																											src={require("../../design/assets/images/trash-gray.svg")}
																											alt="anexo"
																										/>
																										<span
																											style={{
																												color:
																													"#9B9B9B",
																												fontSize:
																													"15px",
																											}}
																										>
																											Apagar
																										</span>
																									</button>
																								</div>
																							</div>
																							<div className="row mt-1">
																								<div className="col-sm-12 col-md-6 col-xl-6">
																									<NavLink
																										to={`/agenda/agenda-cadastro/editar/${item.id}`}
																									>
																										<button
																											className="btn bg-white btn-block text-button p-2"
																											style={{
																												border:
																													"1px solid #D8D8D8",
																											}}
																										>
																											<img
																												style={{
																													marginRight:
																														"5px",
																												}}
																												src={require("../../design/assets/images/editar-gray.svg")}
																												alt="anexo"
																											/>
																											<span
																												style={{
																													color:
																														"#9B9B9B",
																													fontSize:
																														"15px",
																												}}
																											>
																												Editar
																											</span>
																										</button>
																									</NavLink>
																								</div>
																							</div>
																						</div>
																					</div>
																				) : (
																					<div
																						key={
																							index
																						}
																					>
																						<div className="nao-visualizado box-agenda col-12 d-none d-sm-block">
																							<div className="col-sm-12 col-lg-12 col-xl-12">
																								<h5>
																									{moment(
																											item.data_inicio
																										)
																										.format(
																											"HH"
																										) +
																										"h" +
																										moment(
																											item.data_inicio
																										).format(
																											"mm"
																										)}{" "}
																									{item.descricao_prop &&
																										item
																											.descricao_prop
																											.length >
																										0 &&
																										item
																											.descricao_prop[0]
																											.nome
																										? item
																											.descricao_prop[0]
																											.nome
																										: ""}
																								</h5>
																								{item.localCamara !==
																									undefined ? (
																									<p className="mt-3">
																										{item.localCamara !==
																											undefined &&
																											item
																												.localCamara
																												.nome !==
																											undefined
																											? item
																												.localCamara
																												.nome
																											: null}
																									</p>
																								) : null}
																							</div>

																							<hr />

																							<div className="col-sm-12 col-lg-12 col-xl-12 text-wrap">
																								<div className="mt-3 flex-column">
																									<div
																										style={{
																											fontSize:
																												"20px",
																										}}
																									>
																										{item.descricao_prop &&
																											item
																												.descricao_prop
																												.length >
																											0 &&
																											item
																												.descricao_prop[0]
																												.descricao
																											? item
																												.descricao_prop[0]
																												.descricao
																											: ""}
																									</div>
																									<div>
																										{
																											item.apelido
																										}
																									</div>
																								</div>
																							</div>

																							<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																								{/* {item.localCamara !== undefined ? 
                                                                                                    <p className="mt-3">LOCAL: {item.localCamara !== undefined && item.localCamara.nome !== undefined ? item.localCamara.nome : null}</p>
                                                                                                : null} */}

																								<div>
																									<span
																										style={{
																											fontSize:
																												"16px",
																										}}
																									>
																										Descricao
																									</span>
																									<div>
																										{item.descricao !==
																											undefined
																											? item.descricao
																											: null}
																									</div>
																								</div>
																							</div>
																							<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																								<span
																									style={{
																										fontSize:
																											"16px",
																									}}
																								>
																									Pauta
																								</span>
																								<div>
																									{
																										item
																											.pauta[0]
																											.regime
																									}
																								</div>
																							</div>

																							<hr />

																							<div className="row">
																								<div className="col-sm-6 col-md-6 col-xl-6">
																									<button
																										className="btn bg-white btn-block text-button p-2"
																										style={{
																											border:
																												"1px solid #D8D8D8",
																										}}
																										onClick={() =>
																											this.changeShowModalState(
																												true,
																												item.id
																											)
																										}
																									>
																										<img
																											style={{
																												marginRight:
																													"5px",
																											}}
																											src={require("../../design/assets/images/trash-gray.svg")}
																											alt="anexo"
																										/>
																										<span
																											style={{
																												color:
																													"#9B9B9B",
																											}}
																										>
																											Apagar
																										</span>
																									</button>
																								</div>
																								<div className="col-sm-6 col-md-6 col-xl-6">
																									<NavLink
																										to={`/agenda/agenda-cadastro/editar/${item.id}`}
																									>
																										<button
																											className="btn bg-white btn-block text-button p-2"
																											style={{
																												border:
																													"1px solid #D8D8D8",
																											}}
																										>
																											<img
																												style={{
																													marginRight:
																														"5px",
																												}}
																												src={require("../../design/assets/images/editar-gray.svg")}
																												alt="anexo"
																											/>
																											<span
																												style={{
																													color:
																														"#9B9B9B",
																												}}
																											>
																												Editar
																											</span>
																										</button>
																									</NavLink>
																								</div>
																							</div>
																						</div>
																						<div className="ml-1 nao-visualizado box-agenda col-12 d-block d-sm-none text-wrap">
																							<div>
																								<h5
																									style={{
																										fontSize:
																											"13px",
																										marginTop:
																											"0px",
																										fontWeight:
																											"bold",
																									}}
																								>
																									{moment(
																											item.data_inicio
																										)
																										.format(
																											"HH"
																										) +
																										"h" +
																										moment(
																											item.data_inicio
																										).format(
																											"mm"
																										)}
																								</h5>
																								<h5
																									style={{
																										fontSize:
																											"13px",
																										marginTop:
																											"0px",
																										fontWeight:
																											"bold",
																									}}
																								>
																									{item.descricao_prop &&
																										item
																											.descricao_prop
																											.length >
																										0 &&
																										item
																											.descricao_prop[0]
																											.nome
																										? item
																											.descricao_prop[0]
																											.nome
																										: ""}
																								</h5>
																								<hr />
																								{item.localCamara !==
																									undefined ? (
																									<p
																										className="mt-3"
																										style={{
																											fontSize:
																												"13px",
																											marginTop:
																												"0px",
																										}}
																									>
																										Local:{" "}
																										{item.localCamara !==
																											undefined &&
																											item
																												.localCamara
																												.nome !==
																											undefined
																											? item
																												.localCamara
																												.nome
																											: null}
																									</p>
																								) : null}
																							</div>

																							<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																								{/* {item.localCamara !== undefined ? 
                                                                                            <p className="mt-3">LOCAL: {item.localCamara !== undefined && item.localCamara.nome !== undefined ? item.localCamara.nome : null}</p>
                                                                                        : null} */}

																								<div>
																									<span
																										style={{
																											fontSize:
																												"13px",
																											marginTop:
																												"0px",
																										}}
																									>
																										Descricao:{" "}
																									</span>
																									<div
																										style={{
																											fontSize:
																												"13px",
																											marginTop:
																												"0px",
																										}}
																									>
																										{item.descricao !==
																											undefined
																											? item.descricao
																											: null}
																									</div>
																								</div>
																							</div>
																							<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																								<span
																									style={{
																										fontSize:
																											"13px",
																										marginTop:
																											"0px",
																									}}
																								>
																									Pauta:{" "}
																								</span>
																								<div
																									style={{
																										fontSize:
																											"13px",
																										marginTop:
																											"0px",
																									}}
																								>
																									{
																										item
																											.pauta[0]
																											.regime
																									}
																								</div>
																							</div>

																							<hr />

																							<div className="row">
																								<div className="col-sm-12 col-md-6 col-xl-6">
																									<button
																										className="btn bg-white btn-block text-button p-2"
																										style={{
																											border:
																												"1px solid #D8D8D8",
																										}}
																										onClick={() =>
																											this.changeShowModalState(
																												true,
																												item.id
																											)
																										}
																									>
																										<img
																											style={{
																												marginRight:
																													"5px",
																											}}
																											src={require("../../design/assets/images/trash-gray.svg")}
																											alt="anexo"
																										/>
																										<span
																											style={{
																												color:
																													"#9B9B9B",
																											}}
																										>
																											Apagar
																										</span>
																									</button>
																								</div>
																							</div>
																							<div className="row mt-1">
																								<div className="col-sm-12 col-md-6 col-xl-6">
																									<NavLink
																										to={`/agenda/agenda-cadastro/editar/${item.id}`}
																									>
																										<button
																											className="btn bg-white btn-block text-button p-2"
																											style={{
																												border:
																													"1px solid #D8D8D8",
																											}}
																										>
																											<img
																												style={{
																													marginRight:
																														"5px",
																												}}
																												src={require("../../design/assets/images/editar-gray.svg")}
																												alt="anexo"
																											/>
																											<span
																												style={{
																													color:
																														"#9B9B9B",
																												}}
																											>
																												Editar
																											</span>
																										</button>
																									</NavLink>
																								</div>
																							</div>
																						</div>
																					</div>
																				)
																			) : null;
																		}
																	}
																)
																: null}
														</div>
													</div>
												)
											)
											: null}
									</div>
								</div>
							)}
						</section>
					</article>
				) : (




					// ------------------------------- DESK ---------------------------//
					// ------------------------------- DESK ---------------------------//
					// ------------------------------- DESK ---------------------------//
					// ------------------------------- DESK ---------------------------//

					<article className="agenda d-block d-print-none mt-4 ml-4 mb-4 mr-4">
						<section
							className="container-fluid bg-white"
							style={{ borderRadius: "6px" }}
						>
							{this.state.loading ? (<Loading />) :
								(<div className="schedule mr-2">
									<div className="days p-1">
										{this.state.dias !== undefined && Array.isArray(this.state.dias) && this.state.dias.length > 0 ?
											this.state.dias.map((dia, index) => (
												<div className="day" key={index}>
													<div className="calendar-days">
														<p className={`number ${this.formatDate(
															this
																.state
																.selectedDate,
															"DD-MM-YYYY"
														) ===
															this.formatDate(
																dia.fullDate,
																"DD-MM-YYYY"
															)
															? "active"
															: ""
															}`}
														>
															{this.formatDate(
																dia.fullDate,
																"DD"
															)}
														</p>
														<p
															className={`day-of-week ${this
																.state
																.dias[
																new Date().getDay()
															]
																.semana ===
																dia.semana &&
																this.formatDate(
																	this
																		.state
																		.selectedDate,
																	"DD-MM-YYYY"
																) ===
																this.formatDate(
																	dia.fullDate,
																	"DD-MM-YYYY"
																)
																? "active"
																: ""
																}`}
														>
															{dia.semana}
														</p>
													</div>
													<div className="events">
														{this.state
															.eventos !==
															undefined &&
															Array.isArray(
																this.state
																	.eventos
															) &&
															this.state.eventos.length > 0
															? this.state.eventos.map((item, index) => {
																if (item.integrada === true) {
if (item.id_externo == 11370) console.log('***acr3', 'no if de integrada'
	, item.proposicoesSeguidas !== undefined 
	, item.proposicoesSeguidas.length > 0
	, (this.state.casa_legislativa.value === item.casa || this.state.casa_legislativa.value === 0)
	, moment(item.data_inicio).format("DD") === this.formatDate(dia.fullDate, "DD")
	);
																	return item.proposicoesSeguidas !== undefined && item.proposicoesSeguidas.length > 0 &&
																		(this.state.casa_legislativa.value === item.casa || this.state.casa_legislativa.value === 0) &&
																		moment(item.data_inicio).format("DD") === this.formatDate(dia.fullDate, "DD")
																		?
																		(horaCorreta > new Date(item.data_inicio).getTime() + 9200000
																			?
																			(
																				<div key={index}>
																					<div className="visualizado box-agenda col-12 d-none d-sm-block">
																						<h5 className="textH5">
																							{moment(item.data_inicio).format("HH") + "h" + moment(item.data_inicio).format("mm")}{" "}
																							{item.casa === 2 ? "Câmara dos Deputados" : item.casa === 1 ? "Senado Federal" : "Congresso Nacional"}
																						</h5>
																						{item.localCamara !== undefined ? (
																							<p className="mt-3">
																								LOCAL:{" "}
																								{item.localCamara !== undefined && item.localCamara.nome !== undefined ? item.localCamara.nome : null}
																							</p>
																						) : null}
																						<h5 className="textH5 mt-2">
																							{item.orgaos && item.orgaos.length && item.orgaos[0].comissao_nome || ''}
																						</h5>
																						<h6 className="textH5 mt-2">
																							{item.orgaos && item.orgaos.length && item.orgaos[0].tipoOrgao || ''}
																						</h6>
																						<p className="mt-3">
																							{item.descricao !== undefined ? item.descricao : null}
																						</p>
																					</div>
																					<div className="visualizado box-agenda col-12 d-block d-sm-none">
																						<h5 className="textH5">
																							{moment(item.data_inicio)
																								.format("HH") + "h" + moment(item.data_inicio).format("mm")}{" "}
																							{item.casa === 2 ? "Câmara dos Deputados" : item.casa === 1 ? "Senado Federal" : "Congresso Nacional"}
																						</h5>
																						{item.localCamara !== undefined ? (
																							<p className="mt-3">
																								LOCAL:{" "}
																								{item.localCamara !== undefined && item.localCamara.nome !== undefined ? item.localCamara.nome : null}
																							</p>
																						) : null}
																						<h5 className="textH5 mt-2">
																							{item.orgaos && item.orgaos.length && item.orgaos[0].comissao_nome || ''}
																						</h5>
																						<h6 className="textH5 mt-2">
																							{item.orgaos && item.orgaos.length && item.orgaos[0].tipoOrgao || ''}
																						</h6>
																					</div>
																				</div>
																			) : (
																				<div key={index}>
																					<div className="nao-visualizado box-agenda col-12 d-none d-sm-block">
																						<h5>
																							{moment(item.data_inicio).format("HH") + "h" + moment(item.data_inicio).format("mm")}{" "}
																							{item.casa === 2 ? "Câmara dos Deputados" : item.casa === 1 ? "Senado Federal" : "Congresso Nacional"}
																						</h5>
																						{item.localCamara !== undefined ? (
																							<p className="mt-3">
																								LOCAL:{" "}
																								{item.localCamara !== undefined && item.localCamara.nome !== undefined ? item
																									.localCamara
																									.nome
																									: null}
																							</p>
																						) : null}
																						<h5 className="mt-2">
																							{item.orgaos &&
																								item
																									.orgaos
																									.length >
																								0 &&
																								item
																									.orgaos[0]
																									.comissao_nome
																								? item
																									.orgaos[0]
																									.comissao_nome
																								: ""}
																						</h5>
																						<h6 className="mt-2">
																							{item.orgaos &&
																								item
																									.orgaos
																									.length >
																								0 &&
																								item
																									.orgaos[0]
																									.tipoOrgao
																								? item
																									.orgaos[0]
																									.tipoOrgao
																								: ""}
																						</h6>
																						<p className="mt-3">
																							{item.descricao !==
																								undefined &&
																								item.descricao !==
																								null
																								? item.descricao
																								: null}
																						</p>
																					</div>
																					<div className="nao-visualizado box-agenda col-12 d-block d-sm-none text-wrap">
																						<h5>
																							{moment(
																									item.data_inicio
																								)
																								.format(
																									"HH"
																								) +
																								"h" +
																								moment(
																									item.data_inicio
																								).format(
																									"mm"
																								)}{" "}
																							{item.casa ===
																								2
																								? "Câmara dos Deputados"
																								: item.casa ===
																									1
																									? "Senado Federal"
																									: "Congresso Nacional"}
																						</h5>
																						{item.localCamara !==
																							undefined ? (
																							<div className="mt-3 ">
																								{item.localCamara !==
																									undefined &&
																									item
																										.localCamara
																										.nome !==
																									undefined
																									? item
																										.localCamara
																										.nome
																									: null}
																							</div>
																						) : null}
																						<h5 className="mt-2">
																							{item.orgaos &&
																								item
																									.orgaos
																									.length >
																								0 &&
																								item
																									.orgaos[0]
																									.comissao_nome
																								? item
																									.orgaos[0]
																									.comissao_nome
																								: ""}
																						</h5>
																						<h6 className="mt-2">
																							{item.orgaos &&
																								item
																									.orgaos
																									.length >
																								0 &&
																								item
																									.orgaos[0]
																									.tipoOrgao
																								? item
																									.orgaos[0]
																									.tipoOrgao
																								: ""}
																						</h6>
																					</div>
																				</div>
																			)
																		) : null;
																} else {
if (item.id_externo == 11370) console.log('***acr3', 'no else de integrada');
																	return (this.state.casa_legislativa.value > 3 || this.state.casa_legislativa.value === 0) &&
																		moment(item.data_inicio).format("DD") === this.formatDate(dia.fullDate, "DD") ?
																		(horaCorreta > new Date(item.data_inicio).getTime() + 9200000 ? (
																			<div key={index}>
																				{item.localCamara.nome === "Evento de Acontecimentos" ?

																					// CARD DE EVENTO CADASTRADO QUANDO É CRIADO JUNTO COM O CARD DE ACONTECIMENTO

																					<div className="visualizado box-agenda-acontecimento d-none d-sm-block">
																						<div className="col-sm-12 col-lg-12 col-xl-12">
																							<h5>
																								{moment(item.data_inicio).format("HH") + "h" + moment(item.data_inicio).format("mm")}{" "}
																								{item.descricao_prop && item.descricao_prop.length > 0 && item.descricao_prop[0].nome ?
																									item.descricao_prop[0].nome : ""}
																							</h5>
																							{item.localCamara !== undefined ? (
																								<p className="mt-3" style={{ fontSize: "17px", fontWeight: "bolder" }}>
																									{item.localCamara !== undefined && item.localCamara.nome !== undefined ?
																										item.localCamara.nome : null}
																								</p>
																							) : null}
																						</div>

																						<hr />

																						<div className="col-sm-12 col-lg-12 col-xl-12 text-wrap">
																							<div>
																								<p style={{ margin: "0px", fontSize: "11px", fontWeight: "600" }}>
																									Proposição
																								</p>
																								<p style={{ fontSize: "15px", fontWeight: "100" }}>
																									{item.proposicao}
																								</p>
																							</div>
																						</div>

																						<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																							<div>
																								<p style={{ margin: "0px", fontSize: "11px", fontWeight: "600" }}>
																									Descrição do Acontecimento
																								</p>
																								<p className="text-truncate" style={{ fontSize: "15px", fontWeight: "100", maxHeight: "100px", lineClamp: 3, maxLines: 3 }}>
																									{item.descricao}
																								</p>

																							</div>
																						</div>
																					</div>
																					:


																					// CARD DE EVENTO CADASTRADO
																					<div className="visualizado box-agenda d-none d-sm-block">
																						<div className="col-sm-12 col-lg-12 col-xl-12">
																							<h5>
																								{moment(item.data_inicio).format("HH") + "h" + moment(item.data_inicio).format("mm")}{" "}
																								{item.descricao_prop && item.descricao_prop.length > 0 && item.descricao_prop[0].nome ?
																									item.descricao_prop[0].nome : ""}
																							</h5>
																							{item.localCamara !== undefined ? (
																								<p className="mt-3">
																									{item.localCamara !== undefined && item.localCamara.nome !== undefined ?
																										item.localCamara.nome : null}
																								</p>
																							) : null}
																						</div>

																						<hr />

																						<div className="col-sm-12 col-lg-12 col-xl-12 text-wrap">
																							<div className="mt-3 flex-column">
																								<div style={{ fontSize: "20px", }}>
																									{item.descricao_prop && item.descricao_prop.length > 0 && item.descricao_prop[0].descricao ?
																										item.descricao_prop[0].descricao : ""}
																								</div>
																								<div>
																									{item.apelido}
																								</div>
																							</div>
																						</div>

																						<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																							{/* {item.localCamara !== undefined ? 
                                                                                                        <p className="mt-3">LOCAL: {item.localCamara !== undefined && item.localCamara.nome !== undefined ? item.localCamara.nome : null}</p>
                                                                                                        : null} */}

																							{/* {item.proposicoesSeguidas[0].apelido} */}
																							{/* {item.proposicoesSeguidas[0].descricao} */}

																							<div>
																								<span style={{ fontSize: "16px", }}>
																									Descricao
																								</span>
																								<div>
																									{item.descricao !== undefined ? item.descricao : null}
																								</div>
																							</div>
																						</div>
																						<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																							<span style={{ fontSize: "16px", }}>
																								Pauta
																							</span>
																							<div>
																								{item.pauta[0].regime}
																							</div>
																						</div>

																						<hr />

																						<div className="row">
																							<div className="col-sm-6 col-md-6 col-xl-6">
																								<button className="btn bg-white btn-block text-button p-2" style={{ border: "1px solid #D8D8D8", }}
																									onClick={() => this.changeShowModalState(true, item.id)}
																								>
																									<img style={{ marginRight: "5px", }}
																										src={require("../../design/assets/images/trash-gray.svg")}
																										alt="anexo"
																									/>
																									<span style={{ color: "#9B9B9B", }}>
																										Apagar
																									</span>
																								</button>
																							</div>
																							<div className="col-sm-6 col-md-6 col-xl-6">
																								<NavLink to={`/agenda/agenda-cadastro/editar/${item.id}`}>
																									<button className="btn bg-white btn-block text-button p-2" style={{ border: "1px solid #D8D8D8", }}>
																										<img style={{ marginRight: "5px", }}
																											src={require("../../design/assets/images/editar-gray.svg")}
																											alt="anexo"
																										/>
																										<span style={{ color: "#9B9B9B", }}>
																											Editar
																										</span>
																									</button>
																								</NavLink>
																							</div>
																						</div>
																					</div>
																				}


																				<div className="ml-1 visualizado box-agenda col-12 d-block d-sm-none text-wrap">
																					<div>
																						<h5>
																							{moment(
																									item.data_inicio
																								)
																								.format(
																									"HH"
																								) +
																								"h" +
																								moment(
																									item.data_inicio
																								).format(
																									"mm"
																								)}{" "}
																							{item.descricao_prop &&
																								item
																									.descricao_prop
																									.length >
																								0 &&
																								item
																									.descricao_prop[0]
																									.nome
																								? item
																									.descricao_prop[0]
																									.nome
																								: ""}
																						</h5>
																						{item.localCamara !==
																							undefined ? (
																							<p className="mt-3">
																								{item.localCamara !==
																									undefined &&
																									item
																										.localCamara
																										.nome !==
																									undefined
																									? item
																										.localCamara
																										.nome
																									: null}
																							</p>
																						) : null}
																					</div>

																					<div className="mt-2 col-sm-12 col-lg-12 col-xl-12">
																						{/* {item.localCamara !== undefined ? 
                                                                                                    <p className="mt-3">LOCAL: {item.localCamara !== undefined && item.localCamara.nome !== undefined ? item.localCamara.nome : null}</p>
                                                                                                    : null} */}

																						<div>
																							<span
																								style={{
																									fontSize:
																										"16px",
																								}}
																							>
																								Descricao
																							</span>
																							<div>
																								{item.descricao !==
																									undefined
																									? item.descricao
																									: null}
																							</div>
																						</div>
																					</div>
																					<div className="mt-2 col-sm-12 col-lg-12 col-xl-12">
																						<span
																							style={{
																								fontSize:
																									"16px",
																							}}
																						>
																							Pauta
																						</span>
																						<div>
																							{
																								item
																									.pauta[0]
																									.regime
																							}
																						</div>
																					</div>

																					<hr />

																					<div className="row">
																						<div className="col-sm-12 col-md-6 col-xl-6">
																							<button
																								className="btn bg-white btn-block text-button p-2"
																								style={{
																									border:
																										"1px solid #D8D8D8",
																								}}
																								onClick={() =>
																									this.changeShowModalState(
																										true,
																										item.id
																									)
																								}
																							>
																								<img
																									style={{
																										marginRight:
																											"5px",
																									}}
																									src={require("../../design/assets/images/trash-gray.svg")}
																									alt="anexo"
																								/>
																								<span
																									style={{
																										color:
																											"#9B9B9B",
																									}}
																								>
																									Apagar
																								</span>
																							</button>
																						</div>
																					</div>
																					<div className="row mt-1">
																						<div className="col-sm-12 col-md-6 col-xl-6">
																							<NavLink
																								to={`/agenda/agenda-cadastro/editar/${item.id}`}
																							>
																								<button
																									className="btn bg-white btn-block text-button p-2"
																									style={{
																										border:
																											"1px solid #D8D8D8",
																									}}
																								>
																									<img
																										style={{
																											marginRight:
																												"5px",
																										}}
																										src={require("../../design/assets/images/editar-gray.svg")}
																										alt="anexo"
																									/>
																									<span
																										style={{
																											color:
																												"#9B9B9B",
																										}}
																									>
																										Editar
																									</span>
																								</button>
																							</NavLink>
																						</div>
																					</div>
																				</div>
																			</div>
																		) : (
																			<div key={index}>
																				{item.localCamara.nome === "Evento de Acontecimentos" ?

																					// CARD DE EVENTO CADASTRADO QUANDO É CRIADO JUNTO COM O CARD DE ACONTECIMENTO

																					<div className="visualizado box-agenda-acontecimento d-none d-sm-block">
																						<div className="col-sm-12 col-lg-12 col-xl-12">
																							<h5>
																								{moment(item.data_inicio).format("HH") + "h" + moment(item.data_inicio).format("mm")}{" "}
																								{item.descricao_prop && item.descricao_prop.length > 0 && item.descricao_prop[0].nome ?
																									item.descricao_prop[0].nome : ""}
																							</h5>
																							{item.localCamara !== undefined ? (
																								<p className="mt-3" style={{ fontSize: "17px", fontWeight: "bolder" }}>
																									{item.localCamara !== undefined && item.localCamara.nome !== undefined ?
																										item.localCamara.nome : null}
																								</p>
																							) : null}
																						</div>

																						<hr />

																						<div className="col-sm-12 col-lg-12 col-xl-12 text-wrap">
																							<div>
																								<p style={{ margin: "0px", fontSize: "11px", fontWeight: "600" }}>
																									Proposição
																								</p>
																								<p style={{ fontSize: "15px", fontWeight: "100" }}>
																									{item.proposicao}
																								</p>
																							</div>
																						</div>

																						<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																							<div>
																								<p style={{ margin: "0px", fontSize: "11px", fontWeight: "600" }}>
																									Descrição do Acontecimento
																								</p>
																								<p className="text-truncate" style={{ fontSize: "15px", fontWeight: "100", maxHeight: "100px", lineClamp: 3, maxLines: 3 }}>
																									{item.descricao}
																								</p>

																							</div>
																						</div>
																					</div>
																					:


																					// CARD DE EVENTO CADASTRADO
																					<div className="visualizado box-agenda d-none d-sm-block">
																						<div className="col-sm-12 col-lg-12 col-xl-12">
																							<h5>
																								{moment(item.data_inicio).format("HH") + "h" + moment(item.data_inicio).format("mm")}{" "}
																								{item.descricao_prop && item.descricao_prop.length > 0 && item.descricao_prop[0].nome ?
																									item.descricao_prop[0].nome : ""}
																							</h5>
																							{item.localCamara !== undefined ? (
																								<p className="mt-3">
																									{item.localCamara !== undefined && item.localCamara.nome !== undefined ?
																										item.localCamara.nome : null}
																								</p>
																							) : null}
																						</div>

																						<hr />

																						<div className="col-sm-12 col-lg-12 col-xl-12 text-wrap">
																							<div className="mt-3 flex-column">
																								<div style={{ fontSize: "20px", }}>
																									{item.descricao_prop && item.descricao_prop.length > 0 && item.descricao_prop[0].descricao ?
																										item.descricao_prop[0].descricao : ""}
																								</div>
																								<div>
																									{item.apelido}
																								</div>
																							</div>
																						</div>

																						<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																							{/* {item.localCamara !== undefined ? 
							<p className="mt-3">LOCAL: {item.localCamara !== undefined && item.localCamara.nome !== undefined ? item.localCamara.nome : null}</p>
							: null} */}

																							{/* {item.proposicoesSeguidas[0].apelido} */}
																							{/* {item.proposicoesSeguidas[0].descricao} */}

																							<div>
																								<span style={{ fontSize: "16px", }}>
																									Descricao
																								</span>
																								<div>
																									{item.descricao !== undefined ? item.descricao : null}
																								</div>
																							</div>
																						</div>
																						<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																							<span style={{ fontSize: "16px", }}>
																								Pauta
																							</span>
																							<div>
																								{item.pauta[0].regime}
																							</div>
																						</div>

																						<hr />

																						<div className="row">
																							<div className="col-sm-6 col-md-6 col-xl-6">
																								<button className="btn bg-white btn-block text-button p-2" style={{ border: "1px solid #D8D8D8", }}
																									onClick={() => this.changeShowModalState(true, item.id)}
																								>
																									<img style={{ marginRight: "5px", }}
																										src={require("../../design/assets/images/trash-gray.svg")}
																										alt="anexo"
																									/>
																									<span style={{ color: "#9B9B9B", }}>
																										Apagar
																									</span>
																								</button>
																							</div>
																							<div className="col-sm-6 col-md-6 col-xl-6">
																								<NavLink to={`/agenda/agenda-cadastro/editar/${item.id}`}>
																									<button className="btn bg-white btn-block text-button p-2" style={{ border: "1px solid #D8D8D8", }}>
																										<img style={{ marginRight: "5px", }}
																											src={require("../../design/assets/images/editar-gray.svg")}
																											alt="anexo"
																										/>
																										<span style={{ color: "#9B9B9B", }}>
																											Editar
																										</span>
																									</button>
																								</NavLink>
																							</div>
																						</div>
																					</div>
																				}
																				<div className="ml-1 nao-visualizado box-agenda col-12 d-block d-sm-none text-wrap">
																					<div>
																						<h5>
																							{moment(
																									item.data_inicio
																								)
																								.format(
																									"HH"
																								) +
																								"h" +
																								moment(
																									item.data_inicio
																								).format(
																									"mm"
																								)}{" "}
																							{item.descricao_prop &&
																								item
																									.descricao_prop
																									.length >
																								0 &&
																								item
																									.descricao_prop[0]
																									.nome
																								? item
																									.descricao_prop[0]
																									.nome
																								: ""}
																						</h5>
																						{item.localCamara !==
																							undefined ? (
																							<p className="mt-3">
																								{item.localCamara !==
																									undefined &&
																									item
																										.localCamara
																										.nome !==
																									undefined
																									? item
																										.localCamara
																										.nome
																									: null}
																							</p>
																						) : null}
																					</div>

																					<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																						{/* {item.localCamara !== undefined ? 
                                                                                            <p className="mt-3">LOCAL: {item.localCamara !== undefined && item.localCamara.nome !== undefined ? item.localCamara.nome : null}</p>
                                                                                        : null} */}

																						<div>
																							<span
																								style={{
																									fontSize:
																										"16px",
																								}}
																							>
																								Descricao
																							</span>
																							<div>
																								{item.descricao !==
																									undefined
																									? item.descricao
																									: null}
																							</div>
																						</div>
																					</div>
																					<div className="mt-2 col-sm-12 col-lg-12 col-xl-12 text-wrap">
																						<span
																							style={{
																								fontSize:
																									"16px",
																							}}
																						>
																							Pauta
																						</span>
																						<div>
																							{item.pauta &&
																								item
																									.pauta
																									.length >
																								0 &&
																								item
																									.pauta[0]
																									.regime
																								? item
																									.pauta[0]
																									.regime
																								: ""}
																						</div>
																					</div>

																					<hr />

																					<div className="row">
																						<div className="col-sm-12 col-md-6 col-xl-6">
																							<button
																								className="btn bg-white btn-block text-button p-2"
																								style={{
																									border:
																										"1px solid #D8D8D8",
																								}}
																								onClick={() =>
																									this.changeShowModalState(
																										true,
																										item.id
																									)
																								}
																							>
																								<img
																									style={{
																										marginRight:
																											"5px",
																									}}
																									src={require("../../design/assets/images/trash-gray.svg")}
																									alt="anexo"
																								/>
																								<span
																									style={{
																										color:
																											"#9B9B9B",
																									}}
																								>
																									Apagar
																								</span>
																							</button>
																						</div>
																					</div>
																					<div className="row mt-1">
																						<div className="col-sm-12 col-md-6 col-xl-6">
																							<NavLink
																								to={`/agenda/agenda-cadastro/editar/${item.id}`}
																							>
																								<button
																									className="btn bg-white btn-block text-button p-2"
																									style={{
																										border:
																											"1px solid #D8D8D8",
																									}}
																								>
																									<img
																										style={{
																											marginRight:
																												"5px",
																										}}
																										src={require("../../design/assets/images/editar-gray.svg")}
																										alt="anexo"
																									/>
																									<span
																										style={{
																											color:
																												"#9B9B9B",
																										}}
																									>
																										Editar
																									</span>
																								</button>
																							</NavLink>
																						</div>
																					</div>
																				</div>
																			</div>
																		)
																		) : null;
																}
															}
															)
															: null}
													</div>
												</div>
											)
											)
											: null}
									</div>
								</div>
								)}
						</section>
					</article>
				)}
			</>
		);
	}
}

export default Agenda;
