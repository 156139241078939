import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import CampoDeComentario from "./campoDeComentario"
import { put } from "../../services/http"
import authStore from "../../stores/authStore"
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

const regexMencoes = /@@@([0-9]+)@@@/g
const trocarMencionados = ({ comentario, usuariosMapeados }) =>
  comentario.replace(regexMencoes, (...match) => {
    const id = parseInt(match[1])
    const usuario = usuariosMapeados && usuariosMapeados[id]
    const nome = (usuario && usuario.nome) || 'Usuário não identificado'
    return `<b data-id="${id}">${nome}</b>`
  })

const alterar = cancelarEdicao => async ({ id_comentario, link, comentario }) => {
  //console.log('ENTREI AQUI',id_comentario, link, comentario )
  await put(`/comentario/${id_comentario}`, { link, comentario })
  .then( resp => {
    console.log('RESP>>>>>>>',resp)    
  }).catch(err => err)
  
  cancelarEdicao()
}

const Comentario = ({ comentario: dados, podeComentar, responder, editar, excluir, editando,
  cancelarEdicao, excluindo, cancelarExclusao, usuariosMapeados, usuarios, reload }) => {
  const { id, id_usuario, atualizado_em, comentario } = dados
  // const newData = new Date(atualizado_em || criado_em)
  const html = trocarMencionados({ comentario, usuariosMapeados })

  //console.log('>>>>>>',dados)
  return excluindo[id]
    ? <li className="alert alert-warning p-1 pl-3 pr-3 mt-2 mb-2 d-flex justify-content-between d-inline-block text-truncate text-wrap">
      <b>Removendo comentário...</b>
      <button className="btn p-0 fs-12 font-weight-normal text-gray-600"
        onClick={() => cancelarExclusao({ id })}>Cancelar remoção
            </button>
    </li>
    : <li className="bg-gray-200 rounded p-1 pl-3 pr-3 mt-2 mb-2 d-inline-block text-truncate w-100 text-wrap">
      
      <div className=" fs-12 d-flex justify-content-between">

          <div className='col-5 col-sm-5  col-lg-5 col-xl-5'>
              <b className="fs-16   mr-1">{usuariosMapeados[id_usuario].nome}</b>
          </div>

          <div className='col-3 col-sm-3  col-lg-5 col-xl-5'>
              {/* {`${newData.getDate()}/${newData.getMonth()}/${newData.getFullYear()} às ${newData.getHours()}:${newData.getMinutes()}`} */}
              {`${moment(atualizado_em).format('DD/MM/YYYY [ás] HH:mm')}`}
              {/* {atualizado_em ? ' [editado]' : ''} */}
          </div>
         
          

          {authStore.ehAdministrador || id_usuario === authStore.id 
          
          ? 
          
              <div className='col-4 col-sm-4  col-lg-2 col-xl-2 d-flex justify-content-end'>

                <button className="btn p-0 fs-12 font-weight-normal text-gray-600 mr-3 d-inline-block text-truncate"
                  onClick={() => excluir({ id })}>
                  <img src={require('../../design/assets/images/trash-gray.svg')} alt="" />
                </button>
                <button className="btn p-0 fs-12 font-weight-normal text-gray-600 mr-3 "
                  onClick={() => editar({ id })}>
                  <img src={require('../../design/assets/images/editar-gray.svg')} alt="" />
                </button>

              </div> 

          : null}

      </div>

      <br />
      {editando === id ?
        <Fragment>
          <CampoDeComentario
            id={`editar-comentario-${id}`}
            valor={html}
            tituloDaAcao='Salvar'
            id_comentario={id}
            reload={reload}
            usuarios={usuarios}
            acao={alterar(cancelarEdicao)} />
          <div>
            <button className="btn p-0 fs-12 font-weight-normal text-gray-600 mr-3"
              onClick={cancelarEdicao}>Cancelar edição
                        </button>
          </div>
        </Fragment>
        :
        <Fragment>
          <span dangerouslySetInnerHTML={{ __html: html }}></span>
          <div className="d-flex justify-content-between mt-0">
            {podeComentar && id_usuario !== authStore.id ?
              <button className="btn p-0 fs-12 font-weight-normal d-flex Client-btn Client-link "
                onClick={() => responder(usuariosMapeados[id_usuario])}>Responder
                                {/* {`${newData.getDate()}/${newData.getMonth()} às ${newData.getHours()}:${newData.getMinutes()}`} */}
                <div className="ml-4">
                  {`${moment(atualizado_em).format('DD/MM/YYYY [ás] HH:mm')}`}
                </div>
              </button>
              : <div></div>}
          </div>
        </Fragment>
      }
    </li>
}

export default observer(({ comentarios, usuarios, ...props }) => {
  const usuariosMapeados = (usuarios || []).reduce((p, { id, nome }) => ({ ...p, [id]: { id, nome } }), {})
  return (
    <ul className="list-none list-group mt-4 fs-14">
      {
        comentarios.length > 1 && !props.verMais ? <>
          <span className="cursor-pointer Client-link fs-16" onClick={() => props.alterarVerMais(true)}>
            Ver mais {`(${comentarios.length})`}
          </span>
          {
          comentarios.slice(0, 1) !== undefined && 
          Array.isArray(comentarios.slice(0, 1)) &&
          comentarios.slice(0, 1).length > 0 ?
          comentarios.slice(0, 1).map(comentario =>
            <Comentario key={`comentario-${comentario.id}`}
              {...props}
              comentario={comentario}
              usuarios={usuarios}
              usuariosMapeados={usuariosMapeados} />) : null
          }
        </>
          : <>
            {
             comentarios !== undefined && 
             Array.isArray(comentarios) && 
             comentarios.length > 0 ?
             comentarios.map(comentario =>
              <Comentario key={`comentario-${comentario.id}`}
                {...props}
                comentario={comentario}
                usuarios={usuarios}
                usuariosMapeados={usuariosMapeados} />) : null
            }
          </>
      }

    </ul>)
})
