import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { get, post, put } from '../../../services/http'
import moment from 'moment-timezone';
import Loading from '../../../componentes/loading'
import FormatInput from '../../../componentes/forms/inputs/formatInput'
import ClientCheckboxWithSelect from '../../../componentes/forms/basic/clientSelectWithSearch'
import ClientSelect from '../../../componentes/forms/basic/clientSelect'
import SelectInfluenciarSobre from '../../../componentes/forms/basic/selectInfluenciarSobre'
import { resultadoClient } from '../../../enums/resultadoDaAcao';
import Anexos from '../components/anexos'

moment.tz.setDefault('UTC');

export default function FormDialog(props) {

    const user = JSON.parse(window.localStorage.getItem('user'))

    //console.log(user.id)

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [ambito, setAmbito] = React.useState([]);
    const [ambitoSelected, setAmbitoSelected] = React.useState(props.create ? {} : props.dados && { value: props.dados.ambito_id });
    const [casaSelected, setCasaSelected] = React.useState(false);
    const [prazo, setPrazo] = React.useState(props.create ? moment(new Date()).format('YYYY-MM-DD') : new moment(props.dados && props.dados.prazo).format('YYYY-MM-DD'));
    const [responsavel, setResponsavel] = React.useState(props.create ? user.id : props.dados.idResponsavel);
    const [descricao, setDescricao] = React.useState(props.create ? '' : props.dados && props.dados.descricao);
    const [resultado, setResultado] = React.useState(props.create ? '' : props.dados && props.dados.resultado === null ? 0 : props.dados.resultado)
    const [casaToSave, setCasaToSave] = React.useState(props.create ? '' : props.dados && props.dados.casa_legislativa)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const dados = props.dados

    const handleClickOpen = () => {
        setOpen(true);
        setDescricao(props.create ? '' : props.dados && props.dados.descricao)
        if (!props.create) {
            getAmbito(props.dados && props.dados.casa_legislativa)
        }
    };

    const handleClose = () => {
        setOpen(false);
    }

    const handlePrazo = (value) => {
        setPrazo(moment(value).format('YYYY-MM-DD'))
    }

    const handleResponsavel = (value) => {
        setResponsavel(value.value)
    }

    const handleDescricao = (value) => {
        setDescricao(value)
    }

    const casa = [
        { id: 1, nome: 'Senado Federal' },
        { id: 2, nome: 'Câmara dos Deputados' },
        { id: 3, nome: 'Congresso Nacional' }
    ]


    const save = async () => {
        if (props.create === true) {

            const data = {
                tipo: 1,
                idProposicao: props.idProposicaoCasaExterna,
                proposicao: props.descricaoProposicao,
                id_proposicao_novo: props.idNovo,
                idResponsavel: responsavel,
                responsavel: props.usuarios.map(i => i.id === responsavel ? i.nome : null).filter(x => x)[0],
                prazo: new Date(prazo),
                descricao: descricao,
                ambito_id: ambitoSelected.value,
                casa_legislativa: casaToSave
            }

            if (data.ambito_id === undefined || data.casa_legislativa === undefined || data.descricao === '') {
                alert('É necessário preencher todos os campos!')
            } else {
                setLoading(true)
                await post(`acao/${props.idProposicaoCasaExterna}/acao-influencia`, data)
                    .then(res => {
                        salvarInfluenciaSobre(res[0].id)
                        setOpen(false);
                        setLoading(false)
                        props.onClick()
                        props.mensagens()

                    })
                    .catch(err => err)
            }

        } else {
            const data = {
                tipo: 1,
                id: props.dados && props.dados.id,
                idProposicao: props.idProposicaoCasaExterna,
                proposicao: props.descricaoProposicao,
                idResponsavel: responsavel,
                responsavel: props.usuarios.map(i => i.id === responsavel ? i.nome : null).filter(x => x)[0],
                prazo: new Date(prazo),
                descricao: descricao,
                resultado: resultado,
                ambito_id: ambitoSelected.value,
                casa_legislativa: casaToSave
            }
            setLoading(true)
            await put(`acao/${props.idProposicaoCasaExterna}/acao-influencia/${props.dados.id}`, data)
                .then(res => {
                    setLoading(false)
                    props.onClick()
                    props.mensagens()
                })
                .catch(err => err)
        }
    }

    const salvarInfluenciaSobre = async (id) => {

        const dadosInfluenciaSobre = await JSON.parse(window.localStorage.getItem('influenciaSobre'))
        const data = {
            dadosInfluenciaSobre: dadosInfluenciaSobre,
            id_acao_influencia: id
        }
        await post('parlamentar/acao-influencia/recuperando-id', data)
            .then(response => {

                //window.location.reload()
                window.localStorage.removeItem('influenciaSobre')

                setLoading(false)
                setOpen(false);
            })
            .catch(err => err)
    }

    const onChangeCasaLegislativa = async (value) => {
        getAmbito(value.value)
    }

    const getAmbito = async (id) => {
        await get(`/ambito/${id}/cliente`)
            .then(res => {
                var array = []
                res.map(i => {
                    const data = {
                        id: i.id,
                        valor: `${i.sigla} - ${i.descricao}`
                    }
                    return array.push(data)
                })
                setAmbito(array)
                setCasaSelected(true)
                setCasaToSave(id)
            })
            .catch(err => err)
    }

    const onChangeAmbito = (ambito) => {
        setAmbitoSelected(ambito)
    }

    const handleResultado = (resultadoClient) => {
        setResultado(resultadoClient.id)
    }

    const tamanhoTela = window.innerWidth

    const dadosInfluenciarSobre = {
        idProposicao: props.idProposicaoCasaExterna,
        idResponsavel: responsavel,
        prazo: new Date(prazo),
        proposicao: props.descricaoProposicao,
        responsavel: '-',
        tipo: 1
    }





    // console.log('>>>>>', props.dados && props.dados.id)
    // console.log('DATA CODIGO', new moment(props.dados && props.dados.prazo).format('YYYY-MM-DD'))
    // console.log("PRAZZZZZZZZZZZZZOOOOOOOOO", (props.dados && props.dados.prazo))
    // console.log("PRAZO", props)

    return (
        <div>
            {props.create === true ?
                props.button === true ?
                    <div className="Client-btn btn-primario centralizer mt-4 btn-acao-criar" style={{ borderRadius: "7px" }} onClick={handleClickOpen}>
                        CRIAR AÇÃO DE INFLUÊNCIA
                    </div>
                    :
                    <div onClick={handleClickOpen}>
                        <span className='Client-texto mr-2 fs-18'>+</span>
                        <span className="Client-link">Ação de influência</span>
                    </div>
                :
                <div onClick={handleClickOpen}>
                    <img src={require('../../../design/assets/images/editar-gray.svg')} alt="" />
                </div>
            }

            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title" >

                <DialogContent
                    style={{
                        width: tamanhoTela > 600 ? 600 : tamanhoTela,
                        backgroundColor: "white",
                        color: '#3E4049',
                        height: loading ? 500 : null
                    }}
                >

                    {loading ? <Loading /> :

                        <div>

                            <h5>Ação de Influência</h5>

                            <div className="row">

                                <div className="col-md-6">
                                    <p className="Client-label">Prazo</p>
                                    <input defaultValue={prazo} className="inputAcaoInfluencia" type="date" onChange={(e) => handlePrazo(e.target.value)} />
                                    <p className="border-bottom-input mt-2"></p>
                                </div>

                                <FormatInput
                                    className="Client-select"
                                    cols="12 12 6"
                                    label="Responsável"
                                >
                                    <ClientCheckboxWithSelect
                                        value={responsavel}
                                        onChange={idResponsavel => handleResponsavel(idResponsavel)}
                                        options={props.usuarios && props.usuarios.map(item => ({ value: item.id, label: item.nome }))}
                                        placeholder={props.usuarios && props.usuarios.map(i => i.id === responsavel ? i.nome : null).filter(x => x)[0]}
                                    />
                                </FormatInput>

                                <div className="col-12">
                                    <p className="Client-label">Descrição</p>
                                    <textarea maxLength="4000" type="text" defaultValue={descricao} className="form-control" rows="3" placeholder="Digite aqui" onChange={(e) => handleDescricao(e.target.value)} />
                                </div>
                            </div>


                            <div className="row" >
                                <FormatInput
                                    className="Client-select"
                                    cols="12 12 6"
                                    label="Casa Legislativa"
                                >
                                    <ClientCheckboxWithSelect
                                        value={(casa) ? casa.id : null}
                                        onChange={casa_legislativa => onChangeCasaLegislativa(casa_legislativa)}
                                        options={casa && casa.map(item => ({ value: item.id, label: item.nome }))}
                                        placeholder={!props.create && props.dados && props.dados.casa_legislativa ? casa.map(i => i.id === props.dados.casa_legislativa ? i.nome : null).filter(x => x)[0] : null}
                                    />
                                </FormatInput>

                                <FormatInput
                                    cols="12 12 6"
                                    label="Âmbito"
                                >
                                    <ClientCheckboxWithSelect
                                        value={ambito ? ambito.id : null}
                                        onChange={ambito => onChangeAmbito(ambito)}
                                        options={ambito && ambito.map(item => ({ value: item.id, label: item.valor }))}
                                        disabled={casaSelected === false}
                                        placeholder={!props.create && props.dados && props.dados.ambito_id ? ambito.map(i => i.id === props.dados.ambito_id ? i.valor : null).filter(x => x)[0] : null}
                                    />
                                </FormatInput>


                                <FormatInput
                                    cols={props.create ? "12 12 12" : "12 12 6"}
                                    className="Client-select"
                                    label="Influência Sob"

                                >
                                    <SelectInfluenciarSobre
                                        id_acao_influencia={dados && dados.id}
                                        data={dadosInfluenciarSobre}
                                    />
                                    <i className="Client-angulo down position-absolute" style={{ top: '2.5em', right: '0.5em' }}></i>
                                    <p className="border-bottom-input"></p>
                                </FormatInput>



                                {props.create ? null :
                                    <FormatInput
                                        cols="12 12 6"
                                        className="Client-select"
                                        label="Resultado"
                                    >

                                        {resultado === 0 ?
                                            <ClientSelect
                                                items={resultadoClient}
                                                defaultValue={{ id: 99, value: 'Pendente', icon: 'pendente' }}
                                                value={resultado}
                                                onChange={(resultadoClient) => handleResultado(resultadoClient)}
                                            />
                                            :
                                            resultadoClient !== undefined && Array.isArray(resultadoClient) && resultadoClient.length > 0 ?
                                                resultadoClient.map(item => {
                                                    if (item.id === resultado) {
                                                        return (
                                                            <ClientSelect
                                                                items={resultadoClient}
                                                                defaultValue={{ id: item.id, value: item.value, icon: item.icon }}
                                                                value={resultado}
                                                                onChange={(resultadoClient) => handleResultado(resultadoClient)}
                                                            />
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                }) : null
                                        }

                                    </FormatInput>
                                }


                            </div>

                            {props.create === true ?
                                <Anexos
                                    idParametro={props.dados && props.dados.id}
                                    idProposicaoCasaExterna={props.idProposicaoCasaExterna}
                                    create={props.create}
                                    modulo={'acao-influencia'}
                                />
                                :
                                null
                            }

                            <div className="row">
                                <div className="col-md-6 text-center borderBotaoModalAcaoInfluencia borderBotaoModalAcaoInfluenciaLeft" onClick={handleClose}>
                                    CANCELAR
                                </div>
                                <div className="col-md-6 text-center borderBotaoModalAcaoInfluencia" onClick={save}>
                                    SALVAR
                                </div>
                            </div>

                        </div>
                    }

                </DialogContent>

            </Dialog>
        </div>
    );
}

