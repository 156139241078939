import React, { Component } from 'react';
import Loading from '../../../componentes/loading';
import Grafico from '../graficos/graficoParlamentarPorProfissao'
import moment from 'moment-timezone';
// import Pagination from "react-js-pagination";
import { get } from "../../../services/http"
import { inject, observer } from 'mobx-react'

moment.tz.setDefault('UTC');

@inject('authStore')
@observer

class ParlamentarPorProfissao extends Component {
    state = {
        loading: true,
        dataInicio: '',

        tiposQuantidade: [],
        totalGrafico: [],

        resultadoRelatorioLista: [],

        paginaAtualLista: 1,
        limiteLista: 3,
        quantidadeLista: 0,


        totalQuantidadeAdvogado: 0,
        totalQuantidadeEmpresario: 0,
        totalQuantidadeAdm: 0,
        totalQuantidadeMedico: 0,
        totalQuantidadeProfessor: 0,

        dataIni: '',
        dataFim: '',
    }

    async componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        await this.getDataRelatorio(1)

        this.setState({
            dataInicio: moment(this.props.match.params.dtIni).format('DD/MM/YYYY'),
            dataFim: moment(this.props.match.params.dtFim).format('DD/MM/YYYY'),
        })
    }


    async getDataRelatorio(page) {
        const dtIni = this.props.match.params.dtIni
        const dtFim = this.props.match.params.dtFim
        await get(`/relatorio/parlamentares/profissao/${dtIni}/${dtFim}`)
            .then(res => {
                // console.log(res)
                const dados = res || []
                this.setState({
                    tiposQuantidade: dados.tabela,
                    totalGrafico: dados.grafico,
                    loading: false
                })

            })
    }

    imprimir() {
        window.print()
    }


    render() {

        const dados = [
            this.state.totalGrafico.length > 0 ? this.state.totalGrafico[0].quantidade : 0,
            this.state.totalGrafico.length > 0 ? this.state.totalGrafico[1].quantidade : 0,
            this.state.totalGrafico.length > 0 ? this.state.totalGrafico[2].quantidade : 0,
            this.state.totalGrafico.length > 0 ? this.state.totalGrafico[3].quantidade : 0,
            this.state.totalGrafico.length > 0 ? this.state.totalGrafico[4].quantidade : 0,
        ]
        // const dataDaPagina = `Período de ${this.state.dataInicio} a ${this.state.dataFim}`
        // const dataDaPagina = 'Parlamentares em Exercício'

        // const dominio = window.localStorage.getItem('dominio')
        // var logo = null
        // switch (dominio) {
        //     case 'Client':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'blueClient':
        //         logo = 'icon-purpleClient corblueClient'
        //         break

        //     case 'darkBlueClient':
        //         logo = 'icon-logo_darkBlueClient cordarkBlueClient'
        //         break

        //     case 'dev':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'localhost':
        //         logo = `icon-purpleClient cordarkBlueClient`
        //         break

        //     default:
        //         logo = 'icon-logo-Client corClient'
        // }
        return (
            this.state.loading ? <Loading /> :
                <div className="container paddingGeralXS">

                    <div id="non-printable" className="mt-5">
                        <button className="btn btn-danger" onClick={this.imprimir}>
                            <img
                                src={require('../../../design/assets/images/impressora.svg')}
                                alt="anexo"
                            />
                        </button>
                    </div>

                    <div className="pt-5">

                        <div className="row">
                        <div className="col-1" >
                        <a href="/">
                                <div className=" logoMenuRelatorios text-center"></div>
                                    {/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
                                </a>
                            </div>
                            <div className="col-11">
                                <div>
                                    <p className="tituloRelatorio">
                                        Relatório
                                        </p>
                                </div>
                                <div>
                                    <p className="descricaoTituloRelatorio">
                                        Quantidade de Parlamentares classificados a partir da profissão
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 text-center periodoFiltro">
                                <div className="">
                                    {/* {dataDaPagina} */}
                                    Parlamentares em Exercício
                                </div>
                            </div>
                        </div>


                        {/* GRAFICO  */}

                        <div className="row">

                            <div className="col-md-6 divGrafico" id="align-grafico">
                                <Grafico
                                    dados={dados}
                                />
                            </div>
                            <div className="col-md-6 align-self-center divIndice" id="divAlignIndice">
                                <div className="row">

                                    <div className="corIndiceAdvogado"></div>
                                    <div>
                                        <p className="textoIndiceAdvogado">
                                            {this.state.totalGrafico[0].profissao}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceAdvogado">
                                            {this.state.totalGrafico[0].quantidade}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceEmpresario"></div>
                                    <div>
                                        <p className="textoIndiceEmpresario">
                                            {this.state.totalGrafico[1].profissao}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceEmpresario">
                                            {this.state.totalGrafico[1].quantidade}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceAdm"></div>
                                    <div>
                                        <p className="textoIndiceAdm">
                                            {this.state.totalGrafico[2].profissao}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceAdm">
                                            {this.state.totalGrafico[2].quantidade}
                                        </p>
                                    </div>

                                </div>


                                <div className="row">

                                    <div className="corIndiceMedico"></div>
                                    <div>
                                        <p className="textoIndiceMedico">
                                            {this.state.totalGrafico[3].profissao}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceMedico">
                                            {this.state.totalGrafico[3].quantidade}
                                        </p>
                                    </div>

                                </div>


                                <div className="row">

                                    <div className="corIndiceProfessor"></div>
                                    <div>
                                        <p className="textoIndiceProfessor">
                                            {this.state.totalGrafico[4].profissao}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceProfessor">
                                            {this.state.totalGrafico[4].quantidade}
                                        </p>
                                    </div>

                                </div>
                            </div>

                        </div>

                        {/* GRAFICO CAMARA */}


                        <div className="mt-5">
                            <p className="tituloTabelaCamara">
                                Lista Completa
                            </p>
                        </div>

                        <div className="mb-5">
                            <table className="table table-striped">
                                {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                                <thead className="theadStyleLista">
                                    <tr>
                                        <th className="headProfissaoLista">Profissão</th>
                                        <th className="headQuantLista">Quantidade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tiposQuantidade !== undefined && Array.isArray(this.state.tiposQuantidade) && this.state.tiposQuantidade.length > 0 ?
                                        this.state.tiposQuantidade.map((item, index) => {
                                            // console.log(item)
                                            return (
                                                item.profissao !== '' && item.quantidade !== 0 ?
                                                    <tr key={index}>
                                                        <td className="dadosColunaProfissaoLista">{item.profissao}</td>
                                                        <td className="dadosColunaQuantLista">{item.quantidade}</td>
                                                    </tr>
                                                    : null
                                            )
                                        })
                                        : null}
                                </tbody>
                            </table>
                        </div>


                    </div>

                </div>

        )
    }
}

export default ParlamentarPorProfissao