import React, {Component} from 'react';
import Loading from '../../../componentes/loading';
import LoadingCircle  from '../../../componentes/loadingCircle'
import AccordionTemas from '../../../componentes/accordion'
import GraficoTema from './graficoTema'
import GraficoTemaComissoes from './graficoTemaComissoes'
import { get } from '../../../services/http'
import ClientCheckboxWithSelect from '../../../componentes/forms/basic/clientSelectWithSearch'
import FormatInput from '../../../componentes/forms/inputs/formatInput'



class InsightTema extends Component {
    state = {
        loadingCircle:false,
        loading: false,
        dadosApi: [],
        dadosTemas: [],
        dadosComissao: [],
        dadosAmbito: [],
        graficoTema: [],
        dadosTemaPosicao:null,
        casaState:null,
        optionComissao:[],
        casasLegislativas:[
            {value:'1', label:'Câmara dos Deputados'},
            {value:'2', label:'Senado Federal'},
            // {value:'3', label:'Congresso Nacional'}
        ],
        mostraGraficoComissao:false,
        dataIni:null,
        dataFim:null,
        dataIniTram:null,
        dataFimTram:null,
        dataIniPosicao:null,
        dataFimPosicao:null,
        ambito:null,
        tempoMedTram:null,
        temaOption:null,
        temaPosicaoValor:null,
        mostrarMensagemSemResuTempoMedio:false,
        mostraSemResultComissoes:false,
        casaTema:null,
        siglaCasaTema:null,
        casaOptionTemMedio:null,
        optionComissaoValue:null,
        casasLegisTemasMaisRece:null
    }

    // async componentDidMount() {
    //      await this.getData('CN')
    //      await this.getOptionComissao('CN')
    // }
    //esse código do date traz a data atual no  formato yyyy-mm-dd
    async getData(siglaCasa) {
        await get(`insights/tema/${siglaCasa}/20/${this.state.dataIni}/${this.state.dataFim}`)
        .then(response => {
            this.setState({ dadosApi: response })
            if (response !== undefined){
                if(response.length === 0){
                    this.setState({ mostraSemResultComissoes: true })
                    setTimeout(()=>
                    this.setState({ mostraSemResultComissoes: false })
                    ,5000)
                }                    
            }            
        }).catch(err => {
            return err
        })
    }
    async getOptionComissao(siglaCasa) {
        await get(`insights/tema/ambito/lista/${siglaCasa}`)
        .then(response => {
            this.setState({ optionComissao: response })
        }).catch(err => err)
    }    
    async getComissao(comissao) {
        await get(`insights/tema/${this.state.siglaCasaTema}/20/${this.state.dataIni}/${this.state.dataFim}/${comissao}`)
        .then(response => {
            this.setState({ dadosComissao: response })
            if (response !== undefined){
                if(response.length === 0){
                    this.setState({ mostraSemResultComissoes: true })
                    setTimeout(()=>
                    this.setState({ mostraSemResultComissoes: false })
                    ,5000)
                }                    
            }
        }).catch(err => err)
    }
    async getTemas(codCasa) {   

            this.setState({loadingCircle:true}) 
            await get(`insights/tema/${codCasa}`)
            .then(response => {
                this.setState({ dadosTemas: response, loadingCircle:false })
            }).catch(err => err)
        
        //

    }
    async getAmbitos(siglaCasa) {

        this.setState({loadingCircle:true}) 
        await get(`insights/tema/ambito/lista/${siglaCasa}`)
        .then(response => {
            this.setState({ dadosAmbito: response, loadingCircle:false })
        }).catch(err => err)
    }
    async getTemMedTram(codTema,siglaCasa,codAmb) {
        await get(`insights/tema/media/${codTema}/${siglaCasa}/${this.state.dataIniTram}/${this.state.dataFimTram}/${codAmb}`)
        .then(response => {
            this.setState({ tempoMedTram: response })
            console.log(response)
            if (response.total === null){
                this.setState({ mostrarMensagemSemResuTempoMedio: true })
                setTimeout(()=>
                this.setState({ mostrarMensagemSemResuTempoMedio: false })
                ,5000)
            }
        }).catch(err => err)
    }
    async getTemaPosicao(codTema) {
        let dtIni,dtFim
        this.state.dataIniPosicao ? dtIni = this.state.dataIniPosicao: dtIni='2002-02-25'
        this.state.dataFimPosicao ? dtFim = this.state.dataFimPosicao: dtFim = new Date().toISOString().split('T')[0]
        await get(`insights/tema/${codTema}/${dtIni}/${dtFim}`)
        .then(response => { 
            this.setState({ dadosTemaPosicao: response })
        }).catch(err => err)
    }

    onChangeComissao = (comissao) => {
        this.setState({
            mostraGraficoComissao:true,
            optionComissaoValue:comissao
        })
        this.getComissao(comissao.label)
    }
    onChangeCasaLegislativaTemas = (casa) => {
        let siglaCasa
        casa.value === '1' ? siglaCasa = 'CD' : casa.value === '2' ? siglaCasa = 'SF': siglaCasa = 'CN'
        this.setState({
            casasLegisTemasMaisRece:casa,
            // casaOptionTemMedio:casa,
            optionComissaoValue:null,
            siglaCasaTema:siglaCasa,
            dadosComissao:[]
        })       
    }
    onChangeCasaLegislativa = (casa) => {
        let codCasa,siglaCasa
        casa.value === '3' ? codCasa = 1: codCasa = casa.value
        casa.value === '1' ? siglaCasa = 'CD' : casa.value === '2' ? siglaCasa = 'SF': siglaCasa = 'CN'
        this.setState({
            casaOptionTemMedio:casa,
            casaState:siglaCasa,
            temaOption:null,
            ambito:null,
           
        })        
        this.getTemas(codCasa)
        this.getAmbitos(siglaCasa)
    }
    onChangeTema = (tema) => {
        this.setState({
            temaOption:tema            
        })
    }     
    onChangeAmbito = (ambito) => {
        this.setState({
            ambito:ambito
        })               
    }
    onChangeTemaPosicao = (tema) => {
        this.setState({
            temaPosicaoValor:tema.value
        })
        this.getTemaPosicao(tema.value)
    }

    dataInicialTemas = (dtini) =>{
        this.setState({dataIni:dtini.target.value})
    }

    dataFinalTemas = (dtFim) =>{
        this.setState({dataFim:dtFim.target.value})
    }
    dataInicialTram = (dtini) =>{
        this.setState({dataIniTram:dtini.target.value})
    }

    dataFinalTram = (dtFim) =>{
        this.setState({dataFimTram:dtFim.target.value})
    }
    dataInicialPosicao = (dtini) =>{
        this.setState({dataIniPosicao:dtini.target.value})
    }

    dataFinalPosicao = (dtFim) =>{
        this.setState({dataFimPosicao:dtFim.target.value})
    }

    enviaDataBack() {
        this.setState({casaTema:this.state.casasLegisTemasMaisRece.label})
        this.getData(this.state.siglaCasaTema)
        this.getOptionComissao(this.state.siglaCasaTema)
    }
    enviaDataTempoMedio() {
        if(this.state.temaOption !== null && this.state.casaState !== null && this.state.ambito !== null){
            this.getTemMedTram(this.state.temaOption.value,this.state.casaState,this.state.ambito.label)
        }
    }
    enviaDataPosicao() {
        if(this.state.dadosTemaPosicao !== null){
            this.getTemaPosicao(this.state.temaPosicaoValor)
        }
    }

    limparDatasTemas() {
        document.getElementsByClassName('data-inicial-temas')[0].value=""
        document.getElementsByClassName('data-final-temas')[0].value="";
        this.setState({
            dadosApi:[],
            casasLegisTemasMaisRece:null,
            dataIni:null,
            dataFim:null,
            mostraGraficoComissao:false
        })  
    }
    limparDatasTempo() {
        document.getElementsByClassName('data-inicial-tempo')[0].value=""
        document.getElementsByClassName('data-final-tempo')[0].value="";
        this.setState({
            casaOptionTemMedio:null,
            temaOption:null,
            ambito:null,
            dadosTemas:[],
            dadosAmbito:[],
            casaState:null,
            dataIniTram:null,
            dataFimTram:null,
            tempoMedTram:null
        })    

    }
    render() {
    
          
        const {casasLegisTemasMaisRece} = this.state
        const {optionComissaoValue} = this.state
        const {temaOption} = this.state
        const {ambito} = this.state
        const {casaOptionTemMedio} = this.state
        let legendasComissao =[]
       
        this.state.dadosComissao !== undefined ?                                    
        this.state.dadosComissao.map((dado,index)=>
            legendasComissao[index] = dado.tema
        ): legendasComissao = null

        Array.isArray(legendasComissao) ? legendasComissao = legendasComissao.values() : legendasComissao = null

        const larguraTela = window.innerWidth
        const classButtonPesquisar = larguraTela <= 600 ? 'col-12  col-sm-6  col-lg-6 col-xl-6 d-flex justify-content-center mt-3' : 'col-12  col-sm-6  col-lg-6 col-xl-6 d-flex justify-content-end'
        return (
            this.state.loading ? <Loading/> :
            
            <div style={{ backgroundColor: '#fff', margin: "0 0 58px 0" }} className="p-2">
                
                    <div className="acordionsBordas mt-2">
                        <AccordionTemas
                            mb={'mb-0'}
                            p={'p-0'}
                            titulo="Temas que mais tem recebido atenção"
                        >
                         <hr/>
                        <div className="row p-4">  
                            <div className="col-12 col-sm-3  col-lg-3 col-xl-3 mb-2 ">
                                <div className="label-insight">Data Inicial de Apresentação</div> 
                                <input id="data-inicial" className="data-inicial-temas"  type="date" onChange={dtini => this.dataInicialTemas(dtini)} />    
                            </div>
                            <div className="col-12 col-sm-3  col-lg-3 col-xl-3"> 
                                <div className="label-insight">Data Final de Apresentação</div>  
                                <input id="data-final" className="data-final-temas"  type="date" onChange={dtFim => this.dataFinalTemas(dtFim)}   />
                            </div>

                            <div className={this.state.dataIni !== null && this.state.dataFim && this.state.casasLegisTemasMaisRece ?
                                 `${classButtonPesquisar}`: 'd-none'} >
                        
                                <img id="pesquisa-dash" className=""  src={require('../../../design/assets/images/btn_pesquisar.svg')} alt="btn-pesquisar" onClick={() => this.enviaDataBack()}  style={{cursor: 'pointer'}}/>
                                <img  id="limpar-dash" className="ml-3"  src={require('../../../design/assets/images/btn_limpar.svg')} alt="btn-limpar" onClick={() => this.limparDatasTemas()} style={{cursor: 'pointer'}}/>
                            </div>               

                        </div>

                        <div className="row justify-content-start">
                            <div className="col-sm-4  mt-3 ">
                            <FormatInput
                                className="Client-select"
                                label="Casa Legislativa"
                                >
                                <ClientCheckboxWithSelect
                                value={casasLegisTemasMaisRece}
                                onChange={casa_legislativa => this.onChangeCasaLegislativaTemas(casa_legislativa)}
                                options={this.state.casasLegislativas}
                                    placeholder={
                                        "Escolha uma casa"}
                                />
                                </FormatInput>                         
                            </div> 
                        </div>                        
                         <div className={this.state.dadosApi !== undefined ?
                          this.state.dadosApi.length > 0 ? `card-grafico-parlamentar`: `d-none` :  `d-none`}>
                             <div className="row justify-content-center">                         
                                <div className="col-sm-1-md-1 item-descricao2">
                                   {this.state.casaTema !== null ? this.state.casaTema : null}
                                </div>
                                <div className="col-sm-7-md-5">
                                    <GraficoTema 
                                    dados={this.state.dadosApi}
                                    />
                                </div>
                                <div className="col-sm-4-md-6 mt-5">
                                {this.state.dadosApi !== null ?
                                        this.state.dadosApi.map((dado,index)=>
                                        index === 0 ?                                   

                                        <div className="row" key={index}>
                                        <div className="col-1">
                                            <div className="indice-exito mt-1"></div>
                                        </div>
                                        <div className="col-10 indice-descricao">
                                            <div className=" d-inline">
                                            {dado.tema}
                                            </div>
                                        </div>
                                    </div>
                                        :null
                                        )
                                    :null
                                    }      
                                    {this.state.dadosApi !== null ?
                                        this.state.dadosApi.map((dado,index)=>
                                        index === 1 ?                
                                        <div className="row" key={index}>                                                                    
                                        <div className="col-1">
                                            <div className="indice-sem-exito mt-1"></div>
                                        </div>
                                        <div className="col-10 indice-descricao">
                                            <div  className=" d-inline">
                                            {dado.tema}
                                            </div>
                                        </div>
                                    </div>
                                    :null
                                        )
                                    :null
                                    }     
                                  {this.state.dadosApi !== null ?
                                        this.state.dadosApi.map((dado,index)=>
                                        index === 2 ?                
                                        <div className="row" key={index}>                                                                      
                                        <div className="col-1">
                                            <div className="indice-exito-parcial mt-1"></div>
                                        </div>
                                        <div className="col-10 indice-descricao">
                                            <div className=" d-inline">
                                            {dado.tema}                                              
                                            </div>
                                        </div>
                                    </div>
                                      :null
                                      )
                                  :null
                                  } 
                                  {this.state.dadosApi !== null ?
                                        this.state.dadosApi.map((dado,index)=>
                                        index === 3 ?                
                                        <div className="row" key={index}>  
                                            <div className="col-1">
                                            <div className="indice-pendente mt-1"></div>
                                        </div>
                                        <div className="col-10 indice-descricao">
                                            <div className=" d-inline">
                                            {dado.tema}                                              
                                            </div>
                                        </div>
                                    </div>
                                        :null
                                        )
                                    :null
                                    }
                                    {this.state.dadosApi !== null ?
                                        this.state.dadosApi.map((dado,index)=>
                                        index === 4 ?                
                                        <div className="row" key={index}>  
                                         <div className="col-1">
                                            <div className="indice-contrario-emenda mt-1"></div>
                                        </div>
                                        <div className="col-10 indice-descricao">
                                            <div className=" d-inline">
                                            {dado.tema}                                              
                                            </div>
                                        </div>
                                         </div>
                                        :null
                                        )
                                    :null
                                    }
                                </div>
                            </div>
                        </div>
                        <table className={
                            this.state.dadosApi !== undefined && this.state.dadosApi !== null ? this.state.dadosApi.length > 0 ?
                             `table table-striped table-responsive-sm`:`d-none`: `d-none`}>
                            <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                <tr>
                                    <th>Temas</th>
                                    <th>Proposições Novas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                this.state.dadosApi !== undefined && Array.isArray(this.state.dadosApi) && this.state.dadosApi.length > 0 ?
                                this.state.dadosApi.map((dados, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{dados.tema !== null ? dados.tema : ''}</td>
                                            <td>{dados.qtd !== null ? dados.qtd : ''}</td>
                                        </tr>
                                    )
                                }) : null}
                            </tbody>
                        </table>  
                        <div className={this.state.dadosApi === undefined ?
                             `p-3 d-flex justify-content-center mb-2 d-flex col-12 rounded`: `d-none`}
                             style={{backgroundColor: "rgba(86,61,124,.15)",
                                border: "1px solid rgba(86,61,124,.15)", fontSize: "18px"}}>
                                        O sistema encontra-se atualmente fora do ar. 
                        </div>                                              
                        <div className={this.state.dadosApi !== undefined ? this.state.dadosApi.length > 0 ?
                             `col-12 card-grafico-parlamentar` : `d-none`: `d-none`}>
                             <div className="row justify-content-left">                         
                                <div className="col-sm-1-md-1 item-descricao2">
                                    Comissões
                                </div>
                             </div>
                             <div className="row justify-content-left">
                                <div className="col-sm-8 col-md-6 mt-3 optionTema">
                                <FormatInput
                                className="Client-select"
                                label="Comissão"
                                >
                                <ClientCheckboxWithSelect
                                value={optionComissaoValue}
                                onChange={comissao => this.onChangeComissao(comissao)}
                                options={
                                    this.state.optionComissao !== undefined ? 
                                    this.state.optionComissao.map((item,index) => ({ value: index, label: item.ambito })):null}
                                placeholder={
                                "Escolha uma comissão"}
                                />

                                </FormatInput>                       
                                </div>
                             </div>
                             <div className={this.state.mostraGraficoComissao && this.state.dadosComissao.length > 0 ?
                                        `row justify-content-center`:`d-none`}
                                    >  
                                <div className="col-sm-7-md-5">
                                        <GraficoTemaComissoes 
                                        dados={this.state.dadosComissao}
                                        />
                                </div>
                                <div className="col-sm-4-md-6 mt-5">
                                {this.state.dadosComissao !== null ?
                                        this.state.dadosComissao.map((dado,index)=>
                                        index === 0 ?                
                                        <div className="row" key={index}>                                     
                                        <div className="col-1">
                                            <div className="indice-exito mt-1"></div>
                                        </div>
                                        <div className="col-10 indice-descricao">
                                            <div className=" d-inline">
                                            {dado.tema}                                              
                                            </div>
                                        </div>
                                    </div>
                                          :null
                                          )
                                      :null
                                      }
                                    {this.state.dadosComissao !== null ?
                                        this.state.dadosComissao.map((dado,index)=>
                                        index === 1 ?                
                                        <div className="row" key={index}>                                      
                                        <div className="col-1">
                                            <div className="indice-sem-exito mt-1"></div>
                                        </div>
                                        <div className="col-10 indice-descricao">
                                            <div  className=" d-inline">
                                            {dado.tema}    
                                            </div>
                                        </div>
                                    </div>
                                    :null
                                          )
                                      :null
                                      }
                                      {this.state.dadosComissao !== null ?
                                        this.state.dadosComissao.map((dado,index)=>
                                        index === 2 ?                
                                        <div className="row" key={index}>  
                                        <div className="col-1">
                                            <div className="indice-exito-parcial mt-1"></div>
                                        </div>
                                        <div className="col-10 indice-descricao">
                                            <div className=" d-inline">
                                            {dado.tema}
                                            </div>
                                        </div>
                                    </div>
                                       :null
                                       )
                                   :null
                                   }
                                    {this.state.dadosComissao !== null ?
                                        this.state.dadosComissao.map((dado,index)=>
                                        index === 3 ?                
                                        <div className="row" key={index}>  
                                        <div className="col-1">
                                            <div className="indice-pendente mt-1"></div>
                                        </div>
                                        <div className="col-10 indice-descricao">
                                            <div className=" d-inline">
                                            {dado.tema}
                                            </div>
                                        </div>
                                    </div>
                                          :null
                                          )
                                      :null
                                      }
                                     {this.state.dadosComissao !== null ?
                                        this.state.dadosComissao.map((dado,index)=>
                                        index === 4 ?                
                                        <div className="row" key={index}>  
                                        <div className="col-1">
                                            <div className="indice-contrario-emenda mt-1"></div>
                                        </div>
                                        <div className="col-10 indice-descricao">
                                            <div className=" d-inline">
                                            {dado.tema}                                           
                                            </div>
                                        </div>
                                         </div>
                                          :null
                                          )
                                      :null
                                      }
                                </div>
                            </div>    
                        </div>
                        
                        <table className={this.state.mostraGraficoComissao && this.state.dadosComissao.length > 0 && 
                        this.state.dadosApi.length > 0 ?
                         `table table-striped table-responsive-sm`
                        : `d-none`}>
                            <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                <tr>
                                    <th>Temas</th>
                                    <th>Proposições Novas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                this.state.dadosComissao !== undefined && Array.isArray(this.state.dadosComissao) && this.state.dadosComissao.length > 0 ?
                                this.state.dadosComissao.map((dados, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{dados.tema !== null ? dados.tema : ''}</td>
                                            <td>{dados.qtd !== null ? dados.qtd : ''}</td>
                                        </tr>
                                    )
                                }) : null}
                            </tbody>
                        </table>  
                        <div className={this.state.mostraSemResultComissoes ?
                             `p-3 d-flex justify-content-center mb-2 d-flex col-12 rounded`: `d-none`}
                             style={{backgroundColor: "rgba(86,61,124,.15)",
                                border: "1px solid rgba(86,61,124,.15)", fontSize: "18px"}}>
                                        A pesquisa não retornou resultados.
                        </div>                                                     
                        </AccordionTemas>
                    </div>
                    <div className="acordionsBordas mt-2">
                        <AccordionTemas
                            mb={'mb-0'}
                            p={'p-0'}
                            titulo="Tempo médio de tramitação de proposições dos temas"
                        >
                         <hr/> 
                         <div className={this.state.dadosTemas !==undefined ? `row p-4`:`d-none`}>  
                            <div className="col-12 col-sm-3  col-lg-3 col-xl-3 mb-2 ">
                                <div className="label-insight">Data Inicial de Apresentação</div> 
                                <input id="data-inicial" className="data-inicial-tempo"  type="date" onChange={dtIni => this.dataInicialTram(dtIni)} />    
                            </div>
                            <div className="col-12 col-sm-3  col-lg-3 col-xl-3"> 
                                <div className="label-insight">Data Final de Apresentação</div>  
                                <input id="data-final" className="data-final-tempo"  type="date" onChange={dtFim => this.dataFinalTram(dtFim)}   />
                            </div>

                            <div className={this.state.casaOptionTemMedio !== null && 
                                this.state.temaOption !== null && this.state.ambito !== null && 
                                this.state.dataIniTram !== null && this.state.dataFimTram !== null ? 
                                `${classButtonPesquisar}` : 'd-none'} >
                        
                                <img id="pesquisa-dash" className=""  src={require('../../../design/assets/images/btn_pesquisar.svg')} alt="btn-pesquisar" onClick={() => this.enviaDataTempoMedio()}  style={{cursor: 'pointer'}}/>
                                <img  id="limpar-dash" className="ml-3"  src={require('../../../design/assets/images/btn_limpar.svg')} alt="btn-limpar" onClick={() => this.limparDatasTempo()} style={{cursor: 'pointer'}}/>
                            </div>               

                        </div>  
                        <div className={this.state.dadosTemas !==undefined ? `row justify-content-center`: `d-none`}>
                            <div className="col-sm-4 mt-3">
                            <FormatInput
                                className="Client-select"
                                label="Casa Legislativa"
                                >
                                <ClientCheckboxWithSelect
                                value={casaOptionTemMedio}
                                onChange={casa_legislativa => this.onChangeCasaLegislativa(casa_legislativa)}
                                options={this.state.casasLegislativas}
                                    placeholder={
                                        "Escolha uma casa"}
                                />
                                </FormatInput>                         
                            </div>        

                            <div className="col-sm-4 mt-3">

                                {this.state.loadingCircle ? 
                            
                                    <div className="d-flex justify-content-center mt-4" >
                                        <LoadingCircle color={'#DC2D41'} size={'30px'} />
                                    </div>
                                :
                                    <FormatInput
                                        css={this.state.casaState !== null ? "" : "label-disabled"}
                                        className="Client-select"
                                        label="Âmbito"
                                        >
                                        <ClientCheckboxWithSelect
                                        value={ambito}
                                        onChange={ambito => this.onChangeAmbito(ambito)}
                                        options={
                                        this.state.dadosAmbito !== null && this.state.dadosAmbito !== undefined? 
                                        this.state.dadosAmbito.map((item,index) => ({ value: index, label: item.ambito })):null}
                                        placeholder={
                                        "Escolha um âmbito"}
                                        disabled={this.state.casaState !== null ? false : true}
                                        />
                                    </FormatInput>         
                                }                
                            </div>

                            <div className="col-sm-4 mt-3">
                                {this.state.loadingCircle ? 
                                    
                                    <div className="d-flex justify-content-center mt-4" >
                                        <LoadingCircle color={'#DC2D41'} size={'30px'} />
                                    </div>
                                :
                                    <FormatInput
                                        css={this.state.casaState !== null ? "" : "label-disabled"}
                                        className="Client-select "
                                        label="Tema"
                                        >
                                        <ClientCheckboxWithSelect
                                        value={temaOption}
                                        onChange={tema => this.onChangeTema(tema)}
                                        options={
                                            this.state.dadosTemas !== null && this.state.dadosTemas !== undefined? 
                                            this.state.dadosTemas.map((item) => ({ value: item.codigo, label: item.descricao })):null
                                            }
                                        placeholder={"Escolha um tema"}
                                        disabled={this.state.casaState !== null ? false : true}
                                        />
                                    </FormatInput>     
                                }                 
                            </div>


                            <div className={this.state.tempoMedTram !== null && this.state.tempoMedTram.total !== null ?   
                                `justify-content-center d-flex col-12 rounded mb-3 mt-3 p-2`:'d-none' }
                                style={{backgroundColor: "rgba(86,61,124,.15)",
                                border: "1px solid rgba(86,61,124,.15)", fontSize: "14px"}}>
                                Tempo médio de tramitação das proposições 
                                {
                                this.state.tempoMedTram !== null && this.state.tempoMedTram.total !== null ?
                                ' '+ this.state.tempoMedTram.total: null}
                            </div>
                            <div className={this.state.tempoMedTram !== null && this.state.tempoMedTram.total === null &&
                                this.state.mostrarMensagemSemResuTempoMedio ?   
                                `justify-content-center d-flex col-12 rounded mb-3 mt-3 p-2`:'d-none' }
                                style={{backgroundColor: "rgba(86,61,124,.15)",
                                border: "1px solid rgba(86,61,124,.15)", fontSize: "18px"}}>
                                A pesquisa não retornou resultados.
                            </div>                            

                        </div>
                        <div className={this.state.dadosTemas === undefined ?
                           `p-3 d-flex justify-content-center mb-2 d-flex col-12 rounded`: `d-none`}
                           style={{backgroundColor: "rgba(86,61,124,.15)",
                              border: "1px solid rgba(86,61,124,.15)", fontSize: "18px"}}>
                                        O sistema encontra-se atualmente fora do ar. 
                        </div>                          
                        </AccordionTemas>
                    </div>
                    {/* <div className="acordionsBordas mt-2">
                        <AccordionTemas
                            mb={'mb-0'}
                            p={'p-0'}
                            titulo="Posicionamento das comissões em relação a tema"
                        >
                         <hr/> 
                         <div className="row p-4">  
                            <div className="col-12 col-sm-3  col-lg-3 col-xl-3 mb-2 ">
                                <div className="label-insight">Data Inicial</div> 
                                <input id="data-inicial" className=""  type="date" onChange={dtIni => this.dataInicialPosicao(dtIni)} />    
                            </div>
                            <div className="col-12 col-sm-3  col-lg-3 col-xl-3"> 
                                <div className="label-insight">Data Final</div>  
                                <input id="data-final" className=""  type="date" onChange={dtFim => this.dataFinalPosicao(dtFim)}   />
                            </div>

                            <div className={`${classButtonPesquisar}`} >
                        
                                <img id="pesquisa-dash" className=""  src={require('../../../design/assets/images/btn_pesquisar.svg')} alt="btn-pesquisar" onClick={() => this.enviaDataPosicao()}  style={{cursor: 'pointer'}}/>
                                <img  id="limpar-dash" className="ml-3"  src={require('../../../design/assets/images/btn_limpar.svg')} alt="btn-limpar" onClick={() => this.limparDatas()} style={{cursor: 'pointer'}}/>
                            </div>               

                        </div>  
                         <div className="row justify-content-left">
                                <div className="col-sm-4 mt-3 optionTema">
                                <FormatInput
                                className="Client-select"
                                label="Tema"
                                >
                                <ClientCheckboxWithSelect
                                onChange={tema => this.onChangeTemaPosicao(tema)}
                                options={
                                this.state.dadosTemas !== undefined && Array.isArray(this.state.dadosTemas) 
                                && this.state.dadosTemas.length > 0 ?
                                this.state.dadosTemas.map((item) => ({ value: item.codigo, label: item.descricao })):null}
                                placeholder={
                                "Escolha um tema"}
                                />

                                </FormatInput>                       
                                </div>
                             </div>
                             <table className={`table table-striped table-responsive-sm 
                             ${this.state.dadosTemaPosicao !== null ? '' : 'd-none'}`}>
                             <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                <tr>
                                    <th>Comissçao</th>
                                    <th>Posição</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                this.state.dadosTemaPosicao !== undefined && Array.isArray(this.state.dadosTemaPosicao) && this.state.dadosTemaPosicao.length > 0 ?
                                this.state.dadosTemaPosicao.map((dados, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{dados.comissao !== null ? dados.comissao : ''}</td>
                                            <td>Indefinido</td>
                                        </tr>
                                    )
                                }) : null}
                            </tbody>
                        </table>   
                        </AccordionTemas>
                    </div> */}
            </div>
        )
    }
}

export default InsightTema