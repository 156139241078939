import { observable, action } from "mobx"
import { find } from 'lodash'

import menu from '../helpers/menu'

class MenuStore {
    @observable open = false
    @observable menu = menu
    @observable select = {}

    @action
    toggleMenu(id) {
        if (this.select.id === id && this.open) {
            this.open = false
            return
        }

        this.select = find(menu, { 'id': id }) || {}

        if (this.select.list) {
            this.open = true
            return
        }

        this.open = false
    }

    @action
    closeMenu() { if (this.open === true) this.open = false; }
}

export default new MenuStore()