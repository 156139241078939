import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                labels: ['Finalizada', 'Pendente'],
                colors: ['#4CD964','#F63854'],
                legend: {
                    show: false,
                }
            }
        }
    }


    render() {
        const larguraTela = window.innerWidth
        const larguraGrafico = larguraTela > 375 ? '330' : '260'
        const series1 = [
            this.props.finalizada ? parseInt(this.props.finalizada) : 0,
            this.props.pendente ? parseInt(this.props.pendente) : 0,
        ]
        const series2 = [0,0]
        const series = this.props.finalizada && this.props.pendente ? series1 : series2
        // console.log('series aqui ===>', series)
        // console.log('options aqui ===>', this.state.options)

        return (
            <div className="donut">
                <Chart 
                    options={this.state.options} 
                    series={series} 
                    type="donut" 
                    width={larguraGrafico}
                />
            </div>
        );
    }
}

export default Donut;