import { observable, action } from "mobx"
import _ from "lodash"
import { buscarPorId } from "../helpers/array"

const TEMPO_PARA_DESTRUIR = 5000;
export const ALERTA_SUCESSO = 'sucesso';
export const ALERTA_ERRO = 'erro';
export const ALERTA_AVISO = 'aviso';

const tipos = [ALERTA_SUCESSO, ALERTA_ERRO, ALERTA_AVISO];

class AlertaStore {
    @observable alertas = [];

    constructor() {
        this.resetar = this.resetar.bind(this);
        this.criar = this.criar.bind(this);
        this.destruir = this.destruir.bind(this);
        this.aviso = this.aviso.bind(this);
    }

    @action resetar() {
        this.alertas.forEach(x => clearTimeout(x.timeout));
        this.alertas = [];
    }

    @action criar(mensagem, tipo = ALERTA_SUCESSO) {
        if (!tipos.find(x => x === tipo)) return;
        const id = _.uniqueId('alerta-');
        this.alertas.push({
            id,
            tipo,
            mensagem,
            timeout: setTimeout(() => this.destruir(id), TEMPO_PARA_DESTRUIR)
        });
    }

    @action aviso(mensagem, tipo = ALERTA_AVISO) {
        if (!tipos.find(x => x === tipo)) return;
        const id = _.uniqueId('alerta-');
        this.alertas.push({
            id,
            tipo: ALERTA_AVISO,
            mensagem,
            timeout: setTimeout(() => this.destruir(id), TEMPO_PARA_DESTRUIR)
        });
    }

    @action erro(mensagem) {
        const id = _.uniqueId('alerta-');
        this.alertas.push({
            id,
            tipo: ALERTA_ERRO,
            mensagem,
            timeout: setTimeout(() => this.destruir(id), TEMPO_PARA_DESTRUIR)
        });
    }

    @action destruir(id) {
        const index = this.alertas.findIndex(buscarPorId(id));
        if (index === -1) return;
        clearTimeout(this.alertas[index].timeout);
        this.alertas.splice(index, 1);
    }
}

const store = new AlertaStore();
export default store;