import React, { Component } from 'react';
// import Accordion from '../../componentes/accordion'
// import Loading from '../../componentes/loading'
// import { post } from '../../services/http'
// import Pagination from "react-js-pagination";
// import BreadCrumb from '../../estruturas/breadcrumb'
// import ModalIA from '../proposicao/modalAI'
// import posicionamento from "../../enums/posicionamento"
// import prioridades from "../../enums/prioridades"
import { inject, observer } from 'mobx-react'
import Treinamento from './treinamentoIA'
import TemaIA from './temaIA'

@inject('authStore')
@observer


class IA extends Component {
    state = {
        tipoPagina: 'treinamento',
    }

    async componentDidMount() {
        const tipoPagina = window.localStorage.getItem('tipoPagina')
        if(tipoPagina === null || tipoPagina === 'treinamento') {
            await this.setState({ tipoPagina: 'treinamento' })
        } else if(tipoPagina === 'tema') {
            await this.setState({ tipoPagina: 'tema' })
        }
    }


    selecionarTipoPagina(tipo) {
        window.localStorage.setItem('tipoPagina', tipo)
        this.setState({ tipoPagina: tipo });
    }

    render() {
        return (
            <div>
                {/* {this.state.loading ? <Loading /> : */}
                <div>

                    {/* <BreadCrumb /> */}

                    <div className=" bg-white pl-2 marginTopXs" style={{boxShadow: "-3px 0px 10px #C0C0C0"}}>
                        <p style={{fontSize:"10px", color: "var(--linkText)", fontWeight:"bold", padding:"15px" }}>
                        INTELIGÊNCIA ARTIFICIAL
                        </p>
                    </div>

                    <div className="container-fluid">

                        <div className="pt-5 mb-4 pl-3">
                            <h4 className='tituloPagina'>Inteligência Artificial</h4>
                        </div>

                        <div className='row pr-2 pl-2 pt-4'>

                            <div className={`col-6 ${this.state.tipoPagina === 'treinamento' ? 'border-active-ia' : 'border-inactive-ia'}`} onClick={() => { this.selecionarTipoPagina('treinamento') }}>
                                <p className={`${this.state.tipoPagina === 'treinamento' ? 'texto-aba-active-ia' : 'texto-aba-inactive-ia'}`}>Treinamento</p>
                            </div>

                            <div className={`col-6 ${this.state.tipoPagina === 'tema' ? 'border-active-ia' : 'border-inactive-ia'}`} onClick={() => { this.selecionarTipoPagina('tema') }}>
                                <p className={`${this.state.tipoPagina === 'tema' ? 'texto-aba-active-ia' : 'texto-aba-inactive-ia'}`}>Temas Relacionados</p>
                            </div>

                        </div>

                        {this.state.tipoPagina === 'treinamento' ?
                            <Treinamento /> : null
                        }

                        {this.state.tipoPagina === 'tema' ?
                            <TemaIA /> : null
                        }

                    </div>

                </div>

                {/* } */}
            </div>
        )
    }
}

export default IA