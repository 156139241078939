import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                labels: ['Aguardando Designação do Relator', 'Aguardando Parecer do Relator', 'Prazo para Emendas', 'Aguardando Deliberação'],
                colors: ['#4CD964','#F63854', '#5856D7', '#FFD200' ],
                legend: {
                    show: false,
                }
            },
            series: [
                0, 1, 2, 3, 4
            ],
        }
    }


    render() {
        const larguraTela = window.innerWidth
        const larguraGrafico = larguraTela > 375 ? '330' : '260'
        const series1 = [
            this.props.propMomentosCriticosAguardandoDesignacaoRelator !== undefined ? parseInt(this.props.propMomentosCriticosAguardandoDesignacaoRelator) : 0,
            this.props.propMomentosCriticosAguardanoParecerDoRelator !== undefined ? parseInt(this.props.propMomentosCriticosAguardanoParecerDoRelator) : 0,
            this.props.propMomentosCriticosPrazoParaEmenda !== undefined ? parseInt(this.props.propMomentosCriticosPrazoParaEmenda) : 0,
            this.props.propMomentosCriticosAguardaDeliberacao !== undefined ? parseInt(this.props.propMomentosCriticosAguardaDeliberacao) : 0,
        ]
        const series2 = [0,0,0,0]
        const series = this.props.propMomentosCriticosAguardandoDesignacaoRelator !== undefined && this.props.propMomentosCriticosAguardanoParecerDoRelator !== undefined && this.props.propMomentosCriticosPrazoParaEmenda !== undefined && this.props.propMomentosCriticosAguardaDeliberacao !== undefined  ? series1 : series2
        // console.log('TESTE AQUI DONUT ===>',this.props.dados)

        return (
            <div className="donut">
                <Chart 
                    options={this.state.options} 
                    series={series} 
                    type="donut" 
                    width={larguraGrafico}
                />
            </div>
        );
    }
}

export default Donut;