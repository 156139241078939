import {observable, action} from "mobx"

class SeguirProposicaoStore {

    @observable valores = {}

    @action alterarSeguindo = (url, valor) =>
        this.valores = {...this.valores, [url]: valor}
}

export default new SeguirProposicaoStore()