import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

export default function FormDialog(props) {

    const [open, setOpen] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    }


    return (
        <div>
            
            <div onClick={handleClickOpen} style={{cursor:'pointer'}} className="header d-flex align-items-start">
                <div className="title">
                    <span className='Client-titulo-card'>{props.item.tipo} {props.item.numero} {props.item.comissao_sigla}</span>
                    <span className="Client-cinza-claro fs-16 font-weight-bold">{moment(props.item.data_apresentacao).format('DD/MM/YYYY')}</span>
                </div>
            </div>

            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title" >


                <DialogContent
                    style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: "white",
                        color: '#3E4049'
                    }}
                >
                    <div className="p-3">
                        <div>
                            <h4>{props.item.tipo} {props.item.numero} {props.item.comissao_sigla}</h4>
                            <p>{moment(props.item.data_apresentacao).format('DD/MM/YYYY')}</p>
                        </div>

                        <div>
                            <hr />
                        </div>

                        <div>
                            {props.item.texto}
                        </div>

                        <div className="text-center p-3 botaoFecharModalEmendas" onClick={handleClose}>
                            FECHAR
                        </div>
                    </div>



                </DialogContent>

            </Dialog>
        </div >
    );
}