import React, { Component } from 'react';
import Loading from '../../componentes/loading';
import { get } from '../../services/http';
import AccordionMobile from "../../componentes/accordionMobileDashPropo"
// import alertaStore from "../../stores/alertaStore";
import moment from 'moment-timezone';
import { amanha } from '../../helpers/date'
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

// modal
import ModalGraficoCasa from './modal/modalGraficoCasas'
// import FiltroProposicao from './modal/filtroProposicao';
import FiltroProposicao from './modal/filtroAvancadoProposicao';
//import FiltroProposicaoMobile from './modal/filtroProposicaoMobile';

// graficos
import GraficoInfoMomentosCriticosProposicao from './graficos/graficosInfoMomentosCriticosProposicao'
import GraficoInfoAcoesInfluencia from './graficos/graficosInfoAcoesAndamentoProposicao';

import GraficoAcaoPreparatoria1 from './graficos/graficoAcaoPreparatoria1'
import GraficoAcaoPreparatoria2 from './graficos/graficoAcaoPreparatoria2'

moment.tz.setDefault('UTC');

@inject('authStore')
@observer

class ProposicaoDefault extends Component {
    state = {
        loadingGrafico: true,
        loading: true,
        filtros: {
            todas: false,
            novas: true,
            recentes: false,
            tramitacao: false,
            prioridade: null,
            seguidas: false,
            recomendadas: false,
            temas: [],
            tipos: [],
            autores: [],
            dias: 7,
            orderBy: 'Data'
        },
        limite: 50,
        paginaAtual: 1,
        filtroInput: false,
        quantidade: 100,
        descricao: '',
        tipos: [],
        numeros: [],
        temas: [],
        ano: [],
        // apelido: [],
        partido: [],
        estado: [],
        ambito1: [],
        ambito2: [],
        ambito3: [],
        mostrarBotaoFiltro: false,
        //   grafico: this.props.authStore.ehEsforcoColaborativo === true ? false : true,
        dataInicial: '',
        dataFinal: '',
        propNumeroProposicoesCamaraProposicao: '',
        propNumeroProposicoesSenadoProposicao: '',
        propNumeroProposicoesCongressoProposicao: '',
        propMomentosCriticosAguardandoDesignacaoRelatorProposicao: '',
        propMomentosCriticosAguardanoParecerDoRelatorProposicao: '',
        propMomentosCriticosPrazoParaEmendaProposicao: '',
        propMomentosCriticosAguardaDeliberacaoProposicao: '',
        propNumeroProposicoesSeguidasAtivas: '',
        propNumeroProposicoesSeguidasInativas: '',
        CDAtivo: '',
        CDInativo: '',
        SFAtivo: '',
        SFInativo: '',
        CNAtivo: '',
        CNInativo: '',
        propResultadoClientPendente: '',
        propResultadoClientExito: '',
        propResultadoClientSemExito: '',
        propResultadoClientExitoParcial: '',
        message: '',
        relatores: [],
        temasProposicao: [],

        prazo: 0,
        atraso: 0,
        finalizada: 0,
        pendente: 0,
        aberto: 0,
        messageNoData: false
    }


    async componentWillMount() {
        const noInfo = window.localStorage.getItem('no-info')
        const filtroInput = window.localStorage.getItem('filtroInput')
        const filtroGrafico = window.localStorage.getItem('filtroGrafico')
        const filtroProposicao = window.localStorage.getItem('filtroProposicao')
        const filtroPendencia = window.localStorage.getItem('filtroPendencia')

        if (filtroInput !== null || filtroGrafico !== null || filtroProposicao !== null || filtroPendencia !== null) {
            window.location.href = '/proposicao/lista'
        }

        // if(this.props.authStore.ehEsforcoColaborativo === true) {
        //     window.location.href = "/"
        // }
        await this.getDataGraficos()
        if (noInfo) {
            await this.setState({ messageNoData: true })
            window.localStorage.removeItem('no-info')
        }
    }



    aplicarFiltroInput(event) {
        this.setState({ descricao: event.target.value })
    }

    listarProposicao() {
        window.localStorage.removeItem('filtroProposicao')
        window.localStorage.removeItem('filtroGrafico')
        window.localStorage.removeItem('filtroPendencia')
        window.localStorage.setItem('filtroInput', this.state.descricao)
        window.location.href = '/proposicao/lista'
    }

    dataInicial(event) {
        this.setState({ dataInicial: event.target.value })
    }

    dataFinal(event) {
        this.setState({ dataFinal: event.target.value })
    }

    async selectFiltroProposicao(e, text, path, resultado, dtIni, dtFin) {
        await window.localStorage.removeItem('filtroProposicao')
        await window.localStorage.removeItem('filtroInput')
        await window.localStorage.removeItem('filtroPendencia')
        const data = {
            dtIni: this.state.dataInicial ? moment(this.state.dataInicial).format('YYYY-MM-DD') : moment().subtract(5,'years').format('YYYY-MM-DD'),
            dtFim: this.state.dataFinal ? moment(this.state.dataFinal).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            path: path,
            text: text,
            resultado: resultado
            // //,
            // dtIni: dtIni,
            // dtFin: dtFin
        }
        console.log('selectFiltroProposicao',data);
        await window.localStorage.setItem('filtroGrafico', JSON.stringify(data))
        window.location.href = '/proposicao/lista'
    }

    async getDataGraficos() {
        if (this.state.loading === false) {
            await this.setState({ loading: true })
        }

        const dtIni = this.state.dataInicial?moment(this.state.dataInicial).format('YYYY-MM-DD 00:00:00'):moment().subtract(5,'years').format('YYYY-MM-DD 00:00:00');
        const dtFim = this.state.dataFinal ? moment(this.state.dataFinal).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD') //dataAtualPesquisa
        
        await get(`/proposicao/dados-dashboard-proposicao/${dtIni}/${dtFim}`)
            .then(response => {
                const dados = response || []
                //console.log('DADOS>>>>>>>', dados)
                this.setState({
                    propNumeroProposicoesCamaraProposicao: dados.numeroProposicoesCamaraProposicao,
                    propNumeroProposicoesSenadoProposicao: dados.numeroProposicoesSenadoProposicao,
                    propNumeroProposicoesCongressoProposicao: dados.numeroProposicoesCongressoProposicao,
                    propMomentosCriticosAguardandoDesignacaoRelatorProposicao: dados.momentosCriticosAguardandoDesignacaoRelatorProposicao,
                    propMomentosCriticosAguardanoParecerDoRelatorProposicao: dados.momentosCriticosAguardanoParecerDoRelatorProposicao,
                    propMomentosCriticosPrazoParaEmendaProposicao: dados.momentosCriticosPrazoParaEmendaProposicao,
                    propMomentosCriticosAguardaDeliberacaoProposicao: dados.momentosCriticosAguardaDeliberacaoProposicao,
                    propNumeroProposicoesSeguidasAtivas: dados.numeroProposicoesSeguidasAtivas,
                    propNumeroProposicoesSeguidasInativas: dados.numeroProposicoesSeguidasInativas,
                    propResultadoClientPendente: dados.resultadoClientPendente,
                    propResultadoClientExito: dados.resultadoClientExito,
                    propResultadoClientSemExito: dados.resultadoClientSemExito,
                    propResultadoClientExitoParcial: dados.resultadoClientExitoParcial,
                    loading: false,
                    CDAtivo: dados.CDAtivo,
                    CDInativo: dados.CDInativo,
                    SFAtivo: dados.SFAtivo,
                    SFInativo: dados.SFInativo,
                    CNAtivo: dados.CNAtivo,
                    CNInativo: dados.CNInativo,
                    prazo: dados.prazo,
                    atraso: dados.atraso,
                    finalizada: dados.finalizada,
                    pendente: dados.pendente,
                    aberto: dados.aberto,
                })
            })
            .catch(err => err)
    }

    async getDataLimparGraficos() {
        if (this.state.loading === false) {
            await this.setState({ loading: true })
        }

        const dataAtual = new Date()
        const data = amanha(1, dataAtual)

        const dataAtualPesquisa = moment(data).format('YYYY-MM-DD 23:59:50')

        const dtIni = this.state.dataInicial ? moment(this.state.dataInicial).format('YYYY-MM-DD 00:00:00') : moment().subtract(5,'years').format('YYYY-MM-DD 00:00:00')
        const dtFim = this.state.dataFinal ? amanha(1, this.state.dataFinal) : dataAtualPesquisa
        const dataFim = moment(dtFim).format('YYYY-MM-DD 23:59:50')
        await get(`/proposicao/dados-dashboard-proposicao/${dtIni}/${dataFim}`)
            .then(response => {
                const dados = response || []
                this.setState({
                    propNumeroProposicoesCamaraProposicao: dados.numeroProposicoesCamaraProposicao,
                    propNumeroProposicoesSenadoProposicao: dados.numeroProposicoesSenadoProposicao,
                    propNumeroProposicoesCongressoProposicao: dados.numeroProposicoesCongressoProposicao,
                    propMomentosCriticosAguardandoDesignacaoRelatorProposicao: dados.momentosCriticosAguardandoDesignacaoRelatorProposicao,
                    propMomentosCriticosAguardanoParecerDoRelatorProposicao: dados.momentosCriticosAguardanoParecerDoRelatorProposicao,
                    propMomentosCriticosPrazoParaEmendaProposicao: dados.momentosCriticosPrazoParaEmendaProposicao,
                    propMomentosCriticosAguardaDeliberacaoProposicao: dados.momentosCriticosAguardaDeliberacaoProposicao,
                    propNumeroProposicoesSeguidasAtivas: dados.numeroProposicoesSeguidasAtivas,
                    propNumeroProposicoesSeguidasInativas: dados.numeroProposicoesSeguidasInativas,
                    propResultadoClientPendente: dados.resultadoClientPendente,
                    propResultadoClientExito: dados.resultadoClientExito,
                    propResultadoClientSemExito: dados.resultadoClientSemExito,
                    propResultadoClientExitoParcial: dados.resultadoClientExitoParcial,
                    loading: false,
                    CDAtivo: dados.CDAtivo,
                    CDInativo: dados.CDInativo,
                    SFAtivo: dados.SFAtivo,
                    SFInativo: dados.SFInativo,
                    CNAtivo: dados.CNAtivo,
                    CNInativo: dados.CNInativo,
                    prazo: dados.prazo,
                    atraso: dados.atraso,
                    finalizada: dados.finalizada,
                    pendente: dados.pendente,
                    aberto: dados.aberto,
                })
            })
            .catch(err => err)
    }

    async limparDatas() {
        window.localStorage.removeItem('filtroProposicao')
        window.localStorage.removeItem('filtroInput')
        window.localStorage.removeItem('filtroGrafico')
        

    }


    render() {

        return (
            this.state.loading ? <Loading /> :
                <div>

                    {/* >>>>>>>>>>>>> MOBILE CODE <<<<<<<<<<<<<<*/}

                    <div className="hiddenDeskModel">

                        <div className="col-12 bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                            <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                                PROPOSIÇÕES
                            </p>
                        </div>

                        <div style={{paddingLeft:"20px", fontSize:"23px", fontWeight:"600", marginTop:"20px", marginBottom:"10px"}}>
                            Proposições
                        </div>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 col-xl-6 col-lg-12 col-12 espacamentoBottom">
                                    <div className="row">
                                        <div className="col-md-4 col-10" style={{ marginTop: "14px" }}>
                                            <div className="input-group  icon-input">
                                                <input className="form-control  border-right-0 border" id="pesquisar-proposicao" type="search" autoComplete="off"
                                                    value={this.state.descricao}
                                                    onKeyUp={(event) => { if (event.which === 13) { this.listarProposicao() } }}
                                                    onChange={(event) => this.aplicarFiltroInput(event)} placeholder="Buscar proposição" aria-label="Pesquisar" aria-describedby="search" />
                                                <span id="icone-pesquisar-proposicao" className="input-group-append">
                                                    <button style={{ paddingTop: "8.5px" }} className="btn btn-outline-secondary border-left-0 border" type="button"
                                                        onClick={() => { this.listarProposicao(); this.setState({ filtroInput: true }) }}>
                                                        <i className="icon-search-small"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-2">
                                            <FiltroProposicao
                                                numeros={this.state.numeros}
                                                temas={this.state.temas}
                                                ano={this.state.ano}
                                                apelido={this.state.apelido}
                                                partido={this.state.partido}
                                                estado={this.state.estado}
                                                ambito1={this.state.ambito1}
                                                ambito2={this.state.ambito2}
                                                ambito3={this.state.ambito3}
                                                busca={this.state.descricao}
                                            />
                                        </div>
                                        <div className="col-md-5 col-12 marginBotaoCVadastrarProp">
                                            <Link to="/proposicao/cadastro-proposicao" className="btn " style={{ marginTop: "20px", padding: "15px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter", width: "100%" }} variant="outlined">

                                                Cadastrar Proposição
                                            </Link>
                                        </div>


                                    </div>

                                    <hr />

                                    <div style={{ border: "1px solid var(--BorderSelectExpandButtom)", borderRadius: "5px", backgroundColor: '#fff', marginTop: "17px" }} className="p-2">
                                        <AccordionMobile
                                            mb={'mb-0'}
                                            p={'p-0'}
                                            titulo="Filtrar por período"
                                        >
                                            <div style={{ marginTop: "20px" }}>
                                                <hr />
                                            </div>


                                            <div className="col-12">
                                                <div className="row bg-white ">
                                                    <div className="col-12">
                                                        <div className="row espacamentoData">
                                                            <div className="col-6 espacamentoDataInt">
                                                                <div className="data-dashboard-label-proposicao">
                                                                    Data Inicial
                                                                </div>
                                                                <input id="data-inicial" className="" type="date" onChange={event => this.dataInicial(event)} />
                                                            </div>

                                                            <div className="col-6">
                                                                <div className="data-dashboard-label-proposicao">
                                                                    Data Final
                                                                </div>
                                                                <input id="data-final" className="" type="date" onChange={event => this.dataFinal(event)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-12 centerPesquisar">
                                                        <button id="pesquisa-dash" className="btn" style={{ padding: "15px", width: "100%", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)" }} variant="outlined" onClick={() => this.getData()}>
                                                            <img
                                                                className="mr-2"
                                                                src={require('../../design/assets/images/lupa.svg')}
                                                                alt="btn-pesquisar"
                                                            />{' '}
                                                            Pesquisar
                                                        </button>
                                                    </div>
                                                    <div className="col-12">
                                                        <button id="limpar-dash" className="btn" style={{ padding: "15px", width: "100%", backgroundColor: "var(--botaoLimpar)", color: "var(--corTextoBotaoLimpar)", border: "1px solid var(--corTextoBotaoLimpar)" }} variant="outlined" onClick={() => this.limparDatas()}>
                                                            Limpar
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </AccordionMobile>

                                    </div>
                                </div>

                            </div>


                            <section className=" mt-1 ml-2 mr-2 mb-3">
                                <div className="row">

                                    {!this.state.messageNoData ? null :
                                        <div className="col-12 text-center mt-3 mb-3">
                                            <h5 className="text-danger">Não foram encontradas proposições para sua busca!</h5>
                                        </div>
                                    }


                                    {/* grafico acao preparatoria  */}
                                    {this.props.authStore.ehEsforcoColaborativo === false ?
                                        <div className="col-12" style={{ background: 'white' }}>
                                            <div className="row">
                                                <div className="col-md-6 col-12 pt-2">
                                                    <span className="text-left " style={{ fontSize: "18px", marginTop: "10px", paddingLeft: "7px" }}>Ação Preparatória</span>
                                                </div>
                                                <div className="col-md-6 col-12 mt-2">
                                                    <span style={{ fontSize: "13px", paddingLeft: "7px" }}>{this.state.aberto}</span>
                                                    <span style={{ fontSize: "13px", paddingLeft: "4px", color: "var(--linkText)" }}> em aberto</span>
                                                </div>
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">

                                                    <div className="grafico-card-proposicao pt-3 mb-2">
                                                        <div className={`d-flex justify-content-center flex-wrap p-3`}>
                                                            <GraficoAcaoPreparatoria1
                                                                prazo={this.state.prazo}
                                                                atraso={this.state.atraso}
                                                            />

                                                            <div className="div-indice">
                                                                <div className="d-flex">
                                                                    <div className="indice-exito mt-1 mr-2"></div>
                                                                    <div onClick={event => { this.selectFiltroProposicao(event, 'no prazo', 'acao preparatoria') }} className="mr-2 link-graficos-legenda-proposicao" style={{fontSize:"13px"}}>No prazo</div>
                                                                    <div>
                                                                        {this.state.prazo}
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mt-2">
                                                                    <div className="indice-sem-exito mt-1 mr-2"></div>
                                                                    <div onClick={event => { this.selectFiltroProposicao(event, 'em atraso', 'acao preparatoria') }} className="mr-2 link-graficos-legenda-proposicao" style={{fontSize:"13px"}}>Em atraso</div>
                                                                    <div>
                                                                        {this.state.atraso}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-6">

                                                    <div className="grafico-card-proposicao pt-3 mb-2">
                                                        <div className={`d-flex justify-content-center flex-wrap p-3`}>
                                                            <GraficoAcaoPreparatoria2
                                                                finalizada={this.state.finalizada}
                                                                pendente={this.state.pendente}
                                                            />
                                                            <div className="div-indice">
                                                                <div className="d-flex">
                                                                    <div className="indice-exito mt-1 mr-2"></div> 
                                                                    <div onClick={event => { this.selectFiltroProposicao(event, 'finalizada', 'acao preparatoria') }} className="mr-2 link-graficos-legenda-proposicao" style={{fontSize:"13px"}}>Finalizado</div>
                                                                    <div>
                                                                        {this.state.finalizada}
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mt-2">
                                                                    <div className="indice-sem-exito mt-1 mr-2"></div>
                                                                    <div onClick={event => { this.selectFiltroProposicao(event, 'pendente', 'acao preparatoria') }} className="mr-2 link-graficos-legenda-proposicao" style={{fontSize:"13px"}}>Pendente</div>
                                                                    <div>
                                                                        {this.state.pendente}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    <div className="col-12  " style={{ height: '20%', backgroundColor: "white", marginTop: "10px" }} >
                                        <div className="col-12 mt-2">
                                            <div className="grafico-card-proposicao p-3">
                                                <ModalGraficoCasa
                                                    Camara={this.state.propNumeroProposicoesCamaraProposicao}
                                                    Senado={this.state.propNumeroProposicoesSenadoProposicao}
                                                    Congresso={this.state.propNumeroProposicoesCongressoProposicao}
                                                    ativas={this.state.propNumeroProposicoesSeguidasAtivas}
                                                    inativas={this.state.propNumeroProposicoesSeguidasInativas}
                                                    CDAtivo={this.state.CDAtivo}
                                                    CDInativo={this.state.CDInativo}
                                                    SFAtivo={this.state.SFAtivo}
                                                    SFInativo={this.state.SFInativo}
                                                    CNAtivo={this.state.CNAtivo}
                                                    CNInativo={this.state.CNInativo}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 " style={{ height: '20%', backgroundColor: "white", marginTop: "10px" }}  >
                                        <div className="col-12 mt-2">
                                            <div className="grafico-card-proposicao pt-3 mb-2">
                                                <span className="d-flex justify-content-center "><b className="dashboard-graficos-titulo">Momentos críticos</b></span>
                                                <div className={`d-flex justify-content-center flex-wrap p-3`}>
                                                    <GraficoInfoMomentosCriticosProposicao
                                                        propMomentosCriticosAguardandoDesignacaoRelator={this.state.propMomentosCriticosAguardandoDesignacaoRelatorProposicao}
                                                        propMomentosCriticosAguardanoParecerDoRelator={this.state.propMomentosCriticosAguardanoParecerDoRelatorProposicao}
                                                        propMomentosCriticosPrazoParaEmenda={this.state.propMomentosCriticosPrazoParaEmendaProposicao}
                                                        propMomentosCriticosAguardaDeliberacao={this.state.propMomentosCriticosAguardaDeliberacaoProposicao}
                                                    />

                                                    <div className="div-indice">
                                                        <div className="d-flex">
                                                            <div className="indice-exito mt-1 mr-2"></div>
                                                            <div onClick={event => { this.selectFiltroProposicao(event, 'Aguardando Designação do Relator', 'momento critico') }} className="mr-2 link-graficos-legenda-proposicao" style={{fontSize:"13px"}}>Aguardando Designação do Relator</div>
                                                            <div>
                                                                {this.state.propMomentosCriticosAguardandoDesignacaoRelatorProposicao}
                                                            </div>
                                                        </div>

                                                        <div className="d-flex mt-2">
                                                            <div className="indice-sem-exito mt-1 mr-2"></div>
                                                            <div onClick={event => { this.selectFiltroProposicao(event, 'Aguardando Parecer do Relator', 'momento critico') }} className="mr-2 link-graficos-legenda-proposicao" style={{fontSize:"13px"}}>Aguardando Parecer do Relator</div>
                                                            <div>
                                                                {this.state.propMomentosCriticosAguardanoParecerDoRelatorProposicao}
                                                            </div>
                                                        </div>

                                                        <div className="d-flex mt-2">
                                                            <div className="indice-exito-parcial mt-1 mr-2"></div>
                                                            <div onClick={event => { this.selectFiltroProposicao(event, 'Prazo para Emendas', 'momento critico') }} className="mr-2 link-graficos-legenda-proposicao" style={{fontSize:"13px"}}>Prazo para Emendas</div>
                                                            <div>
                                                                {this.state.propMomentosCriticosPrazoParaEmendaProposicao}
                                                            </div>
                                                        </div>

                                                        <div className="d-flex mt-2">
                                                            <div className="indice-pendente mt-1 mr-2 "></div>
                                                            <div onClick={event => { this.selectFiltroProposicao(event, 'Aguardando Deliberação', 'momento critico') }} className="mr-2 link-graficos-legenda-proposicao" style={{fontSize:"13px",}}>Aguardando Deliberação</div>
                                                            <div>
                                                                {this.state.propMomentosCriticosAguardaDeliberacaoProposicao}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-12 " style={{ height: '20%', backgroundColor: "white", marginTop: "10px", marginBottom: "10px" }}  >
                                        {this.props.authStore.ehEsforcoColaborativo === false ?
                                            <div className="col-12  mt-2">
                                                <div className="grafico-card-proposicao pt-3">
                                                    <span className="d-flex justify-content-center mt-2"><b className="dashboard-graficos-titulo">Ação de influência</b></span>
                                                    <div className={`d-flex justify-content-center flex-wrap pb-3`}>
                                                        <GraficoInfoAcoesInfluencia
                                                            propResultadoClientExito={this.state.propResultadoClientExito}
                                                            propResultadoClientSemExito={this.state.propResultadoClientSemExito}
                                                            propResultadoClientExitoParcial={this.state.propResultadoClientExitoParcial}
                                                            propResultadoClientPendente={this.state.propResultadoClientPendente}
                                                        />
                                                        <div className="div-indice">
                                                            
                                                            <div className="d-flex mt-2">
                                                                <div className="indice-exito mt-1 mr-2"></div>
                                                                <div onClick={event => { this.selectFiltroProposicao(event, 'exito', 'acao-influencia', 1) }} className="mr-2 link-graficos-legenda-proposicao" style={{fontSize:"13px"}}>Êxito</div>
                                                                <div>
                                                                    {this.state.propResultadoClientExito}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-2">
                                                                <div className="indice-sem-exito mt-1 mr-2"></div>
                                                                <div onClick={event => { this.selectFiltroProposicao(event, 'semExito', 'acao-influencia', 2) }} className="mr-2 link-graficos-legenda-proposicao" style={{fontSize:"13px"}}>Sem Êxito</div>
                                                                <div>
                                                                    {this.state.propResultadoClientSemExito}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-2">
                                                                <div className="indice-exito-parcial mt-1 mr-2 "></div>
                                                                <div onClick={event => { this.selectFiltroProposicao(event, 'exito-parcial', 'acao-influencia', 3) }} className="mr-2 link-graficos-legenda-proposicao" style={{fontSize:"13px"}}>Êxito Parcial</div>
                                                                <div>
                                                                    {this.state.propResultadoClientExitoParcial}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-2 mb-2">
                                                                <div className="indice-pendente mt-1 mr-2"></div>
                                                                <div onClick={event => { this.selectFiltroProposicao(event, 'pendente', 'acao-influencia', 99) }} className="mr-2 link-graficos-legenda-proposicao" style={{fontSize:"13px"}}>Pendente</div>
                                                                <div>
                                                                    {this.state.propResultadoClientPendente}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>


                    {/* >>>>>>>>>>>>> DESK CODE <<<<<<<<<<<<<<*/}

                    <div className="hiddenMobileModel">

                        <div className="col-12 bg-white pl-2 " style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                            <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                                PROPOSIÇÕES
                          </p>
                        </div>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 col-xl-6 col-lg-12 col-12 espacamentoBottom">
                                    <div className="row">
                                        <div className="col-md-4 marginBuscarProInput">
                                            <div className="input-group  icon-input">
                                                <input className="form-control  border-right-0 border" id="pesquisar-proposicao" type="search" autoComplete="off"
                                                    value={this.state.descricao}
                                                    onKeyUp={(event) => { if (event.which === 13) { this.listarProposicao() } }}
                                                    onChange={(event) => this.aplicarFiltroInput(event)} placeholder="Buscar proposição" aria-label="Pesquisar" aria-describedby="search" />
                                                <span id="icone-pesquisar-proposicao" className="input-group-append">
                                                    <button style={{ paddingTop: "8.5px" }} className="btn btn-outline-secondary border-left-0 border" type="button"
                                                        onClick={() => { this.listarProposicao(); this.setState({ filtroInput: true }) }}>
                                                        <i className="icon-search-small"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <FiltroProposicao
                                                numeros={this.state.numeros}
                                                temas={this.state.temas}
                                                ano={this.state.ano}
                                                apelido={this.state.apelido}
                                                partido={this.state.partido}
                                                estado={this.state.estado}
                                                ambito1={this.state.ambito1}
                                                ambito2={this.state.ambito2}
                                                ambito3={this.state.ambito3}
                                                busca={this.state.descricao}
                                            />
                                        </div>
                                        {
                                            this.props.authStore.ehVisualizador === false ?
                                                <div className="col-md-5 col-6 marginBotaoCVadastrarProp">

                                                    <Link to="/proposicao/cadastro-proposicao" className="btn " style={{ marginTop: "20px", padding: "15px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter", width: "100%" }} variant="outlined">
                                                        <img
                                                            className="mr-2"
                                                            style={{ width: "20px" }}
                                                            src={require('../../design/assets/images/editar.svg')}
                                                            alt="btn-pesquisar"
                                                        />{' '}
                                                        Cadastrar Proposição
                                                    </Link>
                                                </div>

                                                :
                                                null


                                        }

                                    </div>
                                </div>
                                <div className="col-md-12 col-xl-6 col-lg-12">
                                    <div className="row bg-white p-3">
                                        <div className="col-md-6">
                                            <div className="row espacamentoData">
                                                <div className="col-md-6 col-sm-6 espacamentoDataInt">
                                                    <div className="data-dashboard-label-proposicao">
                                                        Data Inicial
                                              </div>
                                                    <input id="data-inicial" className="" type="date" onChange={event => this.dataInicial(event)} />
                                                </div>

                                                <div className="col-md-6 col-sm-6">
                                                    <div className="data-dashboard-label-proposicao">
                                                        Data Final
                                              </div>
                                                    <input id="data-final" className="" type="date" onChange={event => this.dataFinal(event)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-6 centerPesquisar">
                                            <button id="pesquisa-dash" className="btn" style={{ padding: "15px", width: "100%", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)" }} variant="outlined" onClick={() => this.getDataGraficos()}>
                                                <img
                                                    className="mr-2"
                                                    src={require('../../design/assets/images/lupa.svg')}
                                                    alt="btn-pesquisar"
                                                />{' '}
                                              Pesquisar
                                      </button>
                                        </div>
                                        <div className="col-md-2 col-sm-12 col-6">
                                            <button id="limpar-dash" className="btn" style={{ padding: "15px", width: "100%", backgroundColor: "var(--botaoLimpar)", color: "var(--corTextoBotaoLimpar)", border: "1px solid var(--corTextoBotaoLimpar)" }} variant="outlined" onClick={() => this.getDataLimparGraficos()}>
                                                Limpar
                                      </button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <section className="container-fluid mt-3 mb-3">
                                <div className="row">

                                    {!this.state.messageNoData ? null :
                                        <div className="col-12 text-center mt-3 mb-3">
                                            <h5 className="text-danger">Não foram encontradas proposições para sua busca!</h5>
                                        </div>
                                    }


                                    {/* grafico acao preparatoria  */}
                                    {this.props.authStore.ehEsforcoColaborativo === false ?
                                        <div className="col-12 mt-2" style={{ background: 'white' }}>
                                            <div className="row">
                                                <div className="col-md-6 col-8">
                                                    <span className="acaoPreparatoriaDashboardProp text-left mt-4 p-3"><b>Ação Preparatória</b></span>
                                                </div>
                                                <div className="col-md-6 col-1 mt-2">
                                                    <div className="d-flex">
                                                        <div>{ this.state.aberto }</div>
                                                        <div onClick={event => { this.selectFiltroProposicao(event, 'aberto', 'acao preparatoria') }} className="mr-2 link-graficos-legenda-proposicao">      Total</div>
                                                        
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">

                                                    <div className="grafico-card-proposicao pt-3 mb-2">
                                                        <div className={`d-flex justify-content-center flex-wrap p-3`}>
                                                            <GraficoAcaoPreparatoria1
                                                                prazo={this.state.prazo}
                                                                atraso={this.state.atraso}
                                                            />

                                                            <div className="div-indice">
                                                                <div className="d-flex">
                                                                    <div className="indice-exito mt-1 mr-2"></div>
                                                                    <div onClick={event => { this.selectFiltroProposicao(event, 'no prazo', 'acao preparatoria') }} className="mr-2 link-graficos-legenda-proposicao">No prazo</div>
                                                                    <div>
                                                                        {this.state.prazo}
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mt-2">
                                                                    <div className="indice-sem-exito mt-1 mr-2"></div>
                                                                    <div onClick={event => { this.selectFiltroProposicao(event, 'em atraso', 'acao preparatoria') }} className="mr-2 link-graficos-legenda-proposicao">Em atraso</div>
                                                                    <div>
                                                                        {this.state.atraso}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-6">

                                                    <div className="grafico-card-proposicao pt-3 mb-2">
                                                        <div className={`d-flex justify-content-center flex-wrap p-3`}>
                                                            <GraficoAcaoPreparatoria2
                                                                finalizada={this.state.finalizada}
                                                                pendente={this.state.pendente}
                                                            />

                                                            <div className="div-indice">
                                                                <div className="d-flex">
                                                                    <div className="indice-exito mt-1 mr-2"></div>
                                                                    <div onClick={event => { this.selectFiltroProposicao(event, 'finalizada', 'acao preparatoria', undefined, this.state.dataInicial?this.state.dataInicial:undefined, this.state.dataFinal?this.state.dataFinal:undefined) }} className="mr-2 link-graficos-legenda-proposicao">Finalizado</div>
                                                                    <div>
                                                                        {this.state.finalizada}
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mt-2">
                                                                    <div className="indice-sem-exito mt-1 mr-2"></div>
                                                                    <div onClick={event => { this.selectFiltroProposicao(event, 'pendente', 'acao preparatoria') }} className="mr-2 link-graficos-legenda-proposicao">Pendente</div>
                                                                    <div>
                                                                        {this.state.pendente}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    <div className="col-12 " style={{ height: '20%' }}>
                                        <div className="col-12 mt-2">
                                            <div className="grafico-card-proposicao p-3">
                                                <ModalGraficoCasa
                                                    Camara={this.state.propNumeroProposicoesCamaraProposicao}
                                                    Senado={this.state.propNumeroProposicoesSenadoProposicao}
                                                    Congresso={this.state.propNumeroProposicoesCongressoProposicao}
                                                    ativas={this.state.propNumeroProposicoesSeguidasAtivas}
                                                    inativas={this.state.propNumeroProposicoesSeguidasInativas}
                                                    CDAtivo={this.state.CDAtivo}
                                                    CDInativo={this.state.CDInativo}
                                                    SFAtivo={this.state.SFAtivo}
                                                    SFInativo={this.state.SFInativo}
                                                    CNAtivo={this.state.CNAtivo}
                                                    CNInativo={this.state.CNInativo}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 mt-2">
                                            <div className="grafico-card-proposicao pt-3 mb-2">
                                                <span className="d-flex justify-content-center "><b className="dashboard-graficos-titulo">Momentos críticos</b></span>
                                                <div className={`d-flex justify-content-center flex-wrap p-3`}>
                                                    <GraficoInfoMomentosCriticosProposicao
                                                        propMomentosCriticosAguardandoDesignacaoRelator={this.state.propMomentosCriticosAguardandoDesignacaoRelatorProposicao}
                                                        propMomentosCriticosAguardanoParecerDoRelator={this.state.propMomentosCriticosAguardanoParecerDoRelatorProposicao}
                                                        propMomentosCriticosPrazoParaEmenda={this.state.propMomentosCriticosPrazoParaEmendaProposicao}
                                                        propMomentosCriticosAguardaDeliberacao={this.state.propMomentosCriticosAguardaDeliberacaoProposicao}
                                                    />

                                                    <div className="div-indice">
                                                        <div className="d-flex">
                                                            <div className="indice-exito mt-1 mr-2"></div>
                                                            <div onClick={event => { this.selectFiltroProposicao(event, 'Aguardando Designação do Relator', 'momento critico') }} className="mr-2 link-graficos-legenda-proposicao">Aguardando Designação do Relator</div>
                                                            <div>
                                                                {this.state.propMomentosCriticosAguardandoDesignacaoRelatorProposicao}
                                                            </div>
                                                        </div>

                                                        <div className="d-flex mt-2">
                                                            <div className="indice-sem-exito mt-1 mr-2"></div>
                                                            <div onClick={event => { this.selectFiltroProposicao(event, 'Aguardando Parecer do Relator', 'momento critico') }} className="mr-2 link-graficos-legenda-proposicao">Aguardando Parecer do Relator</div>
                                                            <div>
                                                                {this.state.propMomentosCriticosAguardanoParecerDoRelatorProposicao}
                                                            </div>
                                                        </div>

                                                        <div className="d-flex mt-2">
                                                            <div className="indice-exito-parcial mt-1 mr-2"></div>
                                                            <div onClick={event => { this.selectFiltroProposicao(event, 'Prazo para Emendas', 'momento critico') }} className="mr-2 link-graficos-legenda-proposicao">Prazo para Emendas</div>
                                                            <div>
                                                                {this.state.propMomentosCriticosPrazoParaEmendaProposicao}
                                                            </div>
                                                        </div>

                                                        <div className="d-flex mt-2">
                                                            <div className="indice-pendente mt-1 mr-2 mobile-momento-critico-proposicao"></div>
                                                            <div onClick={event => { this.selectFiltroProposicao(event, 'Aguardando Deliberação', 'momento critico') }} className="mr-2 link-graficos-legenda-proposicao">Aguardando Deliberação</div>
                                                            <div>
                                                                {this.state.propMomentosCriticosAguardaDeliberacaoProposicao}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {this.props.authStore.ehEsforcoColaborativo === false ?
                                            <div className="col-12  mt-2">
                                                <div className="grafico-card-proposicao pt-3">
                                                    <span className="d-flex justify-content-center mt-2"><b className="dashboard-graficos-titulo">Ação de influência</b></span>
                                                    <div className={`d-flex justify-content-center flex-wrap pb-3`}>
                                                        <GraficoInfoAcoesInfluencia
                                                            propResultadoClientExito={this.state.propResultadoClientExito}
                                                            propResultadoClientSemExito={this.state.propResultadoClientSemExito}
                                                            propResultadoClientExitoParcial={this.state.propResultadoClientExitoParcial}
                                                            propResultadoClientPendente={this.state.propResultadoClientPendente}
                                                        />
                                                        <div className="div-indice">
                                                            <div className="d-flex">
                                                                <div className="indice-exito mt-1 mr-2"></div>
                                                                <div onClick={event => { this.selectFiltroProposicao(event, 'exito', 'acao-influencia', 1) }} className="mr-2 link-graficos-legenda-proposicao">Êxito</div>
                                                                <div>
                                                                    {this.state.propResultadoClientExito}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-2">
                                                                <div className="indice-sem-exito mt-1 mr-2"></div>
                                                                <div onClick={event => { this.selectFiltroProposicao(event, 'semExito', 'acao-influencia', 2) }} className="mr-2 link-graficos-legenda-proposicao">Sem Êxito</div>
                                                                <div>
                                                                    {this.state.propResultadoClientSemExito}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-2">
                                                                <div className="indice-exito-parcial mt-1 mr-2 "></div>
                                                                <div onClick={event => { this.selectFiltroProposicao(event, 'exito-parcial', 'acao-influencia', 3) }} className="mr-2 link-graficos-legenda-proposicao">Êxito Parcial</div>
                                                                <div>
                                                                    {this.state.propResultadoClientExitoParcial}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-2 mb-2">
                                                                <div className="indice-pendente mt-1 mr-2"></div>
                                                                <div onClick={event => { this.selectFiltroProposicao(event, 'pendente', 'acao-influencia', 99) }} className="mr-2 link-graficos-legenda-proposicao">Pendente</div>
                                                                <div>
                                                                    {this.state.propResultadoClientPendente}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                </div>

                            </section>
                        </div>

                    </div>
                </div>
        )
    }
}

export default ProposicaoDefault