const posicionamento = {
    PENDENTE: 99,
    CONTRA: 1,
    CONTRA_EMENDA: 2,
    NEUTRO: 3,
    FAVORAVEL_EMENDA: 4,
    FAVORAVEL: 5,

    1: 'Rejeição',
    3: 'Rejeição ou Favorável com Emenda saneadora',
    0: 'Neutro',
    99: 'Pendente',
    4: 'Favorável com emenda saneadora',
    5: 'Favorável',
}

export const posicionamentos = [
    // { "id": 1, nome: "Contrário", "spanclass": "acao-red", outline: "danger", icon: 'opposite' },
    // { "id": 2, nome: "Contrário com emenda saneadora", "spanclass": "acao-orange", outline: "info", icon: 'opposite' },
    // { "id": 3, nome: "Neutro", "spanclass": "acao-blue", outline: "primary", icon: 'neutral' },
    // { "id": 99, nome: "Pendente", "spanclass": "acao-blue", outline: "primary", icon: 'neutral' },
    // { "id": 4, nome: "Favorável com emenda saneadora", "spanclass": "acao-blue", outline: "info", icon: 'favorable' },
    // { "id": 5, nome: "Favorável", "spanclass": "acao-green", outline: "success", icon: 'favorable' },
]

export const posicionamentosClient = [
    { "id": 1, value: "Contrário", icon: 'negativo' },
    { "id": 2, value: "Contrário com emenda saneadora", icon: 'pendente' },
    { "id": 3, value: "Neutro", icon: 'neutro' },
    { "id": 99, value: "Pendente", icon: 'aviso' },
    { "id": 4, value: "Favorável com emenda saneadora", icon: 'favoravel2' },
    { "id": 5, value: "Favorável", icon: 'sucesso' },
]

export default  {posicionamentosClient, posicionamentos, posicionamento} 
