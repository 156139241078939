import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

@inject('uploadStore', 'authStore')
@observer

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    this.state = {
      modulo: this.props.modulo || '',
      id_referencia: this.props.id_referencia || 0
    }
  }

  async onChange(event) {
    //console.log('UPLOAD>>>',event,this.state.modulo, this.state.id_referencia)
    this.props.onChange(await this.props.uploadStore.carregarArquivo(event, this.state.modulo, this.state.id_referencia))
  }

  render() {
    
    const { modulo, id_referencia } = this.state;
    const name = `arquivos-${modulo}-${id_referencia}`;

    //console.log('UPLOAD>>>',modulo, id_referencia)
    //console.log('UPLOAD>>>',this.props.linkButton )
    return (
      <div className="mr-auto">

      {this.props.authStore.ehVisualizador === true ? null :
        <label htmlFor={name}>
          {          
            this.props.linkButton ?
            <span id={this.props.id} style={{fontSize:"13px"}}>Anexar</span>
            :
            <div className="modal--files">
              <span className="Client-btn btn-secundario">
                Anexar</span>
            </div>
          }
        </label>
        }


        {this.props.authStore.ehVisualizador === true ? null :
        <input type="file" id={name} className="entradaArquivo" onChange={this.onChange} multiple />
        }

        {this.props.uploadStore.loading && <img src={require('../../../design/assets/images/loading.svg')} alt="carregando" width="10px" />}
      </div>
    )
  }
}

export default UploadFile