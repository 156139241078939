import React, { Fragment, Component } from 'react';
import { inject, observer } from 'mobx-react';
// import './notificacoes.css';
import moment from 'moment-timezone';
import 'moment/locale/pt-br.js'
import Loading from "../../componentes/loading";
import notificacaoStore from "../../stores/notificacaoStore";
import QueryPaginado from "../../componentes/hoc/queryPaginado";
// import ProposicaoNotification from './components/proposicao-notification/proposicao-notification';
import Seguir from '../../componentes/seguir';
// import { NavLink } from 'react-router-dom';
import { put } from '../../services/http'
import alertaStore from "../../stores/alertaStore"
import ConfirmModal from '../../componentes/confirmModal';

moment.tz.setDefault('UTC');

@inject('authStore')
@observer


class Notificacoes extends Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         options: {
    //             labels: ['Êxito', 'Sem êxito', 'Êxito Parcial', 'Pendente'],
    //             colors: ['#4CD964', '#F63854','#5856D7','#D8D8D8'],
    //             legend: {
    //                 show: false,

    //             }

    //         },
    //         notificacao: [],
    //     showModal: false,
    //     }
    // }

    state = {
        notificacao: [],
        showModal: false,
    }

    fromNow = data => {
        const Y = moment(data).year()
        const M = moment(data).month()
        const D = moment(data).date()
        const HH = moment(data).hour()
        const MM = moment(data).minute()
        const SS = moment(data).second()
        return moment([Y, M, D, HH, MM, SS]).fromNow()
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }



    arquivarTodas = async (showModal, save, item) => {
        this.setState({ showModal })
        if (save) {
            await put('/notificacao/arquivar/todas')
                .then((notificacao) => {
                    alertaStore.criar("Notificações arquivadas com sucesso")
                    window.location.reload();
                })
                .catch((err) => {
                    console.error("error ao arquivar notificações", err)
                })
        }
    }

    changeShowModalState(showModal) {
        this.setState({ showModal })
    }

    // renderSwitch(param){
    //     var vermelho = "#FF0000";
    //     var amarelo = "#FFFF00";

    //     switch(param){
    //         case item.mensagem.split('@&')[5] === "maxima" : return vermelho;
    //         case item.mensagem.split('@&')[5] === "minima" : return amarelo;
    //     }
    // }

    // corrigirArrary(data) {
       

    //     var dups = [];
    //     var arr = data && data.length > 0 && data.filter(function(el) {
    //     // If it is not a duplicate, return true
    //     if (dups.indexOf(el.ID) == -1) {
    //         dups.Push(el.ID);
    //         return true;
    //     }

    //     return false;
        
    //     });
    // }


    render() {

        // const coresProps = [
        //     this.props.dados.exitoGrafico !== undefined ? this.props.dados.exitoGrafico : 0,
        //     this.props.dados.semExitoGrafico !== undefined ? this.props.dados.semExitoGrafico : 0,
        //     this.props.dados.parcialGrafico !== undefined ? this.props.dados.parcialGrafico : 0,
        //     this.props.dados.pendenteGrafico !== undefined ? this.props.dados.pendenteGrafico : 0,
        // ]

        // const texto = 'PL123/2020@&texto de emenda@&Relator@&Joao de Deus@&Prioridade@&Minima@&Posicionamento@&Contrario'
        // const textoFormatado = texto.split('@&')
        // console.log(textoFormatado)
        return (
            
            <article className='notificacoes col-12'>
                <section className='container-fluid'>
                    <div className='notifications-container'>
                        

                        <QueryPaginado query={`notificacao`}>

                            {({ fetching, data, change, reload }) => {

                                // solucao temporaria ate corrigir array repetidos em notificacao 
                                // console.log("dadadadatatata", fetching)
                                var dups = [];
                                var novaData = data && data.length > 0 && data.filter((el) => {
                                    
                                    if (dups.indexOf(el.id) === -1) {
                                        dups.push(el.id);
                                        return true;
                                    }
                                    return false;
                                });

                                // console.log("nova data", novaData)

                                return (
                                    
                                    <Fragment>
                                        
                                        <div>
                                            <ConfirmModal
                                                text={'Tem certeza que deseja arquivar todas as notificações?'}
                                                description={'Esta ação não poderá ser desfeita no futuro!'}
                                                onClose={(show, save, item) => this.arquivarTodas(show, save, item)}
                                                show={this.state.showModal}
                                                item={data}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-12 col-lg-12 col-xl-12 ">
                                            {/* <button id="arquivar-todas" className="btn btn-danger" onClick={(e) => {if(window.confirm('Têm certeza que deseja arquivar todas as notificações!')) this.arquivarTodas(e)}}>Arquivar todas</button>     */}
                                            <button id="arquivar-todas" className="btn btn-danger" onClick={() => this.changeShowModalState(true)}>Arquivar todas</button>
                                        </div>
                                        {/* {data !== undefined && Array.isArray(data) && data.length ?
                                            data.map((item, index) => { */}

                                        {novaData !== undefined && Array.isArray(novaData) && novaData.length ?

                                            novaData.map((item, index) => {
                                                var corPrioridade;
                                                var corPosicionamento;
                                                // if(item.tipo === 6 && item.tipo === 5){
                                                switch (item.mensagem.split('@&')[5]) {

                                                    case "Maxima":
                                                        corPrioridade = "#FF0000";
                                                        break

                                                    case "Alta":
                                                        corPrioridade = "#FF4500";
                                                        break

                                                    case "Media":
                                                        corPrioridade = "	#FF6347";
                                                        break

                                                    case "Baixa":
                                                        corPrioridade = "#FF7F50";
                                                        break

                                                    case "Minima":
                                                        corPrioridade = "#FA8072";
                                                        break

                                                    default:
                                                        corPrioridade = null;

                                                }

                                                switch (item.mensagem.split('@&')[7]) {

                                                    case "Favoravel":
                                                        corPosicionamento = "#00FF00";
                                                        break

                                                    case "Favoravel com Emenda":
                                                        corPosicionamento = "#00008B";
                                                        break

                                                    case "Contrario":
                                                        corPosicionamento = "#f0e60b";
                                                        break

                                                    case "Contrario com Emenda Saneadora":
                                                        corPosicionamento = "#1E90FF";
                                                        break

                                                    case "Pendente":
                                                        corPosicionamento = "#FFFF00";
                                                        break

                                                    case "Neutro":
                                                        corPosicionamento = "#DCDCDC";
                                                        break

                                                    default:
                                                        corPosicionamento = null;

                                                }
                                                // }
                                                // console.log(item)
                    
                                                const mensagem = item.tipo === 8 ? item.mensagem.split('&@') : null
                                                return (
                                                
                                                    this.props.authStore.ehEsforcoColaborativo === false ?

                                                        <div key={index}
                                                            className={`notification fs-13`}>
                                                            <div className='notification-item'>
                                                                 {/* {console.log(item)}  */}
                                                                <div className='row'>

                                                                    <div className='col-12 d-flex'>
                                                                        <div>
                                                                            {item.tipo === 0 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div>
                                                                                    <p className="titulo-notificacoes">AÇÃO PREPARATÓRIA ATUALIZADA</p>
                                                                                    <div>
                                                                                        <a href={`/proposicao/${item.id_proposicao}/acao-preparatoria`}>
                                                                                            {item.descricao !== null ?
                                                                                                <div className='card-descricao'>{item.descricao}</div>
                                                                                                : null}
                                                                                            <div className='Client-remove-link-notificacao message p-1 d-flex'>{item.mensagem}</div>
                                                                                        </a>
                                                                                        {item.link2 !== null && item.link2 !== '' ?
                                                                                            <a href={item.link2} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>INTEIRO TEOR</a> :
                                                                                            null}
                                                                                    </div>
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                        <div>
                                                                            {item.tipo === 1 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div>
                                                                                    {/* <p className="titulo-notificacoes">RESPONSÁVEL AÇÃO DE INFLUÊNCIA</p> */}
                                                                                    <span className="d-block" style={{lineHeight:"16px", color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>RESPONSÁVEL AÇÃO DE INFLUÊNCIA</span>
                                                                                    {/* <p>{moment(new Date(new Date(item.criado_em).getTime() - 10800000)).format('DD/MM/YYYY [-] HH[h]mm')}</p> */}
                                                                                    <p  style={{lineHeight:"16px", fontSize: '10px' }}>{moment(new Date(new Date(item.criado_em).getTime())).format('DD/MM/YYYY [-] HH[h]mm')}</p>
                                                                                    <div>
                                                                                        <a href={`/proposicao/${item.id_proposicao}/acoes-influencia`}>
                                                                                            {item.descricao !== null ?
                                                                                                <div className='card-descricao'>{item.descricao}</div>
                                                                                                : null}
                                                                                            <div  style={{lineHeight:"16px", fontSize: '13px', paddingBottom:"10px", textAlign:"start", color:"gray"}} className='Client-remove-link-notificacao message p-1 d-flex'>{item.mensagem}</div>
                                                                                        </a>
                                                                                        {item.link2 !== null && item.link2 !== '' ?
                                                                                            <a href={item.link2} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>INTEIRO TEOR</a> :
                                                                                            null}
                                                                                    </div>
                                                                                </div>

                                                                                : null}
                                                                        </div>
                                                                        <div>
                                                                            {item.tipo === 2 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div>
                                                                                    {/* <p className="titulo-notificacoes">RESPONSÁVEL AÇÃO PREPARATÓRIA</p> */}
                                                                                    <span className="d-block" style={{lineHeight:"16px", color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>RESPONSÁVEL AÇÃO PREPARATÓRIA</span>
                                                                                    {/* <p>{moment(new Date(new Date(item.criado_em).getTime() - 10800000)).format('DD/MM/YYYY [-] HH[h]mm')}</p> */}
                                                                                    <p style={{lineHeight:"16px", fontSize: '10px' }}>{moment(new Date(new Date(item.criado_em).getTime())).format('DD/MM/YYYY [-] HH[h]mm')}</p>
                                                                                    <div>
                                                                                        <a href={`/proposicao/${item.id_proposicao}/acao-preparatoria`}>
                                                                                            {item.descricao !== null ?
                                                                                                <div className='card-descricao'>{item.descricao}</div>
                                                                                                : null}
                                                                                            <div style={{lineHeight:"16px", fontSize: '13px', paddingBottom:"10px", textAlign:"start", color:"gray"}} className='Client-remove-link-notificacao message p-1 d-flex'>{item.mensagem}</div>
                                                                                        </a>
                                                                                        {item.link2 !== null && item.link2 !== '' ?
                                                                                            <a href={item.link2} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>INTEIRO TEOR</a> :
                                                                                            null}
                                                                                    </div>
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                        <div>
                                                                            {item.tipo === 3 ?
                                                                                <div>
                                                                                    {/* <p className="titulo-notificacoes">RESPONSÁVEL ESFORÇO COLABORATIVO</p> */}
                                                                                    <span className="d-block" style={{lineHeight:"16px", color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>RESPONSÁVEL ESFORÇO COLABORATIVO</span>
                                                                                    {/* <p>{moment(new Date(new Date(item.criado_em).getTime() - 10800000)).format('DD/MM/YYYY [-] HH[h]mm')}</p> */}
                                                                                    <p style={{lineHeight:"16px", fontSize: '10px' }}>{moment(new Date(new Date(item.criado_em).getTime())).format('DD/MM/YYYY [-] HH[h]mm')}</p>
                                                                                    <div>
                                                                                        <a href={`/proposicao/${item.id_proposicao}/acao-preparatoria`}>
                                                                                            {item.descricao !== null ?
                                                                                                <div style={{lineHeight:"16px", fontSize: '10px' }} className='card-descricao'>{item.descricao}</div>
                                                                                                : null}
                                                                                            <div className='d-flex' style={{lineHeight:"16px", fontSize: '13px', paddingBottom:"10px", textAlign:"start", color:"gray"}}>{item.mensagem}</div>
                                                                                        </a>
                                                                                        {item.link2 !== null && item.link2 !== '' ?
                                                                                            <a href={item.link2} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>INTEIRO TEOR</a> :
                                                                                            null}
                                                                                    </div>
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                        <div>
                                                                            {item.tipo === 4 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div>
                                                                                    <p className="titulo-notificacoes">MENCIONADO NOS COMENTÁRIOS</p>
                                                                                    <div>
                                                                                        <a href={`/proposicao/${item.id_proposicao}/acao-preparatoria`}>
                                                                                            {item.descricao !== null ?
                                                                                                <div className='card-descricao'>{item.descricao}</div>
                                                                                                : null}
                                                                                            <div className='Client-remove-link-notificacao message p-1 d-flex'>{item.mensagem}</div>
                                                                                        </a>
                                                                                        {item.link2 !== null && item.link2 !== '' ?
                                                                                            <a href={item.link2} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>INTEIRO TEOR</a> :
                                                                                            null}
                                                                                    </div>
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                        <div>
                                                                            {(item.tipo === 6 || item.tipo === 5) && this.props.authStore.ehEsforcoColaborativo === false ?

                                                                                // <ProposicaoNotification relacional={true} item={item}/> : null 
                                                                                <div>

                                                                                    <span className="d-block" style={{lineHeight:"16px", color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>NOVA PROPOSIÇÂO</span>
                                                                                        <div className="row " style={{marginTop:"-15px", marginBottom:"-20px"}}>
                                                                                            <p style={{marginRight:"5px", marginLeft:"7px"}}>{moment(new Date(new Date(item.criado_em.split("T")[0]).getTime())).format('DD/MM/YYYY')}</p>
                                                                                            {/* <p>{item.criado_em.split("T")[1] === "00:00:00.000Z" ? null :  `${item.criado_em.split("T")[1].substr(0, 5)}h`}</p> */}
                                                                                        </div>
                                                                                    <div>

                                                                                        <a href={`/proposicao/${item.id_proposicao}/acao-preparatoria`}>
                                                                                            <div  style={{lineHeight:"16px", fontSize: '13px', paddingBottom:"10px", textAlign:"start", color:"gray", marginBottom:"-20px"}} className='Client-remove-link-notificacao message d-flex'>
                                                                                                {/* {textoFormatado[0]} */}
                                                                                                {item.mensagem.split('@&')[0]}
                                                                                            </div>

                                                                                            <div  style={{lineHeight:"16px", fontSize: '13px', paddingBottom:"10px", textAlign:"start", color:"gray", marginBottom:"-5px"}}>
                                                                                                {/* {textoFormatado[1]} */}
                                                                                                {item.mensagem.split('@&')[1]}
                                                                                            </div>

                                                                                        </a>


                                                                                        {this.isJson(item.link2) ?
                                                                                            JSON.parse(item.link2).map((inteiroTeor, index) => {
                                                                                                return (
                                                                                                    inteiroTeor.url !== '' && inteiroTeor.url !== null && inteiroTeor.descricao !== undefined ?
                                                                                                        <div key={index}>
                                                                                                            <a style={{marginBottom:"-20px"}} href={inteiroTeor.url} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>{inteiroTeor.descricao}</a>
                                                                                                        </div>
                                                                                                        : null
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            item.link2 !== null && item.link2 !== '' ?
                                                                                                <a href={item.link2} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>Inteiro Teor
                                                                                                </a>
                                                                                                :
                                                                                                null
                                                                                        }

                                                                                        <div style={{ fontWeight: "bold", fontSize: "15px", paddingLeft: "7px", marginTop: "10px" }}>
                                                                                            {/* {textoFormatado[2]} */}
                                                                                            {item.mensagem.split('@&')[2]}
                                                                                        </div>

                                                                                        <div style={{ fontSize: "14px", paddingLeft: "7px" }}>
                                                                                            {/* {textoFormatado[3]} */}
                                                                                            {item.mensagem.split('@&')[3]}
                                                                                        </div>

                                                                                        <div style={{ fontWeight: "bold", fontSize: "15px", paddingLeft: "7px", marginTop: "3px" }}>
                                                                                            {/* {textoFormatado[4]} */}
                                                                                            {item.mensagem.split('@&')[4]}
                                                                                        </div>

                                                                                        <div className="row" style={{ fontSize: "14px", paddingLeft: "7px" }}>
                                                                                            {/* {textoFormatado[5]} */}

                                                                                            {item.mensagem.split('@&')[5] &&
                                                                                                <div>
                                                                                                    <div style={{ marginLeft: "7px", marginRight: "10px", marginTop: "4px", backgroundColor: corPrioridade, borderRadius: "150px", width: "12px", height: "12px" }}>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }

                                                                                            {item.mensagem.split('@&')[5] &&
                                                                                                <div>
                                                                                                    {item.mensagem.split('@&')[5]}
                                                                                                </div>
                                                                                            }
                                                                                        </div>

                                                                                        {item.mensagem.split('@&')[6] &&
                                                                                            <div style={{ fontWeight: "bold", fontSize: "15px", paddingLeft: "7px", marginTop: "3px" }}>
                                                                                                {/* {textoFormatado[6]} */}
                                                                                                {item.mensagem.split('@&')[6]}
                                                                                            </div>
                                                                                        }


                                                                                        <div className="row" style={{ fontSize: "14px", paddingLeft: "7px", marginBottom: "10px" }}>
                                                                                            {/* {textoFormatado[7]} */}

                                                                                            {item.mensagem.split('@&')[7] &&
                                                                                                <div>
                                                                                                    <div style={{ marginLeft: "7px", marginRight: "10px", marginTop: "4px", backgroundColor: corPosicionamento, borderRadius: "150px", width: "12px", height: "12px" }}>

                                                                                                    </div>

                                                                                                </div>
                                                                                            }

                                                                                            {item.mensagem.split('@&')[7] &&
                                                                                                <div>
                                                                                                    {item.mensagem.split('@&')[7]}
                                                                                                </div>
                                                                                            }


                                                                                        </div>
                                                                                        {/* <h1>teste nova proposicao</h1>  */}
                                                                                        {/* <NavLink to={`${item.link}/acao-preparatoria`}>
                                                                                            {item.mensagem !== null ?
                                                                                                <div className='Client-remove-link-notificacao message p-1 d-flex'>{item.mensagem}</div>
                                                                                                : null}
                                                                                            {item.nomeCasaIdentificacaoMateria !== null ?
                                                                                                <div className='card-casa'>CASA: {item.nomeCasaIdentificacaoMateria}</div>
                                                                                                : null}
                                                                                        </NavLink>
                                                                                        {this.isJson(item.link2) ?
                                                                                            JSON.parse(item.link2).map((inteiroTeor, index) => {
                                                                                                return (
                                                                                                    inteiroTeor.url !== '' && inteiroTeor.url !== null && inteiroTeor.descricao !== undefined ?
                                                                                                        <div key={index}>
                                                                                                            <a href={inteiroTeor.url} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>{inteiroTeor.descricao}</a>
                                                                                                        </div>
                                                                                                        : null
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            item.link2 !== null && item.link2 !== '' ?
                                                                                                <a href={item.link2} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>Inteiro Teor</a> :
                                                                                                null
                                                                                        } */}

                                                                                    </div>
                                                                                </div>

                                                                                : null

                                                                            }

                                                                        </div>

                                                                        <div>
                                                                            {item.tipo === 7 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div>
                                                                                    {/* <p className="titulo-notificacoes">ESFORÇO COLABORATIVO</p> */}
                                                                                    <span className="d-block"  style={{lineHeight:"16px", color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>ESFORÇO COLABORATIVO</span>
                                                                                    {/* <p>{moment(new Date(new Date(item.criado_em).getTime() - 10800000)).format('DD/MM/YYYY [-] HH[h]mm')}</p> */}
                                                                                    <p style={{lineHeight:"16px", fontSize: '10px' }}>{moment(new Date(new Date(item.criado_em).getTime())).format('DD/MM/YYYY [-] HH[h]mm')}</p>
                                                                                    <div>
                                                                                        <a href={`/proposicao/${item.id_proposicao}/acao-preparatoria`}>
                                                                                            {item.descricao !== null ?
                                                                                                <div className='card-descricao'>{item.descricao}</div>
                                                                                                : null}
                                                                                            <div style={{lineHeight:"16px", fontSize: '13px', paddingBottom:"10px", textAlign:"start", color:"gray"}} className='Client-remove-link-notificacao message p-1 d-flex'>{item.mensagem}</div>
                                                                                        </a>
                                                                                        {item.link2 !== null && item.link2 !== '' ?
                                                                                            <a href={item.link2} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>INTEIRO TEOR</a> :
                                                                                            null}
                                                                                    </div>
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                        <div>
                                                                            {item.tipo === 8 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div >
                                                                                    <span className="d-block" style={{lineHeight:"16px", color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>TRAMITAÇÃO</span>
                                                                                    <p style={{lineHeight:"16px", fontSize: '10px' }}>{item.casa === 2 ? moment(item.criado_em).format('DD/MM/YYYY') + ' - ' + moment(item.criado_em).format('HH') + 'h' + moment(item.criado_em).format('mm') : moment(item.criado_em).format('DD/MM/YYYY')}</p>
                                                                                    {/* <p className="titulo-notificacoes">TRAMITAÇÃO - {item.casa === 2 ? moment(item.criado_em).format('DD/MM/YYYY') + ' - ' + moment(item.criado_em).format('HH') + 'h' + moment(item.criado_em).format('mm') : moment(item.criado_em).format('DD/MM/YYYY')}</p> */}
                                                                                    <div className="mt-3">
                                                                                        {/* {console.log(item)} */}
                                                                                        <a href={`/proposicao/${item.id_proposicao}/acao-preparatoria`}>
                                                                                            {item.descricao !== null ?
                                                                                                <div style={{lineHeight:"16px", fontSize: '30px' }} >{item.descricao}</div>
                                                                                                : null}
                                                                                            {item.descricao !== null ?
                                                                                                <div style={{lineHeight:"16px", fontSize: '10px' }} className='card-sigla'>SIGLA ÓRGÃO: {item.status_siglaOrgao}</div>
                                                                                                : null}
                                                                                            {item.nomeCasaIdentificacaoMateria !== null ?
                                                                                                <div style={{lineHeight:"16px", fontSize: '10px' }} className='card-casa'>CASA: {item.nomeCasaIdentificacaoMateria}</div>
                                                                                                : null}
                                                                                            {/* {item.status_despacho !== null ?
                                                                                            <p style={{lineHeight:"16px", fontSize: '15px', paddingTop:"10px" }} className='card-casa'>STATUS DESPACHO: {item.status_despacho}</p>
                                                                                                : null} */}

                                                                                            {mensagem.length === 4 ?
                                                                                                <div className="pt-2">
                                                                                                    <div>
                                                                                                        <span style={{lineHeight:"16px", color: '#292A2B', fontWeight: 'bold' }}>Ementa:</span>
                                                                                                        <p className='Client-remove-link-notificacao message p-1 d-flex'> {mensagem[1]}</p>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <span style={{lineHeight:"16px", color: '#292A2B', fontWeight: 'bold' }}>Situação:</span>
                                                                                                        <p className='Client-remove-link-notificacao message p-1 d-flex'> {mensagem[2]}</p>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <span style={{lineHeight:"16px", color: '#292A2B', fontWeight: 'bold' }}>Tramitação:</span>
                                                                                                        <p className='Client-remove-link-notificacao message p-1 d-flex'> {mensagem[3]}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                item.mensagem !== null ?
                                                                                                    <p style={{lineHeight:"16px", fontSize: '13px', paddingBottom:"10px", textAlign:"start", color:"gray", marginBottom:"10px"}} className='Client-remove-link-notificacao message p-1 d-flex'>{item.mensagem}</p>
                                                                                                    : null}

                                                                                                    {/* {item.mensagem !== null ?
                                                                                                    <p className='Client-remove-link-notificacao message p-1 d-flex'>{item.mensagem}</p> 
                                                                                                    : null}                                                                                                                                                                                                                                        */}
                                                                                        </a>
                                                                                        {this.isJson(item.link2) && Array.isArray(JSON.parse(item.link2))
                                                                                            && JSON.parse(item.link2) !== undefined ?
                                                                                            JSON.parse(item.link2).map((inteiroTeor, index) => {
                                                                                                return (
                                                                                                    inteiroTeor.url !== '' && inteiroTeor.url !== null ?
                                                                                                        <div key={index}>
                                                                                                            <a href={inteiroTeor.url} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>{inteiroTeor.descricao}</a>
                                                                                                        </div>
                                                                                                        : null
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            item.link2 !== null && item.link2 !== '' ?
                                                                                                <a  href={item.link2} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>Inteiro Teor</a> :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                : null}
                                                                        </div>

                                                                        <div>
                                                                            {item.tipo === 9 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div>
                                                                                    <span className="d-block" style={{lineHeight:"16px", color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>AGENDA</span>
                                                                                    <p style={{lineHeight:"16px", fontSize: '10px' }}>{moment(item.criado_em).format('DD/MM/YYYY [-] HH[h]mm')}</p>    
                                                                                    <div className="mt-3">
                                                                                        <a href={`/agenda/${moment(item.criado_em).format('YYYY-MM-DD')}`}>
                                                                                            {item.descricao !== null ?
                                                                                                <div className='card-descricao' style={{lineHeight:"16px", fontSize: '10px' }}>{item.descricao}</div>
                                                                                            : null}
                                                                                            {item.casa !== null && item.casa !== '' ?
                                                                                                <div className='card-sigla'>CASA: {item.nomeCasaIdentificacaoMateria}</div>
                                                                                            : null} 
                                                                                                                                   
                                                                                            {item.link !== null && item.link !== '' && item.link !== undefined ?
                                                                                                <div className='card-sigla'>
                                                                                                    LOCAL:<a href={`/proposicao/${item.id_evento}/agenda`} className="text-danger"> {item.link}</a>
                                                                                                </div>
                                                                                                : null}
                                                                                            <div style={{lineHeight:"16px", fontSize: '13px', paddingBottom:"10px", textAlign:"start", color:"gray"}} className='Client-remove-link-notificacao message p-1 d-flex'>{item.mensagem}</div>
                                                                                        </a>
                                                                                        {item.link2 !== null && item.link2 !== '' ?
                                                                                            <a href={item.link2} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>INTEIRO TEOR</a> :
                                                                                        null}
                                                                                    </div>
                                                                                </div>
                                                                                : null}
                                                                            
                                                                        </div>

                                                                        <div>
                                                                            {item.tipo === 10 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div className="mt-2 mb-2 Client-remove-link-notificacao">
                                                                                    <a href={`parlamentar/${item.id_parlamentar}/discursos`}>
                                                                                        <span className="d-block" style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>DISCURSOS</span>
                                                                                        <p className="Client-remove-link-notificacao"><b>{moment(item.criado_em).format('DD/MM/YYYY [-] HH[h]mm')}</b></p>
                                                                                        {/* <div className="Client-link">Carlos Zaratini, PT-SP</div>  */}
                                                                                        <div className="message Client-remove-link-notificacao">
                                                                                            {item.mensagem}
                                                                                        </div>

                                                                                    </a>
                                                                                    <a className='Client-link  p-2 d-flex' style={{color:"var(--linkText)", marginTop:"20px", fontSize:"11px" }} href={`/parlamentar/${item.id_parlamentar}/discursos`}>
                                                                                        Discurso
                                                                                    </a>
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                        <div>
                                                                            {item.tipo === 11 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div className="mt-2 mb-2 Client-remove-link-notificacao">
                                                                                    <a href={`/proposicao/${item.id_proposicao}/acao-preparatoria`}>

                                                                                        <span className="d-block" style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>RELATOR DEFINIDO</span>
                                                                                        <p className="Client-remove-link-notificacao"><b>{moment(item.criado_em).format('DD/MM/YYYY [-] HH[h]mm')}</b></p>
                                                                                        {/* <div className="Client-remove-link-notificacao"><b>Marçal</b></div>  */}
                                                                                        {/* <div className="Client-remove-link-notificacao"><b>PL6407/2013</b></div>  */}
                                                                                        <div className="message Client-remove-link-notificacao">
                                                                                            {item.mensagem}
                                                                                        </div>

                                                                                    </a>
                                                                                </div>

                                                                                : null}
                                                                        </div>
                                                                        <div>
                                                                            {item.tipo === 12 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div className="mt-2 mb-2 Client-remove-link-notificacao">
                                                                                    <a href={`parlamentar/${item.id_parlamentar}/acao-influencia`}>
                                                                                        <span className="d-block" style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>ANIVERSÁRIO</span>
                                                                                        <div className="d-flex mt-1">
                                                                                            <img className="mr-2" src={require('../../design/assets/images/aniversario-parlamentar.svg')} alt="aniversario" />
                                                                                            <div className="Client-remove-link-notificacao"><b>{moment(item.criado_em).format('DD/MM/YYYY')}</b></div>
                                                                                        </div>
                                                                                        <div className="message Client-remove-link-notificacao mt-1">{item.mensagem}</div>
                                                                                    </a>
                                                                                </div>

                                                                                : null}
                                                                        </div>
                                                                        <div>
                                                                            {item.tipo === 13 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div className="mt-2 mb-2 Client-remove-link-notificacao">
                                                                                    <a href={`parlamentar/${item.id_parlamentar}/acao-influencia`}>
                                                                                        <span className="d-block" style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>MUDANÇA DE PARTIDO</span>
                                                                                        <p className="Client-remove-link-notificacao"><b>{moment(item.criado_em).format('DD/MM/YYYY')}</b></p>
                                                                                        <div className="message Client-remove-link-notificacao"><b>{item.mensagem}</b></div>
                                                                                        <div className="d-flex mt-2">
                                                                                            <div className="message Client-remove-link-notificacao mr-2" style={{ color: '#9B9B9B', fontSize: '12px' }}>{item.link}</div>
                                                                                            <img className="mr-2" src={require('../../design/assets/images/seta-direita.svg')} alt="aniversario" />
                                                                                            <div className="message Client-remove-link-notificacao" style={{ color: '#9B9B9B', fontSize: '12px' }}>{item.link2}</div>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                                : null}
                                                                        </div>

                                                                        {/* NOTIFICACAO 14 - CITACOES*/}
                                                                        <div>
                                                                            {item.tipo === 14 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div className="mt-2 mb-2 Client-remove-link-notificacao">
                                                                                    <a href={`${item.link}`}>
                                                                                        <span className="d-block" style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>CITAÇÕES</span>
                                                                                        <div className="d-flex mt-1">
                                                                                            <div className="Client-remove-link-notificacao"><b>{moment(item.criado_em).format('DD/MM/YYYY')}</b></div>
                                                                                        </div>
                                                                                        <div className="message Client-remove-link-notificacao mt-1">{item.mensagem}</div>


                                                                                    </a>
                                                                                    {this.isJson(item.link2) && item.link2 !== null && item.link2 !== '' ?                                                                         
                                                                                        JSON.parse(item.link2).map((item, index) => {
                                                                                            return (
                                                                                                <a key={index} href={item.url} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>Link do documento</a> 
                                                                                            )
                                                                                        })
                                                                                     : null}
                                                                                </div>

                                                                                : null}
                                                                        </div>

                                                                        {/* NOTIFICACAO 15 - APENSADAS */}
                                                                        <div>
                                                                            {item.tipo === 15 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div className="mt-2 mb-2 Client-remove-link-notificacao">
                                                                                    <a href={`/proposicao/${item.id_proposicao}/apensadas`}>
                                                                                        <span className="d-block" style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>APENSADAS</span>
                                                                                        <div className="d-flex mt-1">
                                                                                            <div className="Client-remove-link-notificacao"><b>{moment(item.criado_em).format('DD/MM/YYYY')}</b></div>
                                                                                        </div>
                                                                                        <div className="message Client-remove-link-notificacao mt-1">{item.mensagem}</div>
                                                                                    </a>
                                                                                </div>

                                                                                : null}
                                                                        </div>

                                                                        {/* NOTIFICACAO 16 - Emendas */}
                                                                        <div  >
                                                                            {item.tipo === 16 && this.props.authStore.ehEsforcoColaborativo === false ?
                                                                                <div className="mt-2 mb-2 Client-remove-link-notificacao" style={{wordWrap:'break-word'}}>
                                                                                    <a href={`${item.link}`}>
                                                                                    <span className="d-block" style={{ color: '#9B9B9B', fontSize: '12px', fontWeight: 'bold' }}>EMENDAS</span>
                                                                                    <div className="d-flex mt-1">
                                                                                        <div className="Client-remove-link-notificacao"><b>{moment(item.criado_em).format('DD/MM/YYYY')}</b></div>
                                                                                    </div>
                                                                                    <div className="message Client-remove-link-notificacao mt-1">{item.mensagem}</div>
                                                                                    </a>
                                                                                </div>

                                                                                : null}
                                                                                
                                                                                {item.tipo === 16 ?
                                                                                
                                                                                this.isJson(item.link2) && Array.isArray(JSON.parse(item.link2))
                                                                                            && JSON.parse(item.link2) !== undefined ?
                                                                                            JSON.parse(item.link2).map((inteiroTeor, index) => {
                                                                                                return (
                                                                                                    inteiroTeor.url !== '' && inteiroTeor.url !== null ?
                                                                                                        <div key={index}>
                                                                                                            <a href={inteiroTeor.url} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>{inteiroTeor.descricao}</a>
                                                                                                        </div>
                                                                                                        : null
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            item.link2 !== null && item.link2 !== '' ?
                                                                                                <a href={item.link2} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>Emenda</a> :
                                                                                                null
                                                                                        : null}
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                            </div>
                                                            {this.props.authStore.ehVisualizador === true || this.props.authStore.ehEsforcoColaborativo === true ? null :
                                                                <p className="borda-notificacao-dashboard"></p>
                                                            }
                                                            <div className='footer-notification-mobile'>
                                                                <div onClick={async () => {
                                                                    await notificacaoStore.arquivar(item.id).then(async () => await reload(true));
                                                                }}>
                                                                    {this.props.authStore.ehVisualizador === true || this.props.authStore.ehEsforcoColaborativo === true ? null :
                                                                        <div>
                                                                            {item.tipo === 0 ? <span className="Client-link mr-3" style={{lineHeight:"15px"}}>Descartar</span> : null}
                                                                            {item.tipo === 1 ? <span className="Client-link mr-3" style={{lineHeight:"15px"}}>Descartar</span> : null}
                                                                            {item.tipo === 2 ? <span className="Client-link mr-3" style={{lineHeight:"15px"}}>Descartar</span> : null}
                                                                            {item.tipo === 3 ? <span className="Client-link mr-3" style={{lineHeight:"15px"}}>Descartar</span> : null}
                                                                            {item.tipo === 4 ? <span className="Client-link mr-3" style={{lineHeight:"15px"}}>Descartar</span> : null}
                                                                            { item.tipo === 6 ?
                                                                                <div>
                                                                                    <span className="Client-link mr-3" style={{lineHeight:"15px"}}>Descartar</span>
                                                                                    {item.seguindo === false ? 
                                                                                        <Seguir linkButton={true} status={item.seguindo} url={`/proposicao/${item.id_proposicao}/seguir`}></Seguir>
                                                                                    : <span className="Client-link">Seguir</span> }
                                                                                    
                                                                                    
                                                                                </div>
                                                                                : null}
                                                                            {item.tipo === 7 ? <span className="Client-link mr-3">Descartar</span> : null}
                                                                            {item.tipo === 8 ? <span className="Client-link mr-3">Descartar</span> : null}
                                                                            {item.tipo === 9 ? <span className="Client-link mr-3">Descartar</span> : null}
                                                                            {item.tipo === 10 ? <span className="Client-link mr-3">Descartar</span> : null}
                                                                            {item.tipo === 11 ? <span className="Client-link mr-3">Descartar</span> : null}
                                                                            {item.tipo === 12 ? <span className="Client-link mr-3">Descartar</span> : null}
                                                                            {item.tipo === 13 ? <span className="Client-link mr-3">Descartar</span> : null}
                                                                            {item.tipo === 14 ? <span className="Client-link mr-3">Descartar</span> : null}
                                                                            {item.tipo === 15 ? <span className="Client-link mr-3">Descartar</span> : null}
                                                                            {item.tipo === 16 ? <span className="Client-link mr-3">Descartar</span> : null}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        :

                                                        <div key={index}>
                                                            {item.tipo === 3 ?

                                                                <div className="notification fs-13" key={index}>
                                                                    <div className='notification-item'>
                                                                        <div className='row'>
                                                                            <div className='col-12 d-flex'>
                                                                                <div>
                                                                                    <div>
                                                                                        <div>
                                                                                            <p className="titulo-notificacoes">RESPONSÁVEL ESFORÇO COLABORATIVO</p>
                                                                                            <div>
                                                                                                <a href={`/proposicao/${item.id_proposicao}/acao-preparatoria`}>
                                                                                                    {item.descricao !== null ?
                                                                                                        <div className='card-descricao'>{item.descricao}</div>
                                                                                                        : null}
                                                                                                    <div className='Client-remove-link-notificacao message p-1 d-flex'>{item.mensagem}</div>
                                                                                                </a>
                                                                                                {item.link2 !== null && item.link2 !== '' ?
                                                                                                    <a href={item.link2} className='Client-link  p-2 d-flex' target='_blank' rel='noopener noreferrer'>INTEIRO TEOR</a> :
                                                                                                    null}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                 
                                                                {/* {console.log('Esforço >>>>>>',item.tipo)} */}
                                                                        <p className="borda-notificacao-dashboard"></p>
                                                                        
                                                                        <div className='footer-notification'>
                                                                            <div onClick={async () => {
                                                                                await notificacaoStore.arquivar(item.id).then(async () => await reload(true));
                                                                            }}>
                                                                            
                                                                                    <div>
                                                                                        
                                                                                        {item.tipo === 3 ? <span className="Client-link" style={{lineHeight:"15px"}}>Descartar</span> : null}
                                                                                       
                                                                                    </div>
                                                                                
                                                                            </div>
                                                                        </div>


                                                                </div>
                                                                : null}

                                                                
                                                        </div>

                                                )
                                            })
                                            : 
                                            fetching ? null : 'Nenhuma notificação.'}
                                        <div className="p-4">
                                            {fetching ? <Loading /> : null}
                                        </div>
                                    </Fragment>)
                            }
                            }
                        </QueryPaginado>
                    </div>
                </section>
            </article>
        )
    }
}

export default Notificacoes
