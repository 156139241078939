import md5 from 'md5'
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');

export const loginAuth = () => {

    const secret = 'add66c8f4ec3f79cf7636cdf21d3e1c4'
    const data = moment(new Date()).format('MM-DD')
    return md5(secret + data)
    
}