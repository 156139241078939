import React, { Component } from 'react';
// import Loading from '../../componentes/loading';
// import alertaStore from '../../stores/alertaStore';
import { get } from "../../services/http";
import { inject, observer } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import ClientSelect from '../../componentes/forms/basic/clientSelectModalCadUser'
import { post } from '../../services/http'
import AlertaStore from '../../stores/alertaStore'
import { NavLink } from 'react-router-dom'






const inputStyle = {
    border: 'none',
    marginLeft: "0px",
    backgroundColor: 'transparent',
    borderBottom: '1px solid #C4C4C4',
    color: '#000000',
    marginTop: '5px',
    width: '100%',
    outline: 'none'
};





@inject('authStore')
@observer

class Insights extends Component {
    state = {
        loading: false,
        user: JSON.parse(window.localStorage.getItem('user')),
        status: true,

        // listaGruposTemas: {},
        selectedOption: "",
        paginaAtual: 2,

        // Cadastrro de Grupo
        insertDescricaoGrupo: "",
        statusDescricaoCadastroGrupo: false,
        erroDescricaoGrupo: "",

        //Cadastro Tema
        listaGruposTema: [],
        insertDescricaoTema: "",
        statusDescricaoCadastroTema: false,
        grupoEscolhidoEmTema: "",
        erroSelectGrupoEmTema: "",

        //Cadastro Subtema
        listaGruposSubtema: [],
        insertDescricaoSubtema: "",
        statusDescricaoCadastroSubtema: false,
        grupoEscolhidoEmSubtema: [],
        erroSelectGrupoEmSubtema: "",
        temaEscolhidoEmSubtema: "",
        listaPrioridades: [],
        prioridadeSelecionada: "",
        // listaTemasEmSubtemas: []

    }


    componentWillMount() {
        // this.getDepartamentos()
        // this.getListaGrupoTemas()
        // if(this.props.authStore.ehEsforcoColaborativo === true) {
        //     window.location.href = '/'
        // }
    }


    // async getListaGrupoTemas() {
    //     await get(`/tema-proposicoes/obterGrupos?pagina=${this.state.paginaAtual}`)
    //         .then(resp => {
    //             this.setState({
    //                 listaGruposTemas: resp
    //             })
    //             console.log("resposta lista de grupos:", this.state.listaGruposTemas)
    //         })
    //         .catch(err => err)
    // }


    handleChangeStatus = async (position) => {
        if (this.state.status === position) {
            this.setState({
                status: false
            })
        } if (this.state.status !== position) {
            this.setState({
                status: true
            })
        }
    }

    renderFormGroup = async (select) => {
        // console.log("select", select)
        this.setState({
            selectedOption: select
        })

        if (select === "tema") {
            await get(`/tema-proposicoes/grupo/lista`)
                .then(res => {
                    // console.log("resposta", res)

                    const optionsdeGrupoEmTema = res.map(item => ({
                        "value": item.id,
                        "label": item.descricao
                    }))
                    this.setState({
                        listaGruposTema: optionsdeGrupoEmTema
                    })

                })
                .catch(e => {
                    console.log(e)
                })
        } if (select === "subtema") {

            await get(`/tema-proposicoes/grupo/lista`)
                .then(res => {
                    // console.log("resposta", res)

                    const optionsdeGrupoEmSubtema = res.map(item => ({
                        "value": item.id,
                        "label": item.descricao
                    }))
                    this.setState({
                        listaGruposSubtema: optionsdeGrupoEmSubtema
                    })

                })
                .catch(e => {
                    console.log(e)
                })

            await get(`/tema-proposicoes/prioridade/lista`)
                .then(res => {
                    const optionsPrioridadeEmSubtema = res.map(item => ({
                        "value": item.id,
                        "label": item.value
                    }))
                    this.setState({
                        listaPrioridades: optionsPrioridadeEmSubtema
                    })

                })
                .catch(e => {
                    console.log(e)
                })


        }

    }



    // .................CADATRO DE GRUPO................

    cadastrarGrupo = async () => {

        if (this.state.insertDescricaoGrupo === "" || this.state.insertDescricaoGrupo === null || this.state.insertDescricaoGrupo === undefined) {
            this.setState({
                erroDescricaoGrupo: "Campo vazio. Favor preencher!"
            })
        } else {

            const data = {
                data: {
                    descricao: this.state.insertDescricaoGrupo,
                    status: this.state.statusDescricaoCadastroGrupo
                }
            }

            console.log(data)
            post('/tema-proposicoes/inserirGrupo', data)
                .then(response => {
                    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>", response)
                    if (response === undefined) {
                        this.setState({
                            erroDescricaoGrupo: "Descrição do Grupo já cadastrada!"
                        })
                    } else {
                        AlertaStore.criar("Grupo cadastrado com sucesso!")
                        window.location.href = '/classificacao-tema'
                    }
                })

        }


    }

    cadastrarGrupoVarios = async () => {

        if (this.state.insertDescricaoGrupo === "" || this.state.insertDescricaoGrupo === null || this.state.insertDescricaoGrupo === undefined) {
            this.setState({
                erroDescricaoGrupo: "Campo vazio. Favor preencher!"
            })
        } else {
            // console.log("cheguei else")

            const data = {
                data: {
                    descricao: this.state.insertDescricaoGrupo,
                    status: this.state.statusDescricaoCadastroGrupo
                }
            }

            post('/tema-proposicoes/inserirGrupo', data)
                .then(response => {

                    if (response === undefined) {
                        this.setState({
                            erroDescricaoGrupo: "Descrição do Grupo já cadastrada!"
                        })
                    } else {
                        AlertaStore.criar("Grupo cadastrado com sucesso!")
                        window.location.reload()
                    }
                })

        }


    }


    handleTextoInsertDescricaoGrupo = async (descricaoTexto) => {
        this.setState({
            insertDescricaoGrupo: descricaoTexto
        })
    }

    handleStatusInsertDescricaoGrupo = async (descricaoStatus) => {
        this.setState({
            statusDescricaoCadastroGrupo: descricaoStatus
        })
    }



    // .................CADATRO DE TEMA................

    cadastrarTema = async () => {

        if (this.state.insertDescricaoTema === "" || this.state.insertDescricaoTema === null || this.state.insertDescricaoTema === undefined) {
            this.setState({
                erroDescricaoTema: "Campo vazio. Favor preencher!"
            })
        } if (this.state.grupoEscolhidoEmTema === "" || this.state.grupoEscolhidoEmTema === null || this.state.grupoEscolhidoEmTema === undefined) {
            this.setState({
                erroSelectGrupoEmTema: "Precisa selecionar um tema!"
            })
        } else if (this.state.insertDescricaoTema !== "") {
            const data = {
                data: {
                    descricao: this.state.insertDescricaoTema,
                    ativo: this.state.statusDescricaoCadastroTema,
                    id_tema_grupo: this.state.grupoEscolhidoEmTema.value

                }
            }

            // console.log("data", data)

            post('/tema-proposicoes/tema/inserir', data)
                .then(response => {
                    // console.log("response", response)
                    if (response === undefined) {
                        this.setState({
                            erroSelectGrupoEmTema: "Descrição do Tema já cadastrada!"
                        })
                    } else {
                        AlertaStore.criar("Tema cadastrado com sucesso!")
                        window.location.href = '/classificacao-tema'
                    }

                    // console.log('resposta', response)
                })

        }


    }

    cadastrarTemaVarios = async () => {

        if (this.state.insertDescricaoTema === "" || this.state.insertDescricaoTema === null || this.state.insertDescricaoTema === undefined) {
            this.setState({
                erroDescricaoTema: "Campo vazio. Favor preencher!"
            })
        } if (this.state.grupoEscolhidoEmTema === "" || this.state.grupoEscolhidoEmTema === null || this.state.grupoEscolhidoEmTema === undefined) {
            this.setState({
                erroSelectGrupoEmTema: "Precisa selecionar um tema!"
            })
        } else if (this.state.insertDescricaoTema !== "") {
            const data = {
                data: {
                    descricao: this.state.insertDescricaoTema,
                    ativo: this.state.statusDescricaoCadastroTema,
                    id_tema_grupo: this.state.grupoEscolhidoEmTema.value

                }
            }

            console.log("data", data)

            post('/tema-proposicoes/tema/inserir', data)
                .then(response => {
                    console.log("response", response)
                    if (response === undefined) {
                        this.setState({
                            erroSelectGrupoEmTema: "Descrição do Tema já cadastrada!"
                        })
                    } else {
                        AlertaStore.criar("Tema cadastrado com sucesso!")
                        window.location.reload()
                    }
                })

        }


    }


    handleTextoInsertDescricaoTema = async (descricaoTextoTema) => {
        this.setState({
            insertDescricaoTema: descricaoTextoTema
        })
    }

    handleStatusInsertDescricaoTema = async (descricaoStatusTema) => {
        this.setState({
            statusDescricaoCadastroTema: descricaoStatusTema
        })
    }

    changeSelectGrupoEmTema(grupoSelecionado) {
        this.setState({
            grupoEscolhidoEmTema: grupoSelecionado,
        })
    }




    // .................CADATRO DE SUBTEMA................

    cadastrarSubtema = async () => {

        if (this.state.insertDescricaoSubtema === "" || this.state.insertDescricaoSubtema === null || this.state.insertDescricaoSubtema === undefined) {
            this.setState({
                erroDescricaoSubtema: "Campo vazio. Favor preencher!"
            })
            console.log("Descricao")
        } if (this.state.prioridadeSelecionada === "" || this.state.prioridadeSelecionada === null || this.state.prioridadeSelecionada === undefined) {
            this.setState({
                erroEscolhaPrioridade: "Campo vazio. Favor preencher!"
            })
            console.log("Prioridade")
        } if (this.state.temaEscolhidoEmSubtema === "" || this.state.temaEscolhidoEmSubtema === null || this.state.temaEscolhidoEmSubtema === undefined) {
            this.setState({
                erroSelectTemaEmSubtema: "Precisa selecionar um tema!"
            })
            console.log("Tema")
        } if (this.state.grupoEscolhidoEmSubtema.length === 0 || this.state.grupoEscolhidoEmSubtema === null || this.state.grupoEscolhidoEmSubtema === undefined) {
            this.setState({
                erroSelectGrupoEmSubtema: "Precisa selecionar um Grupo!"
            })
            console.log("Grupo")
        } else if (this.state.insertDescricaoSubtema !== "") {

            const data = {
                data: {
                    id_prioridade: this.state.prioridadeSelecionada.value,
                    descricao: this.state.insertDescricaoSubtema,
                    status: this.state.statusDescricaoCadastroSubtema,
                    id_tema: this.state.temaEscolhidoEmSubtema.value,
                }
            }
            console.log(data)
            post('/tema-proposicoes/subtema/inserir', data)
                .then(response => {
                    console.log("response", response)
                    if (response === undefined) {
                        this.setState({
                            erroSelectGrupoEmTema: "Descrição do Subtema já cadastrada!"
                        })
                    } else {
                        AlertaStore.criar("Subtema cadastrado com sucesso!")
                        window.location.href = '/classificacao-tema'
                    }

                    // console.log('resposta', response)
                })

        }




    }

    cadastrarSubtemaVarios = async () => {

        if (this.state.insertDescricaoSubtema === "" || this.state.insertDescricaoSubtema === null || this.state.insertDescricaoSubtema === undefined) {
            this.setState({
                erroDescricaoSubtema: "Campo vazio. Favor preencher!"
            })
            console.log("Descricao")
        } if (this.state.prioridadeSelecionada === "" || this.state.prioridadeSelecionada === null || this.state.prioridadeSelecionada === undefined) {
            this.setState({
                erroEscolhaPrioridade: "Campo vazio. Favor preencher!"
            })
            console.log("Prioridade")
        } if (this.state.temaEscolhidoEmSubtema === "" || this.state.temaEscolhidoEmSubtema === null || this.state.temaEscolhidoEmSubtema === undefined) {
            this.setState({
                erroSelectTemaEmSubtema: "Precisa selecionar um tema!"
            })
            console.log("Tema")
        } if (this.state.grupoEscolhidoEmSubtema.length === 0 || this.state.grupoEscolhidoEmSubtema === null || this.state.grupoEscolhidoEmSubtema === undefined) {
            this.setState({
                erroSelectGrupoEmSubtema: "Precisa selecionar um Grupo!"
            })
            console.log("Grupo")
        } else if (this.state.insertDescricaoSubtema !== "") {

            const data = {
                data: {
                    id_prioridade: this.state.prioridadeSelecionada.value,
                    descricao: this.state.insertDescricaoSubtema,
                    status: this.state.statusDescricaoCadastroSubtema,
                    id_tema: this.state.temaEscolhidoEmSubtema.value,
                }
            }


            post('/tema-proposicoes/subtema/inserir', data)
                .then(response => {
                    console.log("response", response)
                    if (response === undefined) {
                        this.setState({
                            erroSelectGrupoEmTema: "Descrição do Subtema já cadastrada!"
                        })
                    } else {
                        AlertaStore.criar("Subtema cadastrado com sucesso!")
                        window.location.reload()
                    }
                })

        }


    }


    handleTextoInsertDescricaoSubtema = async (descricaoTextoSubtema) => {
        this.setState({
            insertDescricaoSubtema: descricaoTextoSubtema
        })
    }

    handleStatusInsertDescricaoSubtema = async (descricaoStatusSubtema) => {
        this.setState({
            statusDescricaoCadastroSubtema: descricaoStatusSubtema
        })
    }

    changeSelectPrioridadeEmSubtema = (itemSelecionado) => {
        this.setState({
            prioridadeSelecionada: itemSelecionado,
        })
    }

    changeSelectemaEmSubtema = (temaSelecionado) => {
        // console.log("input", temaSelecionado)
        this.setState({
            temaEscolhidoEmSubtema: temaSelecionado,
        })
    }



    changeSelectGrupoEmSubtema = async (grupoSelecionado) => {
        this.setState({
            grupoEscolhidoEmSubtema: grupoSelecionado,
        })

        const data = {
            data: {
                idGrupo: grupoSelecionado.value
            }
        }

        // console.log("data id do grupo pra puxar tema", data)

        await post('/tema-proposicoes/tema/lista/grupoId', data)
            .then(async res => {
                // console.log("resposta lista tema", res)

                const optionsTemasEmSubtema = await res.map(item => ({
                    "value": item.id,
                    "label": item.descricao
                }))

                const erroListaVaziaTemas = [{
                    "value": 0,
                    "label": "Não existe temas cadastrados no grupo"
                }]


                if (optionsTemasEmSubtema.length === 0) {
                    this.setState({
                        listaTemasEmSubtemas: erroListaVaziaTemas
                    })
                } else {
                    this.setState({
                        listaTemasEmSubtemas: optionsTemasEmSubtema
                    })
                }




            })
            .catch(e => {
                console.log(e)
            })

    }



    render() {

        // console.log("select", this.state.selectedOption)
        // console.log("ValueDescricao", this.state.insertDescricaoGrupo)
        // console.log("status", this.state.statusDescricaoCadastro)
        // console.log("grupoSubtemaEscolhido", this.state.grupoEscolhidoEmSubtema)
        // console.log("options", this.state.listaTemasEmSubtemas)
        // console.log("IdDo Tema", this.state.temaEscolhidoEmSubtema.value)
        // console.log("listaPrioridades", this.state.listaPrioridades)
        // console.log("oppp", this.state.oppp)




        const StatusSwitch = withStyles({
            switchBase: {
                color: '#FFF',
                '&$checked': {
                    color: '#4CD964',
                },
                '&$checked + $track': {
                    backgroundColor: '#4CD964',
                },
            },
            checked: {},
            track: {},
        })(Switch);

        // var permissao = ''
        // switch (this.state.user.codigoPermissao) {
        //     case 1:
        //         permissao = 'Master';
        //         break;
        //     case 2:
        //         permissao = 'Normal';
        //         break;
        //     case 3:
        //         permissao = 'Read Only';
        //         break;
        //     case 4:
        //         permissao = 'Esforço Colaborativo';
        //         break;
        //     default:
        //         console.log('permissao');
        // }

        //console.log(this.state.usuarioDepartamento)

        return (
            <div style={{ backgroundColor: "#f6f6f6", height: "100%" }}>
                <div className=" bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                    <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                        CADASTRAR NOVA CLASSIFICAÇÃO
                        </p>
                </div>


                <div className="container">



                    {/* ................head.................... */}
                    <div className='row' style={{ width: "100%" }}>
                        <div className='col-12 col-12'>
                            <p style={{ fontSize: "24px", fontWeight: "bold", paddingTop: "30px", color: "#1E0736" }}>
                                Nova Classificação
                        </p>
                        </div>
                    </div>


                    {/* ................Background White.................... */}
                    <div className='bg-white container' style={{ paddingLeft: "40px", paddingRight: "40px", paddingTop: "25px", paddingBottom: "25px" }}>

                        <div className="text-center" style={{ marginBottom: "20px", fontSize: "22px" }}>
                            Selecione o Tipo
                        </div>



                        {/* ................Região Cinza Radio Button.................... */}

                        <div className="row text-center" style={{ backgroundColor: "#ECECEC", padding: "20px", borderRadius: "4px" }}>

                            <div className="col-12 col-md-4" style={{ fontSize: "16px" }}>
                                <input type="radio" value="grupo" name="classificacao" onClick={() => this.renderFormGroup("grupo")} /> Grupo
                            </div>

                            <div className="col-12 col-md-4" style={{ fontSize: "16px" }}>
                                <input type="radio" value="tema" name="classificacao" onClick={() => this.renderFormGroup("tema")} /> Tema
                            </div>

                            <div className="col-12 col-md-4" style={{ fontSize: "16px" }}>
                                <input type="radio" value="subtema" name="classificacao" onClick={() => this.renderFormGroup("subtema")} /> Subtema
                            </div>

                        </div>



                        {/* ................Formulários.................... */}

                        {
                            this.state.selectedOption === "" ?
                                <div className="text-center" style={{ marginTop: "50px" }}>
                                    <h4>Selecione o Tipo de classificação que deseja cadastrar.</h4>
                                </div>
                                :



                                // .......................GRUPO....................

                                this.state.selectedOption === "grupo" ?
                                    <div style={{ marginTop: "50px" }}>
                                        <div style={{ fontSize: "20px", width: "100%" }}>
                                            Informações Gerais
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mt-3" >
                                                <label style={{ color: "var(--linkText)" }}>Descrição</label>
                                                <input
                                                    value={this.state.insertDescricaoGrupo}
                                                    onChange={(e) => this.handleTextoInsertDescricaoGrupo(e.target.value)}
                                                    name={'DescriçãoGrupo'}
                                                    style={inputStyle}
                                                    type="text"
                                                    placeholder="Digite o nome desejado"
                                                    autoComplete="off"
                                                    maxLength="40"

                                                />

                                                <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{this.state.erroDescricaoGrupo}</span>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div>
                                                    <label style={{ color: "var(--linkText)" }}>Status</label>
                                                </div>
                                                <div className="row">
                                                    <div>
                                                        <StatusSwitch
                                                            checked={this.state.statusDescricaoCadastroGrupo}
                                                            onChange={(e) => this.handleStatusInsertDescricaoGrupo(e.target.checked)}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    </div>
                                                    <div style={{ alignItems: "center", display: "flex" }}>
                                                        {
                                                            this.state.statusDescricaoCadastroGrupo === true ?
                                                                "Ativo"
                                                                :
                                                                "Inativo"
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    :

                                    // .......................TEMA....................

                                    this.state.selectedOption === "tema" ?
                                        <div style={{ marginTop: "50px" }}>
                                            <div style={{ fontSize: "20px", width: "100%" }}>
                                                Informações Gerais
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mt-3" >
                                                    <label style={{ color: "var(--linkText)" }}>Descrição</label>
                                                    <input
                                                        value={this.state.insertDescricaoTema}
                                                        onChange={(e) => this.handleTextoInsertDescricaoTema(e.target.value)}
                                                        name={'DescriçãoTema'}
                                                        style={inputStyle}
                                                        type="text"
                                                        placeholder="Digite o nome desejado"
                                                        autoComplete="off"
                                                        maxLength="40"

                                                    />

                                                    <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{this.state.erroDescricaoTema}</span>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div>
                                                        <label style={{ color: "var(--linkText)" }}>Status</label>
                                                    </div>
                                                    <div className="row">
                                                        <div>
                                                            <StatusSwitch
                                                                checked={this.state.statusDescricaoCadastroTema}
                                                                onChange={(e) => this.handleStatusInsertDescricaoTema(e.target.checked)}
                                                                name="checkedB"
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            />
                                                        </div>
                                                        <div style={{ alignItems: "center", display: "flex" }}>
                                                            {
                                                                this.state.statusDescricaoCadastroTema === true ?
                                                                    "Ativo"
                                                                    :
                                                                    "Inativo"
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mt-3" >
                                                    <label style={{ color: "var(--linkText)" }}>Associar à:</label>
                                                    <ClientSelect
                                                        css="no-border"
                                                        name="listaGruposTema"
                                                        value={this.state.grupoEscolhidoEmTema}
                                                        options={this.state.listaGruposTema}
                                                        onChange={(newValue) => this.changeSelectGrupoEmTema(newValue)}
                                                        placeholder={"Grupo"}
                                                    />
                                                    <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{this.state.erroSelectGrupoEmTema}</span>
                                                </div>
                                            </div>



                                        </div>
                                        :



                                        // .......................SUBTEMA....................

                                        <div style={{ marginTop: "50px" }}>
                                            <div style={{ fontSize: "20px", width: "100%" }}>
                                                Informações Gerais
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mt-3" style={{ paddingLeft: "0px" }}>
                                                    <div className="col-12" style={{ marginTop: "6px" }}>
                                                        <label style={{ color: "var(--linkText)" }}>Descrição</label>
                                                        <input
                                                            value={this.state.insertDescricaoSubtema}
                                                            onChange={(e) => this.handleTextoInsertDescricaoSubtema(e.target.value)}
                                                            name={'DescriçãoSubtema'}
                                                            style={inputStyle}
                                                            type="text"
                                                            placeholder="Digite o nome desejado"
                                                            autoComplete="off"
                                                            maxLength="40"

                                                        />

                                                        <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{this.state.erroDescricaoSubtema}</span>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <div>
                                                            <label style={{ color: "var(--linkText)" }}>Status</label>
                                                        </div>
                                                        <div className="row">
                                                            <div>
                                                                <StatusSwitch
                                                                    checked={this.state.statusDescricaoCadastroSubtema}
                                                                    onChange={(e) => this.handleStatusInsertDescricaoSubtema(e.target.checked)}
                                                                    name="checkedC"
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                            </div>
                                                            <div style={{ alignItems: "center", display: "flex" }}>
                                                                {
                                                                    this.state.statusDescricaoCadastroSubtema === true ?
                                                                        "Ativo"
                                                                        :
                                                                        "Inativo"
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3" >
                                                    <label style={{ color: "var(--linkText)" }}>Prioridade</label>
                                                    <ClientSelect
                                                        css="no-border"
                                                        name="prioridade"
                                                        value={this.state.prioridadeSelecionada}
                                                        options={this.state.listaPrioridades}
                                                        onChange={(newValue) => this.changeSelectPrioridadeEmSubtema(newValue)}
                                                        placeholder={"Selecionar"}
                                                    />
                                                    <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{this.state.erroEscolhaPrioridade}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mt-3" >
                                                    <label style={{ color: "var(--linkText)" }}>Associar à:</label>
                                                    <ClientSelect
                                                        css="no-border"
                                                        name="listaGruposSubtema"
                                                        value={this.state.grupoEscolhidoEmSubtema}
                                                        options={this.state.listaGruposSubtema}
                                                        onChange={(newValue) => this.changeSelectGrupoEmSubtema(newValue)}
                                                        placeholder={"Grupo"}
                                                    />
                                                    <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{this.state.erroSelectGrupoEmSubtema}</span>
                                                </div>

                                                {this.state.grupoEscolhidoEmSubtema.length !== 0 ?
                                                    <div className="col-md-6 mt-3" >
                                                        <label style={{ color: "var(--linkText)" }}>tema</label>
                                                        <ClientSelect
                                                            css="no-border"
                                                            name="tema"
                                                            value={this.state.TemaEscolhidoEmSubtema}
                                                            options={this.state.listaTemasEmSubtemas}
                                                            onChange={(newValue) => this.changeSelectemaEmSubtema(newValue)}
                                                            placeholder={"Selecionar"}
                                                        />
                                                        <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{this.state.erroSelectTemaEmSubtema}</span>
                                                    </div>
                                                    :
                                                    null
                                                }

                                            </div>



                                        </div>
                        }

                    </div>


                    {/* ................Região de Botões.................... */}

                    {
                        this.state.selectedOption === "" ?
                            null
                            :
                            this.state.selectedOption === "grupo" ?

                                // ...................Botoes Grupo................
                                <div className="container">
                                    <div className="row" style={{ float: "right" }}>
                                        <NavLink to={`/classificacao-tema`}>
                                            <div className="cursor-pointer" style={{ padding: "10px", color: "var(--botaoPesquisar)", marginTop: "35px" }}>
                                                Cancelar
                                                </div>
                                        </NavLink>
                                        <div style={{ padding: "10px" }}>
                                            <button type="button" onClick={() => this.cadastrarGrupo()} className="btn" style={{ width: "100%", marginTop: "20px", padding: "15px", backgroundColor: "white", color: "var(--botaoPesquisar)", border: "1px solid var(--botaoPesquisar)", fontWeight: "lighter" }} variant="outlined" >
                                                <img
                                                    className="mr-2"
                                                    style={{ width: "20px", marginTop: "-3px" }}
                                                    src={require('../../design/assets/images/diskSaveWhite.svg')}
                                                    alt="btn-pesquisar"
                                                />{' '}
                                                    Salvar
                                                </button>
                                        </div>
                                        <div style={{ padding: "10px" }}>
                                            <button type="button" onClick={() => this.cadastrarGrupoVarios()} className="btn" style={{ width: "100%", marginTop: "20px", padding: "15px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter" }} variant="outlined" >
                                                <img
                                                    className="mr-2"
                                                    style={{ width: "20px", marginTop: "-3px" }}
                                                    src={require('../../design/assets/images/adicionar.svg')}
                                                    alt="btn-pesquisar"
                                                />{' '}
                                                Salvar e Cadastrar Outra
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                :

                                this.state.selectedOption === "tema" ?


                                    // ...................Botoes Tema................
                                    <div className="container">
                                        <div className="row" style={{ float: "right" }}>
                                            <NavLink to={`/classificacao-tema`}>
                                                <div className="cursor-pointer" style={{ padding: "10px", color: "var(--botaoPesquisar)", marginTop: "35px" }}>
                                                    Cancelar
                                                </div>
                                            </NavLink>
                                            <div style={{ padding: "10px" }}>
                                                <button type="button" onClick={() => this.cadastrarTema()} className="btn" style={{ width: "100%", marginTop: "20px", padding: "15px", backgroundColor: "white", color: "var(--botaoPesquisar)", border: "1px solid var(--botaoPesquisar)", fontWeight: "lighter" }} variant="outlined" >
                                                    <img
                                                        className="mr-2"
                                                        style={{ width: "20px", marginTop: "-3px" }}
                                                        src={require('../../design/assets/images/diskSaveWhite.svg')}
                                                        alt="btn-pesquisar"
                                                    />{' '}
                                                Salvar
                                            </button>
                                            </div>
                                            <div style={{ padding: "10px" }}>
                                                <button type="button" onClick={() => this.cadastrarTemaVarios()} className="btn" style={{ width: "100%", marginTop: "20px", padding: "15px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter" }} variant="outlined" >
                                                    <img
                                                        className="mr-2"
                                                        style={{ width: "20px", marginTop: "-3px" }}
                                                        src={require('../../design/assets/images/adicionar.svg')}
                                                        alt="btn-pesquisar"
                                                    />{' '}
                                                Salvar e Cadastrar Outra
                                            </button>
                                            </div>
                                        </div>
                                    </div>

                                    :

                                    // ...................Botoes Subtema................
                                    <div className="container">
                                        <div className="row" style={{ float: "right" }}>
                                            <NavLink to={`/classificacao-tema`}>
                                                <div className="cursor-pointer" style={{ padding: "10px", color: "var(--botaoPesquisar)", marginTop: "35px" }}>
                                                    Cancelar
                                                </div>
                                            </NavLink>
                                            <div style={{ padding: "10px" }}>
                                                <button type="button" onClick={() => this.cadastrarSubtema()} className="btn" style={{ width: "100%", marginTop: "20px", padding: "15px", backgroundColor: "white", color: "var(--botaoPesquisar)", border: "1px solid var(--botaoPesquisar)", fontWeight: "lighter" }} variant="outlined" >
                                                    <img
                                                        className="mr-2"
                                                        style={{ width: "20px", marginTop: "-3px" }}
                                                        src={require('../../design/assets/images/diskSaveWhite.svg')}
                                                        alt="btn-pesquisar"
                                                    />{' '}
                                                Salvar
                                            </button>
                                            </div>
                                            <div style={{ padding: "10px" }}>
                                                <button type="button" onClick={() => this.cadastrarSubtemaVarios()} className="btn" style={{ width: "100%", marginTop: "20px", padding: "15px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter" }} variant="outlined" >
                                                    <img
                                                        className="mr-2"
                                                        style={{ width: "20px", marginTop: "-3px" }}
                                                        src={require('../../design/assets/images/adicionar.svg')}
                                                        alt="btn-pesquisar"
                                                    />{' '}
                                                Salvar e Cadastrar Outra
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                    }
                </div>




            </div>
        )
    }
}

export default Insights