import React, { Component } from 'react'
import moment from 'moment-timezone';
import 'moment/locale/pt-br'
import {put, post } from "../../services/http";
import { inject, observer } from 'mobx-react'
import Loading from '../../componentes/loading'
import ConfirmModal from '../../componentes/confirmModal';
import EditModal from '../../componentes/editModal';
// import EditModalMobile from '../../componentes/editModalMobile';
import AcontecimentosNovoComentarioParlamentar from "../../componentes/acontecimentos/acontecimentosNovoComentarioParlamentar";
import UploadFile from '../../componentes/forms/inputs/upload'
import UploadFileMobile from '../../componentes/forms/inputs/uploadMobile'

// DE ACORDO COM O REGIME DE TRAMITAÇÃO MOSTRAR MOMENTOS CRITICOS
// CONCLUSIVO MOSTRAR OS 4 MOMENTOS CRÍTICOS

moment.tz.setDefault('UTC');
moment.locale('pt-BR');

@inject('uploadStore', 'authStore', 'alertaStore')
@observer

class Acontecimentos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            timeline: [],
            usuarios: [],
            acontecimento: [],
            anexos: [],
            idParlamentar: 0,
            arquivo: [],
            showModal: false,
            showEdit: false,
            id: "",
            itemToDelete: {},
            itemToEdit: {}
        }
    }

    changeShowModalState(showModal, item) {
        this.setState({ showModal: showModal, showEdit: false, itemToDelete: item })
    }

    editModal(showEdit, item) {
        // console.log("Desk")
        // this.setState({ id: "#exampleModal", itemToEdit: item })
        this.setState({ showModal: false, showEdit: showEdit, itemToEdit: item })
    }

    editModalMobile(showEdit, item) {
        // this.setState({id:"#exampleModal", itemToEdit: item })
        this.setState({ showModal: false, showEdit: showEdit, itemToEdit: item })

    }

    async componentWillMount() {
        // await this.setState({ idParlamentar: this.props.idProposicao })

        // if(this.props.authStore.ehEsforcoColaborativo === true) {
        //   window.location.href = `/proposicao/${this.props.idProposicao}/acao-preparatoria`
        // }

        this.getDadosAcontecimento()
    }

    async excluir(show, save, item) {
        if (save) {
            await put(`/acontecimentos/${this.state.itemToDelete.id}`).then(() => {
                this.setState({ showModal: false })
                this.getDadosAcontecimento(this.state.idParlamentar)
                this.props.alertaStore.criar('Registro de atividade foi excluído com sucesso.');
            });
        }
    }

    edit(show, save, item) {
        if (save) {
            this.setState({ showEdit: false })
            this.getDadosAcontecimento(this.state.idParlamentar)
        }
    }

    async getDadosAcontecimento() {
        const data = {modulo:'acontecimentos-parlamentar'}
        await post(`/parlamentar/acontecimentos/${this.props.idParlamentar}`, data)
            .then(response => {
                console.log('response.anexos',response)
                this.setState({
                    acontecimento: response.acontecimentos,
                    anexos: response.anexos,
                    loading: false
                })
            }
            )
    }

    reRender() {
        setTimeout(() => {
            this.getDadosAcontecimento()
        }, 500);
    }


    render() {
        // const user = JSON.parse(window.localStorage.getItem('user'))
        // const userLogado = user.id
        const telaTamanho = window.innerWidth


        return this.state.loading ? <Loading /> :


            <div>


                {telaTamanho <= 600



                    ?

                    <div>



                        {/* >>>>>>>>>>>>>>>>>>>>>>>> MOBILE <<<<<<<<<<<<<<<<<<< */}


                        <ConfirmModal
                            text={'Tem certeza que deseja excluir o registro de atividade?'}
                            description={'Esta ação não poderá ser desfeita no futuro'}
                            onClose={(show, save, item) => this.excluir(show, save, item)}
                            show={this.state.showModal}
                            reloadKey={true}

                        />
                        <EditModal
                            text={'Tem certeza que deseja excluir o registro de atividade?'}
                            description={'Esta ação não poderá ser desfeita no futuro'}
                            onClose={(show, save, item) => this.edit(show, save, item)}
                            show={this.state.showEdit}
                            edit={this.state.itemToEdit}
                            anexos={this.state.anexos}
                        />
                        <div className="novo-acontecimento">
                            <div className="container-fluid bg-white mb-2 p-0" style={{ border: "1px solid lightgray" }}>
                                <div className="bg-gray-600 p-3">
                                    <h5 className="text-white m-0">Registro de Atividade1</h5>
                                </div>
                                <AcontecimentosNovoComentarioParlamentar
                                    idParlamentar={this.props.idParlamentar}
                                />
                            </div>
                        </div>


                        {/* ----- Cards ----- */}

                        <div className="acontecimentos">
                            <div>
                                <div>
                                    {this.state.acontecimento !== undefined && Array.isArray(this.state.acontecimento)
                                        && this.state.acontecimento.length > 0 ? this.state.acontecimento.map((item, index) => {
                                            return (
                                                <div className="mt-3 mb-3 ml-2 mr-2" >
                                                    <div className="row p-3" style={{ backgroundColor: "white", border: "1px solid lightgray", borderRadius: "7px" }}>


                                                        <div className="col-12 nome-relator">
                                                            <div className="row">
                                                                <div className="col-10" style={{ marginLeft: "-14px" }}>
                                                                    {item.nome.replace('.', ' ').toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase() })}
                                                                    <div style={{ fontSize: "12px" }}>{moment(item.data_acontecimento).format('DD/MM/YYYY')}</div>
                                                                </div>
                                                                <div class="dropleft col-2" >
                                                                    <button class="btn btn-white" style={{ border: "1px solid lightgray" }} type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-three-dots-vertical" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                        </svg>
                                                                    </button>
                                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                        <button style={{ marginTop: "5px" }} onClick={() => this.editModal(true, item)} class="dropdown-item" type="button">
                                                                            <span style={{ fontSize: "14px" }}><img className='' src={require('../../design/assets/images/Vector.svg')} alt="" /><span style={{ marginTop: "10px", marginLeft: "10px", fontSize: "13px" }}>Editar</span></span>
                                                                        </button>
                                                                        <button class="dropdown-item" type="button">
                                                                            <div className="row" style={{ marginTop: "5px" }}>
                                                                                <div>
                                                                                    <img style={{ width: "9px", marginTop: "-3px", marginLeft: "10px", marginRight: "12px" }} src={require('../../design/assets/images/Mask.svg')} alt="excluir anexo delete-anexo" />
                                                                                </div>
                                                                                <div>
                                                                                    <UploadFileMobile linkButton={true} modulo={'acontecimentos'} id_referencia={item.id} onChange={anexo => this.setState({ anexos: [...this.state.anexos, ...anexo], showEdit: false })} />
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                        <button onClick={() => this.changeShowModalState(true, item)} class="dropdown-item" type="button">
                                                                            <span style={{ fontSize: "14px" }}><img style={{ width: "12px", marginTop: "-3px" }} src={require('../../design/assets/images/Trash.svg')} alt="excluir anexo delete-anexo" /><span style={{ marginTop: "10px", marginLeft: "12px", fontSize: "13px" }}>Deletar</span></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* <span>
                                                                        {this.props.authStore.ehVisualizador === true ? null :
                                                                            <span>
                                                                                <span className="ml-3 parlamentarEspecificoDesk d-none d-sm-block" onClick={() => this.changeShowModalState(true, item)}>
                                                                                    <img src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                                </span>
                                                                                <span className="ml-3 parlamentarEspecificoMobile" onClick={() => this.changeShowModalState(true, item)}>
                                                                                    <img src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                                </span>
                                                                                <span className="ml-3 parlamentarEspecificoDesk d-none d-sm-block" onClick={() => this.editModal(true, item)}>
                                                                                    <img className='' src={require('../../../design/assets/images/editar-gray.svg')} alt="" />
                                                                                </span>
                                                                                <span className="ml-3 parlamentarEspecificoMobile" onClick={() => this.editModalMobile(true, item)}>
                                                                                    <img className='' src={require('../../../design/assets/images/editar-gray.svg')} alt="" />
                                                                                </span>
                                                                            </span>
                                                                        }
                                                                    </span> */}
                                                        </div>

                                                        <textarea name="acontecimento-card" id="acontecimento-card" className="mt-3 acontecimento-texto" rows="3" value={`${item.acontecimento.replace(/<(?!!br\s*\/?)[^>]+>/g, '').replace(/&nbsp;/g, '')}`} readonly></textarea >

                                                        {this.state.anexos !== undefined && this.state.anexos.length > 0 ?
                                                            this.state.anexos.map((anexo, index) => {
                                                                return (
                                                                    anexo.modulo === 'acontecimentos-parlamentar' && anexo.id_referencia === item.id ?
                                                                        <div key={index} className="col-md-6 mt-3 d-flex">
                                                                            <div className="anexo-linha">
                                                                                <div className="row">
                                                                                    <div className="col-2" onClick={() => this.props.uploadStore.downloadArquivo(anexo, this.refs).then(x => console.log(x))}>
                                                                                        {/* <img src={require('../../../design/assets/images/clips-red.svg')} alt="anexo" /> */}
                                                                                        <div style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                                                    </div>
                                                                                    <div className="col-8 text-start" style={{ marginLeft: "-30px" }}>
                                                                                        {anexo.nome.substr(0, 18)}...
                                                                                    </div>
                                                                                    {this.props.authStore.ehVisualizador === true ? null :
                                                                                        <div className="col-2 text-center delete-anexo">
                                                                                            <img onClick={() => {
                                                                                                const itemRef = { nome: anexo.nome, id_referencia: anexo.id_referencia }
                                                                                                this.props.uploadStore.removerAnexoSemId(itemRef)
                                                                                                this.props.uploadStore.loading = false;
                                                                                                const array = this.state.anexos
                                                                                                array.splice(index, 1)
                                                                                                this.setState({ anexos: array })
                                                                                            }} src={require('../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                )
                                                            })
                                                            : null}

                                                    </div>
                                                </div>
                                                // <div key={index} className="m-3" style={{ background: 'white' }}>
                                                //     <div className="row p-3">
                                                //         <div className="col-8 nome-relator">
                                                //             {item.nome.replace('.', ' ').toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase() })}
                                                //             {userLogado === item.id_usuario ?
                                                //                 <span>
                                                //                     {this.props.authStore.ehVisualizador === true ? null :
                                                //                         <span>
                                                //                             <span className="ml-3" onClick={() => this.changeShowModalState(true, item)}>
                                                //                                 <img src={require('../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                //                             </span>
                                                //                             <span className="ml-3" onClick={() => this.editModal(true, item)}>
                                                //                                 <img className='' src={require('../../design/assets/images/editar-gray.svg')} alt="" />
                                                //                             </span>
                                                //                         </span>
                                                //                     }
                                                //                 </span>
                                                //                 : null}
                                                //         </div>
                                                //         <div className="col-4 text-right">
                                                //             <div className="tramitacao-data" style={{ fontSize: "15px" }}>{moment(item.data_acontecimento).format('DD/MM/YYYY')}</div>
                                                //         </div>
                                                //         <textarea name="acontecimento-card" id="acontecimento-card" className="mt-3 p-2 acontecimento-texto" rows="6" value={`${item.acontecimento.replace(/<(?!!br\s*\/?)[^>]+>/g, '').replace(/&nbsp;/g, '')}`} readOnly></textarea>

                                                //         <div className="col-12">
                                                //             <UploadFile linkButton={true} modulo={'acontecimentos'} id_referencia={item.id} onChange={anexo => this.setState({ anexos: [...this.state.anexos, ...anexo], showEdit: false })} />
                                                //         </div>

                                                //         {this.state.anexos !== undefined && this.state.anexos.length > 0 ?
                                                //             this.state.anexos.map((anexo, index) => {
                                                //                 return (
                                                //                     anexo.modulo === 'acontecimentos' && anexo.id_referencia === item.id ?
                                                //                         <div key={index} className="col-md-6 mt-3 d-flex">

                                                //                             <div className="anexo-linha">
                                                //                                 <div className="row">
                                                //                                     <div className="col-10" onClick={() => this.props.uploadStore.downloadArquivo(anexo, this.refs).then(x => console.log(x))}>
                                                //                                         <div className="row">
                                                //                                             <div className=" ml-2" style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                //                                             <div className="pl-2">
                                                //                                                 {' '}{anexo.nome.substr(0, 18)}...
                                                //                                             </div>
                                                //                                         </div>
                                                //                                     </div>
                                                //                                     {this.props.authStore.ehVisualizador === true ? null :
                                                //                                         <div className="col-2 text-center delete-anexo" >
                                                //                                             <img onClick={() => {
                                                //                                                 this.props.uploadStore.removerAnexo(anexo);
                                                //                                                 const array = this.state.anexos
                                                //                                                 array.splice(index, 1)
                                                //                                                 this.setState({ anexos: array })
                                                //                                             }} src={require('../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                //                                         </div>
                                                //                                     }
                                                //                                 </div>
                                                //                             </div>
                                                //                         </div>
                                                //                         : null
                                                //                 )
                                                //             })
                                                //             : null}

                                                //     </div>
                                                // </div>
                                            )
                                        }) : null}
                                </div>
                            </div>
                        </div>

                    </div>

                    :

                    <div>

                        {/* >>>>>>>>>>>>>>>>>>>>>>>> DESK <<<<<<<<<<<<<<<<<<< */}

                        <ConfirmModal
                            text={'Tem certeza que deseja excluir o registro de atividade?'}
                            description={'Esta ação não poderá ser desfeita no futuro'}
                            onClose={(show, save, item) => this.excluir(show, save, item)}
                            show={this.state.showModal}
                            reloadKey={true}
                        />
                        <EditModal
                            text={'Tem certeza que deseja excluir o registro de atividade?'}
                            description={'Esta ação não poderá ser desfeita no futuro'}
                            onClose={(show, save, item) => this.edit(show, save, item)}
                            show={this.state.showEdit}
                            id={this.state.id}
                            edit={this.state.itemToEdit}
                            anexos={this.state.anexos}
                            origin={'parlamentar'}
                            idParlamentar={this.props.idParlamentar}
                        />
                        <div className=" m-1">
                            <div className="container-fluid bg-white mb-2 p-0" style={{ border: "1px solid lightgray" }}>
                                <div className="bg-gray-600 p-3">
                                    <h5 className="text-white m-0">Registro de Atividade2</h5>
                                </div>
                                <AcontecimentosNovoComentarioParlamentar idParlamentar={this.props.idParlamentar} />
                            </div>
                        </div>
                        {/* ----- Cards ----- */}

                        <div className="acontecimentos">
                            <div>
                                <div>
                                    {this.state.acontecimento !== undefined && Array.isArray(this.state.acontecimento)
                                        && this.state.acontecimento.length > 0 ? this.state.acontecimento.map((item, index) => {
                                            return (
                                                // <div key={index} className="m-1 " style={{ backgroundColor: "white", border: "1px solid lightgray", borderRadius: "7px" }}>
                                                //     <div className="row p-3">
                                                //         <div className="col-8 nome-relator">
                                                //             {item.nome.replace('.', ' ').toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase() })}
                                                //             {userLogado === item.id_usuario ?
                                                //                 <span>
                                                //                     {this.props.authStore.ehVisualizador === true ? null :
                                                //                         <span>
                                                //                             <span className="ml-3" onClick={() => this.changeShowModalState(true, item)}>
                                                //                                 <img src={require('../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                //                             </span>
                                                //                             <span className="ml-3" onClick={() => this.editModalMobile(true, item)}>
                                                //                                 <img className='' src={require('../../design/assets/images/editar-gray.svg')} alt="" />
                                                //                             </span>
                                                //                         </span>
                                                //                     }
                                                //                 </span>
                                                //                 : null}
                                                //         </div>
                                                //         <div className="col-4 text-right">
                                                //             <div className="" style={{ fontSize: "12px" }}>{moment(item.data_acontecimento).format('DD/MM/YYYY')}</div>
                                                //         </div>
                                                //         <textarea name="acontecimento-card" id="acontecimento-card" className="mt-3 ml-2 acontecimento-texto" rows="6" value={`${item.acontecimento.replace(/<(?!!br\s*\/?)[^>]+>/g, '').replace(/&nbsp;/g, '')}`} readOnly></textarea>

                                                //         <div className="col-12">
                                                //             <UploadFile linkButton={true} modulo={'acontecimentos'} id_referencia={item.id} onChange={anexo => this.setState({ anexos: [...this.state.anexos, ...anexo], showEdit: false })} />
                                                //         </div>

                                                //         {this.state.anexos !== undefined && this.state.anexos.length > 0 ?
                                                //             this.state.anexos.map((anexo, index) => {
                                                //                 return (
                                                //                     anexo.modulo === 'acontecimentos' && anexo.id_referencia === item.id ?
                                                //                         <div key={index} className="col-md-6 mt-3 d-flex">

                                                //                             <div className="anexo-linha">
                                                //                                 <div className="row">
                                                //                                     <div className="col-10" onClick={() => this.props.uploadStore.downloadArquivo(anexo, this.refs).then(x => console.log(x))}>
                                                //                                         <div className="row" >
                                                //                                             <div className=" ml-2" style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                //                                             <div className="pl-2">
                                                //                                                 {' '}{anexo.nome.substr(0, 18)}...
                                                //                                             </div>
                                                //                                         </div>
                                                //                                     </div>
                                                //                                     {this.props.authStore.ehVisualizador === true ? null :
                                                //                                         <div className="col-2 text-center delete-anexo">
                                                //                                             <img onClick={() => {
                                                //                                                 this.props.uploadStore.removerAnexo(anexo);
                                                //                                                 const array = this.state.anexos
                                                //                                                 array.splice(index, 1)
                                                //                                                 this.setState({ anexos: array })
                                                //                                             }} src={require('../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                //                                         </div>
                                                //                                     }
                                                //                                 </div>
                                                //                             </div>
                                                //                         </div>
                                                //                         : null
                                                //                 )
                                                //             })
                                                //             : null}

                                                //     </div>
                                                // </div>
                                                <div key={index} className="m-3 ">
                                                    <div className="row p-3" style={{ backgroundColor: "white", border: "1px solid lightgray", borderRadius: "7px" }}>
                                                        <div className="col-12 nome-relator">
                                                            <div className="row">
                                                                <div className="col-md-11" style={{ marginLeft: "-14px" }}>
                                                                    {item.nome.replace('.', ' ').toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase() })}
                                                                    <div style={{ fontSize: "12px" }}>{moment(item.data_acontecimento).format('DD/MM/YYYY')}</div>
                                                                </div>
                                                                <div class="dropleft col-md-1 ml-2" >
                                                                    <button class="btn btn-white" style={{ border: "1px solid lightgray" }} type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-three-dots-vertical" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fill-rule="evenodd" d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                        </svg>
                                                                    </button>
                                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">

                                                                        <button style={{ marginTop: "5px" }} onClick={() => this.editModal(true, item)} class="dropdown-item" type="button">
                                                                            <span style={{ fontSize: "14px" }}><img className='' src={require('../../design/assets/images/Vector.svg')} alt="" /><span style={{ marginTop: "10px", marginLeft: "10px", fontSize: "13px" }}>Editar</span></span>
                                                                        </button>
                                                                        <button class="dropdown-item" type="button">
                                                                            <div className="row" style={{ marginTop: "5px" }}>
                                                                                <div>
                                                                                    <img style={{ width: "9px", marginTop: "-3px", marginLeft: "16px", marginRight: "12px" }} src={require('../../design/assets/images/Mask.svg')} alt="excluir anexo delete-anexo" />
                                                                                </div>

                                                                                <div >
                                                                                    <UploadFile linkButton={true} modulo={'acontecimentos-parlamentar'} idParlamentar={this.props.idParlamentar} id_referencia={item.id} onChange={anexo => this.setState({ anexos: [...this.state.anexos, ...anexo], showEdit: false })} />
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                        <button onClick={() => this.changeShowModalState(true, item)} class="dropdown-item" type="button">
                                                                            <span style={{ fontSize: "14px" }}><img style={{ width: "12px", marginTop: "-3px" }} src={require('../../design/assets/images/Trash.svg')} alt="excluir anexo delete-anexo" /><span style={{ marginTop: "10px", marginLeft: "12px", fontSize: "13px" }}>Deletar</span></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* <span>
                                                                {this.props.authStore.ehVisualizador === true ? null :
                                                                    <span>
                                                                        <span className="ml-3 parlamentarEspecificoDesk d-none d-sm-block" onClick={() => this.changeShowModalState(true, item)}>
                                                                            <img src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                        </span>
                                                                        <span className="ml-3 parlamentarEspecificoMobile" onClick={() => this.changeShowModalState(true, item)}>
                                                                            <img src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                        </span>
                                                                        <span className="ml-3 parlamentarEspecificoDesk d-none d-sm-block" onClick={() => this.editModal(true, item)}>
                                                                            <img className='' src={require('../../../design/assets/images/editar-gray.svg')} alt="" />
                                                                        </span>
                                                                        <span className="ml-3 parlamentarEspecificoMobile" onClick={() => this.editModalMobile(true, item)}>
                                                                            <img className='' src={require('../../../design/assets/images/editar-gray.svg')} alt="" />
                                                                        </span>
                                                                    </span>
                                                                }
                                                            </span> */}
                                                        </div>

                                                        <textarea name="acontecimento-card" id="acontecimento-card" className="mt-3 acontecimento-texto" rows="3" value={`${item.acontecimento.replace(/<(?!!br\s*\/?)[^>]+>/g, '').replace(/&nbsp;/g, '')}`} readonly></textarea>
                                                        {this.state.anexos !== undefined && this.state.anexos.length > 0 ?
                                                            this.state.anexos.map((anexo, index) => {
                                                                return (
                                                                    anexo.modulo === 'acontecimentos-parlamentar' && anexo.id_referencia === item.id ?
                                                                        <div key={index} className="col-md-6 mt-3 d-flex">
                                                                            <div className="anexo-linha">
                                                                                <div className="row">
                                                                                    <div className="col-2" onClick={() => this.props.uploadStore.downloadArquivo(anexo, this.refs).then(x => console.log(x))}>
                                                                                        {/* <img src={require('../../../design/assets/images/clips-red.svg')} alt="anexo" /> */}
                                                                                        <div style={{ content: "var(--clipsAnexos)", width: "11px" }}></div>
                                                                                    </div>
                                                                                    <div className="col-8 text-start" style={{ marginLeft: "-30px" }}>
                                                                                        {anexo.nome.substr(0, 18)}...
                                                                                        </div>
                                                                                    {this.props.authStore.ehVisualizador === true ? null :
                                                                                        <div className="col-2 text-center delete-anexo">
                                                                                            <img onClick={() => {
                                                                                                const itemRef = { nome: anexo.nome, id_referencia: anexo.id_referencia, idParlamentar: this.props.idParlamentar}
                                                                                                this.props.uploadStore.removerAnexoSemId(itemRef)
                                                                                                this.props.uploadStore.loading = false;
                                                                                                const array = this.state.anexos
                                                                                                array.splice(index, 1)
                                                                                                this.setState({ anexos: array })
                                                                                            }} src={require('../../design/assets/images/trash-gray.svg')} alt="excluir anexo delete-anexo" />
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                )
                                                            })
                                                            : null}

                                                    </div>
                                                </div>
                                            )
                                        }) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div >


    }

}

export default props => <Acontecimentos {...props} />