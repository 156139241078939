import React, { Component } from 'react';
// import Select from 'react-select';
import Select from 'react-select';


class ClientCheckboxWithSelect extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);

    this.state = {
      value: this.props.value || 0
    }
  }

  onClick = value => this.setState({ value: value ? 1 : 0 }, () => this.props.onClick(this.state.value))

  render() {

    const selectStyle = {
      control: styles => ({
        ...styles,
        boxShadow: "none",
        backgroundColor: "none",
        border: 0,
        borderRadius: 0,
        textAlign: "left",
        "&:hover": {
          border: 0,
          borderRadius: 0,
          borderBottom: "1px solid #3e4049",
          cursor: "pointer"
        },
        "&:active": {
          border: 0,
          borderRadius: 0,
          borderBottom: "1px solid #3e4049"
        }
      }),
      input: styles => ({ ...styles }),
      placeholder: styles => ({ ...styles }),
      singleValue: styles => ({ ...styles }),
      indicatorSeparator: styles => ({ display: 'none' }),
      dropdownIndicator: styles => ({ color: "var(--btndangerbackgroundcolor)" }),
      // dropdownIndicator: styles => ({ color: `${var}` })
    
    };
    
    return (
      <Select
        value={this.props.value}
        onChange={this.props.onChange}
        options={this.props.options}
        styles={selectStyle}
        placeholder={this.props.placeholder || ""}
        isDisabled={this.props.disabled || false}
      />
    )
  }
}
export default ClientCheckboxWithSelect