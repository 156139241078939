import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                labels: ['Favorável', 'Favorável com Emenda Saneadora', 'Contrário', 'Contrário com Emenda Saneadora', 'Pendente', 'Neutro'],
                colors: ['#4CD964','#5856D7', '#F63854', '#007AFF', '#FFD200', '#D8D8D8' ],
                legend: {
                    show: false,
                }
            },
            series: [
                0, 1, 2, 3, 4,5
            ],
        }
    }


    render() {
        const larguraTela = window.innerWidth
        const larguraGrafico = larguraTela > 375 ? '330' : '260'
        const series1 = [
            this.props.propPosicionamentoFavoravel !== undefined ? parseInt(this.props.propPosicionamentoFavoravel) : 0,
            this.props.propPosicionamentoFavoravelComEmenda !== undefined ? parseInt(this.props.propPosicionamentoFavoravelComEmenda) : 0,
            this.props.propPosicionamentoContrario !== undefined ? parseInt(this.props.propPosicionamentoContrario) : 0,
            this.props.propPosicionamentoContrarioComEmenda !== undefined ? parseInt(this.props.propPosicionamentoContrarioComEmenda) : 0,
            this.props.propPosicionamentoPendente !== undefined ? parseInt(this.props.propPosicionamentoPendente) : 0,
            this.props.propPosicionamentoNeutro !== undefined ? parseInt(this.props.propPosicionamentoNeutro) : 0,
        ]
        const series2 = [0,0,0,0,0,0]
        const series = this.props.propPosicionamentoFavoravel !== undefined && this.props.propPosicionamentoFavoravelComEmenda !== undefined && this.props.propPosicionamentoContrario !== undefined && this.props.propPosicionamentoContrarioComEmenda && this.props.propPosicionamentoPendente && this.props.propPosicionamentoNeutro !== undefined  ? series1 : series2
        // console.log('TESTE AQUI DONUT ===>',this.props.dados)

        return (
            <div className="donut">
                <Chart 
                    options={this.state.options} 
                    series={series} 
                    type="donut" 
                    width={larguraGrafico}
                />
            </div>
        );
    }
}

export default Donut;