import React, { Component } from 'react'
import ContentEditable from 'react-contenteditable'
import { inject } from "mobx-react"
import noPhoto from "../../design/assets/images/no-photo.svg"
import CircularProgress from '../../componentes/loadingCircle'


const regex = { 
  ultimaMencao: /(^| |&nbsp;)@(.[^ ]*)?$/g,
  mencaoAdicionada: /<b data-id="([0-9]+)">(.[^ ]*)<\/b>&nbsp;$/g,
  mencoes: /<b data-id="([0-9]+)">(.[^ ]*)<\/b>/g,
  limpar: /<b>(.[^ ]*)?<\/b>$/g
}
const keys = { enter: 13, space: 32, up: 40, down: 38, backspace: 8 }

@inject('alertaStore')
class CampoDeComentario extends Component {
  constructor(props) {
    super(props)

    this.escolherUsuario = this.escolherUsuario.bind(this)
    this.listarUsuarios = this.listarUsuarios.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.salvar = this.salvar.bind(this)

    this.state = {
      autocomplete: false,
      selecionado: 0,
      salvando: false,
      formatado: props.valor || ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.valor !== this.props.valor) {
      this.setState({ formatado: this.props.valor })
      document.getElementById(this.props.id).focus()
    }
  }

  escolherUsuario(usuario) {
    if (!usuario) return
    this.setState({
      autocomplete: false,
      selecionado: 0,
      formatado: this.state.formatado.replace(regex.ultimaMencao,
        `$1<b data-id="${usuario.id}">${usuario.nome}</b>&nbsp;`),
    })
  }

  listarUsuarios(usuarios) {
    let mencao = this.state.formatado.match(regex.ultimaMencao)
    mencao = mencao && mencao[0].replace(regex.ultimaMencao, '$2').toLowerCase()
    return mencao ? usuarios.filter(({ nome }) => nome.toLowerCase().match(mencao)) : usuarios
  }

  onKeyDown(usuarios) {
    return event => {
      const listaDeUsuarios = this.listarUsuarios(usuarios)
      const { selecionado, formatado } = this.state

      if (event.keyCode === keys.enter) {
        event.preventDefault()
        return event.shiftKey ? this.salvar() : this.escolherUsuario(listaDeUsuarios[selecionado])
      }

      if (event.keyCode === keys.space && listaDeUsuarios.length === 1) {
        event.preventDefault()
        return this.escolherUsuario(listaDeUsuarios[0])
      }

      if (event.keyCode === keys.backspace && formatado.match(regex.mencaoAdicionada)) {
        event.preventDefault()
        return this.setState({ formatado: formatado.replace(regex.mencaoAdicionada, '') })
      }
    }
  }

  onKeyUp(usuarios) {
    return ({ keyCode, ...e }) => {
      const listaDeUsuarios = this.listarUsuarios(usuarios)
      const { selecionado } = this.state
      const ehUltimo = selecionado === listaDeUsuarios.length - 1

      if (keyCode === keys.up)
        return this.setState({ selecionado: ehUltimo ? selecionado : selecionado + 1 })

      if (keyCode === keys.down)
        return this.setState({ selecionado: selecionado && selecionado - 1 })

      this.setState({
        selecionado: 0,
        autocomplete: this.state.formatado.match(regex.ultimaMencao)
      })
    }
  }

  onChange({ target: { value } }) {
    let formatado = value.replace(/@@/g, '@')
    if (formatado.match(regex.limpar))
      formatado = formatado.replace(regex.limpar, '$1')
    this.setState({ formatado })
  }

  onBlur() {
    setTimeout(() => this.setState({ autocomplete: false }), 500)
  }

  async salvar() {

    //console.log('LINK >>>>>>>>>>>>>>>',window.location.pathname)
    const { formatado } = this.state
    if (!formatado) return this.setState({ error: 'Campo obrigatório.' })

    this.setState({ salvando: true })
    try {
      await this.props.acao({
        ...this.props,
        comentario: formatado.replace(regex.mencoes, (...match) => `@@@${match[1]}@@@`),
        link: window.location.pathname
      })

      this.props.reload && this.props.reload()
      this.props.alertaStore.criar('Comentário enviado.')
      return this.setState({ error: null, formatado: '', salvando: false })
    } catch (error) {
      console.error(error)
      return this.setState({ error: (error && error.message) || 'Não foi possível comentar.', salvando: false })
    }
  }

  render() {
    const { formatado, selecionado, autocomplete, error } = this.state
    const usuarios = this.props.usuarios

    return usuarios ? <div className="dropdown">
      <div className="input-group input-group-lg mt-4 fs-14">
        {this.props.prepend ? <div className="input-group-prepend">
          {/* <div className="input-group-text">+</div> */}
        </div> : false}
        <div className="Client-comentario-image">
          <img src={this.props.image || noPhoto} alt="" />
        </div>
        <ContentEditable
          id={this.props.id}
          className="Client-comentario"
          html={formatado}
          onKeyDown={this.onKeyDown(usuarios)}
          onKeyUp={this.onKeyUp(usuarios)}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
        <div className='Client-link Client-cinza-claro Client-comentario-button' disabled={this.state.salvando}
          onClick={this.salvar}>
            
          { this.state.salvando 
            ? 
            <CircularProgress color={'#DC2D41'} size={'30px'} />
            : 
              <span><img className='mr-3' src={require('../../design/assets/images/send.svg')} alt="" />Enviar</span> 
          }
        </div>
      </div>

      {error ? <small className="form-text text-danger">{error}</small> : null}

      {usuarios && usuarios.length ?
        <ul className={`dropdown-menu ${autocomplete ? 'd-block' : 'd-none'}`}>
          {
            this.listarUsuarios(usuarios) !== undefined &&
            Array.isArray(this.listarUsuarios(usuarios)) &&
            this.listarUsuarios(usuarios).length > 0 ?
            this.listarUsuarios(usuarios).map(({ id, nome }, i) =>
            <li className={`dropdown-item ${i === selecionado ? 'active' : ''}`} key={id}
              onClick={() => this.escolherUsuario({ id, nome })}>{nome}
            </li>) : null
          }
        </ul> : null
      }
    </div> : null
  }
}

export default CampoDeComentario