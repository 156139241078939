import React, {Component} from 'react';
import Loading from '../../componentes/loading';
import LoadingCircle  from '../../componentes/loadingCircle'
import { get } from '../../services/http'
//import ProbabilidadeAprovProp from '../../componentes/accordion'
//import ProjetoLeiMaiorAprov from '../../componentes/accordion'
//import ProjetoLeiMenorAprov from '../../componentes/accordion'
import TempoMedioTramTipo from '../../componentes/accordion'
import ClientCheckboxWithSelect from '../../componentes/forms/basic/clientSelectWithSearch'
//import GraficoProbAprovProp from '../insights/graficos/proposicaoProbabilidadeAprovProp'
import { inject, observer } from 'mobx-react'

@inject('authStore')
@observer

class InsightProposicao extends Component {
    state = {
        loading: false,
        loadingTabela: false,
        loadingResultSelect:false,
        showSelect:false,
        responseTPC:[],
        propFiltro:'',
        proposicao:[],
        proposicaoSelecionada:[],
        pesquisaSemRegistro:false,
        pesquisaSemCorrelatas:false,
    
    }
    
    componentDidMount() {
        if(this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
    }

    selecionarBusca(value){

        if(this._buscarInput.value === '') {
            
            this.setState(
                {
                    proposicao:[], 
                    proposicaoSelecionada:[], 
                    responseTPC:[], 
                    propFiltro:'',
                    pesquisaSemRegistro:false,
                    pesquisaSemCorrelatas: false,
                    showSelect:false,
                }
            )
        }else{
            const proposicao = value
            this.setState({propFiltro: proposicao})     

        }       
    }

    getDataProposicao = async () => {

        // console.log('ENTREI AQUI')
        // console.log(this._buscarInput)


        if(this._buscarInput.value !== '') {
            
            this.setState(
                {
                    proposicao:[], 
                    proposicaoSelecionada:[], 
                    responseTPC:[], 
                    pesquisaSemRegistro:false,
                    pesquisaSemCorrelatas: false,
                    showSelect:false,
                    loadingResultSelect:true,
                }
            )

           
            await get(`insights/proposicoes/temp?tipo=${this.state.propFiltro}`)
            //await get(`insights/proposicoes/?tipo=PL1978/2020`)
                .then(response => {
            
                response.length > 0 
                    ? 
                        this.setState({proposicao: response, loadingResultSelect:false, showSelect: true}) 
                    : 
                        this.setState({ pesquisaSemRegistro: true, propFiltro:'',loadingResultSelect:false })                      
                        this._buscarInput.focus()
                        setTimeout(()=>
                            this.setState({ pesquisaSemRegistro: false })
                        ,5000)               

                }
            )
            .catch(err => err)
           
            
        }else{
            this._buscarInput.focus()
        }
    }

    onChangeResultadoProp(prop){
       
        //console.log(prop)
        this.setState({proposicaoSelecionada: prop, loadingTabela: true, pesquisaSemCorrelatas: false})
        this.getDataResponseTPC(prop.value)

    }

    getDataResponseTPC = async (id) =>{
    
        //console.log(id)
        await get(`insights/proposicoes/media/${id}`)
        //await get(`insights/proposicoes/media/22344`)
        .then(response => {
            //console.log(response)

            response.aprovacao.length > 0 && response.rejeicao.length > 0 
                ?
                    this.setState({responseTPC: [response]})          
                :
                    this.setState({ pesquisaSemCorrelatas: true })                       

        }).catch(err => err)

        this.setState({ loadingTabela:false})
    }

    redirectCorrelatas = () =>{

        // console.log(this.state.proposicaoSelecionada)
        let id = this.state.proposicaoSelecionada.value
        window.location.href = `/proposicao/${id}/correlatas`
    }

   
    render() {

        //console.log(this.state.propFiltro)
        //const larguraTela = window.innerWidth
        //const classButtonPesquisar = larguraTela <= 600 ? 'col-12  col-sm-6  col-lg-6 col-xl-6 d-flex justify-content-center mt-1' : 'col-12  col-sm-6  col-lg-6 col-xl-6 d-flex justify-content-end'
        return (
            this.state.loading ? <Loading/> :
                <div className="" >
                    {/*
                    <div className="acordionsBordas mt-2">
                        
                            <ProbabilidadeAprovProp
                                mb={'mb-0'}
                                p={'p-0'}
                                titulo="Probabilidade de aprovação de proposição"
                            
                            >
                            <hr/>
                        
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <div className="input-group col-12 col-sm-6  col-lg-6 col-xl-6">
                                    <input className="form-control py-2 border-right-0 border" type="search" autoComplete="off"
                                        value={this.state.descricao}
                                        onKeyUp={(event) => {if (event.which === 13)  {this.getDadosPalavraChave()} }}
                                        // onKeyPress={(event) => this.clickEnter(event)}
                                        onChange={(event) => this.selecionarBusca(event.target.value)}
                                        placeholder="Buscar palavra-chave" aria-label="Pesquisar"
                                        aria-describedby="search" 
                                        
                                        ref={(event) => this._buscarInput = (event) }
                                        />
    
                                        <span id="icone-pesquisar-proposicao" className="input-group-append">
                                        <button className="btn btn-outline-secondary border-left-0 border" type="button"
                                        onClick={() => this.getDadosPalavraChave()}>                
                                            <i className="icon-search-small"></i>  
                                        </button>
                                        </span>
                                </div>
                                
                                <div className="col-12 col-sm-6  col-lg-6 col-xl-6 ">
                                    <label className="label-insight-acordion">Resultado da Pesquisa</label>
                                    <ClientCheckboxWithSelect 
                                        css="no-border"
                                        //value={ambitoCasaSelecionado}
                                        //options={this.state.ambito.map(item => ({ value: item.id, label: item.value }))}
                                        //onChange={ambito => this.onChangeAmbito(ambito)} 
                                        // placeholder={this.state.ambitoCasaSelecionado}
                                        label={'Casa Legislativa'}
                                    />  

                                </div>
                        
                            </div>
                                        


                            <div className="col-12  mt-2" id="divgrafico">
                                <div className={`d-flex justify-content-center flex-wrap`}>
                                    <div id="align-grafico-mobile">
                                        
                                            <GraficoProbAprovProp
                                                //ComissoesConvergente        = {this.state.respConvergente}
                                            
                                            />
                                    
                                    </div>
                                    
                                     <div className="div-indice">
                                        <div className="d-flex">
                                            <div className="indice-exito mt-1 mr-2"></div>
                                            <div className="mr-2 dasboard-graficos-legenda">Convergente</div>
                                            <span className="dashboard-graficos-dados">{this.state.propResultadoClientPendente}</span>
                                        </div>
                                        <div className="d-flex mt-2">
                                            <div className="indice-sem-exito mt-1 mr-2"></div>
                                            <div className="mr-2 dasboard-graficos-legenda">Divergente</div>
                                            <span className="dashboard-graficos-dados">{this.state.propResultadoClientExito}</span>
                                        </div>
                                        <div className="d-flex mt-2">
                                            <div className="indice-exito-parcial mt-1 mr-2"></div>
                                            <div className="mr-2 dasboard-graficos-legenda">Não Classificado</div>
                                            <span className="dashboard-graficos-dados">{this.state.propResultadoClientSemExito}</span>
                                        </div>
                                        <div className="d-flex mt-2 mb-2">
                                            <div className="indice-sem-info mt-1 mr-2 "></div>
                                            <div className="mr-2 dasboard-graficos-legenda">Neutro</div>
                                            <span className="dashboard-graficos-dados">{this.state.propResultadoClientExitoParcial}</span>
                                        </div>
                                    </div>
                                 
                                
                                </div>
                            </div>
                        </ProbabilidadeAprovProp>

                                </div>
                    */}
                    
                    {/* <div className="acordionsBordas mt-2">
                        <ProjetoLeiMaiorAprov
                            mb={'mb-0'}
                            p={'p-0'}
                            titulo="Proposições com maiores chances de aprovação"
                        >
                         <hr/>
                            <div className="row p-2">  
                                <div className="col-12 col-sm-3  col-lg-3 col-xl-3">
                                    <div className="label-insight-acordion">Data Inicial</div> 
                                    <input id="data-inicial" className="data-inicial-ParlCPI"  type="date" onChange={event => this.dataInicialParlamentaresCPI(event)} />    
                                </div>
                                <div className="col-12 col-sm-3  col-lg-3 col-xl-3"> 
                                    <div className="label-insight-acordion">Data Final</div>  
                                    <input id="data-final" className="data-final-ParlCPI"  type="date" onChange={event => this.dataFinalParlamentaresCPI(event)}   />
                                </div>

                                {this.state.dataInicialParlamentaresCPI !== "" && this.state.dataFinalParlamentaresCPI !== "" ?
                                    <div className={`${classButtonPesquisar}`} >
                                        <img id="pesquisa-dash" className=""  src={require('../../design/assets/images/btn_pesquisar.svg')} alt="btn-pesquisar" onClick={() => this.getDataParlamentaresCPI()}  style={{cursor: 'pointer'}}/>
                                        <img  id="limpar-dash" className="ml-3"  src={require('../../design/assets/images/btn_limpar.svg')} alt="btn-limpar" onClick={() => this.limparDatasParlCPI()} style={{cursor: 'pointer'}}/>
                                    </div>            
                                : null }   

                            </div>    
                            <div className="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl ">
                                <table className="table table-striped ">
                                    <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                        <tr>
                                            <th>Proposição</th>
                                            <th>% de chance de aprovação </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                         {

                                            this.state.resultadoPRP !== undefined && Array.isArray(this.state.resultadoPRP) && this.state.resultadoPRP.length > 0 ?
                                                this.state.resultadoPRP.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.nome}</td>
                                                            <td>{item.qtd}</td>
                                                        </tr>
                                                    )
                                                })
                                                : null
                                        } 
                                    </tbody>
                                </table>
                            </div> 
                           
                        </ProjetoLeiMaiorAprov>
                    </div> */}
                
                    {/* <div className="acordionsBordas mt-2">
                        <ProjetoLeiMenorAprov
                             mb={'mb-0'}
                             p={'p-0'}
                             titulo="Proposições com menores chances de aprovação"
                        >
                         <hr/>
                        <div className="row p-2">  
                            <div className="col-12 col-sm-3  col-lg-3 col-xl-3">
                                <div className="label-insight-acordion">Data Inicial</div> 
                                <input id="data-inicial" className="data-inicial-ParlTemas"  type="date" onChange={event => this.dataInicialParlamentaresTemas(event)} />    
                            </div>
                            <div className="col-12 col-sm-3  col-lg-3 col-xl-3"> 
                                <div className="label-insight-acordion">Data Final</div>  
                                <input id="data-final" className="data-final-ParlTemas"  type="date" onChange={event => this.dataFinalParlamentaresTemas(event)}   />
                            </div>
                            {this.state.dataInicialParlamentaresTemas !== "" && this.state.dataFinalParlamentaresTemas !== "" && this.state.opcaoSelecionadoTemas !== "" ?
                                <div className={`${classButtonPesquisar}`} >
                                    <img id="pesquisa-dash" className=""  src={require('../../design/assets/images/btn_pesquisar.svg')} alt="btn-pesquisar" onClick={() => this.getDataParlamentaresTemas()}  style={{cursor: 'pointer'}}/>
                                    <img  id="limpar-dash" className="ml-3"  src={require('../../design/assets/images/btn_limpar.svg')} alt="btn-limpar" onClick={() => this.limparDatasParlTemas()} style={{cursor: 'pointer'}}/>
                                </div>
                            : null}                                
                        </div>    
                        <div className="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl ">
                                <table className="table table-striped ">
                                    <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                        <tr>
                                            <th>Proposição</th>
                                            <th>% de chance de aprovação </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                          {

                                            this.state.resultadoPRP !== undefined && Array.isArray(this.state.resultadoPRP) && this.state.resultadoPRP.length > 0 ?
                                                this.state.resultadoPRP.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.nome}</td>
                                                            <td>{item.qtd}</td>
                                                        </tr>
                                                    )
                                                })
                                                : null
                                        } 
                                    </tbody>
                                </table>
                            </div>
                        
                </ProjetoLeiMenorAprov>
            </div> */}

           

             <div className="acordionsBordas mt-2">                        
                    <TempoMedioTramTipo
                        mb={'mb-0'}
                        p={'p-0'}
                        titulo="Tempo médio de tramitação de proposição das correlatas"
                    >
                     <hr/>
                        
                            <div className="row justify-content-center align-items-center p-2">
                                <div className="input-group col-12 col-sm-6  col-lg-6 col-xl-6">
                                    <input className="form-control py-2 border-right-0 border" type="search" autoComplete="off"
                                        value={this.state.propFiltro}
                                        onKeyUp={(event) => {if (event.which === 13)  {this.getDataProposicao()} }}
                                        // onKeyPress={(event) => this.clickEnter(event)}
                                        onChange={(event) => this.selecionarBusca(event.target.value.toUpperCase())}
                                        placeholder="Buscar proposição" aria-label="Pesquisar"
                                        aria-describedby="search" 
                                        
                                        ref={(event) => this._buscarInput = (event) }
                                        />
    
                                        <span id="icone-pesquisar-proposicao" className="input-group-append">
                                        <button className="btn btn-outline-secondary border-left-0 border" type="button"
                                        onClick={() => this.getDataProposicao()}>                
                                            <i className="icon-search-small"></i>  
                                        </button>
                                        </span>
                                </div>
                                
                                <div className="col-12 col-sm-6  col-lg-6 col-xl-6 ">
                                    <label className="label-insight-acordion">Resultado da Pesquisa</label>

                                       {
                                    
                                        this.state.loadingResultSelect ? <div className="d-flex justify-content-center mt-2"><LoadingCircle color={'#DC2D41'} size={'30px'} /></div> : 

                                            this.state.showSelect ?

                                                <ClientCheckboxWithSelect 
                                                    css="no-border"
                                                    value={this.state.proposicaoSelecionada}
                                                    options={this.state.proposicao.map(item => ({
                                                        value: item.id, label: item.descricao + ' ' + item.apelido + ' - ' + item.casa, id_proposicao: item.id_proposicao, casa: item.casa, numero_casa: item.numero_casa
                                                    }))}
                                                    //options={this.state.proposicao.map(item => ({ value: item.id, label: item.descricao + ' >> ' + moment(item.data_apresentacao).format('DD/MM/YYYY')  + ' >> ' + item.apelido + ' >> ' + item.casa, id_proposicao: item.id_proposicao, casa:item.casa}))}
                                                    onChange={proposicao => this.onChangeResultadoProp(proposicao)} 
                                                    placeholder='Selecione a proposição'
                                                    //label={'Casa Legislativa'}
                                                /> 
                                            :
                                            null 
                                       }
                                </div>
                        
                            </div>
                            
                                {
                                     this.state.pesquisaSemRegistro ? <div style={{backgroundColor: "rgba(86,61,124,.15)",
                                                                        border: "1px solid rgba(86,61,124,.15)", fontSize: "18px"}}>
                                                                            <div className={`p-3 d-flex justify-content-center mb-2`}>
                                                                                <b>A pesquisa não retornou resultados</b> 
                                                                            </div> 
                                                                        </div>   
                                     :

                                    this.state.loadingTabela ? <div style={{marginTop:'200px'}}><Loading/></div> :

                                    this.state.pesquisaSemCorrelatas ? <div style={{backgroundColor: "rgba(86,61,124,.15)",
                                                                        border: "1px solid rgba(86,61,124,.15)", fontSize: "18px"}}>
                                                                            <div className={`p-3 d-flex flex-column justify-content-center align-items-center mb-2`}>
                                                                                <span><b>Não existe correlatas cadastradas para essa proposição</b></span>
                                                                                <span >
                                                                                    <span className='pr-3'><b>Para cadastra-las clique no link</b></span>
                                                                                    <span className="fs-20 Client-link" style={{cursor:'pointer'}} onClick={()=> this.redirectCorrelatas()}>Gerar Correlatas</span>
                                                                                </span> 
                                                                            </div> 
                                                                        </div>   
                                    :
                                    this.state.responseTPC !== undefined && Array.isArray(this.state.responseTPC) && this.state.responseTPC.length > 0 ?
                                    this.state.responseTPC.map((item,index) => {
                                        return(
                                            <div className="mt-3" key={index}>
                                                <div className="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl ">
                                                    <span style={{fontSize: '18px', fontWeight:'bold'}}>Aprovação</span>
                                                    <table className="table table-striped ">
                                                        <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                                            <tr>
                                                                <th>Casa Legislativa</th>
                                                                <th>Tempo Médio</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                    item.aprovacao.map((aprovacao, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{aprovacao.casa}</td>
                                                                                <td>{aprovacao.media >= 1 ? parseInt(aprovacao.media) + ' dias' : ' --- '}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                
                                                            } 
                                                        </tbody>
                                                     </table>
                                                </div>
                                                <div className="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl ">
                                                    <span style={{fontSize: '18px', fontWeight:'bold'}}>Rejeição</span>
                                                    <table className="table table-striped ">
                                                        <thead style={{ backgroundColor: '#9B9B9B', color: 'white' }}>
                                                            <tr>
                                                                <th>Casa Legislativa</th>
                                                                <th>Tempo Médio</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                    item.rejeicao.map((rejeicao, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{rejeicao.casa}</td>
                                                                                <td>{rejeicao.media >= 1 ? parseInt(rejeicao.media) + ' dias' : ' --- '}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                
                                                            } 
                                                        </tbody>
                                                    </table>
                                                    </div> 

                                            </div>
                                        )
                                    })

                                    : null                                  
                                
                                }                    
                            

                       </TempoMedioTramTipo>
                   </div>

            </div>
                    
        )
    }
}

export default InsightProposicao