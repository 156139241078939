import * as React from "react"; 
import { useClearCache } from "react-clear-cache";
import IndexDashboard from './indexDashboard'

 
function clearApp(props) {
  const { isLatestVersion, emptyCacheStorage } = useClearCache(); 
  //var { isLatestVersion, emptyCacheStorage } = useClearCache(); 
  
  
  //isLatestVersion = false

  function redirectLogin(){
      window.localStorage.clear()
      window.location.href = "/"
  }

  //console.log(logout)
  return (
    <div>

    {
      isLatestVersion 
      
        ?

          <IndexDashboard/>
        
        :
        
          null
    }

      
    {!isLatestVersion && (
      <>
            <div className="row">
                 <div className=" divFormLogin col-12 col-sm-12  col-lg-12 col-xl-12">
                    <p className="d-flex justify-content-center">Atualize para nova versão!</p>

                    <div className="d-flex justify-content-center">
                        <button
                            className="btn btn-danger col-sm-6 mb-5 w-100 p-3"
                            onClick={e => {
                                e.preventDefault();
                                emptyCacheStorage();
                                redirectLogin();
                                
                            }} 
                        >Nova Versao
                        </button>
                                                  
                    </div>
                                  
                    
                </div>
            </div>
 
         
      </>      
      )}
      
    </div>
    
  );
};
 
export default clearApp;


