import React, { Component } from 'react';
import PropTypes from 'prop-types'

//deve receber a propriedade Lista sendo um array de objetos com id, nome, icone, span-class
class DropdownSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOptions: false,
      filteredItems: [],
      selected: this.props.value || this.props.defaultItem,
      filter: ''
    }

    this.toggleOptions = this.toggleOptions.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
    this.setListOptions = this.setListOptions.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) this.setState({ selected: nextProps.value })
  }

  toggleOptions() { if (this.props.disabled) return; this.setState({ openOptions: !this.state.openOptions }) }

  toggleOptionsWithKeyboard(evt) {
    if (this.props.disabled) return;
    evt.preventDefault();//enter key: toggle options//esc key: hide options   
    if (evt.keyCode === 13) this.toggleOptions();
    else if (evt.keyCode === 27) this.setState({ openOptions: false });
  }

  onSelect(item) {
    this.props.onChange(item.id, this.props.id);
    this.setState({ selected: item.id, filter: '' })
  }

  onSelectWithKeyboard(evt, item) {
    evt.preventDefault();
    //enter key: select
    //esc key: hide options
    if (evt.keyCode === 13) { this.onSelect(item); this.setState({ openOptions: false }); }
    else if (evt.keyCode === 27) this.setState({ openOptions: true })
  }

  filterSearch(evt) {
    let filterValue = evt.target.value;
    let filteredItems = []
    if (filterValue) filteredItems = this.props.lista.filter(x => x.nome.includes(filterValue))
    this.setState({ filter: (filterValue && filterValue.length > 0), filteredItems: filteredItems });
  }

  setListOptions() {
    if (this.state.selected && !this.props.lista.find(i => i.id === this.state.selected)) this.setState({ selected: null });
  }

  componentWillMount() { this.setListOptions() }
  componentDidUpdate(prevProps) { if (prevProps.options !== this.props.lista) this.setListOptions() }

  render() {
    let isSelected = this.state.selected;
    let selectedSize = this.props.selectedSize;
    let fontSize = this.props.optionsSize;
    let options = this.state.filter ? this.state.filteredItems : this.props.lista;
    let selectedOption = options.find(x => x.id === isSelected);
    //return AF {a: "AF"}

    return (
      <div className={`dropdown fs-13 ${this.props.css}`} onClick={() => this.toggleOptions()} onKeyUp={evt => this.toggleOptionsWithKeyboard(evt)}>
        {this.props.searchable && this.state.openOptions ?
          <input ref="selectedFlag" style={{ fontSize: `${selectedSize}px`, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            className={`btn btn-outline-${selectedOption && selectedOption.outline ? selectedOption.outline : 'secondary'} ${this.props.css} ${this.props.bg ? this.props.bg : ''} ${this.props.disabled ? 'disabled' : ''} pl-2 pr-2`}
            type="text" placeholder='Pesquisar...' onChange={(evt) => this.filterSearch(evt)} />

          : <div ref="selectedFlag" style={{ fontSize: `${selectedSize}px`, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} tabIndex="0"
            className={`btn btn-outline-${selectedOption && selectedOption.outline ? selectedOption.outline : this.props.light ? 'light' : 'secondary'} ${this.props.css} ${this.props.bg ? this.props.bg : ''} ${this.props.disabled ? 'disabled' : ''} pl-2 pr-2`} >

            <div>
              {selectedOption && selectedOption.spanclass && <span className={`${selectedOption.spanclass} mt-1 mr-2`} />}
              <span className={!selectedOption && this.props.light ? 'label-no-result fs-13' : `fs-13`}>
                {selectedOption ? selectedOption.nome : this.props.placeholder}</span>
            </div>

            {this.props.disabled ? null : <i className={'icon-arrow-down-small mt-1'} style={{ fontSize: 9 }} />}
          </div>
        }

        {
          this.state.openOptions &&
          <div className={`dropdown-menu p-1 show cursor-pointer ${this.props.css}`} 
          ref="flagOptions" style={{ fontSize: `${fontSize}px` }}>
            {
              options !== undefined && Array.isArray(options) && options.length > 0 ?
              options.map(item => {
                return (
                  <div className='dropdown-item fluid cursor-pointer p-1 pl-2 pr-2 fs-13' key={item.id} tabIndex="0"
                    onClick={() => this.onSelect(item)}
                    onKeyUp={evt => this.onSelectWithKeyboard(evt, item)}>

                    {item.spanclass ? <span className={`${item.spanclass} mt-1 mr-2`} /> : null}
                    <span className="fs-13">{item.nome}</span>
                  </div>
                )
              }):null
            }
          </div>
        }
      </div >)
  }
}

// DropdownSelect.defaultProps = {
//   selectedSize: 16,
//   fontSize: 14,
//   placeholder: "Selecione",
//   alignOptions: "right",
//   disabled: false,
//   searchable: false,
//   css: 'btn-block'
// }

DropdownSelect.propTypes = {
  items: PropTypes.array,
  lista: PropTypes.array,
  selectedSize: PropTypes.number,
  fontSize: PropTypes.number,
  placeholder: PropTypes.string,
  css: PropTypes.string,
  className: PropTypes.string,
  alignOptions: PropTypes.string,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool
}

export default DropdownSelect;