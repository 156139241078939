import React from 'react';
// import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import SelectFiltro from '../../componentes/forms/basic/selectFiltro'
import AuthStore from '../../stores/authStore'


// import Autocomplete from '@material-ui/lab/Autocomplete';

export default function FormDialog(props) {

    const [open, setOpen] = React.useState(false);
    const [convergente, setConvergente] = React.useState(false);
    const [divergente, setDivergente] = React.useState(false);
    const [naoClassificado, setNaoClassificado] = React.useState(false);
    const [neutro, setNeutro] = React.useState(false);
    const [estadoSelected, setEstadoSelected] = React.useState([]);
    const [partidoSelected, setPartidoSelected] = React.useState([]);
    const [idadeSelected, setIdadeSelected] = React.useState([]);
    const [escolaridadeSelected, setEscolaridadeSelected] = React.useState([]);
    const [profissaoSelected, setProfissaoSelected] = React.useState([]);
    const [casaSelected, setCasaSelected] = React.useState([]);
    const [ambitoSelected, setAmbitoSelected] = React.useState([]);
    const [ambito, setAmbito] = React.useState([]);
    // const [busca, setBusca] = React.useState(props.busca);

    const casa = [
        { value: 1, label: 'Senado' },
        { value: 2, label: 'Câmara' },
        { value: 3, label: 'Congresso' }
    ]

    const ambito1 = props.ambito1
    const ambito2 = props.ambito2
    const ambito3 = props.ambito3
    const estado = props.estado
    const partido = props.partido
    const idade = props.idade
    const profissao = props.profissao
    const escolaridade = props.escolaridade
    const busca = props.busca === 'deputad' || props.busca === 'senad' ? '' : props.busca

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }

    const limparFiltro = () => {
        setConvergente(false)
        setDivergente(false)
        setNaoClassificado(false)
        setNeutro(false)
        setEstadoSelected([])
        setPartidoSelected([])
        setIdadeSelected([])
        setEscolaridadeSelected([])
        setProfissaoSelected([])
        setCasaSelected([])
        setAmbitoSelected([])

        window.localStorage.removeItem('filtroParlamentar')
    }

    const filtrar = () => {
        // console.log('entrei no filtro')
        const data = {
            convergente: convergente,
            divergente: divergente,
            naoClassificado: naoClassificado,
            neutro: neutro,
            casa: casaSelected,
            ambito: ambitoSelected,
            estado: estadoSelected,
            partido: partidoSelected,
            idade: idadeSelected,
            profissao: profissaoSelected,
            escolaridade: escolaridadeSelected,
            busca: busca
        }
        // console.log('data do filtro ==> ', data)
        window.localStorage.setItem('filtroParlamentar', JSON.stringify(data))
        window.location.reload()
    }

    const selecionarEstado = (value) => {
        setEstadoSelected(value)
    }

    const selecionarPartido = (value) => {
        setPartidoSelected(value)
    }

    const selecionarIdade = (value) => {
        setIdadeSelected(value)
    }

    const selecionarProfissao = (value) => {
        setProfissaoSelected(value)
    }

    const selecionarEscolaridade = (value) => {
        setEscolaridadeSelected(value)
    }


    const selecionarCasa = (value) => {
        setCasaSelected(value)
        if (value.value === 1) {
            setAmbito(ambito1)
        }
        if (value.value === 2) {
            setAmbito(ambito2)
        }
        if (value.value === 3) {
            setAmbito(ambito3)
        }
    }

    const selecionaAmbito = (value) => {
        setAmbitoSelected(value)
    }

    const qualificacaoConvergente = (event) => {
        setConvergente(event.target.checked)
    }
    const qualificacaoDivergente = (event) => {
        setDivergente(event.target.checked)
    }

    const qualificacaoNaoClassificado = (event) => {
        setNaoClassificado(event.target.checked)
    }

    const qualificacaoNeutro = (event) => {
        setNeutro(event.target.checked)
    }

    //console.log('AMBITO', ambito)

    //    const user = JSON.parse(window.localStorage.getItem('user'))
    const tamanhoTela = window.innerWidth
    return (
        <div>

            {tamanhoTela < 600 ?
                <button className="btn hiddenDeskModelParlamentar" style={{ backgroundColor: "var(--botaoFiltro)", color: "var(--corTextoBotao)" }} variant="outlined" onClick={handleClickOpen}>
                    <img
                        style={{ marginLeft: "-5px", marginRight: "-5px" }}
                        src={require('../../design/assets/images/filtar.png')}
                        alt="anexo"
                    />
                </button>
                :
                <button className="btn hiddenMobileModelParlamentar d-none d-sm-block " style={{ backgroundColor: "var(--botaoFiltro)", color: "var(--corTextoBotao)" }} variant="outlined" onClick={handleClickOpen}>
                    <img
                        className="mr-3"
                        src={require('../../design/assets/images/filtar.png')}
                        alt="anexo"
                    />{' '}
                    Filtrar
                </button>
            }


            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title" >

                <DialogContent
                    style={{
                        width: tamanhoTela > 600 ? 600 : tamanhoTela,
                        backgroundColor: "#3E4049",
                        color: 'white'
                    }}
                >

                    {busca === '' ? null :
                        <div>
                            <Typography variant="h6" gutterBottom style={{ color: 'white', marginTop: '30px' }}>
                                Resultado da Busca:
                            </Typography>
                            <div className="mb-2">
                                <p style={{ color: 'white', fontSize: '1.5rem' }}>"{busca}"</p>
                                {/* <input type="text" readonly value={busca} className="form-control" />     */}
                            </div>
                            <hr style={{ backgroundColor: 'black' }} />
                        </div>
                    }


                    <Typography variant="h6" style={{ color: 'white', marginTop: '30px' }} >
                        Visualizar por
                    </Typography>

                    <div className="row">

                        <div className="col-md-3 mt-2">
                            <label style={{ fontSize: '0.8rem' }}>Estado</label>
                            <SelectFiltro
                                css="no-border"
                                value={estadoSelected}
                                options={estado !== undefined &&
                                    Array.isArray(estado) && estado.length > 0 ?
                                    estado.map(item => ({ value: item.value, label: item.label })) : null}
                                onChange={(newValue) => selecionarEstado(newValue)}
                                placeholder="Selecione"
                            />
                        </div>

                        <div className="col-md-3 mt-2">
                            <label style={{ fontSize: '0.8rem' }}>Partido</label>
                            <SelectFiltro
                                css="no-border"
                                value={partidoSelected}
                                options={
                                    partido !== undefined && Array.isArray(partido) && partido.length > 0 ?
                                        partido.map(item => ({ value: item.value, label: item.label })) : null}
                                onChange={(newValue) => selecionarPartido(newValue)}
                                placeholder="Selecione"
                            />
                        </div>

                        <div className="col-md-3 mt-2">
                            <label style={{ fontSize: '0.8rem' }}>Casa Legislativa</label>
                            <SelectFiltro
                                css="no-border"
                                value={casaSelected}
                                options={
                                    Array.isArray(casa) && casa.length > 0 &&
                                        casa !== undefined ?
                                        casa.map(item => ({ value: item.value, label: item.label })) : null}
                                onChange={(newValue) => selecionarCasa(newValue)}
                                placeholder="Selecione"
                            />
                        </div>

                        <div className="col-md-3 mt-2">
                            <label style={{ fontSize: '0.8rem' }}>Âmbito</label>
                            <SelectFiltro
                                css="no-border"
                                value={ambitoSelected}
                                options={ambito !== undefined && Array.isArray(ambito) && ambito.length > 0 ?
                                    ambito.map(item => ({ value: item.value, label: item.label })) : []}
                                onChange={(newValue) => selecionaAmbito(newValue)}
                                placeholder="Selecione"
                            />
                        </div>

                    </div>

                    <hr style={{ backgroundColor: 'black' }} />

                    <Typography variant="h6" style={{ color: 'white', marginTop: '30px' }} >
                        Informações do Parlamentar
                    </Typography>

                    <div className="row">

                        <div className="col-md-4 mt-2">
                            <label style={{ fontSize: '0.8rem' }}>Idade</label>
                            <SelectFiltro
                                css="no-border"
                                value={idadeSelected}
                                options={idade !== undefined && idade.filter(item => ({ value: item.value, label: item.label }))}
                                onChange={(newValue) => selecionarIdade(newValue)}
                                placeholder="Selecione"
                            />
                        </div>

                        <div className="col-md-4 mt-2">
                            <label style={{ fontSize: '0.8rem' }}>Profissão</label>
                            <SelectFiltro
                                css="no-border"
                                value={profissaoSelected}
                                options={
                                    profissao !== undefined && Array.isArray(profissao) && profissao.length > 0 ?
                                        profissao.map(item => ({ value: item.value, label: item.label })) : null}
                                onChange={(newValue) => selecionarProfissao(newValue)}
                                placeholder="Selecione"
                            />
                        </div>

                        <div className="col-md-4 mt-2">
                            <label style={{ fontSize: '0.8rem' }}>Escolaridade</label>
                            <SelectFiltro
                                css="no-border"
                                value={escolaridadeSelected}
                                options={
                                    escolaridade !== undefined && Array.isArray(escolaridade) && escolaridade.length > 0 ?
                                        escolaridade.map(item => ({ value: item.value, label: item.label })) : null}
                                onChange={(newValue) => selecionarEscolaridade(newValue)}
                                placeholder="Selecione"
                            />
                        </div>

                    </div>

                    {AuthStore.ehEsforcoColaborativo === true ? null :
                        <div>

                            <div className="mt-4">
                                <hr style={{ backgroundColor: 'black' }} />
                            </div>

                            <Typography variant="h6" style={{ color: 'white', marginTop: '30px' }} >
                                Qualificação
                            </Typography>

                            <FormGroup row aria-label="position">

                                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}   >
                                    <div className="mt-3">
                                        <label className="container-new-checkbox"> Convergente
                                            <input type="checkbox" value="3" id="selectAtualizacaoRecente" onChange={event => qualificacaoConvergente(event)} checked={convergente} />
                                            <span className="checkmark-new-checkbox"></span>
                                        </label>
                                    </div>
                                    {/* <FormControlLabel
                                    control={<Checkbox style={{color: 'white'}} value="3" id="selectAtualizacaoRecente" onChange={event => qualificacaoConvergente(event)} checked={convergente}/>}
                                    label="Convergente" 
                                /> */}
                                </Grid>
                                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}   >
                                    <div className="mt-3">
                                        <label className="container-new-checkbox"> Divergente
                                            <input type="checkbox" value="3" id="selectAtualizacaoRecente" onChange={event => qualificacaoDivergente(event)} checked={divergente} />
                                            <span className="checkmark-new-checkbox"></span>
                                        </label>
                                    </div>
                                    {/* <FormControlLabel
                                    control={<Checkbox style={{color: 'white'}} value="3" id="selectAtualizacaoRecente" onChange={event => qualificacaoDivergente(event)} checked={divergente}/>}
                                    label="Divergente" 
                                /> */}
                                </Grid>
                                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}   >
                                    <div className="mt-3">
                                        <label className="container-new-checkbox"> Não Classificado
                                            <input type="checkbox" value="3" id="selectAtualizacaoRecente" onChange={event => qualificacaoNaoClassificado(event)} checked={naoClassificado} />
                                            <span className="checkmark-new-checkbox"></span>
                                        </label>
                                    </div>
                                    {/* <FormControlLabel
                                    control={<Checkbox style={{color: 'white'}} value="3" id="selectAtualizacaoRecente" onChange={event => qualificacaoNaoClassificado(event)} checked={naoClassificado}/>}
                                    label="Não Classificado" 
                                /> */}
                                </Grid>
                                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}   >
                                    <div className="mt-3">
                                        <label className="container-new-checkbox"> Neutro
                                            <input type="checkbox" value="3" id="selectAtualizacaoRecente" onChange={event => qualificacaoNeutro(event)} checked={neutro} />
                                            <span className="checkmark-new-checkbox"></span>
                                        </label>
                                    </div>
                                    {/* <FormControlLabel
                                    control={<Checkbox style={{color: 'white'}} value="3" id="selectAtualizacaoRecente" onChange={event => qualificacaoNeutro(event)} checked={neutro}/>}
                                    label="Neutro" 
                                /> */}
                                </Grid>
                            </FormGroup>

                        </div>
                    }

                    <hr style={{ backgroundColor: 'black' }} />

                    <div className="row mt-5 mb-4">

                        <div className="col-4 text-left">
                            <span onClick={limparFiltro} style={{ calor: 'white', backgroundColor: 'transparent', cursor: 'pointer' }}>Limpar Filtro</span>
                        </div>
                        <div className="col-5 text-right">
                            <button onClick={filtrar} variant="contained" className="btn btn-dark">
                                <img
                                    className="mr-3"
                                    src={require('../../design/assets/images/filtar.png')}
                                    // src={require('../../design/assets/images/teste-botao-filtro.svg')}
                                    alt="anexo"
                                />{' '}
                                Filtrar
                            </button>
                        </div>
                        <div className="col-3 text-right">
                            <button onClick={handleClose} variant="contained" className="btn btn-dark">
                                Fechar
                            </button>
                        </div>
                    </div>




                </DialogContent>

            </Dialog>
        </div>
    );
}

