import _ from "lodash"
import { observable, action } from "mobx"

class PaginacaoStore {
    disparador = observable.box(_.uniqueId())
    @observable limite = 20
    @observable ultimo = -20
    @observable altura = 0
    @observable buscados = [0]
    @observable parouDeBuscar = false

    constructor() {
        this.definirLimite = this.definirLimite.bind(this)
        this.aoRolar = this.aoRolar.bind(this)
    }

    @action
    zerar() {
        this.ultimo = this.limite * -1
        this.altura = 0
        this.buscados = [0]
        this.parouDeBuscar = false
    }

    @action
    definirLimite(limite) {
        this.limite = limite
        this.ultimo = limite * -1
    }

    @action
    buscou(ultimo) {
        this.ultimo = ultimo
        this.buscados = [...this.buscados, ultimo]
        this.buscado = false
    }

    @action
    pararDeBuscar() {
        this.parouDeBuscar = true
    }

    @action aoRolar(event) {
        // console.log("funcaoROlar")
        
        const { scrollTop, scrollHeight, offsetHeight } = event.target
        //console.log('<<<<< ROLAR>>>>>>',scrollTop, scrollHeight, offsetHeight)
        const posicaoDoScroll = scrollHeight - scrollTop - offsetHeight
        const novoUltimo = this.ultimo + this.limite
        const chegouNaMetade = posicaoDoScroll < offsetHeight * 0.6
        const naoFoiBuscado = !this.buscados.find(x => x === novoUltimo)
        if (chegouNaMetade && naoFoiBuscado && !this.parouDeBuscar) {
            this.disparador.set(_.uniqueId())
        }
    }
}

export default new PaginacaoStore()