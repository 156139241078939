import React, { Component } from 'react'
// import { get, put } from '../../../services/http'
// import {  put } from '../../../services/http'

class searchUserInput extends Component {
    state = { temp: null, filtro: "", open: false, value: this.props.value || null, multi: this.props.multi || false, idAcao: {}}

    constructor(props) {
        super(props)
        this.iptFocus = React.createRef()
    }

    preencher = async () => {
        if (!this.props.lista) return;

        let temp = this.props.multi
            ? this.props.lista.filter(item => this.props.value && this.props.value.includes(item.id))
            : this.props.lista.find(item => item.id === this.props.value)
        this.setState({ multi: (this.props.multi || false), temp })
    }

    componentWillMount = async () => await this.preencher()
    async componentDidUpdate(prevProps) {
        if (this.props.value && prevProps.value !== this.props.value) await this.preencher()
    }

    async autocomplete(event) {
        event.preventDefault()
        event.stopPropagation()
        const { keyCode, target: { value } } = event;
        if (!event.target) return;
        this.setState({ filtro: value, open: keyCode !== 13 })
    }

    filtro = () => {
        let disponiveis = this.props.lista;
        if (this.state.temp && !this.state.multi) disponiveis = this.props.lista.filter(u => u.id !== this.state.temp.id);
        if (this.state.temp && this.state.multi) disponiveis = this.props.lista.filter(u => !this.state.temp.map(s => s.id).includes(u.id));
        return disponiveis.filter(elem => elem.nome.toLowerCase().match(new RegExp(this.state.filtro.toLowerCase())))
    }

    getIds = () => this.props.multi ?
    this.state.temp !== undefined && Array.isArray(this.state.temp) && this.state.temp.length > 0
     ? this.state.temp.map(elem => elem.id) : null : this.state.temp.id
     openList = () => this.setState({ open: !this.state.open })

    selecionar = (item) => {
        console.log('item', item)
        if (this.state.multi) {
            let selects = this.state.temp || []
            
            if (!selects.includes(item)) {
                selects.push(item)
                this.setState({ temp: selects, open: false }, () => { this.props.onChange(this.getIds()) })
            } 
        }
        else this.setState({ temp: item, open: false }, () => { this.props.onChange(this.getIds()) })

        this.iptFocus.current.value = ""
        this.focus()
    }


    removerItem = async (item) => {
        // let responsavelList = this.props.ResponsavelEsforco;
        // let itemListaEspecifico = responsavelList.indexOf(item.id_usuario)
        // if (itemListaEspecifico > -1) {
        //     responsavelList.splice(itemListaEspecifico, 1);
        // }
        // console.log("this.props.array", typeof responsavelList)
        

        let { temp } = this.state
        let index = temp.indexOf(item)
        if (index > -1) {
            temp.splice(index, 1)
            //console.log('temp', temp)
            this.setState({ temp }, () => { this.props.onChange(this.getIds())})
            this.focus()
            // const reponsavelNotasTecnicas = { 
            //     id_acao_preparatoria: parseInt(this.props.idAcaoPreparatoriaSearch) , 
            //     id_responsavel: item.id_usuario,   
            // }
            // try {
            //     await put(`/acao-preparatoria/nota-tecnica/excluir-responsavel`, reponsavelNotasTecnicas)
            // } catch (e) {
            //     console.log(e)
            // }   
        }

        

    }
   

    renderSelect = () => {
        if (Array.isArray(this.state.temp) > 0)
            return this.state.temp !== undefined && Array.isArray(this.state.temp) && this.state.temp.length > 0 ?
             this.state.temp.map((item, index) => <span id={`${this.props.id}-${index}`} 
             className="badge badge-multi fs-12 cursor-pointer" key={index} onClick={() =>
                this.removerItem(item)}>{item.nome}
                  <span className="label-esforco-colaborativo">X</span></span> ) : null
        else
            return <span id={this.props.id} className="badge badge-multi fs-12 cursor-pointer">{this.state.temp.nome} <span className=""></span></span>
    }

    focus = async () => {
        await this.setState({ open: true })
        this.iptFocus.current.focus()
        this.iptFocus.current.value = ""
    }
    render() {

        return this.props.lista && this.props.lista.length > 0  ?
            (<div className={`control-multi  cursor-pointer ${this.state.open && 'active'}`}
                onClick={(event) => { event.preventDefault(); this.focus() }}
                onFocus={() => { if (!this.state.focus) this.setState({ focus: true }) }}
                onBlur={() => setTimeout(() => { if (this.state.focus && this.state.open) this.setState({ focus: false, open: false }) }, 1000)}
            >
                
                {this.state.temp && this.renderSelect()}
                <input type="text" autoComplete="off" onKeyUp={(event) => this.autocomplete(event)} ref={this.iptFocus} />
                {!this.state.open ? null :
                    <div className="mascara">
                        <ul className="list-group">
                            {
                                (this.filtro() || []).map((item, index) =>
                                    <li  className="list-group-item" key={`${index}-search`} onClick={() => this.selecionar(item)}>{item.nome}</li>
                                )
                            }
                        </ul>
                    </div>
                } 
                
            </div>) : null
    }
}

export default searchUserInput