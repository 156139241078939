import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                labels: ['Êxito', 'Sem êxito', 'Êxito Parcial', 'Pendente'],
                colors: ['#4CD964', '#F63854','#5856D7','#D8D8D8'],
                legend: {
                    show: false,

                }

            }
        }
    }


    render() {
        const seriesProps = [
            this.props.dados.exitoGrafico !== undefined ? this.props.dados.exitoGrafico : 0,
            this.props.dados.semExitoGrafico !== undefined ? this.props.dados.semExitoGrafico : 0,
            this.props.dados.parcialGrafico !== undefined ? this.props.dados.parcialGrafico : 0,
            this.props.dados.pendenteGrafico !== undefined ? this.props.dados.pendenteGrafico : 0,
        ]
        return (
            <div className="donut divGraficoBuilding">
                <Chart
                    options={this.state.options}
                    series={seriesProps}
                    type="donut"
                />
            </div>
        );
    }
}

export default Donut;
