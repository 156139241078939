import React, { useState, useEffect, useContext } from "react"
import { withRouter } from "react-router-dom"
import { MidiaNewsContext } from "./index"
import Badge from "./badge"

const Form = ({ add, keyword, setKeyword }) => {
    
        const addAndClear = async ({ keyword }) => {

            if(keyword) {
                window.location.reload()
                await add({ keyword })
                setKeyword("")
            }
        }

        
        const addEnter = async({ key }) => {

            if(keyword) {
                key === "Enter" && await addAndClear({ keyword }, window.location.reload())
            }  
            
        }
           
    return (
        <div className="mx-3">
            {/* {console.log("aqui", keyword )} */}
            <div id="remover-bordas-inputs-midia" className="input-group">
                <input
                    maxLength="30"
                    minLength="5"
                    autoComplete="off"
                    required
                    type="text"
                    className="form-control"
                    value={keyword}
                    onChange={async ({ target }) =>
                        await setKeyword(target.value.trimLeft())
                    }
                    onKeyPress={async ({ key }) => await addEnter({ key })}
                    placeholder="Buscar por assuntos ou parlamentar"
                />
               
               <div className="input-group-append" onClick={() => addAndClear({ keyword })}>
                    <span className="input-group-text bg-white">
                        <i className="icon-search-small"></i>
                    </span>
                </div>
                
            </div>
        </div>
    )
}

const addModal = ({ history }) => {
    const { add } = useContext(MidiaNewsContext)
    const [keyword, setKeyword] = useState("")
    const close = () => {
        document.body.classList.toggle("modal-open")
        document
            .getElementById("modal")
            .removeAttribute("class", "modal-backdrop fade show")
        history.push("/midia")
        window.location.reload()
    }
    useEffect(() => {
        document.body.classList.toggle("modal-open")
        document
            .getElementById("modal")
            .setAttribute("class", "modal-backdrop fade show")
    })
    return (
        <div className="modal fade show d-block">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title mb-3"><b>Adicionar Palavras-Chave</b></h5>
                        <button
                            type="button"
                            className="close"
                            onClick={() => close()}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body p-0">
                        <Form
                            add={add}
                            keyword={keyword}
                            setKeyword={setKeyword}
                        />
                        <div className="mx-3 border-bottom">
                            <div className="mb-5 mt-3">
                                <Badge />   
                            </div>
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col-6 col-xl-6 p-3 linha-vertical-midia"  onClick={() => close()}  style={{ cursor: "pointer"}}>
                            <div className="text-center"><b>CANCELAR</b></div> 
                        </div>
                        <div className="col-6 col-xl-6 p-3" onClick={async () => { await add({ keyword }) 
                            close()}} style={{ cursor: "pointer"}}>
                            <div className="text-center"><b>SALVAR</b></div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(addModal)
