import React, { Component } from 'react';
import Loading from '../../../componentes/loading';
import Grafico from '../graficos/graficoProposicoesPorNivelPrioridade'
import moment from 'moment-timezone';
// import Pagination from "react-js-pagination";
import { get } from "../../../services/http"
import { inject, observer } from 'mobx-react'

moment.tz.setDefault('UTC');

@inject('authStore')
@observer

class ProposicaoPorNivelPrioridade extends Component {
    state = {
        loading: true,
        dataInicio: '',

        tiposQuantidade: [],

        resultadoRelatorioMinima: [],
        resultadoRelatorioBaixa: [],
        resultadoRelatorioMedia: [],
        resultadoRelatorioAlta: [],
        resultadoRelatorioMaxima: [],

        // paginaAtualMinima: 1,
        // limiteMinima: 3,
        // quantidadeMinima: 0,

        // paginaAtualBaixa: 1,
        // limiteBaixa: 3,
        // quantidadeBaixa: 0,

        // paginaAtualMedia: 1,
        // limiteMedia: 3,
        // quantidadeMedia: 0,

        // paginaAtualAlta: 1,
        // limiteAlta: 3,
        // quantidadeAlta: 0,

        // paginaAtualMaxima: 1,
        // limiteMaxima: 3,
        // quantidadeMaxima: 0,

        totalQuantidadeMinima: 0,
        totalQuantidadeBaixa: 0,
        totalQuantidadeMedia: 0,
        totalQuantidadeAlta: 0,
        totalQuantidadeMaxima: 0,

        //resposta backend
        minimaGrafico: 0,
        baixaGrafico: 0,
        mediaGrafico: 0,
        altaGrafico: 0,
        maximaGrafico: 0,

        tabelaMinima: []


    }

    async componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        await this.dadosPrioridadesProposicao()

        this.setState({
            dataInicio: moment(this.props.match.params.dtIni).format('DD/MM/YYYY'),
            dataFim: moment(this.props.match.params.dtFim).format('DD/MM/YYYY'),
        })

    }

    async dadosPrioridadesProposicao(page) {
        const dtIni = this.props.match.params.dtIni
        const dtFim = this.props.match.params.dtFim
        await get(`/relatorio/proposicoes/prioridades/${dtIni}/${dtFim}`)
            .then(response => {
                // console.log(response)
                const dados = response || []

                this.setState({
                    minimaGrafico: dados.minimaGrafico,
                    tabelaMinima: dados.tabelaMinima,
                    baixaGrafico: dados.baixaGrafico,
                    tabelaBaixa: dados.tabelaBaixa,
                    mediaGrafico: dados.mediaGrafico,
                    tabelaMedia: dados.tabelaMedia,
                    altaGrafico: dados.altaGrafico,
                    tabelaAlta: dados.tabelaAlta,
                    maximaGrafico: dados.maximaGrafico,
                    tabelaMaxima: dados.tabelaMaxima,
                    loading: false,

                })
            })
            .catch(err => console.error(err))

    }


    imprimir() {
        window.print()
    }


    render() {

        // const dominio = window.localStorage.getItem('dominio')
        // var logo = null
        // switch (dominio){
        //     case 'Client':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'blueClientlient':
        //         logo = 'icon-purpleClient corblueClientlient'
        //         break

        //     case 'darkBlueClient':
        //         logo = 'icon-logo_darkBlueClient cordarkBlueClientClient'
        //         break

        //     case 'dev':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'localhost':
        //         logo = `icon-purpleClient cordarkBlueClient`
        //         break

        //     default:
        //         logo = 'icon-logo-Client corClient'
        // }

        const dados = [
            this.state.minimaGrafico,
            this.state.baixaGrafico,
            this.state.mediaGrafico,
            this.state.altaGrafico,
            this.state.maximaGrafico,
        ]
        const dataDaPagina = `Período de Criação: ${this.state.dataInicio} a ${this.state.dataFim}`
        // console.log("tabelaMinima", this.state.tabelaMinima)

        return (
            this.state.loading ? <Loading /> :
                <div className="container paddingGeralXS">

                    <div id="non-printable" className="mt-5">
                        <button className="btn btn-danger" onClick={this.imprimir}>
                            <img
                                src={require('../../../design/assets/images/impressora.svg')}
                                alt="anexo"
                            />
                        </button>
                    </div>

                    <div className="pt-5">

                        <div className="row">
                        <div className="col-1" >
                        <a href="/">
                                <div className=" logoMenuRelatorios text-center"></div>
                                    {/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
                                </a>
                            </div>
                            <div className="col-11">
                                <div>
                                    <p className="tituloRelatorio">
                                        Relatório
                                        </p>
                                </div>
                                <div>
                                    <p className="descricaoTituloRelatorio">
                                        Quantidade de proposições classificadas a partir do seu nível de prioridade
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 text-center periodoFiltro">
                                <div className="">
                                    {dataDaPagina}
                                </div>
                            </div>
                        </div>


                        {/* GRAFICO  */}

                        <div className="row">

                            <div className="col-md-6 divGrafico" id="align-grafico">
                                <Grafico
                                    dados={dados}
                                />
                            </div>
                            <div className="col-md-6 align-self-center divIndice" id="divAlignIndice">
                                <div className="row">

                                    <div className="corIndiceMinima"></div>
                                    <div>
                                        <p className="textoIndiceMinima">
                                            Mínima
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceMinima">
                                            {this.state.minimaGrafico}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceBaixa"></div>
                                    <div>
                                        <p className="textoIndiceBaixa">
                                            Baixa
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceBaixa">
                                            {this.state.baixaGrafico}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceMedia"></div>
                                    <div>
                                        <p className="textoIndiceMedia">
                                            Média
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceMedia">
                                            {this.state.mediaGrafico}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceAlta"></div>
                                    <div>
                                        <p className="textoIndiceAlta">
                                            Alta
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceAlta">
                                            {this.state.altaGrafico}
                                        </p>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="corIndiceMaxima"></div>
                                    <div>
                                        <p className="textoIndiceMaxima">
                                            Máxima
                                            </p>
                                    </div>
                                    <div>
                                        <p className="totalIndiceMaxima">
                                            {this.state.maximaGrafico}
                                        </p>
                                    </div>

                                </div>
                            </div>

                        </div>

                        {/* GRAFICO MINIMA */}


                        <div className="mt-5">
                            <p className="tituloTabelaMinima">
                                Prioridade Mínima
                            </p>
                        </div>
                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleMinima">
                                <tr>
                                    <th className="headTipoMinima">Tipo de Proposição</th>
                                    <th className="headQuantMinima">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tabelaMinima !== undefined && Array.isArray(this.state.tabelaMinima) && this.state.tabelaMinima.length > 0 ?
                                    this.state.tabelaMinima.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="dadosColunaTipoMinima">{item[0]}</td>
                                                <td className="dadosColunaQuantMinima">{item[1]}</td>
                                            </tr>
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualMinima}
                            itemsCountPerPage={this.state.limiteMinima}
                            totalItemsCount={parseInt(this.state.quantidadeMinima)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaMinima}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}




                        {/* GRAFICO BAIXA */}


                        <div className="mt-5">
                            <p className="tituloTabelaBaixa">
                                Prioridade Baixa
                            </p>
                        </div>
                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleBaixa">
                                <tr>
                                    <th className="headTipoBaixa">Tipo de Proposição</th>
                                    <th className="headQuantBaixa">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tabelaBaixa !== undefined && Array.isArray(this.state.tabelaBaixa) && this.state.tabelaBaixa.length > 0 ?
                                    this.state.tabelaBaixa.map((item, index) => {
                                        return (
                                            <tr  key={index}>
                                                <td className="dadosColunaTipoBaixa">{item[0]}</td>
                                                <td className="dadosColunaQuantBaixa">{item[1]}</td>
                                            </tr>
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualBaixa}
                            itemsCountPerPage={this.state.limiteBaixa}
                            totalItemsCount={parseInt(this.state.quantidadeBaixa)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaBaixa}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}




                        {/* GRAFICO MÉDIA */}


                        <div className="mt-5">
                            <p className="tituloTabelaMedia">
                                Prioridade Média
                            </p>
                        </div>
                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleMedia">
                                <tr>
                                    <th className="headTipoMedia">Tipo de Proposição</th>
                                    <th className="headQuantMedia">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tabelaMedia !== undefined && Array.isArray(this.state.tabelaMedia) && this.state.tabelaMedia.length > 0 ?
                                    this.state.tabelaMedia.map((item, index) => {
                                        return (
                                            <tr  key={index}>
                                                <td className="dadosColunaTipoMedia">{item[0]}</td>
                                                <td className="dadosColunaQuantMedia">{item[1]}</td>
                                            </tr>
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualMedia}
                            itemsCountPerPage={this.state.limiteMedia}
                            totalItemsCount={parseInt(this.state.quantidadeMedia)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaMedia}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}



                        {/* GRAFICO ALTA */}


                        <div className="mt-5">
                            <p className="tituloTabelaAlta">
                                Prioridade Alta
                            </p>
                        </div>
                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleAlta">
                                <tr>
                                    <th className="headTipoAlta">Tipo de Proposição</th>
                                    <th className="headQuantAlta">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tabelaAlta !== undefined && Array.isArray(this.state.tabelaAlta) && this.state.tabelaAlta.length > 0 ?
                                    this.state.tabelaAlta.map((item, index) => {
                                        return (
                                            <tr  key={index}>
                                                <td className="dadosColunaTipoAlta">{item[0]}</td>
                                                <td className="dadosColunaQuantAlta">{item[1]}</td>
                                            </tr>
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualAlta}
                            itemsCountPerPage={this.state.limiteAlta}
                            totalItemsCount={parseInt(this.state.quantidadeAlta)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaAlta}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}



                        {/* GRAFICO MÁXIMA */}


                        <div className="mt-5">
                            <p className="tituloTabelaMaxima">
                                Prioridade Máxima
                            </p>
                        </div>
                        <table className="table table-striped ">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleMaxima">
                                <tr>
                                    <th className="headTipoMaxima">Tipo de Proposição</th>
                                    <th className="headQuantMaxima">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tabelaMaxima !== undefined && Array.isArray(this.state.tabelaMaxima) && this.state.tabelaMaxima.length > 0 ?
                                    this.state.tabelaMaxima.map((item, index) => {
                                        // console.log(item)
                                        return (

                                            < tr key={index}>
                                                <td className="dadosColunaTipoMaxima">{item[0]}</td>
                                                <td className="dadosColunaQuantMaxima">{item[1]}</td>
                                            </tr>
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>

                        {/* <Pagination
                            activePage={this.state.paginaAtualMaxima}
                            itemsCountPerPage={this.state.limiteMaxima}
                            totalItemsCount={parseInt(this.state.quantidadeMaxima)}
                            pageRangeDisplayed={10}
                            hideFirstLastPages={true}
                            onChange={this.carregarPaginaNovaMaxima}
                            prevPageText={<div className="prev-page"><img src={require('../../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                            nextPageText={<div className="next-page"><img src={require('../../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                        /> */}





                    </div>

                </div >

        )
    }
}

export default ProposicaoPorNivelPrioridade