import React, { Component } from 'react';
import Loading from '../../../componentes/loading';
import moment from 'moment-timezone';
import { get } from "../../../services/http"
import { inject, observer } from 'mobx-react'

moment.tz.setDefault('UTC');

@inject('authStore')
@observer

class ProposicaoNovaPorPeriodo extends Component {
    state = {
        loading: true,
        dadosTabelas: []
    }

    async componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        await this.getDadosProposicaoPorTema()
    }

    async getDadosProposicaoPorTema() {
        const dtIni = this.props.match.params.dtIni
        const dtFim = this.props.match.params.dtFim
        await get(`/relatorio/proposicoes/temas/${dtIni}/${dtFim}`)
            .then(res => {
                const dados = res || []
                this.setState({
                    loading: false,
                    dadosTabelas: dados
                })
            })
    }

    imprimir() {
        window.print()
    }


    render() {

        // const dominio = window.localStorage.getItem('dominio')
        // var logo = null
        // switch (dominio) {
        //     case 'Client':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'blueClient':
        //         logo = 'icon-purpleClient corblueClient'
        //         break

        //     case 'darkBlueClient':
        //         logo = 'icon-logo_darkBlueClient cordarkBlueClient'
        //         break

        //     case 'dev':
        //         logo = 'icon-logo-Client corClient'
        //         break

        //     case 'localhost':
        //         logo = `icon-purpleClient cordarkBlueClient`
        //         break

        //     default:
        //         logo = 'icon-logo-Client corClient'
        // }

        const dataDaPagina = `Período de Apresentação: ${moment(this.props.match.params.dtIni).format('DD/MM/YYYY')} a ${moment(this.props.match.params.dtFim).format('DD/MM/YYYY')}`

        return (
            this.state.loading ? <Loading /> :
                <div className="container paddingGeralXS">

                    <div id="non-printable" className="mt-5">
                        <button className="btn btn-danger" onClick={this.imprimir}>
                            <img
                                src={require('../../../design/assets/images/impressora.svg')}
                                alt="anexo"
                            />
                        </button>
                    </div>

                    <div className="pt-5">

                        <div className="row">
                        <div className="col-1" >
                        <a href="/">
                                <div className=" logoMenuRelatorios text-center"></div>
                                    {/* <i className={`var(--logoRelatorios) sizeLogoRelatorio`} ></i> */}
                                </a>
                            </div>
                            <div className="col-11">
                                <div>
                                    <p className="tituloRelatorio">
                                        Relatório
                                        </p>
                                </div>
                                <div>
                                    <p className="descricaoTituloRelatorio">
                                        Quantidade total de proposições divididos por temas
                                    </p>
                                </div>
                            </div>

                            <div className="col-12 text-center periodoFiltro">
                                <div className="">
                                    {dataDaPagina}
                                </div>
                            </div>
                        </div>


                        <div className="mt-5">
                            <p className="tituloTabelaCamara">
                                Câmara
                            </p>
                        </div>

                        <table className="table table-striped">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleCamara">
                                <tr>
                                    <th className="headTipoCamara">Tema</th>
                                    <th className="headQuantCamara">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dadosTabelas !== undefined && Array.isArray(this.state.dadosTabelas) && this.state.dadosTabelas.length > 0 ?
                                    this.state.dadosTabelas.map((item, index) => {
                                        return (
                                            item.casa === 'CD' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoCamara">{item.tema}</td>
                                                    <td className="dadosColunaQuantCamara">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>



                        <div className="mt-5">
                            <p className="tituloTabelaSenado">
                                Senado
                            </p>
                        </div>
                        <table className="table table-striped">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleSenado">
                                <tr>
                                    <th className="headTipoSenado">Tema</th>
                                    <th className="headQuantSenado">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dadosTabelas !== undefined && Array.isArray(this.state.dadosTabelas) && this.state.dadosTabelas.length > 0 ?
                                    this.state.dadosTabelas.map((item, index) => {
                                        return (
                                            item.casa === 'SF' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoSenado">{item.tema}</td>
                                                    <td className="dadosColunaQuantSenado">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>


                        <div className="mt-5">
                            <p className="tituloTabelaCongresso">
                                Congresso
                            </p>
                        </div>
                        <table className="table table-striped table-responsive-sm">
                            {/* style={{ backgroundColor: '#9B9B9B', color: 'white', }} */}
                            <thead className="theadStyleCongresso">
                                <tr>
                                    <th className="headTipoCongresso">Tema</th>
                                    <th className="headQuantCongresso">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dadosTabelas !== undefined && Array.isArray(this.state.dadosTabelas) && this.state.dadosTabelas.length > 0 ?
                                    this.state.dadosTabelas.map((item, index) => {
                                        return (
                                            item.casa === 'CN' && item.quantidade !== 0 ?
                                                <tr key={index}>
                                                    <td className="dadosColunaTipoCongresso">{item.tema}</td>
                                                    <td className="dadosColunaQuantCongresso">{item.quantidade}</td>
                                                </tr>
                                                : null
                                        )
                                    })
                                    : null}
                            </tbody>
                        </table>


                    </div>

                </div>

        )
    }
}

export default ProposicaoNovaPorPeriodo