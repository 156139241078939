
import React from 'react';


export default function FileBase64(props) {
    

  function handleFile(e) {
      // Pego o arquivo 
      const imagem = e.target.files[0];
   
      //console.log(imagem.type)
      if(imagem.type.startsWith("image") === true){
          if(Math.round(imagem.size / 1000) <=1024){
                
                const arrayImgFile = []

                // Novo FileReader
                let reader = new FileReader();

                // Converto o arquivo para base64
                reader.readAsDataURL(imagem);

                // carrego o arquivo ...
                reader.onload = () => {

                // Crio um objeto fileInfo com info do arquivo
                let fileInfo = {
                    name: imagem.name,
                    type: imagem.type,
                    size: Math.round(imagem.size / 1000) + ' kB',
                    base64: reader.result,
                    file: imagem,
                };

                arrayImgFile.push(fileInfo)
                //passo os dados para o modal
                props.onDone(arrayImgFile);
                

                } 
            //console.log('>>>>>>',imgFile)
          }
    
      }else{
          alert('tipo de arquivo não suportado!!!')
      }

    

  }


    return (
        <div className="icone-editar-foto">                     
            <span className="d-flex justify-content-center align-items-center" style={{marginTop:'5px'}}>
            
            <label  >
                <input onChange={handleFile} id="tipofile" type="file" name="img_input" value="" style={{display:'none'}}/>
                <i  style={{color:'#FFFFFF', cursor:'pointer'}} className="icon-icon-edit-photo-usuarios fs-20"></i>
            </label>
           
            </span>
        </div>
    );
  
}