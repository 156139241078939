import React, { Component } from 'react';
import Chart from 'react-apexcharts'

class Donut extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                labels: ['Convergente', 'Divergente', 'Não Classificado', 'Neutro'],
                colors: ['#4CD964','#F63854', '#5856D7', '#D8D8D8' ],
                legend: {
                    show: false,
                }
            },
            series: [
                0, 1, 2, 3
            ],
        }
    }


    render() {
        const larguraTela = window.innerWidth
        const larguraGrafico = larguraTela > 375 ? '330' : '260'
        const series1 = [
            this.props.propQualificacaoParlamentarConvergente !== undefined ? parseInt(this.props.propQualificacaoParlamentarConvergente) : 0,
            this.props.propQualificacaoParlamentarDivergente !== undefined ? parseInt(this.props.propQualificacaoParlamentarDivergente) : 0,
            this.props.propQualificacaoParlamentarNaoClassificado !== undefined ? parseInt(this.props.propQualificacaoParlamentarNaoClassificado) : 0,
            this.props.propQualificacaoParlamentarNeutro !== undefined ? parseInt(this.props.propQualificacaoParlamentarNeutro) : 0,
        ]
        const series2 = [0,0,0,0]
        const series = this.props.propQualificacaoParlamentarConvergente !== undefined && this.props.propQualificacaoParlamentarDivergente !== undefined && this.props.propQualificacaoParlamentarNaoClassificado !== undefined && this.props.propQualificacaoParlamentarNeutro !== undefined  ? series1 : series2
        // console.log('TESTE AQUI DONUT ===>',this.props.dados)

        return (
            <div className="donut">
                <Chart 
                    options={this.state.options} 
                    series={series} 
                    type="donut" 
                    width={larguraGrafico}
                />
            </div>
        );
    }
}

export default Donut;