import React, { useEffect } from 'react';
import { get } from "../../services/http";
// import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import ClientSelect from '../../componentes/forms/basic/clientSelectModalCadUser'
import LoadingCircle from '../../componentes/loadingCircle'
//import { get } from 'lodash';




export default function FormDialog(props) {
  const [loadingCircle, setLoadingCircle] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [usuariosDep, setUsuariosDep] = React.useState([])
  const [usuarioSelecionado, setUsuariosSelecionado] = React.useState([])

  const [nomeDepartamento, setNomeDepartamento] = React.useState('')
  const [arrayUsuarioDepto, setArrayUsuarioDepto] = React.useState([])

  //armazena os erros dos campos em um objeto
  const [errors, setErrors] = React.useState({})
  //Faço a validação do Form, se existir erro >> true, se nao false, seto inicialmente com true para nao chamar a função dentro do UseEffect
  const [validaErrors, setValidaErrors] = React.useState(true)



  // Metodos que iniciam logo no inicio da montagem do componente deve ser coloca primeiro
  const getUsuariosDepto = async () => {
    setLoadingCircle(true)
    await get(`/departamento/lista-membros`)
      .then(res => {
        //console.log('DEPARTAMENTO>>',res)
        setLoadingCircle(false)
        setUsuariosDep(res)
      })
      .catch(err => err)

  }

  useEffect(() => {

    //Se não existir erros, e validaErrors false
    //coloco a consta validaErrors, para ficar verifcando se ouve atualização nela.
    if (Object.keys(errors).length === 0 && validaErrors === false) {
      handleSaveUser()
    }


    //willMount
    return () => { getUsuariosDepto() }


  }, [validaErrors])

  function validate() {

    console.log('validate')
    let messageError = '*Campo Obrigatorio'
    let errors = {}

    if (!nomeDepartamento) {
      errors.departamento = messageError
    }


    //atualizo a const, se existir erros, true, se não false
    //Object.keys(errors).length verifica se existe conteudo dentro do objeto
    Object.keys(errors).length === 0 ? setValidaErrors(false) : setValidaErrors(true)

    return errors
  }

  //função intermediaria que auxilia na validação dos campos
  const handleSubmit = () => {

    setErrors(validate())
    console.log(validate())
    //retorna um objeto de errors
    //exemplo {departamento: "Erro" , nome: "Erro"}



  }

  const handleSaveUser = () => {

    const data = {
      nome_departamento: nomeDepartamento,
      array_usuarios: arrayUsuarioDepto,
    }

    props.callbackParent(data, 'Cadastrar')
    setOpen(false);

    console.log('SALVAR USUARIO', data)
  }

  const handleClickOpen = () => {
    setOpen(true);
    setNomeDepartamento('')
    setArrayUsuarioDepto([])
    getUsuariosDepto()
    setValidaErrors(true)
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleChange = (event) => {

    setNomeDepartamento(event.target.value)

  }

  const handleChangeSelectUsu = (event) => {
    //console.log('CCCC>>>>',event)
    setUsuariosSelecionado(event)

  }

  const addUsuario = () => {
    //console.log(usuarioSelecionado)
    if (usuarioSelecionado.length !== 0) {
      setArrayUsuarioDepto([...arrayUsuarioDepto,
      {
        id_usuario: usuarioSelecionado.value,
        url_foto: usuarioSelecionado.url_foto,
        nome_completo: usuarioSelecionado.label
      }])

      setUsuariosSelecionado([])
    }
  }



  const handleDel = (id) => {

    //faço uma copia do array antigo e add em um novo array
    const newArrayMembros = arrayUsuarioDepto.slice(0)
    //console.log('>>',newArrayPalavras)

    //Pego o index do array escolhido para exclusao da lista
    const index = newArrayMembros.findIndex(membros => membros.id === id)
    //console.log('>>',index)

    //removo a palavra selecionada apartir do seu index do array
    newArrayMembros.splice(index, 1)
    //console.log ('>>',newArrayPalavras)

    //add o novo array ao estado atual do componente
    setArrayUsuarioDepto([...newArrayMembros])

    //console.log ('>>',arrayPalavras)


  }


  const useStylesButtonFooter = makeStyles({
    root: {
      color: '#3E4049',
      backgroundColor: 'White',
      '&:hover': {
        backgroundColor: '#FFF',
      },
    },

  })(Button);

  const inputStyle = {
    border: 'none',
    backgroundColor: 'transparent',
    borderBottom: '1px solid #C4C4C4',
    color: '#000000',
    marginTop: '10px',
    width: '100%',
    placeholder: 'red',
    outline: 'none'
  };
  //console.log('props>>>>>',props)

  const tamanhoTela = window.innerWidth

  //console.log(usuariosDep)
  return (


    <div>

      <button className='btn-usuario btn-block' style={{ borderRadius: "7px" }} onClick={handleClickOpen}>Cadastrar Departamento</button>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} maxWidth={'sm'}>
        <div className="mt-3 ml-3"><h5>Cadastrar Departamento</h5></div>
        <DialogContent
          style={{
            height: tamanhoTela > 700 ? 600 : tamanhoTela,
            width: tamanhoTela > 700 ? 600 : tamanhoTela,
            //backgroundColor: "#3E4049",
            //color: 'white'

          }}
          overflow="hidden"

        >

          <div className="row">
            <div className="col-md-12 mt-1">
              <label className="label-form-filtro-usuario">Nome do Depatartamento</label>
              <input
                name={'departamento'}
                type="text"
                style={inputStyle}
                onChange={handleChange}
                //onFocus={limparCampoNumero}
                placeholder="Digite"
                value={nomeDepartamento}
                id="docrelanoInput"
                autoComplete="off"

              />
              {errors.departamento && <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{errors.departamento}</span>}

            </div>
            <div className="col-md-9 mt-2">
              <label className="label-form-filtro-usuario" style={{ fontSize: '16px', color: '#3E4049' }}>Membros</label>
              <p style={{ fontSize: '12px', color: 'var(--btndangerbordercolor)' }}>Inserir usuário para este departamento</p>
              {
                loadingCircle
                  ?

                  <div className="d-flex justify-content-center mt-2" >
                    <LoadingCircle color={'#DC2D41'} size={'30px'} />
                  </div>
                  :
                  <ClientSelect

                    css="no-border"
                    value={usuarioSelecionado}
                    options={
                      usuariosDep !== undefined && Array.isArray(usuariosDep) && usuariosDep.length > 0 ?
                        usuariosDep.map((item) => ({ value: item.id_usuario, label: item.nome_completo + ' - ' + item.descricao, url_foto: item.url_foto })) : null}
                    onChange={(newValue) => handleChangeSelectUsu(newValue)}
                    placeholder={'Selecione'}
                  />
              }

            </div>
            <div className="col-md-3 mt-5">
              <button type="submit" className='btn-usuario btn-block' style={{ borderRadius: "7px" }} onClick={addUsuario}>+ Adicionar</button>
            </div>

          </div>

          <div className='mt-4 overflow-auto listaPalavras ' style={{ height: `${tamanhoTela > 1400 ? '320px' : '220px'}` }}>



            {
              arrayUsuarioDepto.length > 0 ?
                arrayUsuarioDepto.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className='mb-3 p-2  d-flex justify-content-between' style={{ border: '1px solid #E1E8F2', borderRadius: '6px' }}>

                        <div className={"d-flex justify-content-center"}>

                          <div className="icon-list-user">
                            {item.url_foto === '' || item.url_foto === null

                              ?
                              <div className="icon-list-user">
                                <i className="icon-icon_identity_24px" style={{ fontSize: '24px', color: '#FFF' }}></i>
                              </div>
                              :
                              <div>
                                <img className="img-foto-list-usuario" src={item.url_foto} alt='' />
                              </div>
                            }
                          </div>
                          <div className='fontsCitacoes p-2' style={{ fontSize: '14px' }} >{item.nome_completo}</div>

                        </div>

                        <div className={"p-2"}>
                          <i className='icon-lixeira pr-2 cursor-pointer ' style={{ color: '#9B9B9B' }} onClick={() => handleDel()}></i>
                        </div>


                      </div>
                    </div>

                  )
                })
                :
                <div className='m-3 d-flex flex-column text-center fontsCitacoes' style={{ fontSize: '16px' }}>
                  <span>A lista de usuários aparecerá aqui.</span>
                  <span>Você pode adicionar ou excluir a qualquer momento.</span>
                </div>
            }



          </div>



        </DialogContent>

        <Divider variant="middle" />

        <DialogActions >

          <Button fullWidth={true} autoFocus onClick={handleClose} className={useStylesButtonFooter.root} >
            CANCELAR
                </Button>

          <div className="linha"></div>

          {
            /* arrayUsuarioDepto.length > 0
                 ?*/
            <Button fullWidth={true} autoFocus onClick={handleSubmit} className={useStylesButtonFooter.root} >
              SALVAR
                        </Button>
            /*  :
                  <Button fullWidth={true} disabled >
                  SALVAR
                  </Button>*/

          }

        </DialogActions>



      </Dialog>
    </div>
  );
}