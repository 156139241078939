import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class Line extends Component {


    render() {
        const dados = this.props.dados
        const options1 = {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            grid: {
              row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
            },
            colors: ['#F63854']
        }

        const options2 = {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            grid: {
              row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories: ['Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            },
            colors: ['var(--linkText)']
        }

        const series = [{
            name: "Interações",
            data: [
                dados !== undefined ? dados[0].y : 0,
                dados !== undefined ? dados[1].y : 0,
                dados !== undefined ? dados[2].y : 0,
                dados !== undefined ? dados[3].y : 0,
                dados !== undefined ? dados[4].y : 0,
                dados !== undefined ? dados[5].y : 0,
            ],
        }]

        return (
            <div className="donut">
                <Chart 
                    options={dados !== undefined && new Date(dados[0].x).getMonth() === 0 ? options1 : options2} 
                    series={series} 
                    type="line" 
                    height={350}
                />
            </div>
        );
    }
}

export default Line;
