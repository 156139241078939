import React, { Component } from 'react';
import Loading from '../../componentes/loading'
// import LoadingA from '../../componentes/loading'
import { Row, Grid } from '../../estruturas/bootstrap'
import MediaItem from '../../componentes/mediaItem'
import Seguir from '../../componentes/seguir'
import moment from 'moment-timezone';
import { NavLink, Link } from 'react-router-dom'
import { get, post, put, del } from '../../services/http'
import Slider from 'react-slick'
import { inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import ClientSelect from '../../componentes/forms/basic/clientSelect'
import ClientSelectA from '../../componentes/forms/basic/clientSelectModalCadUser'
import { impactoFinanceiroOptions } from '../../enums/impactoFinanceiro'
// import { ClientPrioridades } from '../../enums/prioridades'
import { buscarListaPrioridades } from "../../../src/enums/prioridades"
import CurrencyFormat from 'react-currency-format';
import FormNotasTecnicas from './form/formNotasTecnicas'
// import Modal from './../../componentes/modal'
// import { withStyles } from '@material-ui/core/styles';
// import Switch from '@material-ui/core/Switch';
// components
import Tramitacao from './components/timeline'
import Acontecimentos from './components/acontecimento'
import Apensada from './components/apensadas'
import Correlatas from './components/correlatas'
import Emendas from './components/emendas'
import AcaoPreparatoria from './components/acaoPreparatoria'
import AcoesInfluencia from './components/acaoInfluencia'
// import FormAcaoInfluencia from './components/formAcaoInfluencia'
import { posicionamentosClient } from "../../enums/posicionamento";
import ConfirmModal from '../../componentes/confirmModal';
import AlertaStore from '../../stores/alertaStore'

// modal
import ModalAI from './modal/modalAI'
import ModalEmentaTexto from '../../paginas/proposicaoNova/modal/modalEmentaTextoBase'
// import { set } from 'lodash';

moment.tz.setDefault('UTC');

// const StatusSwitch = withStyles({
//     switchBase: {
//         color: '#FFF',
//         '&$checked': {
//             color: '#4CD964',
//         },
//         '&$checked + $track': {
//             backgroundColor: '#4CD964',
//         },
//     },
//     checked: {},
//     track: {},
// })(Switch);


//const obterCasa = codigo => ({ 'SF': 'no Senado', 'CD': 'na Câmara', 'CN': 'no Congresso' })[codigo] || 'Não identificado';
const obterNewLinkExterno = (idExterno, casa) => ({
    1: `https://www25.senado.leg.br/web/atividade/materias/-/materia/${idExterno}`,
    2: `https://www.camara.leg.br/proposicoesWeb/fichadetramitacao?idProposicao=${idExterno}`,
    3: `https://www25.senado.leg.br/web/atividade/materias/-/materia/${idExterno}`,
})[casa] || '';

@inject('alertaStore', 'authStore')
@withRouter

class ProposicaoEspecifica extends Component {
    state = {
        loading: true,
        dadosProposicao: {},
        dadosApensadas: {},
        autores: [],
        usuarios: [],
        edit: false,
        analiseImpacto: [],
        temas: {},
        apelido: '',
        valorImpactoFinanceiro: '',
        pushStatus: false,
        showModal: false,
        idPropodicaoNovo: "",
        activeSlide: 0,
        optionsListaPrioridades: [],

        // .......Classificação de Tema......
        listaGrupos: [],
        grupoEscolhido: [],
        listaTemas: [],
        temaSelecionado: [],
        subtemaSelecionado: [],
        listaSubtemas: [],
        erroGrupo: "",
        erroTema: "",
        erroSubtema: "",
        classificacaoTemaData: [],
        renderTema: false,
        renderSubtema: false,
        prioridadeSub: 0,
        numeroDaPrioridade: 0

    }

    componentDidMount() {
        this.getProposicao()
        this.getUsuarios()
        this.getPushStatus()
        this.buscarListaGrupos()

        //chamando a lista de prioridades do banco
        buscarListaPrioridades()
            .then(res => {
                this.setState({
                    optionsListaPrioridades: res,
                })
            })

    }


    async getProposicao() {

        await get(`proposicao/especifica/${this.props.match.params.id}`)
            .then(res => {
                // console.log('>>>>>>>', res)
                if (res.deleted) {

                    window.localStorage.setItem('filtroInput', '')
                    window.localStorage.setItem('prop_deleted', true)
                    window.localStorage.setItem('prop_deleted_decricao', res.descricao)
                    window.location.href = '/proposicao/lista'

                } else {
                    window.localStorage.removeItem('prop_deleted')
                    window.localStorage.removeItem('prop_deleted_decricao')
                    const dados = res || {}

                    if (dados.temas.length !== 0 && dados.temas[0].id_grupo && dados.temas[0].grupo) {

                        this.setState({
                            valueGrupoEscolhido: dados.temas[0].id_grupo,
                            labelGrupoEscolhido: dados.temas[0].grupo,
                            valueTemaEscolhido: dados.temas[0].id_tema,
                            labelTemaEscolhido: dados.temas[0].tema,
                            valueSubtemaEscolhido: dados.temas[0].id_subtema,
                            labelSubtemaEscolhido: dados.temas[0].subtema,
                            prioridadeSub: dados.temas[0].prioridade
                        })
                    }

                    this.setState({
                        dadosProposicao: dados.dados,
                        dadosApensadas: dados.apensadas,
                        autores: dados.autores,
                        analiseImpacto: dados.analiseImpacto,
                        classificacaoTemaData: dados.temas,
                        loading: false,
                        idPropodicaoNovo: dados.dados.id,

                    })

                    if (this.state.classificacaoTemaData.length > 0) {
                        this.setState({
                            grupoEscolhido: { "value": this.state.valueGrupoEscolhido, "label": this.state.labelGrupoEscolhido },
                            temaSelecionado: { "value": this.state.valueTemaEscolhido, "label": this.state.labelTemaEscolhido },
                            subtemaSelecionado: { "value": this.state.valueSubtemaEscolhido, "label": this.state.labelSubtemaEscolhido },
                            renderTema: true,
                            renderSubtema: true,
                        })
                        this.buscarListaTemas({ "value": this.state.valueGrupoEscolhido, "label": this.state.labelGrupoEscolhido })
                        this.buscarListaSubtemas({ "value": this.state.valueTemaEscolhido, "label": this.state.labelTemaEscolhido })
                    }

                }

            })
    }



    async buscarListaGrupos() {
        await get('/tema-proposicoes/grupo/lista')
            .then(resp => {
                const optionsListaGrupos = resp.map(item => ({
                    "value": item.id,
                    "label": item.descricao
                }))
                this.setState({
                    listaGrupos: optionsListaGrupos,
                })
            })
    }

    async changeGrupoSelecionado(value) {
        this.setState({
            grupoEscolhido: value,
        })
        this.buscarListaTemas(value)
    }


    async buscarListaTemas(value) {
        const data = {
            data: {
                idGrupo: value.value
            }
        }

        await post(`/tema-proposicoes/tema/lista/grupoId`, data)
            .then(resp => {
                const optionsListaTemas = resp.map(item => ({
                    "value": item.id,
                    "label": item.descricao
                }))
                this.setState({
                    listaTemas: optionsListaTemas,
                    erroTema: "",
                })
                if (this.state.listaTemas.length === 0) {
                    this.setState({
                        renderTema: false,
                        renderSubtema: false,
                        erroTema: "Este grupo não tem Tema cadastrado"
                    })
                } else {
                    this.setState({
                        renderTema: true,
                    })
                }
            })

    }


    changeTemaSelecionado(value) {
        if (this.state.subtemaSelecionado.length !== 0) {
            this.setState({
                erroSubtema: "",
                temaSelecionado: value,
                erroTema: "",
            })
            this.buscarListaSubtemas(value)
        } else {
            this.setState({
                temaSelecionado: value,
                erroTema: "",
            })
            this.buscarListaSubtemas(value)
        }

    }



    async buscarListaSubtemas(value) {

        const data = {
            data: {
                idTema: value.value
            }
        }
        await post(`/tema-proposicoes/subtema/lista/temaId`, data)
            .then(resp => {
                // console.log(resp.length)
                const optionsListaSubtemas = resp.map(item => ({
                    "value": item.id,
                    "label": item.descricao
                }))
                this.setState({
                    listaSubtemas: optionsListaSubtemas,
                    erroSubtema: "",
                    erroTema: ""
                })
                if (this.state.listaSubtemas.length === 0) {
                    this.setState({
                        renderSubtema: false,
                        erroSubtema: "Este Tema não tem Subtema cadastrado"
                    })
                } else {
                    this.setState({
                        rendertema: true,
                        renderSubtema: true,
                    })
                }

            })
    }



    async changeSelectSubtema(value) {

        this.setState({
            subtemaSelecionado: value,
            erroSubtema: ""
        })

        const idProposicao = this.props.match.params.id
        const estrategiaURL = `proposicao/${idProposicao}/dados`
        const data = { id_subtema: value.value }
        put(`proposicao/${this.props.match.params.id}/dados`, data)
            .then(resp => {
                if (resp.id_subtema !== null || resp.id_subtema !== undefined || resp.id_subtema !== 0) {
                    AlertaStore.criar("Classificação de Tema registrada com sucesso")
                } else {
                    AlertaStore.erro("Problemas com o registro da Classificação de Tema")
                }
            })

        if (this.state.analiseImpacto.prioridade === 0 || this.state.analiseImpacto.prioridade === undefined) {
            const info = {
                "data": {
                    "subTemaId": value.value
                }
            }

            post('/tema-proposicoes/subtema/obterId', info)
                .then(resposta => {
                    this.setState({
                        numeroDaPrioridade: resposta[0].id_prioridade
                    })
                    this.alterarEstrategia(estrategiaURL, "Estratégia de Atuação alterada com sucesso.", "prioridade", `${this.state.numeroDaPrioridade}`, true)
                    this.getProposicao()

                })
        }


    }


    erroSubtema() {
        this.setState({
            erroSubtema: "Primeiro selecione um Tema!"
        })
    }



    async getUsuarios() {
        await get(`/proposicao/obter-usuarios`)
            .then(res => {
                //console.log('USUARIO>>>',res) 
                this.setState({ usuarios: res })
            })
    }
    async getPushStatus() {
        await get(`/notificacao/push-status/${this.props.match.params.id}`)
            .then(res => {
                if (res.length > 0) {
                    this.setState({ pushStatus: res[0].push_tramitacao })
                }
            })
    }

    async pushNotificacaoSalvar(status) {
        const data = { status: status.target.checked, idProposicao: this.props.match.params.id }
        //console.log(data)
        await post('/notificacao/push-tramitacao', data)
            .then(res => {
                //
            })
            .catch(err => err)
        await this.getPushStatus()

    }





    async alterar(url, message = 'Estratégia de Atuação alterada com sucesso.', prop, value, salvar, evento) {
        let obj = {};
        if (evento && evento.keyCode === 27) this.setState({ edit: false })
        // this.props.onChange(prop, value)
        obj[prop] = value;
        this.setState(obj)
        if (evento && evento.keyCode === 13) { evento.preventDefault(); salvar = true; }

        if (salvar) {
            this.setState({ edit: false })
            await put(url, obj)
                .then(res => {
                    this.props.alertaStore.criar(message)
                    this.setState({ loading: false })
                    this.getProposicao()
                })
                .catch(err => err)
        }
    }


    async alterarEstrategia(url, message = 'Estratégia de Atuação alterada com sucesso.', prop, value, salvar, evento) {

        let estrategia = {};
        if (evento && evento.keyCode === 27) this.setState({ edit: false })
        estrategia[prop] = value;
        this.setState({ estrategia })


        if (evento && evento.keyCode === 13) { evento.preventDefault(); salvar = true; }

        if (salvar) {
            this.setState({ edit: false })
            await put(url, estrategia)
                .then(res => {
                    this.props.alertaStore.criar(message)
                    this.getProposicao()
                    this.setState({ loading: false })
                })
                .catch(err => err)
        }
    }

    async alterarPosicionamento(url, novoPosicionamento) {
        this.setState({ posicaoDaOrganizacao: novoPosicionamento })
        await put(url, { posicaoDaOrganizacao: novoPosicionamento })
            .then(res => {
                this.props.alertaStore.criar('Posicionamento alterado com sucesso.')
                this.getProposicao()
                this.setState({ loading: false })
            })
            .catch(err => err)

    }

    aplicarFiltroInput(event) {
        this.setState({ descricao: event.target.value })
    }

    async listarProposicao() {
        await window.localStorage.removeItem('filtroGrafico')
        await window.localStorage.removeItem('filtroProposicao')
        await window.localStorage.setItem('filtroInput', this.state.descricao)
        window.location.href = '/proposicao/lista'
    }

    impactoFinanceiroEhVisualizador() {
        const impactoFinanceiroVisualizador = impactoFinanceiroOptions.find(item => item.id === this.state.analiseImpacto.fatorImpactoFinanceiro)
        return impactoFinanceiroVisualizador !== undefined ? impactoFinanceiroVisualizador.value : 'Não classificado'
    }

    prioridadeEhVisualizador() {
        const prioridadeEhVisualizador = this.state.optionsListaPrioridades.find(item => item.id === this.state.analiseImpacto.prioridade)
        return prioridadeEhVisualizador !== undefined ? prioridadeEhVisualizador.value : 'Não classificado'
    }

    posicionamentEhVisualizador() {
        const posicionamentEhVisualizador = posicionamentosClient.find(item => item.id === this.state.analiseImpacto.posicaoDaOrganizacao)
        return posicionamentEhVisualizador ? posicionamentEhVisualizador.value : 'Não classificado'
    }


    changeShowModalState(showModal) {
        this.setState({ showModal })
    }

    async excluirProposicao(showModal, save, idProposicao) {
        this.setState({ showModal })

        if (save) {
            await del(`/proposicao/excluir-proposicao/${idProposicao}`)
                .then(res => {

                    if (res.length > 0) {
                        this.props.alertaStore.criar('Proposição excluida com sucesso.')
                        setTimeout(() => window.location.href = '/proposicao/lista', 2000)
                    } else {
                        this.props.alertaStore.criar('Erro ao excluir proposicão.')
                    }

                })
                .catch(err => err)
        }
    }


    render() {
        // console.log("data e hora ultima atualizacao", this.state.dadosProposicao.status_data_hora)
        // console.log('prioridade Analise de Impacto', this.state.analiseImpacto.prioridade);
        const tamanhoTela = window.innerWidth
        const urlPathname = window.location.pathname.split('/')

        let SlideActive

        if (tamanhoTela > 600) {
            if (urlPathname[3] === 'acontecimentos' || urlPathname[3] === 'timeline' || urlPathname[3] === 'acao-preparatoria' || urlPathname[3] === 'acoes-influencia') {

                SlideActive = 0

            } else if (urlPathname[3] === 'apensadas' || urlPathname[3] === 'correlatas' || urlPathname[3] === 'emendas') {

                SlideActive = 4

            }
        } else {

            switch (urlPathname[3]) {

                case 'acontecimentos':
                    SlideActive = 0
                    break;

                case 'timeline':
                    SlideActive = 1
                    break;

                case 'acao-preparatoria':
                    SlideActive = 2
                    break;

                case 'acoes-influencia':
                    SlideActive = 3
                    break;

                case 'apensadas':
                    SlideActive = 4
                    break;

                case 'correlatas':
                    SlideActive = 5
                    break;

                case 'emendas':
                    SlideActive = 6
                    break;


                default:
                    break;
            }

        }


        const sliderSettings = {
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: true,
                        infinite: false,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        arrows: true,
                        infinite: false,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: true,
                        infinite: false,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };


        const sliderSettingsMenu = {
            dots: false,
            infinite: false,
            focusOnSelect: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            swipeToSlide: true,
            dontAnimate: true,

            //beforeChange: (current, next) => this.setState({ activeSlide: next }),
            //afterChange: current => this.setState({ activeSlide: current }),
            initialSlide: SlideActive,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: false,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };





        const idProposicaoCasaExterna = `${this.state.dadosProposicao.casa}${this.state.dadosProposicao.id_proposicao}`
        // console.log(">>>>>>>", this.state.dadosProposicao)
        const idProposicao = this.props.match.params.id
        // console.log(">>>>>: ",idProposicao)
        const estrategiaURL = `proposicao/${idProposicao}/dados`
        // let estrategia = this.state.estrategia || {}

        const prop_integrada = this.state.dadosProposicao.integrada

        // console.log('>>>>', this.state.dadosProposicao.integrada)
        return (
            this.state.loading ? <Loading /> :




                <div>

                    <ConfirmModal
                        text={'Tem certeza que deseja excluir essa proposição?'}
                        description={'Esta ação não poderá ser desfeita no futuro'}
                        onClose={(show, save, item) => this.excluirProposicao(show, save, idProposicao)}
                        show={this.state.showModal}
                        item={idProposicao}
                    />

                    <div className=" bg-white pl-2 marginTopXs" style={{ boxShadow: "-3px 0px 10px #C0C0C0" }}>
                        <p style={{ fontSize: "10px", color: "var(--linkText)", fontWeight: "bold", padding: "15px" }}>
                            {/* PROPOSIÇÃO FEDERAL ESPECÍFICA - <span>{this.state.dadosProposicao.descricao}</span> */}
                            PROPOSIÇÃO
                        </p>
                    </div>
                    <div className="container-fluid mt-5 pt-2">
                        <div className='row mt-3 mb-3' >
                            <div className={this.props.authStore.ehEsforcoColaborativo === true || this.props.authStore.ehVisualizador === true ? 'col-12' : 'col-md-9'}>
                                <div className="input-group  icon-input">
                                    <input className="form-control py-2 border-right-0 border" id="pesquisar-proposicao" type="search" autoComplete="off" style={{ background: 'white' }}
                                        value={this.state.descricao}
                                        onKeyUp={(event) => { if (event.which === 13) { this.listarProposicao() } }}
                                        onChange={(event) => this.aplicarFiltroInput(event)} placeholder="Buscar proposição" aria-label="Pesquisar" aria-describedby="search"
                                    />
                                    <span id="icone-pesquisar-proposicao" className="input-group-append">
                                        <button className="btn btn-outline-secondary border-left-0 p-2 border" type="button"
                                            onClick={() => this.listarProposicao()}
                                        >
                                            <i className="icon-search-small"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>

                            {tamanhoTela > 600 ?
                                this.props.authStore.ehEsforcoColaborativo === true || this.props.authStore.ehVisualizador === true ? null :
                                    <div className='col-md-3 marginTopBotaoInt'>
                                        <ModalAI />
                                    </div>
                                :
                                this.props.authStore.ehEsforcoColaborativo === true || this.props.authStore.ehVisualizador === true ? null :
                                    <div className='col-md-3 marginTopBotaoInt mt-2'>
                                        < ModalAI />
                                    </div>
                            }

                        </div>


                        <MediaItem
                            css="h6"
                            size="large"
                            icon="icon-editorial-small"
                            border="mb-3 border-bottom"
                            valorCss="h6"
                            title={this.state.dadosProposicao.descricao} // descricao
                            status={this.state.dadosProposicao.status_descricao_tramitacao} // tramitacao
                            novaComissao={this.state.dadosProposicao.descricao_proposicao === 'null (null) ' || ' () ' ? '' : this.state.dadosProposicao.descricao_proposicao}
                            linkApensadas={this.state.dadosApensadas.descricao}
                            idsApensadas={this.state.dadosApensadas.id}
                        >
                        </MediaItem>

                        {/* BOTAO MOBILE */}

                        {tamanhoTela < 600 ?

                            <div>
                                <div style={{
                                    marginBottom: "20px"
                                }}>
                                    <Seguir onClick={() => this.getProposicao()} status={this.state.dadosProposicao.seguindo}
                                        url={`/proposicao/${this.state.idPropodicaoNovo}/seguir`} />
                                </div>

                                <div>
                                    <div className="row">
                                        {/* <StatusSwitch
                                            checked={this.state.pushStatus}
                                            onChange={status => this.pushNotificacaoSalvar(status)}
                                            //size="small"
                                            name="status"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                        {this.state.pushStatus ? <span style={{ marginTop: "8px" }}>Notificações ativadas</span> : <span style={{ marginTop: "8px" }}>Notificações desativadas</span>} */}
                                        {/* <span>Notificações ativadas</span> */}

                                        <div>
                                            {
                                                this.props.authStore.ehVisualizador === false ?

                                                    prop_integrada === false

                                                        ?

                                                        <div className='col-md-4 d-flex justify-content-end ' >

                                                            <div className=" mr-1 d-flex justify-content-center align-items-center">
                                                                <Link to={`/proposicao/${idProposicao}/editar-proposicao`} className="btn btn-block btn-editExc-proposicoes">
                                                                    <span><img className="" src={require('../../design/assets/images/editar-gray.svg')} alt="icon-parlamentar"></img></span>
                                                                    {/* Editar */}
                                                                </Link>
                                                            </div>
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <button className='btn btn-block btn-editExc-proposicoes ' variant="outlined" onClick={() => this.changeShowModalState(true)}>
                                                                    <img
                                                                        className=""
                                                                        width='14px'
                                                                        src={require('../../design/assets/images/trash-gray.svg')}
                                                                        alt="anexo"
                                                                        id='img-config'
                                                                        style={{ backgroundImage: '#C4C4C4' }}

                                                                    />{' '}
                                                                    {/* <i className='icon-icon_settings_24px' style={{width:'60px', marginRight:'7px', marginTop:'6px' }}></i> */}
                                                                    {/* Apagar */}
                                                                </button>

                                                            </div>

                                                        </div>

                                                        : <div className='col-md-4 d-flex justify-content-end ' ></div>
                                                    : <div className='col-md-4 d-flex justify-content-end ' ></div>
                                            }
                                        </div>

                                    </div>


                                    <hr />

                                </div>

                            </div>



                            :
                            null



                        }




                        {
                            tamanhoTela > 600 ?

                                <div className="d-flex p-3 mb-3 align-items-center" style={{ backgroundColor: '#ECECEC', borderRadius: '6px' }} >
                                    <div className='col-md-5 '>
                                        {/* <StatusSwitch
                                            checked={this.state.pushStatus}
                                            onChange={status => this.pushNotificacaoSalvar(status)}
                                            //size="small"
                                            name="status"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                        {this.state.pushStatus ? <span>Notificações ativadas</span> : <span>Notificações desativadas</span>} */}
                                        {/* <span>Notificações ativadas</span> */}

                                    </div>



                                    {
                                        this.props.authStore.ehVisualizador === false ?

                                            prop_integrada === false

                                                ?

                                                <div className='col-md-4 d-flex justify-content-end ' >

                                                    <div className=" mr-1 d-flex justify-content-center align-items-center">
                                                        <Link to={`/proposicao/${idProposicao}/editar-proposicao`} className="btn btn-block btn-editExc-proposicoes">
                                                            <span><img className="" src={require('../../design/assets/images/editar-gray.svg')} alt="icon-parlamentar"></img></span>
                                                            {/* Editar */}
                                                        </Link>
                                                    </div>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <button className='btn btn-block btn-editExc-proposicoes ' variant="outlined" onClick={() => this.changeShowModalState(true)}>
                                                            <img
                                                                className=""
                                                                width='14px'
                                                                src={require('../../design/assets/images/trash-gray.svg')}
                                                                alt="anexo"
                                                                id='img-config'
                                                                style={{ backgroundImage: '#C4C4C4' }}

                                                            />{' '}
                                                            {/* <i className='icon-icon_settings_24px' style={{width:'60px', marginRight:'7px', marginTop:'6px' }}></i> */}
                                                            {/* Apagar */}
                                                        </button>

                                                    </div>

                                                </div>

                                                : <div className='col-md-4 d-flex justify-content-end ' ></div>
                                            : <div className='col-md-4 d-flex justify-content-end ' ></div>
                                    }


                                    <div className='col-md-3  d-flex justify-content-end '>
                                        {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                            <Seguir onClick={() => this.getProposicao()} status={this.state.dadosProposicao.seguindo}
                                                url={`/proposicao/${this.state.idPropodicaoNovo}/seguir`} />
                                        }
                                    </div>



                                </div>
                                :
                                null
                        }








                        <Row>
                            <div className="bg-white p-1 col-md-3 fs-14">
                                <div className="bg-white p-3">
                                    <h6 className="text-center mb-2 fs-22"><b>Apresentado por</b></h6>

                                    {/* ******* SLIDER DE PARLAMENTAR ********** */}
                                    <Slider {...sliderSettings}>
                                        {this.state.autores !== undefined && Array.isArray(this.state.autores) && this.state.autores.length > 0 && this.state.autores.map((autor, index) => {
                                            return (
                                                <div key={index} className="carousel-item active text-center">
                                                    {this.state.linkDisabled === true ?
                                                        <div>
                                                            <img alt="" id="avatar-parlamentar"
                                                                src={autor.urlFoto ? autor.urlFoto : require('../../design/assets/images/avatar-parlamentar.png')}
                                                                className="d-block m-auto foto-parlamentar"
                                                            />
                                                            <div className="carousel-caption">
                                                                <h5 className="text-capitalize mb-1 font-weight-bold">{autor.nome}</h5>
                                                                <h5 className="foto-parlamentar-proposicao">{autor.tratamento}</h5>
                                                                <h5 className="foto-parlamentar-proposicao">{autor.siglaPartido}/{autor.ufExercicio}</h5>
                                                            </div>

                                                        </div>
                                                        :
                                                        <NavLink id="url-foto-classificadas" to={autor.urlFoto !== undefined ? `/parlamentar/${autor.id ? autor.id : autor.nome}/acao-influencia` : `/proposicao/${idProposicao}/acao-preparatoria`}>
                                                            {autor.urlFoto !== undefined ?
                                                                this.props.authStore.ehEsforcoColaborativo === false ?
                                                                    <img alt="url-fotos-classificadas"
                                                                        src={autor.urlFoto ? autor.urlFoto : require('../../design/assets/images/avatar-parlamentar.png')}
                                                                        className={`d-block m-auto foto-parlamentar
                                                                        ${autor.qualificacao === "Divergente" ? "negativo-foto" : ""} 
                                                                        ${autor.qualificacao === "Convergente" ? "sucesso-foto" : ""}
                                                                        ${autor.qualificacao === "Não Classificado" ? "pendente-foto" : ""}
                                                                        ${autor.qualificacao === "Neutro" ? "neutro-foto" : ""}
                                                                        `}
                                                                    />
                                                                    :
                                                                    <img id="url-foto-sem-classificacao" alt="foto-sem-classificacao"
                                                                        src={autor.urlFoto ? autor.urlFoto : require('../../design/assets/images/avatar-parlamentar.png')}
                                                                        className={`d-block m-auto foto-parlamentar`}
                                                                    />
                                                                :

                                                                <img id="url-foto-sem-id" alt="foto-sem-id"
                                                                    src={this.state.fotoParlamentarSemId ? this.state.fotoParlamentarSemId : require('../../design/assets/images/avatar-parlamentar.png')}
                                                                    className="d-block m-auto foto-parlamentar"
                                                                />

                                                            }
                                                            <div className="carousel-caption">
                                                                <h5 className="text-capitalize mb-1 font-weight-bold">{autor.nome}</h5>
                                                                <h5 className="foto-parlamentar-proposicao">{autor.tratamento}</h5>
                                                                <h5 className="foto-parlamentar-proposicao">{autor.siglaPartido || autor.ufExercicio ? `${autor.siglaPartido}/${autor.ufExercicio}` : null}</h5>
                                                            </div>
                                                        </NavLink>
                                                    }

                                                </div>
                                            )
                                        }
                                        )}
                                    </Slider>

                                    <hr />
                                </div>

                                <div className="p-3">
                                    <h6 className="mb-1"><b>Ementa</b></h6>
                                    <ModalEmentaTexto
                                        ementa={this.state.dadosProposicao.ementa}
                                        id={idProposicao}
                                        title={this.state.dadosProposicao.descricao}

                                    />

                                    {/* <a onClick={this.getTextoBaseLink} className="fs-12 Client-link" target="_blank" rel="noopener noreferrer">{this.state.dadosProposicao.ementa}</a> */}


                                    <h6 className="mb-1"><b>Data de apresentação </b>
                                        {/*obterCasa(this.state.dadosProposicao.sigla_casa)*/}
                                    </h6>
                                    <p className="mb-3 text-wrap ">
                                        {moment(this.state.dadosProposicao.data_apresentacao).format('DD/MM/YYYY')}
                                    </p>

                                    {!this.state.dadosProposicao.status_regime ? null : this.state.dadosProposicao.status_regime !== "" && this.state.dadosProposicao.status_regime !== null && this.state.dadosProposicao.status_regime !== '.' ?
                                        <>
                                            <h6 className="mb-1"><b>Regime de Tramitação</b></h6>
                                            <p className="mb-3 text-wrap ">{this.state.dadosProposicao.status_regime}</p>
                                        </> : null
                                    }

                                    {this.state.dadosProposicao.apreciacao !== "" && this.state.dadosProposicao.apreciacao !== null && this.state.dadosProposicao.apreciacao !== '.' ?
                                        <>
                                            <h6 className="mb-1"><b>Forma de Apreciação</b></h6>
                                            <p className="mb-3 text-wrap ">{this.state.dadosProposicao.apreciacao}</p>
                                        </> : null
                                    }

                                    {this.state.dadosProposicao.tema !== '' && this.state.dadosProposicao.tema !== null ?
                                        <>
                                            <h6 className="mb-1"><b>Temas</b></h6>
                                            <div className="mb-1 text-wrap" >{this.state.dadosProposicao.tema}</div>
                                        </> : null
                                    }

                                    {this.state.dadosProposicao.atualizado_em !== "" || this.state.dadosProposicao.atualizado_em !== null ?
                                        <>
                                            <h6 className="mb-1"><b>Última atualização</b></h6>
                                            {this.state.dadosProposicao.status_data_hora === null || this.state.dadosProposicao.status_data_hora === undefined ?
                                                <p className="mb-3 text-wrap ">{moment(this.state.dadosProposicao.data_apresentacao).format('DD/MM/YYYY')}</p>
                                                :
                                                <p className="mb-3 text-wrap ">{moment(this.state.dadosProposicao.status_data_hora).format('DD/MM/YYYY')}</p>
                                            }
                                        </> : null
                                    }

                                    <h6 className="mb-1"><b>Links Externos</b></h6>
                                    {this.state.dadosProposicao.integrada === true ?
                                        <div className="text-wrap row">
                                            <div className="pl-2" style={{ content: "var(--linkExterno)" }}></div>
                                            {/* {console.log('this.state.dadosProposicao.id_proposicao - - - - - : ', this.state.dadosProposicao.id_proposicao, ' / ', )} */}
                                            {/* <img src={require('../../design/assets/images/link-externo.svg')} alt="link externo" /> */}
                                            <a id={this.state.dadosProposicao.id_proposicao} href={this.state.dadosProposicao.integrada && this.state.dadosProposicao.id_proposicao && this.state.dadosProposicao.casa ? obterNewLinkExterno(this.state.dadosProposicao.id_proposicao, this.state.dadosProposicao.casa).replace("http://", "https://") : ''} className="fs-12 ml-2 Client-link"
                                                target="_blank" rel="noopener noreferrer">Link da Proposta</a>
                                        </div>
                                        :
                                        this.state.dadosProposicao.uri !== "" ?
                                            <div className="text-wrap row">
                                                <div className="pl-2" style={{ content: "var(--linkExterno)" }}></div>
                                                <a id={this.state.dadosProposicao.id_proposicao} href={this.state.dadosProposicao.uri ? this.state.dadosProposicao.uri.replace("http://", "https://") : ''} className="fs-12 ml-2 Client-link"
                                                    target="_blank" rel="noopener noreferrer">Link da Proposta</a>
                                            </div>
                                            :
                                            <div>
                                                Não existem links externos
                                            </div>


                                    }

                                    {this.state.dadosProposicao.uri_inteiro_teor || this.state.dadosProposicao.uri_inteiro_teor ? <div className="row text-wrap">
                                        {/* <img src={require('../../design/assets/images/link-externo.svg')} alt="link externo" /> */}
                                        <div className="pl-2" style={{ content: "var(--linkExterno)" }}></div>
                                        <a id={this.state.dadosProposicao.id_proposicao} href={this.state.dadosProposicao.uri_inteiro_teor ? this.state.dadosProposicao.uri_inteiro_teor.replace("http://", "https://") : ''}
                                            className="fs-12 ml-2 Client-link" target="_blank" rel="noopener noreferrer">Texto Base</a>
                                    </div> : null
                                    }
                                </div>
                            </div>

                            <Grid cols={this.props.authStore.ehEsforcoColaborativo === true ? "12 12 9" : "12 12 6"}>

                                {/* ******* SLIDER DE PROPOSIÇÃO ********** */}
                                <Slider {...sliderSettingsMenu} className="p-2 proposicao-arrow">
                                    {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                        <div className="bordas-links-proposicao">
                                            <NavLink id="proposicao-acontecimentos" className="nav-link"
                                                activeClassName="active"
                                                to={`/proposicao/${idProposicao}/acontecimentos`}>
                                                <div className="remove-link-proposicao">Acontecimentos</div>
                                            </NavLink>
                                        </div>
                                    }

                                    <div className="bordas-links-proposicao">
                                        <NavLink id="proposicao-timeline" className="nav-link" activeClassName="active" to={`/proposicao/${idProposicao}/timeline`}>
                                            <div className="remove-link-proposicao">Timeline</div>
                                        </NavLink>
                                    </div>

                                    <div className="bordas-links-proposicao">
                                        <NavLink id="proposicao-acao-preparatoria" className="nav-link"
                                            activeClassName="active"
                                            to={`/proposicao/${idProposicao}/acao-preparatoria`}>
                                            <div className="remove-link-proposicao">Ação Preparatória</div>
                                        </NavLink>
                                    </div>

                                    {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                        <div className="bordas-links-proposicao">
                                            <NavLink id="proposicao-acao-influencia" className="nav-link"
                                                activeClassName="active"
                                                to={`/proposicao/${idProposicao}/acoes-influencia`}>
                                                <div className="remove-link-proposicao">Ação de Influência</div>
                                            </NavLink>
                                        </div>
                                    }

                                    <div className="bordas-links-proposicao">
                                        <NavLink id="proposicao-apensadas" className="nav-link"
                                            activeClassName="active"
                                            to={`/proposicao/${idProposicao}/apensadas`}>
                                            <div className="remove-link-proposicao" >Apensadas</div>
                                        </NavLink>
                                    </div>
                                    {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                        <div className="bordas-links-proposicao">
                                            <NavLink id="proposicao-correlatas" className="nav-link"
                                                activeClassName="active"
                                                to={`/proposicao/${idProposicao}/correlatas`}>
                                                <div className="remove-link-proposicao">Correlatas</div>
                                            </NavLink>
                                        </div>
                                    }
                                    <div className="bordas-links-proposicao">
                                        <NavLink id="proposicao-correlatas" className="nav-link"
                                            activeClassName="active"
                                            to={`/proposicao/${idProposicao}/emendas`}>
                                            <div className="remove-link-proposicao">Emendas</div>
                                        </NavLink>
                                    </div>
                                </Slider>

                                {this.props.match.params.link === 'acontecimentos' ?
                                    <Acontecimentos
                                        idProposicao={this.props.match.params.id}
                                    />
                                    : null
                                }

                                {this.props.match.params.link === 'timeline' ?
                                    <Tramitacao
                                        idProposicao={this.props.match.params.id}
                                    />
                                    : null
                                }

                                {this.props.match.params.link === 'acao-preparatoria' ?
                                    <AcaoPreparatoria
                                        idProposicao={this.props.match.params.id}
                                        descricao={this.state.dadosProposicao.descricao}
                                        idProposicaoCasaExterna={idProposicaoCasaExterna}
                                        usuarios={this.state.usuarios}
                                        interiorTeor={this.state.dadosProposicao.uri_inteiro_teor || ''}
                                    />
                                    : null
                                }

                                {this.props.match.params.link === 'acoes-influencia' ?
                                    <AcoesInfluencia
                                        idProposicao={this.props.match.params.id}
                                        idProposicaoCasaExterna={idProposicaoCasaExterna}
                                        idNovo={this.state.idPropodicaoNovo}
                                        editarUrl={`/proposicao/${this.props.match.params.id}/acao-influencia/:id`}
                                        usuarios={this.state.usuarios}
                                        descricaoProposicao={this.state.dadosProposicao.descricao}
                                    />
                                    : null
                                }

                                {this.props.match.params.link === 'apensadas' ?
                                    <Apensada
                                        idProposicao={this.props.match.params.id}
                                    />
                                    : null
                                }

                                {this.props.match.params.link === 'correlatas' ?
                                    <Correlatas
                                        idProposicao={this.props.match.params.id}
                                    />
                                    : null
                                }

                                {this.props.match.params.link === 'emendas' ?
                                    <Emendas
                                        idProposicao={this.props.match.params.id}
                                        alert={this.props.alertaStore}
                                    />
                                    : null
                                }

                            </Grid>

                            {this.props.authStore.ehVisualizador !== true ?
                                <Grid cols="12 12 3">
                                    {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                        <div>

                                            {/* >>>>>>>>>>>>>>>>>>>>>CLASSIFICACAO DE TEMA>>>>>>>>>>>>>>>>>>>> */}


                                            <div style={{ marginBottom: "30px" }}>
                                                <h5 >Classificação de Tema</h5>
                                                <div className="mt-3" >
                                                    <label style={{ fontSize: "12px", marginBottom: "-5px" }}>Grupo</label>
                                                    <ClientSelectA
                                                        css="no-border"
                                                        name="grupo"
                                                        id="classificacao-tema-grupo"
                                                        value={this.state.grupoEscolhido}
                                                        options={this.state.listaGrupos}
                                                        onChange={(newValue) => this.changeGrupoSelecionado(newValue)}
                                                        placeholder={"Selecione o Grupo"}
                                                    />
                                                    <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{this.state.erroTema}</span>
                                                </div>

                                                {this.state.renderTema === true ?
                                                    <div className="mt-3" >
                                                        <label style={{ fontSize: "12px", marginBottom: "-5px" }}>Tema</label>
                                                        <ClientSelectA
                                                            css="no-border"
                                                            name="tema"
                                                            id="classificacao-tema-tema"
                                                            value={this.state.temaSelecionado}
                                                            options={this.state.listaTemas}
                                                            onChange={(newValue) => this.changeTemaSelecionado(newValue)}
                                                            placeholder={"Selecione o Tema"}
                                                        />
                                                        <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{this.state.erroSubtema}</span>
                                                    </div>
                                                    :
                                                    null
                                                }

                                                {this.state.renderSubtema === true ?
                                                    <div className="mt-3" >
                                                        <label style={{ fontSize: "12px", marginBottom: "-5px" }}>Subtema</label>
                                                        <ClientSelectA
                                                            css="no-border"
                                                            name="subtema"
                                                            id="classificacao-tema-subtema"
                                                            value={this.state.subtemaSelecionado}
                                                            options={this.state.listaSubtemas}
                                                            onChange={(newValue) => this.changeSelectSubtema(newValue)}
                                                            placeholder={"Selecione o Subtema"}
                                                        />
                                                        {/* <span style={{ color: '#DC2D41', fontSize: '12px', fontWeight: 'bold' }}>{this.state.erroSubtema}</span> */}
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>



                                            {/* >>>>>>>>>>>>>>>>>>>>>>ANALISE DE IMPACTO>>>>>>>>>>>>>>>>>>>>>>> */}

                                            <div>
                                                <h5 >Análise de Impacto</h5>
                                                {/* <hr /> */}
                                                <span className="label-analise-impacto-proposicao">Apelido da proposição</span>
                                                <input
                                                    id="apelidoProposicao"
                                                    className="Client-input-proposicao"
                                                    placeholder="Apelido da proposição"
                                                    defaultValue={this.state.apelido || this.state.analiseImpacto.apelido}
                                                    autoComplete="off"
                                                    maxLength={40}
                                                    onInput={event => this.setState({ apelido: event.target.value })}
                                                    onChange={event => this.alterarEstrategia(estrategiaURL, 'Apelido alterado com sucesso', 'apelido', event.target.value, false)}
                                                    onKeyDown={event => this.alterarEstrategia(estrategiaURL, 'Apelido alterado com sucesso', 'apelido', event.target.value, false, event)}
                                                    onBlur={event => this.alterarEstrategia(estrategiaURL, 'Apelido alterado com sucesso', 'apelido', event.target.value, true)}
                                                />
                                                <span className='d-flex justify-content-end Client-label'><span>{this.state.analiseImpacto.apelido ? this.state.apelido.length : 0}</span>/40</span>

                                                <span className="label-analise-impacto-proposicao">Impacto financeiro</span>
                                                <ClientSelect
                                                    padding="p-0"
                                                    marginLeft="ml-1"
                                                    id="analise-impacto-financeiro-proposicao"
                                                    items={impactoFinanceiroOptions}
                                                    value={this.state.analiseImpacto.fatorImpactoFinanceiro}
                                                    onChange={(event) => { this.alterarEstrategia(estrategiaURL, 'Impacto financeiro alterado com sucesso', 'fatorImpactoFinanceiro', event.id, true) }}
                                                    placeholder={'Impacto financeiro'}
                                                />


                                                {
                                                    this.state.analiseImpacto.fatorImpactoFinanceiro === 4 ? ""
                                                        :
                                                        <div className="">
                                                            <span className="label-analise-impacto-proposicao">Valor do impacto financeiro</span>
                                                            <CurrencyFormat
                                                                id="analise-valor-impacto-financeiro-proposicao"
                                                                value={this.state.valorImpactoFinanceiro || this.state.analiseImpacto.impactoFinanceiro}
                                                                thousandSeparator={true}
                                                                prefix={'R$'}
                                                                onValueChange={(values) => {
                                                                    const { value } = values;
                                                                    this.setState({ profit: value })
                                                                }}
                                                                className="Client-input-proposicao"
                                                                placeholder={"Valor do impacto financeiro"}
                                                                required="required"
                                                                type='text'
                                                                minLength="0"
                                                                maxLength="15"
                                                                onInput={event => this.setState({ valorImpactoFinanceiro: event.target.value })}
                                                                onChange={event => this.alterarEstrategia(estrategiaURL, 'Valor do impacto financeiro alterado com sucesso', 'impactoFinanceiro', event.target.value, false)}
                                                                onKeyPress={event => this.alterarEstrategia(estrategiaURL, 'Valor do impacto financeiro alterado com sucesso', 'impactoFinanceiro', event.target.value, false)}
                                                                onKeyDown={event => this.alterarEstrategia(estrategiaURL, 'Valor do impacto financeiro alterado com sucesso', 'impactoFinanceiro', event.target.value, false)}
                                                                onBlur={event => this.alterarEstrategia(estrategiaURL, 'Valor do impacto financeiro alterado com sucesso', 'impactoFinanceiro', this.state.profit || 0, true)}
                                                            />
                                                        </div>
                                                }

                                                <span className="label-analise-impacto-proposicao">Prioridade</span>
                                                <ClientSelect
                                                    padding="p-0"
                                                    marginLeft="ml-1"
                                                    id="analise-prioridade-proposicao"
                                                    items={this.state.optionsListaPrioridades}
                                                    placeholder='Prioridade'
                                                    value={this.state.analiseImpacto.prioridade}
                                                    onChange={(event) => { this.alterarEstrategia(estrategiaURL, 'Prioridade alterada com sucesso', 'prioridade', event.id, true) }}
                                                />

                                                <span className="label-analise-impacto-proposicao">Posicionamento</span>
                                                <ClientSelect
                                                    padding="p-0"
                                                    marginLeft="ml-1"
                                                    id="analise-posicionamento-proposicao"
                                                    items={posicionamentosClient}
                                                    value={+this.state.analiseImpacto.posicaoDaOrganizacao}
                                                    // label = 'Posicionamento'
                                                    placeholder="Posicionamento"
                                                    onChange={this.props.authStore.ehVisualizador === true ? null : novoPosicionamento => this.alterarPosicionamento(estrategiaURL, novoPosicionamento.id)}
                                                    disabled={this.props.authStore.ehVisualizador === true ? true : false}
                                                />

                                                <span className="label-analise-impacto-proposicao">Análise de Impacto Detalhada</span>
                                                <textarea
                                                    readOnly={this.props.authStore.ehVisualizador === true ? true : false}
                                                    className="form-control form-control-lg  d-inline-block  text-area-analise-impacto text-truncate text-wrap overflow-auto"
                                                    id="analise-impacto-notas-proposicao"
                                                    name="notas"
                                                    rows="6"
                                                    cols="33"
                                                    placeholder="Digite aqui"
                                                    defaultValue={this.state.analiseImpacto.notas}
                                                    autoComplete="off"
                                                    // autofocus="off"
                                                    onChange={this.props.authStore.ehVisualizador === true ? null : event => this.alterar(estrategiaURL, 'Análise de impacto alterado com sucesso', 'notas', event.target.value, false, event)}
                                                    onFocus={this.props.authStore.ehVisualizador === true ? null : event => this.alterar(estrategiaURL, 'Análise de impacto alterado com sucesso', 'notas', event.target.value, false)}
                                                    onKeyDown={this.props.authStore.ehVisualizador === true ? null : event => this.alterar(estrategiaURL, 'Análise de impacto alterado com sucesso', 'notas', event.target.value, false, event)}
                                                    onBlur={this.props.authStore.ehVisualizador === true ? null : event => this.alterar(estrategiaURL, 'Análise de impacto alterado com sucesso', 'notas', event.target.value, true)}
                                                >
                                                </textarea>
                                                <span className='d-flex justify-content-end Client-label'><span>{this.state.analiseImpacto.notas ? this.state.analiseImpacto.notas.length : 0}</span>/4000</span>

                                                <div className="mb-2"><FormNotasTecnicas label="Notas Técnicas" url={estrategiaURL} id={idProposicao} /></div>
                                            </div>
                                        </div>
                                    }
                                </Grid>
                                :

                                <Grid cols="12 12 3">
                                    {this.props.authStore.ehEsforcoColaborativo === true ? null :
                                        < >
                                            <h5 >Análise de Impacto</h5>
                                            <hr />
                                            <span className="label-analise-impacto-proposicao">Apelido da proposição</span>
                                            <div className="text-wrap">{this.state.analiseImpacto.apelido || 'Dado não cadastrado'}</div>
                                            <div className="border"></div>
                                            <span className='d-flex justify-content-end Client-label'><span>{this.state.analiseImpacto.apelido ? this.state.analiseImpacto.apelido.length : 0}</span>/40</span>

                                            <span className="label-analise-impacto-proposicao">Impacto financeiro</span>
                                            <div className="text-wrap">{this.impactoFinanceiroEhVisualizador()}</div>
                                            <div className="border"></div>

                                            <span className="label-analise-impacto-proposicao">Valor do impacto financeiro</span>
                                            <div className="text-wrap">{this.state.analiseImpacto.impactoFinanceiro || 'Dado não cadastrado'}</div>
                                            <div className="border"></div>

                                            <span className="label-analise-impacto-proposicao">Prioridade</span>
                                            <div className="text-wrap">{this.prioridadeEhVisualizador()}</div>
                                            <div className="border"></div>

                                            <span className="label-analise-impacto-proposicao">Posicionamento</span>
                                            <div className="text-wrap">{this.posicionamentEhVisualizador()}</div>
                                            <div className="border"></div>

                                            <span className="label-analise-impacto-proposicao">Análise de Impacto Detalhada </span>
                                            <div className="text-wrap">{this.state.analiseImpacto.notas || 'Dado não cadastrado'}</div>
                                            <div className="border"></div>

                                            <FormNotasTecnicas label="Notas Técnicas" url={estrategiaURL} id={idProposicao} />
                                        </>
                                    }
                                </Grid>
                            }
                        </Row>
                    </div>
                </div>
        )
    }
}

export default ProposicaoEspecifica