import { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import { get } from '../../services/http'
import { withRouter } from 'react-router-dom'
import { inject } from "mobx-react";

@withRouter
@inject('authStore', 'paginacaoStore')
class QueryPaginado extends Component {

    qr='';

    static propTypes = {
        query: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.array.isRequired
        ]),
        variables: PropTypes.object,
        children: PropTypes.func.isRequired,
        normalize: PropTypes.func,
    }

    static defaultProps = { normalize: data => data }

    state = { loaded: false, fetching: false, data: null, error: null, page: 0, lastPage: -1 }

    constructor(props) {
        super(props)
        this.query = this.query.bind(this)
        this.change = this.change.bind(this)
    }

    componentDidMount = () => {
        // if(this.props.location.pathname === '/') {
            this._isMounted = true
            this.query()
            const destruirPaginador = this.props.paginacaoStore.disparador.observe(async () => {
                if (this.state.fetching || this.state.stop || this.state.page === this.state.lastPage) return
                this.setState({lastPage: this.state.page})
                await this.query()
            })
            this.setState({ destruirPaginador })
        // }
    }

    componentDidUpdate = (prevProps) => {
        // if(this.props.location.pathname === '/') {
            if (
                !isEqual(this.props.query, prevProps.query) ||
                !isEqual(this.props.variables, prevProps.variables)
            ) this.query()
        // }
    }

    componentWillUnmount = () => {
        // if(this.props.location.pathname === '/') {
            this._isMounted = false
            this.state.destruirPaginador && this.state.destruirPaginador()
        // }
    }

    
    query = async (pagina = null) => {

        // if(this.props.location.pathname === '/') {
            // console.log("pagina>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", pagina)

            if (pagina) 
                this.setState({ loaded: false, fetching: false, data: null, error: null, page: 0 })

            this.setState({ fetching: false })

            try {
                
                const page = this.state.page + 1;

                const dados = await get(`${this.props.query}?pagina=${page}`);
                const normalizado = this.props.normalize(dados.data);
                const data = page === 1
                    ? normalizado
                    : [...this.state.data, ...normalizado]

                this.safeSetState({
                    data,
                    error: null,
                    loaded: true,
                    fetching: false,
                    page,
                    stop: !normalizado || !normalizado.length
                })
            } catch (error) {
                this.safeSetState({
                    error,
                    data: null,
                    loaded: false,
                    fetching: false,
                })
                if (error && error.response && error.response.status === 400) {
                    this.props.authStore.logout()
                    this.props.history.push('/')
                }
            }
        // } else{
        //     alert("cheguei aqui no else")
        // }
    }

    change = async data => {
        // if(this.props.location.pathname === '/') {
            this.safeSetState({ data })
        // }
    }

    safeSetState = (...args) => {
        // if(this.props.location.pathname === '/') {
            this._isMounted && this.setState(...args)
        // }
    }

                // console.log("pagina>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", data)


    render = () => this.props.children({ ...this.state, reload: this.query, change: this.change })
}

export default QueryPaginado