import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import casa from '../../../helpers/casa';
import moment from 'moment-timezone';
import { post, get, del } from '../../../services/http';
import Loading from '../../../componentes/loading';
import { inject, observer } from 'mobx-react';
import ClientSelect from '../../../componentes/forms/basic/clientSelectModalCadUser'
import LoadingCircle from '../../../componentes/loadingCircle'
import alertaStore from '../../../stores/alertaStore'
// import { Link } from 'react-router-dom';

moment.tz.setDefault('UTC');

const inputStyleA = {
    border: 'none',
    backgroundColor: 'transparent',
    borderBottom: '1px solid #C4C4C4',
    color: '#000000',
    marginTop: '13px',
    width: '100%',
    placeholder: 'red',
    outline: 'none',
    height: "25px"
};

@inject('authStore')
@observer

@withRouter



class Apensadas extends Component {

    constructor(props) {
        super(props)
        this.state = {

            dadosProposicao: {},
            items: [],
            proposicaoId: null,
            loading: true,

            valorProp: "",
            propSelecionada: [],
            listaProposicoes: [],
            dataForm: {
                prop_selecionada: [],
            },
            resNaoSucesso: "",
            resSucesso: "",
            listaPropPopulada: [],
            propParaExcluir: []

            // errors:{},

        }
    }

    async componentDidMount() {
        await this.setState({ proposicaoId: this.props.idProposicao })
        await this.obterApensadas()
        await this.getProposicao()
    }

    async obterApensadas() {
        const data = { id: this.props.idProposicao, pagina: 1 }
        await post('proposicao/apensadas', data)
            .then(response => {
                console.log(response)
                this.setState({ items: response, loading: false, listaPropPopulada: response })
            })
    }

    linkProposicao(id) {
        window.location.href = `/proposicao/${id}/acao-preparatoria`
    }

    async getProposicao() {

        await get(`proposicao/especifica/${this.props.match.params.id}`)
            .then(res => {
                if (res.deleted) {


                    window.localStorage.setItem('filtroInput', '')
                    window.localStorage.setItem('prop_deleted', true)
                    window.localStorage.setItem('prop_deleted_decricao', res.descricao)
                    window.location.href = '/proposicao/lista'

                } else {
                    window.localStorage.removeItem('prop_deleted')
                    window.localStorage.removeItem('prop_deleted_decricao')
                    const dados = res || {}
                    this.setState({
                        dadosProposicao: dados.dados,


                    })
                }

            })
            .catch(err => err)

    }

    async obterProposicoes(propEscrita) {
        this.setState({
            propSelecionada: [],
            valorProp: propEscrita
        })
        await get(`insights/proposicoes/?tipo=${propEscrita}`)

            .then(res => {
                this.setState({
                    listaProposicoes: res
                })
            })
            .catch(e => {
                console.log(e)
            })
    }

    handleChangeSelectProp(value) {
        this.setState({
            propSelecionada: value,
            prop_selecionada: { id: value.value }
        })
    }

    async postRegistroApensada() {


        const data = this.state.prop_selecionada

        await post(`/proposicao/${this.state.proposicaoId}/apensadas`, data)
            .then(res => {
                console.log("res", res)
                if (res !== "jaCadastrada") {
                    alertaStore.criar('Proposição Apensada foi criada com sucesso!')
                    window.location.reload()
                } else {
                    alertaStore.erro("Esta já foi cadastrada. Tente novamente!")
                }
            })
    }

    async removerApensada(data) {
        await del(`/proposicao/${this.state.proposicaoId}/apensadas/${data}`)
            .then(res => {
                alertaStore.criar('Proposição apensada excluida com sucesso')
                window.location.reload()
            })
    }





    render() {
        const prop_integrada = this.state.dadosProposicao.integrada

        return (

            this.state.loading ? <Loading /> :

                <div>

                    {
                        prop_integrada === false

                            ?
                            <div style={{ marginLeft: "8px", marginRight: "8px" }}>
                                <div className="row" style={{ backgroundColor: "white", padding: "20px", borderRadius: "3px" }} >
                                    <div className="input-group col-12 col-sm-6  col-lg-6 col-xl-6">
                                        <label style={{ color: "var(--linkText)" }} > Proposição Apensada à</label>
                                        <input type="search" autoComplete="off"
                                            style={inputStyleA}
                                            value={this.state.valorProp}
                                            onChange={(event) => this.obterProposicoes(event.target.value.toUpperCase())}
                                            placeholder="Buscar proposição" aria-label="Pesquisar"
                                            aria-describedby="search"
                                        />


                                    </div>

                                    {this.state.listaProposicoes.length > 0
                                        ?
                                        <div className="col-md-6">
                                            <div>
                                                <label style={{ color: "var(--linkText)" }}>Selecionar Apensada</label>
                                                <ClientSelect
                                                    css="no-border"
                                                    name="casa"
                                                    value={this.state.propSelecionada}
                                                    options={
                                                        this.state.listaProposicoes !== undefined && Array.isArray(this.state.listaProposicoes) && this.state.listaProposicoes.length > 0 ?
                                                            this.state.listaProposicoes.map((item) => ({ value: item.id, label: item.descricao + ' ' + item.apelido + ' - ' + item.casa })) : null}
                                                    onChange={(newValue) => this.handleChangeSelectProp(newValue)}
                                                    placeholder={"Selecionar"}
                                                />
                                                {/* {errors.casa && errors.casa.message} */}
                                            </div>
                                        </div>
                                        :
                                        this.state.valorProp !== "" ?
                                            <div className="col-md-6 p-3 d-flex flex-column text-center">
                                                <div className="col-12 text-center">
                                                    <LoadingCircle color={'#DC2D41'} size={'30px'} />
                                                </div>
                                                <div className="col-12 text-center fontsCitacoes">
                                                    Continue escrevendo, estamos buscando!
                                                </div>
                                            </div>
                                            :
                                            <div className='col-md-6 p-3 d-flex flex-column text-center fontsCitacoes' style={{ fontSize: '16px' }}>
                                                <span>Será exibido aqui o resultado da busca</span>
                                                <span style={{ color: "var(--linkText)", fontSize: "12px" }}>Busque pela proposição desejada (Ex: PL81/2020)</span>
                                            </div>

                                    }

                                    <button className="btn" style={{ marginTop: "10px", padding: "15px", backgroundColor: "var(--botaoPesquisar)", color: "var(--corTextoBotao)", fontWeight: "lighter", width: "100%" }} variant="outlined"
                                        onClick={() => this.postRegistroApensada()}
                                    >
                                        <img
                                            className="mr-2"
                                            style={{ width: "20px" }}
                                            src={require('../../../design/assets/images/editar.svg')}
                                            alt="btn-pesquisar"
                                        />{' '}
                                        Cadastrar
                                    </button>
                                </div>
                            </div>

                            :
                            null
                    }




                    {this.state.items !== undefined && Array.isArray(this.state.items) && this.state.items.length > 0 ?
                        this.state.items.map((item, index) => {
                            // console.log('item', item)
                            const outrosFalse = item.tipoRelacao === 2 ? "rgb(234,234,234)" : "rgb(255,255,255)"
                            const background = item.outros === false ? outrosFalse : "rgb(245,245,245)"
                            let casa = item.siglaCasa

                            return (
                                item.tipoRelacao !== 3 ?
                                    <div key={index} className={`Client-mini-card mb-2 mt-4`} style={{ backgroundColor: background }}>

                                        <div className="row">
                                            <div style={{ cursor: 'pointer' }} className="header d-flex align-items-start col-11 " onClick={() => this.linkProposicao(item.id)}>
                                                <img src={require('../../../design/assets/images/joined-small.svg')} alt="icone-apensadas" />
                                                {item.outros === true ?
                                                    <div style={{ backgroundColor: "black", color: "white", padding: "5px", fontSize: "10px", borderRadius: "7px", marginLeft: "10px" }}>
                                                        Outros
                                                    </div>
                                                    :
                                                    null
                                                }
                                                <div className="title ml-2" style={{ marginTop: "-2px" }}>
                                                    <span className='Client-titulo-card'>{item.descricao}</span>
                                                    <span className="Client-cinza-claro fs-16 font-weight-bold">{moment(item.data).format('DD/MM/YYYY')}</span>
                                                </div>
                                                <div className='font-weight-bold ml-2' style={{ marginTop: "1px" }}> - {casa} </div>
                                            </div>
                                            {prop_integrada === false ?
                                                <div style={{ cursor: 'pointer', }} className="col-1 text-right" >
                                                    <img onClick={() =>
                                                        this.removerApensada(item.id)
                                                    } src={require('../../../design/assets/images/trash-gray.svg')} alt="excluir" />
                                                </div>
                                                :
                                                null
                                            }

                                            <p className="text-wrap fs-16 mt-2 pl-3">{item.ementa}</p>
                                        </div>


                                    </div>

                                    : null
                            )
                        }) :
                        <div className="container-fluid text-center bg-white p-4 mb-4 mt-3">
                            <h4 className="mb-0 Client-titulo">Ainda não existem apensadas</h4>
                        </div>
                    }

                </div>
        )
    }
}

export default props => <Apensadas {...props} semPaginacao={true} />
