import React from 'react'
import { Switch, Route } from 'react-router-dom'

// import GerenciamentoUsuarios from '../gerenciamento-usuarios'
// import RelatorioUsuarios from '../relatorio-usuarios'
// import Gerenciamento from '../gerenciamento'
// import Perfil from '../perfil'
// import FormUsuario from '../form/formUsuario'

import Usuarios from '../usuariosDefault'


export default () =>
    <Switch>
        <Route exact path="/usuario/:assunto" component={Usuarios} />

        {/* <Route exact path="/usuario/gerenciamento-usuarios" component={GerenciamentoUsuarios} />
        <Route exact path="/usuario/gerenciamento/(ativos|inativos|pendentes)" component={Gerenciamento} />
        <Route exact path="/usuario/perfil" component={Perfil} />
        <Route exact path="/usuario/criar" component={FormUsuario} />
        <Route exact path="/usuario/:id/editar" component={FormUsuario} />
        <Route exact path="/usuario/relatorios" component={RelatorioUsuarios} /> */}
        
        
    </Switch>