import React, { Component } from 'react';
import Accordion from '../../componentes/accordion'
import Loading from '../../componentes/loading'
import { post, put } from '../../services/http'
import Pagination from "react-js-pagination";
// import BreadCrumb from '../../estruturas/breadcrumb'
import ModalIA from '../proposicao/modalAI'
import posicionamento from "../../enums/posicionamento"
import { inject, observer } from 'mobx-react'
import iconTrash from "../../design/assets/images/trash-gray.svg"
import ModalSuccess from '../inteligenciaArtificial/modalSuccess'
import ModalCancelar from '../inteligenciaArtificial/modalCancelarTreinamento'
import { buscarListaPrioridades } from "../../../src/enums/prioridades"




@inject('authStore')
@observer



class TreinamentoIA extends Component {

    state = {
        loading: true,
        paginaAtual: 1,
        limite: 20,
        quantidade: 0,
        offset: 0,
        dadosIA: [],
        buscarApelido: '',
        abrirModalSucesso: false,
        abrirModalCancelar: false,
        ClientPrioridades: []


    }

    componentDidMount() {
        if (this.props.authStore.ehEsforcoColaborativo === true) {
            window.location.href = '/'
        }
        this.getDadosIA(1)
        // this.getCountIA()


        //chamando a lista de prioridades do banco
        buscarListaPrioridades()
            .then(res => {
                this.setState({
                    ClientPrioridades: res,
                })
            })

    }



    async getDadosIA(pagina) {
        const data = {
            limit: this.state.limite,
            offset: pagina === 1 ? 0 : (pagina - 1) * this.state.limite,
            filtro: this.state.buscarApelido
        }
        await post('/ia/paginado', data)
            .then(res => {
                this.setState({ dadosIA: res.dados, quantidade: res.count, loading: false })
            })
    }

    // async getCountIA() {
    //     await get('/ia/reference-true')
    //         .then(res => {
    //             this.setState({ quantidade: res.length })
    //         })
    // }

    carregarPaginaNova = async pagina => {
        this.setState({ paginaAtual: pagina, loading: true })
        await this.getDadosIA(pagina)
    }

    selecionarApelido(value) {
        this.setState({ buscarApelido: value })
    }

    putDeleteData = async (item) => {
        // console.log("ulisses Item", item)
        await put(`/ia/delTextAi/${item.id}`)
            .then(response => {
                //console.log("Ulisses", response)
                // this.props.alertaStore.criar('Palavra removida com sucesso.')
                // this.getDadosIA()
            }).then(this.setState({ abrirModalCancelar: true })).catch(err => err)

        // .then(this.setState({ abrirModalCancelar:true})).then(this.setState({ abrirModalSucesso: true }))   
        // .catch(err => err)
    }

    limparFiltro() {
        window.location.reload()
    }

    render() {

        // const iconTrash = this.props.iconTrash ? this.props.iconTrash : require('../design/assets/images/trash-gray.svg');

        return (
            <div>
                {this.state.loading ? <Loading /> :
                    <div className="p-3" style={{ background: 'white' }}>
                        <div className='row pt-3'>

                            <div className='col-md-9'>
                                <div className="input-group  icon-input pb-2">
                                    <input className="form-control py-2 border-right-0 border" id="pesquisar-proposicao" type="search" autoComplete="off"
                                        value={this.state.buscarApelido}
                                        onKeyUp={(event) => { if (event.which === 13) { this.carregarPaginaNova(1) } }}
                                        // onKeyPress={(event) => this.clickEnter(event)}
                                        onChange={(event) => this.selecionarApelido(event.target.value)} placeholder="Buscar Texto" aria-label="Pesquisar" aria-describedby="search" />
                                    <span id="icone-pesquisar-proposicao" className="input-group-append">
                                        <button className="btn btn-outline-secondary border-left-0 border" type="button"
                                            onClick={() => { this.carregarPaginaNova(1) }}>
                                            <i className="icon-search-small"></i>
                                        </button>
                                    </span>

                                </div>

                            </div>
                            <div className='col-md-3'>
                                {this.props.authStore.ehVisualizador === true ? null :
                                    <ModalIA

                                    />
                                }
                            </div>
                        </div>
                        <span onClick={e => this.limparFiltro(e, true)} className="limpar-filtro">Limpar Filtro</span>
                        <div className="pl-3 pb-1 pt-5">
                            <h4>Textos Cadastrados</h4>
                        </div>

                        {this.state.dadosIA && this.state.dadosIA.length > 0 ?
                            this.state.dadosIA.map((item, index) => {
                                return (
                                    <div key={index} className="acordionsBordas mt-2">
                                        <Accordion
                                            mb={'mb-0'}
                                            p={'p-0'}
                                            titulo={item.apelido}
                                        >

                                            <div className='d-flex justify-content-end' style={{ marginBottom: "-10px" }}>

                                                <div className="mr-3" >
                                                    <img id="acao-preparatoria-excluir" className='cursor-pointer' onClick={() => this.putDeleteData(item)} src={iconTrash} alt="" />
                                                </div>

                                                <div>
                                                    {this.props.authStore.ehVisualizador === true ? null :
                                                        <ModalIA
                                                            path={'ia'}
                                                            dados={item}
                                                        />
                                                    }
                                                </div>

                                            </div>


                                            <hr />
                                            <p className="text-wrap">
                                                {item.texto_integra}
                                            </p>

                                            <div className="row">
                                                <div className="col-sm-6 col-6">
                                                    <div><b className='tituloPrioEPosi'>Prioridade</b></div>
                                                    <div>{this.state.ClientPrioridades.map(i => i.id === item.prioridade ? i.value : null).filter(x => x)[0]}</div>
                                                </div>
                                                <div className="col-sm-6 col-6">
                                                    <div><b className='tituloPrioEPosi'>Posicionamento</b></div>
                                                    <div>{posicionamento.posicionamentosClient.map(i => i.id === item.posicionamento ? i.value : null).filter(x => x)[0]}</div>
                                                </div>
                                            </div>

                                        </Accordion>
                                    </div>

                                )
                            })
                            : null}

                        {this.state.limite > parseInt(this.state.quantidade) && this.state.buscarApelido === '' ? null :
                            <Pagination
                                activePage={this.state.paginaAtual}
                                itemsCountPerPage={this.state.limite}
                                totalItemsCount={parseInt(this.state.quantidade)}
                                pageRangeDisplayed={10}
                                hideFirstLastPages={true}
                                onChange={this.carregarPaginaNova}
                                prevPageText={<div className="prev-page"><img src={require('../../design/assets/images/prev-page-pagination.svg')} alt="prev-page" /></div>}
                                nextPageText={<div className="next-page"><img src={require('../../design/assets/images/next-page-pagination.svg')} alt="next-page" /></div>}
                            />
                        }

                    </div>
                }


                {this.state.abrirModalSucesso === true ?
                    <div className='col-md-3 marginTopBotaoInt'>
                        <ModalSuccess
                            abrirModalSucesso={this.state.abrirModalSucesso}
                        />
                    </div>
                    : null
                }

                {this.state.abrirModalCancelar === true ?
                    <div className='col-md-3 marginTopBotaoInt'>
                        <ModalCancelar
                            abrirModalCancelar={this.state.abrirModalCancelar}
                        />
                    </div>
                    : null
                }
            </div>
        );
    }

}

export default TreinamentoIA