import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import Loading from "../../componentes/loading";
import ModalCitacoes from "./modalCitacoes";
import Accordion from "../../componentes/accordionCustom";
import { get, put, post } from "../../services/http";
import moment from 'moment-timezone';
import Pagination from "react-js-pagination";

moment.tz.setDefault('UTC');

@withRouter
@inject("uploadStore", "authStore", "modalStore", "alertaStore")
@observer
class InsightCitacao extends Component {
	state = {
		loading: false,
		loadingTable: false,
		showModal: false,
		filtrandoPalavra: false,
		arrayFiltroPalavra: [],
		palavraFiltro: "",
		responsePalavra: [],
		responselistaCitacoesPalavras: [],
		limparBusca: false,
		paginaAtual: 1,
		limite: 20,
		quantidade: 0,
		indiceAccordion: null,
		paginaAtualTabela: 1,
		limiteTabela: 20,
		palavrasItem: "",
		totalCitacoes: 0,
	};

	componentDidMount() {
		if (this.props.authStore.ehEsforcoColaborativo === true) {
			window.location.href = "/";
		}
		this.getDataCitacoes(1);
	}

	showModal() {
		this.setState({ showModal: true });
	}

	closeModal() {
		this.setState({ showModal: false });
	}

	getDataCitacoes = async (page) => {
		const offset = page === 1 ? 0 : (page - 1) * this.state.limite;
		this.setState({ loading: true });

		await get(`insights/citacoes/palavras/${this.state.limite}/${offset}`)
			.then((response) => {
				const dados = response || [];
				this.setState({
					responsePalavra: dados.palavras,
					quantidade: dados.palavrasCount,
					loading: false,
				});
				this._buscarInput.focus();
			})
			.catch((err) => err);
	};

	getDadosTabelaCitacoes = async (vlrAcc, item, index) => {
		this.setState({ indiceAccordion: index });

		if (vlrAcc === true) {
			this.setState({ loadingTable: true });

			const offset =
				this.state.paginaAtualTabela === 1
					? 0
					: (this.state.paginaAtualTabela - 1) *
					  this.state.limiteTabela;
			await get(
				`insights/citacoes/discurso/${item.descricao}/${this.state.limiteTabela}/${offset}`
			)
				.then((response) => {
					console.log(response);
					const dados = response || [];
					if (index === this.state.indiceAccordion) {
						if (dados.tabelaCitacoes.length > 0) {
							this.setState({
								responselistaCitacoesPalavras:
									dados.tabelaCitacoes,
								totalCitacoes: parseInt(dados.totalCitacoes),
								loadingTable: false,
							});
						} else {
							this.setState({
								responselistaCitacoesPalavras: [],
								totalCitacoes: [],
								loadingTable: false,
							});
						}
					}
				})
				.catch((err) => err);
		}
	};

	getDadosPalavraChave = async () => {
		await get(`insights/citacoes/palavras/${this.state.palavraFiltro}`)
			.then((response) => {
				const dados = response || [];
				this.setState({
					responsePalavra: dados,
					limparBusca: true,
					loadingTable: false,
				});
			})
			.catch((err) => err);
	};

	putPalavraChave = async (id) => {
		await put(`insights/citacoes/palavra-chave/${id}`)
			.then((response) => {
				this.props.alertaStore.criar("Palavra removida com sucesso.");
				this.getDataCitacoes(1);
			})
			.catch((err) => err);
	};

	saveListPalavras = async (resp, array) => {
		if (resp) {
			await post(`insights/citacoes/palavra-chave`, array)
				.then((response) => {
					this.props.alertaStore.criar(
						"Palavras gravadas com sucesso."
					);
					this.getDataCitacoes(1);
				})
				.catch((err) => err);
		} else {
			this.props.alertaStore.erro("Palavras não inseridas.");
		}
	};

	selecionarBusca(value) {
		if (this._buscarInput.value === "") {
			this.setState({ limparBusca: false });
			this.getDataCitacoes(1);
		} else {
			const palavra = value;
			this.setState({ palavraFiltro: palavra });
		}
	}

	carregarPaginaNova = async (pagina) => {
		this.setState({ paginaAtual: pagina });
		await this.getDataCitacoes(pagina);
	};

	paginacaoTabela = async (pagina, item, index) => {
		window.location.href = `#_${index}`;
		this.setState({ paginaAtualTabela: pagina, palavrasItem: item });
		await this.getDadosTabelaCitacoes(true, item, index);
	};

	limparDadosBusca = () => {
		this.setState({ limparBusca: false });
		this.getDataCitacoes(1);
	};

	render() {
		return this.state.loading ? (
			<div style={{ marginTop: "400px" }}>
				<Loading />
			</div>
		) : (
			<div className="container-citacoes m-4">
				<div>
					<div className="row">
						<div className="input-group col-12 col-sm-8  col-lg-8 col-xl-9">
							<input
								className="form-control py-2 border-right-0 border"
								type="search"
								autoComplete="off"
								value={this.state.descricao}
								onKeyUp={(event) => {
									if (event.which === 13) {
										this.getDadosPalavraChave();
									}
								}}
								// onKeyPress={(event) => this.clickEnter(event)}
								onChange={(event) =>
									this.selecionarBusca(event.target.value)
								}
								placeholder="Buscar palavra-chave"
								aria-label="Pesquisar"
								aria-describedby="search"
								ref={(event) => (this._buscarInput = event)}
							/>

							<span
								id="icone-pesquisar-proposicao"
								className="input-group-append"
							>
								<button
									className="btn btn-outline-secondary border-left-0 border"
									type="button"
									onClick={() => this.getDadosPalavraChave()}
								>
									<i className="icon-search-small"></i>
								</button>
							</span>
						</div>

						<div className="col-12 col-sm-4  col-lg-3 col-xl-3 ">
							{this.props.authStore.ehVisualizador ===
							true ? null : (
								<ModalCitacoes
									callbackParent={this.saveListPalavras}
								/>
							)}
						</div>
					</div>
					{this.state.limparBusca ? (
						<div className="row m-2">
							<div style={{ cursor: "pointer" }}>
								<span
									style={{
										color: "var(--linkText)",
										textDecoration: "none",
										cursor: "pointer",
									}}
									onClick={this.limparDadosBusca}
								>
									Limpar Filtro
								</span>
							</div>
						</div>
					) : null}

					{this.state.responsePalavra.length > 0 ? (
						<div>
							<div className="row mt-3">
								<div className="ml-2">
									<p
										className="fontsCitacoes"
										style={{ fontSize: "18px" }}
									>
										Palavras-chave seguidas
									</p>
								</div>
								{this.state.responsePalavra.map(
									(item, index) => {
										return (
											<div
												id={`_${index}`}
												className="col-12 mb-2 bordasCitacoes"
												key={index}
											>
												<Accordion
													mb={"mb-0"}
													p={"p-0"}
													id={item.id}
													index={index}
													indexAtual={
														this.state
															.indiceAccordion
													}
													onClick={""}
													titulo={item.descricao}
													aberto={""}
													abrirAccordion={() =>
														this.getDadosTabelaCitacoes(
															true,
															item,
															index
														)
													}
													delete={
														this.putPalavraChave
													}
												>
													<hr />

													{this.state.loadingTable ? (
														<div
															style={{
																marginTop:
																	"200px",
															}}
														>
															<Loading />
														</div>
													) : (
														<div className="mt-1">
															<div className="ml-2">
																<p
																	className="fontsCitacoes"
																	style={{
																		fontSize:
																			"16px",
																	}}
																>
																	<b>
																		N° de
																		Citações
																	</b>
																</p>
																<p>
																	{
																		this
																			.state
																			.totalCitacoes
																	}
																</p>
															</div>

															<div className="table-responsive">
																<table className="table table-striped">
																	<thead className="theadStyleCitacoes">
																		<tr>
																			<th className="">
																				Data
																			</th>
																			<th className="">
																				Tipo
																			</th>
																			<th className="">
																				Parlamentar
																			</th>
																			<th className="">
																				Partido
																			</th>
																			<th className="">
																				Estado
																			</th>
																			<th className="">
																				Trecho
																			</th>
																		</tr>
																	</thead>
																	<tbody
																		className="text-left"
																		style={{
																			fontSize:
																				"14px",
																		}}
																	>
																		{this
																			.state
																			.responselistaCitacoesPalavras !==
																			undefined &&
																		Array.isArray(
																			this
																				.state
																				.responselistaCitacoesPalavras
																		) &&
																		this
																			.state
																			.responselistaCitacoesPalavras
																			.length >
																			0
																			? this.state.responselistaCitacoesPalavras.map(
																					(
																						lista,
																						index
																					) => {
																						return (
																							<tr
																								key={
																									index
																								}
																							>
																								<td>
																									{moment(
																										lista.data
																									).format(
																										"DD/MM/YYYY"
																									)}
																								</td>
																								<td>
																									<a
																										href={
																											"../" +
																											lista.link
																										}
																										className="fs-12 Client-link"
																										target="_blank"
																										rel="noopener noreferrer"
																									>
																										{
																											lista.tipo
																										}
																									</a>
																								</td>
																								<td>
																									{
																										lista.parlamentar
																									}
																								</td>
																								<td>
																									{
																										lista.sigla
																									}
																								</td>
																								<td>
																									{
																										lista.uf
																									}
																								</td>
																								<td
																									className="text-truncate"
																									style={{
																										maxWidth:
																											"250px",
																									}}
																								>
																									{
																										lista.trecho
																									}
																								</td>
																							</tr>
																						);
																					}
																			  )
																			: null}
																	</tbody>
																</table>
															</div>
															<div className="d-flex justify-content-center paginacao-citacoes">
																<Pagination
																	activePage={
																		this
																			.state
																			.paginaAtualTabela
																	}
																	itemsCountPerPage={
																		this
																			.state
																			.limiteTabela
																	}
																	totalItemsCount={
																		this
																			.state
																			.totalCitacoes
																	}
																	pageRangeDisplayed={
																		10
																	}
																	hideFirstLastPages={
																		true
																	}
																	onChange={(
																		e
																	) =>
																		this.paginacaoTabela(
																			e,
																			item,
																			index
																		)
																	}
																	prevPageText={
																		<div className="prev-page">
																			<img
																				src={require("../../design/assets/images/prev-page-pagination.svg")}
																				alt="prev-page"
																			/>
																		</div>
																	}
																	nextPageText={
																		<div className="next-page">
																			<img
																				src={require("../../design/assets/images/next-page-pagination.svg")}
																				alt="next-page"
																			/>
																		</div>
																	}
																/>
															</div>
														</div>
													)}
												</Accordion>
											</div>
										);
									}
								)}
							</div>
							<div className="d-flex justify-content-center paginacao-citacoes">
								<Pagination
									activePage={this.state.paginaAtual}
									itemsCountPerPage={this.state.limite}
									totalItemsCount={this.state.quantidade}
									pageRangeDisplayed={10}
									hideFirstLastPages={true}
									onChange={this.carregarPaginaNova}
									prevPageText={
										<div className="prev-page">
											<img
												src={require("../../design/assets/images/prev-page-pagination.svg")}
												alt="prev-page"
											/>
										</div>
									}
									nextPageText={
										<div className="next-page">
											<img
												src={require("../../design/assets/images/next-page-pagination.svg")}
												alt="next-page"
											/>
										</div>
									}
								/>
							</div>
						</div>
					) : (
						<div className="row">
							<div className="mt-4 col-12">
								<p
									className="text-center fontsCitacoes"
									style={{ fontSize: "23px" }}
								>
									As palavras-chave buscadas aparecerão aqui.
								</p>
							</div>
						</div>
					)}
					{/* {
                    this.state.showModal === true ?                           
                
                            <Modal 
                                titulo="Configurações"
                                goBack={`/insights/citacao`}
                                
                            >
                                <FormCitacoes/>    
                            </Modal>  
                    

                    : null
                    } */}
				</div>
			</div>
		);
	}
}

export default InsightCitacao;
